/**
 * @description @antv/layout DagreLayout
 */
import { DagreLayout } from "@antv/layout";

export const layoutDagre = (graphData: any, options?: Record<string, any>) => {
	const antvDagreLayout = new DagreLayout({
		type: "dagre",
		nodesep: 250,
		rankdir: "TB",
		begin: options?.begin || [250, 250],
	});
	antvDagreLayout.layout(graphData);

	return {
		edges: graphData.edges,
		nodes: graphData.nodes,
	};
};
