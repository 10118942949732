import React from "react";
import { Tag, Table } from "antd";

const HomePageTaskListViewItem: React.FC<any> = ({ Data }: { Data: any[] }) => {
	const columns = [
		{
			title: <div style={{ fontFamily: "Poppins" }}>Title</div>,
			dataIndex: "title",
			key: "title",
			render: (text: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<a
						style={{
							color: "black",
							fontWeight: "bold",
							fontFamily: "Open Sans",
						}}
					>
						{text}
					</a>
				</div>
			),
		},
		{
			title: <div style={{ fontFamily: "Poppins" }}>Status</div>,
			dataIndex: "status",
			key: "status",
			render: (text: any) => (
				<Tag color="yellow" style={{ color: "black", fontFamily: "Open Sans" }}>
					{text.toUpperCase()}
				</Tag>
			),
		},
		{
			title: <div style={{ fontFamily: "Poppins" }}>Priority</div>,
			dataIndex: "priority",
			key: "priority",
			render: (priority: any) => (
				<Tag color="blue" style={{ color: "black", fontFamily: "Open Sans" }}>
					{priority}
				</Tag>
			),
		},
	];

	const data = Data.map((item) => ({
		WorkitemId: item.WorkitemId,
		key: item._id,
		title: item.title,
		description: item.description,
		priority: item.priority,
		status: item.status,
	}));
	const pageSize = 3;

	return (
		<Table
			columns={columns}
			dataSource={data}
			pagination={{
				pageSize: pageSize,
				showSizeChanger: false,
			}}
			scroll={{ y: 120 }}
		/>
	);
};

export default HomePageTaskListViewItem;
