import { Key, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridLayout from "react-grid-layout";

import {
	ArrowLeftOutlined,
	DeleteOutlined,
	PlusOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import {
	Modal,
	Input,
	Select,
	Form,
	Button,
	Flex,
	Breadcrumb,
	Card,
	Tooltip,
	Dropdown,
	Menu,
	ConfigProvider,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { v4 as uuidv4 } from "uuid";
import DynamicContent from "./Reports/DynamicContent";
import { remove } from "lodash";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import EditWidgetModal from "./DashboardEditWidgetModal";

interface Widget {
	id: string;
	title: string;
	description: string;
	type: string;
	component: string;
	size?: string;
}

const NewDashboard = () => {
	const [layout, setLayout] = useState([
		{ i: "Add Widget", x: 0, y: 0, w: 4, h: 3 },
	]);
	const { Option } = Select;

	const [isLayoutDisabled, setIsLayoutDisabled] = useState(true);
	const [modalVisible, setModalVisible] = useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { id } = useParams();
	const [formItem, setFormItem] = useState({
		title: "",
		description: "",
		type: "",
		component: "",
	});

	const [data, setData] = useState<any>(null);
	const [dashboardTitle, setDashboardTitle] = useState("");
	const [widgetsData, setWidgetsData] = useState<Widget[]>([]);
	const [editmodalVisible, setEditModalVisible] = useState(false);
	const [selectedWidgetId, setSelectedWidgetId] = useState("");
	const navigate = useNavigate();

	const handleEditClick = (widgetId: SetStateAction<string>) => {
		setSelectedWidgetId(widgetId);
		setEditModalVisible(true);
	};

	const handleEditCancel = () => {
		setEditModalVisible(false);
		setSelectedWidgetId("");
	};

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/dashboard/${id}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const result = await response.json();
				setData(result);
				setDashboardTitle(result.title);
				setWidgetsData(result.widgets);
				if (result.layoutConfig) setLayout(result.layoutConfig);
				console.log("widgetsData", widgetsData);
			} else {
				console.error("Failed to fetch data");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, [id, userProfileStoreInstance.idToken]);

	// const YPositionForNewWidget = () => {
	// 	let y = 0;
	// 	let h = 4;
	// 	layout.forEach((item) => {
	// 		if (item.y > y) {
	// 			y = item.y;
	// 			h = item.h;
	// 		}
	// 	});
	// 	return y + h;
	// };

	const addGrid = () => {
		const maxY = Math.max(...layout.map((item) => item.y));
		const newGrid = {
			i: "Add Widget",
			x: 0,
			y: maxY + 4,
			w: 4,
			h: 4,
		};
		setLayout([...layout, newGrid]);
	};

	const onLayoutChange = (
		newLayout: SetStateAction<
			{ i: string; x: number; y: number; w: number; h: number }[]
		>
	) => {
		console.log("newLayout", newLayout);
		setLayout(newLayout);
	};

	const updateDashboard = (updatedWidgetsData: any, updatedLayoutData: any) => {
		remove(layout, (item) => item.i === "Add Widget");

		fetch(`${process.env.REACT_APP_API_URL}/dashboard/${id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify({
				title: data && data.title ? data.title : "",
				description: data && data.description ? data.description : "",
				widgets: updatedWidgetsData,
				layoutConfig: updatedLayoutData,
			}),
		})
			.then((response) => {
				if (response.ok) {
					console.log("Successfully updated dashboard");
				} else {
					console.error("Failed to update dashboard");
				}
			})

			.catch((error) => {
				console.error("Error updating dashboard:", error);
			});
	};

	const handleEditMode = () => {
		setIsLayoutDisabled(!isLayoutDisabled);
	};

	const handleSaveDashboard = () => {
		//update dashboard
		updateDashboard(widgetsData, layout);
		setIsLayoutDisabled(!isLayoutDisabled);
	};

	const handleOk = async () => {
		try {
			const newWidgetId = uuidv4();
			const newWidgetLayout = {
				i: newWidgetId,
				x: layout.find((item) => item.i === "Add Widget")?.x || 0,
				y: layout.find((item) => item.i === "Add Widget")?.y || 0,
				w: 4,
				h: 10,
			};
			if (formItem.component.includes("Assigned")) {
				newWidgetLayout.h = 4; // height 4 For Assigned Component
			}

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/dashboard/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						title: (data as any)?.title,
						description: (data as any)?.description,
						widgets: [
							...(widgetsData || []),
							{
								id: newWidgetId,
								title: formItem.title,
								description: formItem.description,
								type: formItem.type,
								component: formItem.component,
								size: "small",
								createdAt: new Date().toISOString(),
							},
						],
						layoutConfig: layout.map((item) =>
							item.i === "Add Widget" ? newWidgetLayout : item
						),
					}),
				}
			);

			if (response.ok) {
				setModalVisible(false);
				setIsLayoutDisabled(false);
				const result = await response.json();
				setData(result);
				fetchData();
				//setData(response.json());
			} else {
				console.error("Failed to add item to database");
			}
		} catch (error) {
			console.error("Error adding item:", error);
		}
	};

	const handleDelete = async (widgetId: string) => {
		try {
			const updateWidgetsData = widgetsData.filter(
				(widget) => widget.id !== widgetId
			);
			setWidgetsData(updateWidgetsData);
			console.log("widgetsdata", widgetsData);
			const updatedLayout = layout.filter((item) => item.i !== widgetId);
			setLayout(updatedLayout);
			updateDashboard(updateWidgetsData, updatedLayout);
			// const response = await fetch(
			// 	`${process.env.REACT_APP_API_URL}/dashboard/${id}/widgets/${widgetId}`,
			// 	{
			// 		method: "DELETE",
			// 		headers: {
			// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			// 		},
			// 	}
			// );
			// if (response.ok) {
			// 	console.log("Widget deleted successfully");
			// 	updateDashboard();
			// } else {
			// 	console.error("Failed to delete widget");
			// }
		} catch (error) {
			console.error("Error deleting widget:", error);
		}
	};

	const handleResize = async (widgetId: string, size: string) => {
		const newSize = getSizeConfig(size);
		const newLayout = layout.map((item) => {
			if (item.i === widgetId) {
				return { ...item, ...newSize };
			}
			return item;
		});
		setLayout(newLayout);

		const widgetIndex = widgetsData.findIndex(
			(widget) => widget.id === widgetId
		);
		if (widgetIndex !== -1) {
			const updatedWidgetsData = [...widgetsData];
			updatedWidgetsData[widgetIndex].size = size;

			setWidgetsData(updatedWidgetsData);

			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/dashboard/${id}/widgets/${widgetId}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							size,
						}),
					}
				);

				if (!response.ok) {
					console.error("Failed to update widget size");
				}
			} catch (error) {
				console.error("Error updating widget size:", error);
			}
		} else {
			console.error("Widget not found in widgetsData array");
		}
	};

	const getSizeConfig = (size: string) => {
		switch (size) {
			case "small":
				return { w: 4, h: 10 };
			case "medium":
				return { w: 8, h: 10 };
			case "large":
				return { w: 12, h: 10 };
			default:
				return { w: 4, h: 10 };
		}
	};

	return (
		<div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Flex align="center" gap={16}>
					<Button
						shape="circle"
						icon={<ArrowLeftOutlined />}
						size="large"
						onClick={() => navigate("/")}
						style={{
							marginLeft: "1rem",
							color: "#0444BF",
							borderColor: "#0444BF",
						}}
					/>

					<Flex vertical>
						<Breadcrumb>
							<Breadcrumb.Item>.</Breadcrumb.Item>
						</Breadcrumb>
						<Title
							level={5}
							style={{
								margin: 0,
								textAlign: "left",
								fontFamily: "Poppins",
							}}
						>
							{dashboardTitle}
						</Title>
					</Flex>
				</Flex>
				<div style={{ display: "flex", alignItems: "center" }}>
					{!isLayoutDisabled && (
						<div>
							<Button type="primary" onClick={addGrid}>
								Add Widget
							</Button>
						</div>
					)}
					<div style={{ marginLeft: "auto" }}>
						{isLayoutDisabled ? (
							<Button type="primary" onClick={handleEditMode}>
								Edit Dashboard
							</Button>
						) : (
							<Button type="primary" onClick={handleSaveDashboard}>
								Save Dashboard
							</Button>
						)}
					</div>
				</div>
			</div>
			<div style={{ height: "70vh", overflowY: "auto" }}>
				<GridLayout
					className="layout"
					layout={layout}
					cols={12}
					rowHeight={30}
					width={1200}
					isDraggable={!isLayoutDisabled}
					// isResizable={!isLayoutDisabled}
					isResizable={false}
					onLayoutChange={onLayoutChange}
					draggableCancel=".no-drag"
				>
					{layout.map((layoutItem: { i: Key | null | undefined }) => {
						const widget = (data as any)?.widgets.find(
							(item: { id: string }) => item.id === layoutItem.i
						);
						return (
							<div key={layoutItem.i}>
								<Card
									title={
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												width: "100%",
											}}
										>
											<span
												style={{
													marginTop: "4px",
													marginRight: "15px",
													fontFamily: "Poppins",
													fontWeight: "bold",
													fontSize: "1.2rem",
												}}
											>
												{widget?.title}
											</span>
											{!isLayoutDisabled && widget && (
												<div
													style={{ position: "absolute", top: 12, right: 8 }}
												>
													<ConfigProvider
														theme={{
															components: {
																Dropdown: {
																	borderRadiusLG: 9,
																},
															},
														}}
													>
														<Dropdown
															overlay={
																<Menu>
																	<Menu.Item
																		className="no-drag"
																		onClick={() => handleEditClick(widget?.id)}
																		key="edit"
																	>
																		Edit Widget
																	</Menu.Item>
																	<Menu.SubMenu
																		key="resize"
																		title="Resize"
																		className="no-drag"
																		style={{ border: "none" }}
																	>
																		<Menu.Item
																			key="small"
																			className="no-drag"
																			onClick={() =>
																				handleResize(widget?.id, "small")
																			}
																		>
																			Small
																		</Menu.Item>
																		<Menu.Item
																			key="medium"
																			className="no-drag"
																			onClick={() =>
																				handleResize(widget?.id, "medium")
																			}
																		>
																			Medium
																		</Menu.Item>
																		<Menu.Item
																			key="large"
																			className="no-drag"
																			onClick={() =>
																				handleResize(widget?.id, "large")
																			}
																		>
																			Large
																		</Menu.Item>
																	</Menu.SubMenu>
																</Menu>
															}
															placement="bottomRight"
															//arrow
															className="no-border"
														>
															<span
																style={{ padding: "4px", cursor: "pointer" }}
															>
																<SettingOutlined
																	className="no-drag"
																	style={{ border: "none", color: "#0004bf" }}
																/>
															</span>
														</Dropdown>
													</ConfigProvider>
													<Tooltip placement="top" title="Delete">
														<Button
															type="text"
															icon={
																<DeleteOutlined
																	className="no-drag"
																	style={{ border: "none", color: "#0004bf" }}
																/>
															}
															onClick={() => handleDelete(widget?.id)}
														/>
													</Tooltip>
													<EditWidgetModal
														visible={editmodalVisible}
														onCancel={handleEditCancel}
														widgetId={selectedWidgetId}
														fetchData={fetchData}
													/>
												</div>
											)}
										</div>
									}
								>
									{widget && (
										<div>
											<DynamicContent componentName={widget.component} />
										</div>
									)}
									{!isLayoutDisabled && !widget && (
										<PlusOutlined
											className="no-drag"
											onClick={() => {
												console.log("plus clicked");
												setFormItem({
													title: "",
													description: "",
													type: "",
													component: "",
												});
												setModalVisible(true);
											}}
											// style={{ margin: "130px" }}
										/>
									)}
								</Card>
							</div>
						);
					})}
				</GridLayout>
			</div>
			<Modal
				title="Add Widget"
				visible={modalVisible}
				onOk={handleOk}
				onCancel={() => setModalVisible(false)}
			>
				<Form
					layout="horizontal"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
				>
					<Form.Item label="Title">
						<Input
							value={formItem.title}
							onChange={(e) =>
								setFormItem({ ...formItem, title: e.target.value })
							}
						/>
					</Form.Item>
					<Form.Item label="Description">
						<Input.TextArea
							value={formItem.description}
							onChange={(e) =>
								setFormItem({ ...formItem, description: e.target.value })
							}
						/>
					</Form.Item>
					<Form.Item label="Type">
						<Select
							value={formItem.type}
							onChange={(value) => setFormItem({ ...formItem, type: value })}
							style={{ width: "100%" }}
						>
							<Option value="text">Text</Option>
							<Option value="number">Number</Option>
							<Option value="date">Date</Option>
						</Select>
					</Form.Item>
					<Form.Item label="Component">
						<Select
							value={formItem.component}
							onChange={(value) =>
								setFormItem({ ...formItem, component: value })
							}
							style={{ width: "100%" }}
						>
							<Option value="PieChart">PieChart</Option>
							<Option value="BarChart">BarChart</Option>
							<Option value="AssignedTask">Assigned Task</Option>
							<Option value="AssignedBug">Assigned Bug</Option>
							<Option value="AssignedUserStory">Assigned User Story</Option>
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default NewDashboard;
