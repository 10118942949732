/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Flex, Image, Layout } from "antd";
import SettingsMenu from "./SettingsMenu";

import { Outlet } from "react-router-dom";
//import AppNavbar from "../../layout/AppNavbar";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../../util/ability";
import AppHeader from "../../layout/AppHeader";
import { Content } from "antd/es/layout/layout";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

const { Sider } = Layout;

function SettingsLayout() {
	const [abilities, setAbilities] = useState(ability);
	return (
		<AbilityContext.Provider value={abilities}>
			<Can I="SETTINGS_CAN_VIEW" a="SETTINGS_CAN_VIEW" passThrough>
				{(allowed) =>
					allowed ? (
						<Layout style={{ minHeight: "100vh", backgroundColor: "#FAFAFF" }}>
							{/* <Header
					style={{
						padding: 0,
						marginLeft: 0,
						position: "fixed",
						width: "100%",
						zIndex: 1,
					}}
				>
					<NavigationBar />
				</Header> */}
							{/* <Sider
								style={{
									// background: "#03045e",
									// color: "white",
									borderRadius: "20px",
									paddingTop: ".5vh",
									zIndex: "2",
								}}
							> */}
							<SettingsMenu />
							{/* </Sider> */}

							<Layout
								style={{
									backgroundColor: "#FAFAFF",
									// /paddingLeft: "36px",
								}}
							>
								<Content
									style={{
										height: "90vh",
										overflow: "hidden",
										paddingTop: ".2rem",
									}}
								>
									<div>
										<AppHeader />
									</div>
									{/* <AppNavbar
										breadcrumbs={["Settings"]}
										menuType="settings"
										//leftTitle="Role Management"
										//middleTitle="ijnjijnuj"
										reviewEnabled={false}
										onYesClick={() => {}}
									/> */}
									<Outlet />
								</Content>
							</Layout>
						</Layout>
					) : (
						<Flex style={{ marginTop: "15vh" }} justify="center">
							<Image
								height={"50vh"}
								width={"60vw"}
								src={
									"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
								}
								preview={false}
							/>
						</Flex>
					)
				}
			</Can>
		</AbilityContext.Provider>
	);
}

export default SettingsLayout;
