import { useEffect, useState } from "react";
import { Avatar, ConfigProvider, Flex, Menu, Typography } from "antd";
import {
	//Link,
	useLocation,
	useNavigate,
} from "react-router-dom";
import "./SettingsMenu.css";
import usePlatformStore from "../../stores/platformStore";
import Constants from "../../util/ConstantsUtil";
import Sider from "antd/es/layout/Sider";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
	AuditOutlined,
	BankFilled,
	ContactsFilled,
	ControlFilled,
	LeftOutlined,
	RightOutlined,
} from "@ant-design/icons";
import { motion, useAnimation } from "framer-motion";
function SettingsMenu() {
	// const {
	// 	token: { colorPrimaryBg },
	// } = theme.useToken();

	const navigate = useNavigate();
	const location = useLocation();
	//const [selectedMenuItem, setSelectedMenuItem] = useState("TagManagement");
	// useEffect(() => {
	// 	setNextNavigateUrl("");
	// 	setBackNavigateUrl("/");
	// 	setAppBarTitle(Constants.SETTINGS_TAG_MANAGEMENT);
	// 	setCurrentModule(Constants.SETTINGS);
	// 	setCurrentPage(Constants.SETTINGS_TAG_MANAGEMENT);
	// }, []);
	// const handleMenuClick = (e: any) => {
	// 	setSelectedMenuItem(e.key);
	// 	if (e.key === "RoleManagement") {
	// 		setAppBarTitle(Constants.SETTINGS_ROLE_MANAGEMENT);
	// 		setCurrentPage(Constants.SETTINGS_ROLE_MANAGEMENT);
	// 	} else if (e.key === "UserManagement") {
	// 		setAppBarTitle(Constants.SETTINGS_USER_MANAGEMENT);
	// 		setCurrentPage(Constants.SETTINGS_USER_MANAGEMENT);
	// 	} else if (e.key === "WorkspaceManagement") {
	// 		setAppBarTitle(Constants.SETTINGS_WORKSPACE_MANAGEMENT);
	// 		setCurrentPage(Constants.SETTINGS_WORKSPACE_MANAGEMENT);
	// 	} else if (e.key === "TagManagement") {
	// 		setAppBarTitle(Constants.SETTINGS_TAG_MANAGEMENT);
	// 		setCurrentPage(Constants.SETTINGS_TAG_MANAGEMENT);
	// 	}
	// };

	const [selectedMenuItem, setSelectedMenuItem] = useState(() => {
		switch (location.pathname) {
			case "/settings/user":
				return "UserManagement";
			case "/settings/workspace":
				return "WorkspaceManagement";
			case "/settings/account":
				return "AccountManagement";
			default:
				return "RoleManagement";
		}
	});

	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
	}));

	useEffect(() => {
		setNextNavigateUrl("");
		setBackNavigateUrl("/");
		setCurrentModule(Constants.SETTINGS);

		switch (location.pathname) {
			case "/settings/user":
				setAppBarTitle(Constants.SETTINGS_USER_MANAGEMENT);
				setCurrentPage(Constants.SETTINGS_USER_MANAGEMENT);
				setSelectedMenuItem("UserManagement");
				break;
			case "/settings/workspace":
				setAppBarTitle(Constants.SETTINGS_WORKSPACE_MANAGEMENT);
				setCurrentPage(Constants.SETTINGS_WORKSPACE_MANAGEMENT);
				setSelectedMenuItem("WorkspaceManagement");
				break;
			case "/settings/account":
				setAppBarTitle(Constants.SETTINGS_ACCOUNT_MANAGEMENT);
				setCurrentPage(Constants.SETTINGS_ACCOUNT_MANAGEMENT);
				setSelectedMenuItem("AccountManagement");
				break;
			default:
				setAppBarTitle(Constants.SETTINGS_ROLE_MANAGEMENT);
				setCurrentPage(Constants.SETTINGS_ROLE_MANAGEMENT);
				setSelectedMenuItem("RoleManagement");
				break;
		}
	}, [location.pathname]);

	const handleMenuClick = (e: any) => {
		setSelectedMenuItem(e.key);
		switch (e.key) {
			case "UserManagement":
				setAppBarTitle(Constants.SETTINGS_USER_MANAGEMENT);
				setCurrentPage(Constants.SETTINGS_USER_MANAGEMENT);
				break;
			case "WorkspaceManagement":
				setAppBarTitle(Constants.SETTINGS_WORKSPACE_MANAGEMENT);
				setCurrentPage(Constants.SETTINGS_WORKSPACE_MANAGEMENT);
				break;
			case "AccountManagement":
				setAppBarTitle(Constants.SETTINGS_ACCOUNT_MANAGEMENT);
				setCurrentPage(Constants.SETTINGS_ACCOUNT_MANAGEMENT);
				break;
			default:
				setAppBarTitle(Constants.SETTINGS_ROLE_MANAGEMENT);
				setCurrentPage(Constants.SETTINGS_ROLE_MANAGEMENT);
				break;
		}
	};

	const [collapsed, setCollapsed] = useState(false);
	const screens = useBreakpoint();
	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		if (screens.lg) {
			setCollapsed(false);
		} else {
			setCollapsed(true);
		}
	}, [screens]);

	const renderArrowButton = () => {
		if (!screens.xs) {
			return (
				<div
					style={{
						position: "absolute",
						bottom: "20px",
						left: "15px",
						cursor: "pointer",
						backgroundColor: "white",
						color: "#0444BF",
						borderRadius: "5px",
						width: "50px",
						height: "50px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
					}}
					onClick={toggleCollapsed}
				>
					<motion.div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						animate={{ rotate: collapsed ? -180 : 180 }}
					>
						{collapsed ? (
							<RightOutlined style={{ transformOrigin: "center" }} />
						) : (
							<LeftOutlined style={{ transformOrigin: "center" }} />
						)}
					</motion.div>
				</div>
			);
		}
		return null; // Return null if xs screen
	};

	const controls = useAnimation();
	useEffect(() => {
		controls.start({ width: collapsed ? 80 : 250 });
	}, [collapsed, controls]);

	return (
		<motion.div
			style={{ height: "100%" }}
			animate={controls}
			transition={{ duration: 0.5 }}
		>
			<Sider
				width={250}
				style={{
					background: "#0444BF",
					// color: "white",
					borderRadius: "0px 20px 20px 0px",
					paddingTop: ".5vh",
					zIndex: "2",
					height: "100vh",
				}}
				collapsed={collapsed}
				breakpoint="lg"
			>
				<div
					className="logo"
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "left",
						marginLeft: "-1vw",
						//marginBottom: "1vh",
					}}
					//onClick={() => navigate("/space")}
				>
					<Flex
						//onClick={() => navigate("/space")}
						className="logo"
					>
						{/* <Image
						style={{ marginTop: "4px" }}
						width={50}
						
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
					/> */}
						{/* <Avatar
					shape="circle"
					style={{ backgroundColor: "white", margin: "20px 0px 15px 10px" }}
					size={{ xs: 24, sm: 32, md: 32, lg: 32, xl: 32, xxl: 32 }}
					src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
				/> */}
						<Avatar
							//shape="circle"
							shape="square"
							style={{
								backgroundColor: "#0444BF",
								margin: "22px 0px 15px 3px",
								width: "auto",
								height: "3vh",
							}}
							size={{ xs: 24, sm: 32, md: 32, lg: 32, xl: 32, xxl: 32 }}
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi-white-logo.svg"
						/>
						{!collapsed && (
							<Typography.Title
								style={{
									marginTop: "23px",
									marginLeft: "10px",
									color: "white",
									fontSize: "26px",
									fontFamily: "Poppins",
								}}
							>
								Alchemi
							</Typography.Title>
						)}
					</Flex>
				</div>
				<ConfigProvider
					theme={{
						components: {
							Menu: {
								colorBgContainer: "#0444BF",
								itemBg: "#0444BF",
								subMenuItemBg: "#0444BF",
								popupBg: "#0444BF",

								colorText: "#ffffff",
							},
						},
					}}
				>
					<Menu
						style={{
							paddingTop: "2rem",
							fontFamily: "Poppins",
							marginLeft: "12px",
							width: collapsed ? 55 : 228,
						}}
						defaultSelectedKeys={[selectedMenuItem]}
						mode="inline"
						onClick={handleMenuClick}
					>
						<Menu.ItemGroup
							title={
								<span
									className="ant-menu-light"
									style={{
										marginLeft: collapsed ? "-12px" : "-2px",
										display: "flex",
									}}
								>
									<LeftOutlined
										onClick={() => navigate("/space")}
										style={{
											color: "white",
											fontSize: "16px",
											cursor: "pointer",
										}}
									/>
									<p
										style={{
											color: "white",
											marginTop: collapsed ? "-3px" : 0,
										}}
									>
										{" "}
										{collapsed ? "Works.." : "Workspace"}
									</p>
								</span>
							}
						></Menu.ItemGroup>
						<motion.div
							whileTap={{ scale: 0.8 }}
							whileHover={{
								backgroundColor: "#0540B2",
								color: "#fff",
								borderRadius: "20px",
							}}
							style={{
								display: "flex",
								justifyContent: collapsed ? "center" : "flex-start",
							}}
						>
							<Menu.Item
								key="RoleManagement"
								icon={
									<ControlFilled
										style={{
											marginLeft: collapsed ? "-12px" : "1vw",
											marginRight: collapsed ? "30px" : "auto",
										}}
									/>
								}
								onClick={() => {
									navigate(`/settings/role`);
									setSelectedMenuItem("RoleManagement");
								}}
								style={{
									background:
										selectedMenuItem === "RoleManagement"
											? "white"
											: "transparent",
									color:
										selectedMenuItem === "RoleManagement"
											? "#0444bf"
											: "inherit",
									height: 35,
									borderRadius: "6px",
									width: collapsed ? 48 : 218,
									lineHeight: "35px",
									marginTop: "1vh",
								}}
							>
								Roles Management
							</Menu.Item>
						</motion.div>
						<motion.div
							whileTap={{ scale: 0.8 }}
							whileHover={{
								backgroundColor: "#0540B2",
								color: "#fff",
								borderRadius: "20px",
							}}
							style={{
								display: "flex",
								justifyContent: collapsed ? "center" : "flex-start",
							}}
						>
							<Menu.Item
								key="UserManagement"
								icon={
									<ContactsFilled
										style={{
											marginLeft: collapsed ? "-12px" : "1vw",
											marginRight: collapsed ? "30px" : "auto",
										}}
									/>
								}
								onClick={() => {
									navigate(`/settings/user`);
									setSelectedMenuItem("UserManagement");
								}}
								style={{
									background:
										selectedMenuItem === "UserManagement"
											? "white"
											: "transparent",
									color:
										selectedMenuItem === "UserManagement"
											? "#0444bf"
											: "inherit",
									height: 35,
									borderRadius: "6px",
									width: collapsed ? 48 : 218,
									lineHeight: "35px",
								}}
							>
								User Management
							</Menu.Item>
						</motion.div>
						<motion.div
							whileTap={{ scale: 0.8 }}
							whileHover={{
								backgroundColor: "#0540B2",
								color: "#fff",
								borderRadius: "20px",
							}}
							style={{
								display: "flex",
								justifyContent: collapsed ? "center" : "flex-start",
							}}
						>
							<Menu.Item
								key="WorkspaceManagement"
								icon={
									<BankFilled
										style={{
											marginLeft: collapsed ? "-12px" : "1vw",
											marginRight: collapsed ? "30px" : "auto",
										}}
									/>
								}
								onClick={() => {
									navigate(`/settings/workspace`);
									setSelectedMenuItem("WorkspaceManagement");
								}}
								style={{
									background:
										selectedMenuItem === "WorkspaceManagement"
											? "white"
											: "transparent",
									color:
										selectedMenuItem === "WorkspaceManagement"
											? "#0444bf"
											: "inherit",
									height: 35,
									borderRadius: "6px",
									width: collapsed ? 48 : 218,
									lineHeight: "35px",
								}}
							>
								Workspace Management
							</Menu.Item>
						</motion.div>
						<motion.div
							whileTap={{ scale: 0.8 }}
							whileHover={{
								backgroundColor: "#0540B2",
								color: "#fff",
								borderRadius: "20px",
							}}
							style={{
								display: "flex",
								justifyContent: collapsed ? "center" : "flex-start",
							}}
						>
							<Menu.Item
								key="AccountManagement"
								icon={
									<AuditOutlined
										style={{
											marginLeft: collapsed ? "-12px" : "1vw",
											marginRight: collapsed ? "30px" : "auto",
										}}
									/>
								}
								onClick={() => {
									navigate(`/settings/account`);
									setSelectedMenuItem("AccountManagement");
								}}
								style={{
									background:
										selectedMenuItem === "AccountManagement"
											? "white"
											: "transparent",
									color:
										selectedMenuItem === "AccountManagement"
											? "#0444bf"
											: "inherit",
									height: 35,
									borderRadius: "6px",
									width: collapsed ? 48 : 218,
									lineHeight: "35px",
								}}
							>
								Account Management
							</Menu.Item>
						</motion.div>
						{/* <Menu.Item key="RoleManagement" icon={<ControlFilled />}>
						<Link to="/settings/role">Role Management</Link>
					</Menu.Item>
					<Menu.Item key="UserManagement" icon={<ContactsFilled />}>
						<Link to="/settings/user">User Management</Link>
					</Menu.Item>
					<Menu.Item key="WorkspaceManagement" icon={<BankFilled />}>
						<Link to="/settings/workspace">Workspace Management</Link>
					</Menu.Item>
					<Menu.Item key="AccountManagement" icon={<AuditOutlined />}>
						<Link to="/settings/account">Account Management</Link>
					</Menu.Item> */}
						{/* <Menu.Item key="TagManagement" className="leftmenu-item">
					<Link to="/settings/tag">Tag Management</Link>
				</Menu.Item> */}

						{/* <Menu.Item key="Subscription" className="leftmenu-item">
					Subscription
				</Menu.Item>
				<Menu.Item key="APISettings" className="leftmenu-item">
					API Settings
				</Menu.Item>
				<Menu.Item key="NotificationsSettings" className="leftmenu-item">
					Notifications Settings
				</Menu.Item> */}
					</Menu>
				</ConfigProvider>
				{/* <Tabs
				defaultActiveKey="1"
				type="card"
				size="middle"
				tabPosition="left"
				style={{
					margin: "2rem 0 0 1.7rem",
				}}
				// items={new Array(3).fill(null).map((_, i) => {
				// 	const id = String(i + 1);
				// 	return {
				// 		label: `Card Tab ${id}`,
				// 		key: id,
				// 		// children: `Content of card tab ${id}`,
				// 	};
				// })}
				items={[
					{
						label: "Role Management",
						key: "RoleManagement",
					},
					{
						label: "User Management",
						key: "UserManagement",
					},
					{
						label: "Subscription",
						key: "Subscription",
					},
					{
						label: "API Settings",
						key: "APISettings",
					},
					{
						label: "Notifications Settings",
						key: "NotificationsSettings",
					},
				]}
			/> */}
				{/* </Sider> */}
				{renderArrowButton()}
			</Sider>
		</motion.div>
	);
}

export default SettingsMenu;
