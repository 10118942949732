import React, { useState } from "react";
import {
	Form,
	Input,
	Layout,
	Card,
	Flex,
	Button,
	Typography,
	message,
	ColorPicker,
	ColorPickerProps,
	GetProp,
} from "antd";

type Color = GetProp<ColorPickerProps, "value">;
import "./AddRole.css";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";

const { Text } = Typography;

const AddTag: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const formItemLayout = { labelCol: { span: 2 }, wrapperCol: { span: 14 } };
	const [tagName, setTagName] = useState<string>("");
	const [category, setCategory] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [color, setColor] = useState<Color>("#1677ff");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const handleButtonClick = async () => {
		const name = tagName;
		console.log("Tag name:", name);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({
					name: tagName,
					category,
					description,
					color: color,
				}),
			});
			if (response.ok) {
				const responseData = await response.json();
				console.log("Response from the backend:", responseData);
				message.success("Tag added successfully");
				navigate("/fd/workspace/settings/tag");
			} else if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				const errorData = await response.json();
				message.error(`Error adding tag. Please try again.${errorData}`);
			}
		} catch (error: any) {
			message.error(`Error adding tag. Please try again: ${error.message}`);
		}
	};

	console.log("test", color);

	const handleCancelClick = () => {
		navigate("/fd/workspace/settings/tag");
	};

	return (
		<Layout>
			<Card className="fixedCard-settings">
				<h2 className="titleCardSettings">Add Tag</h2>

				<Form
					form={form}
					onFinish={handleButtonClick}
					{...formItemLayout}
					layout="horizontal"
					colon={false}
				>
					<Flex>
						<Text>Tag Name</Text>
					</Flex>

					<Form.Item
						className="titleTypoAddTag"
						name="tagName"
						rules={[
							{
								required: true,
								message: "Please enter tag name",
							},
						]}
					>
						<Input
							className="formInputUser"
							placeholder="Enter tag name"
							value={tagName}
							onChange={(event: {
								target: { value: React.SetStateAction<string> };
							}) => setTagName(event.target.value)}
						/>
					</Form.Item>
					<Flex>
						<Text>Category</Text>
					</Flex>

					<Form.Item
						className="titleTypoAddTag"
						name="category"
						rules={[
							{
								required: true,
								message: "Please enter tag category",
							},
						]}
					>
						<Input
							className="formInputUser"
							placeholder="Enter tag category"
							value={category}
							onChange={(event: {
								target: { value: React.SetStateAction<string> };
							}) => setCategory(event.target.value)}
						/>
					</Form.Item>

					<Flex>
						<Text>Description</Text>
					</Flex>

					<Form.Item
						className="titleTypoAddTag"
						name="description"
						rules={[
							{
								required: true,
								message: "Please enter tag description",
							},
						]}
					>
						<Input.TextArea
							className="formInputUser"
							placeholder="Enter tag description"
							value={description}
							onChange={(event: {
								target: { value: React.SetStateAction<string> };
							}) => setDescription(event.target.value)}
						/>
					</Form.Item>

					<Flex>
						<Text>Color</Text>
					</Flex>

					<Form.Item
						className="titleTypoAddTag"
						name="color"
						getValueFromEvent={(color) => {
							setColor("#" + color.toHex());
							return "#" + color.toHex();
						}}
						rules={[
							{
								required: false,
								message: "Please select tag color",
							},
						]}
					>
						<ColorPicker
							defaultValue="#1677ff"
							showText={(color) => (
								<span>---Color--- ({color.toHexString()})</span>
							)}
							getPopupContainer={undefined}
							autoAdjustOverflow={undefined}
							destroyTooltipOnHide={undefined}
						/>
					</Form.Item>
					<Flex style={{ paddingBottom: "16px" }} justify={"flex-end"}>
						<div className="buttons-container">
							<Button
								style={{ width: "5rem" }}
								onClick={handleCancelClick}
								data-testId="addTagTest"
							>
								Cancel
							</Button>
							<Button
								style={{ width: "5rem" }}
								// onClick={handleButtonClick}
								type="primary"
								htmlType="submit"
							>
								Save
							</Button>
						</div>
					</Flex>
				</Form>
			</Card>
		</Layout>
	);
};

export default AddTag;
