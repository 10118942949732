import create from "zustand";
interface RitualStore {
	startDate: Date;
	endDate: Date;
	timeInterval: string;
	reoccurringRitual: boolean;
	days: [];
	repetEvery: number;
	setStartDate: (value: Date) => void;
	setEndDate: (value: Date) => void;
	setTimeInterval: (value: string) => void;
	setReoccurringRitual: (value: boolean) => void;
	setDays: (value: []) => void;
	setRepeatEvery: (value: number) => void;
}

export const useRitualStore = create<RitualStore>((set) => ({
	days: [],
	startDate: new Date(),
	endDate: new Date(),
	timeInterval: "None",
	reoccurringRitual: false,
	repetEvery: 0,
	setStartDate: (value) => set({ startDate: value }),
	setEndDate: (value) => set({ endDate: value }),
	setTimeInterval: (value) => set({ timeInterval: value }),
	setReoccurringRitual: (value) => set({ reoccurringRitual: value }),
	setDays: (value) => set({ days: value }),
	setRepeatEvery: (value) => set({ repetEvery: value }),
}));
