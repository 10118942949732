/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Layout,
	Avatar,
	Button,
	Typography,
	Flex,
	Popover,
	Tooltip,
	Image,
	Input,
} from "antd";
import {
	UserOutlined,
	SettingOutlined,
	LogoutOutlined,
	BellOutlined,
	SearchOutlined,
	DownCircleOutlined,
	DownOutlined,
	CloseCircleOutlined,
	CloseOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import "./AppHeader.css";
import { msalInstance } from "..";
import { useState } from "react";
import Notification from "../pages/Notification";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
// import AccountStore, { AccountStoreType } from "../stores/AccountStore";
import usePlatformStore from "../stores/platformStore";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
import ValidateAuthenticationModel from "../component/ValidateAuthenticationModel";
import { useEventListener } from "ahooks";
import useWorkspaceStore from "../stores/WorkspaceState";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

//import AppNavbar from "./AppNavbar";

const { Header } = Layout;

const FullDialogHeader: React.FC = () => {
	// const {
	// 	token: { colorPrimaryBg },
	// } = theme.useToken();
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	// const accountStoreInstance = AccountStore() as AccountStoreType;
	const usePlatformStoreInstance = usePlatformStore() as any;
	const useWorkspaceStoreInstance = useWorkspaceStore() as any;
	const [abilities, setAbilities] = useState(ability);

	const handleSettingsClick = () => {
		navigate("/settings/role");
	};

	const onLogout = () => {
		// localStorage.clear();
		// console.log('User logged out');
		// navigate("/");
		userProfileStoreInstance.clearStore();
		// accountStoreInstance.clearStore();
		usePlatformStoreInstance.clearStore();
		useWorkspaceStoreInstance.clearStore();
		localStorage.clear();
		const logoutConfig = {
			account: msalInstance.getAllAccounts()[0],
			postLogoutRedirectUri: process.env.REACT_APP_AZURE_AD_LOGOUT_REDIRECT_URL,
		};
		try {
			msalInstance.logoutRedirect(logoutConfig).then(() => {
				navigate("/");
			});
		} catch (error) {
			console.log("Error during logout:", error);
		}
	};

	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const [openCommandDialog, setOpenCommandDialog] = useState(false);
	const [ctrlModeActive, setControlModeActive] = useState(false);

	// useEventListener("keydown", (ev) => {
	// 	if (ev.code !== null && ev.code === "KeyM" && ctrlModeActive)
	// 		setOpenCommandDialog(true);
	// 	else if (ev.code !== null && ev.code.startsWith("Control"))
	// 		setControlModeActive(true);
	// });
	// useEventListener("keyup", (ev) => {
	// 	if (ev.code !== null && ev.code.startsWith("Control"))
	// 		setControlModeActive(false);
	// });
	// useEventListener("keydown", (ev) => {});

	const showCommandDialog = () => {
		//setOpenCommandDialog(true);
		//navigate(`${usePlatformStoreInstance.backNavigateUrl}`);
		navigate(-1);
	};

	const handleSwitchWorkspace = () => {
		//navigate("/settings/workspace");
	};

	return (
		<>
			<AbilityContext.Provider value={abilities}>
				{/* <Header
				className="top-navbar flex"
				style={{
					background: colorPrimaryBg,
				}}
			>
				<Flex onClick={() => navigate("/")} className="logo">
					Alchemi
				</Flex>

				<div className="nav-items flex">
					<div style={{ display: "flex", float: "right", marginRight: "30px" }}>
						<SettingOutlined
							onClick={handleSettingsClick}
							style={{ fontSize: "19px", marginLeft: "20px" }}
						/>
					</div> */}

				{/* <div className="avatar">
					<Avatar
						src="https://res.cloudinary.com/dvdljupjx/image/upload/v1663236684/samples/people/kitchen-bar.jpg"
						icon={<UserOutlined />}
					/>
				
				</div> */}
				{/* <Layout>
						<Dropdown
							overlay={
								<Layout className="dropdown"> */}
				{/* <div className="dropdown-item" style={{ cursor: 'pointer' }} onClick={onLogout}><LogoutOutlined />Logout
									
								</div> */}
				{/* <Button
										className="dropdown-item"
										type="link"
										style={{ cursor: "pointer" }}
										onClick={onLogout}
									>
										<LogoutOutlined
											style={{ fontSize: "16px", color: "#000" }}
										/>
										<Typography.Text strong>Logout</Typography.Text>
									</Button>
								</Layout>
							}
							placement="bottomRight"
							arrow
						>
							<Avatar
								src="https://res.cloudinary.com/dvdljupjx/image/upload/v1663236684/samples/people/kitchen-bar.jpg"
								icon={<UserOutlined />}
							/> */}
				{/* <div className="user-name">John Doe</div> */}
				{/* </Dropdown>
					</Layout>
				</div>
			</Header> */}

				<Header
					style={{
						background: "#f5f8fe",
						height: "7vh",
						// width:'95vw',
						// boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.15)',
						// borderRadius:'.7rem'
					}}
				>
					<Flex justify="space-between" align="center">
						<Flex onClick={() => navigate("/")} className="logo">
							<Image
								style={{ marginTop: "4px" }}
								width={50}
								preview={false}
								src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
							/>
							<Typography.Title
								level={1}
								style={{
									marginLeft: "10px",
									color: "#0444bf",
									fontFamily: "Poppins",
								}}
							>
								Alchemi
							</Typography.Title>
						</Flex>
						{useWorkspaceStoreInstance.currentWorkspace && (
							<Flex gap={30}>
								<Tooltip placement="bottom" title="Close">
									<Button
										shape="circle"
										icon={<CloseOutlined />}
										size="large"
										onClick={showCommandDialog}
										//style={{ color: "black", borderColor: "black" }}
										style={{
											color: "#0444BF",
											borderColor: "#0444BF",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									/>
								</Tooltip>
							</Flex>
						)}
					</Flex>
					<ValidateAuthenticationModel />

					{/* <Divider style={{margin:'0px'}}/> */}
				</Header>
				{/* <AppNavbar
				breadcrumbs={["adfsdgf", "asdf", "ybybybg"]}
				leftTitle="njjhbuh"
				middleTitle="ijnjijnuj"
			/> */}
			</AbilityContext.Provider>
		</>
	);
};

export default FullDialogHeader;
