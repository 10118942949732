/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Flex,
	Popover,
	Row,
	Switch,
	Tag,
	Tooltip,
	Typography,
	// Modal,
	Badge,
	message,
	Modal,
	Form,
	Input,
	ColorPicker,

	//Avatar,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
// import prev from "../assets/back-btn.svg";
// import next from "../assets/next-btn.svg";
//import comment from "../assets/comment-icon.svg";
import { motion } from "framer-motion";
import ConformationModel from "../component/ConformationModel";
import WatingModel from "../component/WatingModel";
import {
	//ApartmentOutlined,
	AppstoreOutlined,
	ArrowLeftOutlined,
	ArrowRightOutlined,
	BulbOutlined,
	CloseOutlined,
	CodeOutlined,
	MessageOutlined,
	PieChartOutlined,
	StarOutlined,
	//MessageOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import usePlatformStore from "../stores/platformStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import Constants from "../util/ConstantsUtil";
import { navigateAssistObjForWorkspace } from "../util/NavigateAssistUtil";
import _ from "lodash";
import ReviewComments from "../pages/ReviewComments";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
import { defineAbility } from "@casl/ability";
import AddManagement from "../pages/AddManagementModel";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";

interface AppNavbarProps {
	breadcrumbs: string[];
	menuType: string;
	//leftTitle: string;
	//middleTitle: string;
	reviewEnabled: boolean;
	onYesClick: () => void;
}

function AppNavbar({
	breadcrumbs,
	menuType,
	reviewEnabled,
	onYesClick,
}: AppNavbarProps) {
	const navigate = useNavigate();
	const { id } = useParams();
	const [nav, setNav] = useState<Map<string, any>>();
	const [moduleArray, setModuleArray] = useState<string[]>();
	const [modalVisible, setModalVisible] = useState(false);
	const [openWatingModel, setOpenWatingModel] = useState<boolean>(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [abilities, setAbilities] = useState(ability);

	// useEffect(() => {
	// 	const initialAbilities = defineAbility((can) => {
	// 		const permissions = userProfileStoreInstance.permissions;
	// 		Object.entries(permissions).forEach(([subject, actions]) => {
	// 			can(actions, subject);
	// 		});
	// 	});
	// 	setAbilities(initialAbilities);
	// }, []);
	// const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const {
		setIsReviewEnabled,
		currentWorkspace,
		setCurrentWorkspace,
		reviewData,
		currentActivity,
		//currentState,
		// currentActivity,
		//currentState,
	} = useWorkspaceStore((state: any) => ({
		setIsReviewEnabled: state.setIsReviewEnabled,
		currentWorkspace: state.currentWorkspace,
		setCurrentWorkspace: state.setCurrentWorkspace,
		reviewData: state.reviewData,
		currentActivity: state.currentActivity,
		//currentState: state.currentState,
	}));

	const { backNavigateUrl, nextNavigateUrl, appBarTitle, currentModule } =
		usePlatformStore((state: any) => ({
			currentModule: state.currentModule,
			backNavigateUrl: state.backNavigateUrl,
			nextNavigateUrl: state.nextNavigateUrl,
			appBarTitle: state.appBarTitle,
		}));
	const getWorkspace = (workspaceId: string) => {
		fetch(`${process.env.REACT_APP_API_URL}/workspace/${workspaceId}`, {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setCurrentWorkspace(data);
				console.log("Success:", data);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const getNextSectionState = (currentState: any, currentSection: string) => {
		for (let i = 0; i < currentState?.length - 1; i++) {
			if (currentState[i].section == currentSection) {
				return currentState[i + 1].status;
			}
		}

		return;
	};

	const showModal = () => {
		const nextSectionState = getNextSectionState(
			currentWorkspace.currentState,
			currentWorkspace.currentSection
		);
		console.log("nextSectionState", nextSectionState);
		console.log("currentSection", currentWorkspace.currentSection);
		console.log("nextNavigateUrl", nextNavigateUrl);
		console.log(reviewData, "reviewData");
		if (appBarTitle == Constants.DE_USER_PERSONA) {
			navigate(nextNavigateUrl);
		} else if (appBarTitle == Constants.FE_FEATURE_LIST) {
			navigate(nextNavigateUrl);
		} else if (appBarTitle == Constants.DE_DATA_MODEL) {
			navigate("/");
		} else if (reviewData && reviewData.length > 0) {
			setModalVisible(true);
		} else {
			if (nextSectionState == Constants.ACTIVITY_STATUS_NOT_STARTED) {
				setModalVisible(true); // confirm model
			} else if (nextSectionState == Constants.ACTIVITY_STATUS_PROCESSING) {
				setOpenWatingModel(true); // waiting screen
			} else {
				navigate(nextNavigateUrl);
			}
		}
		//setModalVisible(true);
	};

	const getNextSectionAPIUrl = (
		currentSection: string,
		workspaceId: string
	) => {
		if (currentSection == Constants.FE_FEATURE_LIST) {
			return `${process.env.REACT_APP_API_URL}/describe/${workspaceId}/feature-specification`;
		} else if (currentSection == Constants.FE_FEATURE_SPECIFICATION) {
			return `${process.env.REACT_APP_API_URL}/describe/${workspaceId}/de-ux-spec`;
		} else if (currentSection == Constants.DE_USER_PERSONA) {
			return `${process.env.REACT_APP_API_URL}/describe/${workspaceId}/feature-specification`;
		} else if (currentSection == Constants.DE_INFORMATION_ARCHITECTURE) {
			return `${process.env.REACT_APP_API_URL}/describe/${workspaceId}/product-de-ddd-spec`;
		} else if (currentSection == Constants.DE_DOMAIN_DRIVEN_DESIGN) {
			return `${process.env.REACT_APP_API_URL}/describe/${workspaceId}/de-api-spec`;
		} else if (currentSection == Constants.DE_API_SPECIFICATION) {
			return `${process.env.REACT_APP_API_URL}/describe/${workspaceId}/de-data-schema`;
		} else if (currentSection == Constants.DE_DATA_MODEL) {
			return ``;
		} else {
			return "";
		}
		// need to correct the api rul
	};

	const prepareActivityForAPI = (activity: any) => {
		return activity.aiData;
	};

	const handleModalOk = () => {
		// submit current acitivity and navigate to waiting screen
		const APIUrl = getNextSectionAPIUrl(
			currentWorkspace.currentSection,
			currentWorkspace._id
		);
		//add loader

		const activityData = prepareActivityForAPI(currentActivity);

		fetch(APIUrl, {
			method: "POST",
			body: JSON.stringify(activityData),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw {
						status: response.status,
						message: response.statusText,
					};
				}
				return response.json();
			})
			.then((data) => {
				console.log("Success:", data);
				//remove  loader
				onYesClick();
				setModalVisible(false);
				navigate(nextNavigateUrl);
			})
			.catch((error) => {
				message.error(`Error: ${error.message}`);
				console.error("Error:", error);
			});
		// onYesClick();
		// setModalVisible(false);
		// navigate(nextNavigateUrl);
	};

	// navigate(nextNavigateUrl);

	const handleModalCancel = () => {
		setModalVisible(false);
	};

	useEffect(() => {
		if (appBarTitle && id && menuType === "workspace") getWorkspace(id);
	}, [id, menuType, appBarTitle]);
	const handleReview = (checked: boolean) => {
		console.log(`switch to ${checked}`);
		if (checked) {
			setIsReviewEnabled(true);
		} else {
			setIsReviewEnabled(false);
		}
	};

	useEffect(() => {
		const navMap = new Map();
		let moduleArray = [];
		let sections: any = [];
		for (let i = 0; i < navigateAssistObjForWorkspace.length; i++) {
			// Iterate through navigateAssistObjForWorkspace

			const module = navigateAssistObjForWorkspace[i].module;
			moduleArray.push(module);
			const section = navigateAssistObjForWorkspace[i].step;
			const url = navigateAssistObjForWorkspace[i].url;

			if (!navMap.has(module)) sections = [];
			sections.push({ section, url });

			navMap.set(module, sections);
			// Use module and sections as needed
		}
		setNav(navMap);

		moduleArray = _.uniq(moduleArray);
		setModuleArray([...moduleArray]);
		console.log(navMap);
		console.log(navMap.get("Feature Engineering"));
	}, []);

	const [open, setOpen] = useState(false);

	const hide = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};
	const cardStyle: React.CSSProperties = {
		width: "auto",
		height: "100%",
		padding: "1rem",
	};

	const [openDrawer, setOpenDrawer] = useState(false);
	const [badgeCount, setBadgeCount] = useState(0);

	const showDrawer = () => {
		setOpenDrawer(true);
	};

	// const variants = {
	// 	open: {
	// 		y: 0,
	// 		opacity: 1,
	// 		transition: {
	// 			y: { stiffness: 1000, velocity: -100 },
	// 		},
	// 	},
	// 	closed: {
	// 		y: 50,
	// 		opacity: 0,
	// 		transition: {
	// 			y: { stiffness: 1000 },
	// 		},
	// 	},
	// };

	return (
		<>
			<AbilityContext.Provider value={abilities}>
				{menuType === "workspace" && (
					<Flex
						justify="space-between"
						align="center"
						style={{
							padding: "20px 50px 20px 50px",
							margin: "4px 0 0px",
							backgroundColor: "#f5f8fe",
						}}
					>
						<Flex align="center" gap={16}>
							<Tooltip placement="bottom" title="Back">
								<Button
									shape="circle"
									icon={<ArrowLeftOutlined style={{ color: "#0444BF" }} />}
									size="large"
									onClick={() => {
										navigate(backNavigateUrl);
									}}
								/>
							</Tooltip>
							{/* <Button
						className="prev-btn"
						type="primary"
						size="large"
						style={{ backgroundColor: "#D9E4FA" }}
					>
						<img src={prev} style={{ height: 18 }}></img>
					</Button> */}
							<Flex vertical>
								<Breadcrumb>
									{breadcrumbs.map((item, key) => (
										<Breadcrumb.Item key={key}>
											<Tag color="#87d068" style={{ fontSize: "1.1rem" }}>
												{currentModule}
											</Tag>
										</Breadcrumb.Item>
									))}
									<Title level={4} style={{ margin: 0, textAlign: "left" }}>
										{appBarTitle}
									</Title>
								</Breadcrumb>
								{/* <Breadcrumb>
							<Breadcrumb.Item>Settings</Breadcrumb.Item>
							<Breadcrumb.Item>User</Breadcrumb.Item>
						</Breadcrumb> */}
							</Flex>
						</Flex>
						{/* <Flex align="center">
					<Title level={5}>{middleTitle}</Title>
				</Flex> */}
						<Flex align="center" gap={16}>
							<Popover
								placement="bottomRight"
								title={
									<Flex justify="flex-end">
										{/* <Typography.Title level={5}> Add a Comment </Typography.Title> */}
										<Button
											icon={<CloseOutlined />}
											size="small"
											shape="circle"
											onClick={() => hide()}
										></Button>
									</Flex>
								}
								content={
									<div style={{ height: "60vh" }}>
										<Row gutter={[16, 16]} style={{ width: "70vw" }}>
											{moduleArray &&
												moduleArray.map((module, i) => (
													<Col className="gutter-row" span={12} key={module}>
														<motion.div
															// variants={variants}
															initial={{ y: 50, opacity: 0 }}
															animate={{
																y: 0,
																opacity: 1,
															}}
															transition={{
																// type: "spring",
																duration: 0.7,
																delay: i * 0.2,
															}}
														>
															<Card
																hoverable
																style={cardStyle}
																bodyStyle={{ padding: 0, overflow: "hidden" }}
															>
																<Flex justify="space-between">
																	<Flex
																		vertical
																		align="flex-start"
																		gap="middle"
																		style={{ padding: 24 }}
																	>
																		{module ===
																			Constants.BUSINESS_ENGINEERING && (
																			<PieChartOutlined
																				style={{
																					fontSize: "2rem",
																					color: "#3478f6",
																				}}
																			/>
																		)}
																		{module ===
																			Constants.FEATURE_ENGINEERING && (
																			<StarOutlined
																				style={{
																					fontSize: "2rem",
																					color: "#3478f6",
																				}}
																			/>
																		)}
																		{module ===
																			Constants.DESIGN_ENGINEERING && (
																			<BulbOutlined
																				style={{
																					fontSize: "2rem",
																					color: "#3478f6",
																				}}
																			/>
																		)}
																		{module === Constants.CODE_ENGINEERING && (
																			<CodeOutlined
																				style={{
																					fontSize: "2rem",
																					color: "#3478f6",
																				}}
																			/>
																		)}
																		<Typography.Title level={5}>
																			{module}
																		</Typography.Title>
																		<Typography.Text
																			style={{ fontSize: "0.7rem" }}
																		>
																			Lorem ipsum dolor sit amet, consectetur
																			adipiscing elit, sed do eiusmod tempor
																			incididunt ut labore et dolore magna
																			aliqua.
																		</Typography.Text>
																	</Flex>
																	<Flex
																		vertical
																		align="flex-start"
																		gap={8}
																		style={{ padding: 6 }}
																	>
																		{nav &&
																			[...nav.get(module)]?.map((item: any) => (
																				<motion.div
																					key={item.section}
																					whileHover={{ scale: 1.1 }}
																				>
																					<Button
																						type="primary"
																						onClick={() => {
																							navigate(
																								item.url +
																									`${currentWorkspace._id}`
																							);
																							hide();
																						}}
																						icon={<ArrowRightOutlined />}
																						key={item.section}
																						size="small"
																						shape="round"
																						style={{ fontSize: "0.7rem" }}
																					>
																						{item.section}
																					</Button>
																				</motion.div>
																			))}
																	</Flex>
																</Flex>
															</Card>
														</motion.div>
													</Col>
												))}
										</Row>
									</div>
								}
								trigger="click"
								open={open}
								onOpenChange={handleOpenChange}
							>
								<Tooltip placement="bottom" title="Menu">
									<Button
										shape="round"
										icon={<AppstoreOutlined />}
										style={{ marginRight: "2rem" }}
									/>
								</Tooltip>
							</Popover>

							{/* <Button
							shape="round"
							icon={<ApartmentOutlined />}
							
							
						/> */}

							<Card
								style={{
									width: "auto",
									height: "8vh",
									backgroundColor: "#a2dbfa",
									padding: "0 1rem",
									borderRadius: 12,
									display: "flex",
									alignItems: "center",
								}}
							>
								{reviewEnabled && (
									<>
										<Badge
											count={badgeCount}
											showZero
											offset={[-30, 0]}
											color="blue"
										>
											<ReviewComments
												open={openDrawer}
												setOpen={setOpenDrawer}
												onCommentCountChange={(count) => setBadgeCount(count)}
											/>
											<Tooltip placement="bottom" title="Review comments">
												<Button
													onClick={showDrawer}
													icon={<MessageOutlined />}
													style={{ marginRight: "2rem" }}
												/>
											</Tooltip>
										</Badge>
										<Can
											I={`${currentModule
												.split(" ")[0]
												.toUpperCase()}_CAN_REVIEW`}
											a={`${currentModule
												.split(" ")[0]
												.toUpperCase()}_ENGINEERING_CAN_REVIEW`}
											passThrough
										>
											{(allowed) =>
												allowed ? (
													<Switch
														checkedChildren="Review mode"
														unCheckedChildren="Review mode"
														onChange={handleReview}
														style={{ marginLeft: "auto" }}
													/>
												) : (
													<Tooltip
														title="You don't have permission to give reviews."
														placement="bottom"
													>
														<Switch
															checkedChildren="Review mode"
															unCheckedChildren="Review mode"
															style={{ marginLeft: "auto" }}
															checked={false}
														/>
													</Tooltip>
												)
											}
										</Can>
									</>
								)}
							</Card>

							<Button
								shape="circle"
								icon={<ArrowRightOutlined />}
								size="large"
								onClick={showModal}
							/>
						</Flex>
					</Flex>
				)}

				{/* <Modal
					title="Confirmation"
					visible={modalVisible}
					onOk={handleModalOk}
					// centered
					onCancel={handleModalCancel}
				>
					<Typography.Text>
						Are you sure you want to navigate to the next step?
					</Typography.Text>
				</Modal> */}
				<ConformationModel
					isOpen={modalVisible}
					onOk={handleModalOk}
					onClose={handleModalCancel}
					commentCount={reviewData ? reviewData.length : 0}
				/>
				<WatingModel isopen={openWatingModel} comment={reviewData} />
				{menuType === "settings" && (
					<Flex
						justify="space-between"
						align="center"
						style={{
							padding: "20px 50px 20px 50px",
							margin: "4px 0 0px",
							//backgroundColor: "#FAFAFF",
							backgroundColor: "rgb(245, 248, 254)",
						}}
					>
						<Flex align="center" gap={16}>
							<Button
								shape="circle"
								icon={<ArrowLeftOutlined />}
								size="large"
								onClick={() => navigate("/")}
								style={{ color: "#0444BF", borderColor: "#0444BF" }}
							/>
							{/* <Button
						className="prev-btn"
						type="primary"
						size="large"
						style={{ backgroundColor: "#D9E4FA" }}
					>
						<img src={prev} style={{ height: 18 }}></img>
					</Button> */}
							<Flex vertical>
								<Breadcrumb>
									{/* {breadcrumbs.map((item, key) => ( */}
									<Breadcrumb.Item>{currentModule}</Breadcrumb.Item>
									{/* ))} */}
								</Breadcrumb>
								{/* <Breadcrumb>
							<Breadcrumb.Item>Settings</Breadcrumb.Item>
							<Breadcrumb.Item>User</Breadcrumb.Item>
						</Breadcrumb> */}
								<Title
									level={5}
									style={{
										margin: 0,
										textAlign: "left",
										fontFamily: "Poppins",
									}}
								>
									{appBarTitle}
								</Title>
							</Flex>
						</Flex>
						<AddManagement />
					</Flex>
				)}
			</AbilityContext.Provider>
		</>
	);
}

export default AppNavbar;
