import React from "react";
import { Card } from "antd"; // You can use any UI component from Ant Design that resembles your node
import { register } from "@antv/xflow";

const BASICFLOWNODE1 = "basic-flow-node1";
const BasicFlowNode1 = ({ text }: any) => {
	return <Card title={text} style={{ width: 200 }}></Card>;
};

const ports = {
	groups: {
		group3: {
			position: "bottom",
			attrs: {
				circle: { stroke: "#D06269", strokeWidth: 1, r: 4, magnet: true },
			},
		},
	},
	items: [{ id: "group3", group: "group3" }],
};

register({
	shape: BASICFLOWNODE1,
	component: BasicFlowNode1,
	effect: ["label"],
	ports: { ...ports },
});

export { BASICFLOWNODE1 };
