import React, { useState } from "react";
import { Modal, Input } from "antd";

interface AddChecklistItemModalProps {
	visible: boolean;
	onCreate: (value: string) => void;
	onCancel: () => void;
}

const AddChecklistItemModal: React.FC<AddChecklistItemModalProps> = ({
	visible,
	onCreate,
	onCancel,
}) => {
	const [value, setValue] = useState("");

	const handleChange = (e: any) => {
		setValue(e.target.value);
	};

	const handleOk = () => {
		onCreate(value);
		setValue(""); // Clear input value
	};

	return (
		<Modal
			open={visible}
			title="Add Checklist Item"
			okText="Add"
			onCancel={onCancel}
			onOk={handleOk}
		>
			<Input
				placeholder="Enter checklist item"
				value={value}
				onChange={handleChange}
			/>
		</Modal>
	);
};

export default AddChecklistItemModal;
