import { Card } from "antd";
import { Node, register } from "@antv/xflow";
// import CustomCard from "./CardComponet";
import useWorkspaceStore from "../../../stores/WorkspaceState";

const CUSTOMECHILDNODE = "custome-child-node";
const ChildNode = ({ node }: { node: Node }) => {
	const { setDddSpecOnclick } = useWorkspaceStore((state: any) => ({
		setDddSpecOnclick: state.setDddSpecOnclick,
	}));

	const data = node.getData();

	const handleNodeClick = () => {
		setDddSpecOnclick(data.data);
	};

	return (
		<div>
			<Card
				onClick={handleNodeClick}
				style={{
					width: `${data.page.length}rem`, // 100 * 10 + 20,
					height: "fit-content",
					borderRadius: 10,
					borderColor: "rgba(0, 0, 0, 0.1)",
					backgroundColor: "white",
					filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))",
				}}
			>
				<p style={{ textAlign: "center", fontWeight: "bold" }}>{data.page}</p>
			</Card>
		</div>
	);
};

const ports1 = {
	groups: {
		group3: {
			position: "bottom",
			attrs: {
				circle: { stroke: "#D06269", strokeWidth: 1, r: 4, magnet: true },
			},
		},
		group1: {
			position: "left",
			attrs: {
				circle: { stroke: "#D06269", strokeWidth: 1, r: 4, magnet: true },
			},
		},
	},
	items: [
		{ id: "group3", group: "group3" },
		{ id: "group1", group: "group1" },
	],
};
register({
	shape: CUSTOMECHILDNODE,
	component: ChildNode,
	effect: ["label"],
	ports: ports1,
});
export { CUSTOMECHILDNODE };
