import React, { useState } from "react";
import "./PlanerHeader.css";
import {
	EditOutlined,
	//DownOutlined,
	SearchOutlined,
	//ArrowLeftOutlined,
} from "@ant-design/icons";
import {
	Divider,
	Flex,
	//Button,
	//Space,
	Input,
	//Tooltip,
	//Breadcrumb,
	//Dropdown,
	//Menu,
} from "antd";
// import WorkItemModal from "./AddWorkitemModel";
import { planerViewStore } from "../../stores/PlannerStore";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Can } from "../../layout/AppLayout";
import BreadCrumb from "../Editors/BreadCrumb";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
// import AddMemberModal from "./AddMember";

function TeamMemberNav() {
	const url = useLocation().pathname;
	console.log(url);
	// const [modalVisible, setModalVisible] = useState(false);
	const {
		input,
		setInput,
		//setSortBy,
		setInviteusermodel,
		Inviteusermodel,
	} = planerViewStore();

	// const sortItems = [
	// 	{
	// 		label: "Created date (Asc)",
	// 		key: "created-asc",
	// 	},
	// 	{
	// 		label: "Created date (Desc)",
	// 		key: "created-desc",
	// 	},
	// ];

	// const handleModalCancel = () => {
	// 	setModalVisible(false);
	// };

	// const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setInput(event.target.value);
	// };
	const handleAddMemberClick = () => {
		setInviteusermodel(!Inviteusermodel);
	};

	// const handleAddWorkItemClick = () => {
	// 	setModalVisible(true);
	// };

	// const handleSortByChange = ({ key }: { key: React.Key }) => {
	// 	const selectedItem = sortItems.find((item) => item.key === key);
	// 	if (selectedItem) {
	// 		setSortBy(selectedItem.label as any);
	// 	}
	// };

	const [isSearchExpanded, setIsSearchExpanded] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const handleSearchChange = (event: any) => {
		setInput(event.target.value);
		if (event.target.value === "") {
			setIsSearchExpanded(false);
		}
	};

	const handleSearchIconClick = () => {
		setIsSearchExpanded(true);
	};

	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		<div>
			<Divider className="ant-divider-horizontal" />
			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex className="breadcrumb" style={{ marginLeft: "2vw" }}>
						{/* <Space>
							<Tooltip placement="bottom" title="Back">
								<Button
									shape="circle"
									icon={<ArrowLeftOutlined />}
									size="large"
								/>
							</Tooltip> 
							<Flex vertical>
								<Breadcrumb>
									<Breadcrumb.Item>
										<h3
											color="#87d068"
											style={{ fontSize: "1.1rem", fontFamily: "Poppins" }}
										>
											Team Members
										</h3>
									</Breadcrumb.Item>
								</Breadcrumb>
							</Flex>
						</Space> */}
						<BreadCrumb config={breadcrumbConfig.team} />
					</Flex>
					<Flex justify="end" gap={"small"} style={{ alignItems: "center" }}>
						{/* <Input
							suffix={<SearchOutlined />}
							size="middle"
							style={{
								height: "5vh",
								width: "14vw",
								fontSize: "0.9rem",
								fontFamily: "Poppins",
							}}
							placeholder="Search"
							onChange={handleChange}
							value={input}
						/> */}
						{isSearchExpanded ? (
							<Input
								suffix={<SearchOutlined />}
								size="small"
								className="search-input"
								placeholder="Search"
								onChange={handleSearchChange}
								value={input}
								allowClear
								autoFocus
								onBlur={() => {
									if (input === "") setIsSearchExpanded(false);
								}}
							/>
						) : (
							<SearchOutlined
								className="search-icon"
								onClick={handleSearchIconClick}
								// onMouseEnter={() => setIsSearchExpanded(true)}
							/>
						)}

						{/* <Dropdown
							overlay={
								<Menu onClick={handleSortByChange}>
									{sortItems.map((item) => (
										<Menu.Item key={item.key}>{item.label}</Menu.Item>
									))}
								</Menu>
							}
							trigger={["click"]}
						>
							<Button
								size="middle"
								shape="round"
								style={{
									color: "#FCA311",
									borderColor: "#FCA311",
									backgroundColor: "white",
								}}
							>
								<Space>
									Sort by
									<DownOutlined />
								</Space>
							</Button>
						</Dropdown> */}

						{/* <Button
							size="middle"
							shape="round"
							type="primary"
							onClick={handleAddMemberClick}
						>
							Add Members
						</Button> */}
						<Can I="add" a="team_member" passThrough>
							{(allowed) =>
								allowed ? (
									// <motion.button
									// 	type="button"
									// 	style={{
									// 		fontFamily: "Poppins",
									// 		fontSize: "14px",
									// 		backgroundColor: "#0444BF",
									// 		borderRadius: "20px",
									// 		padding: "8px",
									// 		color: "white",
									// 		borderColor: "white",
									// 		minWidth: "10vw",
									// 		marginTop: "-5px",
									// 	}}
									// 	onClick={handleAddMemberClick}
									// 	whileTap={{ scale: 0.8, backgroundColor: "#032c81" }}
									// >
									// 	Add Members
									// </motion.button>
									<motion.div
										initial="initial"
										animate={isHovered ? "hover" : "initial"}
										variants={buttonVariants}
										transition={{ duration: 0.3 }}
										onMouseEnter={() => setIsHovered(true)}
										onMouseLeave={() => setIsHovered(false)}
										className="add-work-item-button"
										onClick={handleAddMemberClick}
									>
										<EditOutlined />
										{isHovered && (
											<span style={{ marginLeft: "8px" }}>Add Members</span>
										)}
									</motion.div>
								) : null
							}
						</Can>

						{/* <Button
							size="middle"
							shape="round"
							type="primary"
							onClick={handleAddWorkItemClick}
						>
							Add Members
						</Button> */}
					</Flex>
				</Flex>

				{/* <AddMemberModal visible={modalVisible} onCancel={handleModalCancel} />  */}
			</div>
		</div>
	);
}
export default TeamMemberNav;
