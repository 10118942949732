import { register } from "@antv/xflow";
import React, { useState } from "react";
import "../index.css";

const ROUNDEDRECTANGLENODE = "rounded-rectangle-node";

const SquareComponent = () => {
	const [animal, setAnimal] = useState("");

	return (
		<div className="roundRectangle">
			<div
				style={{
					padding: "2rem",
					minWidth: "50%",
					maxWidth: "50%",
					textAlign: "center",
					minHeight: "50%",
					maxHeight: "50%",
					direction: "ltr",
					unicodeBidi: "plaintext",
				}}
				contentEditable={true}
				suppressContentEditableWarning={true}
				onInput={(e) => {
					const newAnimalName = e.currentTarget.innerText;
					setAnimal(newAnimalName);
				}}
			>
				{animal}
			</div>
		</div>
	);
};

const ports = {
	groups: {
		group1: {
			position: "top",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group2: {
			position: "right",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group3: {
			position: "bottom",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group4: {
			position: "left",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
	},
	items: [
		{ id: "group1", group: "group1" },
		{ id: "group2", group: "group2" },
		{ id: "group3", group: "group3" },
		{ id: "group4", group: "group4" },
	],
};

register({
	shape: ROUNDEDRECTANGLENODE,
	ports: ports,
	component: SquareComponent,

	effect: ["data"],
});

export { ROUNDEDRECTANGLENODE };
