/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import {
	Layout,
	Collapse,
	Table,
	Tag,
	Flex,
	Card,
	Space,
	Result,
	Button,
} from "antd";
import { usePlanStore } from "../../stores/PlanStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

import { EyeOutlined, SmileOutlined } from "@ant-design/icons";
// import "./Feature.css";
import PlansNav from "./PlansNav";
import CreatePlanModel from "./CreatePlanModel";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { useParams } from "react-router-dom";
import KanbanBoard from "./CardView";
import BacklogListViewItem from "./BacklogListView";
import PlanViewNav from "./PlanViewNav";
import { planerViewStore } from "../../stores/PlannerStore";

const { Content } = Layout;
const { Panel } = Collapse;

const PlanWorkitemView: React.FC = () => {
	const { planId } = useParams();
	console.log(planId, "plan id");
	const [todos, setTodos] = useState<any[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const {
		isCardView,
		open,
		input,
		setOpen,
		ReleasePlanValue,
		setReleasePlan,
		setFetchPlan,
		selectedGroupBy,
		FetchPlan,
		planvalue,
		filter,
		newPlan,
		setplanvalue,
		urlData,
	} = usePlanStore();

	const { sortBy, rightClickMenu } = planerViewStore();
	const [modalVisible, setModalVisible] = useState(false);
	const [Nodata, setNodata] = useState(false);
	const [planName, setPlanName] = useState("");

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const handleModalCancel = () => {
		setModalVisible(false);
	};

	const handleAddWorkItemClick = () => {
		setModalVisible(true);
	};

	const fetchTodos = async () => {
		try {
			console.log(currentWorkspace?._id, "current workspace id");
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/plan-task/${planId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData: any = await response.json();
				setPlanName(todosData.name);
				if (todosData) {
					setTodos(todosData.data);
				} else {
					console.error("Invalid data structure: 'items' property not found.");
				}
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	const fetchTodosByPlan = async () => {
		try {
			console.log(currentWorkspace?._id, "current workspace id");
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/plan-task/${newPlan.planId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData: any = await response.json();
				console.log("todosData", todosData);
				setPlanName(newPlan.planName);
				if (todosData) {
					setTodos(todosData.data);
				} else {
					console.error("Invalid data structure: 'items' property not found.");
				}
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		if (newPlan.planId && newPlan.active === true) {
			fetchTodosByPlan();
		} else {
			fetchTodos();
		}
	}, [FetchPlan, rightClickMenu]);
	// const filteredDataSource:any[] = todos.filter(item =>
	// 	item.title.replace(/\s/g, "").toLowerCase().includes(input.replace(/\s/g, "").toLowerCase()) ||
	// 	item.description.replace(/\s/g, "").toLowerCase().includes(input.replace(/\s/g, "").toLowerCase())
	// );

	console.log("firstdata", todos);

	const sortedAndFilteredData = (data: any) => {
		const filteredData = data.filter((item: any) => {
			let matchesFilter = true;
			const inputLower = input?.replace(/\s/g, "").toLowerCase();
			const typeFilters = filter
				.filter((filterItem) => filterItem.startsWith("Type"))
				.map((filterItem) => {
					const typeSplit = filterItem.split("_");
					const type = typeSplit.shift();
					const rest = typeSplit.join("_");
					return rest.toLowerCase();
				});
			const statusFilters = filter
				.filter((filterItem) => filterItem.startsWith("Status"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());
			const priorityFilters = filter
				.filter((filterItem) => filterItem.startsWith("Priority"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());

			matchesFilter =
				matchesFilter &&
				(item.title?.replace(/\s/g, "").toLowerCase().includes(inputLower) ||
					item.description
						?.replace(/\s/g, "")
						.toLowerCase()
						.includes(inputLower));

			if (typeFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					typeFilters.some(
						(filterValue) => item?.type?.toLowerCase() === filterValue
					);
			}

			if (statusFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					statusFilters.some(
						(filterValue) => item?.status?.toLowerCase() === filterValue
					);
			}

			if (priorityFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					priorityFilters.some(
						(filterValue) => item?.priority?.toLowerCase() === filterValue
					);
			}

			return matchesFilter;
		});

		const sortedAndFilteredData = [...filteredData].sort((a, b) => {
			if (sortBy === "Created date (Asc)") {
				return (
					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				);
			} else if (sortBy === "Created date (Desc)") {
				return (
					new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				);
			} else {
				return 0;
			}
		});

		return sortedAndFilteredData;
	};

	return (
		<Layout style={{ background: "#F5F8FE" }}>
			<PlanViewNav title={planName} screen={"workitemView"} />
			<Layout>
				<Content style={{ backgroundColor: "#FAFBFF" }}>
					<div
						style={{
							overflow: open ? "hidden" : "scroll",
							position: "relative",
						}}
					>
						<Flex
							justify="center"
							style={{
								backgroundColor: "#EDF4FF",
								margin: "1rem",
								padding: "1rem",
								overflow: "scroll",
								// overflow: open ? "hidden" : "scroll",
								height: "73vh",
								maxWidth: "100vw",
								display: "block",
								position: "relative",
							}}
							className="WorkspaceDiv"
						>
							{isCardView ? (
								<></>
							) : (
								// <KanbanBoard
								//  Data={sortedAndFilteredData(todos)}
								// selectedGroupBy={selectedGroupBy}
								// RowBy={RowBy}
								// CardOrderning={CardOrderning}
								// ></KanbanBoard>
								<BacklogListViewItem
									Data={sortedAndFilteredData(todos)}
								></BacklogListViewItem>
							)}
						</Flex>
					</div>
				</Content>
			</Layout>
			<CreatePlanModel visible={modalVisible} onCancel={handleModalCancel} />
		</Layout>
	);
};

export default PlanWorkitemView;
