import { Avatar, Flex, Modal, Tag, Tooltip, Typography } from "antd";
import React from "react";

interface MapTaskViewModelProps {
	visible: boolean;
	onCancel: () => void;
	data: any;
}

const { Title } = Typography;

const MapTaskViewModel: React.FC<MapTaskViewModelProps> = ({
	visible,
	onCancel,
	data,
}) => {
	const getRandomPastelColor = () => {
		const hue = 219;
		const pastelColor = `hsl(${hue}, 80%, 80%)`;
		return pastelColor;
	};
	const pastelColor = getRandomPastelColor();
	const assigneeNameWords = data?.assignee?.name?.split(" ") || [];
	const firstName = assigneeNameWords[0] || "";
	const lastName =
		assigneeNameWords.length > 1
			? assigneeNameWords[assigneeNameWords.length - 1]
			: "";

	const avatarText = `${firstName.charAt(0)}${lastName.charAt(
		0
	)}`.toUpperCase();

	return (
		<Modal
			width={500}
			open={visible}
			onOk={onCancel}
			onCancel={onCancel}
			footer={null}
		>
			<Flex vertical gap={10} style={{ margin: "0.5rem" }}>
				<Flex justify="space-between" align="center">
					<Title level={3}>
						{data.WorkitemId}_{data.title}
					</Title>{" "}
					<Flex>
						<Tag style={{ height: "1.5rem" }} color="green">
							{data.type}
						</Tag>
					</Flex>
				</Flex>
				<Flex>
					<div
						style={{ color: "black" }}
						dangerouslySetInnerHTML={{ __html: data?.description }}
					></div>
				</Flex>
				<Flex align="end" gap={10} justify="space-between">
					<Flex>
						<Tag style={{ height: "1.5rem" }} color="blue">
							{data.priority}
						</Tag>
						<Tag style={{ height: "1.5rem" }} color="green">
							{data.status}
						</Tag>
					</Flex>
					<Tooltip
						title={data.assignee ? `${data.assignee.name}` : ""}
						placement="top"
					>
						<Avatar
							style={{
								backgroundColor: pastelColor,
							}}
						>
							{" "}
							{avatarText}
						</Avatar>
					</Tooltip>
				</Flex>
			</Flex>
		</Modal>
	);
};

export default MapTaskViewModel;
