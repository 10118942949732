import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Flex, Modal, Layout } from "antd";
import "./AddRole.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";

interface AddUserModalProps {
	isVisible: boolean;
	setIsVisible: (visible: boolean) => void;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
	isVisible,
	setIsVisible,
}) => {
	interface Workspace {
		_id: number;
		name: string;
	}

	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [form] = Form.useForm();
	const [Workspaces, setWorkspaces] = useState<Workspace[]>([]);
	const [responseData, setResponseData] = useState<any[]>([]);
	// const formItemLayout = { labelCol: { span: 2 }, wrapperCol: { span: 14 } };

	const ServerLink = process.env.REACT_APP_API_URL;

	const fetchData = async () => {
		try {
			const response = await fetch(`${ServerLink}/workspace`, {
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			const json = await response.json();
			setWorkspaces(json);
			if (response.status === 401) {
				setUnauthorizedModel(true);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleCancelClick = async () => {
		setIsVisible(false);
	};

	const handleSaveClick = async (values: any) => {
		console.log("values", values);
		try {
			const userProfileResponse = await fetch(`${ServerLink}/user-profiles`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({
					name: values.name,
					email: values.email,
					isActive: true,
					adUserID: "dummyAzureUserID",
					preferences: "dummyPreferences",
					account_Id: userProfileStoreInstance.profile.account_Id,
					CreatedBy: "dummyCreatedByUserID",
					updatedBy: "dummyUpdatedByUserID",
				}),
			});

			if (!userProfileResponse.ok) {
				console.error(
					"Error saving user profile data:",
					userProfileResponse.statusText
				);
				return;
			}

			const userProfileData = await userProfileResponse.json();
			const userProfileId = userProfileData._id;

			const fetchRoleData = async () => {
				try {
					const response = await fetch(`${ServerLink}/roles/${values.roleId}`, {
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					});
					const data = await response.json();
					const permissionDetails = data.permissions.map(
						({ field, permissions }: any) => ({
							field,
							permissions,
						})
					);

					const workspacePermissionsResponse = await fetch(
						`${ServerLink}/workspace-permissions`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
							},
							body: JSON.stringify({
								workspaceId: values.workspaceId,
								userProfileId: userProfileId,
								permission: permissionDetails,
								roles: values.roleId,
								CreatedBy: "Kasinath",
								updatedBy: "Kasinath",
							}),
						}
					);

					if (workspacePermissionsResponse.ok) {
						setIsVisible(false);
					} else {
						console.error(
							"Error saving workspace permissions data:",
							workspacePermissionsResponse.statusText
						);
					}
				} catch (error) {
					console.error("Error in fetchRoleData try-catch block:", error);
				}
			};

			await fetchRoleData();
		} catch (error) {
			console.error("Error in handleSaveClick try-catch block:", error);
		}
	};

	useEffect(() => {
		const fetchRolesData = async () => {
			try {
				const response = await fetch(`${ServerLink}/roles`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					setResponseData(data);
				} else {
					console.error("Error fetching roles data");
				}
			} catch (error) {
				console.error("Error fetching roles data:", error);
			}
		};

		fetchRolesData();
	}, []);

	return (
		<>
			<Modal
				title="Add Roles"
				open={isVisible}
				onCancel={handleCancelClick}
				footer={null}
				width={800}
			>
				<Layout style={{ padding: "20px", background: "white" }}>
					<Form
						name="basic"
						form={form}
						initialValues={{ remember: true }}
						autoComplete="off"
						onFinish={handleSaveClick}
						layout="vertical"
						className="titleTypoAddRole"
						style={{ width: "100%" }}
					>
						<Form.Item name="name" label="User Name">
							<Input
								className="formInputUser"
								style={{ height: "40px", borderRadius: "5px", width: "100%" }}
								placeholder="Name"
								size="middle"
							/>
						</Form.Item>

						<Form.Item name="email" label="Email">
							<Input
								className="formInputUser"
								style={{ width: "100%" }}
								placeholder="Email"
								size="middle"
							/>
						</Form.Item>

						<Form.Item name="workspaceId" label="Select Workspace">
							<Select
								placeholder="Select Workspaces"
								className="formInputUser"
								style={{ width: "100%" }}
								size="middle"
							>
								{Workspaces.length > 0 &&
									Workspaces.map((workspace) => (
										<Select.Option key={workspace._id} value={workspace._id}>
											{workspace.name}
										</Select.Option>
									))}
							</Select>
						</Form.Item>

						<Form.Item name="roleId" label="Select Role">
							<Select
								placeholder="Select Roles"
								className="formInputUser"
								style={{ width: "100%" }}
								size="small"
							>
								{responseData.length > 0 &&
									responseData.map((role) => (
										<Select.Option key={role._id} value={role._id}>
											{role.name}
										</Select.Option>
									))}
							</Select>
						</Form.Item>

						<Form.Item>
							<Flex justify="flex-end">
								<Flex gap={10}>
									<Button
										size="small"
										style={{ borderRadius: "6px", fontSize: "14px" }}
										onClick={handleCancelClick}
									>
										Cancel
									</Button>
									<Button
										size="small"
										style={{ borderRadius: "6px", fontSize: "14px" }}
										type="primary"
										htmlType="submit"
									>
										Save
									</Button>
								</Flex>
							</Flex>
						</Form.Item>
					</Form>
				</Layout>
			</Modal>
		</>
	);
};

export default AddUserModal;
