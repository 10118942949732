import { create } from "zustand";
import { persist } from "zustand/middleware";

export type PlatformStoreType = {
	aiChatWidget: boolean;
	setAiChatWidget: (value: boolean) => void;
	notifications: any;
	setNotifications: (value: any) => void;
	redirectUrl: string;
	setRedirectUrl: (value: string) => void;
	backNavigateUrl: string;
	setBackNavigateUrl: (value: string) => void;
	nextNavigateUrl: string;
	setNextNavigateUrl: (value: string) => void;
	currentPage: string;
	setCurrentPage: (value: string) => void;
	currentModule: string;
	setCurrentModule: (value: string) => void;
	appBarTitle: string;
	setAppBarTitle: (value: string) => void;
	unauthorizedModel: boolean;
	setUnauthorizedModel: (value: boolean) => void;
	afterfeedUrl: string;
	setafterfeedUrl: (value: string) => void;

	pageContext: any;
	setPageContext: (value: any) => void;

	clearStore: () => void;
};

const usePlatformStore = create(
	persist(
		(set) => ({
			aiChatWidget: false,
			setAiChatWidget: (value: boolean) => set({ aiChatWidget: value }),
			notifications: [],
			setNotifications: (value: any) => set({ notifications: value }),
			redirectUrl: "",
			setRedirectUrl: (value: string) => set({ redirectUrl: value }),
			backNavigateUrl: "",
			setBackNavigateUrl: (value: string) => set({ backNavigateUrl: value }),
			nextNavigateUrl: "",
			setNextNavigateUrl: (value: string) => set({ nextNavigateUrl: value }),
			currentPage: "",
			setCurrentPage: (value: string) => set({ currentPage: value }),
			currentModule: "",
			setCurrentModule: (value: string) => set({ currentModule: value }),
			appBarTitle: "",
			setAppBarTitle: (value: string) => set({ appBarTitle: value }),
			afterfeedUrl: "",
			setafterfeedUrl: (value: string) => set({ afterfeedUrl: value }),
			unauthorizedModel: false,
			setUnauthorizedModel: (value: boolean) =>
				set({ unauthorizedModel: value }),
			pageContext: {},
			setPageContext: (value: any) => set({ pageContext: value }),
			clearStore: () =>
				set({
					aiChatWidget: false,
					redirectUrl: "",
					backNavigateUrl: "",
					nextNavigateUrl: "",
					currentPage: "",
					currentModule: "",
					appBarTitle: "",
					afterfeedUrl: "",
				}),
		}),
		{
			name: "platform-store",
		}
	)
);

export default usePlatformStore;
