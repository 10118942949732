import React, { useEffect, useState } from "react";
import { Modal, Input, Card, List, message } from "antd";
import { useDebounceEffect, useRequest } from "ahooks";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";

import useWorkspaceStore from "../stores/WorkspaceState";
import { getWorkItemsbyQuery } from "../api/WorkItemAPI";

interface SelectWorkItemModalProps {
	isModalVisible: boolean;
	handleModalCancel: () => void;
	goalParam: any;
	onOk: (selectedWorkItem: string) => void;
}

const SelectWorkItemModalV2: React.FC<SelectWorkItemModalProps> = ({
	isModalVisible,
	handleModalCancel,
	goalParam,
	onOk,
}) => {
	// const navigate = useNavigate();
	const [workItemData, setWorkItemData] = useState<any[]>([]);

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const [searchInput, setSearchInput] = React.useState("");
	const [filteredOptions, setFilteredOptions] = React.useState<
		{
			label: string;
			type: string;
			data: { url: string };
			category: string;
			tags: string[];
		}[]
	>([]);

	const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>("");

	useDebounceEffect(
		() => {
			setSearchInput(debouncedSearchInput);
		},
		[debouncedSearchInput],
		{
			wait: 1000,
		}
	);

	// const updateConversationThread = async () => {
	// 	const updatedData = {
	// 		...aiConversationThread,
	// 		data: {
	// 			itemType: "FEATURE",
	// 			itemId: aiConversationContext.data.workitemId,
	// 		},
	// 	};

	// 	try {
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/ai-conversation-thread/${aiConversationThread._id}`,
	// 			{
	// 				method: "PUT",
	// 				headers: {
	// 					"Content-Type": "application/json; charset=UTF-8",
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 				body: JSON.stringify(updatedData),
	// 			}
	// 		);

	// 		if (response.ok) {
	// 			console.log("Conversation thread updated successfully");
	// 		} else {
	// 			console.error("Failed to update conversation thread");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error updating conversation thread:", error);
	// 	}
	// };

	const { data: workItemDataByQuery, run: fetchTodosRequest } = useRequest(
		getWorkItemsbyQuery,
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data, "workItemDataByQuery");
			},
			onError: (error) => {
				message.error("Failed to fetch todos.");
				throw new Error(`Fetching todos failed: ${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (workItemDataByQuery) {
			if (
				goalParam == "feature-elaboration" ||
				goalParam == "feature-refinement"
			) {
				const filterResult = workItemDataByQuery.filter(
					(item: any) => item.type === "FEATURE"
				);
				setWorkItemData(filterResult);
			} else {
				setWorkItemData(workItemDataByQuery);
			}
		}
	}, [workItemDataByQuery]);
	useEffect(() => {
		if (currentWorkspace) {
			fetchTodosRequest(
				currentWorkspace._id,
				searchInput,
				userProfileStoreInstance.idToken
			);
		}
	}, [searchInput, currentWorkspace, userProfileStoreInstance.idToken]);

	const handleCancel = () => {
		handleModalCancel();
	};

	React.useEffect(() => {
		if (filteredOptions.length === 0) setFilteredOptions([]);
	}, [filteredOptions.length]);

	console.log(searchInput, "searchInput");

	React.useEffect(() => {
		if (searchInput === "" || searchInput === null) {
			setFilteredOptions([]);
			return;
		} else {
			setFilteredOptions(
				filteredOptions.filter((option) => {
					return option.tags.some((tag) => tag.includes(searchInput));
				})
			);
		}
	}, [searchInput]);

	const doCommand = async (item: any) => {
		onOk(item);
		setSearchInput("");
		setDebouncedSearchInput("");
		setFilteredOptions([]);
		isModalVisible = false;
	};

	return (
		<Modal
			visible={isModalVisible}
			title="Search and select"
			onCancel={handleCancel}
			// onOk={handleOk}
		>
			<Input
				placeholder="Search"
				value={debouncedSearchInput}
				onChange={(e) => setDebouncedSearchInput(e.target.value)}
			/>

			<Card style={{ height: "50vh", border: "0px", overflowY: "auto" }}>
				<List
					style={{ border: "0px", cursor: "pointer" }}
					size="large"
					// header={<div>Header</div>}
					// footer={<div>Footer</div>}
					bordered
					dataSource={workItemData}
					renderItem={(item) => (
						<List.Item onClick={() => doCommand(item)}>
							{" "}
							<div>
								<List.Item.Meta
									title={item.title}
									description={item.description}
								/>
							</div>
						</List.Item>
					)}
				/>
			</Card>
		</Modal>
	);
};

export default SelectWorkItemModalV2;
