import React, { useState, useEffect } from "react";
import { Layout, List, Typography, Flex, Divider } from "antd";
import "./RoleManagement.css";
import { useNavigate } from "react-router-dom";
// import { DeleteOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
import BreadCrumb from "../Editors/BreadCrumb";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import AddUserModal from "./AddUser";
import { motion } from "framer-motion";
import { EditOutlined } from "@ant-design/icons";
const { Text } = Typography;

const UserManagement: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const [responseData, setResponseData] = useState<any[]>([]);
	const navigate = useNavigate();
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isModalVisible, setisModalVisible] = useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	useEffect(() => {
		const fetchRolesData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/user-profiles`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (response.ok) {
					const data = await response.json();
					setResponseData(data);
					console.log(data);
				} else if (response.status === 401) {
					setUnauthorizedModel(true);
				} else {
					console.error("Error fetching roles data");
				}
			} catch (error) {
				console.error("Error fetching roles data:", error);
			}
		};

		fetchRolesData();
	}, []);

	useEffect(() => {
		console.log("Selected User ID:", selectedUserId);
	}, [selectedUserId]);

	const handleAddRoleClick = () => {
		setisModalVisible(true);
	};

	const handleViewPermissionsClick = (userId: any) => {
		setSelectedUserId(userId);
		navigate(`/settings/user/edit/${userId}`);
	};

	const [isHovered, setIsHovered] = useState(false);
	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		<>
			<Divider className="ant-divider-horizontal" />
			<Flex
				justify="space-between"
				align="center"
				style={{
					margin: "0 1vw",
					marginTop: "-0.5vh",
				}}
			>
				<Flex style={{ marginLeft: "1vw" }} className="breadcrumb">
					<BreadCrumb config={breadcrumbConfig.usermanagement} />
				</Flex>

				<motion.div
					initial="initial"
					animate={isHovered ? "hover" : "initial"}
					variants={buttonVariants}
					transition={{ duration: 0.3 }}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					className="add-work-item-button"
					onClick={handleAddRoleClick}
				>
					<EditOutlined />
					{isHovered && <span style={{ marginLeft: "8px" }}>Add User</span>}
				</motion.div>
			</Flex>
			<Layout
				style={{
					height: "80vh",
					background: "white",
					margin: "0 1vw",
					padding: "0 1.5rem ",
					marginTop: "-0.5vh",
				}}
			>
				<AddUserModal
					isVisible={isModalVisible}
					setIsVisible={setisModalVisible}
				/>
				<div>
					<List
						dataSource={responseData}
						renderItem={(item) => (
							<List.Item
								key={item._id}
								style={{ padding: "5px 0" }}
								actions={[
									<a
										key="edit"
										style={{
											color: "#3478F6",
											fontSize: "12px",
											fontFamily: "Open Sans",
										}}
										onClick={() => handleViewPermissionsClick(item._id)}
									>
										Edit Permissions
									</a>,
								]}
							>
								<List.Item.Meta
									title={
										<Flex justify="space-between">
											<Text
												style={{
													fontSize: "12px",
													fontWeight: "400",
												}}
											>
												{item.name}
											</Text>

											<Text
												style={{
													fontSize: "12px",
													fontWeight: "400",
												}}
											>
												{item.email}
											</Text>
										</Flex>
									}
									// description={
									// 	<Text
									// 		style={{
									// 			fontSize: "14px",
									// 			fontWeight: "500",
									// 			fontFamily: "Open Sans",
									// 		}}
									// 	>
									// 		{item.email}
									// 	</Text>
									// }
								/>
							</List.Item>
						)}
						pagination={{
							pageSize: 6,
							pageSizeOptions: ["10", "20", "30"],
							showTotal: (total, range) =>
								`${range[0]}-${range[1]} of ${total} items`,
						}}
					/>
				</div>
			</Layout>
		</>
	);
};

export default UserManagement;
