import React, { useState } from "react";
import { Button, Modal, Form, Input, message, ColorPicker, Flex } from "antd";
import { useLocation } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";

const AddManagement: React.FC = () => {
	const location = useLocation();

	// States for prefix modal and tag modal visibility
	const [openPrefixModal, setOpenPrefixModal] = useState(false);
	const [openPlanTypeModal, setOpenPlanTypeModal] = useState(false);
	const [openTagModal, setOpenTagModal] = useState(false);

	// States for prefix and tag form data
	const [prefixType, setPrefixType] = useState("");
	const [prefixValue, setPrefixValue] = useState("");
	const [tagName, setTagName] = useState("");
	const [category, setCategory] = useState("");
	const [description, setDescription] = useState("");
	const [color, setColor] = useState("");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	// Handler for adding a tag

	const handleAddTag = async () => {
		const name = tagName;
		console.log("Tag name:", name);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({
					name: tagName,
					category,
					description,
					color: color,
				}),
			});
			if (response.ok) {
				const responseData = await response.json();
				console.log("Response from the backend:", responseData);
				message.success("Tag added successfully");
				setOpenTagModal(false);
			} else {
				const errorData = await response.json();
				message.error(`Error adding tag. Please try again.${errorData}`);
			}
		} catch (error: any) {
			message.error(`Error adding tag. Please try again: ${error.message}`);
		}
	};

	// Handler for adding a prefix
	const handleAddPrefix = () => {
		const APIUrl = `${process.env.REACT_APP_API_URL}/workitemIdConfig`;
		const data = {
			type: prefixType,
			prefix: prefixValue,
			workspaceid: currentWorkspace._id,
			lastnumber: 0,
		};
		fetch(APIUrl, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Success:", data);
				message.success("Prefix added successfully!");
				setOpenPrefixModal(false);
			})
			.catch((error) => {
				console.error("Error:", error);
				message.error("Error adding prefix");
			});
	};

	const handleAddPlanType = () => {
		const APIUrl = `${process.env.REACT_APP_API_URL}/planconfig`;
		const data = {
			type: prefixType,
			prefix: prefixValue,
			workspaceid: currentWorkspace._id,
			lastnumber: 0,
		};
		fetch(APIUrl, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Success:", data);
				message.success("Prefix added successfully!");
				setOpenPrefixModal(false);
			})
			.catch((error) => {
				console.error("Error:", error);
				message.error("Error adding prefix");
			});
	};

	// Rendering prefix and tag modals based on the URL path
	return (
		<div>
			{location.pathname === "/fd/workspace/settings/plan" && (
				<>
					<Button type="primary" onClick={() => setOpenPlanTypeModal(true)}>
						Add Type
					</Button>

					{/* PlanType Modal */}
					<Modal
						title="Add Type"
						visible={openPlanTypeModal}
						onCancel={() => setOpenPlanTypeModal(false)}
						onOk={handleAddPlanType}
					>
						<Form layout="horizontal">
							<Form.Item
								label="Type"
								required
								style={{ width: "100%", marginLeft: "90px" }}
								labelCol={{ span: 6 }}
								wrapperCol={{ span: 18 }}
							>
								<Input
									value={prefixType}
									onChange={(e) => setPrefixType(e.target.value)}
								/>
							</Form.Item>
							<Form.Item
								label="Prefix"
								required
								style={{ width: "100%" }}
								labelCol={{ span: 6 }}
								wrapperCol={{ span: 18 }}
							>
								<Input
									value={prefixValue}
									onChange={(e) => setPrefixValue(e.target.value)}
								/>
							</Form.Item>
						</Form>
					</Modal>
				</>
			)}

			{location.pathname === "/fd/workspace/settings/workitem" && (
				<>
					<Button type="primary" onClick={() => setOpenPrefixModal(true)}>
						Add Prefix
					</Button>

					{/* Prefix Modal */}
					<Modal
						title="Add Prefix"
						visible={openPrefixModal}
						onCancel={() => setOpenPrefixModal(false)}
						onOk={handleAddPrefix}
					>
						<Form layout="horizontal">
							<Form.Item
								label="Type"
								required
								style={{ width: "100%" }}
								labelCol={{ span: 6 }}
								wrapperCol={{ span: 18 }}
							>
								<Input
									value={prefixType}
									onChange={(e) => setPrefixType(e.target.value)}
								/>
							</Form.Item>
							<Form.Item
								label="Prefix"
								required
								style={{ width: "100%" }}
								labelCol={{ span: 6 }}
								wrapperCol={{ span: 18 }}
							>
								<Input
									value={prefixValue}
									onChange={(e) => setPrefixValue(e.target.value)}
								/>
							</Form.Item>
						</Form>
					</Modal>
				</>
			)}

			{location.pathname === "/fd/workspace/settings/tag" && (
				<>
					<Button type="primary" onClick={() => setOpenTagModal(true)}>
						Add Tag
					</Button>
					<Modal
						title="Add Tag"
						visible={openTagModal}
						onCancel={() => setOpenTagModal(false)}
						onOk={handleAddTag}
					>
						<Form layout="horizontal">
							<Flex justify="space-between" align="center">
								<Form.Item
									label="Tag"
									name="tagName"
									rules={[
										{
											required: true,
											message: "Please enter tag name",
										},
									]}
									style={{ width: "100%" }}
									labelCol={{ span: 6 }}
									wrapperCol={{ span: 18 }}
								>
									<Input
										placeholder="Enter tag name"
										value={tagName}
										onChange={(event) => setTagName(event.target.value)}
									/>
								</Form.Item>
							</Flex>

							<Flex justify="space-between" align="center">
								<Form.Item
									label="Category"
									name="category"
									rules={[
										{
											required: true,
											message: "Please enter tag category",
										},
									]}
									style={{ width: "100%" }}
									labelCol={{ span: 6 }}
									wrapperCol={{ span: 18 }}
								>
									<Input
										placeholder="Enter tag category"
										value={category}
										onChange={(event) => setCategory(event.target.value)}
									/>
								</Form.Item>
							</Flex>

							<Flex justify="space-between" align="center">
								<Form.Item
									label="Description"
									name="description"
									rules={[
										{
											required: true,
											message: "Please enter tag description",
										},
									]}
									style={{ width: "100%" }}
									labelCol={{ span: 6 }}
									wrapperCol={{ span: 18 }}
								>
									<Input.TextArea
										placeholder="Enter tag description"
										value={description}
										onChange={(event) => setDescription(event.target.value)}
									/>
								</Form.Item>
							</Flex>

							<Flex justify="space-between" align="center">
								<Form.Item
									label="Color"
									name="color"
									getValueFromEvent={(color) => {
										setColor("#" + color.toHex());
										return "#" + color.toHex();
									}}
									rules={[
										{
											required: false,
											message: "Please select tag color",
										},
									]}
									style={{ width: "90%" }}
									labelCol={{ span: 6 }}
									wrapperCol={{ span: 18 }}
								>
									<ColorPicker
										defaultValue="#1677ff"
										showText={(color) => (
											<span>Color: ({color.toHexString()})</span>
										)}
									/>
								</Form.Item>
							</Flex>
						</Form>
					</Modal>
				</>
			)}
		</div>
	);
};

export default AddManagement;
