import React, { useEffect, useRef } from "react";
// import useWorkspaceStore from "../stores/WorkspaceState";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
import useAIActivityStore from "../stores/AIActivityStore";
// import { useNavigate } from "react-router-dom";
// import {
// 	BulbFilled,
// 	NodeExpandOutlined,
// 	RobotOutlined,
// } from "@ant-design/icons";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
import AlchemiRecommendationList from "./alchemi/AlchemiRecommendationList";
import AlchemiChatThreadList from "./alchemi/AlchemiChatThreadList";
import AlchemiNotificationList from "./alchemi/AlchemiNotificationList";
import AlchemiAIRunList from "./alchemi/AlchemiAIRunList";
import ChatSiderUI from "../pages/ChatSiderUI";
import { useInViewport } from "ahooks";

interface AlchemiAIPopupProps {
	view: string;
}

const AlchemiAIPopup: React.FC<AlchemiAIPopupProps> = ({ view }) => {
	// Implement your component logic here
	//const navigate = useNavigate();
	// const { currentWorkspace } = useWorkspaceStore((state: any) => ({
	// 	currentWorkspace: state.currentWorkspace,
	// }));
	//const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	//const { setAIConversationContext } = useAIActivityStore((state: any) => ({
	//	setAIConversationContext: state.setAIConversationContext,
	//}));
	const { currentAIActivities, alRecommendations } = useAIActivityStore(
		(state: any) => ({
			currentAIActivities: state.currentAIActivities,
			setCurrentAIActivities: state.setCurrentAIActivities,
			alRecommendations: state.alRecommendations,
			setAlRecommendations: state.setAlRecommendations,
		})
	);

	// const updateRecommendation = async (recommendation: any) => {
	// 	const response = await fetch(
	// 		`${process.env.REACT_APP_API_URL}/alrecommendations/${recommendation._id}`,
	// 		{
	// 			method: "PUT",
	// 			body: JSON.stringify(recommendation),
	// 			headers: {
	// 				"Content-Type": "application/json; charset=UTF-8",
	// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 			},
	// 		}
	// 	);

	// 	return response;
	// };

	// const getAIActivty = async () => {
	// 	// Implement your code here

	// 	const response = await fetch(
	// 		`${process.env.REACT_APP_API_URL}/describe/${currentWorkspace._id}/activity?status=IN_PROGRESS`,
	// 		{
	// 			method: "GET",
	// 			headers: {
	// 				"Content-Type": "application/json; charset=UTF-8",
	// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 			},
	// 		}
	// 	);

	// 	const result = await response.json();
	// 	setCurrentAIActivities(result);
	// 	//setAIActivityJob(result);
	// };

	// useEffect(() => {
	// 	getAIActivty();
	// }, []);

	useEffect(() => {
		if (currentAIActivities) {
			console.log("aiActivityJob", currentAIActivities);
		}
	}, [currentAIActivities]);

	// const handleMarkAsRead = async (recommendation: any) => {
	// 	recommendation.status = "READ";
	// 	await updateRecommendation(recommendation);
	// 	console.log("Mark as read", recommendation);
	// };

	// const handleAction = async (recommendation: any) => {
	// 	recommendation.status = "READ";
	// 	await updateRecommendation(recommendation);
	// 	console.log("Mark as read", recommendation);
	// 	if (recommendation.category == "aiRun") {
	// 		if (recommendation.data.type == "WORKFLOW") {
	// 			if (recommendation.data.stage == "FEATURE_REFINEMENT") {
	// 				navigate(`/fd/airun/feature-refinement`);
	// 			} else if (recommendation.data.stage == "FEATURE_ELABORATION") {
	// 				navigate(`/fd/airun/feature-elaboration`);
	// 			} else if (recommendation.data.stage == "UX_SPECIFICATION") {
	// 				navigate(`/fd/airun/de-ux-spec`);
	// 			} else if (recommendation.data.stage == "HIFI_MOCKUP") {
	// 				navigate(`/fd/airun/de-hifi-mock-up`);
	// 			} else if (recommendation.data.stage == "DDD_SPECIFICATION") {
	// 				navigate(`/fd/airun/de-ddd-spec`);
	// 			} else if (
	// 				recommendation.data.stage == "MICRO_FRONTEND_SPECIFICATION"
	// 			) {
	// 				navigate(`/fd/airun/de-micro-frontend`);
	// 			} else if (recommendation.data.stage == "API_SPECIFICATION") {
	// 				navigate(`/fd/airun/de-api-spec`);
	// 			} else if (recommendation.data.stage == "DATA_SCHEMA") {
	// 				navigate(`/fd/airun/de-data-schema`);
	// 			}
	// 		}
	// 	}
	// 	console.log("Do elobarate", recommendation);
	// };
	const ref = useRef(null);
	const [inViewport] = useInViewport(ref);
	return (
		<>
			{view === "home" && (
				<AlchemiNotificationList
					alRecommendations={alRecommendations}
					currentAIActivities={currentAIActivities}
				/>
			)}
			{view === "chatUI" && (
				<div ref={ref}>
					{" "}
					<ChatSiderUI
						collapsed={false}
						inViewport={inViewport ? true : false}
					/>
				</div>
			)}
			{view === "recommendations" && <AlchemiRecommendationList />}
			{view === "chatThreads" && <AlchemiChatThreadList />}
			{view === "aiRuns" && <AlchemiAIRunList />}
		</>
	);
};

export default AlchemiAIPopup;
