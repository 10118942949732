import React from "react";
import { Card, Tag } from "antd";

interface CustomCardProps {
	selectedNodeDetails: any; // Adjust type as needed
	// handleRightClick: () => void;
	// Adjust type as needed
}

const CustomCard: React.FC<CustomCardProps> = ({ selectedNodeDetails }) => {
	if (!selectedNodeDetails) {
		return <Card className="ddd-card-info" style={{ padding: "1rem" }} />;
	}

	function formatString(inputString: any) {
		const stringWithoutUnderscores = inputString.replace(/_/g, " ");
		return (
			stringWithoutUnderscores.charAt(0).toUpperCase() +
			stringWithoutUnderscores.slice(1)
		);
	}

	return (
		<div>
			{selectedNodeDetails && (
				<Card className="ddd-card-info" style={{ padding: "1rem" }}>
					<h3 className="strategic-card-title">
						{formatString(selectedNodeDetails.id)}
					</h3>
					<div
						// className={contentClassName}
						data-path={`/de/}`}
					>
						<p className="sub-bold-text-strategic-design">StakeHolders</p>
						<div className="tag-container">
							{selectedNodeDetails.stakeholders &&
								selectedNodeDetails.stakeholders.map(
									(stakeholder: any, index: any) => (
										<Tag className="tag-ddd" key={index} color="blue">
											{formatString(stakeholder)}
										</Tag>
									)
								)}
						</div>
					</div>
					<div
						// className={contentClassName}
						data-path={`/de/}`}
					>
						<p className="sub-bold-text-strategic-design">Tactical Design</p>
						<p className="sub-text">Application Services</p>
						<ul className="ddd-card-list">
							{selectedNodeDetails.tactical_design &&
								selectedNodeDetails.tactical_design.application_services.map(
									(service: any, index: number) => (
										<li key={index}>
											<span className="bold-text">{service.name}</span> -{" "}
											{service.description}
										</li>
									)
								)}
						</ul>
					</div>
					<div
						// className={contentClassName}
						data-path={`/de/}`}
					>
						<div className="tag-container">
							<p className="sub-text">Entities</p>
							{selectedNodeDetails.tactical_design &&
								selectedNodeDetails.tactical_design.entities.map(
									(service: any, index: number) => (
										<Tag className="tag-ddd" key={index} color="blue">
											{formatString(service)}
										</Tag>
									)
								)}
						</div>
					</div>
					{selectedNodeDetails.tactical_design &&
						selectedNodeDetails.tactical_design.value_objects.length > 0 && (
							<div
								// className={contentClassName}
								data-path={`/de/}`}
							>
								<div className="tag-container">
									<p className="sub-text">Value Objects</p>
									{selectedNodeDetails.tactical_design.value_objects.map(
										(service: any, index: number) => (
											<Tag className="tag-ddd" key={index} color="blue">
												{formatString(service)}
											</Tag>
										)
									)}
								</div>
							</div>
						)}{" "}
				</Card>
			)}
		</div>
	);
};

export default CustomCard;
