import React from "react";
import { motion } from "framer-motion";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";

interface MenuItemProps {
	icon: JSX.Element;
	title: string;
	path: string;
	selectedMenuItem: string;
	setSelectedMenuItem: React.Dispatch<React.SetStateAction<string>>;
	collapsed: boolean;
}

const MenuItemMotion: React.FC<MenuItemProps> = ({
	icon,
	title,
	path,
	selectedMenuItem,
	setSelectedMenuItem,
	collapsed,
}) => {
	const navigate = useNavigate();
	const cleanedTitle = title.replace(/\s+/g, ""); // Remove spaces from the title

	const handleClick = () => {
		if (selectedMenuItem !== cleanedTitle) {
			navigate(path);
			setSelectedMenuItem(cleanedTitle);
		}
	};

	return (
		<motion.div
			whileTap={{ scale: 0.8 }}
			whileHover={{
				backgroundColor: "#0540B2",
				color: "#fff",
				borderRadius: "6px",
			}}
			style={{
				display: "flex",
				justifyContent: collapsed ? "center" : "flex-start",
			}}
		>
			<Menu.Item
				key={cleanedTitle}
				icon={icon}
				onClick={handleClick}
				style={{
					background:
						selectedMenuItem === cleanedTitle ? "white" : "transparent",
					color: selectedMenuItem === cleanedTitle ? "#0444bf" : "inherit",
					height: 35,
					borderRadius: "6px",
					lineHeight: "35px",
				}}
			>
				{title}
			</Menu.Item>
		</motion.div>
	);
};

export default MenuItemMotion;
