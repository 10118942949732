import { useGraphStore } from "@antv/xflow";
import { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { CUSTOMMAINNODE } from "./CoreSubDomain/CUSTOMEMAINNODE";
import { CUSTOMECHILDNODE } from "./CoreSubDomain/CUSTOMECHILDNODE";
import useWorkspaceStore from "../../stores/WorkspaceState";

function formatString(inputString: any) {
	const stringWithoutUnderscores = inputString.replace(/_/g, " ");
	return (
		stringWithoutUnderscores.charAt(0).toUpperCase() +
		stringWithoutUnderscores.slice(1)
	);
}

const InitNode = ({ id }: { id: any }) => {
	const addNodes = useGraphStore((state) => state.addNodes);
	const addEdges = useGraphStore((state) => state.addEdges);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [apiData, setApiData] = useState<any>(null);

	const { setDddSpecOnclick } = useWorkspaceStore((state: any) => ({
		setDddSpecOnclick: state.setDddSpecOnclick,
	}));

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiUrl = process.env.REACT_APP_API_URL;

				if (!apiUrl) {
					console.error(
						"REACT_APP_API_URL is not defined in your environment."
					);
					return;
				}

				const response = await fetch(
					`${apiUrl}/project-artifact/de-ddd-spec/${id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);

				if (!response.ok) {
					throw new Error(
						`status: ${response.status} message: ${response.statusText}`
					);
				}

				const data = await response.json();
				setApiData(data);
			} catch (error: any) {
				message.error(`Error: ${error.message}`);
			}
		};

		fetchData();
	}, [userProfileStoreInstance]);

	const addNodeInit = useCallback(() => {
		if (!apiData) return;

		const designSpecData =
			(apiData && apiData[0]?.data?.strategic_design) || [];
		const coreSubDomains =
			(designSpecData &&
				designSpecData.core_sub_domains &&
				designSpecData.core_sub_domains[0]) ||
			null;
		const parentId = coreSubDomains?.id;
		const bounded_contexts = coreSubDomains?.bounded_contexts;
		setDddSpecOnclick(bounded_contexts[0]);

		console.log(parentId, "hello");

		if (designSpecData) {
			const nodeWidth = 190; // Width of each node
			const totalNodesWidth = bounded_contexts.length * nodeWidth; // Total width occupied by all nodes
			const startX = (30 * window.innerWidth) / 100 - totalNodesWidth / 2; // Starting X position to center nodes

			const nodesToAdd = bounded_contexts.map((page: any, index: number) => ({
				id: `initNode${index + 1}`,
				shape: CUSTOMECHILDNODE,
				x: 305,
				y: startX + index * nodeWidth,
				data: {
					page: formatString(page.id),
					data: page,
				},
			}));

			const additionalNode = {
				id: "additionalNode",
				shape: CUSTOMMAINNODE,
				x: 30, // Adjust x position to connect from the left side of the additional node
				y: 20, // Positioned above the dynamically generated nodes
				data: formatString(parentId), // Type assertion or null check
			};

			addNodes([...nodesToAdd, additionalNode]);

			// Connect the ports
			nodesToAdd.forEach((node: any) => {
				addEdges([
					{
						source: "additionalNode",
						sourcePort: "group3", // Fixed port on the left side
						target: node.id,
						targetPort: "group1", // Connect to the top port
						vertices: [
							{ x: 31, y: 247 },
							{ x: 31, y: 247 },
							{ x: 31, y: 247 },
						],
					},
				]);
			});
		}
	}, [apiData, addEdges, addNodes]);

	useEffect(() => {
		addNodeInit();
	}, [addNodeInit]);

	return null;
};

export { InitNode, CUSTOMMAINNODE };
