import { Button, Flex, Image, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import useWorkspaceStore from "../../stores/WorkspaceState";
import useAIActivityStore from "../../stores/AIActivityStore";
import { useRequest } from "ahooks";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

interface PostChatProcessProps {
	//
}

const PostChatProcess: React.FC<PostChatProcessProps> = (props) => {
	// Add your component logic here

	const { goalParam } = useParams();
	const navigate = useNavigate();
	// const { currentWorkspace } = useWorkspaceStore((state: any) => ({
	// 	currentWorkspace: state.currentWorkspace,
	// }));
	// const [aiConversationThread, setAiConversationThread] = useState<any>(null);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { aiConversationContext, currentConversationThread } =
		useAIActivityStore((state: any) => ({
			aiConversationContext: state.aiConversationContext,
			currentConversationThread: state.currentConversationThread,
		}));
	const [completeAnalysis, setCompleteAnalysis] = useState(false);

	const {
		data: threadResponseReviewData,
		run: threadResponseReviewRun,
		cancel: threadResponseReviewCancel,
	} = useRequest((id: string) => handleThreadResponseReview(id), {
		pollingInterval: 2000,
		pollingWhenHidden: false,
	});
	useEffect(() => {
		return () => {
			threadResponseReviewCancel();
		};
	}, []);
	const handleNavigation = () => {
		if (goalParam === "idea-refinement") {
			threadResponseReviewCancel();
			navigate(`/fd/workspace/create/${currentConversationThread._id}`);
		} else if (goalParam === "feature-refinement") {
			threadResponseReviewCancel();
			navigate(`/fd/workspace/create-feature/${currentConversationThread._id}`);
		} else if (goalParam === "feature-elaboration") {
			threadResponseReviewCancel();
			navigate(
				`/fd/workspace/elaborate-feature/${currentConversationThread._id}`
			);
		}
	};
	useEffect(() => {
		console.log("GET request successful", threadResponseReviewData);
		if (threadResponseReviewData) {
			if (threadResponseReviewData.status === "COMPLETE") {
				threadResponseReviewCancel();
				//handleNavigation();
				setCompleteAnalysis(true);
			}
			//else threadResponseReviewRun(aiConversationThread._id);
		}
	}, [threadResponseReviewData]);
	const handleThreadResponseReview = async (id: string) => {
		if (id) {
			const result = await fetch(
				`${process.env.REACT_APP_API_URL}/ai-conversation-thread/${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (result.status !== 200 && result.status !== 201) {
				throw new Error("Network response was not ok");
			}
			const responseData = await result.json();

			return responseData;
		}
	};

	useEffect(() => {
		// Add your code here
		console.log(props);
		console.log(aiConversationContext);
		if (!currentConversationThread) {
			return;
		} else {
			// setAiConversationThread(currentConversationThread._id);
			threadResponseReviewRun(currentConversationThread._id);
		}
	}, []);

	return completeAnalysis ? (
		<Button onClick={handleNavigation}>Continue</Button>
	) : (
		<Flex
			vertical
			style={{
				height: "100vh",
			}}
		>
			<Flex justify="center">
				<>
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
					/>
					<Spin size="large" />
				</>
			</Flex>{" "}
			<Flex justify="center">
				<h3>Analyzing the conversation for next steps...</h3>
			</Flex>
		</Flex>
	);
};

export default PostChatProcess;
