/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Layout,
	Avatar,
	Button,
	Typography,
	Flex,
	Popover,
	Tooltip,
	Image,
	Input,
	Dropdown,
	MenuProps,
	ConfigProvider,
	Badge,
	Grid,
	Menu,
} from "antd";
import {
	UserOutlined,
	SettingOutlined,
	LogoutOutlined,
	BellOutlined,
	SearchOutlined,
	DownCircleOutlined,
	DownOutlined,
	SwapOutlined,
	AppstoreAddOutlined,
	AppstoreOutlined,
	MoreOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

import "./AppHeader.css";
import { msalInstance } from "..";
import { useEffect, useState } from "react";
import Notification from "../pages/Notification";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
// import AccountStore, { AccountStoreType } from "../stores/AccountStore";
import usePlatformStore, { PlatformStoreType } from "../stores/platformStore";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
import ValidateAuthenticationModel from "../component/ValidateAuthenticationModel";
import { useEventListener } from "ahooks";
//import CommandSearch from "../component/CommandSearch";
import useWorkspaceStore, {
	WorkspaceStoreState,
} from "../stores/WorkspaceState";
import useAIActivityStore from "../stores/AIActivityStore";

import NotificationNavButton from "../component/NotificationNavButton";
import AlchemiAINavButton from "../component/AlchemiNavButton";
import { motion } from "framer-motion";
import CommandSearchV2 from "../component/CommandSearchV2";
import ValidateAuthentication from "../component/auth/ValidateAuthentication";
import PopupMenuPopover from "../pages/Planner/PopupMenuModal";
import AlchemiAINavButtonV2 from "../component/AlchemiNavButtonV2";
// import Sider from "antd/es/layout/Sider";

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

//import AppNavbar from "./AppNavbar";

const { Header, Sider } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid;
const AppHeader: React.FC = () => {
	// const {
	// 	token: { colorPrimaryBg },
	// } = theme.useToken();
	const navigate = useNavigate();
	const location = useLocation();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	// const accountStoreInstance = AccountStore() as AccountStoreType;
	const usePlatformStoreInstance = usePlatformStore() as PlatformStoreType;
	const useWorkspaceStoreInstance = useWorkspaceStore() as WorkspaceStoreState;
	const [abilities, setAbilities] = useState(ability);

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const { currentAIActivities } = useAIActivityStore((state: any) => ({
		currentAIActivities: state.currentAIActivities,
		setCurrentAIActivities: state.setCurrentAIActivities,
	}));
	const { profile } = userProfileStore((state: any) => ({
		profile: state.profile,
	}));

	const [workspaceItems, setWorkspaceItems] = useState<MenuProps["items"]>([]);

	const handleSettingsClick = () => {
		navigate("/settings/role");
	};
	// useEffect(() => {
	// 	setWorkspaceItems

	// }, []);
	const items: MenuProps["items"] = [
		{
			key: "1",
			label: (
				<a onClick={() => navigate("/fd/workspace/list")}>Switch Workspace</a>
			),
		},
		{
			key: "2",
			label: (
				<a onClick={() => navigate("/fd/workspace/settings/tag")}>
					Workspace Settings
				</a>
			),
		},
	];
	const onLogout = () => {
		// localStorage.clear();
		// console.log('User logged out');
		// navigate("/");
		userProfileStoreInstance.clearStore();
		// accountStoreInstance.clearStore();
		usePlatformStoreInstance.clearStore();
		useWorkspaceStoreInstance.clearStore();
		localStorage.clear();
		const logoutConfig = {
			account: msalInstance.getAllAccounts()[0],
			postLogoutRedirectUri: process.env.REACT_APP_AZURE_AD_LOGOUT_REDIRECT_URL,
		};
		try {
			msalInstance.logoutRedirect(logoutConfig).then(() => {
				navigate("/");
			});
		} catch (error) {
			console.log("Error during logout:", error);
		}
	};

	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const [openCommandDialog, setOpenCommandDialog] = useState(false);
	const [ctrlModeActive, setControlModeActive] = useState(false);

	useEventListener("keydown", (ev) => {
		if (ev.code && ev.code === "KeyM" && ctrlModeActive)
			setOpenCommandDialog(true);
		else if (ev.code && ev.code.startsWith && ev.code.startsWith("Control"))
			setControlModeActive(true);
	});

	useEventListener("keyup", (ev) => {
		if (ev.code && ev.code.startsWith && ev.code.startsWith("Control"))
			setControlModeActive(false);
	});

	useEventListener("keydown", (ev) => {});

	const showCommandDialog = () => {
		setOpenCommandDialog(true);
	};

	const handleSwitchWorkspace = () => {
		const currentUrl = location.pathname;
		console.log("currentUrl", currentUrl);
		usePlatformStoreInstance.setBackNavigateUrl(currentUrl);
		navigate("/fd/workspace/list");
	};
	const { md } = useBreakpoint();

	const [showTooltipIcons, setShowTooltipIcons] = useState(false);
	const [prevTooltipIcons, setPrevTooltipIcons] = useState(false);

	const toggleTooltipIcons = () => {
		setPrevTooltipIcons(showTooltipIcons);
		setShowTooltipIcons(!showTooltipIcons);
	};

	const handleTooltipClick = (action: string) => {
		if (prevTooltipIcons) {
			setShowTooltipIcons(prevTooltipIcons);
		} else {
			// Implement your action here based on the clicked tooltip icon
		}
	};

	const { menuMode } = userProfileStore((state: any) => ({
		menuMode: state.menuMode,
	}));

	return (
		<>
			<AbilityContext.Provider value={abilities}>
				<Header
					style={{
						background: "#f5f8fe",
						height: "auto",
						padding: "0px 0px 0px 0px",
						marginTop: menuMode === "horizontal" ? "0vh" : "2vh",
					}}
				>
					<Flex justify="space-between" align="center" wrap="nowrap">
						<Flex>
							{location.pathname.includes("/space") &&
								menuMode === "horizontal" && (
									<Flex
										onClick={() => navigate("/space")}
										className="logo"
										style={{
											backgroundColor: "#0444BF",
											padding: "0px 58px 0px 18px",
											borderRadius: "0px 20px 20px 0px",
										}}
									>
										<Avatar
											shape="square"
											style={{
												backgroundColor: "#0444BF",
												margin: "22px 0px 15px 3px",
												width: "auto",
												height: "3vh",
											}}
											size={{ xs: 32, sm: 32, md: 32, lg: 32, xl: 32, xxl: 32 }}
											src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi-white-logo.svg"
										/>
										{/* {!collapsed && ( */}
										<Typography.Title
											style={{
												marginTop: "23px",
												marginLeft: "10px",
												color: "white",
												fontSize: "26px",
												fontFamily: "Poppins",
											}}
										>
											Alchemi
										</Typography.Title>
										{/* )} */}
									</Flex>
								)}
							<Flex gap={8} align="center">
								{/* {menuMode === "inline" ? (
									<Avatar
										size={48}
										style={{
											backgroundColor: "#f5f8fe",
											minWidth: "2vw",
											marginLeft: "-3vw",
										}}
										icon={<AppstoreAddOutlined style={{ color: "#0444BF" }} />}
									/>
								) : (
									<>
										{location.pathname.includes("/space") && (
											<PopupMenuPopover />
										)}
									</>
								)} */}
								{menuMode === "horizontal" &&
									location.pathname.includes("/space") && <PopupMenuPopover />}

								<Text className="space-title" style={{ minWidth: "15vw" }}>
									Space :{" "}
									{currentWorkspace && currentWorkspace.name
										? `${currentWorkspace.name}`
										: ""}{" "}
								</Text>
								<ConfigProvider
									theme={{
										components: {
											Dropdown: {
												borderRadiusLG: 9,
											},
										},
									}}
								>
									<Dropdown menu={{ items }} placement="bottomRight" arrow>
										<Button size="small">
											<motion.div whileHover={{ rotate: 60 }}>
												<SettingOutlined style={{ color: "#0444BF" }} />
											</motion.div>
										</Button>
									</Dropdown>
								</ConfigProvider>
							</Flex>
						</Flex>
						{/* <Flex gap={26}> */}
						<Flex gap={15} style={{ marginTop: "2px", marginBottom: "-2px" }}>
							{/* <AlchemiAINavButton /> */}

							<AlchemiAINavButtonV2 />

							<CommandSearchV2
								openCommandDialog={openCommandDialog}
								setOpenCommandDialog={setOpenCommandDialog}
							/>
							{/* Display hamburger menu icon only if showTooltipIcons is false */}
							{!md ? (
								// Only display menu toggle button on 'xs' and 'sm' screens
								<Dropdown
									overlay={
										<Menu>
											<Menu.Item
												key="1"
												icon={<UserOutlined />}
												onClick={() => navigate("/profile/details")}
											>
												Profile
											</Menu.Item>
											<Menu.Item
												key="2"
												icon={<SettingOutlined />}
												onClick={handleSettingsClick}
											>
												Settings
											</Menu.Item>
											<Menu.Item
												key="3"
												icon={<BellOutlined />}
												onClick={showDrawer}
											>
												Notification
											</Menu.Item>
											<Menu.Item
												key="4"
												icon={<SearchOutlined />}
												onClick={showCommandDialog}
											>
												Search
											</Menu.Item>
											<Menu.Item
												key="5"
												icon={<LogoutOutlined />}
												onClick={onLogout}
											>
												Logout
											</Menu.Item>
										</Menu>
									}
									placement="bottomRight"
									arrow
								>
									<Button
										shape="circle"
										icon={<MoreOutlined />}
										size="small"
										//style={{ marginTop: "1vh" }}
									/>
								</Dropdown>
							) : (
								<>
									{/* <CommandSearch
										openCommandDialog={openCommandDialog}
										setOpenCommandDialog={setOpenCommandDialog}
									/> */}
									<CommandSearchV2
										openCommandDialog={openCommandDialog}
										setOpenCommandDialog={setOpenCommandDialog}
									/>
									<Tooltip placement="bottom" title="Search">
										<Button
											shape="circle"
											icon={<SearchOutlined />}
											//size="middle"
											size="small"
											onClick={showCommandDialog}
											style={{ color: "#0444BF", borderColor: "#0444BF" }}
										/>
									</Tooltip>
									<NotificationNavButton />
									<Can I="SETTINGS_CAN_VIEW" a="SETTINGS_CAN_VIEW" passThrough>
										{(allowed) =>
											allowed ? (
												<Tooltip placement="bottom" title="App Settings">
													<Button
														shape="circle"
														icon={<SettingOutlined />}
														//size="middle"
														size="small"
														onClick={handleSettingsClick}
														style={{ color: "#0444BF", borderColor: "#0444BF" }}
													/>
												</Tooltip>
											) : (
												<> </>
											)
										}
									</Can>

									{/* <SettingOutlined
							shape="circle"
							
							style={{ fontSize: "19px", marginLeft: "20px" }}
						/> */}
									<Popover
										placement="bottomRight"
										content={
											<Layout style={{ background: "#ffffff" }}>
												{/* <div className="dropdown-item" style={{ cursor: 'pointer' }} onClick={onLogout}><LogoutOutlined />Logout
									
								</div> */}
												<Button
													type="link"
													style={{ cursor: "pointer" }}
													onClick={() => navigate("/profile/details")}
												>
													<UserOutlined style={{ color: "#0444BF" }} />

													<Typography.Text strong>Profile</Typography.Text>
												</Button>
												<Button
													type="link"
													style={{ cursor: "pointer" }}
													onClick={onLogout}
												>
													<LogoutOutlined
														// style={{ fontSize: "16px", color: "#000" }}
														style={{ color: "#0444BF" }}
													/>
													<Typography.Text strong>Logout</Typography.Text>
												</Button>
											</Layout>
										}
									>
										{profile?.name ? (
											profile?.profileImage ? (
												<Avatar
													style={{
														color: "white",
														backgroundColor: "#0444BF",
														verticalAlign: "middle",
														marginRight: "1vw",
													}}
													// size="default"
													size="small"
													gap={4}
													src={profile?.profileImage}
												/>
											) : (
												<Avatar
													style={{
														color: "white",
														backgroundColor: "#0444BF",
														verticalAlign: "middle",
														marginRight: "1vw",
													}}
													// size="default"
													size="small"
													gap={4}
												>
													{profile.name.charAt(0).toUpperCase()}
												</Avatar>
											)
										) : (
											<Avatar
												style={{
													color: "white",
													backgroundColor: "#0444BF",
													verticalAlign: "middle",
													marginRight: "1vw",
												}}
												// size="default"
												size="small"
												gap={4}
											>
												{"B"}
											</Avatar>
										)}
									</Popover>
								</>
							)}
						</Flex>
					</Flex>
					<ValidateAuthentication />

					{/* <Divider style={{margin:'0px'}}/> */}
				</Header>
			</AbilityContext.Provider>
			{/* Conditional rendering of vertical icons */}
		</>
	);
};

export default AppHeader;
