// ... (above your component)

import React, { useState } from "react";
import { Tag, Button, message, Layout, Input } from "antd";
import "./FeedBackForm.css";

import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import usePlatformStore, { PlatformStoreType } from "../stores/platformStore";

interface Question {
	id: number;
	text: string;
	options: string[];
}
const { Content } = Layout;
const questions: Question[] = [
	{
		id: 1,
		text: "Tell us about your business",
		options: [
			"Technology",
			"Business",
			"Photography",
			"Management",
			"Leadership",
			"Fitness",
			"Consulting",
			"Education",
			"Interior Design",
			"Travel",
			"Music",
			"Health",
			"Real estate",
			"Design",
			"Marketing",
			"Non-profit",
			"Fashion",
			"Food",
			"Restaurants",
		],
	},
	{
		id: 2,
		text: "What is your purpose of using AlchemI ?",
		options: [
			"To Explore the product",
			"To get a Validation for my work",
			"To discover how will it help me",
			"To Learn how it works",
			"To check if I can create my own application",
			"To understand how this platform works",
			"For my Research purpose",
			"To get some inspiration",
			"To get this new Experience",
			"To get an idea for my product",
			"No idea",
			"Someone referred",
		],
	},
	{
		id: 3,
		text: "How did you find us ?",
		options: [
			"Referred by a friend or colleague",
			"Searched on Google",
			"Saw an ad",
			"Read about it on a website or news article",
			"Read about it on LinkedIn",
			"Heard about it",
			"Family member/ Friend",
			"Worked with the company that developed the app",
		],
	},
];

const FeedbackForm: React.FC<any> = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const platformStore = usePlatformStore() as PlatformStoreType;
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [selectedOptions, setSelectedOptions] = useState<string[][]>([]);
	const [customAnswers, setCustomAnswers] = useState<string[]>(
		Array.from({ length: questions.length }, () => "")
	);
	const ServerLink = process.env.REACT_APP_API_URL;
	const navigate = useNavigate();
	console.log("nextscreen");
	const toggleOption = (option: string) => {
		setSelectedOptions((prevSelectedOptions) => {
			const updatedOptions = [...prevSelectedOptions];
			const questionIndex = currentQuestion;

			const isSelected = updatedOptions[questionIndex]?.includes(option);

			if (isSelected) {
				updatedOptions[questionIndex] = updatedOptions[questionIndex].filter(
					(selectedOption) => selectedOption !== option
				);
			} else {
				if (!updatedOptions[questionIndex]) {
					updatedOptions[questionIndex] = [];
				}
				updatedOptions[questionIndex].push(option);
			}

			return updatedOptions;
		});
	};

	const handleCustomAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCustomAnswers((prevCustomAnswers) => {
			const updatedCustomAnswers = [...prevCustomAnswers];
			updatedCustomAnswers[currentQuestion] = e.target.value;
			return updatedCustomAnswers;
		});
	};

	const nextQuestion = () => {
		if (currentQuestion < questions.length - 1) {
			setCurrentQuestion(currentQuestion + 1);
		}
	};

	const updateTasksData = async () => {
		try {
			await fetch(`${ServerLink}/tasks/updatebyuserprofile`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({
					status: "Closed",
				}),
			});
		} catch (error) {
			console.error(error);
		}
	};

	const submitForm = async () => {
		const feedbackData = {
			feedback_type: "FIRST_TIME_USER",
			feedback_data: questions.map((question, index) => ({
				question: question.text,
				answer: selectedOptions[index] || [customAnswers[index] || ""],
			})),
			created_by: "Kasinath",
			updated_by: "Kasinath",
		};

		try {
			await updateTasksData();

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/feedback`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(feedbackData),
				}
			);

			if (response.ok) {
				message.success("Feedback submitted successfully!");
				navigate(`${platformStore.afterfeedUrl}`);
			} else {
				message.error("Failed to submit feedback. Please try again.");
			}
		} catch (error) {
			console.error("Error submitting feedback:", error);
			message.error("An error occurred. Please try again later.");
		}
	};

	return (
		<Layout className="feedback-form-layout">
			<Content>
				<div style={{ marginTop: "15vh" }}>
					<h1 style={{ textAlign: "center" }}>
						Help us to know about you better
					</h1>
					<div className="feedback-questions">
						<div className="feedback-question-content">
							<h2 className="feedback-title">
								{questions[currentQuestion].text}
							</h2>
							<h3>Choose the field in which you will come under:</h3>
						</div>
						<div>
							{questions[currentQuestion].options.map((option, index) => (
								<Tag
									className="tag-default"
									key={index}
									onClick={() => toggleOption(option)}
									style={{
										cursor: "pointer",
										background: selectedOptions[currentQuestion]?.includes(
											option
										)
											? "rgba(24, 144, 255, 0.8)"
											: "",
										color: selectedOptions[currentQuestion]?.includes(option)
											? "white"
											: "",
									}}
								>
									{option}
								</Tag>
							))}
						</div>
						<div className="custom-asnwer-container">
							<h3>Don’t see what you are looking for?</h3>
							<Input
								className="input-for-feedback"
								placeholder="Describe your purpose"
								value={customAnswers[currentQuestion]}
								onChange={handleCustomAnswerChange}
							/>
						</div>
					</div>
					{currentQuestion < questions.length - 1 && (
						<div className="btn-for-feedback">
							<Button className="btn-skip-next" onClick={nextQuestion}>
								Skip
							</Button>
							<Button
								className="btn-skip-next"
								type="primary"
								onClick={nextQuestion}
							>
								Next
							</Button>
						</div>
					)}
					{currentQuestion === questions.length - 1 && (
						<div className="btn-for-feedback">
							<Button
								className="btn-skip-next"
								type="primary"
								onClick={submitForm}
							>
								Submit
							</Button>
						</div>
					)}
				</div>
			</Content>
		</Layout>
	);
};

export default FeedbackForm;
