import React, { useState, useEffect } from "react";
import {
	//Layout,
	//Card,
	//Table,
	Space,
	Typography,
	message,
	Popconfirm,
	List,
	Tooltip,
	Pagination,
} from "antd";
import "./RoleManagement.css";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import TagManagementNav from "./TagManagementNav";

// const { Content } = Layout;
const { Text } = Typography;

const TagManagement: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const [responseData, setResponseData] = useState<any[]>([]);
	const navigate = useNavigate();
	const [selectedTagId, setSelectedTagId] = useState(null);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [openTagModal, setOpenTagModal] = useState(false);
	const [deleteDisabled] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(15);

	const fetchTagsData = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				const filteredData = data.filter(
					(tag: { isDelete: boolean | undefined }) =>
						tag.isDelete === undefined || tag.isDelete === false
				);
				setResponseData(filteredData);
				console.log(data);
			} else if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				console.error("Error fetching tags data");
			}
		} catch (error) {
			console.error("Error fetching tags data:", error);
		}
	};

	useEffect(() => {
		fetchTagsData();
	}, []);

	useEffect(() => {
		console.log("Selected Tag ID:", selectedTagId);
	}, [selectedTagId]);

	// const handleAddTagClick = () => {
	// 	navigate("/fd/workspace/settings/tag/addTag");
	// };

	const handleEditTagClick = (id: any) => {
		setSelectedTagId(id);
		navigate(`/fd/workspace/settings/tag/edit/${id}`);
	};

	const handleDelete = async (id: any) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/tags/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({ isDelete: true }),
				}
			);
			if (response.ok) {
				setResponseData((prevData) =>
					prevData.map((tag) =>
						tag._id === id ? { ...tag, isDelete: true } : tag
					)
				);
				message.success("Tag deleted successfully");
				fetchTagsData();
			} else if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				console.error("Error deleting tag");
				const errorText = await response.text();
				console.error("Response text:", errorText);
			}
		} catch (error) {
			console.error("Error deleting tag:", error);
		}
	};

	const renderActions = (item: any) => (
		<Space size={"large"}>
			<Tooltip title="Edit">
				<Typography.Link onClick={() => handleEditTagClick(item._id)}>
					<EditOutlined style={{ color: "#0444bf" }} />
				</Typography.Link>
			</Tooltip>
			<Popconfirm
				title="Sure to Delete?"
				onConfirm={() => handleDelete(item._id)}
				disabled={deleteDisabled}
			>
				<a
					style={{
						fontSize: "16px",
						cursor: deleteDisabled ? "not-allowed" : "pointer",
					}}
				>
					<DeleteOutlined style={{ color: "#ff4d4f" }} />
				</a>
			</Popconfirm>
		</Space>
	);

	const renderListItem = (item: any) => (
		<List.Item key={item._id} style={{ padding: "0px 0" }}>
			<div className="list-item" style={{ marginLeft: "0.5vw" }}>
				<div className="list-item-description-left">
					<span
						className="work-item-title"
						style={{ width: 60, textAlign: "left" }}
					>
						{item?.name}
					</span>
					<br />
					<Text
						className="work-item-title"
						style={{ width: 60, textAlign: "left" }}
					>
						{item?.category}
					</Text>
					<br />
					<Text
						className="work-item-title"
						style={{ width: 100, textAlign: "left" }}
					>
						{item?.description}
					</Text>
				</div>
				<div className="list-item-description-right">
					<div
						style={{
							backgroundColor: item?.color,
							width: "20px",
							height: "20px",
							borderRadius: "6px",
							border: "1px solid #ccc",
							marginRight: "1vw",
						}}
					></div>
					<div>{renderActions(item)}</div>
				</div>
			</div>
		</List.Item>
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const currentPageData = responseData.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		<>
			<TagManagementNav
				openTagModal={openTagModal}
				setOpenTagModal={setOpenTagModal}
			/>
			{/* <Layout style={{ height: "100vh" }}>
				<Content className="tag-content-container">
					<Card
						data-testId="card"
						style={{
							backgroundColor: "white",
							borderRadius: "16px",
							padding: "24px",
							border: "none",
							boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
							height: "100%",
							overflow: "scroll",
						}}
					>
						<div>
							<Table
								data-testId="table"
								scroll={{ y: 240 }}
								columns={[
									{
										// title: "Name",
										title: <div style={{ fontFamily: "Poppins" }}>Name</div>,
										dataIndex: "name",
										key: "name",
										render: (text: any) => (
											<Text
												style={{
													fontSize: "14px",
													fontWeight: "500",
													fontFamily: "Open Sans",
												}}
											>
												{text}
											</Text>
										),
									},
									{
										// title: "Category",
										title: (
											<div style={{ fontFamily: "Poppins" }}>Category</div>
										),
										dataIndex: "category",
										key: "category",
										render: (text: any) => (
											<Text
												style={{
													fontSize: "14px",
													fontWeight: "500",
													fontFamily: "Open Sans",
												}}
											>
												{text}
											</Text>
										),
									},
									{
										// title: "Description",
										title: (
											<div style={{ fontFamily: "Poppins" }}>Description</div>
										),
										dataIndex: "description",
										key: "description",
										render: (text: any) => (
											<Text
												style={{
													fontSize: "14px",
													fontWeight: "500",
													fontFamily: "Open Sans",
												}}
											>
												{text}
											</Text>
										),
									},
									{
										// title: "TagColor",
										title: (
											<div style={{ fontFamily: "Poppins" }}>TagColor</div>
										),
										dataIndex: "color",
										key: "color",
										render: (color: string) => (
											<div
												style={{
													backgroundColor: color,
													width: "20px",
													height: "20px",
													borderRadius: "50%",
													border: "1px solid #ccc",
													fontFamily: "Open Sans",
												}}
											></div>
										),
									},
									{
										// title: "Edit",
										title: <div style={{ fontFamily: "Poppins" }}>Edit</div>,
										dataIndex: "",
										key: "x",
										render: (record) => (
											<Space size={"large"}>
												<a
													style={{ fontSize: "16px" }}
													onClick={() => handleEditTagClick(record.key)}
												>
													<EditOutlined style={{ color: "#0444bf" }} />
												</a>
												<Popconfirm
													title="Sure to Delete?"
													onConfirm={() => handleDelete(record.key)}
													disabled={deleteDisabled} // Disable delete if editing or delete already in progress
												>
													<a
														style={{
															fontSize: "16px",
															cursor: deleteDisabled
																? "not-allowed"
																: "pointer",
														}}
													>
														<DeleteOutlined style={{ color: "#ff4d4f" }} />
													</a>
												</Popconfirm>
											</Space>
										),
									},
								]}
								pagination={{
									pageSize: 6,
									pageSizeOptions: ["10", "20", "30"],
									showTotal: (total, range) =>
										`${range[0]}-${range[1]} of ${total} items`,
								}}
								dataSource={responseData.map((record) => ({
									key: record._id,
									name: record.name,
									category: record.category,
									description: record.description,
									color: record.color,
								}))}
							/>
						</div>
					</Card>
				</Content>
			</Layout> */}
			<div
				className="list-view-container"
				style={{
					display: "flex",
					flexDirection: "column",
					height: "76vh",
					padding: "0vw 1vw",
				}}
			>
				<div style={{ flex: "1 1 auto" }}>
					<List
						itemLayout="vertical"
						dataSource={currentPageData}
						renderItem={renderListItem}
						className="list-view"
						size="small"
					/>
				</div>
				<div
					style={{
						flex: "0 0 20px",
						textAlign: "end",
						marginTop: "3px",
						marginBottom: "10px",
					}}
				>
					<Pagination
						current={currentPage}
						total={responseData.length}
						pageSize={pageSize}
						onChange={handlePageChange}
						showSizeChanger={false}
					/>
				</div>
			</div>
		</>
	);
};

export default TagManagement;
