// import React, { useState } from "react";
// import { Modal, Form, Button, Input, Spin } from "antd";
// import { SaveOutlined } from "@ant-design/icons";

// import "react-quill/dist/quill.snow.css";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../../stores/UserProfileStore";

// interface WorkItemModalProps {
// 	visible: boolean;
// 	onCancel: () => void;
// 	data: any;
// }

// const { TextArea } = Input;

// const AddUxProcess: React.FC<WorkItemModalProps> = ({
// 	visible,
// 	onCancel,
// 	data,
// }) => {
// 	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
// 	const [form] = Form.useForm();
// 	const [saving, setSaving] = useState(false); // state to track saving status
// 	const [disableSave, setDisableSave] = useState(true); // state to disable the save button
// 	const [showDisabledIcon, setShowDisabledIcon] = useState(false); // state to control disabled icon visibility

// 	const formItemLayout = {
// 		labelCol: { span: 4 },
// 		wrapperCol: { span: 16 },
// 	};

// 	const handleFinish = (values: any) => {
// 		setSaving(true); // set saving to true when starting to save
// 		setDisableSave(true); // disable the save button
// 		setShowDisabledIcon(false); // hide disabled icon

// 		const persona = {
// 			id: values.id,
// 			user_persona: {
// 				...values,
// 			},
// 		};
// 		const updatedUserPersonas = Array.isArray(data[0]?.data?.user_personas)
// 			? [...data[0].data.user_personas]
// 			: [];
// 		const lastIndex = updatedUserPersonas.length;

// 		updatedUserPersonas[lastIndex] = {
// 			...updatedUserPersonas[lastIndex],
// 			...persona,
// 		};

// 		console.log(updatedUserPersonas, "updatedUserPersonas");

// 		const field = {
// 			...data[0],
// 			data: {
// 				...data[0].data,
// 				user_personas: updatedUserPersonas,
// 			},
// 		};

// 		// console.log(personNo);

// 		// // Create a copy of the persona array
// 		// const updatedUserPersonas = [...persona[0].aiData.user_personas];

// 		// // Update the user_persona object at the specified index
// 		// updatedUserPersonas[personNo] = {
// 		// 	...updatedUserPersonas[personNo],
// 		// 	user_persona: {
// 		// 		...field,
// 		// 	},
// 		// };

// 		// Create a new object with the updated user_personas array
// 		// const updatedData = {
// 		// 	...persona[0],
// 		// 	aiData: {
// 		// 		...persona[0].aiData,
// 		// 		user_personas: updatedUserPersonas,
// 		// 	},
// 		// };

// 		console.log(field, "field");

// 		fetch(`${process.env.REACT_APP_API_URL}/project-artifact/${data[0]._id}`, {
// 			method: "PUT",
// 			headers: {
// 				"Content-Type": "application/json; charset=UTF-8",
// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// 			},
// 			body: JSON.stringify(field),
// 		})
// 			.then((response) => {
// 				if (response.ok) {
// 					console.log("Update successful", response);
// 					// Close the modal after successful update
// 					onCancel();
// 				} else {
// 					console.error("Update failed", response);
// 				}
// 			})
// 			.catch((error) => {
// 				console.error("Error fetching data:", error);
// 			})
// 			.finally(() => {
// 				setSaving(false); // reset saving status
// 				setDisableSave(false); // re-enable the save button
// 			});
// 	};

// 	const handleValuesChange = () => {
// 		if (
// 			form.isFieldsTouched() &&
// 			form.getFieldsError().filter(({ errors }) => errors.length).length ===
// 				0 &&
// 			Object.values(form.getFieldsValue()).every(
// 				(value) => value !== undefined && value !== ""
// 			)
// 		) {
// 			setDisableSave(false); // Enable the save button
// 			setShowDisabledIcon(false); // Hide disabled icon
// 		} else {
// 			setDisableSave(true); // Disable the save button
// 			setShowDisabledIcon(true); // Show disabled icon
// 		}
// 	};

// 	return (
// 		<Modal
// 			title="Add User Experience Process"
// 			centered
// 			visible={visible}
// 			onCancel={onCancel}
// 			footer={[
// 				<Button key="back" onClick={onCancel}>
// 					Cancel
// 				</Button>,
// 				<Button
// 					key="submit"
// 					type="primary"
// 					icon={<SaveOutlined />} // use the icon component
// 					onClick={() => form.submit()}
// 					disabled={disableSave} // disable based on state
// 				>
// 					{saving ? <Spin /> : "Save"} {/* show spinner while saving */}
// 				</Button>,
// 			]}
// 			width={800}
// 		>
// 			<Form
// 				{...formItemLayout}
// 				form={form}
// 				onFinish={handleFinish}
// 				onValuesChange={handleValuesChange}
// 			>
// 				<Form.Item
// 					label="Menu"
// 					name="id"
// 					rules={[{ required: true, message: "Please input your menu!" }]}
// 				>
// 					<Input placeholder="Menu" />
// 				</Form.Item>
// 				<Form.Item
// 					label="Name"
// 					name="name"
// 					rules={[{ required: true, message: "Please input your name!" }]}
// 				>
// 					<Input placeholder="Name" />
// 				</Form.Item>
// 				<Form.Item
// 					label="Age"
// 					name="age"
// 					rules={[{ required: true, message: "Please input your age!" }]}
// 				>
// 					<Input placeholder="Age" />
// 				</Form.Item>
// 				<Form.Item
// 					label="Occupation"
// 					name="occupation"
// 					rules={[{ required: true, message: "Please input your occupation!" }]}
// 				>
// 					<Input placeholder="Occupation" />
// 				</Form.Item>
// 				<Form.Item
// 					label="Biography"
// 					name="biography"
// 					rules={[{ required: true, message: "Please input your Biography!" }]}
// 				>
// 					<TextArea rows={4} placeholder="Biography" />
// 				</Form.Item>
// 				<Form.Item
// 					label="Pain Point"
// 					name="pain_points"
// 					rules={[{ required: true, message: "Please input your Pain Point!" }]}
// 				>
// 					<TextArea rows={4} placeholder="Pain Point" />
// 				</Form.Item>
// 				<Form.Item
// 					label="Motivation"
// 					name="motivations"
// 					rules={[{ required: true, message: "Please input your Motivation!" }]}
// 				>
// 					<TextArea rows={4} placeholder="Motivation" />
// 				</Form.Item>
// 			</Form>
// 			{showDisabledIcon} {/* Show disabled icon if necessary */}
// 		</Modal>
// 	);
// };

// export default AddUxProcess;

// WorkItemModal.tsx
import React from "react";
import { Modal, Form, Button, Input } from "antd";

import "react-quill/dist/quill.snow.css";
//import { update } from "lodash";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

interface WorkItemModalProps {
	visible: boolean;
	onCancel: () => void;
	data: any;
}
const { TextArea } = Input;

const AddUxProcess: React.FC<WorkItemModalProps> = ({
	visible,
	onCancel,
	data,
}) => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [form] = Form.useForm();
	console.log(data, "data");
	const formItemLayout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 16 },
	};

	const handleFinish = (values: any) => {
		const persona = {
			id: values.id,
			role_id: "customer",
			user_persona: {
				...values,
			},
		};
		const updatedUserPersonas = [...data[0].data];
		console.log(updatedUserPersonas, "updatedUserPersonas");
		const lastIndex = updatedUserPersonas.length;

		updatedUserPersonas[lastIndex] = {
			...updatedUserPersonas[lastIndex],
			...persona,
		};

		console.log(updatedUserPersonas, "updatedUserPersonas");

		const field = {
			...data[0],
			data: [
				// ...data[0].data,
				...updatedUserPersonas,
			],
		};

		// console.log(personNo);

		// // Create a copy of the persona array
		// const updatedUserPersonas = [...persona[0].aiData.user_personas];

		// // Update the user_persona object at the specified index
		// updatedUserPersonas[personNo] = {
		//  ...updatedUserPersonas[personNo],
		//  user_persona: {
		//      ...field,
		//  },
		// };

		// Create a new object with the updated user_personas array
		// const updatedData = {
		//  ...persona[0],
		//  aiData: {
		//      ...persona[0].aiData,
		//      user_personas: updatedUserPersonas,
		//  },
		// };

		console.log(field, "field");

		fetch(`${process.env.REACT_APP_API_URL}/project-artifact/${data[0]._id}`, {
			method: "PUT",

			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(field),
		})
			.then((response) => {
				if (response.ok) {
					console.log("Update successful", response);
					// Close the modal after successful update
					onCancel();
				} else {
					console.error("Update failed", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	};

	return (
		<Modal
			title="Add User Experience Process"
			centered
			open={visible}
			onCancel={onCancel}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={() => form.submit()}>
					Save
				</Button>,
			]}
			width={800}
		>
			<Form {...formItemLayout} form={form} onFinish={handleFinish}>
				<Form.Item label="Menu" name="id">
					<Input placeholder="Menu" />
				</Form.Item>
				<Form.Item label="Name" name="name">
					<Input placeholder="Name" />
				</Form.Item>
				<Form.Item label="Age" name="age">
					<Input placeholder="Age" />
				</Form.Item>
				<Form.Item label="Occupation" name="occupation">
					<Input placeholder="Occupation" />
				</Form.Item>

				<Form.Item label="Biography" name="biography">
					<TextArea rows={4} placeholder="Biography" />
				</Form.Item>
				<Form.Item label="Pain Point" name="pain_points">
					<TextArea rows={4} placeholder="Pain Point" />
				</Form.Item>
				<Form.Item label="Motivation" name="motivations">
					<TextArea rows={4} placeholder="Motivation" />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddUxProcess;
