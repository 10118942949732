import { Button, Flex, Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../pages/Editors/BreadCrumb";
import breadcrumbConfig from "../util/BreadCrumbsUtil";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
// import usePlatformStore from "../stores/platformStore";
import { useRequest } from "ahooks";
import { getConnection } from "../api/ConnectionAPI";

const TaskRunNav: React.FC = () => {
	const [openTaskRunModal, setOpenTaskRunModal] = React.useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [taskRunName, setTaskRunName] = React.useState("");
	const [taskRunType, setTaskRunType] = React.useState("");
	const [integration, setIntegration] = React.useState("");
	//const [status, setStatus] = React.useState("open");
	const [connectionName, setConnectionName] = React.useState("");
	const [ConnectionsData, setConnectionsData] = useState<any[]>([]);
	// const [statusOptions, setStatusOptions] = useState([]);

	const { data: connectionsData, run: fetchConnections } = useRequest(
		() => getConnection(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Connections data");
				// message.info("Conection Setting");
			},
			onError: (error) => {
				message.error(`Failed to fetch Connections : ${error.message}`);
				console.error("Error fetching connections data:", error);
			},
		}
	);

	useEffect(() => {
		if (connectionsData) {
			setConnectionsData(connectionsData);
		}
	}, [connectionsData]);

	useEffect(() => {
		fetchConnections();
	}, []);

	const handleCreateTaskRun = () => {
		const APIUrl = `${process.env.REACT_APP_API_URL}/taskrun`;
		const data = {
			name: taskRunName,
			type: taskRunType,
			workspaceId: currentWorkspace._id,
			userProfileId: userProfileStoreInstance.profile._id,
			config: {
				integration: integration,
				connection: connectionName,
			},
			status: "open",
			createdBy: userProfileStoreInstance.profile._id,
			updatedBy: userProfileStoreInstance.profile._id,
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
		};
		fetch(APIUrl, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Success:", data);

				setOpenTaskRunModal(false);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<Flex
			justify="space-between"
			align="center"
			style={{
				padding: "20px 50px 20px 50px",
				margin: "4px 0 0px",
				backgroundColor: "rgb(245, 248, 254)",
			}}
		>
			<Flex align="center" gap={16}>
				<BreadCrumb config={breadcrumbConfig.taskRuns} />
			</Flex>

			<>
				<Button type="primary" onClick={() => setOpenTaskRunModal(true)}>
					Create TaskRun
				</Button>

				<Modal
					title="Create TaskRun"
					visible={openTaskRunModal}
					onCancel={() => setOpenTaskRunModal(false)}
					onOk={handleCreateTaskRun}
				>
					<Form.Item
						label="Name"
						required
						style={{ width: "100%", marginLeft: "60px" }}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 16 }}
					>
						<Input
							style={{ width: "70%", marginLeft: "60px" }}
							value={taskRunName}
							onChange={(e) => setTaskRunName(e.target.value)}
						/>
					</Form.Item>
					<Form>
						<Form.Item
							label="Type"
							required
							style={{ width: "100%", marginLeft: "64px" }}
							labelCol={{ span: 6 }}
							wrapperCol={{ span: 16 }}
						>
							<Select
								defaultValue=""
								style={{ width: "50%", marginLeft: "20px" }}
								onChange={(value) => setTaskRunType(value)}
								options={[
									{ value: "Import", label: "Import" },
									{ value: "Export", label: "Export" },
								]}
							/>
						</Form.Item>
					</Form>
					<Form>
						<Form.Item
							label="Integration"
							required
							style={{ width: "100%", marginLeft: "64px" }}
							labelCol={{ span: 6 }}
							wrapperCol={{ span: 16 }}
						>
							<Select
								defaultValue=""
								style={{ width: "50%", marginLeft: "20px" }}
								onChange={(value) => setIntegration(value)}
								options={[{ value: "JIRA", label: "JIRA" }]}
							/>
						</Form.Item>
						<Form.Item
							label="Connection"
							required
							style={{ width: "100%", marginLeft: "64px" }}
							labelCol={{ span: 6 }}
							wrapperCol={{ span: 16 }}
						>
							<Select
								defaultValue=""
								style={{ width: "50%", marginLeft: "20px" }}
								onChange={(value) => setConnectionName(value)}
								options={ConnectionsData.map((connection) => ({
									value: connection._id,
									label: connection.name,
								}))}
							/>
						</Form.Item>
					</Form>
				</Modal>
			</>
		</Flex>
	);
};

export default TaskRunNav;
