/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Flex, Image, Steps, theme } from "antd";
//import ProductInfo from "../component/ProductInfo";
import ModuleCheck from "../component/ModuleCheck";
import useWorkspaceStore from "../stores/WorkspaceState";
import ProductInfoV2 from "../component/ProductInfoV2";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

const AddWorkspace: React.FC = () => {
	const [abilities, setAbilities] = useState(ability);
	const { token } = theme.useToken();
	const [current, setCurrent] = useState(0);

	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const steps = [
		{
			title: "Product Idea details",
			content: <ProductInfoV2 next={next} />,
		},
		{
			title: "Module selection",
			content: <ModuleCheck next={next} prev={prev} />,
		},
	];

	const items = steps.map((item) => ({ key: item.title, title: item.title }));
	const { addWorkspaceStepsConfig, setAddWorkspaceStepsConfig } =
		useWorkspaceStore((state: any) => ({
			addWorkspaceStepsConfig: state.addWorkspaceStepsConfig,
			setAddWorkspaceStepsConfig: state.setAddWorkspaceStepsConfig,
		}));

	useEffect(() => {
		if (
			addWorkspaceStepsConfig &&
			addWorkspaceStepsConfig.isProductInfoValid &&
			steps[current].title == "Product Idea details"
		)
			setAddWorkspaceStepsConfig({ enableNextStep: true });
		else setAddWorkspaceStepsConfig({ enableNextStep: false });

		if (
			addWorkspaceStepsConfig &&
			addWorkspaceStepsConfig.isModuleInfoValid &&
			steps[current].title == "Module selection"
		)
			setAddWorkspaceStepsConfig({ enableNextStep: true });
		else setAddWorkspaceStepsConfig({ enableNextStep: false });
	}, []);

	const contentStyle: React.CSSProperties = {
		lineHeight: "260px",
		textAlign: "center",
		color: "#F5F8FE",
		backgroundColor: "#F5F8FE",
		borderRadius: token.borderRadiusLG,
		///border: `1px dashed ${token.colorBorder}`,
	};

	return (
		<>
			<AbilityContext.Provider value={abilities}>
				<Can I="WORKSPACE_CAN_CREATE" a="WORKSPACE_CAN_CREATE" passThrough>
					{(allowed) =>
						allowed ? (
							<>
								<Steps
									current={current}
									items={items}
									style={{ padding: "40px 100px 0px 100px" }}
								/>
								{/* <div style={{ padding: "12px 12px 12px 12px" }}>
				{current < steps.length - 1 && (
					<Button
						type="primary"
						onClick={() => next()}
						style={{ float: "right", marginRight: "4vw" }}
						disabled={addWorkspaceStepsConfig && !addWorkspaceStepsConfig.enableNextStep}
					>
						Next
					</Button>
				)}
				{current === steps.length - 1 && (
					<Button
						type="primary"
						onClick={() => message.success("Processing complete!")}
						style={{ float: "right", marginRight: "4vw" }}
					>
						Done
					</Button>
				)}
				{current > 0 && (
					<Button
						style={{ float: "left", marginLeft: "4vw" }}
						onClick={() => prev()}
					>
						Previous
					</Button>
				)}
			</div> */}
								<div style={contentStyle}>{steps[current].content}</div>
							</>
						) : (
							<Flex style={{ marginTop: "15vh" }} justify="center">
								<Image
									height={"50vh"}
									width={"60vw"}
									src={
										"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
									}
									preview={false}
								/>
							</Flex>
						)
					}
				</Can>
			</AbilityContext.Provider>
		</>
	);
};

export default AddWorkspace;
