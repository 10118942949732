import React, { useEffect, useState } from "react";
import {
	Card,
	Row,
	Col,
	Tag,
	Popover,
	Flex,
	Typography,
	Button,
	Input,
	message,
	Result,
	Spin,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import "./AdvancePatterns.css";
import { useParams } from "react-router-dom";
import useWorkspaceStore from "../../stores/WorkspaceState";
import usePlatformStore from "../../stores/platformStore";
import { getNavigateUrlForWorkspaces } from "../../util/NavigateAssistUtil";
import Constants from "../../util/ConstantsUtil";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";

const AdvancedPatterns: React.FC = () => {
	// const [comments, setComments] = useState<any[]>([]);
	const [newCommentContent, setNewCommentContent] = useState<string>("");
	//const [openCommentBox, setOpenCommentBox] = useState(false);
	const [contextMenuPosition, setContextMenuPosition] = useState<{
		x: number;
		y: number;
	} | null>(null);
	const [selectedPath, setSelectedPath] = useState<string | null>(null);
	//const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);

	const { isReviewEnabled } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
		isReviewEnabled: state.isReviewEnabled,
	}));
	const [contentClassName, setContentClassName] = useState(
		"StrategicDesignContent"
	);
	const [isPopoverVisible, setPopoverVisibility] = useState(false);
	const [commentPosted, setCommentPosted] = useState(false);
	const [data, setData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(true); // New loading state
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [designSpecData, setDesignSpecData] = useState<any>(null);
	const { id } = useParams<{ id: string }>();
	const { currentWorkspace, setCurrentActivity } = useWorkspaceStore(
		(state: any) => ({
			currentWorkspace: state.currentWorkspace,
			setCurrentActivity: state.setCurrentActivity,
		})
	);
	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
		setUnauthorizedModel,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	useEffect(() => {
		const navigationUrls = getNavigateUrlForWorkspaces(
			`${id}`,
			currentWorkspace?.modules || [],
			Constants.DESIGN_ENGINEERING,
			Constants.DE_DDD_ADVANCE_PATTERN
		);
		setNextNavigateUrl(navigationUrls.nextUrl);
		setBackNavigateUrl(navigationUrls.prevUrl);
		setAppBarTitle(Constants.DE_DDD_ADVANCE_PATTERN);
		setCurrentModule(Constants.DESIGN_ENGINEERING);
		setCurrentPage(Constants.DE_DDD_ADVANCE_PATTERN);
	}, [currentWorkspace]);
	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			const fetchData = async () => {
				try {
					const response = await fetch(
						`${process.env.REACT_APP_API_URL}/project-artifact/de-ddd-spec/${id}`,
						{
							headers: {
								Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
							},
						}
					);

					const data = await response.json();
					if (!response.ok) {
						if (response.status === 401) {
							setUnauthorizedModel(true);
						}
						throw new Error(
							`Status: ${response.status} Message: ${data.message}`
						);
					}
					setData(data);
					setCurrentActivity(data[0]);

					setDesignSpecData(data[0].data.advanced_patterns);
				} catch (error) {
					message.error(`Error: ${error}`);
					console.error("Error fetching data:", error);
				} finally {
					setIsLoading(false); // Set isLoading to false after data fetching
				}
			};

			fetchData();
		}
	}, [userProfileStoreInstance.idToken]);

	useEffect(() => {
		setContentClassName(
			isReviewEnabled ? "StrategicDesignContentHover" : "StrategicDesignContent"
		);
	}, [isReviewEnabled]);

	const handleRightClick = (e: React.MouseEvent) => {
		e.preventDefault();

		if (isReviewEnabled) {
			setPopoverVisibility(true);
			// setContentClassName(isCommentButtonClicked ? "FeatureSpecificationLayoutContentHover" : "FeatureSpecificationLayoutContent");
			const clickedElement = e.target as HTMLElement;
			const clickedPath = clickedElement.dataset.path || "";
			console.log(clickedPath); // Use an empty string as a fallback
			setContextMenuPosition({ x: e.clientX, y: e.clientY });
			console.log(clickedPath, "clickedpaths");
			setSelectedPath(clickedPath);
		} else {
			setContentClassName("FeatureSpecificationLayoutContent");
		}
	};

	// const addComment = (content: string, path: any) => {
	// 	const newComment: any = {
	// 		content,
	// 		path,
	// 	};
	// 	setComments([...comments, newComment]);
	// };

	const postComment = () => {
		const newComment = {
			comment: newCommentContent,
			path: selectedPath,
		};

		const newData = [...data];
		const userReview = newData[0].userReview || [];
		userReview.push(newComment);
		newData[0].userReview = userReview;
		setCommentPosted(true);

		setPopoverVisibility(false);

		setNewCommentContent("");
		setContextMenuPosition(null);

		fetch(`${process.env.REACT_APP_API_URL}/describe/${id}/de-ddd-spec`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(newData[0]),
		})
			.then((response) => response.json())
			.then((res) => {
				console.log("dev", res);
			})
			.catch((error) => {
				console.log(error);
			});

		setCommentPosted(true);
	};
	// ...

	// const handleCommentButtonClick = () => {
	// 	setContentClassName(setClassNameBasedOnCommentButton());
	// 	setCommentButtonClicked(!isCommentButtonClicked);
	// 	setContextMenuPosition(null);
	// };

	console.log(commentPosted);

	return (
		<div style={{ backgroundColor: "#f5f8fe", margin: "18px" }}>
			{/* { designSpecData  && (  */}
			<Card
				className="main-card-container content-dimensions"
				style={{ padding: "0px 0px 0px 12px" }}
			>
				<h2
					data-testid="advanced-patterns"
					style={{ textAlign: "left", marginTop: "-12px" }}
				>
					Advanced Patterns{" "}
				</h2>
				{isLoading ? (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							minHeight: "50vh",
						}}
					>
						<Spin size="large" />
						<img
							style={{ marginTop: "10px", width: "150px" }}
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
							alt="Loading"
						/>
						<Spin size="large" />
					</div>
				) : designSpecData ? (
					<>
						<Row gutter={[12, 12]} style={{ marginTop: "16px", width: "65vw" }}>
							{designSpecData?.map((item: any) => (
								<Col span={12} key={item.id}>
									<Card
										className="AdvancedPatternsCard"
										style={{ padding: "10px" }}
									>
										<p
											data-testid="advanced-patterns-card"
											className="bold-text"
										>
											{item.description}
										</p>
										<div
											onContextMenu={handleRightClick}
											className={contentClassName}
											data-path={`/de/}`}
										>
											<p className="sub-bold-text">Components</p>
											<div className="tag-container">
												{item.components.map(
													(component: string, index: number) => (
														<Tag key={index} className="tag-ddd" color="blue">
															{component}
														</Tag>
													)
												)}
											</div>
										</div>
										<div
											onContextMenu={handleRightClick}
											className={contentClassName}
											data-path={`/de/}`}
										>
											<p className="sub-bold-text">Usage Scenarios</p>
											<ul className="usage-scenarios">
												{item.usage_scenarios.map(
													(scenario: string, index: number) => (
														<li key={index}>{scenario}</li>
													)
												)}
											</ul>
										</div>
									</Card>
								</Col>
							))}
						</Row>
						{contextMenuPosition && (
							<Popover
								title={
									<Flex justify="space-between">
										<Typography.Title level={5}>
											{" "}
											Add a Comment{" "}
										</Typography.Title>
										<Button
											icon={<CloseOutlined />}
											size="small"
											shape="circle"
											onClick={() => setContextMenuPosition(null)}
										></Button>
									</Flex>
								}
								content={
									<div>
										<Input.TextArea
											style={{ height: "15vh", marginBottom: "1vh" }}
											placeholder="Type your comment here..."
											onChange={(e) => setNewCommentContent(e.target.value)}
											value={newCommentContent}
										/>
										<Button type="primary" onClick={postComment}>
											Post Comment
										</Button>
									</div>
								}
								trigger="click"
								//open={openCommentBox}
								open={isPopoverVisible}
								onOpenChange={(visible) =>
									visible || setContextMenuPosition(null)
								}
							>
								<div
									className="NewCommentSectio"
									style={{
										position: "fixed",
										top: contextMenuPosition.y + "px",
										left: contextMenuPosition.x + "px",
									}}
								>
									{/* Placeholder for comment button */}
									<Button type="link"></Button>
								</div>
							</Popover>
						)}
					</>
				) : (
					<Result
						icon={<SmileOutlined style={{ color: "#0444BF" }} />}
						title="There are No Pattern Found!"
						style={{ marginTop: "10vh", marginLeft: "-10vw" }}
					/>
				)}
			</Card>
			{/* )} */}
		</div>
	);
};

export default AdvancedPatterns;
