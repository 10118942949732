import React, { useState } from "react";
import "./FeatureNav.css";
import {
	DownOutlined,
	UnorderedListOutlined,
	UserOutlined,
	AppstoreFilled,
	SearchOutlined,
	ArrowLeftOutlined,
} from "@ant-design/icons";
import {
	Flex,
	Dropdown,
	Button,
	Space,
	Input,
	TreeSelect,
	Menu,
	Tooltip,
	Breadcrumb,
} from "antd";
// import WorkItemModal from "./AddWorkitemModel";
import { usePlanStore } from "../../../stores/PlanStore";
import { useLocation, useNavigate } from "react-router-dom";
import CreateFeaturePlanModel from "./CreateFeaturePlanModel";
import Constants from "../../../util/ConstantsUtil";

function FeatureNav() {
	const url = useLocation().pathname;
	const navigate = useNavigate();
	console.log(url);
	const [modalVisible, setModalVisible] = useState(false);
	const {
		setListView,
		setCardView,
		input,
		setInput,
		setfilter,
		selectedGroupBy,
		setSelectedGroupBy,
		urlData,
		setSortBy,
	} = usePlanStore();

	const sortItems = [
		{
			label: "Created date (Asc)",
			key: "created-asc",
		},
		{
			label: "Created date (Desc)",
			key: "created-desc",
		},
	];

	const treeData = [
		{
			value: Constants.TYPE, // change to CAPS
			title: Constants.TYPE,
			children: [
				{
					value: Constants.CAPS_TYPE_FEATURE,
					title: Constants.FEATURE,
				},
				{
					value: Constants.CAPS_TYPE_USER_STORY,
					title: Constants.USER_STORY,
				},
			],
		},
		{
			value: Constants.PRIORITY, // change to CAPS
			title: Constants.PRIORITY,
			children: [
				{
					value: Constants.KANO, // change to CAPS
					title: Constants.KANO,
					children: [
						{
							value: Constants.CAPS_PRIORITY_BASIC_NEEDS,
							title: Constants.PRIORITY_BASIC_NEEDS,
						},
						{
							value: Constants.CAPS_PRIORITY_PERFORMANCE_NEEDS,
							title: Constants.PRIORITY_PERFORMANCE_NEEDS,
						},
						{
							value: Constants.CAPS_PRIORITY_EXCITEMENT_NEEDS,
							title: Constants.PRIORITY_EXCITEMENT_NEEDS,
						},
					],
				},
				{
					value: Constants.MOSCOW, // change to CAPS
					title: Constants.MOSCOW,
					children: [
						{
							value: Constants.CAPS_PRIORITY_MUST_HAVE,
							title: Constants.MUST_HAVE,
						},
						{
							value: Constants.CAPS_PRIORITY_SHOULD_HAVE,
							title: Constants.SHOULD_HAVE,
						},
						{
							value: Constants.CAPS_PRIORITY_COULD_HAVE,
							title: Constants.COULD_HAVE,
						},
						{
							value: Constants.CAPS_PRIORITY_WONT_HAVE,
							title: Constants.WONT_HAVE,
						},
					],
				},
			],
		},
		{
			value: Constants.STATUS, //change to CAPS
			title: Constants.STATUS,
			children: [
				{
					value: Constants.CAPS_IN_PROGRESS,
					title: Constants.IN_PROGRESS,
				},
				{
					value: Constants.CAPS_OPEN,
					title: Constants.OPEN,
				},
				{
					value: Constants.CAPS_CLOSED,
					title: Constants.CLOSED,
				},
			],
		},
	];

	const [value, setValue] = useState<any[]>([]);

	const onChange = (newValue: any[]) => {
		const filteredValues = newValue.filter((val: any) => {
			return !treeData.some((item) => item.value === val);
		});
		setValue(filteredValues);
		setfilter(filteredValues);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
	};

	const handleChange = (event: any) => {
		setInput(event.target.value); // Update input value
	};

	const handleAddWorkItemClick = () => {
		setModalVisible(true);
	};

	const items = [
		{
			label: "Type",
			key: "1",
			icon: <UserOutlined />,
		},
		{
			label: "Priority",
			key: "2",
			icon: <UserOutlined />,
		},
		{
			label: "Status",
			key: "3",
			icon: <UserOutlined />,
		},
		{
			label: "None",
			key: "4",
			icon: <UserOutlined />,
		},
	];

	const handleGroupByChange = ({ key }: { key: React.Key }) => {
		const selectedItem = items.find((item) => item.key === key);
		if (selectedItem) {
			setSelectedGroupBy(selectedItem.label);
		}
	};

	const handleSortByChange = ({ key }: { key: React.Key }) => {
		const selectedItem = sortItems.find((item) => item.key === key);
		if (selectedItem) {
			setSortBy(selectedItem.label as any);
		}
	};

	return (
		<div style={{ backgroundColor: "#F5F8FE" }}>
			<div style={{ marginTop: "3vh" }}>
				<Flex justify="space-between" align="center">
					<Flex style={{ alignItems: "center", marginLeft: "3vw" }}>
						<Space>
							<Tooltip placement="bottom" title="Back">
								<Button
									shape="circle"
									icon={<ArrowLeftOutlined />}
									size="large"
								/>
							</Tooltip>
							<Flex vertical>
								<Breadcrumb>
									<Breadcrumb.Item>
										<h3 color="#87d068" style={{ fontSize: "1.1rem" }}>
											Feature Plan
										</h3>
									</Breadcrumb.Item>
								</Breadcrumb>
							</Flex>
						</Space>
					</Flex>
					<Flex justify="end" gap={"small"}>
						<Button
							shape="round"
							size="middle"
							onClick={() => navigate(urlData.newUrl)}
						>
							{urlData.screen}
						</Button>

						<Input
							suffix={<SearchOutlined />}
							size="middle"
							style={{ height: "3.9vh", width: "17vw", fontSize: "0.9rem" }}
							placeholder="Input search text"
							onChange={handleChange}
							value={input}
						/>
						<Dropdown
							overlay={
								<Menu onClick={handleGroupByChange}>
									{items.map((item) => (
										<Menu.Item key={item.key} icon={item.icon}>
											{item.label}
										</Menu.Item>
									))}
								</Menu>
							}
							trigger={["click"]}
						>
							<Button size="middle" shape="round">
								<Space>
									Group by
									{selectedGroupBy === "Release" &&
									url === "/space/planner/backlogs"
										? "None"
										: selectedGroupBy}
									<DownOutlined />
								</Space>
							</Button>
						</Dropdown>

						<Dropdown
							overlay={
								<Menu onClick={handleSortByChange}>
									{sortItems.map((item) => (
										<Menu.Item key={item.key}>{item.label}</Menu.Item>
									))}
								</Menu>
							}
							trigger={["click"]}
						>
							<Button size="middle" shape="round">
								<Space>
									Sort by
									<DownOutlined />
								</Space>
							</Button>
						</Dropdown>
						<Flex>
							<TreeSelect
								showSearch
								size="small"
								style={{ width: "8vw", height: "100%" }}
								value={value}
								dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
								placeholder="Filter By"
								allowClear
								multiple
								treeDefaultExpandAll={false}
								onChange={onChange}
								treeData={treeData}
							/>
						</Flex>

						<Flex>
							<Button
								size="small"
								type="text"
								style={{ fontSize: "1.2rem" }}
								onClick={setListView}
							>
								<UnorderedListOutlined />
							</Button>
							<Button
								size="small"
								type="text"
								style={{ fontSize: "1.2rem" }}
								onClick={setCardView}
							>
								<AppstoreFilled />
							</Button>
						</Flex>
						<Button
							size="middle"
							shape="round"
							type="primary"
							onClick={handleAddWorkItemClick}
						>
							Add Feature Plan
						</Button>
					</Flex>
				</Flex>

				<CreateFeaturePlanModel
					visible={modalVisible}
					onCancel={handleModalCancel}
				/>
			</div>
		</div>
	);
}
export default FeatureNav;
