/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { Badge, Button, Menu, Popover, Input, message, Flex } from "antd";
import {
	CheckCircleFilled,
	FilterOutlined,
	Loading3QuartersOutlined,
	HarmonyOSOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import "./Popover.css";

import { planerViewStore } from "../../stores/PlannerStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

const StatusFilterComponent = ({ type }: { type: string }) => {
	const { selectedItemDetails, setSelectedItemDetails } = planerViewStore();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const initialMenuItems = [
		{ key: "open", label: "Open", icon: <HarmonyOSOutlined /> },
		{
			key: "in-progress",
			label: "In Progress",
			icon: <Loading3QuartersOutlined />,
		},
		{ key: "completed", label: "Completed", icon: <CheckCircleFilled /> },
	];

	const [menuItems, setMenuItems] = useState(initialMenuItems);
	const [popoverVisible, setPopoverVisible] = useState(false);
	const [selectedIcon, setSelectedIcon] = useState<JSX.Element>(
		<FilterOutlined />
	);

	const statusIconMap: {
		[key in "OPEN" | "IN_PROGRESS" | "COMPLETED"]: JSX.Element;
	} = {
		OPEN: <HarmonyOSOutlined />,
		IN_PROGRESS: <Loading3QuartersOutlined />,
		COMPLETED: <CheckCircleFilled />,
	};

	const getDefaultIcon = (status: "OPEN" | "IN_PROGRESS" | "COMPLETED") => {
		return statusIconMap[status] || <FilterOutlined />;
	};

	useEffect(() => {
		setSelectedIcon(
			getDefaultIcon(
				selectedItemDetails.status as "OPEN" | "IN_PROGRESS" | "COMPLETED"
			)
		);
	}, [selectedItemDetails.status]);

	const onChangeValue = (event: any) => {
		const value = event.target.value.toLowerCase();
		const filteredItems = initialMenuItems.filter((item) =>
			item.label.toLowerCase().includes(value)
		);
		setMenuItems(filteredItems);
	};

	const handleMenuClick = async (item: any) => {
		const statusMap: any = {
			open: "OPEN",
			"in-progress": "IN_PROGRESS",
			completed: "COMPLETED",
		};

		const newStatus = statusMap[item.key];
		const newIcon = statusIconMap[
			newStatus as "OPEN" | "IN_PROGRESS" | "COMPLETED"
		] || <FilterOutlined />;

		const requestOptions = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify({ status: newStatus }),
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				requestOptions
			);
			if (response.ok) {
				// Update local state
				setSelectedItemDetails({
					...selectedItemDetails,
					status: newStatus,
				});
				message.success("Status updated successfully");
				setSelectedIcon(newIcon); // Update the button icon
				setPopoverVisible(false);
			} else {
				message.error("Failed to update status");
			}
		} catch (error) {
			console.error("Error updating status:", error);
			message.error("An error occurred while updating status");
		}
	};

	if (type === "Menu") {
		return (
			<Popover
				title={null}
				placement="leftBottom"
				content={
					<>
						<Input
							placeholder="Search"
							onChange={onChangeValue}
							allowClear
							suffix={<SearchOutlined />}
							key={"search"}
						/>
						<Menu
							onClick={handleMenuClick}
							style={{ width: 256 }}
							mode="vertical"
						>
							{menuItems.map((item) => (
								<Menu.Item key={item.key} icon={item.icon}>
									{item.label}
								</Menu.Item>
							))}
						</Menu>
					</>
				}
				trigger="click"
				visible={popoverVisible}
				onVisibleChange={setPopoverVisible}
				style={{ textAlign: "center", border: "2px solid red" }}
			>
				<Flex justify="center">
					<Badge color="blue" offset={[-40, 0]}>
						<Button style={{ color: "black" }} type="link">
							Status : {selectedIcon}
							{selectedItemDetails.status}
						</Button>
					</Badge>
				</Flex>
			</Popover>
		);
	}

	if (type === "Tag") {
		return (
			<Popover
				title={null}
				// placement="leftBottom"
				content={
					<>
						<Input
							placeholder="Search"
							onChange={onChangeValue}
							allowClear
							suffix={<SearchOutlined />}
							key={"search"}
						/>
						<Menu
							onClick={handleMenuClick}
							style={{ width: 256 }}
							mode="vertical"
						>
							{menuItems.map((item) => (
								<Menu.Item key={item.key} icon={item.icon}>
									{item.label}
								</Menu.Item>
							))}
						</Menu>
					</>
				}
				trigger="click"
				visible={popoverVisible}
				onVisibleChange={setPopoverVisible}
				style={{ textAlign: "center", border: "2px solid red" }}
			>
				<Flex justify="center">Status : {selectedItemDetails.status}</Flex>
			</Popover>
		);
	}
};

export default StatusFilterComponent;
