import axios, { AxiosResponse } from "axios";
import { Connection } from "../models/Connection";

async function getConnection(token: any): Promise<Connection[]> {
	try {
		const response: AxiosResponse<Connection[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/connection`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching connections:", error);
		throw error;
	}
}

async function getConnectionById(id: any, token: any): Promise<Connection> {
	try {
		const response: AxiosResponse<Connection> = await axios.get(
			`${process.env.REACT_APP_API_URL}/connection/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching connection:", error);
		throw error;
	}
}

export { getConnection, getConnectionById };
