import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";

const DashboardModel = ({ visible, onCancel }: any) => {
	const [linkTitle, setLinkTitle] = useState("");
	const [linkDescription, setLinkDescription] = useState("");
	const [dashboardId, setDashboardId] = useState("");
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { profile } = userProfileStore((state: any) => ({
		profile: state.profile,
	}));

	const handleclick = () => {
		const APIUrl = `${process.env.REACT_APP_API_URL}/dashboard`;
		const data = {
			title: linkTitle,
			description: linkDescription,
			widgets: [],
			ownerId: profile._id,
		};
		fetch(APIUrl, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				console.log("Success:", data);
				setDashboardId(data._id);
				console.log("dashboardId:", dashboardId);
				navigate(`/space/dashboard/${data._id}`);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<Modal
			title="New Dashboard"
			visible={visible}
			onCancel={onCancel}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={handleclick}>
					Save
				</Button>,
			]}
		>
			<Form
				layout="horizontal"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
			>
				<Form.Item
					label="Title"
					required
					rules={[{ required: true, message: "Please input the title!" }]}
				>
					<Input
						value={linkTitle}
						onChange={(e) => setLinkTitle(e.target.value)}
					/>
				</Form.Item>

				<Form.Item
					label="Description"
					required
					rules={[{ required: true, message: "Please input the description!" }]}
				>
					<Input
						value={linkDescription}
						onChange={(e) => setLinkDescription(e.target.value)}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default DashboardModel;
