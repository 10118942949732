import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Form, Input, Menu, Modal, Space } from "antd";
import { BASICFLOWNODE1 } from "./BASICFLOWNODE1";
import { Node, register } from "@antv/xflow";
import {
	CloseOutlined,
	EllipsisOutlined,
	FileAddOutlined,
} from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";

const CUSTOMMAINNODE = "custom-main-node";
const CustomMainNode = ({ node }: { node: Node }) => {
	const data = node.getData();

	const [carddata, setCardData] = useState(data);
	const [form] = Form.useForm();
	// const [modalVisible, setModalVisible] = useState(false);
	// const [editedData, setEditedData] = useState(carddata);
	const [addModalVisible, setAddModalVisible] = useState(false);
	// const [newData, setNewData] = useState("");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { InformationArchitecture, setInformationArchitecture } =
		useWorkspaceStore((state: any) => ({
			InformationArchitecture: state.InformationArchitecture,
			setInformationArchitecture: state.setInformationArchitecture,
		}));

	useEffect(() => {
		setCardData(data);
	}, [data]);

	const handleModalCancels = () => {
		setAddModalVisible(false);
	};

	const handleDeleteClick = () => {
		setAddModalVisible(true);
	};

	const menu = (
		<Menu>
			<Menu.Item key="delete" onClick={handleDeleteClick}>
				<FileAddOutlined />
				Add
			</Menu.Item>
		</Menu>
	);

	const handleFormSubmit = (formData: any) => {
		const userStoryIds =
			formData.page[0].Node &&
			formData.page[0].Node.map((item: any) => item.id);
		const create = {
			id: formData.page[0].name,
			user_story_ids: userStoryIds,
		};
		// console.log("Form values:", create);

		const nodeData = {
			...carddata.data,
			data: {
				...carddata.data.data,
				sitemap: {
					...carddata.data.data.sitemap,
					pages: [...carddata.data.data.sitemap.pages, create],
				},
			},
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/project-artifact/${carddata.data._id}`,
			{
				method: "PUT",

				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(nodeData),
			}
		)
			.then((response) => {
				if (response.ok) {
					console.log("Update successful", response);
				} else {
					console.error("Update failed", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
		setInformationArchitecture(!InformationArchitecture),
			setAddModalVisible(false);
	};

	return (
		<>
			<Card
				style={{
					width: 190,
					height: 100,
					borderRadius: 0,
					borderWidth: 2,
					borderColor: "#E39B19",
					backgroundColor: "#E39B19",
					color: "#fff",
					fontWeight: "bold",
					wordBreak: "break-all",
				}}
			>
				<Dropdown overlay={menu} trigger={["click"]}>
					<EllipsisOutlined
						style={{
							position: "absolute",
							top: 5,
							right: 5,
							fontSize: 16,
						}}
					/>
				</Dropdown>
				{carddata.name}
			</Card>

			<Modal
				title="Add Node"
				open={addModalVisible}
				onCancel={handleModalCancels}
				footer={[
					<Button key="cancel" onClick={handleModalCancels}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={() => handleFormSubmit(form.getFieldsValue())}
					>
						Submit
					</Button>,
				]}
			>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					form={form}
					name="dynamic_form_complex"
					style={{ maxWidth: 600 }}
					autoComplete="off"
					initialValues={{ page: [{}] }}
				>
					<Form.List name="page">
						{(fields) => (
							<div
								style={{ display: "flex", rowGap: 16, flexDirection: "column" }}
							>
								{fields.map((field) => (
									<Card
										size="small"
										title={`Item ${field.name + 1}`}
										key={field.key}
									>
										<Form.Item label="Name" name={[field.name, "name"]}>
											<Input />
										</Form.Item>

										{/* Nest Form.List */}
										<Form.Item label="Sub Items">
											<Form.List name={[field.name, "Node"]}>
												{(subFields, subOpt) => (
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															rowGap: 16,
														}}
													>
														{subFields.map((subField) => (
															<Space key={subField.key}>
																<Form.Item
																	rules={[
																		{
																			required: true,
																			message: "Please enter node data",
																		},
																	]}
																	noStyle
																	name={[subField.name, "id"]}
																>
																	<Input placeholder="node" />
																</Form.Item>

																<CloseOutlined
																	onClick={() => {
																		subOpt.remove(subField.name);
																	}}
																/>
															</Space>
														))}
														<Button
															type="dashed"
															onClick={() => subOpt.add()}
															block
														>
															+ Add Sub Item
														</Button>
													</div>
												)}
											</Form.List>
										</Form.Item>
									</Card>
								))}
							</div>
						)}
					</Form.List>
				</Form>
			</Modal>
		</>
	);
};

register({
	shape: CUSTOMMAINNODE,
	component: CustomMainNode,
	inherit: BASICFLOWNODE1,
	effect: ["label"],
});

export { CUSTOMMAINNODE };
