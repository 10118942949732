import React, { useEffect, useState } from "react";
import {
	Button,
	//Checkbox,
	Layout,
	Form,
	Flex,
	Card,
	Switch,
	Tag,
} from "antd";

import "./ModuleCheck1.css";
import feature from "../assets/feature.png";
import business from "../assets/business.png";
import design from "../assets/design.png";
import code from "../assets/code.png";
// import userProfileStore, {
//  UserProfileStoreType,
// } from "../stores/UserProfileStore";
const { Content } = Layout;
// import useWorkspaceStore, {
//  WorkspaceStoreState,
// } from "../stores/WorkspaceState";
//import { useNavigate } from "react-router-dom";
//import { CheckboxValueType } from "antd/lib/checkbox/Group";
import Constants from "../util/ConstantsUtil";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import CreateWorkpaceModal from "./CreateWorkspace";
import useWorkspaceStore from "../stores/WorkspaceState";

const ModuleCheck = ({ prev }: any) => {
	//const data = useWorkspaceStore() as WorkspaceStoreState;
	//const navigate = useNavigate();
	const [form] = Form.useForm();

	//const [checkedModules, setCheckedModules] = useState<CheckboxValueType[]>([]);
	//const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const [isCreateWorkspaceModalVisible, setIsCreateWorkspaceModalVisible] =
		useState<boolean>(false);

	const { setAddWorkspaceDetails, addWorkspaceDetails } = useWorkspaceStore(
		(state: any) => ({
			addWorkspaceDetails: state.addWorkspaceDetails,
			setAddWorkspaceDetails: state.setAddWorkspaceDetails,
		})
	);
	//const ServerLink = process.env.REACT_APP_API_URL;

	// const getLabel = (checkbox: string) => {
	//  return checkedModules.includes(checkbox) ? "Unselect" : "Select";
	// };

	// const handleButtonClick = (checkbox: string) => {
	//  setCheckedModules((prevCheckedModules) =>
	//      prevCheckedModules.includes(checkbox)
	//          ? prevCheckedModules.filter((module) => module !== checkbox)
	//          : [...prevCheckedModules, checkbox]
	//  );
	// };

	useEffect(() => {
		setIsCreateWorkspaceModalVisible(false);
		setAddWorkspaceDetails({
			...addWorkspaceDetails,
			isFeatureEngineering: true,
			isDesignEngineering: true,
		});
	}, []);

	const handleCreateWorkspaceModalCancel = () => {
		setIsCreateWorkspaceModalVisible(false);
	};

	// const onFinish = () => {
	//  fetch(`${ServerLink}/workspace`, {
	//      method: "POST",
	//      body: JSON.stringify({
	//          name: workspaceName,
	//          //modules: checkedModules,
	//          currentModule: "Testing",
	//          productInfo: data.data,
	//      }),
	//      headers: {
	//          "Content-type": "application/json; charset=UTF-8",
	//          Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	//      },
	//  })
	//      .then((response) => {
	//          if (!response.ok) {
	//              throw new Error(`HTTP error! Status: ${response.status}`);
	//          }
	//          return response.json();
	//      })
	//      .then((json) => {
	//          message.success("Workspace created successfully!");
	//          //navigate(`/`);

	//          next();
	//          console.log("Data after post", json);
	//      })
	//      .catch((error) => {
	//          const errorMessage = `An error occurred while creating the workspace. ${
	//              error.message || "Please try again."
	//          }`;
	//          message.error(errorMessage);
	//          console.error(error);
	//      });
	// };

	const moduleList = [
		{
			value: Constants.BUSINESS_ENGINEERING,
			label: Constants.BUSINESS_ENGINEERING,
		},
		{
			value: Constants.FEATURE_ENGINEERING,
			label: Constants.FEATURE_ENGINEERING,
			list: [Constants.FE_FEATURE_LIST, Constants.FE_FEATURE_SPECIFICATION],
		},
		{
			value: Constants.DESIGN_ENGINEERING,
			label: Constants.DESIGN_ENGINEERING,
			list: [
				Constants.DE_USER_PERSONA,
				Constants.DE_INFORMATION_ARCHITECTURE,
				Constants.DE_DDD_CORE,
				Constants.DE_API_SPECIFICATION,
				Constants.DE_DATA_MODEL,
			],
		},
		{
			value: Constants.CODE_ENGINEERING,
			label: Constants.CODE_ENGINEERING,
		},
	];

	const handleSwitchSelection = (module: string) => {
		switch (module) {
			case Constants.BUSINESS_ENGINEERING:
				setAddWorkspaceDetails({
					...addWorkspaceDetails,
					isBusinessEngineering: !addWorkspaceDetails.isBusinessEngineering,
				});
				break;
			case Constants.FEATURE_ENGINEERING:
				setAddWorkspaceDetails({
					...addWorkspaceDetails,
					isFeatureEngineering: !addWorkspaceDetails.isFeatureEngineering,
				});
				break;
			case Constants.DESIGN_ENGINEERING:
				setAddWorkspaceDetails({
					...addWorkspaceDetails,
					isDesignEngineering: !addWorkspaceDetails.isDesignEngineering,
				});
				break;
			case Constants.CODE_ENGINEERING:
				setAddWorkspaceDetails({
					...addWorkspaceDetails,
					isCodeEngineering: !addWorkspaceDetails.isCodeEngineering,
				});
				break;
			default:
				break;
		}
	};

	const handleModuleCheckNext = () => {
		if (
			addWorkspaceDetails.isBusinessEngineering ||
			addWorkspaceDetails.isFeatureEngineering ||
			addWorkspaceDetails.isDesignEngineering ||
			addWorkspaceDetails.isCodeEngineering
		) {
			setIsCreateWorkspaceModalVisible(true);
		} else {
			setIsCreateWorkspaceModalVisible(false);
		}
	};

	return (
		<Layout style={{ backgroundColor: "#F5F8FE" }}>
			<Content>
				<Form form={form} layout="vertical">
					{/* <div className="moduleCheckContent"> */}
					<Card
						data-testid="Card"
						style={{
							marginTop: 50,
							width: "80vw",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<Form.Item
							data-testid="ModalCheckbox"
							// label="Modules"
							// rules={[
							//  {
							//      required: true,
							//      message: "Please select at least one checkbox",
							//  },
							// ]}
							name="checkedModules"
						>
							{/* <Checkbox.Group value={checkedModules}> */}
							<Flex justify="space-around" gap={6}>
								{moduleList.map((module) => (
									<>
										<Card
											data-testid="subCard"
											key={module.value}
											style={{ width: "22vw" }}
										>
											{/* <Checkbox
                                                    value={module.value}
                                                    disabled={!checkedModules.includes(module.value)}
                                                > */}
											<Flex data-testid="image" vertical align="center">
												<img
													src={getImageForModule(module.value)}
													alt={`${module.label} Image`}
													style={{ width: "120px", height: "120px" }}
												/>
												<Switch
													data-testid="switch"
													checkedChildren={module.label}
													unCheckedChildren={module.label}
													onClick={() => {
														handleSwitchSelection(module.label);
													}}
													defaultChecked={
														module.label === Constants.CODE_ENGINEERING ||
														module.label === Constants.BUSINESS_ENGINEERING
															? false
															: true
													}
													disabled={
														module.label === Constants.CODE_ENGINEERING ||
														module.label === Constants.BUSINESS_ENGINEERING
													}
												/>
												{module.list ? (
													<ul
														style={{
															paddingLeft: 50,
															textAlign: "left", // Add this line
														}}
													>
														{module.list.map((item, index) => (
															<li key={index}>{item}</li>
														))}
													</ul>
												) : (
													<Tag
														data-testid="tag"
														color="red"
														style={{
															marginTop: 50,
															textAlign: "left", // Add this line
														}}
													>
														Coming soon
													</Tag>
												)}
											</Flex>
											{/* </Checkbox> */}
											{/* <div className="buttonContainer">
                                                    <Button
                                                        onClick={() => handleButtonClick(module.value)}
                                                    >
                                                        {getLabel(module.value)}
                                                    </Button>
                                                </div> */}
										</Card>
									</>
								))}
							</Flex>
							{/* </Checkbox.Group> */}
						</Form.Item>
						<Flex justify="flex-end" gap={10} style={{ marginBottom: 20 }}>
							<Button
								onClick={prev}
								icon={<ArrowLeftOutlined />}
								data-testid="previousButton"
							></Button>
							<Button
								icon={<ArrowRightOutlined />}
								type="primary"
								//htmlType="submit"
								data-testid="nextButton"
								onClick={() => handleModuleCheckNext()}
							></Button>
						</Flex>
					</Card>
					{/* </div> */}

					<CreateWorkpaceModal
						isModalVisible={isCreateWorkspaceModalVisible}
						handleModalCancel={handleCreateWorkspaceModalCancel}
						//workspaceId={selectedWorkspaceId}
					/>
				</Form>
			</Content>
		</Layout>
	);
};

export default ModuleCheck;

function getImageForModule(module: string): string {
	switch (module) {
		case Constants.BUSINESS_ENGINEERING:
			return business;
		case Constants.FEATURE_ENGINEERING:
			return feature;
		case Constants.DESIGN_ENGINEERING:
			return design;
		case Constants.CODE_ENGINEERING:
			return code;
		default:
			return "";
	}
}
