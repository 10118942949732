import { useGraphStore } from "@antv/xflow";
import { useCallback, useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { message } from "antd";
import { CUSTOMSTORYNODE } from "./InformationArchitectureXFlow/CUSTOMSTORYNODE";
import { CUSTOMMAINNODE } from "./InformationArchitectureXFlow/CUSTOMMAINNODE";
import { CUSTOMSECONDRYNODE } from "./InformationArchitectureXFlow/CUSTOMSECONDRYNODE";
import useWorkspaceStore from "../stores/WorkspaceState";

function formatString(inputString: any) {
	const stringWithoutUnderscores = inputString.replace(/_/g, " ");
	return (
		stringWithoutUnderscores.charAt(0).toUpperCase() +
		stringWithoutUnderscores.slice(1)
	);
}

interface InitNodeProps {
	id: any;
}

const InitNode: React.FC<InitNodeProps> = ({ id }) => {
	console.log(id, "id");
	const addNodes = useGraphStore((state) => state.addNodes);
	const addEdges = useGraphStore((state) => state.addEdges);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [apiData, setApiData] = useState<any>(null);
	const [workspace, setWorkspace] = useState<any>(null);
	const { InformationArchitecture } = useWorkspaceStore((state: any) => ({
		InformationArchitecture: state.InformationArchitecture,
		setInformationArchitecture: state.setInformationArchitecture,
	}));
	console.log(InformationArchitecture, "informationArchitecture");
	const fetchworkspaceData = async () => {
		const apiUrl = process.env.REACT_APP_API_URL;

		try {
			const response = await fetch(`${apiUrl}/workspace/${id}`, {
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			const json = await response.json();

			setWorkspace(json);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			fetchworkspaceData();
		}
	}, [userProfileStoreInstance.idToken, InformationArchitecture]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiUrl = process.env.REACT_APP_API_URL;

				if (!apiUrl) {
					console.error(
						"REACT_APP_API_URL is not defined in your environment."
					);
					return;
				}

				const response = await fetch(
					`${apiUrl}/project-artifact/de-ux-spec-information-architecture/${id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);

				if (!response.ok) {
					throw new Error(
						`status: ${response.status} message: ${response.statusText}`
					);
				}

				const data = await response.json();
				console.log(data, "data");
				setApiData(data);
			} catch (error: any) {
				message.error(`Error: ${error.message}`);
			}
		};

		fetchData();
	}, []);

	console.log(apiData, "apiData");

	const addNodeInit = useCallback(() => {
		if (!apiData || apiData.length === 0 || !apiData[0].data) return;

		const {
			data: {
				sitemap: { pages },
			},
		} = apiData[0];

		if (pages) {
			const gap = 100;
			const nodeWidth = 190;
			const totalNodesWidth = pages.length * nodeWidth;
			const startX = (36 * window.innerWidth) / 100 - totalNodesWidth / 2;

			const nodesToAdd = pages.map((page: any, index: number) => ({
				id: `initNode${index + 1}`,
				shape: CUSTOMSECONDRYNODE,
				x: startX + index * (nodeWidth + gap),
				y: 185,
				data: {
					page: page.id,
					index: index,
					data: apiData[0],
					pageData: page,
				},
			}));

			const additionalNode = {
				id: "additionalNode",
				shape: CUSTOMMAINNODE,
				x: startX + ((pages.length - 1) * (nodeWidth + gap)) / 2,
				y: 20,
				data: {
					name: (workspace as { name: string } | null)?.name || "",
					data: apiData[0],
				},
			};

			addNodes([...nodesToAdd, additionalNode]);

			nodesToAdd.forEach((node: any) => {
				addEdges([
					{
						source: "additionalNode",
						sourcePort: "group3",
						target: node.id,
						targetPort: "group1",
						attrs: {
							line: {
								"stroke-width": 1,
								stroke: "black",
							},
						},
					},
				]);
			});

			pages.forEach((page: any, index: number) => {
				let yOffset = 0;

				const subchildNodes = page.user_story_ids.map(
					(storyId: string, subIndex: number) => {
						const yPos = nodesToAdd[index].y + 120 + yOffset;

						yOffset += 120;

						return {
							id: `subchildNode_${index}_${subIndex}`,
							shape: CUSTOMSTORYNODE,
							x: nodesToAdd[index].x,
							y: yPos + 50,
							data: {
								storyId: formatString(storyId),
								subIndex: subIndex,
								page: page,
								index: index,
								data: apiData[0],
							},
						};
					}
				);

				subchildNodes.forEach((subchildNode: any) => {
					addEdges([
						{
							source: nodesToAdd[index].id,
							sourcePort: "group3",
							target: subchildNode.id,
							targetPort: "group1",
							attrs: {
								line: {
									"stroke-width": 1,
									stroke: "black",
								},
							},
						},
					]);
				});

				addNodes(subchildNodes);
			});
		}
	}, [apiData, addEdges, addNodes, workspace]);

	useEffect(() => {
		addNodeInit();
	}, [addNodeInit, apiData, addNodes, workspace]);

	return null;
};

export { InitNode };
