import { Button, Flex, List, Tag } from "antd";
import React, { useEffect } from "react";
import useWorkspaceStore from "../../stores/WorkspaceState";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface AlchemiChatThreadListProps {
	// Define your component props here
}

const AlchemiChatThreadList: React.FC<AlchemiChatThreadListProps> = (props) => {
	// Add your component logic here
	const navigate = useNavigate();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [chatThreads, setChatThreads] = React.useState([]);
	const getChatThreadsByWorkspace = async () => {
		// Implement your code here

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/ai-conversation-thread/workspace/${currentWorkspace._id}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					"Content-Type": "application/json; charset=UTF-8",
				},
			}
		);

		const result = await response.json();
		setChatThreads(result);
	};

	useEffect(() => {
		// Add your code here
		console.log(props);
		getChatThreadsByWorkspace();
	}, []);

	return (
		<Flex
			style={{
				width: "24vw",
				minHeight: "70vh",
				maxHeight: "95vh",
				overflow: "scroll",
			}}
			vertical
		>
			<Flex
				style={{
					width: "100%",
				}}
				justify="space-between"
			>
				&nbsp;
			</Flex>
			<List
				pagination={{ position: "bottom", align: "center", pageSize: 5 }}
				dataSource={chatThreads}
				renderItem={(item: any, index) => (
					<List.Item key={index}>
						<Flex>
							<Flex
								style={{
									width: "50px",
									height: "50px",
									borderRadius: "50%",
									backgroundColor: "#f0f0f0",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{item.goal.charAt(0)}
							</Flex>
							<Flex
								style={{
									marginLeft: "10px",
								}}
								vertical
								justify="space-evenly"
							>
								<div>{item.goal}</div>

								<Tag
									color={item.status == "IN_PROGRESS" ? "pink" : "green"}
									style={{ marginTop: "10px" }}
								>
									{item.status}
								</Tag>
							</Flex>
						</Flex>
						<Flex
							justify="flex-end"
							align="flex-end"
							style={{
								marginRight: "10px",
							}}
						>
							<Flex vertical>
								{item.status == "IN_PROGRESS" && (
									<Button
										type="primary"
										size="small"
										style={{ margin: "10px" }}
										onClick={() => {
											navigate(`/fd/chatv2/resume/${item.goal}/${item._id}`);
										}}
									>
										Resume
									</Button>
								)}
								{item.status == "COMPLETE" && (
									<Button
										type="primary"
										size="small"
										style={{ margin: "10px" }}
										onClick={() => {
											navigate(`/fd/chatv2/history/${item.goal}/${item._id}`);
										}}
									>
										View
									</Button>
								)}
								<Tag color="lime" style={{ borderRadius: "16px" }}>
									{moment(item.createdAt).fromNow()}
								</Tag>
							</Flex>
						</Flex>
					</List.Item>
				)}
			/>
		</Flex>
	);
};

export default AlchemiChatThreadList;
