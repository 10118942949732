// // /* eslint-disable @typescript-eslint/no-unused-vars */
// // import {
// // 	Layout,
// // 	Card,
// // 	Table,
// // 	Typography,
// // 	Form,
// // 	Input,
// // 	DatePicker,
// // 	Modal,
// // 	DatePickerProps,
// // 	Select,
// // 	Tooltip,
// // } from "antd";
// // import React, { useEffect, useState } from "react";
// // import { useNavigate, useParams } from "react-router-dom";
// // import userProfileStore, {
// // 	UserProfileStoreType,
// // } from "../../stores/UserProfileStore";
// // import { planerViewStore } from "../../stores/PlannerStore";

// // import CapacityPlanNav from "./CapacityPlanNav";
// // import AddCapacityPlan from "./AddCapacityPlan";
// // import moment from "moment";
// // import { EditOutlined } from "@ant-design/icons";
// // import input from "antd/lib/input";

// // const { Content } = Layout;
// // const { Text } = Typography;

// // const CapacityPlanning: React.FC = () => {
// // 	interface CapacityRecord {
// // 		key: number;
// // 		user: string;
// // 		hoursPerDay: number;
// // 		leaveDates: string[];
// // 		activity: string;
// // 	}
// //     const [responseData, setResponseData] = useState<any[]>([]);
// //     const regex = new RegExp(input as unknown as string, 'i');
// //     const filteredData = responseData.filter((record) =>
// //         regex.test(record.user)
// //     );

// // 	const [form] = Form.useForm();
// // 	const [open, setOpen] = useState(false);
// // 	const [record, setRecord] = useState<any>();
// // 	const { setUnauthorizedModel } = planerViewStore((state: any) => ({
// // 		setUnauthorizedModel: state.setUnauthorizedModel,
// // 	}));
// // 	const planId = useParams().planId;
// // 	const navigate = useNavigate();
// // 	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

// // 	const { openCapacityPlanner } = planerViewStore();

// // 	const fetchData = async () => {
// // 		try {
// // 			const response = await fetch(
// // 				`${process.env.REACT_APP_API_URL}/capacity-plan/plan/${planId}`,
// // 				{
// // 					headers: {
// // 						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// // 					},
// // 				}
// // 			);

// // 			if (response.ok) {
// // 				const data = await response.json();
// // 				const newdata = data[0].teamMembers.map((test: any) => {
// // 					return {
// // 						user: test.userProfile.name,
// // 						hoursPerDay: test.hoursPerDay,
// // 						leaveDates: test.leaveDates,
// // 						activity: test.activity,
// // 						_id: test._id,
// // 					};
// // 				});
// // 				setResponseData(newdata);
// // 			} else if (response.status === 401) {
// // 				setUnauthorizedModel(true);
// // 			} else {
// // 				console.error("Error fetching user details");
// // 			}
// // 		} catch (error) {
// // 			console.error("Error fetching user details:", error);
// // 		}
// // 	};

// // 	const setFormValues = (record: any) => {
// // 		const { hoursPerDay, leaveDates } = record;
// // 		console.log("leaveDates", leaveDates);
// // 		const formattedLeaveDates =
// // 			leaveDates[0] != null
// // 				? leaveDates.map((dateString: string) => {
// // 						const date = new Date(dateString);
// // 						const month = date.toLocaleString("default", { month: "short" });
// // 						const day = date.getDate();
// // 						const year = date.getFullYear();
// // 						return `${year}-${month}-${day} `;
// // 					})
// // 				: null;

// // 		console.log("formattedLeaveDates", formattedLeaveDates);
// // 		form.setFieldsValue({
// // 			hoursPerDay,
// // 			olderleaveDates: formattedLeaveDates,
// // 			leaveDates: [],
// // 		});
// // 	};

// // 	const columns = [
// // 		{
// // 			title: "User",
// // 			dataIndex: "user",
// // 			key: "user",
// // 			render: (_text: any, record: CapacityRecord) => (
// // 				<Text style={{ fontSize: "14px", fontWeight: "500" }}>
// // 					{record.user}
// // 				</Text>
// // 			),
// // 		},
// // 		{
// // 			title: "No. of Hours per Day",
// // 			dataIndex: "hoursPerDay",
// // 			key: "hoursPerDay",
// // 			render: (text: number) => (
// // 				<Text style={{ fontSize: "14px", fontWeight: "500" }}>{text}</Text>
// // 			),
// // 		},
// // 		{
// // 			title: "Leave Dates",
// // 			dataIndex: "leaveDates",
// // 			key: "leaveDates",
// // 			render: (text: string[]) => (
// // 				<div>
// // 					{text.map((date, index) => (
// // 						<div key={index} style={{ fontSize: "14px", fontWeight: "500" }}>
// // 							{moment(date).isValid()
// // 								? moment(date).format("MMM Do YY")
// // 								: "No leave Dates"}
// // 						</div>
// // 					))}
// // 				</div>
// // 			),
// // 		},
// // 		{
// // 			title: "Action",
// // 			dataIndex: "activity",
// // 			key: "activity",
// // 			render: (_id: any, record: any) => (
// // 				<Tooltip title="Edit">
// // 					<Typography.Link
// // 						onClick={() => {
// // 							setOpen(true);
// // 							setRecord(record);
// // 							setFormValues(record);
// // 						}}
// // 					>
// // 						<EditOutlined style={{ color: "#0444bf" }} />
// // 					</Typography.Link>
// // 				</Tooltip>
// // 			),
// // 		},
// // 	];

// // 	const finish = async (values: any) => {
// // 		console.log("values", values);
// // 		const newvalues = await form.validateFields();
// // 		console.log("newvalues", newvalues);
// // 		// const leaveDates = values?.leaveDates || [];
// // 		const olderleaveDates = values?.olderleaveDates || [];

// // 		// Function to convert month abbreviation to number
// // 		const convertMonthAbbrevToNumber = (monthAbbrev: string) => {
// // 			const months: { [key: string]: string } = {
// // 				Jan: "01",
// // 				Feb: "02",
// // 				Mar: "03",
// // 				Apr: "04",
// // 				May: "05",
// // 				Jun: "06",
// // 				Jul: "07",
// // 				Aug: "08",
// // 				Sep: "09",
// // 				Oct: "10",
// // 				Nov: "11",
// // 				Dec: "12",
// // 			};
// // 			return months[monthAbbrev];
// // 		};

// // 		// Format olderleaveDates from "24-Mar-2024" to "24-03-2024"
// // 		const formattedOlderLeaveDates = olderleaveDates.map((dateStr: string) => {
// // 			const [day, month, year] = dateStr.split("-");
// // 			return `${day}-${convertMonthAbbrevToNumber(month)}-${year}`;
// // 		});

// // 		const leaveDates = values?.leaveDates || [];

// // 		const formattedDates = leaveDates.map(
// // 			(dateObj: any) => dateObj?.format("YYYY-MM-DD") // Use optional chaining here as well
// // 		);

// // 		console.log("formattedDates", formattedDates);

// // 		const allLeaveDates = [...formattedDates, ...formattedOlderLeaveDates];

// // 		try {
// // 			const response = await fetch(
// // 				`${process.env.REACT_APP_API_URL}/capacity-plan/${planId}/team-members/${record._id}`,
// // 				{
// // 					method: "PUT",
// // 					headers: {
// // 						"Content-Type": "application/json",
// // 						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// // 					},
// // 					body: JSON.stringify({
// // 						hoursPerDay: values.hoursPerDay,
// // 						leaveDates: allLeaveDates,
// // 					}),
// // 				}
// // 			);
// // 			if (response.ok) {
// // 				console.log("Roles updated successfully");
// // 				setOpen(false);
// // 				form.resetFields();
// // 				fetchData();
// // 			} else {
// // 				console.error("Error updating roles");
// // 			}
// // 		} catch (error) {
// // 			console.error("Error updating roles:", error);
// // 		}
// // 	};

// // 	useEffect(() => {
// // 		fetchData();
// // 	}, [openCapacityPlanner]);

// // 	// Initialize form with default values
// // 	useEffect(() => {
// // 		form.setFieldsValue({
// // 			leaveDates: [], // Set leaveDates to an empty array
// // 		});
// // 	}, [form]);

// // 	return (
// // 		<Layout style={{ background: "#FAFBFF" }}>
// // 			<CapacityPlanNav />
// // 			<AddCapacityPlan planID={planId} />
// // 			<Layout>
// // 				<Content
// // 					style={{ backgroundColor: "#FAFBFF" }}
// // 					className="capacity-content-container"
// // 				>
// // 					<Card
// // 						style={{
// // 							backgroundColor: "#EDF4FF",
// // 							margin: "1rem",
// // 							padding: "1rem",
// // 							overflow: "scroll",
// // 							height: "73vh",
// // 							maxWidth: "100vw",
// // 							display: "block",
// // 							position: "relative",
// // 						}}
// // 					>
// // 						<Table
// // 							scroll={{ y: 400 }}
// // 							columns={columns}
// // 							key={"capacityPlan"}
// // 							pagination={{
// // 								pageSize: 10,
// // 								pageSizeOptions: ["10", "20", "30"],
// // 								showTotal: (total, range) =>
// // 									`${range[0]}-${range[1]} of ${total} items`,
// // 							}}
// // 							dataSource={filteredData}
// // 						/>
// // 					</Card>
// // 				</Content>

// // 				<Modal
// // 					width={"45vw"}
// // 					open={open}
// // 					onCancel={() => setOpen(false)}
// // 					onOk={() => form.submit()}
// // 				>
// // 					<Form
// // 						style={{ height: "10vh", margin: "0 0 20vh 0" }}
// // 						form={form}
// // 						onFinish={finish}
// // 					>
// // 						<Form.Item
// // 							label="No. of hours"
// // 							name="hoursPerDay"
// // 							style={{ margin: "5vh 0  5vh 0" }}
// // 							rules={[{ required: true, message: "Please select a type" }]}
// // 						>
// // 							<Input />
// // 						</Form.Item>

// // 						<Form.Item
// // 							label="Old Leave Dates"
// // 							name="olderleaveDates"
// // 							style={{ margin: "5vh 0  5vh 0" }}
// // 						>
// // 							<Select
// // 								mode="multiple"
// // 								allowClear
// // 								style={{ width: "100%" }}
// // 								placeholder="No Leave Dates"
// // 								// defaultValue={olderdates?.map((date: string) => (date))}
// // 								onChange={() => {}}
// // 							/>
// // 						</Form.Item>

// // 						<Form.Item
// // 							label="Leave Dates"
// // 							name="leaveDates"
// // 							style={{ margin: "5vh 0  5vh 0" }}
// // 							rules={[{ required: true, message: "Please select a type" }]}
// // 						>
// // 							<DatePicker multiple />
// // 						</Form.Item>
// // 					</Form>
// // 				</Modal>
// // 			</Layout>
// // 		</Layout>
// // 	);
// // };

// // export default CapacityPlanning;

// import React, { useEffect, useState } from "react";
// import { Layout, Card, Table, Typography, Form, Input, DatePicker, Modal, Select, Tooltip } from "antd";
// import { SearchOutlined, EditOutlined } from "@ant-design/icons";
// import {useParams } from "react-router-dom";
// import userProfileStore, { UserProfileStoreType } from "../../stores/UserProfileStore";
// import { planerViewStore } from "../../stores/PlannerStore";
// import CapacityPlanNav from "./CapacityPlanNav";
// import AddCapacityPlan from "./AddCapacityPlan";
// import moment from "moment";

// const { Content } = Layout;
// const { Text } = Typography;

// const CapacityPlanning: React.FC = () => {
//     interface CapacityRecord {
//         key: number;
//         user: string;
//         hoursPerDay: number;
//         leaveDates: string[];
//         activity: string;
//     }

//     const [form] = Form.useForm();
//     const [open, setOpen] = useState(false);
//     const [record, setRecord] = useState<any>();
//     const [input, setInput] = useState("");
//     const { setUnauthorizedModel } = planerViewStore((state: any) => ({
//         setUnauthorizedModel: state.setUnauthorizedModel,
//     }));
//     const planId = useParams().planId;
//     // const navigate = useNavigate();
//     const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

//     const [responseData, setResponseData] = useState<any[]>([]);
//     const { openCapacityPlanner } = planerViewStore();

//     const fetchData = async () => {
//         try {
//             const response = await fetch(
//                 `${process.env.REACT_APP_API_URL}/capacity-plan/plan/${planId}`,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
//                     },
//                 }
//             );

//             if (response.ok) {
//                 const data = await response.json();
//                 const newdata = data[0].teamMembers.map((test: any) => {
//                     return {
//                         user: test.userProfile.name,
//                         hoursPerDay: test.hoursPerDay,
//                         leaveDates: test.leaveDates,
//                         activity: test.activity,
//                         _id: test._id,
//                     };
//                 });
//                 setResponseData(newdata);
//             } else if (response.status === 401) {
//                 setUnauthorizedModel(true);
//             } else {
//                 console.error("Error fetching user details");
//             }
//         } catch (error) {
//             console.error("Error fetching user details:", error);
//         }
//     };

//     const setFormValues = (record: any) => {
//         const { hoursPerDay, leaveDates } = record;
//         console.log("leaveDates", leaveDates);
//         const formattedLeaveDates =
//             leaveDates[0] != null
//                 ? leaveDates.map((dateString: string) => {
//                     const date = new Date(dateString);
//                     const month = date.toLocaleString("default", { month: "short" });
//                     const day = date.getDate();
//                     const year = date.getFullYear();
//                     return `${year}-${month}-${day} `;
//                 })
//                 : null;

//         console.log("formattedLeaveDates", formattedLeaveDates);
//         form.setFieldsValue({
//             hoursPerDay,
//             olderleaveDates: formattedLeaveDates,
//             leaveDates: [],
//         });
//     };

//     const columns = [
//         {
//             title: "User",
//             dataIndex: "user",
//             key: "user",
//             render: (_text: any, record: CapacityRecord) => (
//                 <Text style={{ fontSize: "14px", fontWeight: "500" }}>
//                     {record.user}
//                 </Text>
//             ),
//         },
//         {
//             title: "No. of Hours per Day",
//             dataIndex: "hoursPerDay",
//             key: "hoursPerDay",
//             render: (text: number) => (
//                 <Text style={{ fontSize: "14px", fontWeight: "500" }}>{text}</Text>
//             ),
//         },
//         {
//             title: "Leave Dates",
//             dataIndex: "leaveDates",
//             key: "leaveDates",
//             render: (text: string[]) => (
//                 <div>
//                     {text.map((date, index) => (
//                         <div key={index} style={{ fontSize: "14px", fontWeight: "500" }}>
//                             {moment(date).isValid()
//                                 ? moment(date).format("MMM Do YY")
//                                 : "No leave Dates"}
//                         </div>
//                     ))}
//                 </div>
//             ),
//         },
//         {
//             title: "Action",
//             dataIndex: "activity",
//             key: "activity",
//             render: (_id: any, record: any) => (
//                 <Tooltip title="Edit">
//                     <Typography.Link
//                         onClick={() => {
//                             setOpen(true);
//                             setRecord(record);
//                             setFormValues(record);
//                         }}
//                     >
//                         <EditOutlined style={{ color: "#0444bf" }} />
//                     </Typography.Link>
//                 </Tooltip>
//             ),
//         },
//     ];

//     const finish = async (values: any) => {
//         console.log("values", values);
//         const newvalues = await form.validateFields();
//         console.log("newvalues", newvalues);
//         // const leaveDates = values?.leaveDates || [];
//         const olderleaveDates = values?.olderleaveDates || [];

//         // Function to convert month abbreviation to number
//         const convertMonthAbbrevToNumber = (monthAbbrev: string) => {
//             const months: { [key: string]: string } = {
//                 Jan: "01",
//                 Feb: "02",
//                 Mar: "03",
//                 Apr: "04",
//                 May: "05",
//                 Jun: "06",
//                 Jul: "07",
//                 Aug: "08",
//                 Sep: "09",
//                 Oct: "10",
//                 Nov: "11",
//                 Dec: "12",
//             };
//             return months[monthAbbrev];
//         };

//         // Format olderleaveDates from "24-Mar-2024" to "24-03-2024"
//         const formattedOlderLeaveDates = olderleaveDates.map((dateStr: string) => {
//             const [day, month, year] = dateStr.split("-");
//             return `${day}-${convertMonthAbbrevToNumber(month)}-${year}`;
//         });

//         const leaveDates = values?.leaveDates || [];

//         const formattedDates = leaveDates.map(
//             (dateObj: any) => dateObj?.format("YYYY-MM-DD") // Use optional chaining here as well
//         );

//         console.log("formattedDates", formattedDates);

//         const allLeaveDates = [...formattedDates, ...formattedOlderLeaveDates];

//         try {
//             const response = await fetch(
//                 `${process.env.REACT_APP_API_URL}/capacity-plan/${planId}/team-members/${record._id}`,
//                 {
//                     method: "PUT",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
//                     },
//                     body: JSON.stringify({
//                         hoursPerDay: values.hoursPerDay,
//                         leaveDates: allLeaveDates,
//                     }),
//                 }
//             );
//             if (response.ok) {
//                 console.log("Roles updated successfully");
//                 setOpen(false);
//                 form.resetFields();
//                 fetchData();
//             } else {
//                 console.error("Error updating roles");
//             }
//         } catch (error) {
//             console.error("Error updating roles:", error);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, [openCapacityPlanner]);

//     // Initialize form with default values
//     useEffect(() => {
//         form.setFieldsValue({
//             leaveDates: [], // Set leaveDates to an empty array
//         });
//     }, [form]);

//     const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         setInput(e.target.value);
//         // Filter the data based on the input value here
//         const filteredData = responseData.filter(record =>
//             record.user.toLowerCase().includes(e.target.value.toLowerCase())
//         );
//         setResponseData(filteredData);
//     };

//     return (
//         <Layout style={{ background: "#FAFBFF" }}>
//             <CapacityPlanNav />
//             <AddCapacityPlan planID={planId} />
//             <Layout>
//                 <Content
//                     style={{ backgroundColor: "#FAFBFF" }}
//                     className="capacity-content-container"
//                 >
//                     <Card
//                         style={{
//                             backgroundColor: "#EDF4FF",
//                             margin: "1rem",
//                             padding: "1rem",
//                             overflow: "scroll",
//                             height: "73vh",
//                             maxWidth: "100vw",
//                             display: "block",
//                             position: "relative",
//                         }}
//                     >
//                         <Input
//                             suffix={<SearchOutlined />}
//                             size="middle"
//                             style={{
//                                 height: "5vh",
//                                 width: "14vw",
//                                 fontSize: "0.9rem",
//                                 fontFamily: "Poppins",
//                             }}
//                             placeholder="Search"
//                             onChange={handleChange}
//                             value={input}
//                         />
//                         <Table
//                             scroll={{ y: 400 }}
//                             columns={columns}
//                             key={"capacityPlan"}
//                             pagination={{
//                                 pageSize: 10,
//                                 pageSizeOptions: ["10", "20", "30"],
//                                 showTotal: (total, range) =>
//                                     `${range[0]}-${range[1]} of ${total} items`,
//                             }}
//                             dataSource={responseData}
//                         />
//                     </Card>
//                 </Content>

//                 <Modal
//                     width={"45vw"}
//                     open={open}
//                     onCancel={() => setOpen(false)}
//                     onOk={() => form.submit()}
//                 >
//                     <Form
//                         style={{ height: "10vh", margin: "0 0 20vh 0" }}
//                         form={form}
//                         onFinish={finish}
//                     >
//                         <Form.Item
//                             label="No. of hours"
//                             name="hoursPerDay"
//                             style={{ margin: "5vh 0  5vh 0" }}
//                             rules={[{ required: true, message: "Please select a type" }]}
//                         >
//                             <Input />
//                         </Form.Item>

//                         <Form.Item
//                             label="Old Leave Dates"
//                             name="olderleaveDates"
//                             style={{ margin: "5vh 0  5vh 0" }}
//                         >
//                             <Select
//                                 mode="multiple"
//                                 allowClear
//                                 style={{ width: "100%" }}
//                                 placeholder="No Leave Dates"
//                                 // defaultValue={olderdates?.map((date: string) => (date))}
//                                 onChange={() => { }}
//                             />
//                         </Form.Item>

//                         <Form.Item
//                             label="Leave Dates"
//                             name="leaveDates"
//                             style={{ margin: "5vh 0  5vh 0" }}
//                             rules={[{ required: true, message: "Please select a type" }]}
//                         >
//                             <DatePicker multiple />
//                         </Form.Item>
//                     </Form>
//                 </Modal>
//             </Layout>
//         </Layout>
//     );
// };

// export default CapacityPlanning;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Layout,
	Card,
	Table,
	Typography,
	Form,
	Input,
	Modal,
	Select,
	Tooltip,
	DatePicker,
	message,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import CapacityPlanNav from "./CapacityPlanNav";
import AddCapacityPlan from "./AddCapacityPlan";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import { findByPlan, updateTeamMember } from "../../api/capaicityPlaningAPI";
import { useRequest } from "ahooks";

const { Content } = Layout;
const { Text } = Typography;

const CapacityPlanning: React.FC = () => {
	interface CapacityRecord {
		key: number;
		user: string;
		hoursPerDay: number;
		leaveDates: string[];
		activity: string;
	}
	const [responseData, setResponseData] = useState<any[]>([]);
	const { input } = planerViewStore();
	const regex = new RegExp(input, "i");
	const filteredData = input
		? responseData.filter((record) => regex.test(record.user))
		: responseData;

	const [form] = Form.useForm();
	const [open, setOpen] = useState(false);
	const [record, setRecord] = useState<any>();

	const planId = useParams().planId;
	// const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { openCapacityPlanner } = planerViewStore();

	const { data: capacityPlan, run: fetchCapacityPlan } = useRequest(
		(id) => findByPlan(id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data);
			},
			onError: (error) => {
				message.error(error.message);
			},
		}
	);

	useEffect(() => {
		if (capacityPlan) {
			const newdata = capacityPlan[0].teamMembers.map((test: any) => {
				return {
					user: test.userProfile.name,
					hoursPerDay: test.hoursPerDay,
					leaveDates: test.leaveDates,
					activity: test.activity,
					_id: test._id,
				};
			});
			setResponseData(newdata);
		}
	}, [capacityPlan]);

	const setFormValues = (record: any) => {
		const { hoursPerDay, leaveDates } = record;
		form.setFieldsValue({
			hoursPerDay,
			olderleaveDates: leaveDates,
			leaveDates: [],
		});
	};

	const columns = [
		{
			title: "User",
			dataIndex: "user",
			key: "user",
			render: (_text: any, record: CapacityRecord) => (
				<Text style={{ fontSize: "14px", fontWeight: "500" }}>
					{record.user}
				</Text>
			),
		},
		{
			title: "No. of Hours per Day",
			dataIndex: "hoursPerDay",
			key: "hoursPerDay",
			render: (text: number) => (
				<Text style={{ fontSize: "14px", fontWeight: "500" }}>{text}</Text>
			),
		},
		{
			title: "Leave Dates",
			dataIndex: "leaveDates",
			key: "leaveDates",
			render: (text: string[]) => (
				<div>
					{text.map((date, index) => (
						<div key={index} style={{ fontSize: "14px", fontWeight: "500" }}>
							{moment(date).isValid()
								? moment(date).format("MMM Do YY")
								: "No leave Dates"}
						</div>
					))}
				</div>
			),
		},
		{
			title: "Action",
			dataIndex: "activity",
			key: "activity",
			render: (_id: any, record: any) => (
				<Tooltip title="Edit">
					<Typography.Link
						onClick={() => {
							setOpen(true);
							setRecord(record);
							setFormValues(record);
						}}
					>
						<EditOutlined style={{ color: "#0444bf" }} />
					</Typography.Link>
				</Tooltip>
			),
		},
	];

	const { run: updateCapacityPlan } = useRequest(
		(planid, memberId, data) =>
			updateTeamMember(
				planid,
				memberId,
				data,
				userProfileStoreInstance.idToken
			),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data);
				setOpen(false);
				form.resetFields();
				fetchCapacityPlan(planId);
			},
			onError: (error) => {
				message.error(error.message);
			},
		}
	);

	const finish = async (values: any) => {
		const olderleaveDates = values?.olderleaveDates || [];
		const formattedDates = values?.leaveDates.map((dateObj: any) =>
			dateObj?.format("YYYY-MM-DD")
		);

		const allLeaveDates = [...formattedDates, ...olderleaveDates];

		updateCapacityPlan(planId, record._id, {
			hoursPerDay: values.hoursPerDay,
			leaveDates: allLeaveDates,
		});
	};

	useEffect(() => {
		fetchCapacityPlan(planId);
	}, [openCapacityPlanner]);

	useEffect(() => {
		form.setFieldsValue({
			leaveDates: [],
		});
	}, [form]);

	return (
		<Layout style={{ background: "#FAFBFF" }}>
			<CapacityPlanNav />
			<AddCapacityPlan planID={planId} />
			<Layout>
				<Content
					style={{ backgroundColor: "#FAFBFF" }}
					className="capacity-content-container"
				>
					<Card
						style={{
							backgroundColor: "#EDF4FF",
							margin: "1rem",
							padding: "1rem",
							overflow: "scroll",
							height: "73vh",
							maxWidth: "100vw",
							display: "block",
							position: "relative",
						}}
					>
						<Table
							scroll={{ y: 400 }}
							columns={columns}
							key={"capacityPlan"}
							pagination={{
								pageSize: 10,
								pageSizeOptions: ["10", "20", "30"],
								showTotal: (total, range) =>
									`${range[0]}-${range[1]} of ${total} items`,
							}}
							dataSource={filteredData}
						/>
					</Card>
				</Content>

				<Modal
					width={"45vw"}
					open={open}
					onCancel={() => setOpen(false)}
					onOk={() => form.submit()}
				>
					<Form
						style={{ height: "10vh", margin: "0 0 20vh 0" }}
						form={form}
						onFinish={finish}
					>
						<Form.Item
							label="No. of hours"
							name="hoursPerDay"
							style={{ margin: "5vh 0  5vh 0" }}
							rules={[{ required: true, message: "Please select a type" }]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Old Leave Dates"
							name="olderleaveDates"
							style={{ margin: "5vh 0  5vh 0" }}
						>
							<Select
								mode="multiple"
								allowClear
								style={{ width: "100%" }}
								placeholder="No Leave Dates"
								onChange={() => {}}
							/>
						</Form.Item>

						<Form.Item
							label="Leave Dates"
							name="leaveDates"
							style={{ margin: "5vh 0  5vh 0" }}
							rules={[{ required: true, message: "Please select a type" }]}
						>
							<DatePicker multiple />
						</Form.Item>
					</Form>
				</Modal>
			</Layout>
		</Layout>
	);
};

export default CapacityPlanning;
