import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useEffect, useState } from "react";

export interface AnimTextProps {
	text: string;
	delay: number;
	duration: number;
}

export default function AnimText({ text, delay, duration }: AnimTextProps) {
	const [done, setDone] = useState(false);
	const count = useMotionValue(0);
	const rounded = useTransform(count, (latest) => Math.round(latest));
	const displayText = useTransform(rounded, (latest) => text.slice(0, latest));

	useEffect(() => {
		const controls = animate(count, text.length, {
			type: "tween",
			delay: delay,
			duration: duration,
			ease: "easeInOut",
			onComplete: () => {
				setDone(true);
			},
		});
		return controls.stop;
	}, []);

	return (
		<span>
			<motion.span>{displayText}</motion.span>
			{done && (
				<>
					<br /> <br />
				</>
			)}
		</span>
	);
}
