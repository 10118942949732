import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, message, Modal, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

interface AddRolesModalProps {
	isVisible: boolean;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	onAddRole: (newRole: any) => void;
}

const AddRolesModal: React.FC<AddRolesModalProps> = ({
	isVisible,
	setIsVisible,
	onAddRole,
}) => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [permissions, setPermissions] = useState<any[]>([]);
	const [options, setOptions] = useState<any[]>([]);

	const fetchPermissions = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/permissions`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const PermissionsData = await response.json();
				const newPermissions = PermissionsData.map((permission: any) => ({
					label: permission.name,
					value: permission._id,
				}));
				setPermissions(newPermissions);
				setOptions(newPermissions);
			}
		} catch (error) {
			console.error("Error fetching permissions:", error);
		}
	};

	const handleChange = (value: string) => {
		setOptions(
			permissions.filter((permission: any) =>
				permission.label.toLowerCase().includes(value.toLowerCase())
			)
		);
	};

	useEffect(() => {
		fetchPermissions();
	}, []);

	const handleButtonClick = async (values: any) => {
		const name = values.roleName;
		const createdBy = "defaultCreatedBy";
		const updatedBy = "defaultUpdatedBy";

		const postData = {
			name,
			createdBy,
			updatedBy,
			permissions: values.permissions,
			account_Id: userProfileStoreInstance.profile.account_Id,
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/roles`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(postData),
			});

			if (response.ok) {
				const responseData = await response.json();
				message.success("Role added successfully");
				onAddRole(responseData); // Call the onAddRole function with the new role
				setIsVisible(false);
				form.resetFields(); // Reset form fields
				navigate("/settings/role");
			} else if (response.status === 401) {
				// Handle unauthorized
			} else {
				const errorData = await response.json();
				message.error(`Error adding role. Please try again.${errorData}`);
			}
		} catch (error: any) {
			message.error(`Error adding role. Please try again: ${error.message}`);
		}
	};

	const handleCancelClick = () => {
		setIsVisible(false); // Close the modal
	};

	return (
		<Modal
			title="Add Roles"
			visible={isVisible}
			onCancel={handleCancelClick}
			footer={null}
		>
			<Form
				form={form}
				onFinish={handleButtonClick}
				layout="horizontal"
				colon={false}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							label="Role Name"
							name="roleName"
							required
							rules={[
								{ required: true, message: "Please input the Role Name!" },
							]}
						>
							<Input placeholder="Enter role name" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							label="Permissions"
							name="permissions"
							rules={[
								{
									required: true,
									message: "Please select at least one permission",
								},
							]}
						>
							<Select
								mode="multiple"
								allowClear
								style={{ width: "100%" }}
								placeholder="Please select"
								onSearch={handleChange}
								options={options}
								filterOption={false}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row justify="end">
					<Col>
						<Button
							size="small"
							onClick={handleCancelClick}
							style={{
								marginRight: "8px",
								borderRadius: "6px",
								fontSize: "14px",
							}}
						>
							Cancel
						</Button>
						<Button
							size="small"
							type="primary"
							htmlType="submit"
							style={{ borderRadius: "6px", fontSize: "14px" }}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default AddRolesModal;
