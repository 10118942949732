import React, { useEffect, useState } from "react";
import { Button, Modal, Image, Flex, Typography } from "antd";
import "./ConformationModel.css";
import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";

const ConformationModel: React.FC<any> = ({
	isOpen,
	onClose,
	onOk,
	commentCount,
}) => {
	const { Title, Text } = Typography;

	const [randomItem, setRandomItem] = useState<{
		paragraph: string;
		image: string;
		title: string;
	} | null>(null);
	// const [hasComment, setHasComment] = useState<boolean>(false);

	const itemsWithoutComment = [
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_designer.svg",
			title: "Ready for the Next Adventure?🌟",
			paragraph:
				"You've moved through the current section without leaving any comments.  Want to add any comments?",
		},
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_futuristic.svg",
			title:
				"Thrilled to see your satisfaction! Ready for the next part? It might take a bit, but your patience means a lot.",
			paragraph: "Want to shape things more? Feel free to add your comments!",
		},
	];

	const itemsWithComment = [
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_working_remotely.svg",
			title:
				"Happy to see your satisfaction! Ready for the next part? It might take a bit, but your patience means a lot.",
			paragraph:
				"Want to shape things more? Feel free to add your comments! We'll be back with your upgraded experience soon!",
		},
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_start_building.svg",
			title: "High-Five for Your Comments! 🌟",
			paragraph:
				"Your thoughts have been received, and we're thrilled! We're now cooking up some magic to apply your feedback and regenerate the current section. Hang tight for a few minutes, okay?",
		},
	];

	useEffect(() => {
		// setHasComment(commentCount > 0);
		const itemsArray =
			commentCount > 0 ? itemsWithComment : itemsWithoutComment;
		const randomIndex = Math.floor(Math.random() * itemsArray.length);
		setRandomItem(itemsArray[randomIndex]);
	}, [commentCount, isOpen]);

	return (
		<>
			<Modal
				width="60vw"
				open={isOpen}
				footer={null}
				centered
				onOk={onOk}
				onCancel={onClose}
			>
				<Flex className="modal" align="center" justify="center" vertical>
					{randomItem && (
						<>
							<Flex
								vertical
								align="center"
								justify="center"
								className="modalContent"
							>
								<Image
									height="40vh"
									src={randomItem.image}
									alt="Random Image"
									className="modalImage"
								/>

								<Title className="modalTitle" level={4}>
									{randomItem.title}
								</Title>
								<Text className="modalText">{randomItem.paragraph}</Text>
							</Flex>
						</>
					)}
					<Flex
						gap={35}
						justify="center"
						align="center"
						className="modalFooter"
					>
						<Title level={5}>No. Comments: {commentCount}</Title>
						<Title level={5}>No. of regeneration: 0</Title>
					</Flex>

					<Flex gap={30} className="modalButtons">
						<Button onClick={onClose}>
							Add Comment <EditOutlined />
						</Button>
						<Button type="primary" onClick={onOk}>
							{`Let's Continue`}
							<ArrowRightOutlined />
						</Button>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};

export default ConformationModel;
