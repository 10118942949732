import React, { useEffect, useState } from "react";
import "./ConformationModel.css";
import { Modal, Image, Flex, Typography } from "antd";

const WatingModel: React.FC<any> = ({ comment, isopen }) => {
	const { Text } = Typography;
	// const [open, setOpen] = useState(false);
	const [randomItem, setRandomItem] = useState<{
		paragraph: string;
		image: string;
	} | null>(null);
	// const [hasComment, setHasComment] = useState<boolean>(true);

	const itemsWithComment = [
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_happy_music.svg",
			paragraph:
				"We've received your comments, and behind the scenes, we're orchestrating a technical symphony. Take a moment for yourself – listen to a song, do a little dance, or practice your favorite karate move. We'll be back with your upgraded experience soon!",
		},
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_medidation.svg",
			paragraph:
				"Let's take a short break! We're refining your comments to perfection. Use this time to channel your inner zen master – take a deep breath, relax, and imagine your happy place. We'll resume the adventure shortly!",
		},
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_futuristic.svg",
			paragraph:
				"Great input! You've left 5 comments on this [section name]. Take a break while we work on your suggestions. It might take a bit. We'll let you know when it's done!",
		},
	];

	const itemsWithoutComment = [
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_coffee_time.svg",
			paragraph:
				"Hold on tight for just a few minutes! We're brewing up some behind-the-scenes magic. Why not grab a cup of coffee, take a quick stretch, and we'll have everything ready for you in no time. ☕️✨",
		},
		{
			image:
				"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_drink_coffee.svg",
			paragraph:
				"Take a sip of your coffee while we work some magic behind the scenes. Your patience is the secret sauce for making things even better. Thanks a bunch for helping us out!",
		},
	];

	useEffect(() => {
		// setHasComment(false);
		const itemsArray =
			comment.length > 0 ? itemsWithComment : itemsWithoutComment;

		const randomIndex = Math.floor(Math.random() * itemsArray.length);
		setRandomItem(itemsArray[randomIndex]);
	}, [comment, isopen]);

	return (
		<>
			{/* <Button type="primary" onClick={() => setOpen(true)}>
				Open Modal of 1000px width
			</Button> */}
			<Modal
				width="60vw"
				open={isopen}
				footer={null}
				onOk={() => {}}
				onCancel={() => {}}
			>
				<Flex className="modal" align="center" justify="center">
					{randomItem && (
						<>
							<Flex vertical gap={30} className="modalContent">
								<Image
									height={"50vh"}
									src={randomItem.image}
									alt="Random Image"
									className="modalImage"
								/>
								<Text className="modalText">{randomItem.paragraph}</Text>
							</Flex>
						</>
					)}
				</Flex>
			</Modal>
		</>
	);
};

export default WatingModel;
