/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { Badge, Button, Menu, Popover, Input, message, Flex } from "antd";
import {
	ExclamationOutlined,
	FilterOutlined,
	FallOutlined,
	DashOutlined,
	RiseOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import { planerViewStore } from "../../stores/PlannerStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import "./Popover.css";

const initialPriorityItems = [
	{
		key: "important",
		label: "Important",
		icon: <ExclamationOutlined style={{ color: "red" }} />,
	},
	{
		key: "urgent",
		label: "Urgent",
		icon: <RiseOutlined style={{ color: "orange" }} />,
	},
	{
		key: "medium",
		label: "Medium",
		icon: <DashOutlined style={{ color: "golden" }} />,
	},
	{
		key: "low",
		label: "Low",
		icon: <FallOutlined style={{ color: "green" }} />,
	},
];

const PriorityFilterComponent = ({ type }: { type: string }) => {
	const { selectedItemDetails, setSelectedItemDetails } = planerViewStore();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const [priorityItems, setPriorityItems] = useState(initialPriorityItems);
	const [popoverVisible, setPopoverVisible] = useState(false);
	const [selectedIcon, setSelectedIcon] = useState<JSX.Element>(
		<FilterOutlined />
	);

	const priorityIconMap: {
		[key in "IMPORTANT" | "URGENT" | "MEDIUM" | "LOW"]: JSX.Element;
	} = {
		IMPORTANT: <ExclamationOutlined style={{ color: "red" }} />,
		URGENT: <RiseOutlined style={{ color: "orange" }} />,
		MEDIUM: <DashOutlined style={{ color: "golden" }} />,
		LOW: <FallOutlined style={{ color: "green" }} />,
	};

	const getDefaultIcon = (
		priority: "IMPORTANT" | "URGENT" | "MEDIUM" | "LOW"
	) => {
		return priorityIconMap[priority] || <FilterOutlined />;
	};

	useEffect(() => {
		setSelectedIcon(
			getDefaultIcon(
				selectedItemDetails.priority as
					| "IMPORTANT"
					| "URGENT"
					| "MEDIUM"
					| "LOW"
			)
		);
	}, [selectedItemDetails.priority]);

	const onChangeValue = (event: any) => {
		const value = event.target.value.toLowerCase();
		const filteredItems = initialPriorityItems.filter((item) =>
			item.label.toLowerCase().includes(value)
		);
		setPriorityItems(filteredItems);
	};

	const handleMenuClick = async (item: any) => {
		const priorityMap: any = {
			important: "IMPORTANT",
			urgent: "URGENT",
			medium: "MEDIUM",
			low: "LOW",
		};

		const newPriority = priorityMap[item.key];
		const newIcon = priorityIconMap[
			newPriority as "IMPORTANT" | "URGENT" | "MEDIUM" | "LOW"
		] || <FilterOutlined />;

		const requestOptions = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify({ priority: newPriority }),
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				requestOptions
			);
			if (response.ok) {
				// Update local state
				setSelectedItemDetails({
					...selectedItemDetails,
					priority: newPriority,
				});
				message.success("Priority updated successfully");
				setSelectedIcon(newIcon); // Update the button icon
				setPopoverVisible(false);
			} else {
				message.error("Failed to update priority");
			}
		} catch (error) {
			console.error("Error updating priority:", error);
			message.error("An error occurred while updating priority");
		}
	};
	if (type === "Menu") {
		return (
			<Popover
				title={null}
				placement="leftBottom"
				content={
					<>
						<Input
							placeholder="Search"
							onChange={onChangeValue}
							allowClear
							suffix={<SearchOutlined />}
							key={"search"}
						/>
						<Menu
							onClick={handleMenuClick}
							style={{ width: 256 }}
							mode="vertical"
						>
							{priorityItems.map((item) => (
								<Menu.Item key={item.key} icon={item.icon}>
									{item.label}
								</Menu.Item>
							))}
						</Menu>
					</>
				}
				trigger="click"
				visible={popoverVisible}
				onVisibleChange={setPopoverVisible}
				style={{ textAlign: "center", border: "2px solid red" }}
			>
				<Flex justify="center">
					<Badge color="blue" offset={[-40, 0]}>
						<Button style={{ color: "black" }} type="link">
							Priority : {selectedIcon}
							{selectedItemDetails.priority}
						</Button>
					</Badge>
				</Flex>
			</Popover>
		);
	}
	if (type === "Tag") {
		return (
			<Popover
				title={null}
				// placement="leftBottom"
				content={
					<>
						<Input
							placeholder="Search"
							onChange={onChangeValue}
							allowClear
							suffix={<SearchOutlined />}
							key={"search"}
						/>
						<Menu
							onClick={handleMenuClick}
							style={{ width: 256 }}
							mode="vertical"
						>
							{priorityItems.map((item) => (
								<Menu.Item key={item.key} icon={item.icon}>
									{item.label}
								</Menu.Item>
							))}
						</Menu>
					</>
				}
				trigger="click"
				visible={popoverVisible}
				onVisibleChange={setPopoverVisible}
				style={{ textAlign: "center", border: "2px solid red" }}
			>
				<Flex justify="center">Priority : {selectedItemDetails.priority}</Flex>
			</Popover>
		);
	}
};

export default PriorityFilterComponent;
