import React, { useEffect, useState } from "react";
import { Row, message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { planerViewStore } from "../stores/PlannerStore";
import { useRequest } from "ahooks";
import { getWorkItemSForMYTask } from "../api/WorkItemAPI";

const AssignedTask = () => {
	const [data, setData] = useState<any[]>([]);
	const [workItemData, setWorkItemData] = useState<any[]>([]);
	console.log("workItemData", workItemData);
	const { FetchPlan } = planerViewStore();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { data: workitemTaskData, run: fetchTaskStory } = useRequest(
		(worspaceId, type, assigned) =>
			getWorkItemSForMYTask(
				worspaceId,
				type,
				assigned,
				userProfileStoreInstance.idToken
			),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Task data");
				// message.success(`All Tasks fetched successfully: ${result.length}`);
			},
			onError: (error) => {
				message.error(`Failed to fetch Tasks : ${error.message}`);
				// throw new Error(`Fetching tasks failed: ${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (workitemTaskData) {
			setData(workitemTaskData);
			const data = workitemTaskData.map(({ type, WorkitemId }: any) => ({
				type: type,
				typeId: WorkitemId,
			}));
			setWorkItemData(data);
		}
	}, [workitemTaskData]);

	useEffect(() => {
		fetchTaskStory(currentWorkspace?._id, "TASK", "MyTasks");
	}, [FetchPlan]);

	const renderCount = (filterType: string) => (
		<p
			style={{
				fontSize: "28px",
				fontWeight: "bold",
				fontFamily: "Open Sans",
				marginLeft: "8vw",
			}}
		>
			Count: {data.filter((item) => item.type === filterType).length}
		</p>
	);

	return (
		<Row className="row" gutter={20} justify="start" align="top" wrap={true}>
			{renderCount("TASK")}
		</Row>
	);
};

export default AssignedTask;
