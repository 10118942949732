import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal, Input, message, Spin, Alert } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import { useNavigate } from "react-router-dom";
import {
	createWhiteboard,
	getWhiteboardByWorkspaceId,
} from "../../../api/WhiteboardApi";
import { WhiteboardModel } from "../../../models/WhiteboardModel";
import { useRequest } from "ahooks";

interface Box {
	_id: string;
	name: string;
	workspaceId: string;
}

const WhiteBoardDashboard: React.FC = () => {
	const navigate = useNavigate();
	const [boxes, setBoxes] = useState<Box[]>([]);
	const [visible, setVisible] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>("");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const { run: fetchBoxes } = useRequest(
		async () => {
			return await getWhiteboardByWorkspaceId(
				currentWorkspace._id,
				userProfileStoreInstance.idToken ?? ""
			);
		},
		{
			manual: true,
			onSuccess: (data) => {
				setBoxes(data);
			},
			onError: (error) => {
				message.error("Failed to fetch boxes: " + error.message);
			},
		}
	);

	useEffect(() => {
		if (currentWorkspace?._id && userProfileStoreInstance.idToken) {
			fetchBoxes();
		}
	}, [currentWorkspace?._id, userProfileStoreInstance.idToken]);

	const createWhiteboardRequest = useRequest(createWhiteboard, {
		manual: true,
		onSuccess: (data) => {
			setBoxes([...boxes, data]);
			message.success("Box added successfully!");
		},
		onError: (error) => {
			message.error("Failed to add box: " + error.message);
		},
	});

	const addBox = () => {
		setVisible(true);
	};

	const handleOk = () => {
		if (inputValue.trim() !== "") {
			const newBox: Omit<Box, "_id"> = {
				name: inputValue.trim(),
				workspaceId: currentWorkspace._id,
			};
			createWhiteboardRequest.run(
				newBox as WhiteboardModel,
				userProfileStoreInstance.idToken ?? ""
			);
		}

		setVisible(false);
		setInputValue("");
	};

	const handleCancel = () => {
		setVisible(false);
		setInputValue("");
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleCardClick = (id: string) => {
		navigate(`/space/planner/flow/${id}`);
	};

	return (
		<div style={{ height: "80vh", overflowY: "scroll", padding: "10px" }}>
			<Row gutter={[16, 16]}>
				<Col span={6} style={{ marginBottom: "10px" }}>
					<Card
						hoverable
						onClick={addBox}
						style={{
							height: "18vh",
							backgroundColor: "#1890ff",
							margin: "0 10px",
							cursor: "pointer",
							textAlign: "center",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<PlusOutlined /> Add Box
					</Card>
					<Modal
						title="Enter Box Name"
						open={visible}
						onOk={handleOk}
						onCancel={handleCancel}
					>
						<Input value={inputValue} onChange={handleInputChange} />
					</Modal>
				</Col>
				{boxes &&
					boxes.map((box, index) => (
						<Col span={6} key={index}>
							<Card
								onClick={() => handleCardClick(box._id)}
								style={{
									backgroundColor: "#1890ff",
									height: "18vh",
									color: "#fff",
									margin: "0 10px",
									cursor: "pointer",
									textAlign: "center",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
									transition: "background-color 0.3s ease",
								}}
							>
								{box.name}
							</Card>
						</Col>
					))}
			</Row>
			{createWhiteboardRequest.loading && <Spin tip="Adding box..." />}
			{createWhiteboardRequest.error && (
				<Alert
					message="Error"
					description={`Failed to add box: ${createWhiteboardRequest.error.message}`}
					type="error"
					showIcon
				/>
			)}
		</div>
	);
};

export default WhiteBoardDashboard;
