import React from "react";
import "./PlanerHeader.css";
import {
	SearchOutlined,
	//ArrowLeftOutlined
} from "@ant-design/icons";
import {
	Flex,
	Button,
	//Space,
	Input,
	//TreeSelect,
	//Tooltip,
	//Breadcrumb,
	Steps,
} from "antd";
// import { useViewsStores } from "../../stores/BacklogStore";
// import WorkItemModal from "./AddWorkitemModel";
import { planerViewStore } from "../../stores/PlannerStore";
import { usePlanStore } from "../../stores/PlanStore";
import { useNavigate } from "react-router-dom";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import BreadCrumb from "../Editors/BreadCrumb";
//import Constants from "../../util/ConstantsUtil";

function CapacityPlanNav() {
	const {
		input,
		setInput,
		setOpenCapacityPlanner,
		//setfilter
	} = planerViewStore();
	const navigate = useNavigate();

	const { newPlan } = usePlanStore();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInput(event.target.value);
	};
	const handleAddWorkItemClick = () => {
		setOpenCapacityPlanner(true);
		// setModalVisible(true);
	};

	const handleNextStep = () => {
		navigate(`/space/planner/workitemView/${newPlan.planId}`);
	};

	const onChangestep = (value: number) => {
		if (value === 0) {
			navigate(`/space/planner/planner?planId=${newPlan.planId}`);
		}
		if (value === 1) {
			navigate(`/space/planner/taskboard/${newPlan.planId}`);
		}
	};

	// const handleNavigate = () => {
	// 	navigate("/space/planner/plans");
	// };

	return (
		<div>
			<div style={{ marginTop: newPlan.active ? "8vh" : "3vh" }}>
				<Flex justify="space-between" align="center">
					{newPlan.active ? (
						<Flex
							style={{ alignItems: "center", marginLeft: "3vw", width: "65vw" }}
						>
							<Steps
								current={2}
								onChange={onChangestep}
								items={[
									{
										title: "",
										// description,
									},
									{
										title: "",
										// description,
										// subTitle: "Left 00:00:08",
									},
									{
										title: "Add People To Plan",
										// description,
									},
									{
										title: "",
										// description,
									},
								]}
							/>
						</Flex>
					) : (
						<Flex style={{ alignItems: "center", marginLeft: "3vw" }}>
							{/* <Space>
								<Tooltip placement="bottom" title="Back">
									<Button
										onClick={handleNavigate}
										shape="circle"
										icon={
											<ArrowLeftOutlined
												style={{ color: "#0444BF", borderColor: "#0444BF" }}
											/>
										}
										size="large"
									/>
								</Tooltip>
								<Flex vertical>
									 <Breadcrumb>
										<Breadcrumb.Item>
											<h3
												color="#87d068"
												style={{ fontSize: "1.1rem", fontFamily: "Poppins" }}
											>
												Capacity Plan
											</h3>
										</Breadcrumb.Item>
									</Breadcrumb> 
								</Flex>
							</Space> */}
							<BreadCrumb config={breadcrumbConfig.capacity} />
						</Flex>
					)}

					<Flex justify="end" gap={"small"}>
						{!newPlan.active && (
							<>
								<Input
									suffix={<SearchOutlined />}
									size="middle"
									style={{
										height: "5vh",
										width: "14vw",
										fontSize: "0.9rem",
										fontFamily: "Poppins",
									}}
									placeholder="Search"
									onChange={handleChange}
									value={input}
								/>

								{/* <Flex>
									<TreeSelect
										showSearch
										size="small"
										style={{ width: "8vw", height: "100%" }}
										value={value}
										dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
										placeholder="Filter By"
										allowClear
										multiple
										treeDefaultExpandAll={false}
										onChange={onChange}
										treeData={treeData}
									/>
								</Flex> */}
							</>
						)}

						<Button
							size="middle"
							shape="round"
							type="primary"
							onClick={handleAddWorkItemClick}
						>
							Add members
						</Button>
						{newPlan.active && (
							<Button
								size="middle"
								shape="round"
								type="primary"
								onClick={handleNextStep}
							>
								Next Step
							</Button>
						)}
					</Flex>
				</Flex>
			</div>
		</div>
	);
}
export default CapacityPlanNav;
