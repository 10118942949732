import React, { useState } from "react";
import { Modal, Form, Input, DatePicker, Button, Select } from "antd";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import { getStartEndDateForProject, initTasks } from "../util/RoadmapUtil";
import "gantt-task-react/dist/index.css";

interface AddTaskModalProps {
	visible: boolean;
	onCancel: () => void;
	onAddTask: (newTask: Task) => void;
	existingTasks: Task[];
}

const { Option } = Select;

const AddTaskModal: React.FC<AddTaskModalProps> = ({
	visible,
	onCancel,
	onAddTask,
	existingTasks,
}) => {
	const [form] = Form.useForm();

	const handleOk = () => {
		form
			.validateFields()
			.then((values: any) => {
				form.resetFields();
				onAddTask({
					start: values.startDate.toDate(),
					end: values.endDate.toDate(),
					name: values.taskName,
					id: `Task ${Date.now()}`,
					progress: 0,
					type: "task",
					dependencies: values.dependencies,
				});
			})
			.catch((info: any) => {
				console.log("Validate Failed:", info);
			});
	};

	return (
		<Modal
			visible={visible}
			title="Add New Task"
			onCancel={onCancel}
			onOk={handleOk}
		>
			<Form form={form} layout="vertical">
				<Form.Item
					name="taskName"
					label="Task Name"
					rules={[{ required: true, message: "Please enter the task name" }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="startDate"
					label="Start Date"
					rules={[{ required: true, message: "Please select the start date" }]}
				>
					<DatePicker />
				</Form.Item>
				<Form.Item
					name="endDate"
					label="End Date"
					rules={[{ required: true, message: "Please select the end date" }]}
				>
					<DatePicker />
				</Form.Item>
				<Form.Item name="dependencies" label="Dependencies">
					<Select mode="multiple" placeholder="Select dependencies">
						{existingTasks.map((task) => (
							<Option key={task.id} value={task.id}>
								{task.name}
							</Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};
type ViewSwitcherProps = {
	isChecked: boolean;
	onViewListChange: (isChecked: boolean) => void;
	onViewModeChange: (viewMode: ViewMode) => void;
};
const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
	onViewModeChange,
	onViewListChange,
	isChecked,
}) => {
	return (
		<div className="ViewContainer">
			<Button
				className="Button"
				onClick={() => onViewModeChange(ViewMode.QuarterDay)}
			>
				Quarter of Day
			</Button>
			<Button
				className="Button"
				onClick={() => onViewModeChange(ViewMode.HalfDay)}
			>
				Half of Day
			</Button>
			<Button className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>
				Day
			</Button>
			<Button
				className="Button"
				onClick={() => onViewModeChange(ViewMode.Week)}
			>
				Week
			</Button>
			<Button
				className="Button"
				onClick={() => onViewModeChange(ViewMode.Month)}
			>
				Month
			</Button>

			<div className="Switch">
				<label className="Switch_Toggle">
					<input
						type="checkbox"
						defaultChecked={isChecked}
						onClick={() => onViewListChange(!isChecked)}
					/>
					<span className="Slider" />
				</label>
				Show Task List
			</div>
		</div>
	);
};

const Roadmap: React.FC = function () {
	const [view, setView] = useState<ViewMode>(ViewMode.Day);
	const [tasks, setTasks] = useState<Task[]>(initTasks());
	const [isChecked, setIsChecked] = useState(true);
	const [isAddTaskModalVisible, setAddTaskModalVisible] = useState(false);

	const handleAddTask = (newTask: Task) => {
		setTasks([...tasks, newTask]);
		setAddTaskModalVisible(false);
	};
	let columnWidth = 60;
	if (view === ViewMode.Month) {
		columnWidth = 300;
	} else if (view === ViewMode.Week) {
		columnWidth = 250;
	}

	console.log(tasks);

	const handleTaskChange = (task: Task) => {
		console.log("On date change Id:" + task.id);
		let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
		if (task.project) {
			const [start, end] = getStartEndDateForProject(newTasks, task.project);
			const project =
				newTasks[newTasks.findIndex((t) => t.id === task.project)];
			if (
				project.start.getTime() !== start.getTime() ||
				project.end.getTime() !== end.getTime()
			) {
				const changedProject = { ...project, start, end };
				newTasks = newTasks.map((t) =>
					t.id === task.project ? changedProject : t
				);
			}
		}
		setTasks(newTasks);
	};

	const handleTaskDelete = (task: Task) => {
		const conf = window.confirm(`Are you sure about ${task.name} ?`);
		if (conf) {
			setTasks(tasks.filter((t) => t.id !== task.id));
		}
	};

	const handleProgressChange = async (task: Task) => {
		setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
		console.log("On progress change Id:" + task.id);
	};

	const handleDblClick = (task: Task) => {
		alert("On Double Click event Id:" + task.id);
	};

	const handleSelect = (task: Task, isSelected: boolean) => {
		console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
	};

	const handleExpanderClick = (task: Task) => {
		setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
		console.log("On expander click Id:" + task.id);
	};

	return (
		<div>
			<ViewSwitcher
				onViewModeChange={(viewMode: ViewMode) => setView(viewMode)}
				onViewListChange={setIsChecked}
				isChecked={isChecked}
			/>
			<Button onClick={() => setAddTaskModalVisible(true)}>Add Task</Button>
			<AddTaskModal
				visible={isAddTaskModalVisible}
				onCancel={() => setAddTaskModalVisible(false)}
				onAddTask={handleAddTask}
				existingTasks={tasks}
			/>

			<Gantt
				tasks={tasks}
				viewMode={view}
				onDateChange={handleTaskChange}
				onDelete={handleTaskDelete}
				onProgressChange={handleProgressChange}
				onDoubleClick={handleDblClick}
				onSelect={handleSelect}
				onExpanderClick={handleExpanderClick}
				listCellWidth={""}
				columnWidth={columnWidth}
			/>
		</div>
	);
};

export default Roadmap;
