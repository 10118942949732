import { defineAbility } from "@casl/ability";
import { AdminPermissions, SubscriptionPermissions } from "./AccessUtil";

const mapPermissionsToAbilities = (
	userpermissions: any,
	SubscriptionPermissions: any
) => {
	const mappedAbilities: any = [];
	const finalAbilities: any = [];

	Object.keys(userpermissions).forEach((key) => {
		const action = userpermissions[key];
		const subject = key;
		mappedAbilities.push({
			action,
			subject,
		});
	});

	const numberOfProjectsLimit =
		SubscriptionPermissions.subscriptionLimits["number of projets"];
	const featuresLimit = SubscriptionPermissions.subscriptionLimits["features"];

	if (
		numberOfProjectsLimit &&
		numberOfProjectsLimit.value === 0 &&
		featuresLimit &&
		featuresLimit.value === false
	) {
		finalAbilities.push(
			...mappedAbilities.filter(
				(ability: any) =>
					ability.subject !== "WORKSPACE_CAN_CREATE" &&
					ability.subject !== "FEATURE_ENGINEERING_CAN_REVIEW"
			)
		);
	} else if (numberOfProjectsLimit && numberOfProjectsLimit.value === 0) {
		finalAbilities.push(
			...mappedAbilities.filter(
				(ability: any) => ability.subject !== "WORKSPACE_CAN_CREATE"
			)
		);
	} else if (featuresLimit && featuresLimit.value === false) {
		finalAbilities.push(
			...mappedAbilities.filter(
				(ability: any) => ability.subject !== "FEATURE_ENGINEERING_CAN_REVIEW"
			)
		);
	} else {
		finalAbilities.push(...mappedAbilities);
	}

	return finalAbilities;
};

export default defineAbility((can) => {
	const mappedAbilities = mapPermissionsToAbilities(
		AdminPermissions,
		SubscriptionPermissions
	);
	mappedAbilities.forEach((ability: any) => {
		can(ability.action, ability.subject);
	});
});
