import React, { useEffect, useState } from "react";
import {
	//Breadcrumb,
	Layout,
	Result,
	// 	Button,
	// 	Card,
	// 	Flex,
	// 	Input,
	// 	Modal,
	// 	Space,
	// 	Tabs,
	// 	Typography,
	Spin,
} from "antd";

import { useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import usePlatformStore from "../../stores/platformStore";
import { getNavigateUrlForWorkspaces } from "../../util/NavigateAssistUtil";
import Constants from "../../util/ConstantsUtil";
import DataSchemaV2 from "./DataSchemaV2";
import { Button, Flex, Form, Input, Modal, Space } from "antd";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import BreadCrumb from "../Editors/BreadCrumb";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";

// const LINE_HEIGHT = 30;
// const NODE_WIDTH = 250;
// const TABLE_MARGIN = 165;

const DataSchema: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [AddModelVisible, setAddModelVisible] = useState(false);

	const { isReviewEnabled, setCurrentActivity, setDataSchema } =
		useWorkspaceStore((state: any) => ({
			currentWorkspace: state.currentWorkspace,
			isReviewEnabled: state.isReviewEnabled,
			setCurrentActivity: state.setCurrentActivity,
			setDataSchema: state.setDataSchema,
		}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const [data, setData] = useState<any>([]);
	const { id } = useParams<{ id: string }>();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
		setUnauthorizedModel,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	useEffect(() => {
		const navigationUrls = getNavigateUrlForWorkspaces(
			`${id}`,
			currentWorkspace?.modules || [],
			Constants.DESIGN_ENGINEERING,
			Constants.DE_DATA_MODEL
		);
		setNextNavigateUrl(navigationUrls.nextUrl);
		setBackNavigateUrl(navigationUrls.prevUrl);
		setAppBarTitle(Constants.DE_DATA_MODEL);
		setCurrentModule(Constants.DESIGN_ENGINEERING);
		setCurrentPage(Constants.DE_DATA_MODEL);
	}, [currentWorkspace]);

	console.log(data);

	const handelOpen = () => {
		setAddModelVisible(true);
	};

	const fetchData = () => {
		if (userProfileStoreInstance.idToken) {
			fetch(
				`${process.env.REACT_APP_API_URL}/project-artifact/de-data-schema/${id}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			)
				.then((response) => {
					if (!response.ok) {
						if (response.status === 401) {
							setUnauthorizedModel(true);
						}
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then((result) => {
					setData(result);
					setCurrentActivity(result[0]);
					setDataSchema(result[0]);
					setLoading(false);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		}
	};

	useEffect(() => {
		fetchData();
	}, [isReviewEnabled]);

	const handleOK = () => {
		const id = uuidv4();
		const formData = form.getFieldsValue();
		const g: any = {};

		for (let index = 0; index < formData.items.length; index++) {
			g[formData.items[index].field] = {
				// Use updatedData[index].fieldName instead of updatedData[index].krittiks
				type: formData.items[index].type,
			};
		}

		// console.log(g, "g");

		const def = {
			data_schema: {
				[formData.name]: {
					properties: {
						...g,
					},
				},
			},
			id: id,
			partition_key: "id",
			collections_id: formData.name,
		};

		const SchemaData = {
			...data[0],
			data: {
				...data[0].data,
				collections: [...data[0].data.collections, def],
			},
		};

		fetch(`${process.env.REACT_APP_API_URL}/project-artifact/${data[0]._id}`, {
			method: "PUT",

			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(SchemaData),
		})
			.then((response) => {
				if (response.ok) {
					console.log("Update successful", response);
					form.resetFields();
					setAddModelVisible(false);
					fetchData();
				} else {
					console.error("Update failed", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});

		fetchData();
	};

	const handleCancel = () => {
		setAddModelVisible(false);
	};

	return (
		<Layout style={{ background: "#f5f8fe" }}>
			{loading ? ( // Show loading indicator while loading
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "100vh",
					}}
				>
					<Spin size="large" />
					<img
						style={{ marginTop: "10px", width: "150px" }}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
						alt="Loading"
					/>
					<Spin size="large" />
				</div>
			) : (
				<>
					{/* <Flex justify="start"> */}
					<Flex
						justify="space-between"
						style={{ alignItems: "center", width: "100%" }}
					>
						{/* <Space>
							<Flex vertical>
								<Breadcrumb>
									<Breadcrumb.Item>
										<h2
											color="#87d068"
											style={{ fontSize: "1.1rem", marginLeft: "3vw" }}
										>
											Database Schema
										</h2>
									</Breadcrumb.Item>
								</Breadcrumb>
							</Flex>
						</Space> */}
						<div style={{ fontSize: "1.1rem", marginLeft: "3vw" }}>
							<BreadCrumb config={breadcrumbConfig.dataschema} />
						</div>
						<Button onClick={handelOpen} type="primary">
							Add New Collection
						</Button>
					</Flex>
					<div
						style={{
							width: "fit-content",
							height: "1vh",
							margin: "2rem",
							padding: 0,
						}}
					>
						{/* {data.length > 0 && <DataSchemaV2 schemaData={data} />} */}
						{data.length > 0 ? (
							<DataSchemaV2 schemaData={data} />
						) : (
							<Result
								icon={<SmileOutlined style={{ color: "#0444BF" }} />}
								title="There are No Data Schema Generated!"
								style={{ marginTop: "10vh", marginLeft: "20vw" }}
							/>
						)}
						<Modal
							title="Add Collection"
							open={AddModelVisible}
							onCancel={handleCancel}
							footer={null}
						>
							<Form
								onFinish={handleOK}
								labelCol={{ span: 6 }}
								wrapperCol={{ span: 18 }}
								form={form}
								name="dynamic_form_complex"
								style={{ maxWidth: 600 }}
								autoComplete="off"
								initialValues={{ items: [{}] }}
							>
								<Form.Item label="Name" name="name">
									<Input placeholder="Database name" />
								</Form.Item>

								<Form.Item label="Field Data">
									<Form.List name={"items"}>
										{(subFields, subOpt) => (
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													rowGap: 16,
												}}
											>
												{subFields.map((subField) => (
													<Space key={subField.key}>
														<Form.Item noStyle name={[subField.name, "field"]}>
															<Input placeholder="field" />
														</Form.Item>
														<Form.Item noStyle name={[subField.name, "type"]}>
															<Input placeholder="type" />
														</Form.Item>
														<CloseOutlined
															onClick={() => {
																subOpt.remove(subField.name);
															}}
														/>
													</Space>
												))}
												<Button
													type="dashed"
													onClick={() => subOpt.add()}
													block
												>
													+ Add Sub Item
												</Button>
											</div>
										)}
									</Form.List>
								</Form.Item>

								<Form.Item>
									<Button type="primary" htmlType="submit">
										Save
									</Button>
								</Form.Item>

								{/* <Button type="dashed" onClick={() => add()} block>
									+ Add Item
								</Button> */}

								{/* <Form.Item noStyle shouldUpdate>
						{() => (
							<Typography>
								<pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
							</Typography>
						)}
					</Form.Item> */}
							</Form>
						</Modal>
					</div>
				</>
			)}
		</Layout>
		// <>
		// 	<Tabs
		// 		defaultActiveKey="2"
		// 		items={[

		// 			{
		// 				label: "Tab 2",
		// 				key: "2",
		// 				children: (
		// 					<Space
		// 						direction="vertical"
		// 						style={{ width: "90vw", height: "100%", overflow: "auto" }}
		// 						size={[0, 48]}
		// 					>
		// 						<Card
		// 							style={{
		// 								backgroundColor: "white",
		// 								overflow: "auto",
		// 								height: "80vh",
		// 							}}
		// 						>
		// 							<div
		// 								id="container"
		// 								data-testid="container"
		// 								style={{
		// 									width: "100%",
		// 									height: "200vh",
		// 									marginTop: "2rem",
		// 									overflow: "scroll",
		// 								}}
		// 							></div>
		// 							{showPopOver && (
		// 								<Modal
		// 									width={350}
		// 									onCancel={() => {
		// 										setShowPopOver(false);
		// 									}}
		// 									visible={showPopOver}
		// 									mask={false}
		// 									footer={false}
		// 									style={{
		// 										position: "absolute",
		// 										top: modalPosition?.top,
		// 										left: modalPosition?.left,
		// 									}}
		// 								>
		// 									<Flex justify="space-between">
		// 										<Typography.Title level={5}>
		// 											{" "}
		// 											Add a Comment{" "}
		// 										</Typography.Title>
		// 									</Flex>
		// 									<div>
		// 										<Input.TextArea
		// 											style={{ height: "15vh", marginBottom: "1vh" }}
		// 											placeholder="Type your comment here..."
		// 											onChange={(e) => setNewCommentContent(e.target.value)}
		// 											value={newCommentContent}
		// 										/>
		// 										<Button type="primary" onClick={postComment}>
		// 											Post Comment
		// 										</Button>
		// 									</div>
		// 								</Modal>
		// 							)}
		// 						</Card>
		// 					</Space>
		// 				),
		// 			},
		// 			{
		// 				label: "Tab 3",
		// 				key: "3",
		// 				children: <DataSchemaV2 schemaData={data}/>,
		// 			},
		// 		]}
		// 	/>
		// </>
	);
};

export default DataSchema;
