import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tag, Collapse, Avatar, Tooltip, List, Pagination } from "antd";
import {
	BugOutlined,
	CheckCircleFilled,
	CheckCircleOutlined,
	DashOutlined,
	DeploymentUnitOutlined,
	ExclamationOutlined,
	EyeOutlined,
	FallOutlined,
	FileTextOutlined,
	HarmonyOSOutlined,
	HighlightOutlined,
	Loading3QuartersOutlined,
	MessageOutlined,
	RiseOutlined,
	WechatWorkOutlined,
} from "@ant-design/icons";
import { planerViewStore } from "../../stores/PlannerStore";

import { useLocation } from "react-router-dom";
//import "./ListView.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import BacklogDropdownMenu from "./BacklogContextMenu";
import "./backloglistview.css";

interface AssigneeData {
	name: string;
	preferences?: {
		profileColor?: string;
	};
}

interface ItemData {
	_id: string;
	WorkitemId: string;
	key: string;
	title: string;
	priority: string;
	type: string;
	status: string;
	assignee: AssigneeData;
}

interface BacklogListViewItemProps {
	Data: ItemData[];
	ListSelectedGroupBy: any;
	setDrawerTab: any;
}

const BacklogListViewItemV2: React.FC<BacklogListViewItemProps> = ({
	Data,
	ListSelectedGroupBy,
	setDrawerTab,
}) => {
	const navigate = useNavigate();
	const { setOpen, setSelectedItemDetails } = planerViewStore();
	const url = useLocation().pathname;
	const [selectRec, setSelectRec] = useState<ItemData | undefined>(undefined);
	const [tags, setTags] = useState<any[]>([]);
	const [newData, setNewData] = useState<ItemData[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(20);

	useEffect(() => {
		setNewData(Data);
	}, [Data]);

	const setGroupKey = (selectedGroupBy: string) => {
		switch (selectedGroupBy) {
			case "Type":
				return "type";
			case "Priority":
				return "priority";
			case "Status":
				return "status";
			case "release":
				return "release";
			default:
				if (url === "/space/planner/backlogs") {
					return "None";
				} else if (url === "/space/planner/mytasks") {
					return "status";
				} else {
					return "release";
				}
		}
	};

	const groupKey = setGroupKey(ListSelectedGroupBy);

	const groupedTasks = groupBy(newData, groupKey);

	const handleEyeIconClick = (item: any, newData: any[]) => {
		setOpen(true);
		setDrawerTab("details");

		const selectedItemData = newData.find(
			(dataItem) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);
	};

	const handleEditor = (item: any) => {
		const selectedItemData = newData.find(
			(dataItem) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);

		navigate(`/space/planner/editor/${item?._id}`);
	};

	const handleMapIconClick = (item: any, newData: any[]) => {
		setOpen(true);
		setDrawerTab("mapview");

		const selectedItemData = newData.find(
			(dataItem) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);
	};
	const handleChatIconClick = (item: any, newData: any[]) => {
		setOpen(true);
		setDrawerTab("discussion");

		const selectedItemData = newData.find(
			(dataItem) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);
	};

	useEffect(() => {
		const fetchTagsData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/team-members?workspace-id=${currentWorkspace._id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (response.ok) {
					const data = await response.json();
					setTags(data);
					console.log(tags, "tags");
				} else {
					console.error("Error fetching tags data");
				}
			} catch (error) {
				console.error("Error fetching tags data:", error);
			}
		};

		fetchTagsData();
	}, [currentWorkspace._id, userProfileStoreInstance.idToken]);

	const renderActions = (item: any) => (
		<div style={{ display: "flex", alignItems: "center" }}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginRight: "1vw",
				}}
			>
				<EyeOutlined
					onClick={() => handleEyeIconClick(item, Data)}
					style={{
						fontSize: "1rem",
						marginLeft: "1vw",
					}}
				/>
			</div>
			<div style={{ display: "flex", alignItems: "center" }}>
				<WechatWorkOutlined
					onClick={() => handleEditor(item)}
					style={{
						fontSize: "1rem",
						marginRight: "1vw",
					}}
				/>
				<DeploymentUnitOutlined
					onClick={() => handleMapIconClick(item, Data)}
					style={{
						fontSize: "1rem",
						marginRight: "1vw",
					}}
				/>
			</div>
			<div style={{ display: "flex", alignItems: "center" }}>
				<MessageOutlined
					onClick={() => handleChatIconClick(item, Data)}
					style={{
						fontSize: ".9rem",
						marginRight: "1vw",
					}}
				/>
			</div>
		</div>
	);

	const getRandomPastelColor = () => {
		const hue = 219;
		const pastelColor = `hsl(${hue}, 80%, 80%)`;
		return pastelColor;
	};

	const [menuVisible, setMenuVisible] = React.useState(false);
	const [position, setPosition] = React.useState({
		x: 0,
		y: 0,
	});

	const renderAssigneeAvatar = (assigneeData: AssigneeData) => {
		const assigneeNameWords = assigneeData?.name?.split(" ") || [];
		const firstName = assigneeNameWords[0] || "";
		const lastName =
			assigneeNameWords.length > 1
				? assigneeNameWords[assigneeNameWords.length - 1]
				: "";

		const avatarText = `${firstName.charAt(0)}${lastName.charAt(
			0
		)}`.toUpperCase();

		const pastelColor = assigneeData?.preferences?.profileColor
			? assigneeData.preferences.profileColor
			: getRandomPastelColor();

		return (
			<Tooltip title={`${assigneeData?.name}`} placement="top">
				<Avatar
					style={{
						backgroundColor: pastelColor,
					}}
					size="small"
				>
					{avatarText}
				</Avatar>
			</Tooltip>
		);
	};

	const handleListItemClick = (item: ItemData) => {
		setSelectedItemDetails(item);
	};

	const getTypeIcon = (type: string) => {
		switch (type) {
			case "FEATURE":
				return <HighlightOutlined />;
			case "TASK":
				return <CheckCircleOutlined />;
			case "BUG":
				return <BugOutlined />;
			case "USER_STORY":
				return <FileTextOutlined />;
			case "USER STORY":
				return <FileTextOutlined />;
			default:
				return null;
		}
	};

	const getPriorityIcon = (priority: string) => {
		switch (priority) {
			case "URGENT":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "Urgent":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "IMPORTANT":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Important":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Medium":
				return <DashOutlined style={{ color: "golden" }} />;
			case "LOW":
				return <FallOutlined style={{ color: "green" }} />;
			case "Low":
				return <FallOutlined style={{ color: "green" }} />;
			default:
				return <HarmonyOSOutlined />;
		}
	};

	const getTagcolor = (priority?: string) => {
		if (!priority) return "blue";
		switch (priority.toLowerCase()) {
			case "urgent":
				return "orange";
			case "important":
				return "red";
			case "medium":
				return "gold";
			case "low":
				return "green";
			default:
				return "blue";
		}
	};

	const getStatusStyle = (status?: string) => {
		if (!status) return { color: "gray", icon: <DashOutlined /> };
		const formattedStatus = status.replace(/_/g, " ").toLowerCase();

		switch (formattedStatus) {
			case "completed":
				return { color: "green", icon: <CheckCircleFilled /> };
			case "in progress":
				return { color: "blue", icon: <Loading3QuartersOutlined /> };
			case "pending":
				return { color: "orange", icon: <ExclamationOutlined /> };
			case "open":
				return { color: "red", icon: <HarmonyOSOutlined /> };
			default:
				return { color: "gray", icon: <DashOutlined /> };
		}
	};

	const formatStatusText = (status?: string) => {
		if (!status) return "";
		return status.replace(/_/g, " ").toLowerCase();
	};

	const renderListItem = (item: ItemData) => (
		<List.Item
			key={item._id}
			onClick={() => handleListItemClick(item)}
			onContextMenu={(event) => {
				event.preventDefault();
				setSelectRec(item);
				console.log("selectRec", selectRec);
				if (!menuVisible) {
					document.addEventListener(`click`, function onClickOutside() {
						setMenuVisible(false);
						document.removeEventListener(`click`, onClickOutside);
					});
				}
				setMenuVisible(true);
				setPosition({ x: event.clientX, y: event.clientY });
			}}
			style={{ padding: "0px 0" }}
		>
			<div className="list-item">
				<div className="list-item-description-left ">
					<div className="list-item-action">{renderActions(item)}</div>
					<span className="work-item-id">{item.WorkitemId}</span>
					<span className="work-item-title">{item.title}</span>
				</div>
				<div className="list-item-description-right ">
					<span
						className="status-tag"
						style={{ color: getStatusStyle(item.status).color }}
					>
						{formatStatusText(item.status)}
					</span>
					<Tooltip title={item.type}>
						<Tag color="blue" className="tag-icon">
							{getTypeIcon(item.type)}
						</Tag>
					</Tooltip>
					<Tooltip title={item.priority ? item.priority : "Null"}>
						<Tag color={getTagcolor(item.priority)} className="priority-tag">
							{getPriorityIcon(item.priority)}
						</Tag>
					</Tooltip>
					<div className="assignee-avatar">
						{renderAssigneeAvatar(item.assignee)}
					</div>
				</div>
			</div>
		</List.Item>
	);

	const data = newData.filter(
		(item) => item.type !== "task" && item.type !== "WORKFLOW_TASK"
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const currentPageData = data.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	//////////////////////////////////////Case: No grouping is applied///////////////////////////////////////////////////

	if (ListSelectedGroupBy === "None") {
		return (
			// <div className="list-view-container">
			<div
				className="list-view-container"
				style={{
					display: "flex",
					flexDirection: "column",
					height: "76vh",
					padding: "0vw",
					marginRight: ".4vw",
				}}
			>
				<BacklogDropdownMenu
					open={menuVisible}
					pos={position}
					selectedRec={selectRec}
				/>
				<div style={{ flex: "1 1 auto" }}>
					<List
						itemLayout="vertical"
						dataSource={currentPageData}
						renderItem={renderListItem}
						className="list-view"
						size="small"
					/>
				</div>
				<div
					style={{
						flex: "0 0 20px",
						textAlign: "end",
						marginTop: "3px",
						marginBottom: "10px",
					}}
				>
					<Pagination
						current={currentPage}
						total={data.length}
						pageSize={pageSize}
						onChange={handlePageChange}
						showSizeChanger={false}
					/>
				</div>
			</div>
		);
	}

	////////////////////////////////////Case: grouping is applied////////////////////////////////////////////////////////

	return (
		<Collapse defaultActiveKey={["1"]} expandIconPosition="end">
			{Object.entries(groupedTasks)
				.filter(([groupKey]) => groupKey !== "null" && groupKey !== "undefined")
				.map(([groupKey, tasks]) => (
					<Collapse.Panel header={groupKey} key={groupKey}>
						<BacklogDropdownMenu
							open={menuVisible}
							pos={position}
							selectedRec={selectRec}
						/>
						{/* <List
							itemLayout="vertical"
							dataSource={tasks}
							renderItem={renderListItem}
							style={{
								maxHeight: "70vh",
								overflowY: "scroll",
								height: 35,
								lineHeight: "35px",
							}}
							size="small"
							pagination={{
								pageSize: 15,
							}}
						/> */}
						<div style={{ flex: "1 1 auto" }}>
							<List
								itemLayout="vertical"
								dataSource={tasks}
								renderItem={renderListItem}
								className="list-view"
								size="small"
							/>
						</div>
						<div
							style={{
								flex: "0 0 20px",
								textAlign: "end",
								marginTop: "3px",
								marginBottom: "10px",
							}}
						>
							<Pagination
								current={currentPage}
								total={tasks.length}
								pageSize={pageSize}
								onChange={handlePageChange}
								showSizeChanger={false}
							/>
						</div>
					</Collapse.Panel>
				))}
		</Collapse>
	);
};

// Helper function to group tasks
const groupBy = (array: any[], key: string): Record<string, any[]> => {
	return array.reduce(
		(result, currentValue) => {
			const groupKey = currentValue[key];
			if (!result[groupKey]) {
				result[groupKey] = [];
			}
			result[groupKey].push(currentValue);
			return result;
		},
		{} as Record<string, any[]>
	);
};

export default BacklogListViewItemV2;
