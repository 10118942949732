/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Collapse, Empty, Timeline, Table, Tag, Flex, Button } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import "./MapViewTab.css";
import { planerViewStore } from "../../stores/PlannerStore";
import { useViewsStores } from "../../stores/BacklogStore";
import {
	BarsOutlined,
	BugOutlined,
	DatabaseOutlined,
	EyeOutlined,
	FileTextOutlined,
	GoldOutlined,
} from "@ant-design/icons";
import MapTaskViewModel from "./MapTaskViewModel";

function MapViewTab() {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [originalData, setOriginalData] = useState<any[]>([]);
	const [updates, setUpdates] = useState<any[]>([]);
	const [selectedItem, setSelectedItem] = useState<any>([]);
	const [activeKey, setActiveKey] = useState<string[]>([]);
	const { selectedItemDetails, isEditModalOpen } = planerViewStore();
	const { DrawerTab } = useViewsStores();
	const [prevdata, setprevdata] = useState<any>([]);
	const [open, setOpen] = useState(false);
	const [taskdata, settaskdata] = useState<any>([]);

	const fetchUpdates = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/child/${selectedItemDetails._id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData = await response.json();
				console.log("childdata", todosData);
				const getUniqueUpdates = (data: any) => {
					const uniqueUpdates: any = [];
					const seenTypes = new Set();

					data.forEach((item: any) => {
						if (!seenTypes.has(item.type)) {
							seenTypes.add(item.type);
							uniqueUpdates.push(item);
						}
					});

					return uniqueUpdates;
				};

				const uniqueData = getUniqueUpdates(todosData);
				console.log("uniqueData", uniqueData);
				setOriginalData(todosData);
				setUpdates(uniqueData);

				setActiveKey(uniqueData.map((item: any) => item.type));
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		if (selectedItemDetails._id && DrawerTab === "mapview") {
			fetchUpdates();
			//setActiveKey([]);
		}
	}, [selectedItemDetails._id, isEditModalOpen, DrawerTab]);

	const handleCollapseChange = (key: string | string[]) => {
		// Set active key to the clicked collapse panel
		console.log("key", key);
		if (Array.isArray(key)) {
			setActiveKey(key);
		} else {
			if (activeKey.includes(key)) {
				setActiveKey(activeKey.filter((k: string) => k !== key));
			} else {
				setActiveKey([...activeKey, key]);
			}
		}
		if (Array.isArray(key) && key.length > 0) {
			const prevdata = selectedItem;
			setprevdata(prevdata);
			// Find the selected item from updates
			const selectedItems = originalData.filter((item) => item.type === key[0]);
			// console.log("selectedItemafterfilter", selectedItem);
			setSelectedItem(selectedItems);

			//setActiveKey(key);
		} else {
			//setActiveKey(key);
			setSelectedItem([]);
		}
	};

	console.log("selectedItemafterfilter", selectedItem);

	const handleButtonClick = (workitemId: any, data: any) => {
		setOpen(true);
		settaskdata(data);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	const columns = [
		{
			title: "Action",
			key: "action",
			render: (record: any) => (
				<EyeOutlined
					onClick={() => handleButtonClick(record.WorkitemId, record)}
					style={{ color: "#08c" }}
				/>
			),
		},

		{
			title: "Id",
			dataIndex: "WorkitemId",
			key: "dataField1",
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "dataField2",
		},
		{
			title: "priority",
			dataIndex: "priority",
			key: "priority",
			render: (priority: any) => (
				<Tag color="blue" style={{ color: "black" }}>
					{priority}
				</Tag>
			),
		},
		{
			title: "status",
			dataIndex: "status",
			key: "status",
			render: (type: any) => <div>{type ? type.toUpperCase() : ""}</div>,
		},
		// Add more columns as needed
	];

	const getCount = (type: string) => {
		const length = originalData.filter((item) => item.type === type).length;
		return (
			<Flex>
				<div>{type} &nbsp;</div>
				<Tag
					color="blue"
					style={{
						color: "black",
						display: "inline-block",
						height: "1.2rem",
						alignContent: "center",
					}}
				>
					{length}
				</Tag>
			</Flex>
		);
	};

	const getIcons = (type: string) => {
		if (type === "FEATURE") {
			return <GoldOutlined style={{ fontSize: "20px" }} />;
		} else if (type === "EPIC") {
			return <DatabaseOutlined style={{ fontSize: "20px" }} />;
		} else if (type === "USER STORY") {
			return <FileTextOutlined style={{ fontSize: "20px" }} />;
		} else if (type === "TASK") {
			return <BarsOutlined style={{ fontSize: "20px" }} />;
		} else if (type === "BUG") {
			return <BugOutlined style={{ fontSize: "20px" }} />;
		}
	};

	return (
		<div
			className="custom-collapse"
			style={{
				textAlign: "left",
				marginTop: "1rem",
				alignContent: "center",
				alignItems: "center",
			}}
		>
			{updates.length > 0 ? (
				<Timeline mode="left">
					{updates.map((item) => (
						<Timeline.Item
							color={"blue"}
							dot={getIcons(item.type)}
							key={item._id}
							label={item.date}
						>
							<Collapse
								//accordion
								onChange={handleCollapseChange}
								activeKey={activeKey}
								ghost
							>
								<Collapse.Panel
									style={{ padding: "0" }}
									key={item.type}
									header={getCount(item.type)}
								>
									<Table
										columns={columns}
										dataSource={originalData.filter(
											(dataItem) => dataItem.type === item.type
										)}
										pagination={false}
									/>
								</Collapse.Panel>
							</Collapse>
						</Timeline.Item>
					))}
				</Timeline>
			) : (
				<Empty description="No data" />
			)}

			<MapTaskViewModel
				visible={open}
				onCancel={handleCancel}
				data={taskdata}
			/>
		</div>
	);
}

export default MapViewTab;
