import React, { useEffect, useState } from "react";
import { Modal, Form, Input as AntInput, Input, AutoComplete } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import Constants from "../../../util/ConstantsUtil";

interface TaskCreationModelProps {
	visible: boolean;
	onCancel: () => void;
	id: string;
	Parenttype: string;
}

// CAPS_URGENT: "URGENT",
// 	URGENT: "Urgent",
// 	CAPS_IMPORTANT: "IMPORTANT",
// 	IMPORTANT: "Important",
// 	CAPS_MEDIUM: "MEDIUM",
// 	MEDIUM: "Medium",
// 	CAPS_LOW: "LOW",
// 	LOW: "Low",

const PriorityValues = [
	{
		label: Constants.CAPS_URGENT,
		key: Constants.CAPS_URGENT,
	},
	{
		label: Constants.CAPS_IMPORTANT,
		key: Constants.CAPS_IMPORTANT,
	},
	{
		label: Constants.CAPS_MEDIUM,
		key: Constants.CAPS_MEDIUM,
	},
	{
		label: Constants.CAPS_LOW,
		key: Constants.CAPS_LOW,
	},
];

const TaskCreationModel: React.FC<TaskCreationModelProps> = ({
	visible,
	onCancel,
	id,
	Parenttype,
}) => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [filterpriority, setfilterpriority] = useState(PriorityValues);
	const [form] = Form.useForm();
	const [isFormValid, setIsFormValid] = useState(false); //state to manage validation
	useEffect(() => {
		// Check form validity whenever the form values change
		const { title, Priority } = form.getFieldsValue(["title", "Priority"]);
		setIsFormValid(!!title && !!Priority);
	}, [form]);

	const onFinish = async () => {
		try {
			const formData = await form.validateFields();
			const workItemData = {
				type: "TASK",
				title: formData.title,
				description: formData.description,
				parentObject: {
					type: Parenttype,
					id: id,
				},
				status: "OPEN",
				priority: formData.Priority,
				workspaceId: currentWorkspace?._id,
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems`,
				{
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(workItemData),
				}
			);

			if (response.ok) {
				onCancel();
				form.resetFields();
			} else {
				console.error("Failed to add work item");
			}
		} catch (error) {
			console.error("Error adding work item:", error);
		}
	};

	const onSelectpriority = (value: string, option: any) => {
		form.setFieldsValue({ Priority: option.label }); // Set label as the value
	};

	const handleSearchpriority = (value: string) => {
		const inputLower = value?.replace(/\s/g, "").toLowerCase();
		const filteredData = PriorityValues.filter((item) => {
			return item.key?.replace(/\s/g, "").toLowerCase().includes(inputLower);
		});
		setfilterpriority(filteredData);
	};

	const formItemLayout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 14 },
	};
	// function to handle form field changes
	const HandleFormValuesChange = (changeValues: any, allValues: any) => {
		//check if title and priorty is present
		if (allValues.title && allValues.Priority) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	};
	return (
		<Modal
			title="Create Task"
			visible={visible} //corrected prop name
			onCancel={onCancel}
			onOk={onFinish}
			width={"50%"}
			okButtonProps={{ disabled: !isFormValid }} // disable based on form valited
		>
			<Form
				{...formItemLayout}
				form={form}
				onValuesChange={HandleFormValuesChange} // track  form filed change
			>
				<Form.Item
					name="title"
					label="Title"
					rules={[{ required: true, message: "Please enter title" }]}
				>
					<AntInput
						style={{ borderRadius: "0.25rem" }}
						placeholder="Enter work item title "
					/>
				</Form.Item>
				<Form.Item name="description" label="Description">
					<ReactQuill
						style={{
							borderRadius: "0.25rem",
							height: "30vh",
							marginBottom: "5rem",
						}}
					/>
				</Form.Item>

				<Form.Item
					label="Priority"
					name="Priority"
					rules={[{ required: true, message: "Please choose priority" }]}
				>
					<AutoComplete
						// popupMatchSelectWidth={252}
						style={{ width: 300, borderRadius: "0.25rem" }}
						options={filterpriority?.map((item) => {
							return {
								label: item.label,
								value: item.key,
							};
						})}
						onSelect={onSelectpriority}
						onSearch={handleSearchpriority}
						size="large"
					>
						<Input
							size="large"
							placeholder="input here"
							style={{ borderRadius: "0.25rem" }}
						/>
					</AutoComplete>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default TaskCreationModel;
