import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { fetchWorkitemByType } from "../api/WorkItemAPI";
import { useRequest } from "ahooks";
import { message } from "antd";

const PieChart = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [details, setdetails] = useState<any[]>([]);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state?.currentWorkspace,
	}));

	const { data: AllWorkitemData, run: fetchAllWorkitem } = useRequest(
		(worspaceId, type) =>
			fetchWorkitemByType(worspaceId, type, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Bug data");
				// message.success(`Data for pice chart: ${result.length}`);
			},
			onError: (error) => {
				message.error(`Failed to fetch user story : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (AllWorkitemData) {
			setdetails(AllWorkitemData);
		}
	}, [AllWorkitemData]);

	useEffect(() => {
		fetchAllWorkitem(currentWorkspace?._id, "AllItems");
	}, [currentWorkspace?._id, userProfileStoreInstance?.idToken]);

	// Transform data to count the number of work items for each status
	const data: { [key: string]: number } = {};
	details.forEach((item) => {
		if (data[item.status]) {
			data[item.status]++;
		} else {
			data[item.status] = 1;
		}
	});

	const pieChartData = [];
	for (const status in data) {
		pieChartData.push({
			status,
			count: data[status],
		});
	}

	console.log(pieChartData, "pieChartData");

	const config = {
		data: pieChartData,
		angleField: "count",
		colorField: "status",
		paddingTop: 0,
		marginLeft: 70,
		paddingBottom: 30,
		paddingRight: 60,
		label: {
			text: (d: { status: any; count: any }) => `${d.status}\n ${d.count}`,
			position: "spider",
			style: {
				fontWeight: "bold",
				fontSize: 10,
			},
		},
		tooltip: (d: { status: any; count: any }) => ({
			value: `${d.status} Count: - ${d.count}`,
			style: {
				textAlign: "left",
			},
		}),
		legend: false,
		width: 290,
		height: 250,
	};

	return (
		<div className="">
			<Pie {...config} />
		</div>
	);
};

export default PieChart;
