import React from "react";
import { Button, Col, Divider, Flex, Row, Tag, Typography } from "antd";

import { planerViewStore } from "../../stores/PlannerStore";
import { SkinTwoTone, StarTwoTone, TeamOutlined } from "@ant-design/icons";

const { Title } = Typography;
const FeatureQuickView = () => {
	//const navigate = useNavigate();

	//const { setEditModalOpen, selectedItemDetails, setOpen } = useViewsStore();
	const { selectedItemDetails } = planerViewStore();

	// const handleDrawerCancel = () => {
	// 	setOpen(false);
	// 	setEditModalOpen(true);
	// 	navigate("/space/planner/edit");
	// };
	const formatText = (text: any) => {
		const words = text?.split("_");
		const formattedWords = words?.map(
			(word: any) => word.charAt(0).toUpperCase() + word.slice(1)
		);
		const formattedText = formattedWords?.join(" ");
		return formattedText;
	};
	return (
		<div>
			{/* <Flex style={{ alignItems: "center" }} gap={20}> */}
			{/* <Flex>
					<EditOutlined onClick={handleDrawerCancel} />
				</Flex>
				<Flex style={{}}>
					<h3>{selectedItemDetails?.title}</h3>
				</Flex> */}
			{/* <Flex style={{ height: "max-content" }}>
					<Tag>{selectedItemDetails?.story}</Tag>
				</Flex>
			</Flex> */}
			<div style={{ textAlign: "left" }}>
				<p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
					dangerouslySetInnerHTML={{ __html: selectedItemDetails?.description }}
				></p>

				{/* <p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
				>
					Type: {selectedItemDetails?.type}
				</p> */}

				<Row gutter={16}>
					<Col className="gutter-row" span={4}>
						<div>Status</div>
					</Col>
					<Col className="gutter-row" span={8}>
						<Tag color="red" style={{ textAlign: "left" }}>
							{selectedItemDetails?.status}
						</Tag>
					</Col>
					<Col className="gutter-row" span={4}>
						<div>Priority</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<Tag color="pink" style={{ textAlign: "left" }}>
							{selectedItemDetails?.priority}
						</Tag>
					</Col>
				</Row>

				{/* <p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
				>
					Stage: {selectedItemDetails?.stage}
				</p> */}
			</div>
			<Divider />
			{selectedItemDetails?.data && (
				<Flex gap={20} style={{ marginTop: 25 }} vertical>
					<Flex gap={20} style={{ textAlign: "left" }}>
						<div className="Complexity-icon">
							<Button>
								<SkinTwoTone />
							</Button>
						</div>
						<Flex
							//onContextMenu={handleRightClick}
							//className={contentClassName}
							//data-path={`fe/featurelist/${featureState}`}
							vertical
							justify="center"
							gap={5}
						>
							<Flex gap={10} style={{ alignItems: "center" }}>
								<Title
									//onContextMenu={handleRightClick}
									//className={contentClassName}
									//data-path={`fe/featurelist/${featureState}`}
									level={5}
									style={{
										margin: 0,
										fontWeight: 500,
										fontSize: 15,
									}}
								>
									Complexity
								</Title>
								<Tag
									// onContextMenu={handleRightClick}
									// className={contentClassName}
									// data-path={`fe/featurelist/${featureState}`}
									color="gold"
								>
									{selectedItemDetails?.data?.complexity_score}
								</Tag>
							</Flex>
							<Flex gap={10} style={{ alignItems: "center" }}>
								<p
									// onContextMenu={handleRightClick}
									// className={contentClassName}
									// data-path={`fe/featurelist/${featureState}`}
									style={{ margin: 0, fontWeight: 400 }}
								>
									{selectedItemDetails?.data?.complexity_score_reason}
								</p>
							</Flex>
						</Flex>
					</Flex>

					<Flex gap={20} style={{ textAlign: "left" }}>
						<div className="Complexity-icon">
							<Button>
								<TeamOutlined />
							</Button>
						</div>
						<Flex
							// onContextMenu={handleRightClick}
							// className={contentClassName}
							// data-path={`fe/featurelist/${featureState}`}
							vertical
							justify="center"
							gap={5}
						>
							<Title
								// onContextMenu={handleRightClick}
								// className={contentClassName}
								// data-path={`fe/featurelist/${featureState}`}
								level={5}
								style={{
									margin: 0,
									fontWeight: 500,
									fontSize: 15,
								}}
							>
								Roles
							</Title>
							<ul
								style={{
									margin: 0,
									padding: "0 0 0 20px",
									opacity: 0.5,
								}}
							>
								{selectedItemDetails?.data?.role_ids?.map(
									(role: string, roleIndex: number) => (
										<li
											// onContextMenu={handleRightClick}
											// className={contentClassName}
											// data-path={`fe/featurelist/${featureState}`}
											key={roleIndex}
										>
											{formatText(role)}
										</li>
									)
								)}
							</ul>
						</Flex>
					</Flex>

					<Flex gap={20} style={{ textAlign: "left" }}>
						<div className="Complexity-icon">
							<Button>
								<StarTwoTone />
							</Button>
						</div>
						<Flex
							// onContextMenu={handleRightClick}
							// className={contentClassName}
							// data-path={`fe/featurelist/${featureState}`}
							vertical
							justify="center"
							gap={5}
						>
							<Title
								// onContextMenu={handleRightClick}
								// className={contentClassName}
								// data-path={`fe/featurelist/${featureState}`}
								level={5}
								style={{
									margin: 0,
									fontWeight: 500,
									fontSize: 15,
								}}
							>
								Benefits
							</Title>
							<ul
								style={{
									margin: 0,
									padding: "0 0 0 20px",
									opacity: 0.5,
								}}
							>
								{selectedItemDetails?.data?.benefits?.map(
									(benefit: string, benfitIndex: number) => (
										<li
											// onContextMenu={handleRightClick}
											// className={contentClassName}
											// data-path={`fe/featurelist/${featureState}`}
											key={benfitIndex}
										>
											{benefit}
										</li>
									)
								)}
							</ul>
						</Flex>
					</Flex>
				</Flex>
			)}
		</div>
	);
};

export default FeatureQuickView;
