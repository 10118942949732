import { useGraphStore } from "@antv/xflow";
import { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { CUSTOMMAINNODE } from "./CoreSubDomain/CUSTOMEMAINNODE";
// import { CUSTOMECHILDNODE } from "./CoreSubDomain/CUSTOMECHILDNODE";
// import useWorkspaceStore from "../stores/WorkspaceState";

function formatString(inputString: any) {
	const stringWithoutUnderscores = inputString.replace(/_/g, " ");
	return (
		stringWithoutUnderscores.charAt(0).toUpperCase() +
		stringWithoutUnderscores.slice(1)
	);
}

const InitNode = ({ id }: { id: any }) => {
	const addNodes = useGraphStore((state) => state.addNodes);
	const addEdges = useGraphStore((state) => state.addEdges);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [apiData, setApiData] = useState<any>(null);

	// const { setDddSpecOnclick } = useWorkspaceStore((state: any) => ({
	// 	setDddSpecOnclick: state.setDddSpecOnclick,
	// }));

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiUrl = process.env.REACT_APP_API_URL;

				if (!apiUrl) {
					console.error(
						"REACT_APP_API_URL is not defined in your environment."
					);
					return;
				}

				const response = await fetch(
					`${apiUrl}/project-artifact/de-ddd-spec/${id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);

				if (!response.ok) {
					throw new Error(
						`status: ${response.status} message: ${response.statusText}`
					);
				}

				const data = await response.json();
				setApiData(data);
			} catch (error: any) {
				message.error(`Error: ${error.message}`);
			}
		};

		fetchData();
	}, [userProfileStoreInstance]);

	const addNodeInit = useCallback(() => {
		if (!apiData) return;

		const designSpecData =
			(apiData && apiData[0]?.data?.strategic_design) || [];
		const coreSubDomains =
			(designSpecData &&
				designSpecData.context_mapping &&
				designSpecData.context_mapping) ||
			null;

		if (designSpecData) {
			const uniquePages = new Set();
			const nodesToAdd: any[] = [];

			coreSubDomains.forEach((element: any, index: number) => {
				const entitiy = element.between;
				entitiy.forEach((page: any) => {
					console.log("Processing page:", page); // Log the page being processed

					if (!uniquePages.has(page)) {
						uniquePages.add(page);
						nodesToAdd.push({
							id: `initNode_${page}`,
							shape: CUSTOMMAINNODE,
							x: 305,
							y: 50 * (index + 1),
							data: formatString(page),
						});
					}
				});

				if (element.direction === "Upstream to Downstream") {
					console.log("Nodes to add:", nodesToAdd); // Log the nodes to be added

					addEdges([
						{
							source: `initNode_${element.between[0]}`,
							target: `initNode_${element.between[1]}`,
							sourcePort: "group3",
							targetPort: "group1",
							attrs: {
								line: {
									stroke: "black",
									strokeWidth: 2,
								},
							},
							labels: [
								{
									position: 0.5, // Position of the label along the edge (0 to 1)
									attrs: {
										text: {
											text: formatString(element.type), // Text of the label
											fill: "black", // Color of the label text
											fontSize: 12, // Font size of the label text
										},
									},
								},
							],
						},
					]);
				}

				if (element.direction === "Downstream to Upstream") {
					console.log("Nodes to add:", nodesToAdd); // Log the nodes to be added

					addEdges([
						{
							source: `initNode_${element.between[1]}`,
							target: `initNode_${element.between[0]}`,
							sourcePort: "group3",
							targetPort: "group1",
							attrs: {
								line: {
									stroke: "black",
									strokeWidth: 2,
								},
							},
							labels: [
								{
									position: 0.5, // Position of the label along the edge (0 to 1)
									attrs: {
										text: {
											text: formatString(element.type), // Text of the label
											fill: "black", // Color of the label text
											fontSize: 12, // Font size of the label text
										},
									},
								},
							],
						},
					]);
				}
			});

			console.log("Nodes to add:", nodesToAdd); // Log the nodes to be added
			addNodes(nodesToAdd);
		}
	}, [apiData, addNodes]);

	useEffect(() => {
		addNodeInit();
	}, [addNodeInit]);

	return null;
};

export { InitNode, CUSTOMMAINNODE };
