import {
	Button,
	Card,
	Drawer,
	Flex,
	Input,
	Layout,
	Menu,
	Popover,
	Space,
	Tabs,
	Tag,
	Typography,
	message,
	theme,
	Form,
	FloatButton,
} from "antd";
import "./FeatureSpec.css";
import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { useParams } from "react-router-dom";
import usePlatformStore from "../stores/platformStore";
import { getNavigateUrlForWorkspaces } from "../util/NavigateAssistUtil";
import Constants from "../util/ConstantsUtil";
import useWorkspaceStore from "../stores/WorkspaceState";

function FeatureSpecification() {
	const {
		token: { colorPrimaryBg },
	} = theme.useToken();

	const { Sider } = Layout;
	const [form] = Form.useForm();
	const [editformuserstories] = Form.useForm();
	const [editformexposition] = Form.useForm();

	const { TabPane } = Tabs;
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [data, setData] = useState<any>([]);
	const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);
	const [currentstory, setcurrentstory] = useState<any>();

	const [selectedUserStory, setSelectedUserStory] = useState<string | null>(
		null
	);
	const { id } = useParams<{ id: string }>();
	const [openExposition, setOpenExposition] = useState(false);
	const [openUserStory, setOpenUserStory] = useState(false);
	const [addUserStory, setaddUserStory] = useState(false);

	const showDrawerforExposition = () => {
		setOpenExposition(true);
	};

	const onCloseExposition = () => {
		setOpenExposition(false);
	};

	const showDrawerforUserStory = () => {
		setOpenUserStory(true);
	};

	const onCloseUserStory = () => {
		setOpenUserStory(false);
	};

	const showDrawerforCreatingUserStory = () => {
		setaddUserStory(true);
	};

	const onCloseForCreatingUserStory = () => {
		setaddUserStory(false);
	};

	const [clickedKeyValue, setClickedKeyValue] = useState<string | null>(null);
	const [newCommentContent, setNewCommentContent] = useState<string>("");
	const [isPopoverVisible, setPopoverVisibility] = useState(false);
	//const [openCommentBox, setOpenCommentBox] = useState(false);
	const [contextMenuPosition, setContextMenuPosition] = useState<{
		x: number;
		y: number;
	} | null>(null);
	const [selectedPath, setSelectedPath] = useState<string | null>(null);
	//const [commentPosted, setCommentPosted] = useState(false);
	//const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);

	const { currentWorkspace, isReviewEnabled, setCurrentActivity } =
		useWorkspaceStore((state: any) => ({
			currentWorkspace: state.currentWorkspace,
			isReviewEnabled: state.isReviewEnabled,
			setCurrentActivity: state.setCurrentActivity,
		}));
	const [contentClassName, setContentClassName] = useState(
		"FeatureSpecificationLayoutContent"
	);

	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
		setUnauthorizedModel,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	useEffect(() => {
		const navigationUrls = getNavigateUrlForWorkspaces(
			`${id}`,
			currentWorkspace?.modules || [],
			Constants.FEATURE_ENGINEERING,
			Constants.FE_FEATURE_SPECIFICATION
		);
		setNextNavigateUrl(navigationUrls.nextUrl);
		setBackNavigateUrl(navigationUrls.prevUrl);
		setAppBarTitle(Constants.FE_FEATURE_SPECIFICATION);
		setCurrentModule(Constants.FEATURE_ENGINEERING);
		setCurrentPage(Constants.FE_FEATURE_SPECIFICATION);
	}, [currentWorkspace]);

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${id}/feature-specification`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const result = await response.json();
			if (!response.ok) {
				const result = await response.json();
				if (response.status === 401) {
					setUnauthorizedModel(true);
				}
				throw new Error(`status: ${response.status} message: ${result}`);
			}

			setData(result);
			setCurrentActivity(result[0]);
			if (
				result.length > 0 &&
				result[0].appData.features_specifications.length > 0
			) {
				if (selectedMenuItem === null) {
					const firstMenuItemId =
						result[0].appData.features_specifications[0]
							.features_specifications_id;

					// Set the default selected menu item to the first feature specification ID
					setSelectedMenuItem(firstMenuItemId);
					const firstUserStoryId =
						result[0]?.appData?.features_specifications[0]?.user_stories[0]
							?.user_stories_id || null;
					setSelectedUserStory(firstUserStoryId);

					const firstUserStory =
						result[0]?.appData.features_specifications[0]?.user_stories[0] ||
						null;
					const firstKeyValue =
						firstUserStory && Object.keys(firstUserStory)[0];
					setClickedKeyValue(firstKeyValue);
				}
			}

			// console.log(result, "result");
		} catch (error: any) {
			message.error(`Error: ${error.message}`);
		}
	};

	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			fetchData();
		}
	}, [userProfileStoreInstance.idToken]);

	const handleMenuItemClick = (itemId: any) => {
		setSelectedMenuItem(itemId);
		const selectedFeature = data?.find((feature: any) =>
			feature.appData.features_specifications.some(
				(data: any) => data.features_specifications_id === itemId
			)
		);

		const selectedAiData = selectedFeature
			? selectedFeature.appData.features_specifications.find(
					(data: any) => data.features_specifications_id === itemId
				)
			: null;

		const firstUserStoryId =
			selectedAiData && selectedAiData.user_stories.length > 0
				? selectedAiData.user_stories[0].user_stories_id
				: null;

		const firstKeyValue =
			selectedAiData && selectedAiData.user_stories.length > 0
				? Object.keys(selectedAiData.user_stories[0])[0]
				: null;

		setSelectedUserStory(firstUserStoryId);
		setClickedKeyValue(firstKeyValue);
	};

	const selectedFeature = data?.find((feature: any) =>
		feature.appData.features_specifications.some(
			(data: any) => data.features_specifications_id === selectedMenuItem
		)
	);

	const selectedAiData = selectedFeature
		? selectedFeature.appData.features_specifications.find(
				(data: any) => data.features_specifications_id === selectedMenuItem
			)
		: null;

	const selectUserStorys = selectedAiData
		? selectedAiData.user_stories.find(
				(data: any) => data.user_stories_id === selectedUserStory
			)
		: null;

	const handleKeyClick = (key: any) => {
		setClickedKeyValue(key);
	};

	const Id = data.map((feature: any) => {
		const ids = feature.appData.features_specifications.map(
			(data: any) => data.features_specifications_id
		);
		return ids;
	});

	const handleUserStoryClick = (userStory: any) => {
		setSelectedUserStory(userStory);
	};

	const formatText = (text: any) => {
		const words = text?.split("_");
		const formattedWords = words?.map(
			(word: any) => word.charAt(0).toUpperCase() + word.slice(1)
		);
		const formattedText = formattedWords?.join(" ");
		return formattedText;
	};

	// const setClassNameBasedOnCommentButton = () => {
	// 	return isReviewEnabled
	// 		? "FeatureSpecificationLayoutContent"
	// 		: "FeatureSpecificationLayoutContentHover";
	// };

	useEffect(() => {
		setContentClassName(
			isReviewEnabled
				? "FeatureSpecificationLayoutContentHover"
				: "FeatureSpecificationLayoutContent"
		);
	}, [isReviewEnabled]);

	const handleRightClick = (e: React.MouseEvent) => {
		e.preventDefault();

		if (isReviewEnabled) {
			setPopoverVisibility(true);
			// setContentClassName(isCommentButtonClicked ? "FeatureSpecificationLayoutContentHover" : "FeatureSpecificationLayoutContent");
			const clickedElement = e.target as HTMLElement;
			const clickedPath = clickedElement.dataset.path || "";

			setContextMenuPosition({ x: e.clientX, y: e.clientY });

			setSelectedPath(clickedPath);
		} else {
			setContentClassName("FeatureSpecificationLayoutContent");
		}
	};

	const postComment = () => {
		const newComment = {
			comment: newCommentContent,
			path: selectedPath,
		};

		const newData = [...data];
		const userReview = newData[0]?.userReview || [];
		userReview.push(newComment);
		newData[0].userReview = userReview;
		//setCommentPosted(true);

		setPopoverVisibility(false);

		setNewCommentContent("");
		setContextMenuPosition(null);

		fetch(
			`${process.env.REACT_APP_API_URL}/describe/${id}/feature-specification`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(newData[0]),
			}
		)
			.then((response) => response.json())
			.then((res) => {
				console.log("dev", res);
			})
			.catch((error) => {
				console.log(error);
			});

		//setCommentPosted(true);
	};

	const edituser_stories = async (featuredetail: any) => {
		const selectedUserStory = data.flatMap((feature: any) =>
			feature.appData.features_specifications.flatMap((spec: any) => {
				if (spec.features_specifications_id === selectedMenuItem) {
					const matchingStory = spec.user_stories.find(
						(story: any) => story.user_stories_id === featuredetail
					);
					return matchingStory ? [matchingStory] : [];
				}
				return [];
			})
		);

		// console.log("hsadfhkjlsdf", selectedUserStory);

		if (selectedUserStory.length > 0) {
			// Assuming you want to work with the first found story
			const userStory = selectedUserStory[0];
			// console.log("userstory", selectedUserStory);
			setcurrentstory(userStory.id);
			editformuserstories.setFieldsValue({
				user_stories_id: userStory.user_stories_id,
				description: userStory.description,
				acceptance_criteria: userStory.acceptance_criteria,
				assumptions: userStory.assumptions,
				business_rules: userStory.business_rules,
				dependencies: userStory.dependencies,
				edge_cases: userStory.edge_cases,
				story_points: userStory.story_points,
				user_role_id: userStory.user_role_id,
				id: "",
				use_cases: [
					{
						alternate_flow: userStory?.use_cases[0]?.alternate_flow,
						basic_flow: userStory?.use_cases[0]?.basic_flow,
						business_logic: userStory?.use_cases[0]?.business_logic,
						post_conditions: userStory?.use_cases[0]?.post_conditions,
						pre_conditions: userStory?.use_cases[0]?.pre_conditions,
						system_behavior: userStory?.use_cases[0]?.system_behavior,
						id: "",
					},
				],
			});
		} else {
			console.error("User story not found!");
		}
	};

	const editexposition = async (id: any) => {
		const selectedUserStory = data.flatMap((feature: any) =>
			feature.appData.features_specifications.flatMap((spec: any) => {
				if (spec.id === id) {
					const matchingStory = spec.exposition;
					return matchingStory ? [matchingStory] : [];
				}
				return [];
			})
		);

		if (selectedUserStory.length > 0) {
			// Assuming you want to work with the first found story
			const userStory = selectedUserStory;

			editformexposition.setFieldsValue({
				exposition: userStory,
			});
		} else {
			console.error("User story not found!");
		}
	};

	interface FeatureChanges {
		[key: string]: {
			oldValue: any;
			newValue: any;
		};
	}

	const compareObjects = async (
		obj1: any,
		obj2: any,
		type: any,
		checkfeaturespecid: any
	): Promise<FeatureChanges[]> => {
		const changes: FeatureChanges[] = [];
		console.log("obj1", obj1);
		console.log("obj2", obj2);

		// Helper function to convert comma-separated string to array
		const stringToArray = (value: any): any => {
			return Array.isArray(value) ? value : value ? value.split(".,") : [];
		};

		if (Array.isArray(obj1.use_cases) && Array.isArray(obj2.use_cases)) {
			obj1.use_cases.forEach((useCase1: any, index: number) => {
				const useCase2 = obj2.use_cases[index];

				for (const key in useCase1) {
					if (Object.prototype.hasOwnProperty.call(useCase2, key)) {
						const oldValue = useCase1[key];
						const newValue = useCase2[key];

						// Convert values to arrays if necessary
						const isArrayField = [
							"alternate_flow",
							"basic_flow",
							"business_logic",
							"post_conditions",
							"pre_conditions",
							"system_behavior",
						].includes(key);
						const oldValueArray = isArrayField
							? stringToArray(oldValue)
							: oldValue;
						const newValueArray = isArrayField
							? stringToArray(newValue)
							: newValue;

						// Check if the values are different
						if (
							JSON.stringify(oldValueArray) !== JSON.stringify(newValueArray)
						) {
							if (
								!changes.some(
									(change) =>
										JSON.stringify(change[key]) ===
										JSON.stringify({
											oldValue: oldValueArray,
											newValue: newValueArray,
										})
								)
							) {
								changes.push({
									[`use_cases/${key}`]: {
										oldValue: oldValueArray,
										newValue: newValueArray,
									},
								});
							}
						}
					}
				}
			});
		}

		// Iterate over the keys of obj1
		for (const key in obj1) {
			// Skip the 'use_cases' field
			if (key === "use_cases") {
				continue;
			}

			// Check if the key exists in obj2 and values are different
			if (Object.prototype.hasOwnProperty.call(obj2, key)) {
				let oldValue = obj1[key];
				let newValue = obj2[key];

				// Convert values to arrays if the key is 'exposition' or 'benefits'
				if (
					key === "exposition" ||
					key === "acceptance_criteria" ||
					key === "description" ||
					key === "assumptions" ||
					key === "business_rules" ||
					key === "dependencies" ||
					key === "edge_cases" ||
					key === "user_role_id"
				) {
					oldValue = stringToArray(oldValue);
					newValue = stringToArray(newValue);
				}

				// Check if the values are different
				if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
					changes.push({
						[key]: {
							oldValue,
							newValue,
						},
					});
				}
			}
		}

		const Reqbody: any = {
			workspace: `${id}`,
			module: "FeatureEngineering",
			versionsequence: 1,
			section: "FeatureSpecification",
			reviseReview: "",
			path: "",
			newValue: [],
			mode: "manual",
			oldValue: [],
			createdBy: "",
			appBranch: "00bb00da00d000000000d000",
		};

		if (
			changes.length === 1 &&
			type === "exposition" &&
			checkfeaturespecid === "nostory"
		) {
			Reqbody.path = `featurespecification/${obj1.id}/${
				Object.keys(changes[0])[0]
			}`;
			const firstKey = Object.keys(changes[0])[0];
			Reqbody.oldValue.push({ [firstKey]: changes[0][firstKey].oldValue });
			Reqbody.newValue.push({ [firstKey]: changes[0][firstKey].newValue });
		} else if (changes.length === 1 && type === "story") {
			Reqbody.path = `featurespecification/${checkfeaturespecid}/user_story/${
				obj1.id
			}/${Object.keys(changes[0])[0]}`;
			const firstKey = Object.keys(changes[0])[0];
			Reqbody.oldValue.push({ [firstKey]: changes[0][firstKey].oldValue });
			Reqbody.newValue.push({ [firstKey]: changes[0][firstKey].newValue });
		} else if (changes.length >= 2) {
			Reqbody.path = `featurespecification/${checkfeaturespecid}/user_story/${obj1.id}`;
			for (const change of changes) {
				const firstKey = Object.keys(change)[0];
				Reqbody.oldValue.push({ [firstKey]: change[firstKey].oldValue });
				Reqbody.newValue.push({ [firstKey]: change[firstKey].newValue });
			}
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/al_steps_changes`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(Reqbody),
				}
			);

			if (response.ok) {
				// Feature updated successfully.
				// Fetch data again to refresh the UI
				fetchData();
				// onCloseEditDrawer();
			} else {
				console.error("Failed to update feature.");
			}
		} catch (error) {
			console.error("Error updating feature:", error);
		}

		return changes;
	};

	const onFinisheditexposition = async (values: any) => {
		console.log("submittedformvalues exposition", values);
		const updatedData = data.map((feature: any) => {
			// Check if appData exists and features_specifications is an array
			if (
				feature.appData &&
				Array.isArray(feature.appData.features_specifications)
			) {
				// Map over features_specifications
				feature.appData.features_specifications =
					feature.appData.features_specifications.map((spec: any) => {
						// Check if spec.featurespecifications_id matches selectedMenuItem
						if (spec.features_specifications_id === selectedMenuItem) {
							// Map over user_stories
							compareObjects(spec, values, "exposition", "nostory");
							spec.exposition = Array.isArray(values.exposition)
								? values.exposition
								: values.exposition.split(".,");

							return spec;
						}
						// Return the unchanged spec if featurespecifications_id doesn't match selectedMenuItem
						return spec;
					});
			}
			// Return the unchanged feature if appData or features_specifications doesn't exist
			return feature;
		});

		console.log("updatedData", updatedData);

		// Submit updated data to the API
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${id}/feature-specification`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(updatedData[0]),
				}
			);

			if (response.ok) {
				// Feature updated successfully.
				// Fetch data again to refresh the UI
				fetchData();
				// onCloseEditDrawer();
				onCloseExposition();
			} else {
				console.error("Failed to update feature.");
			}
		} catch (error) {
			console.error("Error updating feature:", error);
		}
	};

	const onFinishedituserstories = async (values: any) => {
		console.log("submittedformvalues userstories", values);
		console.log("currentstory", currentstory);

		// Map over the data
		const updatedData = data.map((feature: any) => {
			// Check if appData exists and features_specifications is an array
			if (
				feature.appData &&
				Array.isArray(feature.appData.features_specifications)
			) {
				// Map over features_specifications
				feature.appData.features_specifications =
					feature.appData.features_specifications.map((spec: any) => {
						// Check if spec.featurespecifications_id matches selectedMenuItem
						if (spec.features_specifications_id === selectedMenuItem) {
							// Map over user_stories
							spec.user_stories = spec.user_stories.map((story: any) => {
								// Check if story.id matches currentstory
								if (story.id === currentstory) {
									// Return the story with updated values
									if (!values.id || !values.use_cases[0].id) {
										console.log(
											"values.id is not set. Assigning story.id:",
											story.id
										);
										values.id = story.id;
										values.use_cases[0].id = story.use_cases[0].id;
									}

									compareObjects(story, values, "story", spec.id);
									return {
										...story,
										acceptance_criteria: Array.isArray(
											values.acceptance_criteria
										)
											? values.acceptance_criteria
											: values.acceptance_criteria.split(".,"),
										assumptions: values.assumptions,
										business_rules: values.business_rules,
										dependencies: values.dependencies,
										description: values.description,
										edge_cases: values.edge_cases,
										story_points: values.story_points,
										use_cases: [
											{
												alternate_flow: Array.isArray(
													values.use_cases[0].alternate_flow
												)
													? values.use_cases[0].alternate_flow
													: values.use_cases[0].alternate_flow.split(".,"),

												basic_flow: Array.isArray(
													values.use_cases[0].basic_flow
												)
													? values.use_cases[0].basic_flow
													: values.use_cases[0].basic_flow.split(".,"),

												business_logic: Array.isArray(
													values.use_cases[0].business_logic
												)
													? values.use_cases[0].business_logic
													: values.use_cases[0].business_logic.split(".,"),

												post_conditions: Array.isArray(
													values.use_cases[0].post_conditions
												)
													? values.use_cases[0].post_conditions
													: values.use_cases[0].post_conditions.split(".,"),
												pre_conditions: Array.isArray(
													values.use_cases[0].pre_conditions
												)
													? values.use_cases[0].pre_conditions
													: values.use_cases[0].pre_conditions.split(".,"),
												system_behavior: Array.isArray(
													values.use_cases[0].system_behavior
												)
													? values.use_cases[0].system_behavior
													: values.use_cases[0].system_behavior.split(".,"),
												id: story?.id,
											},
										],
										user_role_id: values.user_role_id,
										user_stories_id: values.user_stories_id,
										id: story?.id,
									};
								}
								// Return the unchanged story if it doesn't match currentstory
								return story;
							});
						}
						// Return the unchanged spec if featurespecifications_id doesn't match selectedMenuItem
						return spec;
					});
			}
			// Return the unchanged feature if appData or features_specifications doesn't exist
			return feature;
		});

		console.log("updatedData", updatedData);

		// Submit updated data to the API
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${id}/feature-specification`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(updatedData[0]),
				}
			);

			if (response.ok) {
				// Feature updated successfully.
				// Fetch data again to refresh the UI
				fetchData();
				onCloseUserStory();
				// onCloseEditDrawer();
			} else {
				console.error("Failed to update feature.");
			}
		} catch (error) {
			console.error("Error updating feature:", error);
		}
	};

	const onFinish = async (values: any) => {
		console.log("values", values);

		// Map over the data
		const updatedData = data.map((feature: any) => {
			// Check if appData exists and features_specifications is an array
			if (
				feature.appData &&
				Array.isArray(feature.appData.features_specifications)
			) {
				// Map over features_specifications
				feature.appData.features_specifications =
					feature.appData.features_specifications.map((spec: any) => {
						// Check if spec.featurespecifications_id matches selectedMenuItem
						if (spec.features_specifications_id === selectedMenuItem) {
							// Create a new story and add it to the user_stories array
							const newStory = {
								acceptance_criteria: Array.isArray(values.acceptance_criteria)
									? values.acceptance_criteria
									: values.acceptance_criteria.split(".,"),
								assumptions: values.assumptions,
								business_rules: values.business_rules,
								dependencies: values.dependencies,
								description: values.description,
								edge_cases: values.edge_cases,
								story_points: values.story_points,
								use_cases: [
									{
										alternate_flow: Array.isArray(
											values.use_cases.alternate_flow
										)
											? values.use_cases.alternate_flow
											: values.use_cases.alternate_flow.split(".,"),
										basic_flow: Array.isArray(values.use_cases.basic_flow)
											? values.use_cases.basic_flow
											: values.use_cases.basic_flow.split(".,"),
										business_logic: Array.isArray(
											values.use_cases.business_logic
										)
											? values.use_cases.business_logic
											: values.use_cases.business_logic.split(".,"),
										post_conditions: Array.isArray(
											values.use_cases.post_conditions
										)
											? values.use_cases.post_conditions
											: values.use_cases.post_conditions.split(".,"),
										pre_conditions: Array.isArray(
											values.use_cases.pre_conditions
										)
											? values.use_cases.pre_conditions
											: values.use_cases.pre_conditions.split(".,"),
										system_behavior: Array.isArray(
											values.use_cases.system_behavior
										)
											? values.use_cases.system_behavior
											: values.use_cases.system_behavior.split(".,"),
										id: Math.random().toString(36).substr(2, 9), // Generate a random ID for the new story
									},
								],
								user_role_id: values.user_role_id,
								user_stories_id: values.name,
							};

							// Push the new story into the user_stories array
							spec.user_stories.push(newStory);
						}
						// Return the unchanged spec if featurespecifications_id doesn't match selectedMenuItem
						return spec;
					});
			}
			// Return the unchanged feature if appData or features_specifications doesn't exist
			return feature;
		});

		console.log("updatedData", updatedData);

		// Submit updated data to the API
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${id}/feature-specification`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(updatedData[0]),
				}
			);

			if (response.ok) {
				// Feature updated successfully.
				// Fetch data again to refresh the UI
				fetchData();
				onCloseForCreatingUserStory();
				// onCloseEditDrawer();
			} else {
				console.error("Failed to update feature.");
			}
		} catch (error) {
			console.error("Error updating feature:", error);
		}
	};

	// ...

	// const handleCommentButtonClick = () => {
	// 	setContentClassName(setClassNameBasedOnCommentButton());
	// 	setCommentButtonClicked(!isCommentButtonClicked);
	// 	setContextMenuPosition(null);
	// };

	return (
		<Layout
			className="FeatureSpecification"
			style={{ backgroundColor: colorPrimaryBg }}
		>
			<Sider width={280} style={{ backgroundColor: colorPrimaryBg }}>
				<div data-testid="MenuItem" className="MenuItem-wrap">
					<Menu className="leftmenu" mode="inline" defaultSelectedKeys={["0"]}>
						{Id.length > 0 ? (
							Id[0].map((data: any, index: number) => (
								<Menu.Item
									onClick={() => {
										handleMenuItemClick(data);
									}}
									key={index.toString()}
									className="leftmenu-item"
								>
									{formatText(data)}
								</Menu.Item>
							))
						) : (
							<></>
						)}
					</Menu>
				</div>
			</Sider>

			<Layout style={{ backgroundColor: colorPrimaryBg }}>
				{/* <Button onClick={handleCommentButtonClick}>Comment</Button>  */}
				{selectedAiData ? (
					<Card
						className="FeatureSpecificationLayoutDiv content-dimensions"
						// style={{ height: "72vh", width: "70vw", overflow: "scroll" }}
					>
						<div className="FeatureSpecificationLayoutHeading">
							<div className="Darkcolor">Checkpoint creation</div>
							<Tag color="blue" className="LightColor">
								id: {selectedAiData.id}
							</Tag>
						</div>

						<div
							onContextMenu={handleRightClick}
							className={contentClassName}
							data-path={`/fe/${selectedMenuItem}`}
						>
							<div className="Darkcolor">
								Exposition{" "}
								<EditOutlined
									onClick={() => {
										showDrawerforExposition();
										editexposition(selectedAiData.id);
									}}
								/>
							</div>
							{selectedAiData.exposition.map((exposition: any, index: any) => (
								<div
									data-path={`/fe/${selectedMenuItem}`}
									key={index}
									className="contents"
								>
									<ul data-path={`/fe/${selectedMenuItem}`}>
										<li data-path={`/fe/${selectedMenuItem}`}>{exposition}</li>
									</ul>
								</div>
							))}
						</div>

						<div className="FeatureSpecificationLayoutStories">
							{selectedAiData.user_stories.map((story: any) => (
								<div
									key={story.id}
									className="StoryPointDiv"
									onClick={() => {
										handleUserStoryClick(story.user_stories_id);
									}}
								>
									{formatText(story.user_stories_id)}
									<EditOutlined
										onClick={() => {
											showDrawerforUserStory();
											edituser_stories(story.user_stories_id);
										}}
										style={{ marginLeft: "0.5rem" }}
									/>
								</div>
							))}
						</div>

						{selectUserStorys && (
							<>
								<div className="ViewDescription">
									<div className="ViewDescriptionContent">
										<div className="Darkcolor">View Itinerary</div>
										<div className="StoriesNumber">1</div>
									</div>
									<div className="StoryDescription">
										{selectUserStorys.description}
									</div>
								</div>
								<Card style={{ marginTop: "2rem", width: "52vw" }}>
									<Tabs
										tabPosition="right"
										type="card"
										onChange={(key) => handleKeyClick(key)}
										style={{ height: "40vh", width: "50vw" }}
									>
										{Object.keys(selectUserStorys).map((key) => (
											<TabPane
												style={{ marginLeft: "1rem" }}
												tab={formatText(key)}
												key={key}
											>
												<div className="AcceptanceCriteria">
													{clickedKeyValue && (
														<div
															style={{ height: "39vh", overflowY: "auto" }}
															className={contentClassName}
															onContextMenu={handleRightClick}
															data-path={`/fe/${selectedMenuItem}/${selectUserStorys}/${clickedKeyValue}`}
														>
															<div
																data-path={`/fe/${selectedMenuItem}/${selectUserStorys}/${clickedKeyValue}`}
																className="Darkcolor"
															>
																{formatText(clickedKeyValue)}
															</div>
															{Array.isArray(
																selectUserStorys[clickedKeyValue]
															) ? (
																<ul
																	data-path={`/fe/${selectedMenuItem}/${selectUserStorys}/${clickedKeyValue}`}
																	className="AcceptanceCriteriaList"
																>
																	{selectUserStorys[clickedKeyValue] &&
																		selectUserStorys[clickedKeyValue].map(
																			(item: any, index: any) => (
																				<li key={index}>
																					{typeof item === "object" ? (
																						Object.entries(item).map(
																							([key, value]: [string, any]) => (
																								<div key={key}>
																									<div>{key}</div>
																									{Array.isArray(value) ? (
																										<ul>
																											{value.map(
																												(
																													element,
																													elementIndex
																												) => (
																													<li
																														key={elementIndex}
																													>
																														{element}
																													</li>
																												)
																											)}
																										</ul>
																									) : (
																										<div>{value}</div>
																									)}
																								</div>
																							)
																						)
																					) : (
																						<div>{item}</div>
																					)}
																				</li>
																			)
																		)}
																</ul>
															) : typeof selectUserStorys[clickedKeyValue] ===
															  "string" ? (
																<Typography.Text>
																	{selectUserStorys[clickedKeyValue]}
																</Typography.Text>
															) : typeof selectUserStorys[clickedKeyValue] ===
															  "number" ? (
																<Typography.Text>
																	{selectUserStorys[clickedKeyValue]}
																</Typography.Text>
															) : typeof selectUserStorys[clickedKeyValue] ===
															  "object" ? (
																/* Render the object content as needed */
																<pre>
																	{JSON.stringify(
																		selectUserStorys[clickedKeyValue],
																		null,
																		2
																	)}
																</pre>
															) : null}
														</div>
													)}
												</div>
											</TabPane>
										))}
									</Tabs>
								</Card>
								{/* </Flex> */}
							</>
						)}
					</Card>
				) : (
					<></>
				)}

				{/* Input for New Comment */}
				{contextMenuPosition && (
					<Popover
						title={
							<Flex justify="space-between">
								<Typography.Title level={5}> Add a Comment </Typography.Title>
								<Button
									icon={<CloseOutlined />}
									size="small"
									shape="circle"
									onClick={() => setContextMenuPosition(null)}
								></Button>
							</Flex>
						}
						content={
							<div>
								<Input.TextArea
									style={{ height: "15vh", marginBottom: "1vh" }}
									placeholder="Type your comment here..."
									onChange={(e) => setNewCommentContent(e.target.value)}
									value={newCommentContent}
								/>
								<Button type="primary" onClick={postComment}>
									Post Comment
								</Button>
							</div>
						}
						trigger="click"
						//open={openCommentBox}
						open={isPopoverVisible}
						onOpenChange={(visible) => visible || setContextMenuPosition(null)}
					>
						<div
							className="NewCommentSectio"
							style={{
								position: "fixed",
								top: contextMenuPosition.y + "px",
								left: contextMenuPosition.x + "px",
							}}
						>
							{/* Placeholder for comment button */}
							<Button type="link"></Button>
						</div>
					</Popover>
				)}

				<Drawer
					title="Edit Exposition"
					width={720}
					onClose={onCloseExposition}
					open={openExposition}
					footer={
						<Space style={{ float: "right" }}>
							<Button onClick={onCloseExposition}>Cancel</Button>
							<Button
								type="primary"
								onClick={() => editformexposition.submit()}
							>
								Submit
							</Button>
						</Space>
					}
				>
					<Form
						// labelCol={{ span: 10 }}
						// wrapperCol={{ span: 25 }}
						layout="horizontal"
						hideRequiredMark
						form={editformexposition}
						onFinish={onFinisheditexposition}
						labelAlign="left"
						style={{ maxWidth: 600 }}
					>
						<p style={{ fontWeight: "bold", color: "black" }}>Exposition:</p>

						<Form.Item
							name="exposition"
							rules={[
								{
									required: true,
									message: "Please enter Exposition",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px", height: "25vh" }}
							/>
						</Form.Item>
					</Form>
				</Drawer>

				<Drawer
					title="Edit UserStory"
					width={720}
					onClose={onCloseUserStory}
					open={openUserStory}
					footer={
						<Space style={{ float: "right" }}>
							<Button onClick={onCloseUserStory}></Button>
							<Button
								type="primary"
								onClick={() => editformuserstories.submit()}
							>
								Submit
							</Button>
						</Space>
					}
				>
					<Form
						labelCol={{ span: 10 }}
						wrapperCol={{ span: 25 }}
						layout="horizontal"
						hideRequiredMark
						form={editformuserstories}
						onFinish={onFinishedituserstories}
						labelAlign="left"
						style={{ maxWidth: 600 }}
					>
						<Form.Item
							name="user_stories_id"
							label="Name"
							rules={[{ required: true, message: "Please enter name" }]}
						>
							<Input
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="description"
							label="Description"
							rules={[
								{
									required: true,
									message: "Please enter description",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="acceptance_criteria"
							label="Acceptance criteria"
							rules={[
								{
									required: true,
									message: "Please enter Acceptance criteria",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="assumptions"
							label="Assumptions"
							rules={[{ required: true, message: "Please enter assumptions" }]}
						>
							<Input
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="business_rules"
							label="Business rules"
							rules={[
								{ required: true, message: "Please enter Business rules" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="dependencies"
							label="Dependencies"
							rules={[{ required: true, message: "Please enter dependencies" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="edge_cases"
							label="Edge cases"
							rules={[{ required: true, message: "Please enter Edge cases" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="story_points"
							label="Story points"
							rules={[{ required: true, message: "Please enter Story points" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="user_role_id"
							label="	User role id"
							rules={[
								{ required: true, message: "Please enter System behavior" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<p
							style={{
								fontWeight: "lighter",
								color: "black",
								fontSize: "16px",
							}}
						>
							Use Cases :
						</p>

						<Form.Item
							name={["use_cases", 0, "alternate_flow"]}
							label="Alternate flow"
							rules={[
								{ required: true, message: "Please enter Alternate flow" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", 0, "basic_flow"]}
							label="Basic flow"
							rules={[{ required: true, message: "Please enter Basic flow" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", 0, "business_logic"]}
							label="Business logic"
							rules={[
								{ required: true, message: "Please enter Business logic" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", 0, "post_conditions"]}
							label="Post conditions"
							rules={[
								{ required: true, message: "Please enter Post condition" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", 0, "pre_conditions"]}
							label="Pre conditions"
							rules={[
								{ required: true, message: "Please enter Pre conditions" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", 0, "system_behavior"]}
							label="System behavior"
							rules={[
								{ required: true, message: "Please enter System behavior" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
					</Form>
				</Drawer>
				<FloatButton
					shape="circle"
					type="primary"
					style={{ right: 80 }}
					icon={<PlusOutlined />}
					onClick={showDrawerforCreatingUserStory}
				/>
				<Drawer
					title="Add UserStory"
					width={720}
					onClose={onCloseForCreatingUserStory}
					open={addUserStory}
					footer={
						<Space style={{ float: "right" }}>
							<Button onClick={onCloseForCreatingUserStory}>Cancel</Button>
							<Button type="primary" onClick={() => form.submit()}>
								Submit
							</Button>
						</Space>
					}
				>
					<Form
						labelCol={{ span: 10 }}
						wrapperCol={{ span: 25 }}
						layout="horizontal"
						hideRequiredMark
						form={form}
						onFinish={onFinish}
						labelAlign="left"
						style={{ maxWidth: 600 }}
					>
						<Form.Item
							name="name"
							label="Name"
							rules={[{ required: true, message: "Please enter name" }]}
						>
							<Input
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="description"
							label="Description"
							rules={[
								{
									required: true,
									message: "Please enter description",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="acceptance_criteria"
							label="Acceptance criteria"
							rules={[
								{
									required: true,
									message: "Please enter Acceptance criteria",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="assumptions"
							label="Assumptions"
							rules={[{ required: true, message: "Please enter assumptions" }]}
						>
							<Input
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="business_rules"
							label="Business rules"
							rules={[
								{ required: true, message: "Please enter Business rules" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="dependencies"
							label="Dependencies"
							rules={[{ required: true, message: "Please enter dependencies" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="edge_cases"
							label="Edge cases"
							rules={[{ required: true, message: "Please enter Edge cases" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name="story_points"
							label="Story points"
							rules={[{ required: true, message: "Please enter Story points" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="user_role_id"
							label="User role id"
							rules={[{ required: true, message: "Please enter User role id" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<p
							style={{
								fontWeight: "lighter",
								color: "black",
								fontSize: "16px",
							}}
						>
							Use Cases :
						</p>

						<Form.Item
							name={["use_cases", "alternate_flow"]}
							label="Alternate flow"
							rules={[
								{ required: true, message: "Please enter Alternate flow" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", "basic_flow"]}
							label="Basic flow"
							rules={[{ required: true, message: "Please enter Basic flow" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", "business_logic"]}
							label="Business logic"
							rules={[
								{ required: true, message: "Please enter Business logic" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", "post_conditions"]}
							label="Post conditions"
							rules={[
								{ required: true, message: "Please enter Post condition" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", "pre_conditions"]}
							label="Pre conditions"
							rules={[
								{ required: true, message: "Please enter Pre conditions" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
						<Form.Item
							name={["use_cases", "system_behavior"]}
							label="System behavior"
							rules={[
								{ required: true, message: "Please enter System behavior" },
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
					</Form>
				</Drawer>
			</Layout>
		</Layout>
	);
}

export default FeatureSpecification;
