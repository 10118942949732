import { Form, Input } from "antd";
import React from "react";

interface GithubConnectionDetailsProps {
	connectionData: any;
	setConnectionData: (connectionData: any) => void;
}

const GithubConnectionDetails: React.FC<GithubConnectionDetailsProps> = (
	props
) => {
	return (
		<>
			<Form.Item
				label="AccessToken"
				name={["connectionData", "accessToken"]}
				required
				style={{ width: "100%" }}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
			>
				<Input
					style={{ marginRight: "7px" }}
					value={props.connectionData.accessToken}
					onChange={(e) =>
						props.setConnectionData({
							...props.connectionData,
							accessToken: e.target.value,
						})
					}
				/>
			</Form.Item>
		</>
	);
};

export default GithubConnectionDetails;
