import React, { useState, useRef, useEffect } from "react";
import { Input, List, Avatar, Flex, Button, Row, Col } from "antd";
import {
	BulbOutlined,
	PauseCircleOutlined,
	ReloadOutlined,
	SendOutlined,
} from "@ant-design/icons";
import "./ChatUI.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import CreateWorkpaceModal from "../component/CreateWorkspace";
import { useRequest } from "ahooks";
import Markdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import useWorkspaceStore from "../stores/WorkspaceState";
import useAIActivityStore from "../stores/AIActivityStore";
// import SelectWorkItemModal from "./SelectWorkItemModal";
// import ChatIntro from "../component/chat/ChatIntro";
// import ChatOutro from "../component/chat/ChatOutro";
import DotLoading from "../component/chat/DotLoading";
import remarkGfm from "remark-gfm";

const { TextArea } = Input;

interface Message {
	content: string;
	participant: any;
	isLoading?: boolean;
	resumeLastStep?: boolean;
}

const ChatUI: React.FC = () => {
	const navigate = useNavigate();
	const { goalParam, conversationThreadId } = useParams();

	const [existingConversationThreadId, setExistingConversationThreadId] =
		useState<string>("");
	// const [responseReviewLoading, setResponseReviewLoading] =
	// 	useState<boolean>(false);
	const [conversationGoal, setConversationGoal] = useState<any>(null);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [goal, setGoal] = useState<string>("");

	const [messages, setMessages] = useState<Message[]>([]);
	const [inputValue, setInputValue] = useState<string>("");
	const [lastResponseIndex, setLastResponseIndex] = useState<number>(-1);
	const [responseWaitLoading, setResponseWaitLoading] =
		useState<boolean>(false);
	//const [isFirstStepChange, setIsFirstStepChange] = useState<boolean>(true);
	const [isCreateWorkspaceModalVisible, setIsCreateWorkspaceModalVisible] =
		useState<boolean>(false);
	const [endMessage, setEndMessage] = useState<boolean>(false);
	const [aiConversationThread, setAiConversationThread] = useState<any>(null);
	const [userParticipant, setUserParticipant] = useState<any>(null);
	const [lastMessageId, setLastMessageId] = useState<string>("");
	const { aiConversationContext, setCurrentConversationThread } =
		useAIActivityStore((state: any) => ({
			aiConversationContext: state.aiConversationContext,
			setCurrentConversationThread: state.setCurrentConversationThread,
		}));
	// const [workItemInContext, setWorkItemInContext] = useState<any>(null);
	// const [treadId , setThreadId] = useState<string>('');
	const messageListRef = useRef<HTMLDivElement>(null);
	const streamMessageRef = useRef<HTMLDivElement>(null);
	//const [currentStep, setCurrentStep] = useState<number>(0);

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const [messageStream, setMessageStream] = useState<string>("");
	const [streamMessage, setStreamMessage] = useState<boolean>(false);

	// const [itemSelectionModalVisible, setItemSelectionModalVisible] =
	// 	useState(false);

	useEffect(() => {
		if (goalParam) setGoal(goalParam);
		if (conversationThreadId)
			setExistingConversationThreadId(conversationThreadId);
	}, [goalParam]);

	const handleCreateWorkspaceModalCancel = () => {
		setIsCreateWorkspaceModalVisible(false);
	};
	const scrollToBottom = () => {
		if (messageListRef.current) {
			messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
		}
	};

	const getConversationThread = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/ai-conversation-thread/${existingConversationThreadId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const responseData = await response.json();
		setAiConversationThread(responseData);

		setUserParticipant({
			id: userProfileStoreInstance.profile._id,
			ptype: "user",
			name: userProfileStoreInstance.profile.name,
			email: userProfileStoreInstance.profile.email,
		});
		await getConversationMessages();
		console.log(responseData, "responseData");
		//simulateAIQuestion();
	};

	const getConversationMessages = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/ai-converse-messages/${existingConversationThreadId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const responseData = await response.json();
		const modifiedData = responseData.map((message: Message) => {
			if (message.participant.ptype === "AI")
				return {
					content: message.content,
					participant: message.participant,
					isLoading: false,
					resumeLastStep: false,
				};
			else {
				console.log(message, "message");
				return {
					content: message.content,
					participant: {
						_id: message.participant.id,
						ptype: "user",
						name: message.participant.name,
						email: message.participant.email,
					},
				};
			}
		});
		//filter the first message
		modifiedData.shift();
		console.log(modifiedData, "modifiedData");
		setMessages([...modifiedData]);
		console.log(responseData, "responseData");
	};

	useEffect(() => {
		preProcess();
	}, [goal, existingConversationThreadId]);

	const preProcess = async () => {
		if (existingConversationThreadId !== "") {
			await getConvsersationThreadConfig(goal);
		} else {
			if (goal) {
				setMessages([]);
				await getConvsersationThreadConfig(goal);
			}
		}
	};

	useEffect(() => {
		if (conversationGoal && aiConversationThread) {
			handleInitialMessage();
		}
	}, [conversationGoal, aiConversationThread]);

	// const checkItemInAIConversationContext = (goalParam: string) => {
	// 	if (aiConversationContext) {
	// 		if (
	// 			goalParam === "feature-elaboration" &&
	// 			aiConversationContext.type === "feature-elaboration"
	// 		) {
	// 			if (aiConversationContext?.data?.workitemId) {
	// 				return true;
	// 			} else {
	// 				return false;
	// 			}
	// 		}
	// 		if (
	// 			goalParam === "feature-refinement" &&
	// 			aiConversationContext.type === "feature-refinement"
	// 		) {
	// 			if (aiConversationContext?.data?.workitemId) {
	// 				return true;
	// 			} else {
	// 				return false;
	// 			}
	// 		}
	// 	}
	// 	return false;
	// };

	// const { setAIConversationContext } = useAIActivityStore((state: any) => ({
	// 	setAIConversationContext: state.setAIConversationContext,
	// }));
	// const handleWorkItemSelection = async (item: any) => {
	// 	setItemSelectionModalVisible(false);
	// 	if (goal === "feature-elaboration") {
	// 		setWorkItemInContext(item);
	// 		setAIConversationContext({
	// 			type: "feature-elaboration",
	// 			data: {
	// 				workspaceId: currentWorkspace._id,
	// 				workitemId: item._id,
	// 				userProfile: userProfileStoreInstance.profile,
	// 			},
	// 		});
	// 		await updateConversationThread();
	// 		await getConvsersationThreadConfig(goal);
	// 	} else if (goal === "feature-refinement") {
	// 		setWorkItemInContext(item);
	// 		setAIConversationContext({
	// 			type: "feature-refinement",
	// 			data: {
	// 				workspaceId: currentWorkspace._id,
	// 				workitemId: item._id,
	// 				userProfile: userProfileStoreInstance.profile,
	// 			},
	// 		});
	// 		await updateConversationThread();
	// 		await getConvsersationThreadConfig(goal);
	// 	}
	// };

	// const updateConversationThread = async () => {
	// 	let updatedData = {};
	// 	if (
	// 		goal === "feature-elaboration" &&
	// 		aiConversationContext.data.workitemId
	// 	) {
	// 		updatedData = {
	// 			...aiConversationThread,
	// 			data: {
	// 				itemType: "FEATURE",
	// 				itemId: aiConversationContext.data.workitemId,
	// 			},
	// 		};
	// 	} else if (
	// 		goal === "feature-refinement" &&
	// 		aiConversationContext.data.workitemId
	// 	) {
	// 		updatedData = {
	// 			...aiConversationThread,
	// 			data: {
	// 				itemType: "FEATURE",
	// 				itemId: aiConversationContext.data.workitemId,
	// 			},
	// 		};
	// 	} else if (goal === "idea-refinement") {
	// 		updatedData = {
	// 			...aiConversationThread,
	// 			workspaceId: currentWorkspace._id,
	// 			data: {
	// 				itemType: "WORKSPACE",
	// 				itemId: currentWorkspace._id,
	// 			},
	// 		};
	// 	}

	// 	try {
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/ai-conversation-thread/${aiConversationThread._id}`,
	// 			{
	// 				method: "PUT",
	// 				headers: {
	// 					"Content-Type": "application/json; charset=UTF-8",
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 				body: JSON.stringify(updatedData),
	// 			}
	// 		);

	// 		if (response.ok) {
	// 			console.log("Conversation thread updated successfully");
	// 		} else {
	// 			console.error("Failed to update conversation thread");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error updating conversation thread:", error);
	// 	}
	// };

	// Get the goal input based on the goal

	const getGoalInput = async (goalParam: string) => {
		console.log(aiConversationContext, "aIConversationContext");
		if (goalParam == "idea-refinement") {
			return {
				userProfile: userProfileStoreInstance.profile,
			};
		} else if (goalParam == "feature-refinement") {
			if (
				aiConversationContext.type === "feature-refinement" &&
				aiConversationContext?.data?.workitemId
			) {
				return {
					userProfile: userProfileStoreInstance.profile,
					workspace: currentWorkspace,
					data: aiConversationContext.data,
				};
			} else {
				return {
					userProfile: userProfileStoreInstance.profile,
					workspace: currentWorkspace,
				};
			}
		} else if (goalParam == "feature-elaboration") {
			if (aiConversationContext.type === "feature-elaboration")
				return {
					userProfile: userProfileStoreInstance.profile,
					workspace: currentWorkspace,
					data: aiConversationContext.data,
				};
		}
	};

	const getConvsersationThreadConfig = async (goal: string) => {
		try {
			const goalInput = await getGoalInput(goal);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/ai-conversation/goal/${goal}`,
				{
					method: "POST",
					body: JSON.stringify(goalInput),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const responseData = await response.json();
			setConversationGoal(responseData);

			if (existingConversationThreadId === "") {
				await createConversationThread(responseData.slug, goalInput);
			} else {
				await getConversationThread();
			}

			// Handle the response from the server if needed
			console.log("GET request for step change successful", responseData);
		} catch (error) {
			console.error(
				"There was a problem with the GET request for step change",
				error
			);
		}
	};

	const createConversationThread = async (slug: string, goalInput: any) => {
		try {
			let goalData = {};
			console.log(goalInput, "goalInput");
			if (goalInput?.data?.workitemId) {
				if (goal === "feature-refinement" || goal === "feature-elaboration")
					goalData = {
						itemType: "FEATURE",
						itemId: goalInput.data.workitemId,
					};
			} else if (goal === "idea-refinement") {
				goalData = {
					itemType: "WORKSPACE",
					itemId: currentWorkspace._id,
				};
			}

			const data = {
				participants: [
					{
						id: userProfileStoreInstance.profile._id,
						ptype: "user",
						name: userProfileStoreInstance.profile.name,
						email: userProfileStoreInstance.profile.email,
					},
					{
						id: "",
						ptype: "AI",
						name: "Alchemi AI",
						email: "",
					},
				],
				workspaceId: currentWorkspace._id,
				userProfile: userProfileStoreInstance.profile._id,
				goal: slug,
				data: goalData,
				status: "IN_PROGRESS", // Example: Change based on currentStep
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/ai-conversation-thread`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(data),
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			} else {
				const responseData = await response.json();
				setCurrentConversationThread(responseData);
				setAiConversationThread(responseData);
				setUserParticipant({
					id: userProfileStoreInstance.profile._id,
					ptype: "user",
					name: userProfileStoreInstance.profile.name,
					email: userProfileStoreInstance.profile.email,
				});

				//simulateAIQuestion();

				// Handle the response from the server if needed
				console.log("POST request for step change successful", responseData);
			}
		} catch (error) {
			console.error(
				"There was a problem with the POST request for step change",
				error
			);
		}
	};

	const handleSendMessage = () => {
		if (inputValue.trim() !== "" && !responseWaitLoading) {
			//postUserMessage();
			simulateUserResponse();
			setInputValue("");
		}
	};

	useEffect(() => {
		if (lastResponseIndex !== -1) {
			const timeoutId = setTimeout(() => {
				setLastResponseIndex(-1);
			}, 5000);
			return () => clearTimeout(timeoutId);
		}
	}, [lastResponseIndex, messages]);

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const simulateUserResponse = async () => {
		const userResponse: Message = {
			content: inputValue,
			participant: userParticipant,
		};
		console.log(userParticipant.name);

		const res = await sendPostRequest(userResponse);
		console.log(res, "res");

		setMessages((prevMessages) => [...prevMessages, userResponse]);
		scrollToBottom();
		setLastResponseIndex(messages.length);
		setResponseWaitLoading(true);

		//setLastMessageId("");
	};

	const handleAIResponse1 = async (messageId: string) => {
		console.log(messageId, "messageId");
		if (messageId) {
			const result = await fetch(
				`${process.env.REACT_APP_API_URL}/ai-converse-messages/replyfor/${messageId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (result.status !== 200 && result.status !== 201) {
				throw new Error("Network response was not ok");
			}
			try {
				const responseData = await result.json();

				return responseData;
			} catch (error) {
				if (
					error instanceof SyntaxError &&
					error.message === "Unexpected end of JSON input"
				) {
					// Handle the case of unexpected end of JSON input
					console.log("JSON data is incomplete or empty.");
				} else {
					// Handle other types of errors
					console.error("An error occurred:", error);
				}
			}
		}
	};
	const { data, run, cancel } = useRequest(
		(id: string) => handleAIResponse1(id),
		{
			pollingInterval: 2000,
			pollingWhenHidden: false,
		}
	);

	useEffect(() => {
		console.log("GET request successful", data);
		if (data) {
			const aiResponse: Message = {
				content: data.content,
				participant: data.participant,
				isLoading: false,
				resumeLastStep: false,
			};
			//setMessages((prevMessages) => [...prevMessages, aiResponse]);
			setEndMessage(data.endMessage);
			//test to update last mesage
			setMessages((prevMessages) =>
				prevMessages.map((message, index) =>
					index === prevMessages.length - 1
						? {
								...message,
								content: aiResponse.content,
								isLoading: false,
								resumeLastStep: false,
							}
						: message
				)
			);
			cancel();
			scrollToBottom();
			setStreamMessage(false);
			setResponseWaitLoading(false);
		}
	}, [data]);
	useEffect(() => {
		return () => {
			cancel();
			//threadResponseReviewCancel();
		};
	}, []);
	// const handleThreadResponseReview = async (id: string) => {
	// 	if (id) {
	// 		const result = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/ai-conversation-thread/${id}`,
	// 			{
	// 				method: "GET",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 			}
	// 		);
	// 		if (result.status !== 200 && result.status !== 201) {
	// 			throw new Error("Network response was not ok");
	// 		}
	// 		const responseData = await result.json();

	// 		return responseData;
	// 	}
	// };
	// const {
	// 	data: threadResponseReviewData,
	// 	run: threadResponseReviewRun,
	// 	cancel: threadResponseReviewCancel,
	// } = useRequest((id: string) => handleThreadResponseReview(id), {
	// 	pollingInterval: 2000,
	// 	pollingWhenHidden: false,
	// });
	// useEffect(() => {
	// 	console.log("GET request successful", threadResponseReviewData);
	// 	if (threadResponseReviewData) {
	// 		if (threadResponseReviewData.status === "COMPLETE") {
	// 			threadResponseReviewCancel();
	// 			handleNavigation();
	// 		}
	// 		//else threadResponseReviewRun(aiConversationThread._id);
	// 	}
	// }, [threadResponseReviewData]);

	const getMessageStream = async (id: string) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/ai-converse-messages/replyfor/stream/${id}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);
		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}
		setStreamMessage(true);
		setResponseWaitLoading(true);
		const reader = response.body?.getReader();
		const decoder = new TextDecoder();
		// let result = "";

		if (reader) {
			let done = false;
			while (!done) {
				const { value, done: readerDone } = await reader.read();
				let chunk = decoder.decode(value, { stream: !readerDone });
				setMessageStream((previousValue) => {
					if (
						chunk.includes("start_message") ||
						chunk.includes("stop_message")
					) {
						chunk = chunk.replace("start_message", "");
						chunk = chunk.replace("stop_message", "");
						//return previousValue;
					}
					if (chunk !== "") setResponseWaitLoading(false);

					return previousValue + chunk;
				});
				// result += chunk;
				// if (result.length == 0) result = result.trim();

				// console.log("before replace", result + "-");

				// if (
				// 	result.includes("start_message") ||
				// 	result.includes("stop_message")
				// ) {
				// 	result = result.replace("start_message", "");
				// 	result = result.replace("stop_message", "");
				// 	setMessageStream(result);
				// }
				// if (result !== "") {
				// 	//if (result.trim() != "") setResponseWaitLoading(false);
				// 	setMessageStream(result => result + "-");
				// 	scrollToBottomStreamMessage();
				// }
				// console.log("after replace", result + "-");

				if (chunk.includes("stop_message")) {
					done = true;
					setTimeout(() => {
						setMessageStream("");
					}, 1000);
				}

				if (readerDone) {
					console.log("done");
					done = true;
					// after stream finish set the complete message to div
				}
			}
			scrollToBottom();
			//setStreamMessage(false);
			setTimeout(() => {
				run(id);
			}, 1000);
			//run(id);
		}
	};
	// const scrollToBottomStreamMessage = () => {
	// 	if (streamMessageRef.current && messageListRef.current) {
	// 		console.log(streamMessageRef.current.scrollTop, "scrollTop");
	// 		console.log(streamMessageRef.current.scrollHeight, "scrollHeight");
	// 		streamMessageRef.current.scrollTop = messageListRef.current.scrollHeight;
	// 	}
	// };

	const sendPostRequest = async (message: Message) => {
		const data = {
			participant: message.participant,
			content: message.content,
			contentType: "text",
			version: "1.0",
			aiConversationThreadId: aiConversationThread._id,
			previousMessageId: lastMessageId,
		};
		fetch(`${process.env.REACT_APP_API_URL}/ai-converse-messages`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				return response.json();
			})
			.then((data) => {
				// Handle the response from the server if needed
				console.log("POST request successful", data._id);
				setLastMessageId(data._id);
				//wait for 3000ms to get the response from the server
				const aiResponse: Message = {
					content: "Please wait for a moment...",
					participant: {
						id: "",
						ptype: "AI",
						name: "Alchemi AI",
						email: "",
					},
					isLoading: true,
				};
				setMessages((prevMessages) => [...prevMessages, aiResponse]);

				//query for stream of message
				setTimeout(() => {
					getMessageStream(data._id);
				}, 2000);

				//this is where reply from AI for long polling is handled
				//run(data._id);
			})
			.catch((error) => {
				console.error("There was a problem with the POST request", error);
			});
	};

	const resumeConversation = () => {
		setMessages((prevMessages) =>
			prevMessages.map((message, index) =>
				index === prevMessages.length - 1
					? { ...message, isLoading: true, resumeLastStep: false }
					: message
			)
		);
		run(lastMessageId);
	};

	const handleInitialMessage = async () => {
		// if (goal === "idea-refinement") {
		// 	//await updateConversationThread();
		// 	await getConvsersationThreadConfig(goal);
		// } else if (goal === "feature-refinement") {
		// 	//await updateConversationThread();
		// 	await getConvsersationThreadConfig(goal);
		// } else if (goal === "feature-elaboration") {
		// 	//await updateConversationThread();
		// 	await getConvsersationThreadConfig(goal);
		// }

		console.log(conversationGoal, "conversationGoal");

		const initialMessage: Message = {
			content: conversationGoal.initialMessage,
			participant: {
				id: userProfileStoreInstance.profile._id,
				ptype: "user",
				name: userProfileStoreInstance.profile.name,
				email: userProfileStoreInstance.profile.email,
			},
		};

		console.log(aiConversationThread, "aiConversationThread111");

		const data = {
			participant: initialMessage.participant,
			content: initialMessage.content,
			contentType: "text",
			version: "1.0",
			aiConversationThreadId: aiConversationThread._id,
			previousMessageId: lastMessageId,
		};

		fetch(`${process.env.REACT_APP_API_URL}/ai-converse-messages`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				return response.json();
			})
			.then((data) => {
				// Handle the response from the server if needed
				console.log("POST request successful", data._id);
				setLastMessageId(data._id);
				//wait for 3000ms to get the response from the server
				const aiResponse: Message = {
					content: "Please wait for a moment...",
					participant: {
						id: "",
						ptype: "AI",
						name: "Alchemi AI",
						email: "",
					},
					isLoading: true,
				};
				setMessages((prevMessages) => [...prevMessages, aiResponse]);

				//query for stream of message
				setTimeout(() => {
					getMessageStream(data._id);
				}, 2000);

				//this is where reply from AI for long polling is handled
				//run(data._id);
			})
			.catch((error) => {
				console.error("There was a problem with the POST request", error);
			});

		//setMessages((prevMessages) => [...prevMessages, initialMessage]);
		scrollToBottom();
	};

	const handleResponseReview = () => {
		//setResponseReviewLoading(true);
		//threadResponseReviewRun(aiConversationThread._id);
		navigate(`/fd/postprocess/chat/${goalParam}`);
	};

	// const handleNavigation = () => {
	// 	if (goal === "idea-refinement") {
	// 		navigate(`/fd/workspace/create/${aiConversationThread._id}`);
	// 	} else if (goal === "feature-refinement") {
	// 		navigate(`/fd/workspace/create-feature/${aiConversationThread._id}`);
	// 	} else if (goal === "feature-elaboration") {
	// 		navigate(`/fd/workspace/elaborate-feature/${aiConversationThread._id}`);
	// 	}
	// };

	// responseReviewLoading ? (
	// 	<ChatOutro />
	// ) :

	return (
		<>
			<Flex
				vertical
				style={{
					height: "100vh",
				}}
			>
				<Flex justify="center">
					<Avatar
						style={{ backgroundColor: "white", margin: "1.5rem 1rem" }}
						size={{ xs: 12, sm: 24, md: 32, lg: 40, xl: 40, xxl: 40 }}
						icon={<BulbOutlined style={{ color: "blue" }} />}
					/>

					<h3 style={{ fontSize: "1.5rem" }}>
						{conversationGoal ? conversationGoal.goal : ""}
					</h3>
				</Flex>

				<Flex
					justify="center"
					style={{
						flex: 1,
						overflowY: "auto",
					}}
					ref={messageListRef}
				>
					{messages &&
					messages.length === 0 &&
					existingConversationThreadId == "" ? (
						<></>
					) : (
						<Flex
							vertical
							align="center"
							style={{
								flex: 1,
								width: "100%",
							}}
						>
							{" "}
							<List
								style={{ width: "70%" }}
								split={false}
								dataSource={messages}
								renderItem={(message, index) => (
									<List.Item
										key={index}
										style={{
											justifyContent:
												message.participant.ptype === "AI" ? "left" : "right",
											textAlign:
												message.participant.ptype === "AI" ? "left" : "right",
										}}
									>
										<Flex
											align={
												message.participant.ptype === "AI" ? "start" : "end"
											}
											justify={
												message.participant.ptype === "AI" ? "start" : "end"
											}
											style={{
												borderRadius: "8px",
												margin:
													message.participant.ptype === "AI"
														? "0rem 15rem 0rem 2rem"
														: "0rem 2rem 0rem 5rem",
											}}
										>
											<Row>
												{message.participant.ptype === "AI" &&
													!message.isLoading &&
													(!streamMessage || message.content != "") && (
														<Col span={2}>
															<>
																<Avatar
																	src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
																	style={{ marginRight: "8px", width: "32px" }}
																/>
															</>
														</Col>
													)}

												{message.participant.ptype === "AI" &&
													message.isLoading &&
													!streamMessage && (
														<Col span={4}>
															<Flex vertical={false} style={{ width: "400px" }}>
																<Avatar
																	src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
																	style={{ marginRight: "8px", width: "32px" }}
																/>

																<DotLoading />
															</Flex>
														</Col>
													)}

												{/* {message.participant.ptype === "AI" &&
													message.isLoading &&
													!streamMessage && <Col span={1}><DotLoading /></Col>} */}
												{message.participant.ptype === "AI" &&
													message.resumeLastStep && (
														<ReloadOutlined
															onClick={() => resumeConversation()}
															size={32}
														/>
													)}

												{message.participant.ptype === "AI" &&
													!message.isLoading && (
														<Col span={21}>
															<div
																style={{
																	textAlign: "left",
																	background: "#fff",
																	padding: "14px",
																	marginRight: "30px",
																	borderRadius: "12px",
																}}
															>
																<Markdown remarkPlugins={[remarkGfm]}>
																	{Array.isArray(message.content)
																		? message.content.join("")
																		: message.content}
																</Markdown>
															</div>
														</Col>
													)}

												{message.participant.ptype === "user" && (
													<Col span={21}>
														<div
															style={{
																textAlign: "right",
																marginLeft: "30px",
																background: "#fff",
																padding: "0px 14px 14px 14px",
																borderRadius: "12px",
															}}
														>
															{" "}
															<br />
															{message.content}
														</div>
													</Col>
												)}

												{message.participant.ptype === "user" && (
													<Col span={1}>
														<Avatar
															style={{
																color: "white",
																backgroundColor: "#3478f6",
																verticalAlign: "middle",
																textAlign: "right",
																marginTop: "25px",
															}}
															size="default"
															gap={4}
														>
															{userProfileStoreInstance.profile.name
																.charAt(0)
																.toUpperCase()}
														</Avatar>
													</Col>
												)}
											</Row>
										</Flex>
									</List.Item>
								)}
							/>
							{streamMessage && (
								<List style={{ width: "70%" }}>
									<List.Item
										key={23}
										style={{
											justifyContent: "left",
											textAlign: "left",
										}}
									>
										<Flex
											ref={streamMessageRef}
											align={"start"}
											justify={"start"}
											style={{
												borderRadius: "8px",
												margin: "0rem 15rem 0rem 2rem",
											}}
										>
											<Avatar
												src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
												style={{
													marginRight: "20px",
													width: "32px",
													marginTop: "-25px",
												}}
											/>

											<div
												style={{
													marginTop: "-25px",
													textAlign: "left",
													color:
														"var(--character-secondary-45, rgba(0, 0, 0, 0.45))",
													fontFamily: "Poppins",
													fontSize: "15px",
													fontStyle: "normal",
													fontWeight: "400",
													lineHeight: "22px",
													background: "#fff",
													padding: "14px",
													borderRadius: "12px",
												}}
											>
												{messageStream && (
													<>
														{/* <Markdown remarkPlugins={[remarkGfm]}> */}
														{messageStream}{" "}
														<span className="blinking-cursor">|</span>
														{/* </Markdown> */}
													</>
												)}
											</div>
										</Flex>
									</List.Item>
								</List>
							)}
						</Flex>
					)}
				</Flex>
				{messages && messages.length > 0 && endMessage && (
					<Flex justify="center">
						<Flex
							justify="center"
							style={{
								marginTop: "1rem",
								marginBottom: "15vh",
								display: "flex",
								alignItems: "center",
								width: "60%",
							}}
						>
							<Button onClick={() => handleResponseReview()}>Continue</Button>
						</Flex>
					</Flex>
				)}
				{!endMessage && messages && messages.length > 0 && (
					<Flex justify="center">
						<Flex
							justify="center"
							style={{
								marginTop: "1rem",
								marginBottom: "15vh",
								display: "flex",
								alignItems: "center",
								width: "60%",
							}}
						>
							<TextArea
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onPressEnter={handleSendMessage}
								placeholder="Type your question here..."
								autoSize={{ minRows: 1, maxRows: 6 }}
								style={{
									flex: 1,
									padding: "10px",
									marginRight: "5px",
								}}
							/>
							{responseWaitLoading ? (
								<PauseCircleOutlined
									twoToneColor={"#696969"}
									style={{ cursor: "not-allowed", fontSize: "1.5rem" }}
								/>
							) : (
								<SendOutlined
									style={{ cursor: "pointer", fontSize: "1.5rem" }}
									onClick={handleSendMessage}
								/>
							)}
						</Flex>
					</Flex>
				)}
				<CreateWorkpaceModal
					isModalVisible={isCreateWorkspaceModalVisible}
					handleModalCancel={handleCreateWorkspaceModalCancel}
					//workspaceId={selectedWorkspaceId}
				/>
			</Flex>
		</>
	);
};

export default ChatUI;
