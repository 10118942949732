import { createReactBlockSpec } from "@blocknote/react";
import MonacoEditor from "@monaco-editor/react";
import { Dropdown, Button, Menu } from "antd";

export const languageOptions = [
	{ title: "JavaScript", value: "javascript" },
	{ title: "TypeScript", value: "typescript" },
	{ title: "Python", value: "python" },
	{ title: "Java", value: "java" },
	{ title: "C++", value: "cpp" },
	{ title: "HTML", value: "html" },
	{ title: "CSS", value: "css" },
] as const;

type LanguageValue = (typeof languageOptions)[number]["value"];

export const CodeBlock = createReactBlockSpec(
	{
		type: "procode",
		propSchema: {
			language: {
				default: "javascript",
			},
			code: {
				default: "",
			},
		},
		content: "none",
	},
	{
		render: (props) => {
			const currentLanguage = props.block.props.language as LanguageValue;

			// const data = props.block?.props;
			// const onInputChange = (newValue: string, event: any) => {
			//   props.editor.updateBlock(props.block, {

			//     props: { ...props.block.props, code: newValue },
			//   });
			// };
			const menu = (
				<Menu
					onClick={({ key }) => {
						const selectedLanguage = key as LanguageValue;
						props.editor.updateBlock(props.block, {
							type: "procode",
							props: { language: selectedLanguage },
						});
					}}
				>
					{languageOptions.map((lang) => (
						<Menu.Item key={lang.value}>{lang.title}</Menu.Item>
					))}
				</Menu>
			);

			return (
				<div
					id={props.block?.id}
					className={"code-block"}
					data-language={currentLanguage}
				>
					<Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
						<Button>
							{languageOptions.find((lang) => lang.value === currentLanguage)
								?.title || "Select Language"}
						</Button>
					</Dropdown>
					<MonacoEditor
						height="200px"
						language={currentLanguage}
						theme="vs-dark"
						value={props.block.props.code}
						onChange={(newValue: any) => {
							props.editor.updateBlock(props.block, {
								props: { ...props.block.props, code: newValue },
							});
						}}
					/>
				</div>
			);
		},
		toExternalHTML: ({ block }) => {
			return (
				<pre>
					<code>{block.props.code}</code>
				</pre>
			);
		},
	}
);
