/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import {
	Button,
	Card,
	Avatar,
	Space,
	Mentions,
	Flex,
	Upload,
	message,
} from "antd";
import {
	LikeOutlined,
	MessageOutlined,
	UserOutlined,
	CloseOutlined,
	DislikeOutlined,
	SendOutlined,
	PlusOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./DiscussionTab.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import moment from "moment";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { BlobServiceClient } from "@azure/storage-blob";

// interface Message {
// 	// _id:number;
//     replies: any[];
//     id: number;
//     content: string;
//     username: string;
//     type: "main" | "reply";
// }

const ChatInterface: React.FC = () => {
	const { selectedItemDetails } = planerViewStore();
	const [inputValue, setInputValue] = useState<string>("");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [messages, setMessages] = useState<any[]>([]);
	const [replyMode, setReplyMode] = useState<{
		active: boolean;
		messageId: number | null;
	}>({ active: false, messageId: null });
	const [replyModeReply, setReplyModeReply] = useState<{
		active: boolean;
		messageId: number | null;
	}>({ active: false, messageId: null });
	const [replyContent, setReplyContent] = useState<string>("");
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [options, setOptions] = useState<any[]>([]);
	const [mentions, setMentions] = useState<any[]>([]);
	const [uploadImage, setUploadImage] = useState<any>([]);
	const [fileList, setFileList] = useState<any>([]);

	// console.log(selectedItemDetails, "selectedItemDetails");

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		}
	};

	console.log("userProfileStoreInstance", userProfileStoreInstance);

	const fetchMessages = async () => {
		try {
			console.log(selectedItemDetails, "selectedItemDetails");
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/discussions/${selectedItemDetails?._id}/${selectedItemDetails?.type}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();

				console.log(data, "data");
				setMessages(data);
			} else {
				console.error("Failed to fetch messages");
			}
		} catch (error) {
			console.error("Error fetching messages:", error);
		}
	};

	useEffect(() => {
		fetchMessages();
	}, [selectedItemDetails]);

	const handleMessageSend = async () => {
		if (inputValue.trim() !== "") {
			const newMessage = {
				// _id:messages[0]._id,
				id: messages.length,
				content: inputValue.trim(),
				username: userProfileStoreInstance.profile.name,
				replies: [],
				type: "main",
			};
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/discussions`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							type: "main",
							mentions: mentions,
							workspaceId: currentWorkspace._id,
							attachments: [],
							content: inputValue.trim(),
							createdBy: userProfileStoreInstance.profile.name,
							threadId: null,
							parentObjectId: [
								{ type: selectedItemDetails.type, id: selectedItemDetails._id },
							],

							reactions: [{ type: null, createdBy: null, createdAt: null }],
							resolved: false,
							resolvedBy: null,
							resolvedAt: null,
						}),
					}
				);
				if (response.ok) {
					const data = await response.json();
					newMessage.id = data._id;
					setMessages([...messages, newMessage]);
					fetchMessages();
				} else {
					console.error("Failed to post message");
				}
			} catch (error) {
				console.error("Error posting message:", error);
			}
			setInputValue("");
		}
		if (uploadImage.length > 0) {
			// const getAllImageTags = () => {
			// 	const urls = uploadImage.map((image:any) => image.url);
			// 	return urls.join('\n') <img key={index} src={image.url} alt={`Image ${index}`} />
			// };
			const getAllImageUrlsString = () => {
				return uploadImage
					.map(
						(image: any, index: number) =>
							`<img key=${index} width="100%" src="${image.url}" alt="Image ${index}" />`
					)
					.join("\n");
			};

			// const getAllImageUrlsString = () => {
			// 	const urls = uploadImage.map((image:any) => image.url);
			// 	return urls.join('\n');
			//   };
			// const getAllImageUrls = uploadImage.map((image:any) => image.url);

			const newMessage = {
				// _id:messages[0]._id,
				id: messages.length,
				content: getAllImageUrlsString(),
				username: userProfileStoreInstance.profile.name,
				replies: [],
				type: "image",
			};
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/discussions`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							type: "image",
							mentions: mentions,
							workspaceId: currentWorkspace._id,
							attachments: [],
							content: getAllImageUrlsString(),
							createdBy: userProfileStoreInstance.profile.name,
							threadId: null,
							parentObjectId: [
								{ type: selectedItemDetails.type, id: selectedItemDetails._id },
							],

							reactions: [{ type: null, createdBy: null, createdAt: null }],
							resolved: false,
							resolvedBy: null,
							resolvedAt: null,
						}),
					}
				);
				if (response.ok) {
					const data = await response.json();
					newMessage.id = data._id;
					setMessages([...messages, newMessage]);
					setUploadImage([]);
					setFileList([]);
					fetchMessages();
				} else {
					console.error("Failed to post message");
				}
			} catch (error) {
				console.error("Error posting message:", error);
			}
			setInputValue("");
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleMessageSend();
			setReplyMode({ ...replyMode, active: false });
		}
	};

	const handleKeyDownReply = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleReplySend();
			setReplyModeReply({ ...replyModeReply, active: false });
			setReplyMode({ ...replyMode, active: false });
		}
	};

	const handleReplyClick = (messageId: number) => {
		setReplyMode({ active: true, messageId });
	};

	const handleCancelReply = () => {
		setReplyMode({ active: false, messageId: null });
		setReplyModeReply({ active: false, messageId: null });
	};

	const handleReplyChange = (value: string) => {
		setReplyContent(value);
	};

	const handleReplySend = async () => {
		if (replyContent.trim() !== "") {
			const newReply = {
				// _id:messages._id,
				id: messages.length, // This should be replaced with the ID received from the backend
				content: replyContent.trim(),
				username: userProfileStoreInstance.profile.name,
				replies: [],
				type: "reply",
			};
			try {
				let parentMessageId: number | null = null;
				if (replyMode.active && replyMode.messageId !== null) {
					parentMessageId = replyMode.messageId;
				} else if (replyModeReply.active && replyModeReply.messageId !== null) {
					parentMessageId = replyModeReply.messageId;
				}

				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/discussions`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							type: "reply",
							mentions: [],
							attachments: [],
							content: replyContent.trim(),
							createdBy: userProfileStoreInstance.profile.name,
							threadId: parentMessageId,
							parentObjectId: [
								{ type: selectedItemDetails.type, id: selectedItemDetails._id },
							],

							reactions: [{ type: null, createdBy: null, createdAt: null }],
							resolved: false,
							resolvedBy: null,
							resolvedAt: null,
						}),
					}
				);
				if (response.ok) {
					const data = await response.json();
					newReply.id = data._id; // Assuming backend returns the ID of the created reply

					// Find the index of the parent message
					const parentMessageIndex = messages.findIndex(
						(msg) => msg.id === parentMessageId
					);

					if (parentMessageIndex !== -1) {
						// Update the replies array of the parent message
						messages[parentMessageIndex].replies = [
							...(messages[parentMessageIndex].replies || []),
							newReply,
						];

						// Update the state with the modified messages array
						setMessages([...messages]);

						// Clear reply content after sending
						setReplyContent("");
					}
				} else {
					console.error("Failed to post reply");
					// Handle error
				}
			} catch (error) {
				console.error("Error posting reply:", error);
				// Handle error
			}
		}
	};

	const handleReaction = async (messageId: number, reactionType: string) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/discussions/${messageId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						reactions: [
							{
								type: reactionType,
								createdBy: userProfileStoreInstance.profile.name,
								createdAt: new Date().toISOString(),
							},
						],
					}),
				}
			);
			if (!response.ok) {
				console.error(`Failed to ${reactionType} message`);
			}
		} catch (error) {
			console.error(`Error ${reactionType} message:`, error);
		}
	};

	const fetchUserProfile = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${currentWorkspace._id}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				const options = data.map((item: any) => {
					const formattedName = item.userProfileId.name.replace(/\s+/g, "-"); // Replace spaces with hyphens
					return {
						value: formattedName,
						label: formattedName,
						id: item.userProfileId._id,
					};
				});
				setOptions(options);
				console.log("datafor userprofile", data);
			} else {
				console.error("Error fetching tags data");
			}
		} catch (error) {
			console.error("Error fetching tags data:", error);
		}
	};
	useEffect(() => {
		fetchUserProfile();
	}, []);

	console.log("inputValue", inputValue);

	// const handlevalueChange = (value: string) => {
	// 	setInputValue(value);
	// 	const Mentions = value.match(/@\w+/g) || []; // Regex to match mentions
	// 	setMentions(Mentions);
	// };

	const handlevalueChange = (value: string) => {
		setInputValue(value);

		// Extract mentions from the value
		const Mentions = value.match(/@\w+/g) || [];

		// Map mentions to get corresponding IDs
		const newMentions = Mentions.map((mention) => {
			// Extract username from mention
			const username = mention.substring(1); // Remove '@' symbol
			// Find the corresponding option in options array
			const option = options.find(
				(opt) => opt.value.split("-")[0] === username
			);
			if (option) {
				return option.id;
			}
			return null; // Return null if no option found
		});

		// Filter out null mentions
		const filteredMentions = newMentions.filter((mention) => mention !== null);

		// Use filteredMentions as needed

		console.log("filtered", filteredMentions);
		setMentions(filteredMentions);
	};

	const account = process.env.REACT_APP_FILE_UPLOAD_STORAGE_ACCOUNT; // get the storage account name from the .env file
	const sasToken = process.env.REACT_APP_FILE_UPLOAD_STORAGE_SAS_TOKEN; // get the SAS token from the .env file
	const containerName: any =
		process.env.REACT_APP_FILE_UPLOAD_STORAGE_CONTAINER; // get the container name from the .env file
	const blobServiceClient = new BlobServiceClient(
		`https://${account}.blob.core.windows.net/?${sasToken}`
	); // create a blobServiceClient
	const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

	const checkUplpoadedImage = (e: any) => {
		console.log("e in side fun", e);
	};

	console.log("fileList", fileList);

	const HandleRemove = (file: any) => {
		console.log("file", file);

		setUploadImage((prevImages: any) => {
			const index = prevImages.findIndex(
				(image: any) => image.name === file.name
			);
			if (index !== -1) {
				prevImages.splice(index, 1); // Remove the file from the array
			}
			return [...prevImages]; // Return the updated array
		});

		// Remove the file from fileList if its name matches with the existing data
		setFileList((prevImages: any) => {
			const index = prevImages.findIndex(
				(image: any) => image.name === file.name
			);
			if (index !== -1) {
				prevImages.splice(index, 1); // Remove the file from the array
			}
			return [...prevImages]; // Return the updated array
		});
	};

	return (
		<div style={{ maxWidth: "1000px", margin: "auto", textAlign: "left" }}>
			{/* <ReactQuill
				value={inputValue}
				onChange={setInputValue}
				placeholder="Type your message..."
				onKeyDown={handleKeyDown}
			/> */}
			<Flex justify="space-between" align="center">
				<div style={{ width: "100%" }}>
					<Mentions
						value={inputValue}
						onChange={handlevalueChange}
						options={options}
						allowClear
						rows={3}
					/>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignContent: "center",
						}}
					>
						<Upload
							fileList={fileList}
							customRequest={async ({ onSuccess, file }) => {
								const blobName = `${new Date().getTime()}-${
									userProfileStoreInstance.profile._id
								}.jpeg`; // Specify a default blob name if needed
								const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
								await blobClient.uploadData(file as Blob, {
									blobHTTPHeaders: { blobContentType: "image/jpeg" },
								}); // upload the image
								//   await fetchImages();   // fetch all images again after the upload is completed
								const newdata = {
									...userProfileStoreInstance.profile,
									UploadedImage: `https://${account}.blob.core.windows.net/${containerName}/${blobName}`,
									name: (file as File).name,
								};
								if (onSuccess) onSuccess("ok");
								console.log("filedetail in newdata fun", file);

								console.log("newdata", newdata);
								// setUploadImage((prevImages:any) => [...prevImages.url,{"url":newdata.UploadedImage}]);
								setUploadImage((prevImages: any) => {
									if (prevImages) {
										return [
											...prevImages,
											{ url: newdata.UploadedImage, name: newdata.name },
										];
									} else {
										return [{ url: newdata.UploadedImage, name: newdata.name }];
									}
								});
							}}
							// listType="picture-card"
							onChange={(e: any) => {
								checkUplpoadedImage(e);
								console.log("e", e);

								// setFileList([e.file]);
								if (e.file.status === "done") {
									setFileList((prevFileList: any) =>
										prevFileList.map((file: any) => {
											if (
												file.name === e.file.name &&
												file.status === "uploading"
											) {
												// Change the status to "done" for the file with matching name and previous status "uploading"
												return {
													...file,
													status: "done",
												};
											}
											return file; // Return other files as they are
										})
									);
								} else if (e.file.status === "error") {
									message.error("Failed to upload image.");
								} else {
									// setFileList([]);
									setFileList((prevImages: any) => {
										if (prevImages) {
											return [...prevImages, e.file];
										} else {
											return [e.file];
										}
									});
								}
							}}
							onRemove={HandleRemove}
						>
							<Button
								style={{
									margin: "0.7rem 0 0 1rem",
									// width: "2rem",
									// padding: "0.3rem",
								}}
								// type="primary"
								icon={<UploadOutlined />}
							>
								Upload Image
							</Button>
						</Upload>
						<Button
							style={{
								margin: "0.7rem 1rem 0 0rem",
								padding: "0.4rem 0.4rem 0.4rem 0.5rem",
							}}
							icon={<SendOutlined />}
							type="primary"
							onClick={handleMessageSend}
						></Button>
					</div>
				</div>
			</Flex>
			<Card style={{ border: "none", height: "50vh", overflow: "scroll" }}>
				{messages.map(
					(message) =>
						message.content && (
							<div key={message.id} style={{ marginBottom: "10px" }}>
								<Card
									ref={messagesEndRef}
									style={{
										borderRadius: "0px",
										width: "90%",
										float: message.type === "main" ? "left" : "right",
										boxShadow:
											(replyMode.active &&
												replyMode.messageId === message.id) ||
											(replyModeReply.active &&
												replyModeReply.messageId === message.id)
												? "0 2px 4px rgba(0.1,0.1,0.1,0.2)"
												: "0 4px 4px -2px rgba(0, 0, 0, 0.1)",
										backgroundColor:
											(replyMode.active &&
												replyMode.messageId === message.id) ||
											(replyModeReply.active &&
												replyModeReply.messageId === message.id)
												? "#f0f0f0"
												: "white",
										marginBottom: "10px",
									}}
									actions={[
										<div key={message.id}>
											<div
												dangerouslySetInnerHTML={{ __html: message.content }}
												style={{
													textAlign: "left",
													marginLeft: "1.5vw",
													color: "black",
												}}
											/>
										</div>,
									]}
								>
									<Card.Meta
										title={
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<Space style={{ marginLeft: "1rem" }}>
													<Avatar
														style={{ backgroundColor: "#EAF0FC" }}
														icon={<UserOutlined style={{ color: "#3478F6" }} />}
													/>
													<p
														style={{
															color: "black",
															fontWeight: "normal",
															margin: 0,
														}}
													>
														{message.username || message?.createdBy?.name}
													</p>
												</Space>
												<Space>
													<Button
														icon={<LikeOutlined />}
														onClick={() => handleReaction(message._id, "like")}
													/>
													<Button
														icon={<DislikeOutlined />}
														onClick={() =>
															handleReaction(message._id, "dislike")
														}
													/>
													{!replyMode.active && (
														<Button
															icon={<MessageOutlined />}
															onClick={() => handleReplyClick(message.id)}
															style={{ marginLeft: "8px" }}
														/>
													)}
													{replyMode.active &&
														replyMode.messageId === message.id && (
															<Button
																icon={<CloseOutlined />}
																onClick={handleCancelReply}
																style={{ marginLeft: "8px" }}
															/>
														)}
													<p
														style={{
															color: "black",
															fontWeight: "normal",
															margin: 0,
														}}
													>
														{moment(message.createdAt).fromNow()}
													</p>
												</Space>
											</div>
										}
									/>
								</Card>
								{replyMode.active && replyMode.messageId === message.id && (
									// <ReactQuill
									// 	style={{ width: "90%" }}
									// 	value={replyContent}
									// 	onChange={handleReplyChange}
									// 	onKeyDown={handleKeyDownReply}
									// 	placeholder="Reply to this message..."
									// />
									<Flex justify="space-between" align="center">
										<Mentions
											value={inputValue}
											onChange={handlevalueChange}
											options={options}
											allowClear
											rows={3}
										/>
										<Button
											style={{ marginLeft: "1rem" }}
											icon={<SendOutlined />}
											onClick={handleMessageSend}
										></Button>
									</Flex>
								)}
								{message.replies &&
									message.replies.map((reply: any) => (
										<div
											key={reply.id}
											style={{
												float: message.type === "main" ? "left" : "right",
												clear: "both",
												textAlign: message.type === "main" ? "left" : "right",
												width: "90%",
												marginRight: "10%",
											}}
										>
											<Card
												ref={messagesEndRef}
												style={{
													borderRadius: "0px",
													width: "90%",
													float: "right",
													boxShadow:
														replyModeReply.active &&
														replyModeReply.messageId === message.id
															? "0 2px 4px rgba(0.1,0.1,0.1,0.2)"
															: "0 4px 4px -2px rgba(0, 0, 0, 0.1)",
													backgroundColor:
														replyModeReply.active &&
														replyModeReply.messageId === message.id
															? "#f0f0f0"
															: "white",
													marginBottom: "10px",
												}}
												actions={[
													<div key={reply.id}>
														<div
															dangerouslySetInnerHTML={{
																__html: reply.content,
															}}
															style={{
																textAlign: "left",
																marginLeft: "1.5vw",
																color: "black",
															}}
														/>
													</div>,
												]}
											>
												<Card.Meta
													title={
														<div
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
															}}
														>
															<Space style={{ marginLeft: "1rem" }}>
																<Avatar
																	style={{ backgroundColor: "#EAF0FC" }}
																	icon={
																		<UserOutlined
																			style={{ color: "#3478F6" }}
																		/>
																	}
																/>
																<p
																	style={{
																		color: "black",
																		fontWeight: "normal",
																		margin: 0,
																	}}
																>
																	{reply.username || reply?.createdBy?.name}
																</p>
															</Space>
															<Space>
																<Button
																	icon={<LikeOutlined />}
																	onClick={() =>
																		handleReaction(message._id, "like")
																	}
																/>
																<Button
																	icon={<DislikeOutlined />}
																	onClick={() =>
																		handleReaction(message._id, "dislike")
																	}
																/>
																<p
																	style={{
																		color: "black",
																		fontWeight: "normal",
																		margin: 0,
																	}}
																>
																	{moment(message.createdAt).fromNow()}
																</p>
															</Space>
														</div>
													}
												/>
											</Card>
										</div>
									))}
							</div>
						)
				)}
			</Card>
		</div>
	);
};

export default ChatInterface;
