import { Button, Flex, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { planerViewStore } from "../../stores/PlannerStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

function LinkTabModel({
	openModal,
	setopenModal,
}: {
	openModal: boolean;
	setopenModal: React.Dispatch<React.SetStateAction<boolean>>;
}): any {
	const [linkName, setLinkName] = useState("");
	const [linkType, setLinkType] = useState("");
	const [linkDescription, setLinkDescription] = useState("");
	const [linkURL, setLinkURL] = useState("");
	const { selectedItemDetails } = planerViewStore();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const handleOpenModal = () => {
		setLinkName("");
		setLinkType("");
		setLinkDescription("");
		setLinkURL("");
		setopenModal(true);
	};

	const handleAddLink = () => {
		const APIUrl = `${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}/links`;
		const data = {
			name: linkName,
			type: linkType,
			description: linkDescription,
			url: linkURL,
		};
		fetch(APIUrl, {
			method: "PUT",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Success:", data);
				setopenModal(false);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<Flex
			justify="right"
			align="center"
			style={{
				padding: "20px 50px 20px 50px",
				margin: "4px 0 0px",
				backgroundColor: "rgb(245, 248, 254)",
			}}
		>
			<Button type="primary" onClick={handleOpenModal}>
				Add Link
			</Button>
			<Modal
				title="Add Link"
				visible={openModal}
				onCancel={() => setopenModal(false)}
				onOk={handleAddLink}
			>
				<Form layout="horizontal">
					<Form.Item
						label="Name"
						required
						rules={[{ required: true, message: "input the name!" }]}
					>
						<Input
							value={linkName}
							onChange={(e) => setLinkName(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label="Type"
						required
						rules={[{ required: true, message: "select the type!" }]}
					>
						<Select value={linkType} onChange={(value) => setLinkType(value)}>
							<Select.Option value="Figma">Figma</Select.Option>
							<Select.Option value="Antd">Antd</Select.Option>
							<Select.Option value="Xflow">Xflow</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Description"
						required
						rules={[{ required: true, message: "input the Description!" }]}
					>
						<Input
							value={linkDescription}
							onChange={(e) => setLinkDescription(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label="URL"
						required
						rules={[{ required: true, message: "input the URL!" }]}
					>
						<Input
							value={linkURL}
							onChange={(e) => setLinkURL(e.target.value)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Flex>
	);
}

export default LinkTabModel;
