// viewsStore.js

// viewsStore.js
import create from "zustand";
import { persist } from "zustand/middleware";

interface ViewsStore {
	PlanSelectedFilter: any[];
	PlanFilterValue: any[];
	radioButtonSelected: string;
	rightClickMenu: boolean;
	sortBy: string;
	isCardView: boolean;
	isEditModalOpen: boolean;
	input: string;
	filter: any[];
	ReleasePlanValue: boolean;
	TaskValue: boolean;
	setInput: (value: string) => void;
	setfilter: (value: any[]) => void;
	selectedGroupBy: string;
	open: boolean;
	selectedItemDetails: any;
	FetchPlan: boolean;
	planvalue: any[];
	backlogworkitemmodel: boolean;
	mytaskworkitemmodel: boolean;
	Inviteusermodel: boolean;
	OpenCapacityPlanner: boolean;
	setPlanSelectedFilter: (value: any[]) => void;
	setPlanFilterValue: (value: any[]) => void;
	setOpenCapacityPlanner: (isOpen: boolean) => void;
	setRadioButtonSelected: (value: string) => void;
	setInviteusermodel: (isOpen: boolean) => void;
	setRightClickMenu: (isOpen: boolean) => void;
	setmytaskworkitemmodel: (isOpen: boolean) => void;
	setbacklogworkitemmodel: (isOpen: boolean) => void;
	setListView: () => void;
	setCardView: () => void;
	setSortBy: (sortBy: string) => void;
	setEditModalOpen: (isOpen: boolean) => void;
	setSelectedGroupBy: (groupBy: string) => void;
	setOpen: (isOpen: boolean) => void;
	setSelectedItemDetails: (item: any) => void;
	setReleasePlan: (isOpen: boolean) => void;
	setFetchPlan: (FetchPlan: boolean) => void;
	setplanvalue: (value: any[]) => void;
	setTask: (isOpen: boolean) => void;
	urlData: {
		prevUrl: string;
		newUrl: string;
		screen: string;
	};
	changeUrl: (prevUrl: string, newUrl: string, screen: string) => void;
	newPlan: {
		planId: string;
		active: boolean;
		planName: string;
	};

	setnewplan: (planId: string, active: boolean, planName: string) => void;
}

export const usePlanStore = create(
	persist<ViewsStore>(
		(set) => ({
			PlanSelectedFilter: [],
			PlanFilterValue: [],
			radioButtonSelected: "MyTasks",
			rightClickMenu: false,
			sortBy: "None",
			isCardView: false,
			isEditModalOpen: false,
			input: "",
			filter: [],
			ReleasePlanValue: false,
			backlogworkitemmodel: false,
			mytaskworkitemmodel: false,
			FetchPlan: false,
			TaskValue: false,
			Inviteusermodel: false,
			OpenCapacityPlanner: false,
			setPlanSelectedFilter: (value: any[]) =>
				set({ PlanSelectedFilter: value }),
			setPlanFilterValue: (value: any[]) => set({ PlanFilterValue: value }),
			setOpenCapacityPlanner: (isOpen: boolean) =>
				set({ OpenCapacityPlanner: isOpen }),
			setRadioButtonSelected: (value: string) =>
				set({ radioButtonSelected: value }),
			setInviteusermodel: (isOpen: boolean) => set({ Inviteusermodel: isOpen }),
			setRightClickMenu: (isOpen: boolean) => set({ rightClickMenu: isOpen }),
			setmytaskworkitemmodel: (isOpen: boolean) =>
				set({ mytaskworkitemmodel: isOpen }),
			setbacklogworkitemmodel: (isOpen: boolean) =>
				set({ backlogworkitemmodel: isOpen }),
			setSortBy: (sortBy: string) => set({ sortBy: sortBy }),
			setListView: () => set({ isCardView: false }),
			setCardView: () => set({ isCardView: true }),
			// setEditModalOpen: () => set({isEditModalOpen:true}),
			setEditModalOpen: (isOpen: boolean) => set({ isEditModalOpen: isOpen }),
			setInput: (value: string) => set({ input: value }),
			setfilter: (value: any[]) => set({ filter: value }),
			selectedGroupBy: "None",
			open: false,
			selectedItemDetails: null,
			planvalue: [],
			setSelectedGroupBy: (groupBy: string) =>
				set({ selectedGroupBy: groupBy }),
			setOpen: (isOpen: boolean) => set({ open: isOpen }),
			setSelectedItemDetails: (item: any) => set({ selectedItemDetails: item }),
			setReleasePlan: (isOpen: boolean) => set({ ReleasePlanValue: isOpen }),
			setFetchPlan: (isopen: boolean) => set({ FetchPlan: isopen }),
			setplanvalue: (value: any[]) => set({ planvalue: value }),
			setTask: (isOpen: boolean) => set({ TaskValue: isOpen }),
			urlData: {
				prevUrl: "",
				newUrl: "",
				screen: "",
			},
			changeUrl: (prevUrl, newUrl, screen) => {
				set((state) => ({
					...state,
					urlData: {
						...state.urlData,
						prevUrl: prevUrl,
						newUrl: newUrl,
						screen: screen,
					},
				}));
			},
			newPlan: {
				planId: "",
				active: false,
				planName: "",
			},
			setnewplan: (planId: string, active: boolean, planName: string) => {
				set((state) => ({
					newPlan: {
						...state.newPlan,
						planId: planId,
						active: active,
						planName: planName,
					},
				}));
			},
		}),
		{
			name: "plan-store",
		}
	)
);

// import create from "zustand";

// interface ViewsStore {
// 	radioButtonSelected: string;
// 	rightClickMenu: boolean;
// 	sortBy: string;
// 	isCardView: boolean;
// 	isEditModalOpen: boolean;
// 	input: string;
// 	filter: any[];
// 	ReleasePlanValue: boolean;
// 	TaskValue: boolean;
// 	setInput: (value: string) => void;
// 	setfilter: (value: any[]) => void;
// 	selectedGroupBy: string;
// 	open: boolean;
// 	selectedItemDetails: any;
// 	FetchPlan: boolean;
// 	planvalue: any[];
// 	backlogworkitemmodel: boolean;
// 	mytaskworkitemmodel: boolean;
// 	Inviteusermodel: boolean;
// 	OpenCapacityPlanner: boolean;
// 	setOpenCapacityPlanner: (isOpen: boolean) => void;
// 	setRadioButtonSelected: (value: string) => void;
// 	setInviteusermodel: (isOpen: boolean) => void;
// 	setRightClickMenu: (isOpen: boolean) => void;
// 	setmytaskworkitemmodel: (isOpen: boolean) => void;
// 	setbacklogworkitemmodel: (isOpen: boolean) => void;
// 	setListView: () => void;
// 	setCardView: () => void;
// 	setSortBy: (sortBy: string) => void;
// 	setEditModalOpen: (isOpen: boolean) => void;
// 	setSelectedGroupBy: (groupBy: string) => void;
// 	setOpen: (isOpen: boolean) => void;
// 	setSelectedItemDetails: (item: any) => void;
// 	setReleasePlan: (isOpen: boolean) => void;
// 	setFetchPlan: (FetchPlan: boolean) => void;
// 	setplanvalue: (value: any[]) => void;
// 	setTask: (isOpen: boolean) => void;
// 	urlData: {
// 		prevUrl: string;
// 		newUrl: string;
// 		screen: string;
// 		changeUrl: (prevUrl: string, newUrl: string, screen: string) => void;
// 	};
// 	newPlan:{
// 		planId: string;
// 		active: boolean;
// 		setnewplan: (planId: string, active: boolean) => void;
// 	};
// }

// export const usePlanStore = create<ViewsStore>((set) => ({
// 	radioButtonSelected: "MyTasks",
// 	rightClickMenu: false,
// 	sortBy: "None",
// 	isCardView: false,
// 	isEditModalOpen: false,
// 	input: "",
// 	filter: [],
// 	ReleasePlanValue: false,
// 	backlogworkitemmodel: false,
// 	mytaskworkitemmodel: false,
// 	FetchPlan: false,
// 	TaskValue: false,
// 	Inviteusermodel: false,
// 	OpenCapacityPlanner: false,
// 	setOpenCapacityPlanner: (isOpen: boolean) =>
// 		set({ OpenCapacityPlanner: isOpen }),
// 	setRadioButtonSelected: (value: string) =>
// 		set({ radioButtonSelected: value }),
// 	setInviteusermodel: (isOpen: boolean) => set({ Inviteusermodel: isOpen }),
// 	setRightClickMenu: (isOpen: boolean) => set({ rightClickMenu: isOpen }),
// 	setmytaskworkitemmodel: (isOpen: boolean) =>
// 		set({ mytaskworkitemmodel: isOpen }),
// 	setbacklogworkitemmodel: (isOpen: boolean) =>
// 		set({ backlogworkitemmodel: isOpen }),
// 	setSortBy: (sortBy: string) => set({ sortBy: sortBy }),
// 	setListView: () => set({ isCardView: false }),
// 	setCardView: () => set({ isCardView: true }),
// 	// setEditModalOpen: () => set({isEditModalOpen:true}),
// 	setEditModalOpen: (isOpen: boolean) => set({ isEditModalOpen: isOpen }),
// 	setInput: (value: string) => set({ input: value }),
// 	setfilter: (value: any[]) => set({ filter: value }),
// 	selectedGroupBy: "None",
// 	open: false,
// 	selectedItemDetails: null,
// 	planvalue: [],
// 	setSelectedGroupBy: (groupBy: string) => set({ selectedGroupBy: groupBy }),
// 	setOpen: (isOpen: boolean) => set({ open: isOpen }),
// 	setSelectedItemDetails: (item: any) => set({ selectedItemDetails: item }),
// 	setReleasePlan: (isOpen: boolean) => set({ ReleasePlanValue: isOpen }),
// 	setFetchPlan: (isopen: boolean) => set({ FetchPlan: isopen }),
// 	setplanvalue: (value: any[]) => set({ planvalue: value }),
// 	setTask: (isOpen: boolean) => set({ TaskValue: isOpen }),
// 	urlData: {
// 		prevUrl: "",
// 		newUrl: "",
// 		screen: "",
// 		changeUrl: (prevUrl: string, newUrl: string, screen: string) => {
// 			set((state) => ({
// 				urlData: {
// 					...state.urlData,
// 					prevUrl: prevUrl,
// 					newUrl: newUrl,
// 					screen: screen,
// 				},
// 			}));
// 		},
// 	},
// 	newPlan: {
// 		planId: "",
// 		active: false,
// 		setnewplan: (planId: string, active: boolean) => {
// 			set((state) => ({
// 				newPlan: {
// 					...state.newPlan,
// 					planId: planId,
// 					active: active,
// 				},
// 			}));
// 		},
// 	},
// }));
