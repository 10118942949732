import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { Alert, Button, Flex, Spin, message } from "antd";
import { SendOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useRequest } from "ahooks";
import { getRitualSession, updateRitualSession } from "../../../api/RitualApi";

const RitualNotes = () => {
	const [notes, setNotes] = useState("");
	const [data, setData] = useState<any>();
	const [originalNotes, setOriginalNotes] = useState("");
	const { itemId } = useParams();
	const [alertMessage, setAlertMessage] = useState<string | null>(null);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const {
		run: fetchData,
		loading,
		error,
	} = useRequest(
		() => {
			const token = userProfileStoreInstance.idToken;
			return getRitualSession(itemId ?? "", token ?? "");
		},
		{
			manual: true,
			onSuccess: (result) => {
				setData(result);
				setNotes(result.notes || "");
				setOriginalNotes(result.notes || "");
			},
			onError: (err) => {
				message.error("Error fetching item: " + err.message);
			},
		}
	);

	useEffect(() => {
		fetchData();
	}, [itemId, fetchData]);

	const { run: sendRequest } = useRequest(
		async () => {
			const newNotesContent = notes.replace(originalNotes, "").trim();
			const updatedNotes = data.notes
				? `${data.notes}\n${newNotesContent}`
				: newNotesContent;

			const updatedData = { ...data, notes: updatedNotes };
			await updateRitualSession(
				itemId ?? "",
				updatedData,
				userProfileStoreInstance.idToken ?? ""
			);
		},
		{
			onSuccess: () => {
				fetchData();
				setAlertMessage("Notes added successfully");
				setNotes("");
				setTimeout(() => {
					setAlertMessage(null);
				}, 3000);
			},
			onError: (err) => {
				console.error("Error updating Notes:", err);
			},
		}
	);

	const handleSend = async () => {
		try {
			await sendRequest();
		} catch (error) {
			console.error("Error updating Notes: ", error);
			setAlertMessage("Failed to add Notes");
		}
	};

	const handleNotesChange = (content: React.SetStateAction<string>) => {
		setNotes(content);
	};

	// const removePTags = (content: string) => {
	// 	return content.replace(/<\/?p>/g, "");
	// };

	return (
		<div
			style={{
				maxWidth: "1000px",
				width: "100%",
				textAlign: "left",
				overflow: "auto",
				maxHeight: "500px",
			}}
		>
			{!loading && !error && (
				<Flex style={{ width: "100%" }}>
					<div style={{ width: "100%" }}>
						<ReactQuill value={notes} onChange={handleNotesChange} />
						<div
							style={{
								display: "flex",
								justifyContent: "end",
								alignItems: "flex-end",
							}}
						>
							<Button
								style={{
									margin: "0.7rem 1rem 0 0rem",
									padding: "0.4rem  0.5rem",
								}}
								icon={<SendOutlined style={{ margin: 0 }} />}
								type="primary"
								onClick={handleSend}
							>
								Send
							</Button>
						</div>
						<div>
							{alertMessage && (
								<Alert
									message={alertMessage}
									type="success"
									showIcon
									closable
									onClose={() => setAlertMessage(null)}
								/>
							)}
							{loading && <Spin />} {/* Display loading indicator */}
							{error && (
								<Alert
									message="Error"
									description={error}
									type="error"
									closable
									onClose={() => setAlertMessage(null)}
								/>
							)}
						</div>

						{/* {data && data.notes && data.notes.trim().length > 0 && (
						<div style={{ marginTop: "1rem" }}>
							<h3>Notes:</h3>
							<ul>
								{data.notes.split("\n").map((note: string, index: React.Key | null | undefined) => {
									const cleanedNote = removePTags(note);
									return <li key={index}>{cleanedNote}</li>;
								})}
							</ul>
						</div>
					)} */}
					</div>
				</Flex>
			)}
		</div>
	);
};

export default RitualNotes;
