// LoaderPage.tsx
import { Image, Modal, Spin, Typography } from "antd";
import "./LoaderScreen.css";
import { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { useNavigate } from "react-router-dom";

const LoaderPage: React.FC = () => {
	const navigate = useNavigate();
	const ServerLink = process.env.REACT_APP_API_URL;
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [task, setTask] = useState<string>("completed");
	const [isTasksCompleted, setIsTasksCompleted] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const fetchTasksData = async () => {
			try {
				const response = await fetch(
					`${ServerLink}/tasks/activity?activity_type=FIRST_TIME_USER_FEEDBACK`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				const task = await response.json();
				console.log("tasksand", task);
				// userProfileStoreInstance.setProfile(task[0].user_profile_id);
				setTask(task[0].status);
			} catch (error) {
				console.error(error);
			}
		};

		fetchTasksData();
		setIsTasksCompleted(task === "open");
		if (isTasksCompleted && isModalOpen === false) {
			const delay = 100;

			const timer = setTimeout(() => {
				navigate("/feed");
			}, delay);

			return () => clearTimeout(timer);
		}
		if (!isTasksCompleted && isModalOpen === false) {
			const delay = 100;

			const timer = setTimeout(() => {
				navigate("/");
			}, delay);

			return () => clearTimeout(timer);
		}
	}, [isTasksCompleted, task, isModalOpen]);

	const [Invitedata, setInvitedata] = useState<any>([]);
	// const [isTasksCompleted, setIsTasksCompleted] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async () => {
		try {
			const data = { ...Invitedata[0], status: "accepted" };

			// First fetch request
			const response1 = await fetch(
				`${ServerLink}/invite-user/${Invitedata[0]._id}`,
				{
					method: "PUT",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						account_Id: data.account_Id,
						email: data.email,
						status: data.status,
						workspace_Id: data.workspace_Id,
					}),
				}
			);

			const responseData1 = await response1.json();
			console.log("after put", responseData1);

			if (responseData1.status === "pending") {
				showModal();
			}

			// Second fetch request, triggered only if the first one is successful
			const response2 = await fetch(`${ServerLink}/user-profiles/user/email`, {
				method: "PATCH",
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					account_Id: data.account_Id,
				}),
			});

			const responseData2 = await response2.json();
			console.log("after patch", responseData2);
		} catch (err) {
			console.error(err);
		} finally {
			setIsModalOpen(false);
		}
	};

	const handleCancel = async () => {
		try {
			const data = { ...Invitedata[0], status: "rejected" };
			const response = await fetch(
				`${ServerLink}/invite-user/${Invitedata[0]._id}`,
				{
					method: "PUT",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						"Content-Type": "application/json", // Add Content-Type header
					},
					body: JSON.stringify({
						account_Id: data.account_Id,
						email: data.email,
						status: data.status,
						workspace_Id: data.workspace_Id,
					}),
				}
			);

			const responseData = await response.json();
			console.log("after put", responseData);

			if (responseData.status === "pending") {
				showModal();
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsModalOpen(false);
		}
	};

	useEffect(() => {
		fetch(`${ServerLink}/invite-user/user/email`, {
			headers: {
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				setInvitedata(data);
				if (data[0].status === "pending") {
					showModal();
				}
			})
			.catch((err) => console.error(err));
	}, []);

	return (
		<div className="loader-container">
			<Modal
				title="Invitation"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Typography.Text>You have a invitation for a workspace</Typography.Text>
				<Typography.Text>Do you want to accept it?</Typography.Text>
			</Modal>
			{!isModalOpen && (
				<>
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
					/>
					<Spin size="large" />
				</>
			)}
		</div>
	);
};

export default LoaderPage;
