import React, { useState } from "react";
import { Card, Col, Divider, Empty, Row, Tabs, Tag, Typography } from "antd";

import { planerViewStore } from "../../stores/PlannerStore";

import TabPane from "antd/es/tabs/TabPane";

const UserStoryQuickView = () => {
	//const navigate = useNavigate();

	//const { setEditModalOpen, selectedItemDetails, setOpen } = useViewsStore();
	const { selectedItemDetails } = planerViewStore();

	// const handleDrawerCancel = () => {
	// 	setOpen(false);
	// 	setEditModalOpen(true);
	// 	navigate("/space/planner/edit");
	// };
	const [clickedKeyValue, setClickedKeyValue] = useState<string | null>(null);
	const handleKeyClick = (key: any) => {
		setClickedKeyValue(key);
	};
	const formatText = (text: any) => {
		const words = text?.split("_");
		const formattedWords = words?.map(
			(word: any) => word.charAt(0).toUpperCase() + word.slice(1)
		);
		const formattedText = formattedWords?.join(" ");
		return formattedText;
	};
	return (
		<div>
			{/* <Flex style={{ alignItems: "center" }} gap={20}> */}
			{/* <Flex>
					<EditOutlined onClick={handleDrawerCancel} />
				</Flex>
				<Flex style={{}}>
					<h3>{selectedItemDetails?.title}</h3>
				</Flex> */}
			{/* <Flex style={{ height: "max-content" }}>
					<Tag>{selectedItemDetails?.story}</Tag>
				</Flex>
			</Flex> */}
			<div style={{ textAlign: "left" }}>
				<p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
					dangerouslySetInnerHTML={{ __html: selectedItemDetails?.description }}
				></p>

				{/* <p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
				>
					Type: {selectedItemDetails?.type}
				</p> */}

				<Row gutter={16}>
					<Col className="gutter-row" span={4}>
						<div>Status</div>
					</Col>
					<Col className="gutter-row" span={8}>
						<Tag color="red" style={{ textAlign: "left" }}>
							{selectedItemDetails?.status}
						</Tag>
					</Col>
					<Col className="gutter-row" span={4}>
						<div>Priority</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<Tag color="pink" style={{ textAlign: "left" }}>
							{selectedItemDetails?.priority}
						</Tag>
					</Col>
				</Row>

				{/* <p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
				>
					Stage: {selectedItemDetails?.stage}
				</p> */}
			</div>
			<Divider />
			{selectedItemDetails?.data && (
				<>
					<Card style={{ textAlign: "left" }}>
						<Tabs
							tabPosition="right"
							type="card"
							defaultActiveKey={"1"}
							onChange={(key) => handleKeyClick(key)}
							style={{ height: "40vh", width: "50vw" }}
						>
							{Object.keys(selectedItemDetails?.data).map((key) => (
								<TabPane
									style={{ marginTop: "-3rem", marginLeft: "1rem" }}
									tab={formatText(key)}
									key={key}
								>
									<div className="AcceptanceCriteria">
										{clickedKeyValue && (
											<div
												style={{ height: "39vh", overflowY: "auto" }}
												// className={contentClassName}
												// onContextMenu={handleRightClick}
												// data-path={`/fe/${selectedMenuItem}/${selectUserStorys}/${clickedKeyValue}`}
											>
												<div
													//data-path={`/fe/${selectedMenuItem}/${selectUserStorys}/${clickedKeyValue}`}
													className="Darkcolor"
												>
													{formatText(clickedKeyValue)}
												</div>
												{Array.isArray(
													selectedItemDetails?.data[clickedKeyValue]
												) ? (
													<ul
														//data-path={`/fe/${selectedMenuItem}/${selectUserStorys}/${clickedKeyValue}`}
														className="AcceptanceCriteriaList"
													>
														{selectedItemDetails?.data[clickedKeyValue]
															.length == 0 && <Empty />}
														{selectedItemDetails?.data[clickedKeyValue] &&
															selectedItemDetails?.data[clickedKeyValue].map(
																(item: any, index: any) => (
																	<li key={index}>
																		{typeof item === "object" ? (
																			Object.entries(item).map(
																				([key, value]: [string, any]) => (
																					<div key={key}>
																						<div>{key}</div>
																						{Array.isArray(value) ? (
																							<ul>
																								{value.map(
																									(element, elementIndex) => (
																										<li key={elementIndex}>
																											{element}
																										</li>
																									)
																								)}
																							</ul>
																						) : (
																							<div>{value}</div>
																						)}
																					</div>
																				)
																			)
																		) : (
																			<div>{item}</div>
																		)}
																	</li>
																)
															)}
													</ul>
												) : typeof selectedItemDetails?.data[
														clickedKeyValue
												  ] === "string" ? (
													<Typography.Text>
														{selectedItemDetails?.data[clickedKeyValue]}
													</Typography.Text>
												) : typeof selectedItemDetails?.data[
														clickedKeyValue
												  ] === "number" ? (
													<Typography.Text>
														{selectedItemDetails?.data[clickedKeyValue]}
													</Typography.Text>
												) : typeof selectedItemDetails?.data[
														clickedKeyValue
												  ] === "object" ? (
													/* Render the object content as needed */
													<pre>
														{JSON.stringify(
															selectedItemDetails?.data[clickedKeyValue],
															null,
															2
														)}
													</pre>
												) : null}
											</div>
										)}
									</div>
								</TabPane>
							))}
						</Tabs>
					</Card>
					{/* </Flex> */}
				</>
			)}
		</div>
	);
};

export default UserStoryQuickView;
