import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Form, Typography, Select } from "antd";
import "./EditWorkspace.css";

import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { CloseCircleOutlined } from "@ant-design/icons";
import useWorkspaceStore from "../stores/WorkspaceState";
//startsWithimport Constants from "../util/ConstantsUtil";
import { useNavigate } from "react-router-dom";
import operation_done from "../assets/operation_done.svg";
import operation_failed from "../assets/operation_failed.svg";

const CreateWorkpaceModal: React.FC<any> = ({
	isModalVisible,
	handleModalCancel,
	//workspaceId,
}) => {
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [form] = Form.useForm();

	const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };

	const buttonItemLayout = { wrapperCol: { span: 2, offset: 19 } };

	const fetchWorkspaceData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const existingWorkspace = await response.json();

			form.setFieldsValue({
				workspaceName: existingWorkspace.name,
				checkbox: existingWorkspace.modules,
			});
		} catch (error) {
			console.error("Error fetching workspace data:", error);
		}
	};

	const { setAddWorkspaceDetails, addWorkspaceDetails } = useWorkspaceStore(
		(state: any) => ({
			addWorkspaceDetails: state.addWorkspaceDetails,
			setAddWorkspaceDetails: state.setAddWorkspaceDetails,
		})
	);

	useEffect(() => {
		if (isModalVisible) {
			fetchWorkspaceData();
		}
	}, [isModalVisible]);

	const prepareWorkspace = (values: any) => {
		const modules: any[] = [];
		const currentModule = "test";
		const currentSection = "test";
		// if (addWorkspaceDetails.isBusinessEngineering) {
		// 	modules.push(Constants.BUSINESS_ENGINEERING);
		// 	currentModule = Constants.BUSINESS_ENGINEERING;
		// 	currentSection = "";
		// }
		// if (addWorkspaceDetails.isFeatureEngineering) {
		// 	modules.push(Constants.FEATURE_ENGINEERING);
		// 	currentModule = Constants.FEATURE_ENGINEERING;
		// 	currentSection = Constants.FE_FEATURE_LIST;
		// }
		// if (addWorkspaceDetails.isDesignEngineering) {
		// 	modules.push(Constants.DESIGN_ENGINEERING);
		// 	currentModule = Constants.DESIGN_ENGINEERING;
		// 	currentSection = Constants.DE_USER_PERSONA;
		// }
		// if (addWorkspaceDetails.isCodeEngineering) {
		// 	modules.push(Constants.CODE_ENGINEERING);
		// 	currentModule = Constants.CODE_ENGINEERING;
		// 	currentSection = "";
		// }
		return {
			name: values.workspaceName,
			modules: modules,
			productInfo: {},
			currentModule: currentModule,
			currentSection: currentSection,
		};
	};

	const [modal, contextHolder] = Modal.useModal();

	const countDown = (message: any, status: any) => {
		let secondsToGo = 5;
		let instance: any;

		if (status === "fail") {
			instance = modal.error({
				title: "Error!",
				content: (
					<div>
						<Typography.Text>{message}</Typography.Text>
						<img
							src={operation_failed}
							alt="Error Image"
							style={{ maxWidth: "100%" }}
						/>
					</div>
				),
			});
		} else {
			instance = modal.success({
				title: "Success!",
				content: (
					<div>
						<Typography.Text>{message}</Typography.Text>
						<img
							src={operation_done}
							alt="Success Image"
							style={{ maxWidth: "100%" }}
						/>
					</div>
				),
			});
		}

		const timer = setInterval(() => {
			secondsToGo -= 1;
			//   instance.update({
			// 	content: (
			// 	  <div>
			// 		<p>{message}</p>
			// 		<img src={imageUrl} alt="Image" style={{ maxWidth: '100%' }} />
			// 	  </div>
			// 	),
			//   });
		}, 1000);

		setTimeout(() => {
			clearInterval(timer);
			instance.destroy();
		}, secondsToGo * 1000);
	};

	const [confirmLoading, setConfirmLoading] = useState(false);

	const onFinish = (values: any) => {
		setConfirmLoading(true);
		setAddWorkspaceDetails({
			...addWorkspaceDetails,
			workspaceName: values.workspaceName,
		});
		const workspace = prepareWorkspace(values);
		fetch(`${process.env.REACT_APP_API_URL}/workspace`, {
			method: "POST",
			body: JSON.stringify(workspace),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				if (json) {
					countDown(
						`Workspace ${values.workspaceName} created successfully. Redirecting to home page`,
						"pass"
					);
					setTimeout(() => {
						setConfirmLoading(false);
						navigate("/");
						setAddWorkspaceDetails(null);
					}, 3000);
				} else {
					countDown(
						"We could not create your workspace. Please try again later",
						"fail"
					);
					setTimeout(() => {
						setConfirmLoading(false);
						navigate("/");
						setAddWorkspaceDetails(null);
					}, 3000);
				}
			})
			.catch((error) => {
				console.error("Error updating workspace:", error);
			});
	};

	const [disabledSave, setDisabledSave] = useState(true);

	const handleFormChange = () => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
		setDisabledSave(hasErrors);
	};

	return (
		<Modal
			width={"50%"}
			closeIcon={<CloseCircleOutlined style={{ fontSize: "24px" }} />}
			title="Create Workspace"
			visible={isModalVisible}
			onCancel={handleModalCancel}
			// onOk={onFinish}
			footer={null}
			className="modal"
			confirmLoading={confirmLoading}
		>
			{contextHolder}
			<Form
				onFieldsChange={handleFormChange}
				form={form}
				onFinish={onFinish}
				{...formItemLayout}
				layout="horizontal"
				style={{ marginTop: "32px" }}
			>
				<Form.Item
					name="workspaceName"
					label="Workspace Name"
					colon={false}
					rules={[
						{ required: true, message: "Workspace name is required" },
						{ min: 5 },
					]}
				>
					<Input placeholder="Enter Workspace Name" />
				</Form.Item>
				<Form.Item
					name="workspaceType"
					label="Workspace Type"
					colon={false}
					rules={[{ required: true, message: "Workspace type is required" }]}
				>
					<Select placeholder="Select Workspace Type">
						<Select.Option value="appDev">App Development</Select.Option>
						<Select.Option value="webDev">Web Development</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item {...buttonItemLayout}>
					<Button
						type="primary"
						htmlType="submit"
						disabled={disabledSave}
						loading={confirmLoading}
					>
						Submit
					</Button>
				</Form.Item>

				{/* <div className="button-container">
					<Button
						type="default"
						style={{ marginRight: "10px" }}
						onClick={handleModalCancel}
					>
						Share
					</Button>
					<Button type="primary" htmlType="submit">
						Done
					</Button>
				</div> */}
			</Form>
		</Modal>
	);
};

export default CreateWorkpaceModal;
