import React, { useEffect, useState } from "react";
import { Row, message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { planerViewStore } from "../stores/PlannerStore";
import { useRequest } from "ahooks";
import { fetchWorkitemByType } from "../api/WorkItemAPI";

const AssignedBug = () => {
	const [data, setData] = useState<any[]>([]);
	const [workItemData, setWorkItemData] = useState<any[]>([]);
	console.log("workItemData", workItemData);
	const { FetchPlan } = planerViewStore();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { data: workitemBugData, run: fetchBug } = useRequest(
		(worspaceId, type) =>
			fetchWorkitemByType(worspaceId, type, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Bug data");
				message.success(`Bugs fetched successfully): ${result}`);
			},
			onError: (error) => {
				message.error(`Failed to fetch Bug : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemBugData) {
			setData(workitemBugData);
			const data = workitemBugData.map(({ type, WorkitemId }) => ({
				type,
				typeId: WorkitemId,
			}));
			setWorkItemData(data);
		}
	}, [workitemBugData]);

	useEffect(() => {
		fetchBug(currentWorkspace?._id, "Bug");
	}, [open, FetchPlan]);

	const renderCount = (filterType: string) => (
		<p
			style={{
				fontSize: "28px",
				fontWeight: "bold",
				fontFamily: "Open Sans",
				marginLeft: "8vw",
			}}
		>
			Count: {data.filter((item) => item.type === filterType).length}
		</p>
	);

	return (
		<Row className="row" gutter={20} justify="start" align="top" wrap={true}>
			{renderCount("BUG")}
		</Row>
	);
};

export default AssignedBug;
