/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Layout, Flex, Spin, Image, message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

import { TaskStore } from "../../stores/TasksStore";
//import MyTaskListViewItem from "./MyTaskListView";
import DrawerComponent from "./Drawer";
import KanbanBoard from "./CardView";
import { EyeOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import TaskDrawerPlanerComponent from "./MyTaskDrawer";
import MyTaskNav from "./MyTasksNav";
import useWorkspaceStore from "../../stores/WorkspaceState";
import WorkItemModal from "./AddWorkitemModel";
import { planerViewStore } from "../../stores/PlannerStore";
import { useLocation } from "react-router-dom";
import { useRequest } from "ahooks";
import { getWorkitemByTaskFilter } from "../../api/WorkItemAPI";
import MyTaskListViewItemV2 from "./MyTaskListViewV2";

const { Content } = Layout;

const MyTasks: React.FC = () => {
	const { isCardView, open, setOpen, TaskValue, setTask } = TaskStore();
	const [modalVisible, setModalVisible] = useState(false);
	const [modalVisible1, setModalVisible1] = useState(false);
	const [tasks, setTasks] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [workItemData, setWorkItemData] = useState<any[]>([]);
	const {
		input,
		filter,
		FetchPlan,
		mytaskworkitemmodel,
		setmytaskworkitemmodel,
		TaskFilterValue,
		TaskSelectedFilter,
		selectedGroupBy,
		RowBy,
		CardOrderning,
		setDrawerTab,
		ListSelectedGroupBy,
	} = TaskStore();

	const { selectedItemDetails } = planerViewStore();

	const url = useLocation().pathname;

	const { radioButtonSelected, sortBy, rightClickMenu } = planerViewStore();

	// const { data: workitemTaskData, run: fetchTaskStory } = useRequest(
	// 	(worspaceId, type, assigned) =>
	// 		getWorkItemSForMYTask(
	// 			worspaceId,
	// 			type,
	// 			assigned,
	// 			userProfileStoreInstance.idToken
	// 		),
	// 	{
	// 		manual: true,
	// 		onSuccess: (result) => {
	// 			console.log(result, "Task data");
	// 			message.success(` ${radioButtonSelected} fetched successfully`);
	// 		},
	// 		onError: (error) => {
	// 			message.error(`Failed to fetch Tasks : ${error.message}`);
	// 			// throw new Error(`Fetching tasks failed: ${error.message}`);
	// 		},
	// 	}
	// );

	const { data: workitemTaskData, run: fetchTaskStory } = useRequest(
		() =>
			getWorkitemByTaskFilter(
				currentWorkspace._id,
				TaskFilterValue,
				userProfileStoreInstance
			),
		{
			manual: true,
		}
	);

	useEffect(() => {
		if (workitemTaskData) {
			const sortedResult = workitemTaskData.sort(
				(
					a: { createdAt: string | number | Date },
					b: { createdAt: string | number | Date }
				) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
			);
			setTasks(sortedResult);
			const data = sortedResult.map(
				({ title, _id, type, WorkitemId }: any) => ({
					name: title,
					id: _id,
					type: type,
					typeId: WorkitemId,
				})
			);
			setWorkItemData(data);
			setLoading(false);
		}
	}, [workitemTaskData]);

	useEffect(() => {
		fetchTaskStory();
	}, [
		TaskSelectedFilter,
		FetchPlan,
		rightClickMenu,
		radioButtonSelected,
		mytaskworkitemmodel,
		selectedItemDetails,
	]);

	useEffect(() => {
		setTimeout(() => {
			fetchTaskStory();
		}, 1000);
	}, [url]);

	useEffect(() => {
		setModalVisible1(mytaskworkitemmodel);
	}, [mytaskworkitemmodel]);

	const handleModalCancel = () => {
		setModalVisible1(false);
		setmytaskworkitemmodel(false);
	};

	console.log("firstdata", tasks);

	const sortedTasks = [...tasks].sort((a, b) => {
		if (sortBy === "Created date (Asc)") {
			return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
		} else if (sortBy === "Created date (Desc)") {
			return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
		} else {
			return 0;
		}
	});

	const filteredDataSource = (data: any[]) => {
		return data.filter((item) => {
			let matchesFilter = true;
			const inputLower = input.replace(/\s/g, "").toLowerCase();
			const typeFilters = filter
				.filter((filterItem) => filterItem.startsWith("Type"))
				.map((filterItem) => {
					const typeSplit = filterItem.split("_");
					const type = typeSplit.shift();
					const rest = typeSplit.join("_");
					return rest.toLowerCase();
				});
			const statusFilters = filter
				.filter((filterItem) => filterItem.startsWith("Status"))
				.map((filterItem) => {
					const typeSplit = filterItem.split("_");
					const type = typeSplit.shift();
					const rest = typeSplit.join("_");
					return rest.toLowerCase();
				});
			const priorityFilters = filter
				.filter((filterItem) => filterItem.startsWith("Priority"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());

			matchesFilter =
				matchesFilter &&
				(item?.title.replace(/\s/g, "").toLowerCase().includes(inputLower) ||
					item?.description
						.replace(/\s/g, "")
						.toLowerCase()
						.includes(inputLower) ||
					item?.WorkitemId?.replace(/\s/g, "")
						.toLowerCase()
						.includes(inputLower));

			if (typeFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					typeFilters.some(
						(filterValue) => item?.type?.toLowerCase() === filterValue
					);
			}

			if (statusFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					statusFilters.some(
						(filterValue) =>
							item?.status?.toLowerCase().replace(/_/g, " ") ===
							filterValue.replace(/_/g, " ")
					);
			}

			if (priorityFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					priorityFilters.some(
						(filterValue) => item?.priority?.toLowerCase() === filterValue
					);
			}

			return matchesFilter;
		});
	};

	const handleDrawerCancel = () => {
		setOpen(false);
	};

	return (
		<Layout style={{ background: "#f5f8fe" }}>
			{loading ? (
				<div style={{ textAlign: "center", marginTop: "30vh" }}>
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
					/>
					<Spin size="large" />
				</div>
			) : (
				<>
					<MyTaskNav />
					<Layout style={{ background: "#f5f8fe" }}>
						{/* <Content style={{ backgroundColor: "#f5f8fe" }}>
							<Flex
								justify="center"
								style={{
									backgroundColor: "white",
									margin: "1rem",
									padding: "1rem",
									overflow: "scroll",
									height: "68vh",
									maxWidth: "100vw",
									display: "block",
									position: "relative",
								}}
								className="WorkspaceDiv"
							>
								{isCardView ? (
									<KanbanBoard
										Data={filteredDataSource(sortedTasks)}
									></KanbanBoard>
								) : (
									// <MyTaskListViewItem
									// 	Data={filteredDataSource(sortedTasks)}
									// ></MyTaskListViewItem>
									<MyTaskListViewItemV2
										Data={filteredDataSource(sortedTasks)}
									></MyTaskListViewItemV2>
								)}
							</Flex>
						</Content> */}
						{isCardView ? (
							<KanbanBoard
								Data={filteredDataSource(sortedTasks)}
								selectedGroupBy={selectedGroupBy}
								RowBy={RowBy}
								CardOrderning={CardOrderning}
							></KanbanBoard>
						) : (
							// <MyTaskListViewItem
							// 	Data={filteredDataSource(sortedTasks)}
							// ></MyTaskListViewItem>
							<MyTaskListViewItemV2
								Data={filteredDataSource(sortedTasks)}
								selectedGroupBy={ListSelectedGroupBy}
								setDrawerTab={setDrawerTab}
							></MyTaskListViewItemV2>
						)}
					</Layout>
					<TaskDrawerPlanerComponent
						visible={modalVisible}
						onClose={handleModalCancel}
						data={"data"}
					/>
					<WorkItemModal
						visible={modalVisible1}
						onCancel={handleModalCancel}
						data={workItemData}
						screen={"mytask"}
					/>
				</>
			)}
		</Layout>
	);
};

export default MyTasks;
