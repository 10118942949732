/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-prototype-builtins */
import { Flex } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Column, Tasks } from "./type";
import ColumnContainer from "./CardColumn";
import {
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import DraggableCard from "./CardComponent";
// import { planerViewStore } from "../../stores/PlannerStore";
import { useViewsStores } from "../../stores/BacklogStore";
import { motion } from "framer-motion";

function KanbanBoard({
	Data,
	selectedGroupBy,
	RowBy,
	CardOrderning,
}: {
	Data: any[];
	selectedGroupBy: any;
	RowBy: any;
	CardOrderning: any;
}) {
	// const { selectedGroupBy, RowBy, CardOrderning } = useViewsStores();

	const [column, setColumn] = useState<Column[]>([]);
	const columnId = useMemo(() => column.map((col) => col.id), [column]);
	const [activeTask, setActiveTask] = useState<Tasks | null>(null);
	const [tasks, setTasks] = useState<Tasks[]>(Data);

	// const setColumnState = (selectedMenuItem: string) => {
	// 	let groupedColumns;
	// 	switch (selectedMenuItem) {
	// 		case "Type":
	// 			groupedColumns = groupBy(tasks, "type");
	// 			break;
	// 		case "None":
	// 			groupedColumns = groupBy(tasks, "status");
	// 			break;
	// 		case "Priority":
	// 			groupedColumns = groupBy(tasks, "priority");
	// 			break;
	// 		case "Status":
	// 			groupedColumns = groupBy(tasks, "status");
	// 			break;
	// 		default:
	// 			groupedColumns = groupBy(tasks, "status");
	// 	}

	// 	const columns = Object.entries(groupedColumns).map(([groupKey]) => ({
	// 		id: groupKey,
	// 		title: `${groupKey} `,
	// 	}));
	// 	setColumn(columns);
	// };

	// useEffect(() => {
	// 	setColumnState(selectedGroupBy);
	// }, [selectedGroupBy]);

	// useEffect(()=>{
	// 	setTasks(Data);
	// 	setColumnState(selectedGroupBy);
	// },[Data,selectedGroupBy]);

	const Possiblevalueoftypes = [
		{
			key: "type",
			label: "type",
			children: ["FEATURE", "USER_STORY", "TEST", "WORKFLOW_TASK", "BUG"],
		},
		{
			key: "Priority",
			label: "priority",
			children: ["MEDIUM", "IMPORTANT", "URGENT", "Low"],
		},
		{
			key: "Status",
			label: "status",
			children: ["OPEN", "COMPLETED", "IN_PROGRESS"],
		},
	];

	const getChildrenByKey = (key: any) => {
		const item: any = Possiblevalueoftypes.find(
			(element) => element.label === key
		);
		return item ? item : [];
	};

	const sortTasks = (tasks: Tasks[]) => {
		console.log("tasks", tasks);
		return tasks.sort((a, b) => {
			if (CardOrderning === "priority") {
				const priorityA = a.priority || "";
				const priorityB = b.priority || "";
				const priorityCompare = priorityA.localeCompare(priorityB);
				if (priorityCompare !== 0) return priorityCompare;
			}
			if (CardOrderning === "status") {
				const statusA = a.status || "";
				const statusB = b.status || "";
				const statusCompare = statusA.localeCompare(statusB);
				if (statusCompare !== 0) return statusCompare;
			}
			if (CardOrderning === "assignee") {
				const assigneeA = a.assignee ? a.assignee.name : "z";
				const assigneeB = b.assignee ? b.assignee.name : "z";
				return assigneeA.localeCompare(assigneeB);
			}
			// Default fallback if no condition matches
			return 0;
		});
	};

	const setColumnState = useCallback(
		(selectedMenuItem: string) => {
			let groupedColumns: any;
			switch (selectedMenuItem) {
				case "Type":
					groupedColumns = groupBy(tasks, "type", RowBy);
					break;
				case "Priority":
					groupedColumns = groupBy(tasks, "priority", RowBy);
					break;
				case "Status":
				case "None":
				default:
					groupedColumns = groupBy(tasks, "status", RowBy);
			}
			const columns = Object.entries(groupedColumns)
				.map(([groupKey, group]: [any, any]) => {
					const children = getChildrenByKey(RowBy).children.map(
						(value: any) => {
							const subGroup = group.find(
								(subGroup: any) =>
									subGroup.key.toLowerCase() === value.toLowerCase()
							);
							return subGroup
								? {
										parent: groupKey,
										key: subGroup.key,
										title: `${subGroup.key}`,
										value: sortTasks(subGroup.value),
									}
								: {
										parent: value,
										key: value,
										title: `${value + " (0) "}`,
										value: [],
									};
						}
					);
					return {
						id: groupKey,
						title: `${groupKey}`,
						children: children.sort((a: any, b: any) =>
							a.title.localeCompare(b.title)
						),
					};
				})
				.filter((column) => column.id !== "pending");

			// Exclude pending column
			setColumn(columns);
		},
		[tasks]
	);

	useEffect(() => {
		setColumnState(selectedGroupBy);
	}, [selectedGroupBy, setColumnState]);

	useEffect(() => {
		setTasks(Data);
	}, [Data]);

	const [activeColumn, setActiveColumn] = useState<Column | null>(null);
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 10,
			},
		})
	);

	return (
		<Flex
			className="m-auto
     flex 
     min-h-screen 
     w-full 
     items-center 
     overflow-x-auto 
     overflow-y-hidden"
		>
			<DndContext
				sensors={sensors}
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				onDragOver={onDragOver}
			>
				<Flex className="n-auto flex gap-4" style={{ overflow: "scroll" }}>
					<Flex className="flex gap-4 ">
						<SortableContext items={columnId}>
							{column.map((col) => (
								<ColumnContainer
									key={col.id}
									column={col}
									tasks={tasks.filter((task) => {
										switch (selectedGroupBy) {
											case "Type":
												return task.type === col.id;
											case "Priority":
												return task.priority === col.id;
											case "Status":
												return (
													task.status === col.id && task.status !== "pending"
												);
											default:
												return (
													task.status === col.id && task.status !== "pending"
												);
										}
									})}
								/>
							))}
						</SortableContext>
					</Flex>
				</Flex>
				{createPortal(
					<DragOverlay>
						{activeColumn && (
							<ColumnContainer
								column={activeColumn}
								tasks={tasks.filter((task) => {
									switch (selectedGroupBy) {
										case "Type":
											return task.type === activeColumn.id;
										case "Priority":
											return task.priority === activeColumn.id;
										case "Status":
											return task.status === activeColumn.id;
										default:
											return task.status === activeColumn.id;
									}
								})}
							/>
						)}

						{/* {activeTask && <DraggableCard task={activeTask} />} */}
						{activeTask && (
							<motion.div
								layout
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								<DraggableCard task={activeTask} />
							</motion.div>
						)}
					</DragOverlay>,
					document.body
				)}
			</DndContext>
		</Flex>
	);

	function onDragStart(event: DragStartEvent) {
		// console.log(event.active.data.current?.type, "Column");
		// console.log("onDragStart", event);
		if (event.active.data.current?.type === "Column") {
			setActiveColumn(event.active.data.current?.column);
		}

		// console.log(event.active.data.current?.type);
		if (event.active.data.current?.type === "Task") {
			setActiveTask(event.active.data.current?.task);
			return;
		}
	}

	function onDragEnd(event: DragEndEvent) {
		setActiveColumn(null);
		setActiveTask(null);

		const { active, over } = event;

		if (!over) return;
		const activeColumnId = active.id;
		const overColumnId = over.id;
		if (activeColumnId === overColumnId) return;
		setColumn((column) => {
			const activeColumnIndex = column.findIndex(
				(col) => col.id === activeColumnId
			);
			const overColumnIndex = column.findIndex(
				(col) => col.id === overColumnId
			);

			return arrayMove(column, activeColumnIndex, overColumnIndex);
		});
	}

	function onDragOver(event: DragOverEvent) {
		const { active, over } = event;
		if (!over) return;

		const activeId = active.id;
		const overId = over.id;

		if (activeId === overId) return;

		const isActiveATask = active.data.current?.type === "Task";
		const isOverATask = over.data.current?.type === "Task";

		if (!isActiveATask) return;

		const dynamicField: string | number = getDynamicField();

		if (isActiveATask && isOverATask) {
			setTasks((tasks) => {
				const activeIndex = tasks.findIndex((t) => t._id === activeId);
				const overIndex = tasks.findIndex((t) => t._id === overId);

				if (
					tasks[activeIndex][dynamicField] !== tasks[overIndex][dynamicField]
				) {
					tasks[activeIndex][dynamicField] = tasks[overIndex][dynamicField];
					return arrayMove(tasks, activeIndex, overIndex - 1);
				}

				return arrayMove(tasks, activeIndex, overIndex);
			});
		}

		const isOverAColumn = over.data.current?.type === "Column";

		if (isActiveATask && isOverAColumn) {
			setTasks((tasks) => {
				const activeIndex = tasks.findIndex((t) => t._id === activeId);

				tasks[activeIndex][dynamicField] = overId;
				// console.log(`DROPPING TASK OVER COLUMN for ${dynamicField}`, {
				// 	activeIndex,
				// });
				return arrayMove(tasks, activeIndex, activeIndex);
			});
		}
	}

	function getDynamicField() {
		// Use selectedGroupBy or any other logic to determine the dynamic field name
		switch (selectedGroupBy) {
			case "Type":
				return "type";
			case "None":
				return "status";
			case "Priority":
				return "priority";
			case "Status":
				return "status";
			default:
				return "status";
		}
	}
}

interface GroupedResult {
	[key: string]: { key: string; value: any[] }[];
}

function groupBy(
	array: any[],
	groupByKey: string,
	subdivideByKey: string
): GroupedResult {
	return array.reduce((result: GroupedResult, currentItem: any) => {
		const groupKeyValue = currentItem[groupByKey];
		const subdivideKeyValue = currentItem[subdivideByKey];
		if (groupKeyValue !== undefined && subdivideKeyValue !== undefined) {
			if (!result[groupKeyValue]) {
				result[groupKeyValue] = [];
			}
			const group = result[groupKeyValue];
			const existingSubGroup = group.find(
				(subGroup) => subGroup.key === subdivideKeyValue
			);
			if (existingSubGroup) {
				existingSubGroup.value.push(currentItem);
			} else {
				group.push({
					key: subdivideKeyValue,
					value: [currentItem],
				});
			}
		}
		return result;
	}, {});
}

export default KanbanBoard;
