import axios, { AxiosResponse } from "axios";
import { Activelogs } from "../models/Activelogs";

async function getActivelogsbyparentId(
	workspaceId: any,
	token: any
): Promise<Activelogs[]> {
	try {
		const response: AxiosResponse<Activelogs[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/activelogs/parentId`,
			{
				params: {
					parentId: workspaceId,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching Activelogs:", error);
		throw error;
	}
}

async function createActivelog(body: any, token: any): Promise<Activelogs> {
	try {
		const response: AxiosResponse<Activelogs> = await axios.post(
			`${process.env.REACT_APP_API_URL}/activelogs`,
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error creating Activelogs:", error);
		throw error;
	}
}
export { getActivelogsbyparentId, createActivelog };
