import {
	XFlow,
	XFlowGraph,
	Grid,
	Clipboard,
	History,
	Snapline,
	Transform,
	useGraphStore,
	Minimap,
} from "@antv/xflow";

import "./DataSchemaV2.css";
import _ from "lodash";
// import { REACT_NODE } from "./xflow/ReactNode";
//import { DAG_EDGE } from "./xflow/DagEdge";
import {
	DAG_CONNECTOR,
	DAG_EDGE1,
} from "../Design-artifacts/xflow/DagConnector";
import { useCallback, useEffect, useState } from "react";
import { DATA_SCHEMA_NODE } from "../Design-artifacts/xflow/DataSchemaNode";
import { layoutDagre } from "../Design-artifacts/xflow/DagreLayout";

const DataSchemaV2 = ({ schemaData }: { schemaData: any }) => {
	const [data, setData] = useState<any>(null);
	useEffect(() => {
		console.log("schemaData", schemaData);
		setData(schemaData);
	}, [schemaData]);
	return (
		<div className={"page"}>
			<div className={"container"} style={{ marginTop: "-20vh" }}>
				<XFlow>
					<div className={"content"}>
						<XFlowGraph
							zoomable
							pannable
							centerView
							fitView
							connectionOptions={{
								snap: true,
								allowBlank: false,
								allowLoop: false,
								highlight: true,
								connectionPoint: "anchor",
								anchor: "center",
								connector: DAG_CONNECTOR,
							}}
							connectionEdgeOptions={{
								attrs: {
									line: {
										stroke: "#8f8f8f",
										strokeWidth: 1,
									},
								},
							}}
						/>
						<Grid type="mesh" options={{ color: "#ccc", thickness: 1 }} />
						<Minimap
							simple
							style={{
								position: "absolute",
							}}
						/>
						{data && <InitNode2 schemaData={data} />}

						<Clipboard />

						<History />
						<Snapline sharp />
						<Transform resizing rotating />
					</div>
				</XFlow>
			</div>
		</div>
	);
};

const InitNode2 = ({ schemaData }: { schemaData: any }) => {
	const initData = useGraphStore((state) => state.initData);

	useEffect(() => {
		console.log("schemaData", schemaData);
	}, []);

	const setInitData = useCallback(() => {
		//console.log("schemaData", schemaData);
		//if(schemaData)
		let fields: { fieldName: string; type: string }[] = [];
		const collections: { entityName: string; entityFields: any }[] = [];

		schemaData[0].data.collections.forEach((collection: any) => {
			//collections.push(_.keys(_.values(collection)[0])[0]);
			fields = [];
			console.log("collection1", _.keys(_.values(collection)[0])[0]);

			_.keys(_.values(_.values(collection)[0])[0].properties).forEach(
				(key: any) => {
					console.log("collection2", key);
					fields.push({
						fieldName: key,
						type: _.values(
							_.values(_.values(collection)[0])[0].properties[key]
						)[0],
					});
				}
			);
			collections.push({
				entityName: _.keys(_.values(collection)[0])[0],
				entityFields: fields,
			});
			console.log("collections", collections);
			//console.log("fields", fields);
		});

		const nodes = collections.map((collection: any, index: number) => {
			return {
				id: index.toString(),
				shape: DATA_SCHEMA_NODE,
				data: {
					entityName: collection.entityName,
					entityFields: collection.entityFields,
				},
				ports: [{ id: "group1", group: "group1" }],
				width: 60,
				height: 60,
				position: { x: 0, y: 0 },
			};
		});
		const edges = [];
		for (let i = 1; i < collections.length; i++) {
			const sourceIndex = Math.floor(Math.random() * i);
			edges.push({
				type: DAG_EDGE1,
				source: {
					cell: sourceIndex.toString(),
					port: "group1",
				},
				target: {
					cell: i.toString(),
					port: "group1",
				},
			});
		}

		const layoutData = layoutDagre({
			nodes: nodes,
			edges: edges,
		});
		console.log("layoutData", layoutData);

		initData(layoutData);
	}, [initData, schemaData]);

	useEffect(() => {
		setInitData();
	}, [setInitData, schemaData]);

	return null;
};

export default DataSchemaV2;
