import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	//Layout,
	// Card,
	// Table,
	// Space,
	//Typography,
	List,
	Modal,
	Pagination,
	Select,
	message,
} from "antd";
//import "./RoleManagement.css";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";

import { EditOutlined } from "@ant-design/icons";
import ConnectionManagementNav from "./ConnectionsNav";
import GithubConnectionDetails from "./connections/GithubConnectionDetails";
import { useRequest } from "ahooks";
import { getConnection, getConnectionById } from "../api/ConnectionAPI";

// const { Content } = Layout;
// const { Text } = Typography;

const Connections: React.FC = () => {
	const [openConnectionModal, setOpenConnectionModal] = useState(false);
	const [responseData, setResponseData] = useState<any[]>([]);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	// const [selectedTagId, setSelectedTagId] = useState(null);
	const [selectedConnectionId, setSelectedConnectionId] = useState<any>(null);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [connectionData, setConnectionData] = useState<any>({});
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(15);

	const { data: connectionsData, run: fetchConnections } = useRequest(
		() => getConnection(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Connections data");
				message.info("Conection Setting");
			},
			onError: (error) => {
				message.error(`Failed to fetch Connections : ${error.message}`);
				console.error("Error fetching connections data:", error);
			},
		}
	);

	useEffect(() => {
		if (connectionsData) {
			setResponseData(connectionsData);
		}
	}, [connectionsData]);

	useEffect(() => {
		fetchConnections();
	}, [openConnectionModal]);

	useEffect(() => {
		console.log("Selected Connection ID:", setSelectedConnectionId);
	}, [selectedConnectionId]);

	const { data: getdataById, run: getDataByidRun } = useRequest(
		(id) => getConnectionById(id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "getdataById");
			},
			onError: (error) => {
				message.error(`Failed to fetch Connections by Id : ${error.message}`);
				console.log(error, "getdataById");
			},
		}
	);

	useEffect(() => {
		if (getdataById) {
			setConnectionData(getdataById);
			setSelectedConnectionId(getdataById._id);
			setEditModalVisible(true);
		}
	}, [getdataById]);

	const handleButtonClick = async () => {
		const formData = form.getFieldsValue();
		const createdBy = connectionData.createdBy || "defaultCreatedBy";
		const updatedBy = "defaultUpdatedBy";

		const postData = {
			name: formData.connectionName || connectionData.name,
			type: formData.connectionType || connectionData.type,
			data: connectionData,
			createdBy,
			updatedBy,
			account_Id: userProfileStoreInstance.profile.account_Id,
		};

		console.log("Sending data to the backend:", postData);

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/connection/${selectedConnectionId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(postData),
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				console.log("Response from the backend:", responseData);
				console.log("Data sent successfully");
				navigate("/fd/workspace/settings/connections");
			} else {
				const errorData = await response.json();
				console.error("Error sending data to the backend:", errorData);
				console.error("Error sending data to the backend");
			}
		} catch (error) {
			console.error("Error sending data:", error);
		}
	};

	const handleCloseEditModal = () => {
		setEditModalVisible(false);
		setSelectedConnectionId(null);
		setConnectionData({});
	};

	const renderActions = (item: any) => (
		<a onClick={() => getDataByidRun(item._id)}>
			<EditOutlined style={{ color: "#0444bf" }} />
		</a>
	);

	const renderListItem = (item: any) => (
		<List.Item key={item._id} style={{ padding: "0px 0" }}>
			<div className="list-item" style={{ marginLeft: "0.5vw" }}>
				<div className="list-item-description-left">
					<a
						className="work-item-title"
						style={{ width: 150, textAlign: "left" }}
					>
						{item?.name}
					</a>
					<br />
				</div>
				<div className="list-item-description-right">
					<a
						className="work-item-title"
						style={{ width: 100, textAlign: "left" }}
					>
						{item?.type}
					</a>
					<div style={{ marginRight: "1vw" }}>{renderActions(item)}</div>
				</div>
			</div>
		</List.Item>
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const currentPageData = responseData.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		<>
			<ConnectionManagementNav
				openConnectionModal={openConnectionModal}
				setOpenConnectionModal={setOpenConnectionModal}
			/>
			{/* <Layout style={{ height: "100vh" }}>
				<Content className="tag-content-container">
					<Card
						data-testId="card"
						style={{
							backgroundColor: "white",
							borderRadius: "16px",
							padding: "24px",
							border: "none",
							boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
							height: "100%",
							overflow: "scroll",
						}}
					>
						<div>
							<Table
								data-testId="table"
								scroll={{ y: 240 }}
								columns={[
									{
										// title: "Name",
										title: <div style={{ fontFamily: "Poppins" }}>Name</div>,
										dataIndex: "name",
										key: "name",
										render: (text: any) => (
											<Text
												style={{
													fontSize: "14px",
													fontWeight: "500",
													fontFamily: "Open Sans",
												}}
											>
												{text}
											</Text>
										),
									},
									{
										// title: "Category",
										title: <div style={{ fontFamily: "Poppins" }}>Type</div>,
										dataIndex: "type",
										key: "type",
										render: (text: any) => (
											<Text
												style={{
													fontSize: "14px",
													fontWeight: "500",
													fontFamily: "Open Sans",
												}}
											>
												{text}
											</Text>
										),
									},

									{
										// title: "Edit",
										title: <div style={{ fontFamily: "Poppins" }}>Edit</div>,
										dataIndex: "",
										key: "x",
										render: (record) => (
											<Space size={"large"}>
												<a
													style={{ fontSize: "16px" }}
													onClick={() => handleEditConnectionClick(record.key)}
												>
													<EditOutlined style={{ color: "#0444bf" }} />
												</a>
											</Space>
										),
									},
								]}
								pagination={{
									pageSize: 6,
									pageSizeOptions: ["10", "20", "30"],
									showTotal: (total, range) =>
										`${range[0]}-${range[1]} of ${total} items`,
								}}
								dataSource={responseData.map((record) => ({
									key: record._id,
									name: record.name,
									type: record.type,
									accessToken: record.accessToken,
									// color: record.color,
								}))}
							/>
						</div>
					</Card>
				</Content>
			</Layout> */}
			<div
				className="list-view-container"
				style={{
					display: "flex",
					flexDirection: "column",
					height: "76vh",
					padding: "0vw 1vw",
				}}
			>
				<div style={{ flex: "1 1 auto" }}>
					<List
						itemLayout="vertical"
						dataSource={currentPageData}
						renderItem={renderListItem}
						className="list-view"
						size="small"
					/>
				</div>
				<div
					style={{
						flex: "0 0 20px",
						textAlign: "end",
						marginTop: "3px",
						marginBottom: "10px",
					}}
				>
					<Pagination
						current={currentPage}
						total={responseData.length}
						pageSize={pageSize}
						onChange={handlePageChange}
						showSizeChanger={false}
					/>
				</div>

				<Modal
					title="Edit Connection"
					visible={editModalVisible}
					onCancel={handleCloseEditModal}
					onOk={handleButtonClick}
				>
					<Form layout="horizontal">
						<Form.Item
							label="Type"
							required
							style={{ width: "60%", marginLeft: "0px" }}
							labelCol={{ span: 6 }}
							wrapperCol={{ span: 16 }}
						>
							<Select
								value={connectionData.type}
								style={{ width: "315px", marginLeft: "52px" }}
								onChange={(value) =>
									setConnectionData({ ...connectionData, type: value })
								}
								options={[
									{ value: "Jira", label: "Jira" },
									{ value: "Github", label: "Github" },
								]}
							/>
						</Form.Item>

						<Form.Item
							label="Name"
							required
							style={{ width: "100%", marginLeft: "0px" }}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 16 }}
						>
							<Input
								style={{ marginLeft: "45px" }}
								value={connectionData.name}
								onChange={(e) =>
									setConnectionData({ ...connectionData, name: e.target.value })
								}
							/>
						</Form.Item>

						{connectionData.type === "Github" && (
							<GithubConnectionDetails
								connectionData={connectionData}
								setConnectionData={setConnectionData}
							/>
						)}
						{connectionData.type === "Jira" && (
							<>
								<Form.Item
									label="BaseUrl"
									style={{ width: "87%", marginLeft: "0px" }}
									required
								>
									<Input
										style={{ marginLeft: "30px" }}
										value={connectionData.baseUrl}
										onChange={(e) =>
											setConnectionData({
												...connectionData,
												baseUrl: e.target.value,
											})
										}
									/>
								</Form.Item>

								<Form.Item
									label="UserName"
									style={{ width: "92%", marginLeft: "0px" }}
									required
								>
									<Input
										style={{ marginLeft: "5px" }}
										value={connectionData.userName}
										onChange={(e) =>
											setConnectionData({
												...connectionData,
												userName: e.target.value,
											})
										}
									/>
								</Form.Item>

								<Form.Item
									label="APIToken"
									style={{ width: "90%", marginLeft: "0px" }}
									required
								>
									<Input
										style={{ marginLeft: "16px" }}
										value={connectionData.apiToken}
										onChange={(e) =>
											setConnectionData({
												...connectionData,
												apiToken: e.target.value,
											})
										}
									/>
								</Form.Item>
							</>
						)}
					</Form>
				</Modal>
			</div>
		</>
	);
};

export default Connections;
