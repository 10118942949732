import { useGraphEvent, useGraphInstance, useGraphStore } from "@antv/xflow";
import { useCallback, useEffect, useState } from "react";
import { SQUARENODE } from "./shape/square";
import { ROUNDEDRECTANGLENODE } from "./shape/roundRectangle";
import { ELLIPSENODE } from "./shape/ellipse";
import { DOWNTRINAGLENODE } from "./shape/downTriangle";
import { TRINAGLENODE } from "./shape/triangle";
import { DIAMONDNODE } from "./shape/diamond";
import { CHEVRONNODE } from "./shape/chevron";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import {
	getWhiteboardById,
	updateWhiteboard,
} from "../../../api/WhiteboardApi";
import { useRequest } from "ahooks";
import { message } from "antd";

// const ports = {
// 	groups: {
// 		group1: {
// 			position: "top",
// 			attrs: {
// 				circle: {
// 					stroke: "#D06269",
// 					strokeWidth: 1,
// 					r: 4,
// 					magnet: true,
// 				},
// 			},
// 		},
// 		group2: {
// 			position: "right",
// 			attrs: {
// 				circle: {
// 					stroke: "#D06269",
// 					strokeWidth: 1,
// 					r: 4,
// 					magnet: true,
// 				},
// 			},
// 		},
// 		group3: {
// 			position: "bottom",
// 			attrs: {
// 				circle: {
// 					stroke: "#D06269",
// 					strokeWidth: 1,
// 					r: 4,
// 					magnet: true,
// 				},
// 			},
// 		},
// 		group4: {
// 			position: "left",
// 			attrs: {
// 				circle: {
// 					stroke: "#D06269",
// 					strokeWidth: 1,
// 					r: 4,
// 					magnet: true,
// 				},
// 			},
// 		},
// 	},
// 	items: [
// 		{ id: "group1", group: "group1" },
// 		{ id: "group2", group: "group2" },
// 		{ id: "group3", group: "group3" },
// 		{ id: "group4", group: "group4" },
// 	],
// };

// const ELLIPSENODE = "ellipse-node";
// const SQUARENODE = "Square-node";
// const TRINAGLENODE = "triangle-node";
// const DOWNTRINAGLENODE = "down-triangle-node";
// const DIAMONDNODE = "diamond-node";
// const ROUNDEDRECTANGLENODE = "rounded-rectangle-node";
// const CHEVRONNODE = "chevron-node";

// Graph.registerNode(SQUARENODE, {
// 	inherit: "rect",
// 	attrs: {
// 		text: {
// 			fontSize: 14,
// 			fill: "#fff",
// 		},
// 	},
// 	ports: { ...ports },
// });

// Graph.registerNode(ELLIPSENODE, {
// 	inherit: "circle",
// 	attrs: {
// 		text: {
// 			fontSize: 14,
// 			fill: "#fff",
// 		},
// 	},
// 	ports: { ...ports },
// });

// Graph.registerNode(TRINAGLENODE, {
// 	inherit: "polygon",
// 	attrs: {
// 		text: {
// 			fontSize: 14,
// 			fill: "#fff",
// 		},
// 		body: {
// 			refPoints: "0,20 10,0 20,20",
// 		},
// 	},
// 	ports: { ...ports },
// });

// Graph.registerNode(DOWNTRINAGLENODE, {
// 	inherit: "polygon",
// 	attrs: {
// 		text: {
// 			fontSize: 14,
// 			fill: "#fff",
// 		},
// 		body: {
// 			refPoints: "0,0 20,0 10,20",
// 		},
// 	},
// 	ports: { ...ports },
// });

// Graph.registerNode(DIAMONDNODE, {
// 	inherit: "polygon",
// 	attrs: {
// 		text: {
// 			fontSize: 14,
// 			fill: "#fff",
// 		},
// 	},
// 	ports: { ...ports },
// });

// Graph.registerNode(ROUNDEDRECTANGLENODE, {
// 	inherit: "rect",
// 	attrs: {
// 		text: {
// 			fontSize: 14,
// 			fill: "#fff",
// 		},
// 		body: {
// 			rx: 8,
// 			ry: 8,
// 		},
// 	},
// 	ports: { ...ports },
// });

// Graph.registerNode(CHEVRONNODE, {
// 	inherit: "polygon",
// 	attrs: {
// 		text: {
// 			fontSize: 14,
// 			fill: "#fff",
// 		},
// 		body: {
// 			rx: 8,
// 			ry: 8,
// 			refPoints: "0,0 15,10 0,20 40,20 55,10 40,0",
// 		},
// 	},
// 	ports: { ...ports },
// });

// Graph.registerNode(CUSTOMVERIFYNODE, {
// 	inherit: SQUARENODE,
// 	width: 36,
// 	height: 36,
// 	attrs: {
// 		body: {
// 			rx: 5,
// 			ry: 5,
// 			strokeWidth: 2,
// 			stroke: "rgb(72, 203, 164)",
// 			fill: "rgb(72, 203, 164)",
// 		},
// 	},
// });

// Graph.registerNode(CUSTOMCOURSENODE, {
// 	inherit: SQUARENODE,
// 	width: 100,
// 	height: 60,
// 	attrs: {
// 			strokeWidth: 2,
// 			stroke: "rgb(74, 123, 203)",
// 			fill: "rgb(74, 123, 203)",
// 		},
// 		text: {
// 			wordBreak: "break-all",
// 		},
// 	},
// });

// Graph.registerNode(DIAMONDNODE, {
// 	inherit: "polygon",
// 		body: {
// 			rx: 0,
// 			ry: 0,
// 	width: 140,
// 	height: 60,
// 	attrs: {
// 		body: {
// 			strokeWidth: 1,
// 			stroke: "rgb(128, 170, 255)",

// 			refPoints: "0,10 10,0 20,10 10,20",
// 		},
// 		text: {
// 			fontSize: 14,
// 			fill: "#fff",
// 		},
// 	},
// 	ports: {
// 		...ports,
// 	},
// });

interface InitNodeProps {
	id: string | any;
}

interface InitNodeProps {
	id: string | any;
}

const InitNode: React.FC<InitNodeProps> = ({ id }) => {
	console.log(id, "id");
	const graph = useGraphInstance();
	const [graphData, setGraphData] = useState<any>({ nodes: [], edges: [] });
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [data, setData] = useState<any>([]);

	const addNodes = useGraphStore((state) => state.addNodes);
	const addEdges = useGraphStore((state) => state.addEdges);
	const updateNode = useGraphStore((state) => state.updateNode);

	useGraphEvent("node:click", ({ node }) => {
		const { id } = node;
		const randomColor = Math.floor(Math.random() * 16777215).toString(16);
		const fillColor = `#${randomColor}`;
		updateNode(id, {
			attrs: {
				body: {
					stroke: "#8f8f8f",
					strokeWidth: 1,
					fill: fillColor,
				},
			},
		});
	});

	const {
		run: fetchBoxesRun,
		error: errors,
		loading,
	} = useRequest(
		() => getWhiteboardById(id, userProfileStoreInstance.idToken ?? ""),
		{
			manual: true,
			onSuccess: (whiteboardData) => {
				setData(whiteboardData);
				console.log("Data fetched successfully:", whiteboardData);
			},
			onError: (errors) => {
				message.error("Network Error " + errors.message);
			},
		}
	);

	useEffect(() => {
		fetchBoxesRun();
	}, []);

	useEffect(() => {
		if (loading) {
			console.log("Data is loading...");
		}
		if (errors) {
			console.error("Error occurred:", errors.message);
		}
	}, [loading, errors]);

	const addNodeInit = useCallback(() => {
		if (data && data?.data?.nodes && data.data.nodes.length > 0) {
			console.log(data, "data");
			data?.data.nodes.forEach((node: any) => {
				addNodes([
					{
						id: node.id,
						shape: node.shape,
						x: node.position.x,
						y: node.position.y,
						width: node.size.width,
						height: node.size.height,
						data: node?.data || " ",
					},
				]);
			});

			data?.data.edges.forEach((edge: any) => {
				addEdges([
					{
						id: edge.id,
						source: edge.source,
						target: edge.target,
					},
				]);
			});
		}
	}, [addNodes, data, graph]);

	useEffect(() => {
		addNodeInit();
	}, [addNodeInit, data, graph]);

	const logAllNodes = () => {
		const graphNodes = graph?.getNodes() || [];
		const graphEdges = graph?.getEdges() || [];

		setGraphData({ nodes: graphNodes, edges: graphEdges });
	};

	useEffect(() => {
		const handleNodeAddition = () => {
			logAllNodes();
		};

		graph?.on("node:added", handleNodeAddition);

		return () => {
			graph?.off("node:added", handleNodeAddition);
		};
	}, [graph, data]);

	useEffect(() => {
		logAllNodes();
		console.log(graph, "data");
	}, []);

	useEffect(() => {
		console.log("Nodes data:", graphData);
	}, [graphData, data, graph]);

	const {
		run: updateWhiteboardRun,
		error: updateError,
		loading: updateLoading,
	} = useRequest(
		() =>
			updateWhiteboard(
				id,
				{
					...data,
					data: graphData,
				},
				userProfileStoreInstance.idToken ?? ""
			),
		{
			manual: true,
			onSuccess: (responseData) => {
				console.log("Data updated successfully:", responseData);
			},
			onError: (updateError) => {
				message.error("Network Error " + updateError.message);
			},
		}
	);

	useEffect(() => {
		if (id && Object.keys(graphData).length > 0) {
			updateWhiteboardRun();
		}
	}, [graphData, id]);

	useEffect(() => {
		if (updateLoading) {
			console.log("Updating data...");
		}
		if (updateError) {
			console.error("Error occurred during update:", updateError.message);
		}
	}, [updateLoading, updateError]);

	return null;
};

export {
	InitNode,
	ELLIPSENODE,
	DOWNTRINAGLENODE,
	TRINAGLENODE,
	ROUNDEDRECTANGLENODE,
	CHEVRONNODE,
	DIAMONDNODE,
	SQUARENODE,
};
