import { Flex, Image, Spin } from "antd";
import React, { useEffect } from "react";

interface ChatOutroProps {
	//
}

const ChatOutro: React.FC<ChatOutroProps> = (props) => {
	// Add your component logic here

	useEffect(() => {
		// Add your code here
		console.log(props);
	}, []);

	return (
		<Flex
			vertical
			style={{
				height: "100vh",
			}}
		>
			<Flex justify="center">
				<>
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
					/>
					<Spin size="large" />
				</>
			</Flex>{" "}
			<Flex justify="center">
				<h3>Analyzing the conversation for next steps...</h3>
			</Flex>
		</Flex>
	);
};

export default ChatOutro;
