import axios, { AxiosResponse } from "axios";
import { Notes } from "../models/Notes";

async function getNotesbyWorkspaceId(
	workspaceId: any,
	token: any
): Promise<Notes[]> {
	try {
		const response: AxiosResponse<Notes[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/notes/workspaceId`,
			{
				params: {
					workspaceId: workspaceId,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching notes:", error);
		throw error;
	}
}

async function getNotesbyId(notesId: any, token: any): Promise<Notes> {
	try {
		const response: AxiosResponse<Notes> = await axios.get(
			`${process.env.REACT_APP_API_URL}/notes/${notesId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching notes:", error);
		throw error;
	}
}

async function createNote(body: any, token: any): Promise<Notes> {
	try {
		const response: AxiosResponse<Notes> = await axios.post(
			`${process.env.REACT_APP_API_URL}/notes`,
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching notes:", error);
		throw error;
	}
}

async function addPage(body: any, id: any, token: any): Promise<Notes> {
	try {
		const response: AxiosResponse<Notes> = await axios.post(
			`${process.env.REACT_APP_API_URL}/notes/addpage/${id}`,
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching notes:", error);
		throw error;
	}
}

async function addContent(
	body: any,
	id: any,
	pageId: any,
	token: any
): Promise<Notes> {
	try {
		const response: AxiosResponse<Notes> = await axios.put(
			`${process.env.REACT_APP_API_URL}/notes/${id}/pages/${pageId}/content`,
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching notes:", error);
		throw error;
	}
}

export { getNotesbyWorkspaceId, getNotesbyId, createNote, addPage, addContent };
