import React from "react";
import {
	Button,
	//TableColumnsType,
	Flex,
	Image,
	Typography,
	message,
} from "antd";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
// // import { useNavigate, useParams } from "react-router-dom";
// import useWorkspaceStore from "../stores/WorkspaceState";
import { useNavigate, useParams } from "react-router-dom";
import useWorkspaceStore from "../stores/WorkspaceState";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AIRunPrepViewDummy = () => {
	// const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const navigate = useNavigate();
	const { stageParam } = useParams();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [messageApi, contextHolder] = message.useMessage();
	const handleSubmit = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${currentWorkspace._id}/aievent/${stageParam}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const result = await response.json();
			console.log(result, "result");

			if (response.ok) {
				messageApi.info("AI Job created");
				navigate(-1);
			} else {
				messageApi.error("Error creating AI Job");
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	// const { currentWorkspace } = useWorkspaceStore((state: any) => ({
	// 	currentWorkspace: state.currentWorkspace,
	// }));
	// State for table data

	return (
		<Flex vertical align="center" justify="center">
			{contextHolder}
			{stageParam === "feature-elaboration" && (
				<>
					<Flex
						vertical
						align="center"
						justify="center"
						style={{ maxWidth: "60vw", marginTop: "10vh" }}
					>
						<Typography.Title level={2}>Feature Elaboration</Typography.Title>
						<Image
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_feature_elaboration.svg"
							width={"30vh"}
							preview={false}
						/>
						<Typography.Paragraph>
							Feature elaboration refers to the process of expanding on the
							functionalities and capabilities of a product or service. With the
							aid of AI, feature elaboration becomes more efficient and
							effective. By analyzing user data and behavior patterns, AI can
							identify areas for improvement and suggest innovative features
							that enhance user experience. Through AI-driven insights,
							businesses can iterate on their offerings, ensuring they meet the
							evolving needs and preferences of their customers, ultimately
							leading to better outcomes.
						</Typography.Paragraph>
					</Flex>
					<Button
						type="primary"
						onClick={() => handleSubmit()}
						style={{ marginTop: "4vh" }}
					>
						Create AI Job for the Feature Elaboration
					</Button>
				</>
			)}
			{stageParam === "de-ux-spec" && (
				<>
					<Flex
						vertical
						align="center"
						justify="center"
						style={{ maxWidth: "60vw", marginTop: "10vh" }}
					>
						<Typography.Title level={2}>
							UX - Information architecture
						</Typography.Title>
						<Image
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_user_experience.svg"
							width={"30vh"}
							preview={false}
						/>
						<Typography.Paragraph>
							Information architecture involves organizing, structuring, and
							labeling content in a way that facilitates user navigation and
							understanding. AI can greatly assist in optimizing information
							architecture by analyzing large datasets to identify patterns in
							user interactions and preferences. By leveraging AI algorithms,
							businesses can refine their information architecture to ensure
							that content is presented in a logical and intuitive manner,
							leading to improved user engagement and satisfaction.
							Additionally, AI can help in automating the process of
							categorization and tagging, ensuring that content is accurately
							classified and easily accessible to users.
						</Typography.Paragraph>
					</Flex>
					<Button
						type="primary"
						onClick={() => handleSubmit()}
						style={{ marginTop: "4vh" }}
					>
						Create AI Job for the Information architecture
					</Button>
				</>
			)}
			{stageParam === "de-hifi-mock-up" && (
				<>
					<Flex
						vertical
						align="center"
						justify="center"
						style={{ maxWidth: "60vw", marginTop: "10vh" }}
					>
						<Typography.Title level={2}>UX - Mockup</Typography.Title>

						<Image
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_hifi_mockup.svg"
							width={"30vh"}
							preview={false}
						/>
						<Typography.Paragraph>
							Mock-up design is the process of creating visual representations
							or prototypes of a product or application before it is fully
							developed. AI can revolutionize mock-up design by automating
							certain aspects such as layout generation and style suggestions
							based on user preferences and industry standards. Through machine
							learning algorithms, AI can analyze existing mock-ups and design
							trends to generate insightful recommendations for improving
							usability and aesthetics. By incorporating AI into mock-up design
							processes, businesses can streamline iteration cycles, reduce
							development time, and ultimately deliver more polished and
							user-friendly products.
						</Typography.Paragraph>
					</Flex>
					<Button
						type="primary"
						onClick={() => handleSubmit()}
						style={{ marginTop: "4vh" }}
					>
						Create AI Job for the details for mock up design
					</Button>
				</>
			)}
			{stageParam === "de-ddd-spec" && (
				<>
					<Flex
						vertical
						align="center"
						justify="center"
						style={{ maxWidth: "60vw", marginTop: "10vh" }}
					>
						<Typography.Title level={2}>Technical design</Typography.Title>
						<Image
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_ddd_spec.svg"
							width={"30vh"}
							preview={false}
						/>
						<Typography.Paragraph>
							Domain-Driven Design (DDD) is an approach to software development
							that focuses on understanding and modeling the problem domain. AI
							can enhance DDD by analyzing large volumes of domain-specific data
							to uncover hidden patterns and insights. By leveraging machine
							learning techniques, AI can assist in identifying core domain
							concepts, relationships, and boundaries, aiding developers in
							creating more accurate domain models. Furthermore, AI can help in
							automating certain aspects of domain modeling and validation,
							allowing teams to iterate more efficiently and ensure that the
							software aligns closely with the real-world domain it serves.
							Integrating AI into Domain-Driven Design can lead to more robust
							and effective solutions that better address the needs of users and
							stakeholders.
						</Typography.Paragraph>
					</Flex>
					<Button
						type="primary"
						onClick={() => handleSubmit()}
						style={{ marginTop: "4vh" }}
					>
						Create AI Job for the Technical Architecture Design
					</Button>
				</>
			)}
			{stageParam === "de-micro-frontend" && (
				<>
					<Flex
						vertical
						align="center"
						justify="center"
						style={{ maxWidth: "60vw", marginTop: "10vh" }}
					>
						<Typography.Title level={2}>
							UI components and frontend
						</Typography.Title>
						<Image
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_micro_frontend.svg"
							width={"30vh"}
							preview={false}
						/>
						<Typography.Paragraph>
							Frontend UI design involves creating the visual and interactive
							elements of a user interface. AI can significantly enhance
							frontend UI design by offering insights into user behavior,
							preferences, and trends. Through machine learning algorithms, AI
							can analyze user interactions with existing UI designs to identify
							areas for improvement and suggest enhancements such as layout
							adjustments, color schemes, and typography choices. Additionally,
							AI-powered tools can assist designers in generating responsive and
							accessible designs that adapt to various screen sizes and user
							needs. By leveraging AI in frontend UI design, businesses can
							create more engaging and intuitive interfaces that ultimately lead
							to improved user satisfaction and retention.
						</Typography.Paragraph>
					</Flex>
					<Button
						type="primary"
						onClick={() => handleSubmit()}
						style={{ marginTop: "4vh" }}
					>
						Create AI Job for the Frontend design
					</Button>
				</>
			)}
			{stageParam === "de-api-spec" && (
				<>
					<Flex
						vertical
						align="center"
						justify="center"
						style={{ maxWidth: "60vw", marginTop: "10vh" }}
					>
						<Typography.Title level={2}>API design</Typography.Title>
						<Image
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_api_spec.svg"
							width={"30vh"}
							preview={false}
						/>
						<Typography.Paragraph>
							API design involves creating interfaces that enable communication
							between different software systems or components. AI can greatly
							benefit API design by analyzing usage patterns, performance data,
							and user feedback to optimize the design of APIs for efficiency
							and usability. Machine learning algorithms can help in
							automatically generating API documentation, suggesting
							improvements to endpoints, and detecting potential issues such as
							latency or security vulnerabilities. By incorporating AI into API
							design processes, developers can streamline the creation of robust
							and intuitive APIs that better meet the needs of both developers
							and end-users, ultimately leading to more efficient integration
							and enhanced user experiences.
						</Typography.Paragraph>
					</Flex>
					<Button
						type="primary"
						onClick={() => handleSubmit()}
						style={{ marginTop: "4vh" }}
					>
						Create AI Job for the API design
					</Button>
				</>
			)}
			{stageParam === "de-data-schema" && (
				<>
					<Flex
						vertical
						align="center"
						justify="center"
						style={{ maxWidth: "60vw", marginTop: "10vh" }}
					>
						<Typography.Title level={2}>Data schema design</Typography.Title>
						<Image
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_data_schema.svg"
							width={"30vh"}
							preview={false}
						/>
						<Typography.Paragraph>
							Database design is the process of structuring and organizing data
							in a way that facilitates efficient storage, retrieval, and
							manipulation. AI can revolutionize database design by analyzing
							vast amounts of data to identify optimal schema designs, indexing
							strategies, and query optimization techniques. Machine learning
							algorithms can also assist in predicting future data access
							patterns and suggesting partitioning or sharding strategies to
							improve scalability and performance. Additionally, AI-powered
							tools can automate the process of data modeling and normalization,
							ensuring that databases are well-structured and optimized for the
							specific requirements of the application. By leveraging AI in
							database design, businesses can build more robust and scalable
							data storage solutions that efficiently handle large volumes of
							data and support complex querying needs.
						</Typography.Paragraph>
					</Flex>
					<Button
						type="primary"
						onClick={() => handleSubmit()}
						style={{ marginTop: "4vh" }}
					>
						Create AI Job for the Data schema design
					</Button>
				</>
			)}
		</Flex>
	);
};

export default AIRunPrepViewDummy;
