import { Avatar, Flex } from "antd";
import React from "react";
// import AlchemiAIPopup from "./AlchemiAIPopup";
// import useAIActivityStore from "../stores/AIActivityStore";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
// import useWorkspaceStore from "../stores/WorkspaceState";
// import { useRequest } from "ahooks";
// import {
// 	BulbOutlined,
// 	CommentOutlined,
// 	HomeOutlined,
// 	NodeExpandOutlined,
// } from "@ant-design/icons";
import usePlatformStore from "../stores/platformStore";

const AlchemiAINavButtonV2 = () => {
	// const [showAIBadge, setShowAIBadge] = useState(false);
	// const { currentWorkspace } = useWorkspaceStore((state: any) => ({
	// 	currentWorkspace: state.currentWorkspace,
	// }));
	// const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	// const [alchemiBadgeCount, setAlchemiBadgeCount] = useState(0);
	// const {
	// 	currentAIActivities,
	// 	setCurrentAIActivities,
	// 	alRecommendations,
	// 	setAlRecommendations,
	// } = useAIActivityStore((state: any) => ({
	// 	currentAIActivities: state.currentAIActivities,
	// 	setCurrentAIActivities: state.setCurrentAIActivities,
	// 	alRecommendations: state.alRecommendations,
	// 	setAlRecommendations: state.setAlRecommendations,
	// }));
	// const [alchemiView, setAlchemiView] = useState("home");

	// const getAIRecommendations = async () => {
	// 	// Implement your code here

	// 	const response = await fetch(
	// 		`${process.env.REACT_APP_API_URL}/alrecommendations/${currentWorkspace._id}?status=ACTIVE`,
	// 		{
	// 			method: "GET",
	// 			headers: {
	// 				"Content-Type": "application/json; charset=UTF-8",
	// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 			},
	// 		}
	// 	);

	// 	const result = await response.json();
	// 	return result;
	// 	//setAIActivityJob(result);
	// };
	// const {
	// 	data: alRecommendationsData,
	// 	run: alRecommendationsRun,
	// 	cancel: alRecommendationsCancel,
	// } = useRequest(() => getAIRecommendations(), {
	// 	pollingInterval: 120000,
	// 	pollingWhenHidden: false,
	// });

	// const getAIActivty = async () => {
	// 	// Implement your code here

	// 	const response = await fetch(
	// 		`${process.env.REACT_APP_API_URL}/describe/${currentWorkspace._id}/activity?status=IN_PROGRESS`,
	// 		{
	// 			method: "GET",
	// 			headers: {
	// 				"Content-Type": "application/json; charset=UTF-8",
	// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 			},
	// 		}
	// 	);

	// 	const result = await response.json();
	// 	return result;
	// 	//setAIActivityJob(result);
	// };
	// const {
	// 	data: aiActivitiesData,
	// 	run: aiActivitiesRun,
	// 	cancel: aiActivitiesCancel,
	// } = useRequest(() => getAIActivty(), {
	// 	pollingInterval: 120000,
	// 	pollingWhenHidden: false,
	// });

	// useEffect(() => {
	// 	return () => {
	// 		aiActivitiesCancel();
	// 		alRecommendationsCancel();
	// 	};
	// }, []);

	// useEffect(() => {
	// 	if (aiActivitiesData) {
	// 		setCurrentAIActivities(aiActivitiesData);
	// 	}
	// }, [aiActivitiesData]);

	// useEffect(() => {
	// 	if (alRecommendationsData) {
	// 		setAlRecommendations(alRecommendationsData);
	// 	}
	// }, [alRecommendationsData]);

	// useEffect(() => {
	// 	let alchemiBadgeCountNumber = 0;
	// 	currentAIActivities &&
	// 		currentAIActivities.length > 0 &&
	// 		currentAIActivities.filter((activity: any) => {
	// 			if (activity.status === "IN_PROGRESS") {
	// 				alchemiBadgeCountNumber = alchemiBadgeCountNumber + 1;
	// 			}
	// 		});

	// 	alRecommendations &&
	// 		alRecommendations.length > 0 &&
	// 		alRecommendations.filter((recommendation: any) => {
	// 			if (recommendation.status === "ACTIVE") {
	// 				alchemiBadgeCountNumber = alchemiBadgeCountNumber + 1;
	// 			}
	// 		});

	// 	if (alchemiBadgeCountNumber && alchemiBadgeCountNumber > 0) {
	// 		setAlchemiBadgeCount(alchemiBadgeCountNumber);
	// 		setShowAIBadge(false);
	// 	}
	// }, [alRecommendations, currentAIActivities]);

	// useEffect(() => {
	// 	if (currentWorkspace) {
	// 		aiActivitiesRun();
	// 		alRecommendationsRun();
	// 	}
	// }, [currentWorkspace]);

	// useEffect(() => {
	// 	if (currentWorkspace) {
	// 		aiActivitiesRun();
	// 		alRecommendationsRun();
	// 	}
	// }, []);

	// useEffect(() => {
	// 	currentAIActivities &&
	// 		currentAIActivities.length > 0 &&
	// 		currentAIActivities.filter((activity: any) => {
	// 			if (activity.status === "IN_PROGRESS") {
	// 				setShowAIBadge(true);
	// 			} else {
	// 				setShowAIBadge(false);
	// 			}
	// 		});
	// 	if (currentAIActivities && currentAIActivities.length === 0) {
	// 		setShowAIBadge(false);
	// 		console.log(showAIBadge);
	// 	}
	// }, [currentAIActivities]);
	// const handleAlchemiView = (e: any) => {
	// 	console.log("setAlchemiView", e.target.value);
	// 	setAlchemiView(e.target.value);
	// };
	const { aiChatWidget, setAiChatWidget } = usePlatformStore((state: any) => ({
		aiChatWidget: state.aiChatWidget,
		setAiChatWidget: state.setAiChatWidget,
	}));
	return (
		// JSX code for your component's UI
		// <Popover
		// 	title={
		// 		<>
		// 			<Flex>
		// 				<Flex justify="flex-start" align="center" style={{ width: "100%" }}>
		// 					AI Activities
		// 				</Flex>
		// 				<Flex justify="flex-end" align="center" style={{ width: "100%" }}>
		// 					<Radio.Group defaultValue="home" size="middle">
		// 						<Radio.Button
		// 							value="home"
		// 							defaultChecked
		// 							onClick={handleAlchemiView}
		// 						>
		// 							<HomeOutlined />
		// 						</Radio.Button>
		// 						<Radio.Button
		// 							value="recommendations"
		// 							onClick={handleAlchemiView}
		// 						>
		// 							<Badge
		// 								count={0}
		// 								size="small"
		// 								color="blue"
		// 								offset={[6, 0]}
		// 								showZero={false}
		// 							>
		// 								<BulbOutlined />
		// 							</Badge>
		// 						</Radio.Button>
		// 						<Radio.Button value="chatThreads" onClick={handleAlchemiView}>
		// 							<Badge count={0} size="small" color="blue" offset={[6, 0]}>
		// 								{" "}
		// 								<CommentOutlined />
		// 							</Badge>
		// 						</Radio.Button>
		// 						<Radio.Button value="aiRuns" onClick={handleAlchemiView}>
		// 							<Badge
		// 								count={0}
		// 								size="small"
		// 								color="blue"
		// 								offset={[6, 0]}
		// 								showZero={false}
		// 							>
		// 								{" "}
		// 								<NodeExpandOutlined />
		// 							</Badge>
		// 						</Radio.Button>
		// 					</Radio.Group>
		// 				</Flex>
		// 			</Flex>
		// 		</>
		// 	}
		// 	placement="bottomRight"
		// 	content={<AlchemiAIPopup view={alchemiView} />}
		// 	trigger={"click"}
		// 	style={{ textAlign: "center", border: "2px solid red" }}
		// >
		// 	{" "}
		<Flex justify="center" align="center">
			{/* <Badge
					count={alchemiBadgeCount}
					showZero={true}
					color="blue"
					offset={[-40, 0]}
				> */}
			<Avatar
				onClick={() => setAiChatWidget(!aiChatWidget)}
				shape="square"
				style={{
					backgroundColor: "white",
					cursor: "pointer",
					marginTop: "-6px",
					marginBottom: "6px",
				}}
				size={{ xs: 24, sm: 32, md: 32, lg: 32, xl: 32, xxl: 40 }}
				src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_ai_logo_v2.svg"
			/>
			{/* </Badge> */}
		</Flex>
		// </Popover>
	);
};

export default AlchemiAINavButtonV2;
