// UpdateModal.js
import React, { useEffect } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { useParams } from "react-router-dom";
import { createActivelog } from "../../../api/ActiveLogAPI";
import { useRequest } from "ahooks";

const { Option } = Select;

const UpdateModal: React.FC<any> = ({ visible, onCancel, data }) => {
	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { itemId } = useParams();

	useEffect(() => {
		form.setFieldsValue({
			title: data?.title,

			description: data?.description,
			status: data?.status,

			priority: data?.priority,
		});
	}, [data, form]);

	interface FeatureChanges {
		[key: string]: {
			oldValue: any;
			newValue: any;
		};
	}

	const { run: postActivelogs } = useRequest(
		(body) => createActivelog(body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				message.success("Ritual updated successfully");
				console.log(response);
			},
			onError: (error) => {
				console.log(error);
				message.error(`Active Log Error: ${error.message}`);
			},
		}
	);

	const compareObjects = async (
		obj1: any,
		obj2: any,
		type: string
	): Promise<FeatureChanges[]> => {
		const changes: FeatureChanges[] = [];

		// Helper function to convert comma-separated string to array
		const stringToArray = (value: any): any => {
			return Array.isArray(value) ? value : value ? value.split(".,") : [];
		};

		if (
			typeof obj1.data === "object" &&
			obj1.data !== null &&
			typeof obj2.data === "object" &&
			obj2.data !== null &&
			type === "FEATURE"
		) {
			// Iterate over the keys of obj1.data
			for (const key in obj1.data) {
				if (
					Object.prototype.hasOwnProperty.call(obj1.data, key) &&
					Object.prototype.hasOwnProperty.call(obj2.data, key)
				) {
					const oldValue = obj1.data[key];
					const newValue = obj2.data[key];

					// Convert values to arrays if necessary
					const isArrayField = [
						"benefits",
						"complexity_score",
						"complexity_score_reason",
						"exposition",
						"dependant_features_ids",
						"role_ids",
					].includes(key);
					const oldValueArray = isArrayField
						? stringToArray(oldValue)
						: oldValue;
					const newValueArray = isArrayField
						? stringToArray(newValue)
						: newValue;

					// Check if the values are different
					if (JSON.stringify(oldValueArray) !== JSON.stringify(newValueArray)) {
						if (
							!changes.some(
								(change) =>
									JSON.stringify(change[key]) ===
									JSON.stringify({
										oldValue: oldValueArray,
										newValue: newValueArray,
									})
							)
						) {
							changes.push({
								[`data/${key}`]: {
									oldValue: oldValueArray,
									newValue: newValueArray,
								},
							});
						}
					}
				}
			}
		}

		if (
			Array.isArray(obj1.data?.use_cases) &&
			Array.isArray(obj2.data?.use_cases)
		) {
			obj1.data.use_cases.forEach((useCase1: any, index: number) => {
				const useCase2 = obj2.data.use_cases[index];

				for (const key in useCase1) {
					if (Object.prototype.hasOwnProperty.call(useCase2, key)) {
						const oldValue = useCase1[key];
						const newValue = useCase2[key];

						// Convert values to arrays if necessary
						const isArrayField = [
							"alternate_flow",
							"basic_flow",
							"business_logic",
							"post_conditions",
							"pre_conditions",
							"system_behavior",
						].includes(key);
						const oldValueArray = isArrayField
							? stringToArray(oldValue)
							: oldValue;
						const newValueArray = isArrayField
							? stringToArray(newValue)
							: newValue;

						// Check if the values are different
						if (
							JSON.stringify(oldValueArray) !== JSON.stringify(newValueArray)
						) {
							if (
								!changes.some(
									(change) =>
										JSON.stringify(change[key]) ===
										JSON.stringify({
											oldValue: oldValueArray,
											newValue: newValueArray,
										})
								)
							) {
								changes.push({
									[`use_cases/${key}`]: {
										oldValue: oldValueArray,
										newValue: newValueArray,
									},
								});
							}
						}
					}
				}
			});
		}

		// Iterate over the keys of obj1
		for (const key in obj1) {
			// Skip the 'use_cases' field
			if (key === "data") {
				continue;
			}

			// Check if the key exists in obj2 and values are different
			if (Object.prototype.hasOwnProperty.call(obj2, key)) {
				let oldValue = obj1[key];
				let newValue = obj2[key];

				// Convert values to arrays if the key is 'exposition' or 'benefits'
				if (key === "description" || key === "status" || key === "title") {
					oldValue = stringToArray(oldValue);
					newValue = stringToArray(newValue);
				}

				// Check if the values are different
				if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
					changes.push({
						[key]: {
							oldValue,
							newValue,
						},
					});
				}
			}
		}

		const parentId = itemId; // Current ritual's ID
		const Reqbody: any = {
			type: "TASK",
			content: changes,
			parentId: parentId,
			createdBy: "", // You need to provide createdBy data
		};

		postActivelogs(Reqbody);

		return changes;
	};

	const handleUpdate = async (values: any) => {
		try {
			compareObjects(data, values, "TASK");
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${data._id}`,
				{
					method: "PUT",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(values),
				}
			);

			if (response.ok) {
				// Handle success, e.g., close the modal
				onCancel();
			} else {
				// Handle error
				console.error("Failed to update item");
			}
		} catch (error) {
			console.error("Error updating item:", error);
		}
	};

	return (
		<Modal
			title="Update Item"
			open={visible}
			onCancel={onCancel}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					Cancel
				</Button>,
				<Button key="update" type="primary" onClick={() => form.submit()}>
					Update
				</Button>,
			]}
		>
			<Form form={form} onFinish={handleUpdate}>
				<Form.Item label="Title" name="title">
					<Input />
				</Form.Item>

				<Form.Item label="Description" name="description">
					<Input.TextArea />
				</Form.Item>
				<FormItem label="Status" name="status">
					<Select>
						<Option value="pending">Pending</Option>
						<Option value="ready">Ready</Option>
						<Option value="start">Start</Option>
						<Option value="end">End</Option>
					</Select>
				</FormItem>

				{/* <Form.Item label="Priority" name="newPriority">
          <Select>
            <Option value="high">High</Option>
            <Option value="medium">Medium</Option>
            <Option value="low">Low</Option>
          </Select>
        </Form.Item> */}
			</Form>
		</Modal>
	);
};

export default UpdateModal;
