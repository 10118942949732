import { register } from "@antv/xflow";
import { Flex } from "antd";
import React from "react";
const BASICFLOWNODE = "basic-flow-node";
const BasicFlowNode = ({ text }: any) => {
	console.log("text", text);
	return (
		<Flex
			style={{
				width: 100,
				height: 50,
				backgroundColor: "#000",
				color: "#fff",
			}}
		>
			<span style={{ fontSize: 14 }}>{text}</span>
		</Flex>
	);
};
const ports1 = {
	groups: {
		group1: {
			position: "top",
			attrs: {
				circle: { stroke: "#D06269", strokeWidth: 1, r: 4, magnet: true },
			},
		},
	},
	items: [{ id: "group1", group: "group1" }],
};
register({
	shape: BASICFLOWNODE,
	component: BasicFlowNode,
	ports: {
		...ports1,
	},
	effect: ["label"],
});

export { BASICFLOWNODE };
