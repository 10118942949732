// ReviewComments.tsx
import { Button, Drawer } from "antd";
import React, { useState } from "react";
import ReviewCommentList from "../component/ReviewCommentList";

interface ReviewCommentsProps {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	open: boolean;
	onCommentCountChange: (count: number) => void;
}

const ReviewComments: React.FC<ReviewCommentsProps> = ({
	setOpen,
	open,
	onCommentCountChange,
}) => {
	const [commentCount, setCommentCount] = useState<number>(0);

	const onClose = () => {
		setOpen(false);
	};

	// Function to get the commentCount
	const getCommentCount = () => {
		return commentCount;
	};

	return (
		<Drawer
			title={<h1>Review comments</h1>}
			placement="right"
			onClose={onClose}
			open={open}
			width={"32vw"}
		>
			<ReviewCommentList
				key={open.toString()}
				open={open}
				setOpen={setOpen}
				onCommentCountChange={(count) => {
					setCommentCount(count);
					onCommentCountChange(count); // Notify parent component about the change
				}}
			/>
			<div>
				<p>Comment Count: {commentCount}</p>
			</div>
			{/* Add a button to trigger the export */}
			<Button
				onClick={() =>
					console.log("Exported Comment Count:", getCommentCount())
				}
			>
				Export Comment Count
			</Button>
		</Drawer>
	);
};

export default ReviewComments;
