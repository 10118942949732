import { register } from "@antv/xflow";
import React, { useState } from "react";
import "../index.css";
import type { Node } from "@antv/xflow";

const DIAMONDNODE = "diamond-node";

const DiamondComponent = ({ node }: { node: Node }) => {
	const data = node.getData();

	const [animal, setAnimal] = useState<string>(data);

	const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
		const newAnimalName = e.currentTarget.innerText.trim();
		setAnimal(newAnimalName);
		node.updateData(newAnimalName.toString());
	};

	return (
		<div className="diamond">
			<div
				style={{
					padding: "5px 5px 5px 5px",
					minWidth: "100%",
					maxWidth: "100%",
					transform: "rotate(-45deg)",
					alignContent: "center",
					textAlign: "center",
					minHeight: "100%",
					maxHeight: "100%",
					direction: "ltr",
					unicodeBidi: "plaintext",
				}}
				contentEditable={true}
				suppressContentEditableWarning={true}
				onInput={handleInput}
			>
				{animal}
			</div>
		</div>
	);
};

const ports = {
	groups: {
		group1: {
			position: "top",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group2: {
			position: "right",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group3: {
			position: "bottom",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group4: {
			position: "left",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
	},
	items: [
		{ id: "group1", group: "group1" },
		{ id: "group2", group: "group2" },
		{ id: "group3", group: "group3" },
		{ id: "group4", group: "group4" },
	],
};

register({
	shape: DIAMONDNODE,
	ports: ports,
	component: DiamondComponent,

	effect: ["data"],
});

export { DIAMONDNODE };
