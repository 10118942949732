import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Divider, Row, Table, Tag, message } from "antd";

import { planerViewStore } from "../../stores/PlannerStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import { getSelectedWorkItems } from "../../api/WorkItemAPI";

const WorkflowTaskQuickView = () => {
	const navigate = useNavigate();

	//const { setEditModalOpen, selectedItemDetails, setOpen } = useViewsStore();
	const { selectedItemDetails } = planerViewStore();
	const [workitems, setWorkitems] = useState<any>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	// const handleDrawerCancel = () => {
	// 	setOpen(false);
	// 	setEditModalOpen(true);
	// 	navigate("/space/planner/edit");
	// };

	// const workitems = [
	// 	{
	// 	  key: '1',
	// 	  name: 'Mike',
	// 	  age: 32,
	// 	  address: '10 Downing Street',
	// 	},
	// 	{
	// 	  key: '2',
	// 	  name: 'John',
	// 	  age: 42,
	// 	  address: '10 Downing Street',
	// 	},
	//   ];

	const { data: selectedWorkitemData, run: fetchWorkitemforSelected } =
		useRequest(
			(body) => getSelectedWorkItems(body, userProfileStoreInstance.idToken),
			{
				manual: true,
				onSuccess: (result) => {
					console.log(result, "result");
					message.success("Workitem fetched successfully");
				},
				onError: (error) => {
					console.log(error, "error");
					message.error("Failed to fetch workitem");
				},
			}
		);

	useEffect(() => {
		if (selectedWorkitemData) {
			setWorkitems(selectedWorkitemData);
		}
	}, [selectedWorkitemData]);

	useEffect(() => {
		fetchWorkitemforSelected({ items: selectedItemDetails?.data?.items });
	}, [selectedItemDetails]);

	const columns = [
		{
			title: "Id",
			dataIndex: "WorkitemId",
			key: "WorkitemId",
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
		},
	];
	return (
		<div>
			<div style={{ textAlign: "left" }}>
				{selectedItemDetails?.type === "WORKFLOW_TASK" && (
					<Alert
						message="To complete the task"
						type="info"
						style={{ borderRadius: "4px", textAlign: "left" }}
						action={
							<Button
								size="small"
								onClick={() =>
									navigate(`/wfitemfd/refinement/${selectedItemDetails._id}`)
								}
							>
								Open
							</Button>
						}
						showIcon
					/>
				)}
				<br />
				{selectedItemDetails?.type === "WORKFLOW_TASK" && (
					<Alert
						message="Task requires elaboration"
						type="info"
						style={{ borderRadius: "4px", textAlign: "left" }}
						action={
							<Button
								size="small"
								onClick={() =>
									navigate(`/wfitemfd/elaboration/${selectedItemDetails._id}`)
								}
							>
								Open
							</Button>
						}
						showIcon
					/>
				)}
				<p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
					dangerouslySetInnerHTML={{ __html: selectedItemDetails?.description }}
				></p>

				{/* <p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
				>
					Type: {selectedItemDetails?.type}
				</p> */}

				<Row gutter={16}>
					<Col className="gutter-row" span={4}>
						<div>Status</div>
					</Col>
					<Col className="gutter-row" span={8}>
						<Tag color="red" style={{ textAlign: "left" }}>
							{selectedItemDetails?.status}
						</Tag>
					</Col>
					<Col className="gutter-row" span={4}>
						<div>Priority</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<Tag color="pink" style={{ textAlign: "left" }}>
							{selectedItemDetails?.priority}
						</Tag>
					</Col>
				</Row>

				{/* <p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
				>
					Stage: {selectedItemDetails?.stage}
				</p> */}
			</div>
			<Divider />
			<Table dataSource={workitems} columns={columns} />;
		</div>
	);
};

export default WorkflowTaskQuickView;
