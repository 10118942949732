import {
	XFlow,
	XFlowGraph,
	History,
	Clipboard,
	Transform,
	Snapline,
	Minimap,
} from "@antv/xflow";

import { Connector } from "./connector";
import { Dnd } from "./dnd";
// import { InitEdge } from "./edge";
import "./index.css";
import { Keyboard } from "./keyboard";
import { InitNode } from "./node";
import { useParams } from "react-router-dom";
// import { InitNode } from "./node";

const FlowPage = () => {
	const { id } = useParams();
	return (
		<div className={"page"}>
			<div className={"container"}>
				<XFlow>
					<Dnd />
					{/* <Dnd /> */}
					<XFlowGraph
						centerView
						zoomable
						zoomOptions={{
							minScale: 0.5,
							maxScale: 3,
						}}
						pannable
						fitView
						magnetAdsorbedHighlightOptions={{
							name: "stroke",
							args: {
								attrs: {
									fill: "#5F95FF",
									stroke: "#5F95FF",
								},
							},
						}}
						connectionOptions={{
							snap: true,
							allowBlank: false,
							allowLoop: false,
							highlight: true,
							anchor: "center",
							router: "orth",
							connector: "rounded",
						}}
						connectionEdgeOptions={{
							attrs: {
								line: {
									stroke: "rgb(72, 203, 164)",
									strokeWidth: 2,
									targetMarker: {
										name: "block",
										width: 14,
										height: 10,
									},
								},
							},
							zIndex: 0,
						}}
						selectOptions={{
							multiple: true,
							rubberband: true,
							strict: true,
							modifiers: "shift",
						}}
					/>

					<Transform resizing rotating />
					<Snapline />
					<Minimap simple />
					<History />
					<Clipboard />
					<Keyboard />
					<InitNode id={id} />
					<Connector />
				</XFlow>
			</div>
		</div>
	);
};

export default FlowPage;
