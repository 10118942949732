/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Layout,
	Card,
	Table,
	Space,
	Typography,
	Popconfirm,
	Select,
	Form,
	Input,
	Tooltip,
	Spin,
	Flex,
	Image,
	List,
	Popover,
	Pagination,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import TeamMemberNav from "./TeamMemberNav";
import ShareWorkSpaceModal from "../../component/ShareWorkspace";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Can } from "../../layout/AppLayout";
const { Content } = Layout;
const { Text } = Typography;

interface TeamMemberData {
	id: string;
	userProfile: string;
	workspaceID: string;
	roleID: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	//inputType: 'number' | 'text'| 'select';
	record: any;
	index: number;
	children: React.ReactNode;
	inputNodex: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	//inputType,
	//record,
	//index,
	inputNodex,
	children,
	...restProps
}) => {
	//let inputNode = inputType === 'number' || inputType === 'text' ? <Input /> : <Select><Option value="role1">Role 1</Option></Select>;

	//inputNode = <Select><Option value="role1">role1</Option></Select>;
	// inputNode = (
	// 	<Select>
	// 		{roles.map((role) => (
	// 			<Option key={role} value={role}>
	// 				{role}
	// 			</Option>
	// 		))}
	// 	</Select>
	// );

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0,
					}}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNodex}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

interface UserProfile {
	_id: string;
	name: string;
}

interface Workspace {
	_id: string;
	name: string;
}

interface Role {
	_id: string;
	name: string;
}

const { Option } = Select;

const TeamMemberV2: React.FC = () => {
	const { setUnauthorizedModel } = planerViewStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const [responseData, setResponseData] = useState<any[]>([]);
	const { FetchPlan, input, sortBy, Inviteusermodel, setInviteusermodel } =
		planerViewStore();
	const navigate = useNavigate();
	const [selectedTeamId, setSelectedTeamId] = useState(null);
	const [Workspaces, setWorkspaces] = useState<Workspace[]>([]);
	const [workspaceId, setWorkspaceId] = useState(null);
	const [editingKey, setEditingKey] = useState("");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [modalVisible, setModalVisible] = useState(false);
	const isEditing = (record: any) => record.key === editingKey;
	const [form] = Form.useForm();
	const [roles, setRoles] = useState<string[]>([]);
	const [selectedRole, setSelectedRole] = useState<string>("");
	const [loading, setLoading] = useState(true);
	const [popoverVisible, setPopoverVisible] = useState<string | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(20);

	interface RolesSelectProps {
		roles: any;
		onChange: (value: string) => void;
	}

	const RolesSelect: React.FC<RolesSelectProps> = ({ roles, onChange }) => {
		const handleChange = (value: string) => {
			onChange(value); // Propagate the selected value to the parent component
		};
		console.log("filterroles", roles);
		return (
			<Select
				onChange={handleChange}
				value={selectedRole}
				style={{ minWidth: 200 }}
			>
				{roles.map((role: any) => (
					<Option key={role._id} value={role._id}>
						{role.name}
					</Option>
				))}
			</Select>
		);
	};

	const handleRoleChange = (value: string) => {
		setSelectedRole(value); // Update the selected role
	};

	const fetchRoles = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/roles/`, {
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});

			if (response.ok) {
				const data = await response.json();
				console.log("data", data);
				setRoles(data);
				//return data;
			} else if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				console.error("Error fetching user details");
			}
		} catch (error) {
			console.error("Error fetching user details:", error);
		}
	};
	useEffect(() => {
		fetchRoles();
	}, []);

	const fetchworkspaceData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const json = await response.json();
			setWorkspaces(json);
			if (response.status === 401) {
				setUnauthorizedModel(true);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const cancel = () => {
		setEditingKey("");
	};

	const save = async (record: any & { key: React.Key }, value: string) => {
		console.log("record", record);
		console.log("reord_id", record._id);
		console.log("rolevaluedirect", value);
		try {
			const row = await form.validateFields();

			const newData: any = roles;
			console.log("newData", newData);
			const key = record.key;
			console.log("key", key);
			const index = newData.findIndex((item: any) => key == item._id);
			const filterrole: any = roles.filter((role: any) => role._id === value);
			console.log("index", index);
			if (index > -1) {
				const item: any = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
				// setData(newData);
				setEditingKey("");
			} else {
				// Call API to save edited roles
				console.log("row", row);
				console.log("selectedRole", selectedRole);
				console.log("filterrole", filterrole);
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/workspace-permissions/${key}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							roles: filterrole[0]._id,
							permission: filterrole[0].permissions,
						}),
					}
				);

				if (response.ok) {
					console.log("Roles updated successfully");
					fetchData();
				} else {
					console.error("Error updating roles");
				}
				// const dataa= await response.json();

				// newData.push({
				// 	...row,
				// 	_id: dataa._id,
				// 	key: dataa._id,
				// 	workspaceId: record.workspaceId,
				// 	roles: dataa.roles,
				// });
				// setData(newData);
				setEditingKey("");
				console.log("newData", newData);
			}
		} catch (errInfo) {
			console.log("Validate Failed:", errInfo);
		}
	};

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${currentWorkspace._id}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setResponseData(data);
				setLoading(false);
				console.log(data);
			} else if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				console.error("Error fetching team data");
			}
		} catch (error) {
			console.error("Error fetching team data:", error);
		}
	};

	useEffect(() => {
		fetchData();
		fetchworkspaceData();
	}, [workspaceId, setUnauthorizedModel, FetchPlan, Inviteusermodel]);

	const sortedAndFilteredData = (data: any) => {
		const filteredData = data.filter((item: any) => {
			let matchesFilter = true;
			const inputLower = input?.replace(/\s/g, "")?.toLowerCase();

			matchesFilter =
				matchesFilter &&
				(item?.userProfileId?.name
					?.replace(/\s/g, "")
					?.toLowerCase()
					?.includes(inputLower) ||
					item?.roles?.name
						?.replace(/\s/g, "")
						?.toLowerCase()
						?.includes(inputLower));

			return matchesFilter;
		});

		const test = [...filteredData].sort((a, b) => {
			console.log("sortBy", sortBy);
			if (sortBy === "Created date (Asc)") {
				return (
					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				);
			} else if (sortBy === "Created date (Desc)") {
				return (
					new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				);
			} else {
				return 0;
			}
		});

		return test;
	};

	const edit = (record: any) => {
		console.log("record", record);
		form.setFieldsValue({ Workspace: "", Roles: "", ...record });
		setEditingKey(record.key);
	};

	const Handledelete = async (id: any) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/workspace-permissions/${id}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);
		if (response.ok) {
			fetchData();
		}
	};

	const handleModalCancel = () => {
		setInviteusermodel(!Inviteusermodel);
	};

	const handleAddWorkItemClick = () => {
		setModalVisible(true);
	};

	console.log(responseData, "responseData");

	const handleEditTagClick = (id: any) => {
		setSelectedTeamId(id);
		navigate(`/space/planner/teammember/edit/${id}`);
	};

	const renderActions = (
		record: any,
		edit: (record: any) => void,
		Handledelete: (id: any) => void,
		isEditing: (record: any) => boolean,
		save: (record: any, value: string) => void,
		cancel: () => void
	) => {
		const editable = isEditing(record);
		const deleteDisabled = editable || record.editing;

		return editable ? (
			<Space size={"small"}>
				<Typography.Link
					// onClick={() => save({ key: record._id, ...record })}
					style={{
						marginRight: 8,
						fontFamily: "Open Sans",
						color: "#0444bf",
						fontSize: "16px",
					}}
				>
					Save
				</Typography.Link>
				<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
					<a
						style={{
							fontFamily: "Open Sans",
							color: "#0444bf",
							fontSize: "16px",
						}}
					>
						Cancel
					</a>
				</Popconfirm>
				<Popconfirm
					title="Sure to Delete?"
					onConfirm={() => {
						Handledelete(record._id);
					}}
					disabled={deleteDisabled}
				>
					<a
						style={{
							color: editingKey !== "" ? "red" : "red",
							fontSize: "12px",
							cursor: editingKey !== "" ? "not-allowed" : "pointer",
						}}
					>
						<DeleteOutlined
							style={{
								fontSize: "14px",
								color: editingKey !== "" ? "red" : "red",
							}}
						/>
					</a>
				</Popconfirm>
			</Space>
		) : (
			<Space size={"large"}>
				{/* <Tooltip title="Edit">
					<Typography.Link
						disabled={editingKey !== ""}
						onClick={() => {
							edit(record);
							setSelectedRole(record?.Role);
						}}
						style={{
							fontFamily: "Open Sans",
							cursor: editingKey !== "" ? "not-allowed" : "pointer",
						}}
					>
						<EditOutlined
							style={{ color: editingKey !== "" ? "#3478F6" : "#0444bf" }}
						/>
					</Typography.Link>
				</Tooltip> */}
				<Popover
					content={
						<RolesSelect
							roles={roles}
							onChange={(value) => {
								setSelectedRole(value);
								setPopoverVisible(null);
								save({ key: record._id, ...record }, value);
							}}
						/>
					}
					title="Select Role"
					trigger="click"
					visible={popoverVisible === record._id}
					onVisibleChange={(visible) => {
						setPopoverVisible(visible ? record._id : null);
					}}
				>
					<Tooltip title="Edit">
						<Typography.Link
							disabled={editingKey !== ""}
							onClick={() => {
								setPopoverVisible(record._id);
							}}
							style={{
								fontFamily: "Open Sans",
								cursor: editingKey !== "" ? "not-allowed" : "pointer",
							}}
						>
							<EditOutlined
								style={{ color: editingKey !== "" ? "#3478F6" : "#0444bf" }}
							/>
						</Typography.Link>
					</Tooltip>
				</Popover>
				<Tooltip title="Delete">
					<Popconfirm
						title="Sure to Delete?"
						onConfirm={() => {
							if (editingKey === "") {
								Handledelete(record._id);
							}
						}}
						disabled={editingKey !== ""}
					>
						<a
							style={{
								color: editingKey !== "" ? "red" : "red",
								fontSize: "12px",
								cursor: editingKey !== "" ? "not-allowed" : "pointer",
							}}
						>
							<DeleteOutlined
								style={{
									fontSize: "14px",
									color: editingKey !== "" ? "red" : "red",
								}}
							/>
						</a>
					</Popconfirm>
				</Tooltip>
			</Space>
		);
	};

	const renderListItem = (item: any) => (
		<List.Item key={item._id} style={{ padding: "0px 0" }}>
			<div className="list-item" style={{ marginLeft: "0.5vw" }}>
				<div className="list-item-description-left">
					<span
						className="work-item-title"
						style={{ width: 150, textAlign: "left" }}
					>
						{item?.userProfileId?.name}
					</span>
					{/* <span className="work-item-title">{item?.workspaceId?.name}</span> */}
				</div>
				<div className="list-item-description-right">
					<span
						className="work-item-title"
						style={{ width: 100, textAlign: "left" }}
					>
						{item?.roles?.name}
					</span>
					<div>
						{renderActions(item, edit, Handledelete, isEditing, save, cancel)}
					</div>
				</div>
			</div>
		</List.Item>
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const currentPageData = sortedAndFilteredData(responseData).slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		<Can I="view" a="team_member" passThrough>
			{(allowed) =>
				allowed ? (
					<Layout style={{ background: "#f5f8fe" }}>
						{loading ? ( // Show loading indicator while loading
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									minHeight: "100vh",
								}}
							>
								<Spin size="large" />
								<img
									style={{ marginTop: "10px", width: "150px" }}
									src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
									alt="Loading"
								/>
								<Spin size="large" />
							</div>
						) : (
							<>
								<TeamMemberNav />
								{/* <div
									className="list-view-container"
									style={{ marginLeft: "1vw" }}
								>
									<List
										itemLayout="vertical"
										dataSource={sortedAndFilteredData(responseData)}
										renderItem={renderListItem}
										className="list-view"
										size="small"
										pagination={{
											pageSize: 15,
										}}
									/> */}
								<div
									className="list-view-container"
									style={{
										display: "flex",
										flexDirection: "column",
										height: "76vh",
										padding: "0vw 1vw",
									}}
								>
									<div style={{ flex: "1 1 auto" }}>
										<List
											itemLayout="vertical"
											dataSource={currentPageData}
											renderItem={renderListItem}
											className="list-view"
											size="small"
										/>
									</div>
									<div
										style={{
											flex: "0 0 20px",
											textAlign: "end",
											marginTop: "3px",
											marginBottom: "10px",
										}}
									>
										<Pagination
											current={currentPage}
											total={responseData.length}
											pageSize={pageSize}
											onChange={handlePageChange}
											showSizeChanger={false}
										/>
									</div>
								</div>
								<ShareWorkSpaceModal
									visible={Inviteusermodel}
									onCancel={handleModalCancel}
									id={currentWorkspace._id}
								/>
							</>
						)}
					</Layout>
				) : (
					<Flex style={{ marginTop: "15vh" }} justify="center">
						<Image
							height={"50vh"}
							width={"60vw"}
							src={
								"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
							}
							preview={false}
						/>
					</Flex>
				)
			}
		</Can>
	);
};

export default TeamMemberV2;
