import React, { useState, useEffect } from "react";
import {
	Card,
	//Tabs,
	Input,
	Button,
	Table,
	Checkbox,
	Row,
	Col,
	Modal,
	Radio,
	//TableColumnsType,
	Flex,
	message,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { useNavigate, useParams } from "react-router-dom";
import useWorkspaceStore from "../stores/WorkspaceState";
import { useRequest } from "ahooks";
import {
	fetchWorkitemByType,
	getSelectedWorkItems,
	getWorkItemsById,
} from "../api/WorkItemAPI";

interface DataType {
	key: React.Key;
	title: string;
	description: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WorkItemRefinement = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	// /const { TabPane } = Tabs;
	// State for textarea value
	const navigate = useNavigate();
	//take wfitemId from the url path params
	const { wfitemId } = useParams();
	const [updateItems, setUpdateItems] = useState([]);
	const [method, setMethod] = useState("");
	const [aiInstructions, setAiInstructions] = useState("");
	const [workFlowItem, setWorkFlowItem] = useState<any>([]);
	const [workitems, setWorkitems] = useState<any>([]);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	// State for table data
	const [tableData, setTableData] = useState<any>([]);
	const [visible, setVisible] = useState(false);
	//const [selectedRows, setSelectedRows] = useState([] as DataType[]);
	const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
		"checkbox"
	);

	const showModal = () => {
		setVisible(true);
	};

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
			console.log(
				`selectedRowKeys: ${selectedRowKeys}`,
				"selectedRows: ",
				selectedRows
			);
			//setSelectedRows(selectedRows);
		},
		getCheckboxProps: (record: any) => ({
			name: record.title,
		}),
	};

	// Function to handle button click
	const handleOk = () => {
		setVisible(false);
		// Here you can handle the selectedRows
		//console.log(selectedRows);
	};

	// Function to handle modal close
	const handleCancel = () => {
		setVisible(false);
	};

	const { data: selectedWorkitemData, run: fetchWorkitemforSelected } =
		useRequest(
			(body) => getSelectedWorkItems(body, userProfileStoreInstance.idToken),
			{
				manual: true,
				onSuccess: (result) => {
					console.log(result, "result");
					message.success("Workitem fetched successfully");
				},
				onError: (error) => {
					console.log(error, "error");
					message.error("Failed to fetch workitem");
				},
			}
		);

	useEffect(() => {
		if (selectedWorkitemData) {
			setWorkitems(selectedWorkitemData);
		}
	}, [selectedWorkitemData]);

	const { data: workitemByIdData, run: getWorkItemById } = useRequest(
		(workItemId) =>
			getWorkItemsById(workItemId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.info(
					`${result.title.slice(0, 1).toUpperCase() + result.title.slice(1)} Link`
				);
				console.log(result, "feature data");
			},
			onError: (error) => {
				message.error(`Failed to fetch workitem feature : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemByIdData) {
			setUpdateItems(workitemByIdData?.data?.items);
			setWorkFlowItem(workitemByIdData);
			fetchWorkitemforSelected(workitemByIdData?.data?.items);
		}
	}, [workitemByIdData]);

	useEffect(() => {
		getWorkItemById(wfitemId);
	}, [wfitemId]);

	const { data: workitemFeatureData, run: fetchFeature } = useRequest(
		(worspaceId, type) =>
			fetchWorkitemByType(worspaceId, type, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "feature data");
			},
			onError: (error) => {
				message.error(`Failed to fetch workitem feature : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemFeatureData) {
			setTableData(workitemFeatureData);
		}
	}, [workitemFeatureData]);

	useEffect(() => {
		fetchFeature(currentWorkspace?._id, "FEATURE");
	}, []);

	const columns = [
		{
			title: "Id",
			dataIndex: "WorkitemId",
			key: "WorkitemId",
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
		},
	];

	const updateWorkFlowItem = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${wfitemId}`,
				{
					method: "PUT",
					body: JSON.stringify({
						...workFlowItem,
						data: {
							method: method,
							aiInstructions: aiInstructions,
							items: updateItems,
						},
					}),
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const data = await response.json();

			if (!response.ok) {
				throw new Error(`status: ${response.status} message: ${data.message}`);
			} else {
				navigate(`/wfitemfd/refinement/fr/${wfitemId}`);
			}
			console.log(data);
		} catch (error) {
			console.error("Error updating workflow item:", error);
		}
	};

	// useEffect(() => {
	// 	setUpdateItems(workitems);
	// }, [workitems]);

	const handleInputSubmission = () => {
		updateWorkFlowItem();
	};

	const handleMethod = (value: any) => {
		setMethod(value);
		console.log(value);
	};

	return (
		<div style={{ textAlign: "left" }}>
			<Row gutter={16}>
				<Col span={12}>
					<Flex vertical>
						<h2>Refinement</h2>
						<p>
							Process of improving or enhancing a specific aspect of a product
						</p>
					</Flex>
				</Col>
				<Col span={12}>
					<Flex style={{ justifyContent: "flex-end" }}>
						<Button type="primary" onClick={handleInputSubmission}>
							Proceed
						</Button>
					</Flex>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={8}>
					<Card style={{ textAlign: "left" }}>
						<Flex
							vertical
							style={{ textAlign: "left", padding: "1rem" }}
							gap={4}
						>
							<Checkbox
								value="alchemi"
								checked={method === "alchemi"}
								onChange={(e) => handleMethod(e.target.value)}
							>
								Alchemi
							</Checkbox>

							<div style={{ paddingLeft: "1rem" }}>
								This option AI helps in the process of refine the work item
								which provides the necessary artifacts{" "}
							</div>
						</Flex>
					</Card>
				</Col>
				<Col span={8}>
					<Card style={{ textAlign: "left" }}>
						<Flex
							vertical
							style={{ textAlign: "left", padding: "1rem" }}
							gap={4}
						>
							<Checkbox
								value="manual"
								checked={method === "manual"}
								onChange={(e) => handleMethod(e.target.value)}
							>
								Manual
							</Checkbox>
							<div>
								This option provide you with flexibility for owner to provide
								all the details{" "}
							</div>
						</Flex>
					</Card>
				</Col>
			</Row>
			{/* Textarea */}
			<h5 style={{ textAlign: "left" }}>
				Provide the instruction if any for the Alchemi for the refinement
				process
			</h5>
			<Input.TextArea
				placeholder="A design system for enterprise level products. Create an efficient and enjoyable work experience."
				value={aiInstructions}
				onChange={(e) => setAiInstructions(e.target.value)}
				rows={4}
			/>

			{/* Tabs */}
			{/* <Tabs defaultActiveKey="tab1">
				<TabPane tab="Features" key="tab1"> */}
			{/* Button */}
			<div style={{ marginTop: "20px", textAlign: "right" }}>
				<Button type="primary" onClick={showModal}>
					Add Feature
				</Button>
			</div>
			<Modal
				width={800}
				title="Add Feature"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Radio.Group
					onChange={({ target: { value } }) => {
						setSelectionType(value);
					}}
					value={selectionType}
				>
					<Radio value="checkbox">checkbox</Radio>
					<Radio value="radio">radio</Radio>
				</Radio.Group>
				<Table
					rowSelection={{
						type: selectionType,
						...rowSelection,
					}}
					dataSource={tableData}
					columns={columns}
					style={{ marginTop: "20px" }}
					rowKey="_id"
				/>
			</Modal>
			{/* Table to display selected features */}
			<Table columns={columns} dataSource={workitems} rowKey="_id" />
			{/* </TabPane>
				<TabPane tab="Additional Context" key="tab2">
					
					<p>Content for Tab 2</p>
				</TabPane>
			</Tabs> */}
		</div>
	);
};

export default WorkItemRefinement;
