import { create } from "zustand";
import { persist } from "zustand/middleware";

export type WorkspaceStoreState = {
	data: any[];
	setData: (data: any) => void;
	currentWorkspace: null;
	setCurrentWorkspace: (currentWorkspace: any) => void;
	currenctActivity: null;
	setCurrentActivity: (currentActivity: any) => void;
	reviewData: any[];
	setReviewData: (reviewData: any[]) => void;
	workspacePermissions: any[];
	setworkspacePermissions: (workspacePermissions: any[]) => void;
	currentState: null;
	setCurrentState: (currentState: any) => void;
	workspaceStatus: null;
	setWorkspaceStatus: (workspaceStatus: any) => void;
	isReviewEnabled: false;
	setIsReviewEnabled: (isReviewEnabled: boolean) => void;
	addWorkspaceDetails: any;
	setAddWorkspaceDetails: (addWorkspaceDetails: any) => void;
	addWorkspaceStepsConfig: any;
	dataSchema: any;
	setDataSchema: (dataSchema: any[]) => void;
	InformationArchitecture: boolean;
	setInformationArchitecture: (InformationArchitecture: boolean) => void;
	setAddWorkspaceStepsConfig: (addWorkspaceStepsConfig: any) => void;
	postCallbacks: Record<string, () => void>;

	registerPostCallback: (componentName: string, callback: () => void) => void;
	clearStore: () => void;
};

const useWorkspaceStore = create(
	persist(
		(set) => ({
			dddSpecOnclick: null,
			setDddSpecOnclick: (dddSpecOnclick: any) => set({ dddSpecOnclick }),
			dataSchema: [],
			setDataSchema: (dataSchema: any[]) => set({ dataSchema: dataSchema }),
			data: [],
			setData: (newData: any) => set({ data: newData }),
			currentWorkspace: null,
			setCurrentWorkspace: (currentWorkspace: any) =>
				set({ currentWorkspace: currentWorkspace }),
			currentActivity: null,
			setCurrentActivity: (currentActivity: any) =>
				set({ currentActivity: currentActivity }),
			reviewData: [],
			setReviewData: (reviewData: any[]) => set({ reviewData: reviewData }),
			workspacePermissions: [],
			setworkspacePermissions: (workspacePermissions: any[]) =>
				set({ workspacePermissions: workspacePermissions }),
			currentState: null,
			setCurrentState: (currentState: any) =>
				set({ currentState: currentState }),
			isReviewEnabled: false,
			setIsReviewEnabled: (isReviewEnabled: boolean) =>
				set({ isReviewEnabled: isReviewEnabled }),
			addWorkspaceDetails: {},
			setAddWorkspaceDetails: (addWorkspaceDetails: any) =>
				set({ addWorkspaceDetails: addWorkspaceDetails }),
			addWorkspaceStepsConfig: {},

			setAddWorkspaceStepsConfig: (addWorkspaceStepsConfig: any) =>
				set({ addWorkspaceStepsConfig: addWorkspaceStepsConfig }),
			postCallbacks: {},
			registerPostCallback: (componentName: string, callback: () => void) =>
				set((state: any) => ({
					postCallbacks: { ...state.postCallbacks, [componentName]: callback },
				})),

			InformationArchitecture: false,
			setInformationArchitecture: (InformationArchitecture: boolean) =>
				set({ InformationArchitecture: InformationArchitecture }),
			clearStore: () =>
				set({
					data: [],
					currentWorkspace: null,
					currentActivity: null,
					reviewData: [],
					currentState: null,
					isReviewEnabled: false,
					addWorkspaceDetails: {},
					addWorkspaceStepsConfig: {},
					postCallbacks: {},
					InformationArchitecture: false,
					dataSchema: [],
				}),
		}),

		{
			name: "workspaceStore",
		}
	)
);

export default useWorkspaceStore;
