import React, { useState } from "react";
import "./AppLayout.css";
//import EditWorkspaceModal from "../Alchemi/EditWorkspace";
import //PlusCircleOutlined,
//DeploymentUnitOutlined,
//EditOutlined,
//UserOutlined,
//BulbTwoTone,
//SettingOutlined,
"@ant-design/icons";
import {
	//Avatar,
	// Button,
	// //Card,
	// Checkbox,
	// Col,
	FloatButton,
	// Image,
	//Empty,
	// Form,
	// Input,
	Layout,
	//Modal,
	//Progress,
	//Row,
	// Spin,
} from "antd";
import { Outlet } from "react-router-dom";
//import ShareWorkSpaceModal from "../component/ShareWorkspace";
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../authConfig";
import AppHeader from "./AppHeader";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Help from "../pages/Help";
import AppNavbar from "./AppNavbar";

function WorkspaceLayout() {
	// const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { Content } = Layout;
	// const [Workspace, setWorkspace] = useState([]);
	//const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
	//const [isShareModalVisible, setIsShareModalVisible] = useState(false);
	// const { instance, accounts } = useMsal();

	// const [authenticated, setAuthenticated] = useState(false);

	// useEffect(() => {
	// 	const checkAuthentication = async () => {
	// 		if (accounts.length === 0) {
	// 			try {
	// 				await new Promise((resolve) => {
	// 					setTimeout(resolve, 1000);
	// 				});

	// 				await instance.loginRedirect(loginRequest);

	// 				setAuthenticated(true);
	// 			} catch (error) {
	// 				console.error(error);
	// 			}
	// 		} else {
	// 			setAuthenticated(true);
	// 		}
	// 	};

	// 	checkAuthentication();
	// }, [instance, accounts.length]);

	// console.log("workspace", Workspace.length);

	// const showModal = () => {
	// 	setOpen(true);
	// };

	// const formatDateDifference = (updatedAt: any) => {
	// 	const updatedAtDate = new Date(updatedAt);
	// 	const currentDate: Date = new Date();

	// 	const timeDifference = currentDate.getTime() - updatedAtDate.getTime();
	// 	const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	// 	const monthsDifference =
	// 		currentDate.getMonth() -
	// 		updatedAtDate.getMonth() +
	// 		12 * (currentDate.getFullYear() - updatedAtDate.getFullYear());

	// 	if (daysDifference < 1) {
	// 		return "Today";
	// 	} else if (daysDifference === 1) {
	// 		return "1 day ago";
	// 	} else if (daysDifference < 30) {
	// 		return `${daysDifference} days ago`;
	// 	} else if (monthsDifference === 1) {
	// 		return "1 month ago";
	// 	} else if (monthsDifference > 1) {
	// 		return `${monthsDifference} months ago`;
	// 	} else {
	// 		return "Today";
	// 	}
	// };

	// const handleSettingsClick = () => {
	// 	navigate("/settings/role");
	// };

	// const onFinish = (values: any) => {
	// 	fetch(`${ServerLink}/workspace`, {
	// 		method: "POST",
	// 		body: JSON.stringify({
	// 			name: values.WorkspaceName,
	// 			modules: values.checkbox,
	// 			currentModule: "Testing",
	// 			createdBy: "Testing",
	// 			updatedBy: "Testing",
	// 		}),
	// 		headers: {
	// 			"Content-type": "application/json; charset=UTF-8",
	// 			Authorization: `Bearer ${authStore.idToken}`,
	// 		},
	// 	})
	// 		.then((response) => response.json())
	// 		.then((json) => {
	// 			if (json !== undefined) {
	// 				handleCancel();
	// 				form.resetFields();
	// 				fetchData();
	// 				navigate(`/describe/${json._id}`);
	// 				// console.log("Data after post", json);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 		});
	// };

	// const handleCancel = () => {
	// 	setOpen(false);
	// };

	// const showEditModal = () => {
	// 	setIsEditModalVisible(true);
	// };

	// const handleEditClick = (workspaceId: any) => {
	// 	setSelectedWorkspaceId(workspaceId);
	// 	console.log(selectedWorkspaceId);
	// 	showEditModal();
	// };

	// const handleEditModalCancel = () => {
	// 	setIsEditModalVisible(false);
	// 	fetchData();
	// };

	// const showShareModal = () => {
	// 	setIsShareModalVisible(true);
	// };

	// const handleShareClick = (id: any) => {
	// 	setSelectedWorkspaceId(id);
	// 	console.log(selectedWorkspaceId);
	// 	showShareModal();
	// };

	// Function to close the edit modal
	// const handleShareModalCancel = () => {
	// 	setIsShareModalVisible(false);
	// };
	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const handleNavbarModelButtonClick = () => {
		// Trigger the post request logic here
		// For example, you can call a function or use a state to handle the post request
		// handlePostRequest();
		console.log("Yes button clicked! Perform post request logic here.");
	};

	return (
		<Layout className="LandingPage" style={{ height: "100vh" }}>
			{/* {authenticated ? ( */}
			<>
				<AppHeader />

				<Content
					style={{ height: "90vh", overflow: "hidden", paddingTop: ".2rem" }}
				>
					{/* <div className="LandingPageNavbar">
							<div onClick={() => navigate("/")} className="logo">
								Alchemi
							</div>

							<div className="nav-items flex">
								<div
									style={{
										display: "flex",
										float: "right",
										marginRight: "1px",
									}}
								>
									<SettingOutlined
										onClick={handleSettingsClick}
										style={{ fontSize: "19px", marginLeft: "20px" }}
									/>
								</div>

								<div className="avatar">
									<Avatar
										src="https://res.cloudinary.com/dvdljupjx/image/upload/v1663236684/samples/people/kitchen-bar.jpg"
										icon={<UserOutlined />}
									/>
								</div>
							</div>
						</div> */}
					<AppNavbar
						breadcrumbs={["workspace"]}
						menuType="workspace"
						reviewEnabled={true}
						onYesClick={handleNavbarModelButtonClick}
					/>

					<Outlet />
					<FloatButton icon={<QuestionCircleOutlined />} onClick={showDrawer} />
					<Help open={open} setOpen={setOpen} />
					{/* <Modal
							data-testid="Modal"
							className="Modal"
							title={<h3>Create Workspace</h3>}
							open={open}
							onCancel={handleCancel}
							footer={null}
						>
							<Form
								name="workspaceForm"
								form={form}
								onFinish={onFinish}
								initialValues={{ workspaceName: "", description: "" }}
								className="ModalForm"
							>
								<Form.Item
									data-testid="ModalInputWorkspace"
									name="WorkspaceName"
									rules={[
										{ required: true, message: "WorkspaceName is required" },
										{ min: 5 },
									]}
								>
									<Input
										className="ModalInputWorkspace"
										placeholder="Workspace Name"
									/>
								</Form.Item>

								<Form.Item
									data-testid="ModalCheckbox"
									rules={[
										{
											required: true,
											message: "Please select at least one checkbox",
										},
									]}
									initialValue={[
										"Business Engineering",
										"Feature Engineering",
										"Design Engineering",
										"Code Engineering",
									]}
									name="checkbox"
								>
									<Checkbox.Group
										style={{ width: "65%" }}
										onChange={() => {}}
										className="Checkbox"
									>
										<Row gutter={[10, 10]}>
											<Col span={11}>
												<Checkbox value="Business Engineering">
													Business Engineering
												</Checkbox>
											</Col>
											<Col span={11}>
												<Checkbox value="Feature Engineering">
													Feature Engineering
												</Checkbox>
											</Col>
											<Col span={11}>
												<Checkbox value="Design Engineering">
													Design Engineering
												</Checkbox>
											</Col>
											<Col span={11}>
												<Checkbox value="Code Engineering">
													Code Engineering
												</Checkbox>
											</Col>
										</Row>
									</Checkbox.Group>
								</Form.Item>
								<div className="create-button">
									<Button
										data-testid="FormSubmittButton"
										className="FormSubmittButton"
										type="primary"
										htmlType="submit"
									>
										Create
									</Button>
								</div>
							</Form>
						</Modal> */}
				</Content>
			</>
			{/* // ) : (
			// 	<div className="loader-container">
			// 		<Spin size="large" />
			// 		<Image
			// 			style={{ marginTop: "10px" }}
			// 			width={150}
			// 			preview={false}
			// 			src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
			// 		/>
			// 		<Spin size="large" />
			// 	</div>
			// )} */}
		</Layout>
	);
}

export default WorkspaceLayout;
