// import { StarOutlined } from "@ant-design/icons";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Bookmark from "../Bookmark/Bookmark";
//import breadcrumbConfig from "../../util/BreadCrumbsUtil";

interface BreadcrumbItem {
	label: string;
	url: string;
	dynamicId?: boolean;
}

interface PageConfig {
	page: string;
	config: BreadcrumbItem[];
}

interface Props {
	separator?: string;
	config: PageConfig;
	workItemId?: string;
}

const BreadCrumb: React.FC<Props> = ({
	separator = "/",
	config,
	workItemId,
}) => {
	const { config: breadcrumbItems } = config;
	const location = useLocation();

	const replaceDynamicId = (url: string): string => {
		const id = location.pathname.split("/").pop();
		return url.replace(":id", id || "");
	};

	const getItemLabel = (item: any): string => {
		if (item.label === "Details" && workItemId) {
			return workItemId;
		}
		return item.label;
	};

	return (
		<nav aria-label="breadcrumb">
			<ol style={{ listStyleType: "none", padding: 0, display: "flex" }}>
				{breadcrumbItems.map((item, index) => (
					<li key={item.url} style={{ display: "flex", alignItems: "center" }}>
						{index > 0 && <span style={{ margin: "0 5px" }}>{separator}</span>}
						{/* <StarOutlined style={{ marginRight: "10px", fontSize: "1.1rem"}} /> */}
						{/* <Bookmark /> */}
						<Link
							to={item.dynamicId ? replaceDynamicId(item.url) : item.url}
							style={{ fontSize: "1rem", fontWeight: "bold" }}
						>
							{getItemLabel(item)}
						</Link>

						{index === breadcrumbItems.length - 1 && <Bookmark />}

						{/* <Bookmark /> */}
					</li>
				))}
			</ol>
		</nav>
	);
};

export default BreadCrumb;
