import { Button, Flex, Tag, Typography } from "antd";
import React, { useEffect } from "react";
import useWorkspaceStore from "../../stores/WorkspaceState";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import moment from "moment";
import { BulbFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface AlchemiRecommendationListProps {
	// Define your component props here
}

const AlchemiRecommendationList: React.FC<AlchemiRecommendationListProps> = (
	props
) => {
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [aiRecommendations, setAIRecommendations] = React.useState([]);
	const getAIRunsByWorkspace = async () => {
		// Implement your code here

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/alrecommendations/${currentWorkspace._id}?status=ALL`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					"Content-Type": "application/json; charset=UTF-8",
				},
			}
		);

		const result = await response.json();
		setAIRecommendations(result);
	};
	const navigate = useNavigate();
	// const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const updateRecommendation = async (recommendation: any) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/alrecommendations/${recommendation._id}`,
			{
				method: "PUT",
				body: JSON.stringify(recommendation),
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);

		return response;
	};
	const handleMarkAsRead = async (recommendation: any) => {
		recommendation.status = "READ";
		await updateRecommendation(recommendation);
		console.log("Mark as read", recommendation);
	};

	const handleAction = async (recommendation: any) => {
		recommendation.status = "READ";
		await updateRecommendation(recommendation);
		console.log("Mark as read", recommendation);
		if (recommendation.category == "aiRun") {
			if (recommendation.data.type == "WORKFLOW") {
				if (recommendation.data.stage == "FEATURE_REFINEMENT") {
					navigate(`/fd/airun/feature-refinement`);
				} else if (recommendation.data.stage == "FEATURE_ELABORATION") {
					navigate(`/fd/airun/feature-elaboration`);
				} else if (recommendation.data.stage == "UX_SPECIFICATION") {
					navigate(`/fd/airun/de-ux-spec`);
				} else if (recommendation.data.stage == "HIFI_MOCKUP") {
					navigate(`/fd/airun/de-hifi-mock-up`);
				} else if (recommendation.data.stage == "DDD_SPECIFICATION") {
					navigate(`/fd/airun/de-ddd-spec`);
				} else if (
					recommendation.data.stage == "MICRO_FRONTEND_SPECIFICATION"
				) {
					navigate(`/fd/airun/de-micro-frontend`);
				} else if (recommendation.data.stage == "API_SPECIFICATION") {
					navigate(`/fd/airun/de-api-spec`);
				} else if (recommendation.data.stage == "DATA_SCHEMA") {
					navigate(`/fd/airun/de-data-schema`);
				}
			}
		}
		console.log("Do elobarate", recommendation);
	};
	useEffect(() => {
		// Add your code here
		console.log(props);
		getAIRunsByWorkspace();
	}, []);

	return (
		<Flex
			style={{
				width: "24vw",
				minHeight: "70vh",
				maxHeight: "90vh",
				overflow: "scroll",
			}}
			vertical
		>
			<Flex
				style={{
					width: "100%",
				}}
				justify="space-between"
			>
				&nbsp;
			</Flex>
			<>
				<Typography.Text style={{ padding: "6px", fontSize: "12px" }}>
					Alchemi recommendations for...
				</Typography.Text>
				<Flex
					style={{ padding: "8px" }}
					wrap="wrap"
					justify="space-evenly"
					gap="14px 2px"
				>
					{aiRecommendations.map((recommendation: any, index) => (
						<Flex
							key={recommendation._id}
							style={{
								width: "100%",
								background: "white",
								padding: "8px",

								borderRadius: "12px",
								boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.2)",
							}}
						>
							<Flex vertical>
								<Flex>
									<Flex
										justify="flex-start"
										align="flex-start"
										style={{ margin: "4px" }}
									>
										<BulbFilled style={{ color: "blue" }} />
									</Flex>

									<Flex
										justify="flex-end"
										align="flex-end"
										style={{ width: "100%" }}
									>
										<Button
											type="link"
											style={{ fontSize: "10px", color: "grey" }}
											onClick={() => handleMarkAsRead(recommendation)}
										>
											Mark as read{" "}
										</Button>
									</Flex>
								</Flex>
								<Flex>
									<Flex vertical style={{ margin: "2px" }}>
										<Typography.Paragraph
											style={{ fontSize: "12px", textAlign: "left" }}
											ellipsis={{ rows: 2 }}
										>
											{recommendation.content}
										</Typography.Paragraph>
										<Flex style={{ width: "100%" }}>
											<Flex
												justify="flex-start"
												align="flex-start"
												style={{ width: "100%" }}
											>
												<Tag
													color="blue"
													key={index}
													style={{
														fontSize: "9px",
														borderRadius: "16px",
														marginBottom: "0px",
													}}
												>
													{moment(recommendation.createdAt).fromNow()}
												</Tag>
											</Flex>
											<Flex
												justify="right"
												align="flex-end"
												style={{ width: "100%" }}
											>
												<Button
													type="default"
													size="small"
													style={{
														fontSize: "12px",
														color: "",
														float: "right",
													}}
													onClick={() => handleAction(recommendation)}
												>
													Let&apos;s work{" "}
												</Button>
											</Flex>
										</Flex>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					))}
				</Flex>
			</>
			{/* <List
				pagination={{ position: "bottom", align: "center", pageSize: 5 }}
				dataSource={aiRecommendations}
				renderItem={(item: any, index) => (
					<List.Item key={item._id}>
						

						<Flex style={{ width: "100%" }} vertical>
							{item.content}
							<Flex style={{ width: "100%" }} justify="space-between">
								<Flex justify="flex-start">
									<Tag color="blue" key={index}>
										{item.status}
									</Tag>
								</Flex>
								<Flex justify="flex-end" align="flex-end">
									<Tag color="blue" key={index}>
										{moment(item.createdAt).fromNow()}
									</Tag>
								</Flex>
							</Flex>
						</Flex>
					</List.Item>
				)}
			/> */}
		</Flex>
	);
};

export default AlchemiRecommendationList;
