import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import { Layout, Flex, Tabs, Card, Tag, message } from "antd";
import TaskChatInterface from "./TaskDiscussionTab";
import ViewTaskActivity from "./ViewTaskActivity";
import QuickViewBacklog from "./QuickviewBacklog";
import MapViewTab from "./MapViewTab";

import ViewPageNavbar from "../../layout/ViewPageNavbar";
import { useRequest } from "ahooks";
import { getWorkItemsById } from "../../api/WorkItemAPI";

// interface BacklogDetails {
// 	_id: string;
// 	title: string;
// 	type: string;
// 	status: string;
// 	assignee: {
// 		name: string;
// 		email: string;
// 		preferences: {
// 			profileColor: string;
// 		};
// 	};
// 	description: string;
// 	// Add other properties from the response as needed
// }
const { TabPane } = Tabs;
const { Content } = Layout;

const ViewBacklog = () => {
	const { taskId } = useParams();
	const { open } = planerViewStore();
	console.log(open, "open");
	const [backlogDetails, setBacklogDetails] = useState<any | null>(null);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [activeTab, setActiveTab] = useState<string>("details");

	const handleTabChange = (key: string) => {
		setActiveTab(key);
	};

	const { data: workitemByIdData, run: getWorkItemById } = useRequest(
		(workItemId) =>
			getWorkItemsById(workItemId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.success(`${result.title} ${result.type} details`);
				console.log(result, "feature data");
			},
			onError: (error) => {
				message.error(`Failed to fetch workitem feature : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemByIdData) {
			setBacklogDetails(workitemByIdData);
		}
	}, [workitemByIdData]);

	useEffect(() => {
		getWorkItemById(taskId);
	}, [taskId]);

	// const navigate = useNavigate();
	// const handleNavigate = () => {
	// 	navigate("/space");
	// };

	const renderbacklogDetails = () => {
		if (!backlogDetails) {
			return <div>Loading...</div>;
		}
		return (
			<Layout style={{ background: "white" }}>
				<Flex style={{ alignItems: "center" }} gap={20}>
					<h3 style={{ marginTop: "3vh", marginLeft: "8vw" }}>
						{backlogDetails?.title}
					</h3>
					<Tag color="blue">{backlogDetails?.type}</Tag>
				</Flex>
				<Card
					style={{
						width: "90%",
						marginTop: "2vh",
						maxHeight: "60vh",
						overflowY: "auto",
						marginLeft: "5vw",
					}}
				>
					<Layout style={{ background: "white" }}>
						<Content style={{ padding: "20px" }}>
							<div style={{ marginLeft: "20px" }}>
								<>
									{/* <Flex style={{ alignItems: "center" }} gap={20}>
											<h3>{backlogDetails?.title}</h3>
											<Tag color="blue">{backlogDetails?.type}</Tag>
										</Flex> */}
									<Tabs
										defaultActiveKey="details"
										activeKey={activeTab}
										onChange={handleTabChange}
									>
										<TabPane tab="Details" key="details">
											{/* {backlogDetails.type === "FEATURE" && (
													<QuickViewBacklog taskId={taskId} />
												)}
												{(backlogDetails?.type === "USER STORY" ||
													backlogDetails?.type === "USER_STORY") && (
													<QuickViewBacklog taskId={taskId} />
												)}
												{backlogDetails?.type === "WORKFLOW_TASK" && (
													<QuickViewBacklog taskId={taskId} />
												)}
												{backlogDetails?.type === "BUG" && (
													<QuickViewBacklog taskId={taskId} />
												)} */}
											<QuickViewBacklog taskId={taskId} />
										</TabPane>
										<TabPane tab="Discussion" key="discussion">
											<TaskChatInterface taskId={taskId} />
										</TabPane>
										<TabPane tab="Activity" key="activity">
											<ViewTaskActivity taskId={taskId} />
										</TabPane>
										<TabPane tab="MapView" key="mapview">
											<MapViewTab />
										</TabPane>
									</Tabs>
								</>
							</div>
						</Content>
					</Layout>
				</Card>
			</Layout>
		);
	};

	return (
		<Layout style={{ backgroundColor: "white" }}>
			<Layout style={{ marginTop: ".5vh", backgroundColor: "#f5f8fe" }}>
				<ViewPageNavbar />
				<div>{renderbacklogDetails()}</div>
			</Layout>
		</Layout>
	);
};

export default ViewBacklog;
