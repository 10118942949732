/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Divider, Flex, Row } from "antd";
import React, { useState } from "react";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import BreadCrumb from "../Editors/BreadCrumb";
import AddRolesModal from "./AddRoleModal";
import { motion } from "framer-motion";
import { EditOutlined } from "@ant-design/icons";

function RoleManagementNav(): any {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [responseData, setResponseData] = useState<any[]>([]);

	const handleAddRoleClick = () => {
		setIsModalVisible(true);
	};

	const handleAddRole = (newRole: any) => {
		setResponseData([...responseData, newRole]);
	};

	const [isHovered, setIsHovered] = useState(false);
	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		<>
			<Divider className="ant-divider-horizontal" />
			<Flex
				justify="space-between"
				align="center"
				style={{
					margin: "0 1vw",
					marginTop: "-0.5vh",
				}}
			>
				<Flex style={{ marginLeft: "1vw" }} className="breadcrumb">
					<BreadCrumb config={breadcrumbConfig.rolemanagement} />
				</Flex>
				<div>
					<motion.div
						initial="initial"
						animate={isHovered ? "hover" : "initial"}
						variants={buttonVariants}
						transition={{ duration: 0.3 }}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
						className="add-work-item-button"
						onClick={handleAddRoleClick}
					>
						<EditOutlined />
						{isHovered && <span style={{ marginLeft: "8px" }}>Add Role</span>}
					</motion.div>
				</div>
				<AddRolesModal
					isVisible={isModalVisible}
					setIsVisible={setIsModalVisible}
					onAddRole={handleAddRole}
				/>
			</Flex>
		</>
	);
}

export default RoleManagementNav;
