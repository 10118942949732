import React, { useState, useEffect } from "react";
import { Form, Flex, Button, Select, Typography, message, Layout } from "antd";
import "./AddMember.css";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";

const { Content } = Layout;

const EditMemberLayout: React.FC = () => {
	interface Workspace {
		_id: number;
		name: string;
	}
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));

	const [form] = Form.useForm();
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [Workspaces, setWorkspaces] = useState<Workspace[]>([]);
	const [UserresponseData, setUserResponseData] = useState<any[]>([]);
	const [responseData, setResponseData] = useState<any[]>([]);
	const { Text } = Typography;

	const [teamMemberData, setTeamMemberData] = useState<teamMemberData>({
		_id: 0,
		UserProfile: 0,
		workspaceId: 0,
		roleId: 0,
	});

	const ServerLink = process.env.REACT_APP_API_URL;

	const fetchData = async () => {
		try {
			const response = await fetch(`${ServerLink}/workspace`, {
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			const json = await response.json();
			setWorkspaces(json);
			if (response.status === 401) {
				setUnauthorizedModel(true);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleSaveClick = async () => {
		const values = form.getFieldsValue([
			"UserProfile",
			"workspaceId",
			"roleId",
		]);
		console.log("values", values);
		try {
			const values = await form.validateFields();
			const updatedTeamMemberResponse = await fetch(
				`${ServerLink}/team-members/${teamMemberData._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						UserProfile: values.UserProfile,
						workspaceId: values.workspaceId,
						roleId: values.roleId,
						UpdatedBy: "dummyUpdatedByUserID",
					}),
				}
			);

			if (!updatedTeamMemberResponse.ok) {
				console.error(
					"Error updating team member data:",
					updatedTeamMemberResponse.statusText
				);
				return;
			}

			const responseData = await updatedTeamMemberResponse.json();
			console.log("Response from the backend:", responseData);
			message.success("Team member updated successfully");
			navigate("/planner/teammember");
		} catch (error: any) {
			message.error(
				`Error updating team member. Please try again: ${error.message}`
			);
		}
	};

	useEffect(() => {
		// Assuming you have a function to fetch team member data based on an ID
		const fetchTeamMemberData = async () => {
			try {
				const response = await fetch(
					`${ServerLink}/team-members/${teamMemberData._id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);

				if (response.ok) {
					const responseData = await response.json();
					setTeamMemberData(responseData);
					form.setFieldsValue({
						UserProfile: responseData.UserProfile,
						workspaceId: responseData.workspaceId,
						roleId: responseData.roleId,
					});
				} else {
					console.error(
						"Error fetching team member data:",
						response.statusText
					);
				}
			} catch (error) {
				console.error("Error fetching team member data:", error);
			}
		};

		fetchTeamMemberData();
	}, [teamMemberData._id, form, userProfileStoreInstance.idToken]);

	useEffect(() => {
		const fetchRolesData = async () => {
			try {
				const response = await fetch(`${ServerLink}/roles`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					setResponseData(data);
				} else {
					console.error("Error fetching roles data");
				}
			} catch (error) {
				console.error("Error fetching roles data:", error);
			}
		};

		fetchRolesData();
	}, []);

	useEffect(() => {
		const UserProfileData = async () => {
			try {
				const userProfileResponse = await fetch(`${ServerLink}/user-profiles`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				const json = await userProfileResponse.json();
				setUserResponseData(json);
				if (userProfileResponse.status === 401) {
					setUnauthorizedModel(true);
				}
			} catch (error) {
				console.error(error);
			}
		};

		UserProfileData();
	}, []);

	return (
		<Layout>
			<Content className="edit-member-layout">
				<Typography.Title>Edit Team Member</Typography.Title>
				<Form form={form} layout="vertical">
					<Text className="titleTypoAddMember">Select User</Text>
					<Form.Item name="UserProfile">
						<Select
							placeholder="Select UserProfile"
							className="formInputMember"
							size="middle"
							dropdownStyle={{ width: 500 }}
						>
							{UserresponseData.length > 0 &&
								UserresponseData.map((userprofile) => (
									<Select.Option key={userprofile._id} value={userprofile._id}>
										{userprofile.name}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
					<Text className="titleTypoAddMember">Select Workspace</Text>
					<Form.Item name="workspaceId">
						<Select
							placeholder="Select Workspaces"
							className="formInputMember"
							size="middle"
							dropdownStyle={{ width: 500 }}
						>
							{Workspaces.length > 0 &&
								Workspaces.map((workspace) => (
									<Select.Option key={workspace._id} value={workspace._id}>
										{workspace.name}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
					<Text className="titleTypoAddMember">Select Role</Text>
					<Form.Item name="roleId">
						<Select
							placeholder="Select Roles"
							className="formInputMember"
							size="middle"
							dropdownStyle={{ width: 500 }}
						>
							{responseData.length > 0 &&
								responseData.map((role) => (
									<Select.Option key={role._id} value={role._id}>
										{role.name}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
					<Flex style={{ paddingBottom: "16px" }} justify={"flex-end"}>
						<div className="buttons-container">
							<Button style={{ width: "5rem" }} data-testId="addRoleTest">
								Cancel
							</Button>
							<Button
								style={{ width: "5rem" }}
								type="primary"
								onClick={handleSaveClick}
								htmlType="submit"
							>
								Save
							</Button>
						</div>
					</Flex>
				</Form>
			</Content>
		</Layout>
	);
};

export default EditMemberLayout;

interface teamMemberData {
	_id: number;
	UserProfile: number;
	workspaceId: number;
	roleId: number;
}
