import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { useParams } from "react-router-dom";

const { Option } = Select;

interface EditWidgetModalProps {
	visible: boolean;
	onCancel: () => void;
	widgetId: string;
	fetchData: () => void;
}

interface Widget {
	id: string;
	title: string;
	description: string;
	type: string;
	component: string;
}

const EditWidgetModal: React.FC<EditWidgetModalProps> = ({
	visible,
	onCancel,
	widgetId,
	fetchData,
}) => {
	const [form] = Form.useForm();
	const { id } = useParams();
	const [widgetsData, setWidgetsData] = useState<Widget[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	console.log(widgetsData, "widgetsData");

	const getWidgetDetails = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/dashboard/${id}/widgets/${widgetId}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const result = await response.json();
				setWidgetsData(result);
				form.setFieldsValue(result);
			} else {
				console.error("Failed to fetch widget details");
			}
		} catch (error) {
			console.error("Error fetching widget details:", error);
		}
	};

	useEffect(() => {
		if (visible) {
			getWidgetDetails();
		}
	}, [visible, widgetId, id, form, userProfileStoreInstance.idToken]);

	const handleOk = async () => {
		try {
			const values = await form.validateFields();
			values.id = widgetId;
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/dashboard/${id}/widgets/${widgetId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(values),
				}
			);

			if (response.ok) {
				const updatedWidgetData = await response.json();
				fetchData();
				setWidgetsData((prevWidgetsData) => {
					if (Array.isArray(prevWidgetsData)) {
						return prevWidgetsData.map((widget) =>
							widget.id === updatedWidgetData.id ? updatedWidgetData : widget
						);
					} else {
						console.error("not an array");
						return prevWidgetsData;
					}
				});
				onCancel();
			} else {
				console.error("Failed to update widget");
			}
		} catch (error) {
			console.error("Error updating widget:", error);
		}
	};

	return (
		<Modal
			title="Edit Widget"
			visible={visible}
			onOk={handleOk}
			onCancel={onCancel}
		>
			<Form
				form={form}
				layout="horizontal"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
			>
				<Form.Item label="Title" name="title">
					<Input />
				</Form.Item>
				<Form.Item label="Description" name="description">
					<Input.TextArea />
				</Form.Item>
				<Form.Item label="Type" name="type">
					<Select style={{ width: "100%" }}>
						<Option value="text">Text</Option>
						<Option value="number">Number</Option>
						<Option value="date">Date</Option>
					</Select>
				</Form.Item>
				<Form.Item label="Component" name="component">
					<Select style={{ width: "100%" }}>
						<Option value="PieChart">PieChart</Option>
						<Option value="BarChart">BarChart</Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditWidgetModal;
