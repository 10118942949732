import Constants from "./ConstantsUtil";

export const getHelpData = (module: string, page: string, section: string) => {
	for (const helpModule of HelpData) {
		if (helpModule.module === module) {
			for (const helpPage of helpModule.pages) {
				if (helpPage.page === page) {
					if (section == "" || section == null || section == undefined) {
						return helpPage.content;
					} else {
						for (const helpSection of helpPage.sections) {
							if (helpSection.section === section) {
								return helpSection.content;
							}
						}
					}
				}
			}
		}
	}
	return null;
};

const HelpData = [
	{
		module: Constants.BUSINESS_ENGINEERING,
		pages: [
			{
				page: Constants.BUSINESS_ENGINEERING,
				content: "This is the content",
				sections: [
					{
						section: "Section1",
						content: "This is the content",
					},
				],
			},
		],
	},
	{
		module: Constants.FEATURE_ENGINEERING,
		pages: [
			{
				page: Constants.FE_FEATURE_LIST,
				content: "This is the content",
				sections: [
					{
						section: "Section1",
						content: "This is the content",
					},
				],
			},
		],
	},
];
