import {
	NodeExpandOutlined,
	//	RobotOutlined,
} from "@ant-design/icons";
import { Avatar, Flex, Progress, Tag, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../../stores/UserProfileStore";
import useAIActivityStore from "../../stores/AIActivityStore";
import AnimText from "../LoadingAnimation/AnimText";

interface AlchemiNotificationListProps {
	// Define your component props here
	currentAIActivities: any;
	alRecommendations: any;
}

const AlchemiNotificationList: React.FC<AlchemiNotificationListProps> = (
	props
) => {
	// Add your component logic here
	const { setAIConversationContext, setIsAIConversationActive } =
		useAIActivityStore((state: any) => ({
			setAIConversationContext: state.setAIConversationContext,
			setIsAIConversationActive: state.setIsAIConversationActive,
		}));
	const navigate = useNavigate();
	// const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	// const updateRecommendation = async (recommendation: any) => {
	// 	const response = await fetch(
	// 		`${process.env.REACT_APP_API_URL}/alrecommendations/${recommendation._id}`,
	// 		{
	// 			method: "PUT",
	// 			body: JSON.stringify(recommendation),
	// 			headers: {
	// 				"Content-Type": "application/json; charset=UTF-8",
	// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 			},
	// 		}
	// 	);

	// 	return response;
	// };
	// const handleMarkAsRead = async (recommendation: any) => {
	// 	recommendation.status = "READ";
	// 	await updateRecommendation(recommendation);
	// 	console.log("Mark as read", recommendation);
	// };

	// const handleAction = async (recommendation: any) => {
	// 	recommendation.status = "READ";
	// 	await updateRecommendation(recommendation);
	// 	console.log("Mark as read", recommendation);
	// 	if (recommendation.category == "aiRun") {
	// 		if (recommendation.data.type == "WORKFLOW") {
	// 			if (recommendation.data.stage == "FEATURE_REFINEMENT") {
	// 				navigate(`/fd/airun/feature-refinement`);
	// 			} else if (recommendation.data.stage == "FEATURE_ELABORATION") {
	// 				navigate(`/fd/airun/feature-elaboration`);
	// 			} else if (recommendation.data.stage == "UX_SPECIFICATION") {
	// 				navigate(`/fd/airun/de-ux-spec`);
	// 			} else if (recommendation.data.stage == "HIFI_MOCKUP") {
	// 				navigate(`/fd/airun/de-hifi-mock-up`);
	// 			} else if (recommendation.data.stage == "DDD_SPECIFICATION") {
	// 				navigate(`/fd/airun/de-ddd-spec`);
	// 			} else if (
	// 				recommendation.data.stage == "MICRO_FRONTEND_SPECIFICATION"
	// 			) {
	// 				navigate(`/fd/airun/de-micro-frontend`);
	// 			} else if (recommendation.data.stage == "API_SPECIFICATION") {
	// 				navigate(`/fd/airun/de-api-spec`);
	// 			} else if (recommendation.data.stage == "DATA_SCHEMA") {
	// 				navigate(`/fd/airun/de-data-schema`);
	// 			}
	// 		}
	// 	}
	// 	console.log("Do elobarate", recommendation);
	// };

	return (
		<Flex
			style={{
				width: "24vw",
				minHeight: "70vh",
				maxHeight: "90vh",
				overflow: "scroll",
			}}
			vertical
		>
			{props.currentAIActivities && props.currentAIActivities.length > 0 && (
				<>
					<Typography.Text style={{ padding: "6px", fontSize: "12px" }}>
						Alchemi is working on job ...
					</Typography.Text>
					<Flex
						style={{ padding: "8px" }}
						wrap="wrap"
						justify="space-evenly"
						gap="14px 2px"
					>
						{props.currentAIActivities.map((job: any) => (
							<Flex
								key={job._id}
								style={{
									width: "100%",

									padding: "8px",
									borderRadius: "12px",
									boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.2)",
								}}
							>
								<Flex
									justify="center"
									align="flex-start"
									style={{ margin: "4px" }}
								>
									<NodeExpandOutlined />
								</Flex>
								<Flex vertical style={{ width: "100%" }}>
									{job.section == "feature-list" && (
										<Typography.Paragraph
											style={{
												fontSize: "12px",
												margin: "4px 0px 0px 4px",
											}}
											ellipsis={{ rows: 2 }}
										>
											Feature refinement
										</Typography.Paragraph>
									)}
									{job.section == "feature-specification" && (
										<Typography.Paragraph
											style={{
												fontSize: "12px",
												margin: "4px 0px 0px 4px",
											}}
											ellipsis={{ rows: 2 }}
										>
											Feature elaboration
										</Typography.Paragraph>
									)}
									{job.section == "de-ux-spec" && (
										<Typography.Paragraph
											style={{
												fontSize: "12px",
												margin: "4px 0px 0px 4px",
											}}
											ellipsis={{ rows: 2 }}
										>
											Information architecture
										</Typography.Paragraph>
									)}
									{job.section == "de-hifi-mock-up" && (
										<Typography.Paragraph
											style={{
												fontSize: "12px",
												margin: "4px 0px 0px 4px",
											}}
											ellipsis={{ rows: 2 }}
										>
											High fidelity mockup
										</Typography.Paragraph>
									)}
									{job.section == "de-ddd-spec" && (
										<Typography.Paragraph
											style={{
												fontSize: "12px",
												margin: "4px 0px 0px 4px",
											}}
											ellipsis={{ rows: 2 }}
										>
											Domain driven design
										</Typography.Paragraph>
									)}
									{job.section == "de-micro-frontend" && (
										<Typography.Paragraph
											style={{
												fontSize: "12px",
												margin: "4px 0px 0px 4px",
											}}
											ellipsis={{ rows: 2 }}
										>
											Micro frontend
										</Typography.Paragraph>
									)}
									{job.section == "de-api-spec" && (
										<Typography.Paragraph
											style={{
												fontSize: "12px",
												margin: "4px 0px 0px 4px",
											}}
											ellipsis={{ rows: 2 }}
										>
											API specification
										</Typography.Paragraph>
									)}
									{job.section == "de-data-schema" && (
										<Typography.Paragraph
											style={{
												fontSize: "12px",
												margin: "4px 0px 0px 4px",
											}}
											ellipsis={{ rows: 2 }}
										>
											Data schema
										</Typography.Paragraph>
									)}

									<Progress percent={50} status="active" showInfo={false} />
								</Flex>
							</Flex>
						))}
					</Flex>
				</>
			)}
			{/* {props.alRecommendations && props.alRecommendations.length > 0 && (
				<>
					<Typography.Text style={{ padding: "6px", fontSize: "12px" }}>
						Alchemi recommendations for...
					</Typography.Text>
					<Flex
						style={{ padding: "8px" }}
						wrap="wrap"
						justify="space-evenly"
						gap="14px 2px"
					>
						{props.alRecommendations.map((recommendation: any) => (
							<Flex
								key={recommendation._id}
								style={{
									width: "100%",
									background: "white",
									padding: "8px",

									borderRadius: "12px",
									boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.2)",
								}}
							>
								<Flex
									justify="center"
									align="flex-start"
									style={{ margin: "4px" }}
								>
									<BulbFilled style={{ color: "blue" }} />
								</Flex>
								<Flex
									justify="center"
									align="center"
									vertical
									style={{ margin: "2px" }}
								>
									<Typography.Paragraph
										style={{ fontSize: "12px", marginBottom: "0px" }}
										ellipsis={{ rows: 2 }}
									>
										{recommendation.content}
									</Typography.Paragraph>
									<Flex style={{ width: "100%" }}>
										<Flex
											justify="flex-start"
											align="flex-start"
											style={{ width: "100%" }}
										>
											<Button
												type="link"
												style={{ fontSize: "10px", color: "grey" }}
												onClick={() => handleMarkAsRead(recommendation)}
											>
												Mark as read{" "}
											</Button>
										</Flex>
										<Flex
											justify="right"
											align="flex-end"
											style={{ width: "100%" }}
										>
											<Button
												type="default"
												style={{
													fontSize: "12px",
													color: "",
													float: "right",
												}}
												onClick={() => handleAction(recommendation)}
											>
												Let&apos;s work{" "}
											</Button>
										</Flex>
									</Flex>
								</Flex>
							</Flex>
						))}
					</Flex>
				</>
			)} */}

			{/* <Flex align="center" justify="center">
						<Card
							style={{
								width: "95%",
								boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.1)",
								zIndex: 10,
								height: "auto",
							}}
						>
							<Flex
								align="flex-start"
								justify="space-between"
								gap={10}
								style={{ marginLeft: "14px" }}
							>
								<Flex justify="center" align="flex-start">
									<BulbFilled />
								</Flex>
								<Flex justify="flex-start" align="flex-start" vertical>
									<Typography.Paragraph
										style={{ fontSize: "12px" }}
										ellipsis={{ rows: 2 }}
									>
										TESTTESTTEST TESTTESTTEST TESTTESTTEST TESTTESTTEST TES
										TESTTESTTE STTESTTEST TESTTESTTES TTESTTESTTEST Paragraph
										STTESTTEST TESTTESTTESTT ESTTESTTEST
									</Typography.Paragraph>
									<Flex justify="flex-end" style={{ width: "100%" }}>
										<Button type="link">Read more</Button>
									</Flex>
								</Flex>
							</Flex>
						</Card>
					</Flex> */}
			<Flex align="center" justify="center">
				{/* <Avatar
					shape="circle"
					style={{
						marginTop: "32px",
						background: "#c1d4e7",
						color: "#000",
						fontWeight: "bold",
					}}
					size={128}
				>
					{" "}
					<RobotOutlined style={{ fontSize: "48px" }} />
				</Avatar> */}

				<Avatar
					style={{
						backgroundColor: "white",
						cursor: "pointer",
						marginTop: "-6px",
						marginBottom: "6px",
					}}
					shape="square"
					size={128}
					src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_ai_logo_v2.svg"
				/>
			</Flex>

			<Typography.Text
				style={{
					textAlign: "center",
					fontSize: "14px",
					padding: "32px 12px 12px 12px",
				}}
			>
				<AnimText
					text="I am Alchemi, your Platform Assitant. Based on the context, I can
						help you in the work."
					delay={2}
					duration={6}
				/>
			</Typography.Text>
			<Flex
				style={{ padding: "8px" }}
				wrap="wrap"
				justify="space-evenly"
				gap="14px 2px"
			>
				{/* <Tag color="blue">Feature refinement</Tag> */}
				<Tag
					color="cyan"
					onClick={() => {
						setAIConversationContext({
							type: "feature-elaboration",
							data: null,
						});
						navigate(`/fd/preprocess/chat/feature-elaboration`);
					}}
					style={{ cursor: "pointer" }}
				>
					Feature elaboration
				</Tag>
				<Tag
					color="red"
					onClick={() => navigate(`/fd/preprocess/chat/feature-refinement`)}
					style={{ cursor: "pointer" }}
				>
					New feature
				</Tag>
				<Tag
					color="red"
					onClick={() => setIsAIConversationActive(true)}
					style={{ cursor: "pointer" }}
				>
					Feature elaboration v2
				</Tag>
				<Tag color="cyan">User story splitting</Tag>
			</Flex>
		</Flex>
	);
};

export default AlchemiNotificationList;
