import React, { useState, useEffect } from "react";

const DotLoading: React.FC = () => {
	const [dots, setDots] = useState(".");

	useEffect(() => {
		const intervalId = setInterval(() => {
			setDots((prevDots) => (prevDots.length === 3 ? "." : prevDots + "."));
		}, 500);
		return () => clearInterval(intervalId);
	}, []);

	return (
		<span
			style={{
				color: "#696969",
				fontSize: "3rem",
				marginTop: "-2rem",
				width: "40%",
			}}
		>
			{dots}
		</span>
	);
};

export default DotLoading;
