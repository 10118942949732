//import { ArrowLeftOutlined } from "@ant-design/icons";
import {
	//Breadcrumb,
	//Button,
	ColorPicker,
	Divider,
	Flex,
	Form,
	Input,
	Modal,
	Space,
	message,
} from "antd";
import "../Planner/PlanerHeader.css";
//import Title from "antd/es/typography/Title";
import React, { useState } from "react";
//import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import BreadCrumb from "../Editors/BreadCrumb";
import { motion } from "framer-motion";
import { EditOutlined } from "@ant-design/icons";

function TagManagementNav({
	openTagModal,
	setOpenTagModal,
}: {
	openTagModal: boolean;
	setOpenTagModal: React.Dispatch<React.SetStateAction<boolean>>;
}): any {
	//const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [tagName, setTagName] = useState("");
	const [category, setCategory] = useState("");
	const [description, setDescription] = useState("");
	const [color, setColor] = useState("");

	const handleAddTag = async () => {
		const name = tagName;
		console.log("Tag name:", name);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({
					name: tagName,
					category,
					description,
					color: color,
				}),
			});
			if (response.ok) {
				const responseData = await response.json();
				console.log("Response from the backend:", responseData);
				message.success("Tag added successfully");
				setOpenTagModal(false);
			} else {
				const errorData = await response.json();
				message.error(`Error adding tag. Please try again.${errorData}`);
			}
		} catch (error: any) {
			message.error(`Error adding tag. Please try again: ${error.message}`);
		}
	};

	const [isHovered, setIsHovered] = useState(false);
	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		<div>
			<Divider className="ant-divider-horizontal" />
			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex style={{ alignItems: "center", marginLeft: "2vw" }}>
						<Space>
							{/* <Flex
			justify="space-between"
			align="center"
			style={{
				padding: "20px 50px 20px 50px",
				margin: "4px 0 0px",
				backgroundColor: "rgb(245, 248, 254)",
			}}
		> 
			<Flex align="center" gap={16}> */}
							{/* <Button
					shape="circle"
					icon={<ArrowLeftOutlined />}
					size="large"
					onClick={() => navigate("/")}
					style={{ color: "#0444BF", borderColor: "#0444BF" }}
				/>

				<Flex vertical>
					<Breadcrumb>
						<Breadcrumb.Item>Workspace Settings</Breadcrumb.Item>
					</Breadcrumb>
					<Title
						level={5}
						style={{
							margin: 0,
							textAlign: "left",
							fontFamily: "Poppins",
						}}
					>
						Tag Mangement
					</Title>
				</Flex> */}
							<Flex className="breadcrumb">
								<BreadCrumb config={breadcrumbConfig.tagmanagement} />
							</Flex>
						</Space>
					</Flex>
					<>
						{/* <Button type="primary" onClick={() => setOpenTagModal(true)}>
					Add Tag
				</Button> */}
						<motion.div
							initial="initial"
							animate={isHovered ? "hover" : "initial"}
							variants={buttonVariants}
							transition={{ duration: 0.3 }}
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							className="add-work-item-button"
							onClick={() => setOpenTagModal(true)}
						>
							<EditOutlined />
							{isHovered && <span style={{ marginLeft: "8px" }}>Add Tag</span>}
						</motion.div>
						<Modal
							title="Add Tag"
							open={openTagModal}
							onCancel={() => setOpenTagModal(false)}
							onOk={handleAddTag}
						>
							<Form layout="horizontal">
								<Flex justify="space-between" align="center">
									<Form.Item
										label="Tag"
										name="tagName"
										rules={[
											{
												required: true,
												message: "Please enter tag name",
											},
										]}
										style={{ width: "100%", marginLeft: "84px" }}
										labelCol={{ span: 4.5 }}
										wrapperCol={{ span: 20 }}
									>
										<Input
											style={{ marginRight: "10px" }}
											placeholder="Enter tag name"
											value={tagName}
											onChange={(event) => setTagName(event.target.value)}
										/>
									</Form.Item>
								</Flex>

								<Flex justify="space-between" align="center">
									<Form.Item
										label="Category"
										name="category"
										rules={[
											{
												required: true,
												message: "Please enter tag category",
											},
										]}
										style={{ width: "100%", marginLeft: "37px" }}
										labelCol={{ span: 6.6 }}
										wrapperCol={{ span: 24 }}
									>
										<Input
											style={{ marginRight: "35px" }}
											placeholder="Enter tag category"
											value={category}
											onChange={(event) => setCategory(event.target.value)}
										/>
									</Form.Item>
								</Flex>

								<Flex justify="space-between" align="center">
									<Form.Item
										label="Description"
										name="description"
										rules={[
											{
												required: true,
												message: "Please enter tag description",
											},
										]}
										style={{ width: "100%", marginLeft: "20px" }}
										labelCol={{ span: 7 }}
										wrapperCol={{ span: 24 }}
									>
										<Input.TextArea
											style={{ marginRight: "80px" }}
											placeholder="Enter tag description"
											value={description}
											onChange={(event) => setDescription(event.target.value)}
										/>
									</Form.Item>
								</Flex>

								<Flex justify="space-between" align="center">
									<Form.Item
										label="Color"
										name="color"
										getValueFromEvent={(color) => {
											setColor("#" + color.toHex());
											return "#" + color.toHex();
										}}
										rules={[
											{
												required: false,
												message: "Please select tag color",
											},
										]}
										style={{ width: "90%", marginLeft: "78px" }}
										labelCol={{ span: 4.5 }}
										wrapperCol={{ span: 18 }}
									>
										<ColorPicker
											style={{ marginRight: "80px" }}
											defaultValue="#1677ff"
											showText={(color) => (
												<span>Color: ({color.toHexString()})</span>
											)}
										/>
									</Form.Item>
								</Flex>
							</Form>
						</Modal>
					</>
				</Flex>
			</div>
		</div>
	);
}

export default TagManagementNav;
