import { Graph, Path } from "@antv/xflow";

const DAG_EDGE1 = "dag-edge";
const DAG_CONNECTOR = "dag-connector";

Graph.registerConnector(
	DAG_CONNECTOR,
	(s, e) => {
		const offset = 4;
		const deltaY = Math.abs(e.y - s.y);
		const control = Math.floor((deltaY / 3) * 2);

		const v1 = { x: s.x, y: s.y + offset + control };
		const v2 = { x: e.x, y: e.y - offset - control };

		return Path.normalize(
			`M ${s.x} ${s.y}
       L ${s.x} ${s.y + offset}
       C ${v1.x} ${v1.y} ${v2.x} ${v2.y} ${e.x} ${e.y - offset}
       L ${e.x} ${e.y}
      `
		);
	},
	true
);

Graph.registerEdge(
	DAG_EDGE1,
	{
		inherit: "edge",
		attrs: {
			line: {
				stroke: "#C2C8D5",
				strokeWidth: 1,
				targetMarker: null,
			},
		},
		zIndex: -1,
	},
	true
);

export { DAG_EDGE1, DAG_CONNECTOR };
