/* eslint-disable @typescript-eslint/no-unused-vars */
import { EyeOutlined, FilterFilled, ToolOutlined } from "@ant-design/icons";
import {
	Avatar,
	Button,
	Card,
	Collapse,
	Dropdown,
	Flex,
	Input,
	Layout,
	List,
	Menu,
	// Menu,
	MenuProps,
	Space,
	Tag,
	Typography,
	message,
	// message,
} from "antd";
// import "./FeaturePrioritization/KanoPrioritization.css";

import { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { usePlanStore } from "../../../stores/PlanStore";
import ReleaseNav from "./ReleaseNav";
import useWorkspaceStore from "../../../stores/WorkspaceState";

const { Content, Header } = Layout;
const { Title } = Typography;
const { Search } = Input;

function ReleasePlanView() {
	const [data, setData] = useState<any>();
	const [tags, setTags] = useState<any>();
	const [Menudata, setMenudata] = useState<any[]>([]);
	const [filterid, setfilterid] = useState<any[]>([]);
	const [filtereddata, setfiltereddata] = useState<any[]>([]);
	const [searchvalue, setsearchvalue] = useState<string>("");
	const [filterstatus, setfilterstatus] = useState<string>("");

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const {
		selectedGroupBy,
		planvalue,
		urlData,
		isCardView,
		FetchPlan,
		setFetchPlan,
		ReleasePlanValue,
		changeUrl,
	} = usePlanStore();

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const fetchTodos = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${currentWorkspace?._id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const result = await response.json();

				setData(result);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	const fetchRelease = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${currentWorkspace?._id}?type=Releaseplan`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData: any[] = await response.json();
				setTodos(todosData);
				setMenudata(todosData);
				const ids = todosData
					.map((todo) => {
						return todo.items.map((item: any) => item._id);
					})
					.flat();
				setfilterid(ids);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		const datafilter = data?.filter(
			(itemer: any) => !filterid?.includes(itemer._id)
		);
		setfiltereddata(datafilter);
	}, []);

	useEffect(() => {
		const datafilter = data?.filter(
			(itemer: any) => !filterid?.includes(itemer._id)
		);
		setfiltereddata(datafilter);
	}, [
		urlData.screen,
		urlData.newUrl,
		isCardView,
		data,
		urlData.prevUrl,
		FetchPlan,
		filterid,
	]);

	useEffect(() => {
		fetchRelease();
		fetchTodos();
	}, [ReleasePlanValue, FetchPlan]);

	const [todos, setTodos] = useState<any[]>([]);

	function groupBy(array: any[], key: string) {
		return array.reduce((result: any, currentItem: any) => {
			const groupKey = currentItem[key];
			if (!result[groupKey]) {
				result[groupKey] = [];
			}
			result[groupKey].push(currentItem);
			return result;
		}, {});
	}

	const setGroupKey = (selectedGroupBy: string) => {
		switch (selectedGroupBy) {
			case "Type":
				return "bug";
			case "Priority":
				return "priority";
			case "Status":
				return "status";
			case "release":
				return "release";
			default:
				return "name";
		}
	};

	const groupKey = setGroupKey("Release");
	const groupedTasks = groupBy(todos, groupKey);

	useEffect(() => {
		const fetchTagsData = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					setTags(data);
					console.log(data);
				} else if (response.status === 401) {
					setUnauthorizedModel(true);
				} else {
					console.error("Error fetching tags data");
				}
			} catch (error) {
				console.error("Error fetching tags data:", error);
			}
		};

		fetchTagsData();
	}, []);

	const [column, setColumn] = useState<any[]>([]);

	const setColumnState: () => any = () => {
		const column = groupBy(todos, "kano");
		const columns = Object.entries(column).map(([groupKey]) => ({
			id: groupKey,
			title: `${groupKey} `,
		}));
		setColumn(columns);
	};

	useEffect(() => {
		setColumnState();
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	useEffect(() => {
		changeUrl(
			"/space/planner/releaseplanner",
			"/space/planner/releaseplans",
			"Release Plan"
		);
	}, []);

	const handleMenuClick = async (e: any, item: any) => {
		const resultdata = Menudata?.filter((data) => {
			return data._id === e.key;
		});

		// Remove additional data from items except for _id
		resultdata[0].items = resultdata[0].items.map((item: any) => {
			return item._id;
		});

		// Push a new id into items array
		resultdata[0].items.push(item._id);

		// Return the updated object
		console.log("obj", resultdata[0]);

		// console.log("resultdata", resultdata);

		message.success(`Selected menu item: ${e?.key}:${item?._id}`);

		try {
			const updateResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${e?.key}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(resultdata[0]),
				}
			);

			const updateResult = await updateResponse.json();

			if (updateResponse.ok) {
				console.log("Data updated successfully:", updateResult);
				fetchRelease();
				setFetchPlan(!FetchPlan);
			} else {
				console.error("Failed to update data");
			}
		} catch (error) {
			console.error("Error updating data:", error);
		}

		if (item.startDate === undefined || item.endDate === undefined) {
			const payload = {
				...item,
				startDate: resultdata[0].startdate,
				endDate: resultdata[0].enddate,
			};

			console.log("payload", payload);

			try {
				const updateWorkItemResponse = await fetch(
					`${process.env.REACT_APP_API_URL}/workitems/${item._id}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify(payload),
					}
				);

				const updateResult = await updateWorkItemResponse.json();

				if (updateWorkItemResponse.ok) {
					console.log("Work item updated successfully:", updateResult);
					// Additional logic or state updates can be done here
					fetchRelease();
					setFetchPlan(!FetchPlan);
				} else {
					console.error("Failed to update work item");
				}
			} catch (error) {
				console.error("Error updating work item:", error);
			}
		}
	};

	const truncateDescription = (description: string, wordCount: number) => {
		const words = description.split(" ");
		if (words.length > wordCount) {
			return words.slice(0, wordCount).join(" ") + "...";
		}
		return description;
	};

	const sortItems = [
		{
			label: "Pending",
			key: "PENDING",
		},
		{
			label: "Ready",
			key: "READY",
		},
		{
			label: "In Progress",
			key: "In progress",
		},
		{
			label: "None",
			key: "",
		},
	];

	const handleSortByChange = ({ key }: { key: string }) => {
		setfilterstatus(key.toLowerCase() as any);
	};

	const SearchinputValue = (values: any) => {
		const newvalues = values?.toLowerCase();
		setsearchvalue(newvalues);
	};

	const FilterSearchValudata = (data: any) => {
		const newdata = data.filter(
			(test: any) =>
				(test.title?.toLowerCase().includes(searchvalue) ||
					test.description?.toLowerCase().includes(searchvalue) ||
					test?.WorkitemId?.toLowerCase().includes(searchvalue)) &&
				test?.status?.toLowerCase().includes(filterstatus)
		);
		return newdata;
	};

	return (
		<>
			<Layout style={{ background: "#FAFBFF" }}>
				<ReleaseNav />
				<Content style={{ backgroundColor: "#F5F8FE", padding: "2rem" }}>
					<Flex>
						{isCardView ? (
							<Flex
								style={{
									backgroundColor: "#F5F8FE",
									overflow: "scroll",
									height: "80vh",
									width: "65%",
									// display: "block",
									// position: "relative",
									animation: "fadeIn 2s ease-in-out",
								}}
							>
								<Flex vertical style={{ width: "25vw" }} key={1}>
									<Flex justify="center" className="ColumnTitle">
										Unassigned
									</Flex>
									<Flex vertical style={{ overflow: "scroll", height: "70vh" }}>
										{filtereddata
											// .filter((item: any) => item.name === col)
											// ?.filter((itemer: any) => !filterid?.includes(itemer._id))
											?.map((task: any) => (
												<Dropdown
													key={task.id}
													overlay={
														<Menu onClick={(e) => handleMenuClick(e, task)}>
															{Menudata.map((menuItem) =>
																menuItem ? (
																	<Menu.Item key={menuItem._id}>
																		{menuItem.name}
																	</Menu.Item>
																) : null
															)}
														</Menu>
													}
													trigger={["contextMenu"]}
												>
													<Card className="KanoCard" key={task.id}>
														<Flex align="start">
															{/* <div
																style={{
																	color: col.color,
																	fontSize: "1.2rem",
																}}
															>
																{task.icon}
															</div> */}
															<Space direction="vertical">
																<Flex justify="space-between">
																	<div style={{ fontWeight: "bold" }}>
																		{task.title}
																	</div>
																	{/* <div style={{ fontWeight: "bold" }}>
																		{task.story}
																	</div> */}
																</Flex>
																<Flex className="kanoCardDescription">
																	{truncateDescription(task.description, 10)}
																	{/* {task.description.split(' ').slice(0, 20).join(' ')}
													{task.description.split(' ').length == 20 && '...'} */}
																</Flex>
															</Space>
														</Flex>
														<Flex
															justify="space-between"
															style={{ marginTop: "0.5rem" }}
														>
															<Tag
																// color={col.color}
																style={{
																	color: "black",
																	fontSize: "0.8rem",
																	width: "fit-content",
																	textAlign: "center",
																}}
															>
																{task.priority}
															</Tag>
															<div style={{ width: "4vw" }}>
																{/* <Tag
																	style={{
																		color: task.colour,
																		fontSize: "0.8rem",
																		textAlign: "center",
																	}}
																>
																	{task.bug}
																</Tag> */}
															</div>
															<div
																style={{
																	fontSize: "0.8rem",
																	fontWeight: "bold",
																	// color: col.color,
																}}
															>
																Status
															</div>
														</Flex>
													</Card>
												</Dropdown>
											))}
									</Flex>
								</Flex>
							</Flex>
						) : (
							<Flex
								style={{
									backgroundColor: "#F5F8FE",
									overflow: "scroll",
									height: "80vh",
									width: "65%",
									// display: "block",
									// position: "relative",
									animation: "fadeIn 2s ease-in-out",
								}}
							>
								{
									<Flex vertical style={{ width: "30vw" }}>
										<Flex vertical>
											<List
												dataSource={filtereddata}
												renderItem={(item: any) => (
													<List.Item
														style={{
															margin: "0.5rem",
															backgroundColor: "white",
															padding: "1rem",
															borderRadius: "0.25rem",
															width: "55vw",
														}}
													>
														<Dropdown
															overlay={
																<Menu onClick={(e) => handleMenuClick(e, item)}>
																	{Menudata.map((menuItem) =>
																		menuItem ? (
																			<Menu.Item key={menuItem._id}>
																				{menuItem.name}
																			</Menu.Item>
																		) : null
																	)}
																</Menu>
															}
															trigger={["contextMenu"]}
														>
															<Flex gap={"1rem"} style={{ width: "100%" }}>
																<Flex gap={"1rem"} style={{ width: "100%" }}>
																	<Space size={30}>
																		<span
																			style={{
																				fontSize: "1.5rem",
																				cursor: "pointer",
																			}}
																		>
																			<EyeOutlined />
																		</span>
																		<span
																			style={{
																				fontSize: "1.5rem",
																				color: item.colour,
																			}}
																		>
																			{item.icon}
																		</span>
																	</Space>
																	<Space
																		size={[8, 8]}
																		direction="vertical"
																		style={{ width: "100%", textAlign: "left" }}
																	>
																		<span
																			style={{
																				fontSize: "1rem",
																				textAlign: "left",
																				fontWeight: "bold",
																			}}
																		>
																			{item.title}
																		</span>
																		<div style={{ fontSize: "0.8rem" }}>
																			{item.description}
																		</div>
																	</Space>
																</Flex>
															</Flex>
														</Dropdown>
													</List.Item>
												)}
											/>
										</Flex>
									</Flex>
								}
							</Flex>
						)}

						<Flex
							vertical
							className="kano"
							gap={20}
							style={{
								height: "70vh",
								animation: "fadeIn 2s ease-in-out",
								width: "35%",
							}}
						>
							<Flex justify="space-between" align="center">
								<Search
									placeholder="input search text"
									allowClear
									style={{ width: 380 }}
									onChange={(e) => SearchinputValue(e.target.value)}
								/>
								<Dropdown
									overlay={
										<Menu>
											{sortItems.map((item) => (
												<Menu.Item
													onClick={() => handleSortByChange(item)}
													key={item.key}
												>
													{item.label}
												</Menu.Item>
											))}
										</Menu>
									}
									trigger={["click"]}
								>
									<FilterFilled />
								</Dropdown>
							</Flex>
							<Collapse
								style={{
									height: "80vh",
									overflow: "scroll",
									animation: "fadeIn 0.5s ease-in-out",
								}}
								defaultActiveKey={planvalue}
								ghost
							>
								{Object.entries(groupedTasks).map(([groupKey]) => (
									<Collapse.Panel
										key={groupKey}
										style={{ backgroundColor: "white", alignItems: "center" }}
										header={
											<div style={{ display: "flex", alignItems: "center" }}>
												<h4 style={{ textAlign: "left", margin: 0 }}>
													{groupKey}
												</h4>
											</div>
										}
									>
										<List
											split={false}
											style={{
												overflowY: "auto",
												width: "100%",
											}}
											dataSource={groupedTasks[groupKey]}
											renderItem={(itemer: any) => (
												<>
													{/* {console.log("menuItem", itemer)} */}
													{FilterSearchValudata(itemer?.items)?.map(
														(item: any, index: number) => (
															<List.Item key={item._id} style={{}}>
																<Flex style={{ width: "100%" }}>
																	<Avatar>
																		<ToolOutlined />
																	</Avatar>
																	<Space
																		direction="vertical"
																		align="start"
																		style={{
																			textAlign: "left",
																			width: "82%",
																			marginLeft: "0.2rem",
																			marginRight: "0.2rem",
																		}}
																	>
																		<Space
																			style={{
																				color: "#82a2df",
																				fontWeight: "bold",
																			}}
																			align="start"
																			// className="kanoTitle"
																		>
																			<Space>{item.title}</Space>
																			<Space>
																				<Tag
																					color="yellow"
																					style={{ color: "black" }}
																				>
																					{item.status}
																				</Tag>
																			</Space>
																		</Space>
																		<Space
																			align="start"
																			className="kanoDescription"
																			style={{ width: "20rem" }}
																		>
																			{item.description}
																		</Space>
																	</Space>
																	<Avatar
																		style={{
																			backgroundColor:
																				(index + 1) % 2 == 0
																					? "#9d58be"
																					: "#be5858",
																		}}
																	>
																		AM
																	</Avatar>
																</Flex>
															</List.Item>
														)
													)}
												</>
											)}
										/>
									</Collapse.Panel>
								))}
							</Collapse>
						</Flex>
					</Flex>
				</Content>
			</Layout>
		</>
	);
}

export default ReleasePlanView;
function setUnauthorizedModel(arg0: boolean) {
	throw new Error("Function not implemented.");
}
