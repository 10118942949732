import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	// Checkbox,
	Button,
	Layout,
	Typography,
	Flex,
	Select,
	Divider,
} from "antd";
import "./AddRole.css";
import { useNavigate, useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
// import EditRoleNav from "./EditRoleNav";
import BreadCrumb from "../Editors/BreadCrumb";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";

const EditRolesModal: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { id } = useParams(); // Assuming you are using react-router for routing
	const [form] = Form.useForm();
	const { Title } = Typography;
	const formItemLayout = { labelCol: { span: 2 }, wrapperCol: { span: 14 } };
	const [permissions, setPermissions] = useState<any>([]);
	const [options, setOptions] = useState<any>([]);

	// const permissions: string[] = [
	// 	"Can Create",
	// 	"Can View",
	// 	"Can Edit",
	// 	"Can Delete",
	// ];
	// const categories: string[] = ["Business", "Features", "Code", "Design"];

	// const [selectedPermissionsMap, setSelectedPermissionsMap] = useState<{
	// 	[key: string]: string[];
	// }>({});
	const [roleData, setRoleData] = useState<any>({}); // State to store fetched role data

	const fetchPermissions = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/permissions`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const PermissionsData = await response.json();
				const newpermissions = PermissionsData.map((permission: any) => {
					return {
						label: permission.name,
						value: permission._id,
					};
				});
				setPermissions(newpermissions);
				setOptions(newpermissions);
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	const handleChange = (value: string) => {
		setOptions(
			permissions.filter((permission: any) =>
				permission.label.toLowerCase().includes(value.toLowerCase())
			)
		);
	};

	useEffect(() => {
		fetchPermissions();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/roles/${id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (response.ok) {
					const responseData = await response.json();
					console.log("Response from the backend:", responseData);
					setRoleData(responseData); // Store fetched data in state
					// Set the form values based on the fetched data
					form.setFieldsValue({
						role: responseData.name,
						permissions: responseData?.permissions?.map(
							(permission: any) => permission
						),
					});
					const permissionsMap: { [key: string]: string[] } = {};
					responseData.permissions.forEach((permission: any) => {
						permissionsMap[permission.field] = permission.permissions;
					});
					// setSelectedPermissionsMap(permissionsMap);
				} else if (response.status === 401) {
					setUnauthorizedModel(true);
				} else {
					console.error("Error fetching role data:", response.statusText);
				}
			} catch (error) {
				console.error("Error fetching role data:", error);
			}
		};

		fetchData();
	}, [form, id]);

	// const handlePermissionChange = (category: string, selected: string[]) => {
	// 	setSelectedPermissionsMap({
	// 		...selectedPermissionsMap,
	// 		[category]: selected,
	// 	});
	// };

	const handleButtonClick = async (values: any) => {
		const name = form.getFieldValue("role");
		const createdBy = roleData.createdBy || "defaultCreatedBy"; // Use existing value or default
		const updatedBy = "defaultUpdatedBy"; // Update with the current user or default

		// const permissionsArray = categories.map((category) => {
		// 	const selectedPermissions = selectedPermissionsMap[category] || [];
		// 	return {
		// 		field: category,
		// 		permissions: selectedPermissions,
		// 	};
		// });

		const postData = {
			name,
			createdBy,
			updatedBy,
			permissions: values.permissions,
			account_Id: userProfileStoreInstance.profile.account_Id,
		};

		console.log("Sending data to the backend:", postData);

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/roles/${id}`,
				{
					method: "PUT", // Assuming you want to use PUT for updating
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(postData),
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				console.log("Response from the backend:", responseData);
				// Handle success
				console.log("Data sent successfully");
				navigate("/settings/role");
			} else {
				const errorData = await response.json();
				console.error("Error sending data to the backend:", errorData);
				// Handle error
				console.error("Error sending data to the backend");
			}
		} catch (error) {
			console.error("Error sending data:", error);
		}
	};

	const handleCancelClick = async () => {
		navigate("/settings/role");
	};

	return (
		<>
			{/* <EditRoleNav /> */}
			<Divider className="ant-divider-horizontal" />
			<Flex
				justify="space-between"
				align="center"
				style={{
					margin: "0 1vw",
					marginTop: "-0.5vh",
				}}
			>
				<Flex style={{ marginLeft: "1vw" }} className="breadcrumb">
					<BreadCrumb config={breadcrumbConfig.editrolemanagement} />
				</Flex>
				<Flex justify={"flex-end"}>
					<Button
						style={{
							borderRadius: "6px",
							fontSize: "14px",
							marginRight: "0.5vw",
						}}
						onClick={handleCancelClick}
						data-testId="addRoleTest"
						size="small"
					>
						Cancel
					</Button>
					<Button
						style={{ borderRadius: "6px", fontSize: "14px" }}
						type="primary"
						htmlType="submit"
						size="small"
						// onClick={handleButtonClick}
					>
						Save
					</Button>
				</Flex>
			</Flex>
			<Layout
				style={{
					background: "white",
					margin: " 0 1vw",
					padding: "0 1vw",
					height: "80vh",
				}}
			>
				<h2 style={{ color: "rgba(0, 0, 0,0.6)", textAlign: "left" }}>
					Edit Roles
				</h2>

				<Form
					layout="horizontal"
					{...formItemLayout}
					form={form}
					colon={false}
					// style={{ marginTop: "32px" }}
					onFinish={handleButtonClick}
				>
					<Title className="titleTypoAddRole" level={5}>
						Role Name
					</Title>
					<Form.Item name="role">
						<Input placeholder="Enter role name" />
					</Form.Item>

					<Title className="titleTypoAddRole" level={5}>
						Permissions
					</Title>
					<Form.Item name="permissions">
						<Select
							mode="multiple"
							allowClear
							style={{ width: "100%" }}
							placeholder="Please select"
							onSearch={handleChange}
							options={options}
							filterOption={false}
						/>
						{/* <Card style={{ border: "none" }}>
							{categories.map((category) => (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										marginBottom: "22px",
									}}
									key={category}
								>
									<h4 style={{ fontSize: "14px", margin: 0 }}>{category}</h4>
									<Checkbox.Group
										options={permissions.map((permission) => ({
											label: permission,
											value: permission,
										}))}
										value={selectedPermissionsMap[category] || []}
										onChange={(selected) =>
											handlePermissionChange(category, selected as string[])
										}
									/>
								</div>
							))}
						</Card> */}
					</Form.Item>
				</Form>
			</Layout>
		</>
	);
};

export default EditRolesModal;
