/* eslint-disable prefer-const */
import React, { useEffect } from "react";
import {
	Form,
	Modal,
	DatePicker,
	Select,
	Space,
	Checkbox,
	Row,
	Col,
	InputNumber,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import { useRitualStore } from "./RitualStore";

interface RepeatingMeetingFormProps {
	visible: boolean;
	onCancel: () => void;

	startDate: any;
}

const RepeatingMeetingForm: React.FC<RepeatingMeetingFormProps> = ({
	visible,
	onCancel,

	startDate,
}) => {
	const [form] = Form.useForm();

	const {
		setReoccurringRitual,
		setStartDate,
		setEndDate,
		setTimeInterval,
		setDays,
		setRepeatEvery,
	} = useRitualStore();

	useEffect(() => {
		if (startDate?.$d) {
			form.setFieldsValue({
				startDate: moment(startDate?.$d),
			});
		}
	}, [startDate, form]);

	const handleFinish = async () => {
		try {
			const formData = await form.validateFields();
			setEndDate(formData.endDate);
			setStartDate(formData.startDate);
			setReoccurringRitual(true);
			setTimeInterval(formData.repetitionInterval);
			setDays(formData.selectedDays);
			setRepeatEvery(formData.repetEvery);
			onCancel();
		} catch (error) {
			console.error("Error in creating repeating meeting:", error);
		}
	};

	return (
		<Modal
			title="Set recurrence"
			open={visible}
			onCancel={onCancel}
			onOk={handleFinish}
		>
			<Form form={form}>
				<Form.Item label="Starting Date" name="startDate">
					<DatePicker style={{ width: "100%" }} />
				</Form.Item>
				<Form.Item label="End Date" name="endDate">
					<DatePicker />
				</Form.Item>

				<Form.Item label="Repet Every">
					<Space>
						<Form.Item name="repetEvery" noStyle>
							<InputNumber style={{ width: "70px" }} min={1} max={99} />
						</Form.Item>
						<Form.Item
							name="repetitionInterval"
							noStyle
							style={{ margin: "0 8px" }}
						>
							<Select style={{ width: "100px" }}>
								<Option value="days">Days</Option>
								<Option value="weeks">Weeks</Option>
								<Option value="months">Months</Option>
								<Option value="years">Years</Option>
							</Select>
						</Form.Item>
					</Space>
				</Form.Item>

				{form.getFieldValue("repetitionInterval") === "weeks" && (
					<Form.Item label="Select Days" name="selectedDays">
						<Checkbox.Group style={{ width: "100%" }}>
							<Row>
								{["0", "1", "2", "3", "4", "5", "6"].map((day) => (
									<Col key={day} span={8}>
										<Checkbox value={day}>
											{moment().day(day).format("dddd")}
										</Checkbox>
									</Col>
								))}
							</Row>
						</Checkbox.Group>
					</Form.Item>
				)}
			</Form>
		</Modal>
	);
};

export default RepeatingMeetingForm;
