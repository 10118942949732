/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Input, Select, Form, Button, Card, Flex, message } from "antd";
import { planerViewStore } from "../../stores/PlannerStore";

import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Option } = Select;
import { useNavigate } from "react-router-dom";
import Constants from "../../util/ConstantsUtil";
import ReactQuill from "react-quill";
import { useRequest } from "ahooks";
import { createActivelog } from "../../api/ActiveLogAPI";

const ExpandedModalForEdit = () => {
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { isEditModalOpen, selectedItemDetails, setEditModalOpen } =
		planerViewStore();
	const [editFormFeature] = Form.useForm();
	const [editFormUserStory] = Form.useForm();
	const [editFormTask] = Form.useForm();
	const [loading, setLoading] = useState(false);
	let assigneeId, userId;

	if (selectedItemDetails?.assignee) {
		assigneeId = selectedItemDetails.assignee._id;
	}

	if (userProfileStoreInstance.profile) {
		userId = userProfileStoreInstance.profile._id;
	}
	console.log("assigneeId", assigneeId);
	console.log("userId", userId);

	useEffect(() => {
		if (selectedItemDetails?.type === "FEATURE") {
			editFormFeature.setFieldsValue({
				title: selectedItemDetails?.title,
				description: selectedItemDetails.description,
				status: selectedItemDetails.status,
				priority: selectedItemDetails.priority,
				type: selectedItemDetails.type,
				//stage: selectedItemDetails.stage,
				// data: {
				// 	benefits: selectedItemDetails.data?.benefits,
				// 	role_ids: selectedItemDetails.data?.role_ids,
				// 	exposition: selectedItemDetails.data?.exposition,
				// 	complexity_score: selectedItemDetails.data?.complexity_score,
				// 	complexity_score_reason:
				// 		selectedItemDetails.data?.complexity_score_reason,
				// },
			});
		}
	}, [editFormFeature]);

	useEffect(() => {
		if (selectedItemDetails?.type === "USER STORY") {
			editFormUserStory.setFieldsValue({
				title: selectedItemDetails?.title,
				description: selectedItemDetails?.description,
				status: selectedItemDetails?.status,
				priority: selectedItemDetails?.priority,
				type: selectedItemDetails?.type,
				// stage: selectedItemDetails?.stage,
				// data: {
				// 	story_points: selectedItemDetails?.data?.story_points,
				// 	business_rules: selectedItemDetails?.data?.business_rules,
				// 	acceptance_criteria: selectedItemDetails?.data?.acceptance_criteria,
				// 	assumptions: selectedItemDetails?.data?.assumptions,
				// 	edge_cases: selectedItemDetails?.data?.edge_cases,
				// 	role_ids: selectedItemDetails?.data?.role_ids,
				// 	use_cases: [
				// 		{
				// 			alternate_flow:
				// 				selectedItemDetails?.data?.use_cases[0]?.alternate_flow,
				// 			basic_flow: selectedItemDetails?.data?.use_cases[0]?.basic_flow,
				// 			post_conditions:
				// 				selectedItemDetails?.data?.use_cases[0]?.post_conditions,
				// 			pre_conditions:
				// 				selectedItemDetails?.data?.use_cases[0]?.pre_conditions,
				// 			system_behavior:
				// 				selectedItemDetails?.data?.use_cases[0]?.system_behavior,
				// 		},
				// 	],
				// },
			});
		}
	}, [editFormUserStory]);

	useEffect(() => {
		if (selectedItemDetails?.type === "TASK") {
			editFormTask.setFieldsValue({
				title: selectedItemDetails?.title,
				description: selectedItemDetails?.description,
				status: selectedItemDetails?.status,
				priority: selectedItemDetails?.priority,
				type: selectedItemDetails?.type,
				// stage: selectedItemDetails?.stage,
				// data: {
				// 	benefits: selectedItemDetails.data?.benefits,
				// 	role_ids: selectedItemDetails.data?.role_ids,
				// 	exposition: selectedItemDetails.data?.exposition,
				// 	complexity_score: selectedItemDetails.data?.complexity_score,
				// 	complexity_score_reason:
				// 		selectedItemDetails.data?.complexity_score_reason,
				// },
			});
		}
	}, [editFormTask]);

	const formItemLayout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 14 },
	};

	interface FeatureChanges {
		[key: string]: {
			oldValue: any;
			newValue: any;
		};
	}

	const { run: postActivelogs } = useRequest(
		(body) => createActivelog(body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				message.success("Activlog  updated successfully");
				console.log(response);
			},
			onError: (error) => {
				console.log(error);
				message.error(`Active Log Error: ${error.message}`);
			},
		}
	);
	const compareObjects = async (
		obj1: any,
		obj2: any,
		type: string
	): Promise<FeatureChanges[]> => {
		const changes: FeatureChanges[] = [];

		// Helper function to convert comma-separated string to array
		const stringToArray = (value: any): any => {
			return Array.isArray(value) ? value : value ? value.split(".,") : [];
		};

		if (
			typeof obj1.data === "object" &&
			obj1.data !== null &&
			typeof obj2.data === "object" &&
			obj2.data !== null &&
			type === "FEATURE"
		) {
			// Iterate over the keys of obj1.data
			for (const key in obj1.data) {
				if (
					Object.prototype.hasOwnProperty.call(obj1.data, key) &&
					Object.prototype.hasOwnProperty.call(obj2.data, key)
				) {
					const oldValue = obj1.data[key];
					const newValue = obj2.data[key];

					// Convert values to arrays if necessary
					const isArrayField = [
						"benefits",
						"complexity_score",
						"complexity_score_reason",
						"exposition",
						"dependant_features_ids",
						"role_ids",
					].includes(key);
					const oldValueArray = isArrayField
						? stringToArray(oldValue)
						: oldValue;
					const newValueArray = isArrayField
						? stringToArray(newValue)
						: newValue;

					// Check if the values are different
					if (JSON.stringify(oldValueArray) !== JSON.stringify(newValueArray)) {
						if (
							!changes.some(
								(change) =>
									JSON.stringify(change[key]) ===
									JSON.stringify({
										oldValue: oldValueArray,
										newValue: newValueArray,
									})
							)
						) {
							changes.push({
								[`data/${key}`]: {
									oldValue: oldValueArray,
									newValue: newValueArray,
								},
							});
						}
					}
				}
			}
		}

		if (
			Array.isArray(obj1.data?.use_cases) &&
			Array.isArray(obj2.data?.use_cases)
		) {
			obj1.data.use_cases.forEach((useCase1: any, index: number) => {
				const useCase2 = obj2.data.use_cases[index];

				for (const key in useCase1) {
					if (Object.prototype.hasOwnProperty.call(useCase2, key)) {
						const oldValue = useCase1[key];
						const newValue = useCase2[key];

						// Convert values to arrays if necessary
						const isArrayField = [
							"alternate_flow",
							"basic_flow",
							"business_logic",
							"post_conditions",
							"pre_conditions",
							"system_behavior",
						].includes(key);
						const oldValueArray = isArrayField
							? stringToArray(oldValue)
							: oldValue;
						const newValueArray = isArrayField
							? stringToArray(newValue)
							: newValue;

						// Check if the values are different
						if (
							JSON.stringify(oldValueArray) !== JSON.stringify(newValueArray)
						) {
							if (
								!changes.some(
									(change) =>
										JSON.stringify(change[key]) ===
										JSON.stringify({
											oldValue: oldValueArray,
											newValue: newValueArray,
										})
								)
							) {
								changes.push({
									[`use_cases/${key}`]: {
										oldValue: oldValueArray,
										newValue: newValueArray,
									},
								});
							}
						}
					}
				}
			});
		}

		// Iterate over the keys of obj1
		for (const key in obj1) {
			// Skip the 'use_cases' field
			if (key === "data") {
				continue;
			}

			// Check if the key exists in obj2 and values are different
			if (Object.prototype.hasOwnProperty.call(obj2, key)) {
				let oldValue = obj1[key];
				let newValue = obj2[key];

				// Convert values to arrays if the key is 'exposition' or 'benefits'
				if (
					key === "description" ||
					key === "stage" ||
					key === "priority" ||
					key === "status" ||
					key === "title"
				) {
					oldValue = stringToArray(oldValue);
					newValue = stringToArray(newValue);
				}

				// Check if the values are different
				if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
					changes.push({
						[key]: {
							oldValue,
							newValue,
						},
					});
				}
			}
		}

		const Reqbody: any = {
			type: type,
			content: changes,
			parentId: obj1._id,
			createdBy: "",
		};

		postActivelogs(Reqbody);
		// try {
		// 	const response = await fetch(
		// 		`${process.env.REACT_APP_API_URL}/activelogs`,
		// 		{
		// 			method: "POST",
		// 			headers: {
		// 				"Content-Type": "application/json",
		// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
		// 			},
		// 			body: JSON.stringify(Reqbody),
		// 		}
		// 	);

		// 	if (response.ok) {
		// 		// Feature updated successfully.
		// 		// Fetch data again to refresh the UI
		// 		// fetchData();
		// 		// onCloseEditDrawer();
		// 	} else {
		// 		console.error("Failed to update feature.");
		// 	}
		// } catch (error) {
		// 	console.error("Error updating feature:", error);
		// }

		return changes;
	};

	const handleUpdate = async (values: any) => {
		const reqbody = {
			title: values.title,
			description: values.description,
			status: values.status,
			type: selectedItemDetails.type,
			tags: selectedItemDetails.tags,
			priority: values.priority,
			parentObject: selectedItemDetails.parentObject,
			links: selectedItemDetails.links,
			dependencies: selectedItemDetails.dependencies,
			// stage: values.stage,
			// data: {
			// 	...selectedItemDetails.data,
			// 	benefits: Array.isArray(values.data?.benefits)
			// 		? values.data.benefits
			// 		: values.data.benefits?.split(".,"),
			// 	dependant_features_ids: Array.isArray(
			// 		selectedItemDetails?.data?.dependant_features_ids
			// 	)
			// 		? selectedItemDetails?.data?.dependant_features_ids
			// 		: selectedItemDetails?.data?.dependant_features_ids?.split(".,"),
			// 	complexity_score: values.data.complexity_score,
			// 	complexity_score_reason: values.data.complexity_score_reason,
			// 	exposition: values.data.exposition,
			// 	role_ids: Array.isArray(values.data.role_ids)
			// 		? values.data.role_ids
			// 		: values.data.role_ids?.split(".,"),
			// },
		};
		// compareObjects(selectedItemDetails, reqbody, "FEATURE");

		try {
			setLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(reqbody),
				}
			);

			if (response.ok) {
				setEditModalOpen(!isEditModalOpen);
			} else {
				console.error("Failed to update work item");
			}
		} catch (error) {
			console.error("Error updating work item:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleUpdateStory = async (values: any) => {
		const reqbody = {
			title: values.title,
			description: values.description,
			status: values.status,
			type: selectedItemDetails.type,
			stage: values.stage,
			tags: selectedItemDetails.tags,
			priority: values.priority,
			parentObject: selectedItemDetails.parentObject,
			links: selectedItemDetails.links,
			dependencies: selectedItemDetails.dependencies,
			// data: {
			// 	...selectedItemDetails.data,
			// 	acceptance_criteria: Array.isArray(values?.data?.acceptance_criteria)
			// 		? values?.data?.acceptance_criteria
			// 		: values?.data?.acceptance_criteria?.split(".,"),
			// 	story_points: values?.data?.story_points,
			// 	role_ids: Array.isArray(values?.data?.role_ids)
			// 		? values?.data?.role_ids
			// 		: values?.data?.role_ids?.split(".,"),
			// 	edge_cases: Array.isArray(selectedItemDetails?.data?.edge_cases)
			// 		? selectedItemDetails?.data?.edge_cases
			// 		: selectedItemDetails?.data?.edge_cases?.split(".,"),
			// 	dependencies: Array.isArray(selectedItemDetails?.data?.dependencies)
			// 		? selectedItemDetails?.data?.dependencies
			// 		: selectedItemDetails?.data?.dependencies?.split(".,"),
			// 	assumptions: Array.isArray(selectedItemDetails?.data?.assumptions)
			// 		? selectedItemDetails?.data?.assumptions
			// 		: selectedItemDetails?.data?.assumptions?.split(".,"),
			// 	business_rules: Array.isArray(selectedItemDetails?.data?.business_rules)
			// 		? selectedItemDetails?.data?.business_rules
			// 		: selectedItemDetails?.data?.business_rules?.split(".,"),
			// 	use_cases: [
			// 		{
			// 			alternate_flow: Array.isArray(
			// 				values?.data?.use_cases[0]?.alternate_flow
			// 			)
			// 				? values?.data?.use_cases[0]?.alternate_flow
			// 				: values?.data?.use_cases[0]?.alternate_flow?.split(".,"),
			// 			basic_flow: Array.isArray(values.data.use_cases[0]?.basic_flow)
			// 				? values?.data?.use_cases[0]?.basic_flow
			// 				: values?.data?.use_cases[0]?.basic_flow?.split(".,"),
			// 			post_conditions: Array.isArray(
			// 				values?.data?.use_cases[0]?.post_conditions
			// 			)
			// 				? values?.data?.use_cases[0]?.post_conditions
			// 				: values?.data?.use_cases[0]?.post_conditions?.split(".,"),
			// 			pre_conditions: Array.isArray(
			// 				values?.data?.use_cases[0]?.pre_conditions
			// 			)
			// 				? values?.data?.use_cases[0]?.pre_conditions
			// 				: values?.data?.use_cases[0]?.pre_conditions?.split(".,"),
			// 			system_behavior: Array.isArray(
			// 				values?.data?.use_cases[0]?.system_behavior
			// 			)
			// 				? values?.data?.use_cases[0]?.system_behavior
			// 				: values?.data?.use_cases[0]?.system_behavior?.split(".,"),
			// 			business_logic: Array.isArray(
			// 				selectedItemDetails?.data?.use_cases[0]?.business_logic
			// 			)
			// 				? selectedItemDetails?.data?.use_cases[0]?.business_logic
			// 				: selectedItemDetails?.data?.use_cases[0]?.business_logic?.split(
			// 						".,"
			// 					),
			// 		},
			// 	],
			// },
		};

		// compareObjects(selectedItemDetails, reqbody, "USER_STORY");

		try {
			setLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(reqbody),
				}
			);

			if (response.ok) {
				console.log("Work item updated successfully", values);
				setEditModalOpen(!isEditModalOpen);
			} else {
				console.error("Failed to update work item");
			}
		} catch (error) {
			console.error("Error updating work item:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleUpdateTask = async (values: any) => {
		const reqbody = {
			title: values.title,
			description: values.description,
			status: values.status,
			type: selectedItemDetails.type,
			tags: selectedItemDetails.tags,
			priority: values.priority,
			parentObject: selectedItemDetails.parentObject,
			links: selectedItemDetails.links,
			dependencies: selectedItemDetails.dependencies,
			// stage: values.stage,
			// data: {
			// 	...selectedItemDetails.data,
			// 	benefits: Array.isArray(values.data?.benefits)
			// 		? values.data.benefits
			// 		: values.data.benefits?.split(".,"),
			// 	dependant_features_ids: Array.isArray(
			// 		selectedItemDetails?.data?.dependant_features_ids
			// 	)
			// 		? selectedItemDetails?.data?.dependant_features_ids
			// 		: selectedItemDetails?.data?.dependant_features_ids?.split(".,"),
			// 	complexity_score: values.data.complexity_score,
			// 	complexity_score_reason: values.data.complexity_score_reason,
			// 	exposition: values.data.exposition,
			// 	role_ids: Array.isArray(values.data.role_ids)
			// 		? values.data.role_ids
			// 		: values.data.role_ids?.split(".,"),
			// },
		};

		try {
			setLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(reqbody),
				}
			);

			if (response.ok) {
				console.log("Work item updated successfully", values);
				setEditModalOpen(!isEditModalOpen);
			} else {
				console.error("Failed to update work item");
			}
		} catch (error) {
			console.error("Error updating work item:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleNavigate = () => {
		if (selectedItemDetails?.type === "FEATURE") {
			navigate("/space/planner/backlogs");
		} else if (selectedItemDetails?.type === "USER STORY") {
			navigate("/space/planner/backlogs");
		} else if (selectedItemDetails?.type === "TASK") {
			navigate("/space/planner/mytasks");
		}
	};

	return (
		<Flex
			justify="center"
			style={{
				backgroundColor: "#EDF4FF",
				margin: "1rem",
				padding: "1rem",
				overflow: "scroll",
				// overflow: open ? "hidden" : "scroll",
				height: "73vh",
				maxWidth: "100vw",
				display: "block",
				position: "relative",
			}}
			className="WorkspaceDiv"
		>
			<Card>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginLeft: "3.9vw",
						}}
					>
						<Button
							onClick={handleNavigate}
							shape="circle"
							icon={<ArrowLeftOutlined style={{ color: "#0444BF" }} />}
							style={{ marginRight: "5px" }}
						/>
						<h2 style={{ textAlign: "left", marginLeft: "10px" }}>
							{selectedItemDetails?.title}
						</h2>
					</div>
					<Button
						key="update"
						type="primary"
						loading={loading}
						onClick={() => {
							if (selectedItemDetails?.type === "FEATURE") {
								setEditModalOpen(!isEditModalOpen);
								editFormFeature.submit();
								navigate("/space/planner/backlogs");
							} else if (selectedItemDetails?.type === "USER STORY") {
								setEditModalOpen(!isEditModalOpen);
								editFormUserStory.submit();
								navigate("/space/planner/backlogs");
							} else if (selectedItemDetails?.type === "TASK") {
								setEditModalOpen(!isEditModalOpen);
								editFormTask.submit();
								navigate("/space/planner/mytasks");
							}
						}}
					>
						Update
					</Button>
				</div>

				<div style={{ height: "58vh", overflow: "scroll", marginTop: "2vh" }}>
					{selectedItemDetails?.type === "FEATURE" ? (
						<Form
							style={{ marginLeft: "3vw" }}
							{...formItemLayout}
							form={editFormFeature}
							onFinish={handleUpdate}
						>
							<div className="form-row">
								<Form.Item
									className="form-item-edit-modal"
									label="Title"
									name="title"
								>
									<Input />
								</Form.Item>
								<Form.Item label="Description" name="description">
									<ReactQuill />
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Status" name="status">
									<Select>
										<Option value={Constants.CAPS_OPEN}>
											{Constants.OPEN}
										</Option>
										<Option value={Constants.CAPS_IN_PROGRESS}>
											{Constants.IN_PROGRESS}
										</Option>
										<Option value={Constants.CAPS_COMPLETED}>
											{Constants.COMPLETED}
										</Option>
									</Select>
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Priority" name={["priority"]}>
									<Select>
										<Option value={Constants.CAPS_URGENT}>
											{Constants.URGENT}
										</Option>
										<Option value={Constants.CAPS_IMPORTANT}>
											{Constants.IMPORTANT}
										</Option>
										<Option value={Constants.CAPS_MEDIUM}>
											{Constants.MEDIUM}
										</Option>
										<Option value={Constants.CAPS_LOW}>{Constants.LOW}</Option>
									</Select>
								</Form.Item>
							</div>
							{/* <div className="form-row">
								<Form.Item label="Stage" name="stage">
									<Input />
								</Form.Item>
								<Form.Item
									label="Complexity Score"
									name={["data", "complexity_score"]}
								>
									<Select>
										<Option value="S">S</Option>
										<Option value="M">M</Option>
										<Option value="L">L</Option>
									</Select>
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Exposition" name={["data", "exposition"]}>
									<Input />
								</Form.Item>
								<Form.Item
									label="Complexity Score Reason"
									name={["data", "complexity_score_reason"]}
								>
									<Input />
								</Form.Item>
								<Form.Item label="Benefits" name={["data", "benefits"]}>
									<Input.TextArea />
								</Form.Item>
								<Form.Item label="Role Id" name={["data", "role_ids"]}>
									<Input.TextArea />
								</Form.Item>
							</div> */}
						</Form>
					) : selectedItemDetails?.type === "TASK" ? ( // Handle TASK form separately
						<Form
							style={{ marginLeft: "3vw" }}
							{...formItemLayout}
							form={editFormTask}
							onFinish={handleUpdateTask}
						>
							<div className="form-row">
								<Form.Item
									className="form-item-edit-modal"
									label="Title"
									name="title"
								>
									<Input />
								</Form.Item>
								<Form.Item label="Description" name="description">
									<ReactQuill />
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Status" name="status">
									<Select disabled={userId !== assigneeId}>
										<Option value="OPEN">open</Option>
										<Option value="IN_PROGRESS">in progress</Option>
										<Option value="COMPLETED">completed</Option>
									</Select>
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Priority" name={["priority"]}>
									<Select>
										<Option value="Urgent">Urgent</Option>
										<Option value="Important">Important</Option>
										<Option value="Medium">Medium</Option>
										<Option value="Low">Low</Option>
									</Select>
								</Form.Item>
							</div>
							{/* <div className="form-row">
								<Form.Item label="Stage" name="stage">
									<Input />
								</Form.Item>
								<Form.Item
									label="Complexity Score"
									name={["data", "complexity_score"]}
								>
									<Select>
										<Option value="S">S</Option>
										<Option value="M">M</Option>
										<Option value="L">L</Option>
									</Select>
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Exposition" name={["data", "exposition"]}>
									<Input />
								</Form.Item>
								<Form.Item
									label="Complexity Score Reason"
									name={["data", "complexity_score_reason"]}
								>
									<Input />
								</Form.Item>
								<Form.Item label="Benefits" name={["data", "benefits"]}>
									<Input.TextArea />
								</Form.Item>
								<Form.Item label="Role Id" name={["data", "role_ids"]}>
									<Input.TextArea />
								</Form.Item>
							</div> */}
						</Form>
					) : (
						<Form
							style={{ marginLeft: "3vw" }}
							{...formItemLayout}
							form={editFormUserStory}
							onFinish={handleUpdateStory}
						>
							<div className="form-row">
								<Form.Item
									className="form-item-edit-modal"
									label="Title"
									name="title"
								>
									<Input />
								</Form.Item>
								<Form.Item label="Description" name="description">
									<ReactQuill />
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Status" name="status">
									<Select>
										<Option value="OPEN">open</Option>
										<Option value="IN_PROGRESS">in progress</Option>
										<Option value="COMPLETED">completed</Option>
									</Select>
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Priority" name={["priority"]}>
									<Select>
										<Option value="Urgent">Urgent</Option>
										<Option value="Important">Important</Option>
										<Option value="Medium">Medium</Option>
										<Option value="Low">Low</Option>
									</Select>
								</Form.Item>
							</div>
							{/* <div className="form-row">
								<Form.Item label="Stage" name="stage">
									<Input />
								</Form.Item>

								<Form.Item
									label="Aceptance criteria"
									name={["data", "acceptance_criteria"]}
								>
									<Input.TextArea />
								</Form.Item>
							</div>
							<div className="form-row">
								<Form.Item label="Story Points" name={["data", "story_points"]}>
									<Input />
								</Form.Item>
								<Form.Item label="Role Id" name={["data", "role_ids"]}>
									<Input.TextArea />
								</Form.Item>
								<Form.Item
									label="Alternate Flow"
									name={["data", "use_cases", 0, "alternate_flow"]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label="Basic Flow"
									name={["data", "use_cases", 0, "basic_flow"]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label="Pre Conditions"
									name={["data", "use_cases", 0, "pre_conditions"]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label="Post Conditions"
									name={["data", "use_cases", 0, "post_conditions"]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label="System Behaviour"
									name={["data", "use_cases", 0, "system_behavior"]}
								>
									<Input />
								</Form.Item>
							</div> */}
						</Form>
					)}
				</div>
			</Card>
		</Flex>
	);
};

export default ExpandedModalForEdit;
