import { BlockNoteEditor, PartialBlock } from "@blocknote/core";
import { BlockNoteView } from "@blocknote/mantine";
import { useEffect, useMemo, useState, useRef } from "react";

interface BlockNoteViewComponentProps {
	Data: any;
}

const BacklogDocsView: React.FC<BlockNoteViewComponentProps> = ({
	Data,
}: {
	Data: any;
}) => {
	const [initialContent, setInitialContent] = useState<
		PartialBlock[] | undefined | "loading"
	>("loading");

	const editorRef = useRef<any>();

	useEffect(() => {
		const loadContent = async () => {
			if (Data && Data.length > 0) {
				const mappedContent = Data.map((item: any) => {
					return {
						type: "paragraph",
						props: {},
						content: [
							`${item?.WorkitemId} : ${item?.title}`,
							`\n${item?.description}`,
							`\n`,
						],
					} as PartialBlock;
				});

				setInitialContent([...mappedContent]);
			} else {
				setInitialContent([{ type: "paragraph", content: [""] }]);
			}
		};
		loadContent();
	}, [Data]);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		// Prevent typing by intercepting key press events
		event.preventDefault();
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Prevent cursor movement and selection by intercepting click events
		event.preventDefault();
	};

	const editor = useMemo(() => {
		if (initialContent === "loading") {
			return undefined;
		}
		return BlockNoteEditor.create({ initialContent });
	}, [initialContent]);

	useEffect(() => {
		if (editor) {
			// Attach event listeners to the editor's DOM element
			editorRef.current.addEventListener("keydown", handleKeyPress);
			editorRef.current.addEventListener("click", handleClick);

			// Clean up event listeners on component unmount
			return () => {
				const currentEditorRef = editorRef.current;
				if (currentEditorRef) {
					currentEditorRef.removeEventListener("keydown", handleKeyPress);
					currentEditorRef.removeEventListener("click", handleClick);
				}
			};
		}
	}, [editor]);

	if (initialContent === "loading" || !editor) {
		return <div>Loading content...</div>;
	}

	return (
		<>
			<div style={{ overflowY: "scroll", height: "70vh" }}>
				<div ref={editorRef} style={{ pointerEvents: "none" }}>
					<BlockNoteView editor={editor} theme="light" />
				</div>
			</div>
		</>
	);
};

export default BacklogDocsView;
