import React, { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

type ChatStreamProps = {
	// Define your props here
};

const ChatStream: React.FC<ChatStreamProps> = () => {
	const [streamRes, setStreamRes] = useState<string>(""); // State to store the stream response

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	// const fetchData = async () => {
	// 	try {
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/ai-converse-messages/invoke/streamv2`,
	// 			{
	// 				method: "POST",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					// Authorization: "Basic " + btoa("myusername:password123"),
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 				body: JSON.stringify({
	// 					aiInvokeType: "goal-conversation",
	// 					data: {
	// 						threadId: "66337eb1293d2645aec465bb",
	// 						message: {
	// 							content: "Write blog about AI and ML",
	// 						},
	// 					},
	// 				}),
	// 			}
	// 		);

	// 		if (!response.ok) {
	// 			throw new Error("Failed to fetch data");
	// 		}
	// 		console.log(response.body);
	// 		// const reader = response.body?.getReader();
	// 		// const decoder = new TextDecoder();
	// 		// let result = "";

	// 		// if (reader) {
	// 		//     let done = false;
	// 		//     while (!done) {
	// 		//         const { value, done: readerDone } = await reader.read();
	// 		//         const chunk = decoder.decode(value, { stream: !readerDone });

	// 		//         result += chunk;
	// 		//         console.log(result);
	// 		//         setStreamRes(result);

	// 		//         if (readerDone) {
	// 		//             console.log("done");
	// 		//             done = true;
	// 		//         }
	// 		//     }
	// 		// }
	// 		streamData();
	// 	} catch (error) {
	// 		console.error("Error fetching data:", error);
	// 	}
	// };

	const streamData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/ai-converse-messages/replyfor/stream/663b1499153d50813f7f3dc1`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						// Authorization: "Basic " + btoa("myusername:password123"),
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					// body: JSON.stringify({
					// 	aiInvokeType: "goal-conversation",
					// 	data: {
					// 		threadId: "66337eb1293d2645aec465bb",
					// 		message: {
					// 			content: "Write blog about AI and ML",
					// 		},
					// 	},
					// }),
				}
			);

			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			//console.log(response.body);
			const reader = response.body?.getReader();
			const decoder = new TextDecoder();
			let result = "";

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					const chunk = decoder.decode(value, { stream: !readerDone });

					result += chunk;
					console.log(result);
					setStreamRes(result);

					if (readerDone) {
						console.log("done");
						done = true;
					}
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		// Call the API and stream the response
		streamData();
		//fetchData();
	}, []);

	return (
		<div>
			{/* Render the stream response */}
			{streamRes}
		</div>
	);
};

export default ChatStream;
