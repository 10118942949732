// /* eslint-disable prefer-const */
// import { register } from "@antv/xflow";
// import type { Node } from "@antv/xflow";

// import "./xflow.css";
// import { useEffect, useState } from "react";

// import { Button, Dropdown, Flex, Form, Input, Menu, Modal, Table } from "antd";
// import useWorkspaceStore from "../../stores/WorkspaceState";
// import { AppstoreAddOutlined, EditOutlined } from "@ant-design/icons";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../../stores/UserProfileStore";
// const DATA_SCHEMA_NODE = "data-schmea-node";

// const NodeComponent = ({ node }: { node: Node }) => {
// 	const { dataSchema } = useWorkspaceStore((state: any) => ({
// 		dataSchema: state.dataSchema,
// 	}));
// 	const [form] = Form.useForm();
// 	const data = node.getData();
// 	const [tableName, setTableName] = useState<string>("");
// 	const [tableData, setTableData] = useState<
// 		{ fieldName: string; type: string }[]
// 	>([]);
// 	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

// 	const [selectedRow, setSelectedRow] = useState<any>([]);
// 	const [modalVisible, setModalVisible] = useState(false);
// 	const [AddModelVisible, setAddModelVisible] = useState(false);

// 	const handleEditClick = (record: any[]) => {
// 		// console.log(record, "record");
// 		setSelectedRow(record);
// 		setModalVisible(true);
// 	};

// 	const handleModalClose = () => {
// 		setModalVisible(false);
// 	};

// 	const handleAddModelClose = () => {
// 		setAddModelVisible(false);
// 	};

// 	const handleAddModelClick = () => {
// 		setAddModelVisible(true);
// 	};

// 	useEffect(() => {
// 		console.log("data", tableData);
// 	}, [tableData]);
// 	useEffect(() => {
// 		setTableName(data.entityName);
// 		console.log(data.entityFields);
// 		setTableData(data.entityFields);
// 	}, [data]);

// 	useEffect(() => {
// 		if (selectedRow) {
// 			form.setFieldsValue({ ...selectedRow });
// 		}
// 	}, [selectedRow, form]);

// 	const columns = [
// 		{ title: "Field Name", dataIndex: "fieldName", key: "fieldName" },
// 		{ title: "Field Type", dataIndex: "type", key: "type" },
// 		{
// 			title: "Action",

// 			key: "action",
// 			render: (text: any, record: any) => (
// 				<Dropdown
// 					overlay={
// 						<Menu>
// 							<Menu.Item key="edit" onClick={() => handleEditClick(record)}>
// 								Edit
// 							</Menu.Item>
// 							<Menu.Item key="delete" onClick={() => handleDelete(record)}>
// 								Delete
// 							</Menu.Item>
// 						</Menu>
// 					}
// 					trigger={["click"]}
// 				>
// 					<Button>
// 						<EditOutlined />
// 					</Button>
// 				</Dropdown>
// 			),
// 		},
// 	];

// 	// console.log(dataSchema, "dataSchema");

// 	const handleSave = () => {
// 		const formData = form.getFieldsValue();

// 		let collectionIndexss: any = -1;

// 		if (!selectedRow) return;

// 		const selectedIndex = tableData.findIndex((item) => item === selectedRow);

// 		if (selectedIndex !== -1) {
// 			const updatedData = [...tableData];
// 			updatedData[selectedIndex] = formData; // Use formData instead of selectedRow

// 			const g: any = {};

// 			for (let index = 0; index < updatedData.length; index++) {
// 				g[updatedData[index].fieldName] = {
// 					// Use updatedData[index].fieldName instead of updatedData[index].krittiks
// 					type: updatedData[index].type,
// 				};
// 			}

// 			const s = {
// 				properties: {
// 					...g,
// 				},
// 			};

// 			for (let index = 0; index < dataSchema.data.collections.length; index++) {
// 				const collection = dataSchema.data.collections[index];
// 				if (collection.data_schema[data.entityName]) {
// 					console.log(index, "index");
// 					collectionIndexss = index;

// 					break;
// 				}
// 			}

// 			// console.log(collectionIndexss, "collectionIndexss");

// 			const abc = [
// 				...dataSchema.data.collections.map((collection: any, index: any) => {
// 					if (index === collectionIndexss) {
// 						return {
// 							...collection,
// 							data_schema: {
// 								...collection.data_schema,
// 								[data.entityName]: {
// 									...collection.data_schema[data.entityName],
// 									...s,
// 								},
// 							},
// 						};
// 					} else {
// 						return collection;
// 					}
// 				}),
// 			];

// 			const def = {
// 				...dataSchema,
// 				data: {
// 					...dataSchema.data,
// 					collections: abc,
// 				},
// 			};

// 			console.log(def);
// 			fetch(
// 				`${process.env.REACT_APP_API_URL}/project-artifact/${dataSchema._id}`,
// 				{
// 					method: "PUT",

// 					headers: {
// 						"Content-Type": "application/json; charset=UTF-8",
// 						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// 					},
// 					body: JSON.stringify(def),
// 				}
// 			)
// 				.then((response) => {
// 					if (response.ok) {
// 						console.log("Update successful", response);
// 					} else {
// 						console.error("Update failed", response);
// 					}
// 				})
// 				.catch((error) => {
// 					console.error("Error fetching data:", error);
// 				});
// 			setTableData(updatedData);
// 		}

// 		setModalVisible(false);
// 	};

// 	const handleDelete = (recoard: any) => {
// 		console.log(recoard, "recoard");

// 		// setSelectedRow(recoard);
// 		let collectionIndexss: any = -1;

// 		// if (!selectedRow) return;

// 		const selectedIndex = tableData.findIndex((item) => item === recoard);

// 		if (selectedIndex !== -1) {
// 			const updatedData = [...tableData];
// 			updatedData.splice(selectedIndex, 1);

// 			console.log(updatedData, "updatedData");
// 			const g: any = {};

// 			for (let index = 0; index < updatedData.length; index++) {
// 				g[updatedData[index].fieldName] = {
// 					// Use updatedData[index].fieldName instead of updatedData[index].krittiks
// 					type: updatedData[index].type,
// 				};
// 			}

// 			const s = {
// 				properties: {
// 					...g,
// 				},
// 			};

// 			console.log(s, "s");

// 			for (let index = 0; index < dataSchema.data.collections.length; index++) {
// 				const collection = dataSchema.data.collections[index];
// 				if (collection.data_schema[data.entityName]) {
// 					console.log(index, "index");
// 					collectionIndexss = index;

// 					break;
// 				}
// 			}

// 			// console.log(collectionIndexss, "collectionIndexss");

// 			const abc = [
// 				...dataSchema.data.collections.map((collection: any, index: any) => {
// 					if (index === collectionIndexss) {
// 						return {
// 							...collection,
// 							data_schema: {
// 								...collection.data_schema,
// 								[data.entityName]: {
// 									...collection.data_schema[data.entityName],
// 									...s,
// 								},
// 							},
// 						};
// 					} else {
// 						return collection;
// 					}
// 				}),
// 			];

// 			const def = {
// 				...dataSchema,
// 				data: {
// 					...dataSchema.data,
// 					collections: abc,
// 				},
// 			};

// 			console.log(def);
// 			fetch(
// 				`${process.env.REACT_APP_API_URL}/project-artifact/${dataSchema._id}`,
// 				{
// 					method: "PUT",

// 					headers: {
// 						"Content-Type": "application/json; charset=UTF-8",
// 						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// 					},
// 					body: JSON.stringify(def),
// 				}
// 			)
// 				.then((response) => {
// 					if (response.ok) {
// 						console.log("Update successful", response);
// 					} else {
// 						console.error("Update failed", response);
// 					}
// 				})
// 				.catch((error) => {
// 					console.error("Error fetching data:", error);
// 				});
// 			setTableData(updatedData);
// 		}
// 	};

// 	const handleAddRow = () => {
// 		const formData = form.getFieldsValue();

// 		let collectionIndexss: any = -1;

// 		if (!selectedRow) return;

// 		const selectedIndex = tableData.length;

// 		if (selectedIndex !== -1) {
// 			const updatedData = [...tableData];
// 			updatedData[selectedIndex] = formData;

// 			const g: any = {};

// 			for (let index = 0; index < updatedData.length; index++) {
// 				g[updatedData[index].fieldName] = {
// 					type: updatedData[index].type,
// 				};
// 			}

// 			const s = {
// 				properties: {
// 					...g,
// 				},
// 			};

// 			for (let index = 0; index < dataSchema.data.collections.length; index++) {
// 				const collection = dataSchema.data.collections[index];
// 				if (collection.data_schema[data.entityName]) {
// 					console.log(index, "index");
// 					collectionIndexss = index;

// 					break;
// 				}
// 			}

// 			// console.log(collectionIndexss, "collectionIndexss");

// 			const abc = [
// 				...dataSchema.data.collections.map((collection: any, index: any) => {
// 					if (index === collectionIndexss) {
// 						return {
// 							...collection,
// 							data_schema: {
// 								...collection.data_schema,
// 								[data.entityName]: {
// 									...collection.data_schema[data.entityName],
// 									...s,
// 								},
// 							},
// 						};
// 					} else {
// 						return collection;
// 					}
// 				}),
// 			];

// 			const def = {
// 				...dataSchema,
// 				data: {
// 					...dataSchema.data,
// 					collections: abc,
// 				},
// 			};

// 			console.log(def);
// 			fetch(
// 				`${process.env.REACT_APP_API_URL}/project-artifact/${dataSchema._id}`,
// 				{
// 					method: "PUT",

// 					headers: {
// 						"Content-Type": "application/json; charset=UTF-8",
// 						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// 					},
// 					body: JSON.stringify(def),
// 				}
// 			)
// 				.then((response) => {
// 					if (response.ok) {
// 						console.log("Update successful", response);
// 						form.resetFields();
// 					} else {
// 						console.error("Update failed", response);
// 					}
// 				})
// 				.catch((error) => {
// 					console.error("Error fetching data:", error);
// 				});
// 			setTableData(updatedData);
// 		}

// 		setAddModelVisible(false);
// 	};

// 	return (
// 		<>
// 			<div
// 				style={{ width: "fit-content", minHeight: "200px", overflow: "scroll" }}
// 			>
// 				<Flex
// 					justify="space-between"
// 					style={{ background: "cyan", padding: "0.5rem" }}
// 				>
// 					<h2>{tableName}</h2>
// 					<AppstoreAddOutlined
// 						style={{ cursor: "pointer", fontSize: "2rem" }}
// 						onClick={handleAddModelClick}
// 					/>
// 				</Flex>
// 				<Table
// 					dataSource={tableData}
// 					columns={columns}
// 					showHeader={false}
// 					pagination={false}
// 				/>
// 				<Modal
// 					title="Edit Row"
// 					open={modalVisible}
// 					onCancel={handleModalClose}
// 					footer={null}
// 				>
// 					<Form
// 						labelCol={{ span: 6 }}
// 						wrapperCol={{ span: 18 }}
// 						form={form}
// 						onFinish={handleSave}
// 					>
// 						<Form.Item name="fieldName" label="Field Name">
// 							<Input />
// 						</Form.Item>
// 						<Form.Item name="type" label="Type">
// 							<Input />
// 						</Form.Item>
// 						<Form.Item>
// 							<Button type="primary" htmlType="submit">
// 								Save
// 							</Button>
// 						</Form.Item>
// 					</Form>
// 				</Modal>
// 				<Modal
// 					title="Add Row"
// 					open={AddModelVisible}
// 					onCancel={handleAddModelClose}
// 					footer={null}
// 				>
// 					<Form
// 						labelCol={{ span: 6 }}
// 						wrapperCol={{ span: 18 }}
// 						form={form}
// 						onFinish={handleAddRow}
// 					>
// 						<Form.Item name="fieldName" label="Field Name">
// 							<Input />
// 						</Form.Item>
// 						<Form.Item name="type" label="Type">
// 							<Input />
// 						</Form.Item>
// 						<Form.Item>
// 							<Button type="primary" htmlType="submit">
// 								Save
// 							</Button>
// 						</Form.Item>
// 					</Form>
// 				</Modal>
// 			</div>
// 		</>
// 	);
// };
// const ports = {
// 	groups: {
// 		group1: {
// 			position: "top",
// 			attrs: {
// 				circle: {
// 					stroke: "#D06269",
// 					strokeWidth: 1,
// 					r: 4,
// 					magnet: true,
// 				},
// 			},
// 		},
// 		group2: {
// 			position: "right",
// 			attrs: {
// 				circle: {
// 					stroke: "#D06269",
// 					strokeWidth: 1,
// 					r: 4,
// 					magnet: true,
// 				},
// 			},
// 		},
// 		group3: {
// 			position: "bottom",
// 			attrs: {
// 				circle: {
// 					stroke: "#D06269",
// 					strokeWidth: 1,
// 					r: 4,
// 					magnet: true,
// 				},
// 			},
// 		},
// 		group4: {
// 			position: "left",
// 			attrs: {
// 				circle: {
// 					stroke: "#D06269",
// 					strokeWidth: 1,
// 					r: 4,
// 					magnet: true,
// 				},
// 			},
// 		},
// 	},
// 	items: [{ id: "group1", group: "group1" }],
// };

// register({
// 	shape: DATA_SCHEMA_NODE,
// 	component: NodeComponent,
// 	effect: ["data"],
// 	ports: { ...ports },
// });

// export { DATA_SCHEMA_NODE };

/* eslint-disable prefer-const */
import { register } from "@antv/xflow";
import type { Node } from "@antv/xflow";

import "./xflow.css";
import { useEffect, useState } from "react";

import { Button, Dropdown, Flex, Form, Input, Menu, Modal, Table } from "antd";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import { AppstoreAddOutlined, EditOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";

const DATA_SCHEMA_NODE = "data-schmea-node";

const NodeComponent = ({ node }: { node: Node }) => {
	const { dataSchema } = useWorkspaceStore((state: any) => ({
		dataSchema: state.dataSchema,
	}));
	const [form] = Form.useForm();
	const data = node.getData();
	const [tableName, setTableName] = useState<string>("");
	const [tableData, setTableData] = useState<
		{ fieldName: string; type: string }[]
	>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const [selectedRow, setSelectedRow] = useState<any>([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [AddModelVisible, setAddModelVisible] = useState(false);

	const handleEditClick = (record: any[]) => {
		setSelectedRow(record);
		setModalVisible(true);
	};

	const handleModalClose = () => {
		setModalVisible(false);
	};

	const handleAddModelClose = () => {
		setAddModelVisible(false);
	};

	const handleAddModelClick = () => {
		setAddModelVisible(true);
	};

	useEffect(() => {
		console.log("data", tableData);
	}, [tableData]);

	useEffect(() => {
		setTableName(data.entityName);
		setTableData(data.entityFields);
	}, [data]);

	useEffect(() => {
		if (selectedRow) {
			form.setFieldsValue({ ...selectedRow });
		}
	}, [selectedRow, form]);

	const columns = [
		{ title: "Field Name", dataIndex: "fieldName", key: "fieldName" },
		{ title: "Field Type", dataIndex: "type", key: "type" },
		{
			title: "Action",

			key: "action",
			render: (text: any, record: any) => (
				<Dropdown
					overlay={
						<Menu>
							<Menu.Item key="edit" onClick={() => handleEditClick(record)}>
								Edit
							</Menu.Item>
							<Menu.Item key="delete" onClick={() => handleDelete(record)}>
								Delete
							</Menu.Item>
						</Menu>
					}
					trigger={["click"]}
				>
					<Button>
						<EditOutlined />
					</Button>
				</Dropdown>
			),
		},
	];

	const handleSave = () => {
		const formData = form.getFieldsValue();
		let collectionIndexss: any = -1;

		if (!selectedRow) return;

		const selectedIndex = tableData.findIndex((item) => item === selectedRow);

		if (selectedIndex !== -1) {
			const updatedData = [...tableData];
			updatedData[selectedIndex] = formData;

			const g: any = {};

			for (let index = 0; index < updatedData.length; index++) {
				g[updatedData[index].fieldName] = {
					type: updatedData[index].type,
				};
			}

			const s = {
				properties: {
					...g,
				},
			};

			for (let index = 0; index < dataSchema.data.collections.length; index++) {
				const collection = dataSchema.data.collections[index];
				if (collection.data_schema[data.entityName]) {
					collectionIndexss = index;
					break;
				}
			}

			const abc = [
				...dataSchema.data.collections.map((collection: any, index: any) => {
					if (index === collectionIndexss) {
						return {
							...collection,
							data_schema: {
								...collection.data_schema,
								[data.entityName]: {
									...collection.data_schema[data.entityName],
									...s,
								},
							},
						};
					} else {
						return collection;
					}
				}),
			];

			const def = {
				...dataSchema,
				data: {
					...dataSchema.data,
					collections: abc,
				},
			};

			fetch(
				`${process.env.REACT_APP_API_URL}/project-artifact/${dataSchema._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(def),
				}
			)
				.then((response) => {
					if (response.ok) {
						console.log("Update successful", response);
					} else {
						console.error("Update failed", response);
					}
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
			setTableData(updatedData);
		}

		setModalVisible(false);
	};

	const handleDelete = (record: any) => {
		let collectionIndexss: any = -1;

		const selectedIndex = tableData.findIndex((item) => item === record);

		if (selectedIndex !== -1) {
			const updatedData = [...tableData];
			updatedData.splice(selectedIndex, 1);

			const g: any = {};

			for (let index = 0; index < updatedData.length; index++) {
				g[updatedData[index].fieldName] = {
					type: updatedData[index].type,
				};
			}

			const s = {
				properties: {
					...g,
				},
			};

			for (let index = 0; index < dataSchema.data.collections.length; index++) {
				const collection = dataSchema.data.collections[index];
				if (collection.data_schema[data.entityName]) {
					collectionIndexss = index;
					break;
				}
			}

			const abc = [
				...dataSchema.data.collections.map((collection: any, index: any) => {
					if (index === collectionIndexss) {
						return {
							...collection,
							data_schema: {
								...collection.data_schema,
								[data.entityName]: {
									...collection.data_schema[data.entityName],
									...s,
								},
							},
						};
					} else {
						return collection;
					}
				}),
			];

			const def = {
				...dataSchema,
				data: {
					...dataSchema.data,
					collections: abc,
				},
			};

			fetch(
				`${process.env.REACT_APP_API_URL}/project-artifact/${dataSchema._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(def),
				}
			)
				.then((response) => {
					if (response.ok) {
						console.log("Update successful", response);
					} else {
						console.error("Update failed", response);
					}
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
			setTableData(updatedData);
		}
	};

	const handleAddRow = () => {
		const formData = form.getFieldsValue();
		let collectionIndexss: any = -1;

		const selectedIndex = tableData.length;

		if (selectedIndex !== -1) {
			const updatedData = [...tableData];
			updatedData[selectedIndex] = formData;

			const g: any = {};

			for (let index = 0; index < updatedData.length; index++) {
				g[updatedData[index].fieldName] = {
					type: updatedData[index].type,
				};
			}

			const s = {
				properties: {
					...g,
				},
			};

			for (let index = 0; index < dataSchema.data.collections.length; index++) {
				const collection = dataSchema.data.collections[index];
				if (collection.data_schema[data.entityName]) {
					collectionIndexss = index;
					break;
				}
			}

			const abc = [
				...dataSchema.data.collections.map((collection: any, index: any) => {
					if (index === collectionIndexss) {
						return {
							...collection,
							data_schema: {
								...collection.data_schema,
								[data.entityName]: {
									...collection.data_schema[data.entityName],
									...s,
								},
							},
						};
					} else {
						return collection;
					}
				}),
			];

			const def = {
				...dataSchema,
				data: {
					...dataSchema.data,
					collections: abc,
				},
			};

			fetch(
				`${process.env.REACT_APP_API_URL}/project-artifact/${dataSchema._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(def),
				}
			)
				.then((response) => {
					if (response.ok) {
						console.log("Update successful", response);
						form.resetFields();
					} else {
						console.error("Update failed", response);
					}
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
			setTableData(updatedData);
		}

		setAddModelVisible(false);
	};

	return (
		<>
			<div
				style={{ width: "fit-content", minHeight: "200px", overflow: "scroll" }}
			>
				<Flex
					justify="space-between"
					style={{ background: "cyan", padding: "0.5rem" }}
				>
					<h2>{tableName}</h2>
					<AppstoreAddOutlined
						style={{ cursor: "pointer", fontSize: "2rem" }}
						onClick={handleAddModelClick}
					/>
				</Flex>
				<Table
					dataSource={tableData}
					columns={columns}
					showHeader={false}
					pagination={false}
				/>
				<Modal
					title="Edit Row"
					open={modalVisible}
					onCancel={handleModalClose}
					footer={null}
				>
					<Form
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
						form={form}
						onFinish={handleSave}
					>
						<Form.Item name="fieldName" label="Field Name">
							<Input />
						</Form.Item>
						<Form.Item name="type" label="Type">
							<Input />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					</Form>
				</Modal>
				<Modal
					title="Add Row"
					open={AddModelVisible}
					onCancel={handleAddModelClose}
					footer={null}
				>
					<Form
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
						form={form}
						onFinish={handleAddRow}
					>
						<Form.Item name="fieldName" label="Field Name">
							<Input />
						</Form.Item>
						<Form.Item name="type" label="Type">
							<Input />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</>
	);
};

const ports = {
	groups: {
		group1: {
			position: "top",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group2: {
			position: "right",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group3: {
			position: "bottom",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group4: {
			position: "left",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
	},
	items: [{ id: "group1", group: "group1" }],
};

register({
	shape: DATA_SCHEMA_NODE,
	component: NodeComponent,
	effect: ["data"],
	ports: { ...ports },
	validate: (args: any) => {
		const { edge, graph } = args;
		if (!edge) return false;
		const sourceNode = graph.getNode(edge.source);
		const targetNode = graph.getNode(edge.target);
		if (!sourceNode || !targetNode) return false;
		if (sourceNode === targetNode) return false;
		return true;
	},
	createEdge: (args: any) => {
		const { edge, graph } = args;
		graph.addEdge({
			...edge,
			attrs: {
				line: {
					stroke: "#1890ff",
					strokeWidth: 2,
					targetMarker: {
						name: "classic",
						size: 8,
					},
				},
			},
		});
	},
});

export { DATA_SCHEMA_NODE };
