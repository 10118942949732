const Constants = {
	WORKSPACE: "Workspace",
	WORKSPACE_LIST: "Workspace List",
	BUSINESS_ENGINEERING: "Business Engineering",
	FEATURE_ENGINEERING: "Feature Engineering",
	DESIGN_ENGINEERING: "Design Engineering",
	CODE_ENGINEERING: "Code Engineering",
	FE_FEATURE_ROLE: "Feature Role",
	FE_FEATURE_LIST: "Feature List",
	FE_FEATURE_SPECIFICATION: "Feature Specification",
	DE_USER_PERSONA: "User Persona",
	DE_INFORMATION_ARCHITECTURE: "Information Architecture",
	DE_DOMAIN_DRIVEN_DESIGN: "Domain Driven Design",
	DE_DDD_CORE: "Core Domain",
	DE_DDD_STRATEGIC_DESGIN: "Strategic Design",
	DE_DDD_ADVANCE_PATTERN: "Advance Pattern",
	DE_API_SPECIFICATION: "API Specification",
	DE_DATA_MODEL: "Data Model",

	SETTINGS: "App Settings",
	SETTINGS_USER_MANAGEMENT: "User Management",
	SETTINGS_ROLE_MANAGEMENT: "Role Management",
	SETTINGS_WORKSPACE_MANAGEMENT: "Workspace Management",
	SETTINGS_ACCOUNT_MANAGEMENT: "Account Management",
	SETTINGS_TAG_MANAGEMENT: "Tag Management",
	SETTINGS_WORKITEM_MANAGEMENT: "Workitem Management",
	SETTINGS_PLAN_MANAGEMENT: "Plan Management",
	SETTINGS_PROPERTIES_MANAGEMENT: "Properties Management",

	PROFILE: "Profile",
	PROFILE_MANAGEMENT: "Profile Management",
	PREFERENCES: "Preferences",

	ACTIVITY_STATUS_NOT_STARTED: "Not Started",
	ACTIVITY_STATUS_IN_PROGRESS: "In Progress",
	ACTIVITY_STATUS_COMPLETED: "Completed",
	ACTIVITY_STATUS_PROCESSING: "Processing",

	CAPS_FEATURE: "FEATURE",
	CAPS_USER_STORY: "USER_STORY",
	CAPS_IDEA: "IDEA", // changed to CAPS - Type_Idea
	CAPS_BUG: "BUG", // changed to CAPS - Type_Bug
	CAPS_PRIORITY: "PRIORITY",
	CAPS_TYPE: "TYPE",
	CAPS_UX: "UX",
	TYPE: "Type",
	PRIORITY: "Priority",
	FEATURE: "Feature",
	USER_STORY: "User Story",
	IDEA: "Idea",
	BUG: "Bug",
	CAPS_PRIORITY_SHOULD_HAVE: "Priority_Should Have", // change to CAPS
	SHOULD_HAVE: "Should Have",
	CAPS_PRIORITY_WONT_HAVE: "Priority_Won't have", // change to CAPS
	WONT_HAVE: "Won't Have",
	CAPS_PRIORITY_COULD_HAVE: "Priority_Could have", // change to CAPS
	COULD_HAVE: "Could Have",
	CAPS_PRIORITY_MUST_HAVE: "Priority_Must Have", // change to CAPS
	MUST_HAVE: "Must Have",
	CAPS_STATUS: "STATUS",
	STATUS: "Status",
	CAPS_IN_PROGRESS: "IN PROGRESS", // changed to CAPS - Status_In progress
	IN_PROGRESS: "In_progress",
	CAPS_COMPLETED: "COMPLETED", // changed to CAPS - Status_Completed
	COMPLETED: "Completed",
	CAPS_NOT_STARTED: "NOT STARTED", // changed to CAPS - Status_Not Started
	NOT_STARTED: "Not Started",
	CAPS_OPEN: "OPEN", // changed to CAPS - Status_Open
	OPEN: "Open",
	CAPS_CLOSED: "CLOSED", // changed to CAPS - Status_Closed
	CLOSED: "Closed",
	// CAPS_PENDING: "PENDING", // changed to CAPS - Status_Pending
	// PENDING: "Pending",

	CAPS_RITUAL: "Type_RITUAL", // change to CAPS
	RITUALS: "Rituals",
	CAPS_STAND_UP: "Type_STAND_UP",
	STAND_UP: "Stand Up",

	CAPS_TYPE_FEATURE: "Type_FEATURE", // change to CAPS or replace with CAPS_FEATURE
	CAPS_TYPE_USER_STORY: "Type_USER_STORY",
	CAPS_KANO: "KANO",
	KANO: "Kano",
	CAPS_PRIORITY_BASIC_NEEDS: "Priority_Basic Needs", // change to CAPS
	PRIORITY_BASIC_NEEDS: "Basic Needs",
	CAPS_PRIORITY_PERFORMANCE_NEEDS: "Priority_Performance Needs", // change to CAPS
	PRIORITY_PERFORMANCE_NEEDS: "Performance Needs",
	CAPS_PRIORITY_EXCITEMENT_NEEDS: "Priority_Excitement Needs", // change to CAPS
	PRIORITY_EXCITEMENT_NEEDS: "Excitement Needs",
	CAPS_MOSCOW: "MOSCOW",
	MOSCOW: "MoSCoW",

	// CAPS_IN_PROGRESS: "IN PROGRESS",
	// CAPS_COMPLETED: "COMPLETED",

	CAPS_CODING: "CODING",
	CODING: "Coding",
	CAPS_TESTING: "TESTING",
	TESTING: "Testing",
	CAPS_MEETING: "MEETING",
	MEETING: "Meeting",
	CAPS_OTHER: "OTHER",
	OTHER: "Other",

	CAPS_URGENT: "URGENT",
	URGENT: "Urgent",
	CAPS_IMPORTANT: "IMPORTANT",
	IMPORTANT: "Important",
	CAPS_MEDIUM: "MEDIUM",
	MEDIUM: "Medium",
	CAPS_LOW: "LOW",
	LOW: "Low",
};

export default Constants;
