import React, { useEffect, useState } from "react";
import { List, Spin } from "antd";
import { useParams, useLocation } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";

function isNumeric(value: string) {
	return /^\d+$/.test(value);
}

interface ReviewCommentListProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onCommentCountChange: (count: number) => void; // New prop for passing the count to parent
}

const ReviewCommentList: React.FC<ReviewCommentListProps> = ({
	open,
	setOpen,
	onCommentCountChange,
}) => {
	const { id } = useParams<{ id: string }>();
	const [comments, setComments] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const location = useLocation();
	const [apiEndpoint, setApiEndpoint] = useState<string>("");

	const segments = location.pathname.split("/");

	const dynamicParts = segments.filter(
		(part) => part !== "" && part !== "workspace" && !isNumeric(part)
	);

	const endpoint = dynamicParts.join("/");

	console.log(apiEndpoint);

	useEffect(() => {
		if (endpoint === `fe/featurelist/${id}`) {
			setApiEndpoint("feature-list");
		} else if (endpoint === `fe/featurespec/${id}`) {
			setApiEndpoint("feature-specification");
		} else if (endpoint === `de/uxprocess/${id}`) {
			setApiEndpoint("de-ux-spec");
		} else if (endpoint === `de/info/${id}`) {
			setApiEndpoint("de-ux-spec");
		} else if (endpoint === `de/ddd/core/${id}`) {
			setApiEndpoint("de-ddd-spec");
		} else if (endpoint === `de/ddd/advanced/${id}`) {
			setApiEndpoint("de-ddd-spec");
		} else if (endpoint === `de/ddd/strategic/${id}`) {
			setApiEndpoint("de-ddd-spec");
		} else {
			setApiEndpoint("");
		}
	}, [endpoint, id]);

	const fetchComments = async () => {
		try {
			const apiUrl = `${process.env.REACT_APP_API_URL}/describe/${id}/${apiEndpoint}`;
			console.log("API URL:", apiUrl);
			const response = await fetch(apiUrl, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			const result = await response.json();

			if (response.ok) {
				const userReviewArray = result[0]?.userReview || [];

				const formattedComments = userReviewArray.map((review: any) => ({
					title: review.comment,
					path: review.path,
				}));

				setComments(formattedComments);
				onCommentCountChange(formattedComments.length);
			} else {
				console.error("Failed to fetch comments.");
			}
		} catch (error) {
			console.error("Error fetching comments:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (open) {
			fetchComments();
		}
	}, [id, open, setOpen, apiEndpoint]);

	return (
		<>
			{loading ? (
				<Spin />
			) : (
				<List
					dataSource={comments}
					renderItem={(item) => (
						<List.Item>
							<List.Item.Meta
								title={<span>Path: {item.path}</span>}
								description={item.title}
							/>
						</List.Item>
					)}
				/>
			)}
		</>
	);
};

export default ReviewCommentList;
