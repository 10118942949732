import React, { useEffect, useState } from "react";
import { Bar } from "@ant-design/plots";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { useRequest } from "ahooks";
import { fetchWorkitemByType } from "../api/WorkItemAPI";
import { message } from "antd";

// interface WorkItem {
// 	assignee: {
// 		_id: string;
// 		name: string;
// 	} | null;
// }

const BarChart = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [Details, setDetails] = useState<any[]>([]);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state?.currentWorkspace,
	}));

	const { data: workitemAllData, run: fetchAlldata } = useRequest(
		(worspaceId, type) =>
			fetchWorkitemByType(worspaceId, type, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Bar Cart data");
				// message.success(`All items fetched successfully for bar chart`);
			},
			onError: (error) => {
				message.error(`Failed to fetch all items for bar chart : ${error}`);
			},
		}
	);

	console.log(workitemAllData, "workitemAllData");

	useEffect(() => {
		if (workitemAllData) {
			setDetails(workitemAllData);
		}
	}, [workitemAllData]);

	useEffect(() => {
		fetchAlldata(currentWorkspace?._id, "AllItems");
	}, [currentWorkspace?._id, userProfileStoreInstance?.idToken]);

	// Count the number of work items for each assignee
	const assigneeCounts: { [key: string]: number } = {};
	Details.forEach((item) => {
		const assigneeName = item.assignee ? item.assignee.name : "Not Assigned";
		if (assigneeCounts[assigneeName]) {
			assigneeCounts[assigneeName]++;
		} else {
			assigneeCounts[assigneeName] = 1;
		}
	});

	// Prepare data for bar chart
	const barChartData = [];
	for (const assigneeName in assigneeCounts) {
		barChartData.push({
			assignee: assigneeName,
			count: assigneeCounts[assigneeName],
		});
	}
	console.log(barChartData, "barChartData");

	const config = {
		data: barChartData,
		xField: "assignee",
		yField: "count",
		height: 250,
		autoFit: true,
		barWidthRatio: 0.6,
	};

	return <Bar {...config} />;
};

export default BarChart;
