/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import { Collapse, Flex } from "antd";
import { Column } from "./type";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./CardColumn.css";
import DraggableCard from "./CardComponent";
// import { useMemo } from "react";
interface Props {
	column: Column;
	tasks: any[];
}

function ColumnContainer(props: Props) {
	const { column, tasks } = props;

	// const tasksIds = useMemo(() => {
	// 	return tasks.map((task) => task._id);
	// }, [tasks]);

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: column.id, data: { type: "Column", column } });
	const style = {
		transition,
		transform: CSS.Transform.toString(transform),
	};
	if (isDragging) {
		return <div ref={setNodeRef} style={style} className="element"></div>;
	}

	console.log("inside", column);

	return (
		<Flex ref={setNodeRef} style={style} className="custom-container">
			<Flex
				style={{ border: "none", backgroundColor: "#d6e4ff" }}
				{...attributes}
				{...listeners}
				className="column-header"
			>
				{column.title}
			</Flex>
			{column?.children.map((data: any) => (
				<Collapse
					collapsible="header"
					defaultActiveKey={["1"]}
					items={[
						{
							key: "1",
							label: data.title,
							children: (
								<Flex vertical>
									<SortableContext items={data.value}>
										{data.value.map((task: any) => (
											<DraggableCard key={task._id} task={task} />
										))}
									</SortableContext>
								</Flex>
							),
						},
					]}
				/>
			))}

			{/* <Collapse
				collapsible="header"
				defaultActiveKey={["1"]}
				items={
					
					[
					{
						key: "1",
						label: "This panel can only be collapsed by clicking text",
						children: (
							<Flex vertical>
								<SortableContext items={tasksIds}>
									{tasks.map((task) => (
										<DraggableCard key={task._id} task={task} />
									))}
								</SortableContext>
							</Flex>
						),
					},
				]
			}
			/> */}
			{/* <Flex vertical >
				<SortableContext items={tasksIds}>
					{tasks.map((task) => (
						<DraggableCard key={task._id} task={task} />
					))}
				</SortableContext>
			</Flex> */}
		</Flex>
	);
}

export default ColumnContainer;
