/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { Avatar, Flex, List, Button, Card, Space, message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import moment from "moment";
import {
	CloseOutlined,
	DislikeOutlined,
	LikeOutlined,
	MessageOutlined,
	SendOutlined,
	UserOutlined,
} from "@ant-design/icons";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { BlobServiceClient } from "@azure/storage-blob";
import "@blocknote/core/fonts/inter.css";
import {
	DefaultReactSuggestionItem,
	SuggestionMenuController,
	useCreateBlockNote,
} from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import {
	BlockNoteSchema,
	defaultInlineContentSpecs,
	filterSuggestionItems,
} from "@blocknote/core";
import { Mention } from "./Mention";
import { useRequest } from "ahooks";
import { getActivelogsbyparentId } from "../../api/ActiveLogAPI";

const Activity: any = ({
	subscriberlist,
	setsubscriberlist,
}: {
	subscriberlist: any;
	setsubscriberlist: any;
}) => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [updates, setUpdates] = React.useState<any[]>([]);
	const [matchedUsers, setmatchedUsers] = React.useState<any[]>([]);
	const [mentionedUsers, setMentionedUsers] = useState<any>([]);
	const { selectedItemDetails, isEditModalOpen, rightClickMenu } =
		planerViewStore();

	const [inputValue, setInputValue] = useState<string>("");
	const [messages, setMessages] = useState<any[]>([]);
	const [dell, setDell] = useState<any[]>([]);
	const [replyMode, setReplyMode] = useState<{
		active: boolean;
		messageId: number | null;
	}>({ active: false, messageId: null });
	const [replyModeReply, setReplyModeReply] = useState<{
		active: boolean;
		messageId: number | null;
	}>({ active: false, messageId: null });
	const [replyContent, setReplyContent] = useState<string>("");
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [options, setOptions] = useState<any[]>([]);
	const [mentionTeamUsers, setMentionTeamUsers] = useState<any[]>([]);
	const [mentions, setMentions] = useState<any[]>([]);
	const [uploadImage, setUploadImage] = useState<any>([]);
	const [fileList, setFileList] = useState<any>([]);

	const { data: activelogData, run: ActiveLoggsRun } = useRequest(
		(workspaceId) =>
			getActivelogsbyparentId(workspaceId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				// message.info(`${response.length} updates`);
				console.log("response", response);
			},
			onError: (error) => {
				message.error(error.message);
			},
		}
	);

	useEffect(() => {
		if (activelogData) {
			setUpdates([]);

			const filteredData = activelogData
				.filter((res: any) => res.content && res.content.length > 0)
				.map((res: any) => {
					const extractedData = res.content.flatMap((item: any) => {
						if (typeof item === "object") {
							const keys = Object.keys(item);
							return keys.map((key) => ({
								key,
								data: item[key],
								type: res.type,
								date: res.createdAt,
								createdBy: res.createdBy.name,
							}));
						}
						return [];
					});
					return {
						content: extractedData,
					};
				});

			const extractDataArray = (extractedData: any) => {
				return extractedData.flatMap((data: any) => {
					return data.content.map((item: any) => {
						return {
							type: item.type,
							key: item.key,
							date: item.date,
							createdBy: item.createdBy,
							data: item.data,
						};
					});
				});
			};

			const dataArray = extractDataArray(filteredData);

			setUpdates(dataArray);
		}
	}, [activelogData]);

	useEffect(() => {
		ActiveLoggsRun(selectedItemDetails._id);
	}, [selectedItemDetails._id, selectedItemDetails, isEditModalOpen]);

	useEffect(() => {
		setTimeout(() => {
			ActiveLoggsRun(selectedItemDetails._id);
		}, 1000);
	}, [rightClickMenu, isEditModalOpen]);

	function extractValueAfterSlash(inputString: string) {
		// Find the index of the slash
		const slashIndex = inputString.indexOf("/");

		// Extract the substring after the slash
		if (slashIndex !== -1) {
			// Check if slash is found
			const valueAfterSlash = inputString.substring(slashIndex + 1);
			return valueAfterSlash;
		} else {
			return inputString; // Return null if slash is not found
		}
	}

	const formatRelativeTime = (date: any) => {
		let fromNowText = moment(date).fromNow();

		if (fromNowText.includes("seconds ago")) {
			fromNowText = fromNowText.replace("seconds ago", "sec ago");
		} else if (fromNowText.includes("minutes ago")) {
			fromNowText = fromNowText.replace("minutes ago", "min ago");
		} else if (fromNowText.includes("minute ago")) {
			fromNowText = fromNowText.replace("minute ago", "min ago");
		}
		return fromNowText;
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		}
	};

	const fetchMessages = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/discussions/${selectedItemDetails?._id}/${selectedItemDetails?.type}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();

				setMessages(data);
			} else {
				console.error("Failed to fetch messages");
			}
		} catch (error) {
			console.error("Error fetching messages:", error);
		}
	};

	useEffect(() => {
		fetchMessages();
	}, [selectedItemDetails, replyMode]);

	const account = process.env.REACT_APP_FILE_UPLOAD_STORAGE_ACCOUNT;
	const sasToken = process.env.REACT_APP_FILE_UPLOAD_STORAGE_SAS_TOKEN;
	const containerName = process.env
		.REACT_APP_FILE_UPLOAD_STORAGE_CONTAINER as string;

	if (!account || !sasToken || !containerName) {
		throw new Error("Missing Azure Blob Storage configuration.");
	}

	async function uploadFile(file: File) {
		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net/?${sasToken}`
		);
		const containerClient = blobServiceClient.getContainerClient(containerName);

		// Generate a unique blob name and upload the file
		const blobName = `${new Date().getTime()}-${file.name}`;
		const blobClient = containerClient.getBlockBlobClient(blobName);
		await blobClient.uploadData(file, {
			blobHTTPHeaders: { blobContentType: file.type },
		});

		// Get the URL of the uploaded file
		const fileUrl = `https://${account}.blob.core.windows.net/${containerName}/${blobName}`;

		return fileUrl;
	}

	const fetchUserProfile = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${currentWorkspace._id}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();

				const option = data.map((item: any) => {
					const formattedName = item?.userProfileId?.name?.replace(/\s+/g, "-");
					return {
						value: formattedName,
						label: formattedName,
						id: item.userProfileId?._id,
					};
				});
				// const value = event.target.value.toLowerCase();
				setMentionTeamUsers(option);

				const filteredOptions = option.filter(
					(option: any) => option.value !== undefined
				);

				const valuesArray = filteredOptions.map((option: any) => option.value);

				setOptions(valuesArray);
			} else {
				console.error("Error fetching tags data");
			}
		} catch (error) {
			console.error("Error fetching tags data:", error);
		}
	};
	useEffect(() => {
		fetchUserProfile();
	}, []);

	const schema = BlockNoteSchema.create({
		inlineContentSpecs: {
			// Adds all default inline content.
			...defaultInlineContentSpecs,
			// Adds the mention tag.
			mention: Mention,
		},
	});

	const getMentionMenuItems = (
		editor: typeof schema.BlockNoteEditor
	): DefaultReactSuggestionItem[] => {
		return options.map((user) => ({
			title: user,
			onItemClick: () => {
				editor.insertInlineContent([
					{
						type: "mention",
						props: {
							user,
						},
					},
					" ", // add a space after the mention
				]);
			},
		}));
	};

	const getMentionedUsers = (editorContent: any) => {
		const traverseContent = (content: any[]) => {
			content.forEach(
				(node: { type: string; props: { user: any }; content: any }) => {
					if (node.type === "mention" && node.props?.user) {
						mentionedUsers.push(node.props.user);
					} else if (node.content && Array.isArray(node.content)) {
						traverseContent(node.content);
					}
				}
			);
		};

		traverseContent(editorContent);
		return mentionedUsers;
	};

	const handleGetMentionedUsers = () => {
		const editorContent = editor.document;
		const users = getMentionedUsers(editorContent);
		setMentionedUsers(users);
	};

	const editor = useCreateBlockNote({ uploadFile, schema });

	const handleMessageSend = async () => {
		const editorContents = editor.document;
		handleGetMentionedUsers();

		mentionedUsers.forEach((name: any) => {
			const match = mentionTeamUsers.find((user) => user.value === name);
			if (match) {
				matchedUsers.push({
					name: match.value,
					_id: match.id,
					label: match.label,
				});
			}
		});

		HandleSubscriberlist();

		const html = await editor.blocksToHTMLLossy(editor.document);
		if (html !== null) {
			const newMessage = {
				id: messages.length,
				content: html,
				username: userProfileStoreInstance.profile.name,
				replies: [],
				type: "main",
			};
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/discussions`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							type: "main",
							mentions: matchedUsers,
							workspaceId: currentWorkspace._id,
							attachments: [],
							content: html,
							createdBy: userProfileStoreInstance.profile.name,
							threadId: replyMode.messageId,
							parentObjectId: [
								{ type: selectedItemDetails.type, id: selectedItemDetails._id },
							],

							reactions: [{ type: null, createdBy: null, createdAt: null }],
							resolved: false,
							resolvedBy: null,
							resolvedAt: null,
						}),
					}
				);
				if (response.ok) {
					const data = await response.json();
					newMessage.id = data._id;
					setMessages([...messages, newMessage]);
					fetchMessages();
					setMentionedUsers([]);
					setmatchedUsers([]);
				} else {
					console.error("Failed to post message");
				}
			} catch (error) {
				console.error("Error posting message:", error);
			}
			setInputValue("");
			editor.removeBlocks(editorContents);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleMessageSend();
			setReplyMode({ ...replyMode, active: false });
		}
	};

	const handleKeyDownReply = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleReplySend();
			setReplyModeReply({ ...replyModeReply, active: false });
			setReplyMode({ ...replyMode, active: false });
		}
	};

	const handleReplyClick = (messageId: number) => {
		setReplyMode({ active: true, messageId });
	};

	const handleCancelReply = () => {
		setReplyMode({ active: false, messageId: null });
		setReplyModeReply({ active: false, messageId: null });
	};

	const handleReplyChange = (value: string) => {
		setReplyContent(value);
	};

	const HandleSubscriberlist = async () => {
		try {
			let reqbody = {
				subscriberlist: [] as any,
			};
			const subscriberIds = subscriberlist?.map((subscriber: any) =>
				subscriber._id.toString()
			);
			const matchusersIds = matchedUsers.map((user: any) => user._id);
			reqbody.subscriberlist = [...subscriberIds, ...matchusersIds];
			setsubscriberlist([...subscriberlist, ...matchedUsers]);
			const requestOptions = {
				method: "PUT",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(reqbody),
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				requestOptions
			);

			if (response.ok) {
				const data = await response.json();
				console.log("Subscriberlist updated successfully");
			} else {
				console.error("Failed to update assignee");
			}
		} catch (error) {
			console.error("Error updating assignee:", error);
		}
	};

	const handleReplySend = async () => {
		const editorContents = editor.document;
		handleGetMentionedUsers();

		mentionedUsers.forEach((name: any) => {
			const match = mentionTeamUsers.find((user) => user.value === name);
			if (match) {
				matchedUsers.push({
					name: match.value,
					_id: match.id,
					label: match.label,
				});
			}
		});

		HandleSubscriberlist();

		const html = await editor.blocksToHTMLLossy(editor.document);
		if (html !== null) {
			const newReply = {
				id: messages.length,
				content: html,
				username: userProfileStoreInstance.profile.name,
				replies: [],
				type: "reply",
			};
			try {
				let parentMessageId = null;
				if (replyMode.active && replyMode.messageId !== null) {
					parentMessageId = replyMode.messageId;
				} else if (replyModeReply.active && replyModeReply.messageId !== null) {
					parentMessageId = replyModeReply.messageId;
				}

				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/discussions`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							type: "reply",
							mentions: matchedUsers,
							attachments: [],
							content: html,
							createdBy: userProfileStoreInstance.profile.name,
							parentMessageId: parentMessageId,
							parentObjectId: [
								{ type: selectedItemDetails.type, id: selectedItemDetails._id },
							],
							reactions: [{ type: null, createdBy: null, createdAt: null }],
							resolved: false,
							resolvedBy: null,
							resolvedAt: null,
						}),
					}
				);

				if (response.ok) {
					const data = await response.json();
					newReply.id = data._id;

					setReplyMode({ active: false, messageId: null });
					setReplyContent("");
					editor.removeBlocks(editorContents);
				} else {
					console.error("Failed to post reply");
				}
			} catch (error) {
				console.error("Error posting reply:", error);
			}
		}
	};

	const handleReaction = async (messageId: number, reactionType: string) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/discussions/${messageId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						reactions: [
							{
								type: reactionType,
								createdBy: userProfileStoreInstance.profile.name,
								createdAt: new Date().toISOString(),
							},
						],
					}),
				}
			);
			if (!response.ok) {
				console.error(`Failed to ${reactionType} message`);
			}
		} catch (error) {
			console.error(`Error ${reactionType} message:`, error);
		}
	};

	const handlevalueChange = (value: string) => {
		setInputValue(value);
		const Mentions = value.match(/@\w+/g) || [];
		const newMentions = Mentions.map((mention) => {
			const username = mention.substring(1);
			const option = options.find(
				(opt) => opt.value.split("-")[0] === username
			);
			if (option) {
				return option.id;
			}
			return null;
		});

		const filteredMentions = newMentions.filter((mention) => mention !== null);
		setMentions(filteredMentions);
	};

	const updatesWithType = updates.map((item) => ({
		...item,
		sourceType: "update",
	}));
	const messagesWithType = messages.map((item) => ({
		...item,
		sourceType: "message",
	}));

	const combinedDataSource = [...updatesWithType, ...messagesWithType];
	combinedDataSource.sort((a, b) => {
		const dateA = new Date(a.date || a.createdAt?.$date || a.createdAt);
		const dateB = new Date(b.date || b.createdAt?.$date || b.createdAt);
		return dateA.getTime() - dateB.getTime();
	});

	async function processMessagesAsync(msgData: any[]) {
		const ms = [...msgData];

		async function someAsyncOperation() {
			return new Promise((resolve) => setTimeout(resolve, 100));
		}
		const replies = ms.filter((msg) => msg.parentMessageId);

		for (const reply of replies) {
			const parentMessageId = reply.parentMessageId;
			if (!parentMessageId) continue;

			const parentMessageIndex = ms.findIndex(
				(msg) => msg._id === parentMessageId
			);

			if (parentMessageIndex !== -1) {
				await someAsyncOperation();
				const insertIndex = parentMessageIndex + 1;
				const replyIndex = ms.findIndex((msg) => msg._id === reply._id);
				if (replyIndex !== -1) {
					ms.splice(replyIndex, 1);
				}
				ms.splice(insertIndex, 0, reply);
			}
		}

		return ms;
	}

	useEffect(() => {
		processMessagesAsync(combinedDataSource)
			.then((result) => {
				setDell(result); // Set the processed result to dell
			})
			.catch((error) => console.error("Error processing messages:", error));
	}, [updates, selectedItemDetails, messages]);

	return (
		<>
			<List
				itemLayout="horizontal"
				key={"test"}
				dataSource={dell}
				renderItem={(item, index) => {
					if (item.sourceType === "update") {
						return (
							<List.Item key={index + 1} style={{ borderBottom: "none" }}>
								<div
									key={index + 1}
									style={{
										display: "flex",
										width: "100%",
										alignItems: "center",
									}}
								>
									<div style={{ width: "100%", textAlign: "left" }}>
										<Avatar
											style={{ marginLeft: "18px", backgroundColor: "#EAF0FC" }}
											icon={<UserOutlined style={{ color: "#3478F6" }} />}
										/>
										<span style={{ marginLeft: "5px", fontWeight: "bold" }}>
											{item.createdBy}
										</span>
										{extractValueAfterSlash(item.key) === "newlycreated" ? (
											<span>
												{" "}
												Created a new{" "}
												<span style={{ fontWeight: "bold" }}>{item.type}</span>
											</span>
										) : (
											<span>
												{" "}
												updated the field{" "}
												<span style={{ fontWeight: "bold" }}>
													{extractValueAfterSlash(item.key)}
												</span>
											</span>
										)}
										{" -"}
										{formatRelativeTime(item.date)}
									</div>
								</div>
							</List.Item>
						);
					} else if (item.sourceType === "message") {
						const createdBy = item.createdBy?.name || "Unknown User";
						const content = item.content;
						const formatHTML = (html: any) => {
							const parser = new DOMParser();
							const doc = parser.parseFromString(html, "text/html");
							return doc.body.innerHTML;
						};

						const formattedHtml = formatHTML(content);
						return (
							<div key={index + 1} style={{ marginBottom: "10px" }}>
								<Card>
									<Card.Meta
										title={
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<Space style={{ marginLeft: "1rem" }}>
													<Avatar
														style={{ backgroundColor: "#EAF0FC" }}
														icon={<UserOutlined style={{ color: "#3478F6" }} />}
													/>
													<p
														style={{
															color: "black",
															fontWeight: "bold",
															fontSize: "14px",
															margin: 0,
														}}
													>
														{createdBy}
													</p>
													<p
														style={{
															color: "black",
															fontWeight: "normal",
															fontSize: "14px",
															margin: 0,
														}}
													>
														{moment(item.createdAt).fromNow()}
													</p>
												</Space>
												<Space>
													<Button
														icon={<LikeOutlined />}
														onClick={() => handleReaction(item._id, "like")}
													/>
													<Button
														icon={<DislikeOutlined />}
														onClick={() => handleReaction(item._id, "dislike")}
													/>
													{!replyMode.active && (
														<Button
															icon={<MessageOutlined />}
															onClick={() => handleReplyClick(item._id)}
															style={{ marginLeft: "8px" }}
														/>
													)}
													{replyMode.active &&
														replyMode.messageId === item._id && (
															<Button
																icon={<CloseOutlined />}
																onClick={handleCancelReply}
																style={{ marginLeft: "8px" }}
															/>
														)}
												</Space>
											</div>
										}
										description={
											<div>
												<style>
													{`
												.content-container img {
												  max-width: 90%;
												  height: auto;
												}
												.content-container figure {
												  margin: 0;
												}
											  `}
												</style>
												<p
													style={{
														color: "black",
														fontWeight: "bold",
														fontSize: "14px",
														marginLeft: "3.5rem",
													}}
													className="content-container"
													dangerouslySetInnerHTML={{ __html: formattedHtml }}
												></p>
											</div>
										}
									/>
								</Card>
								{replyMode.active && replyMode.messageId === item._id && (
									<Flex justify="space-between" align="center">
										<Card style={{ width: "100%" }}>
											<BlockNoteView editor={editor} theme="light" />
											<div
												style={{
													display: "flex",
													justifyContent: "end",
													alignContent: "end",
												}}
											>
												<Button
													style={{ marginLeft: "1rem" }}
													icon={<SendOutlined />}
													onClick={() => {
														handleReplySend();
														setOptions([]);
													}}
												/>
											</div>
										</Card>
									</Flex>
								)}
								{item.parentMessageId === item._id &&
									item?.replies?.map(
										(reply: {
											id: React.Key | null | undefined;
											content: any;
											username: any;
											createdBy: { name: any };
										}) => (
											<div
												key={reply.id}
												style={{
													float: "left",
													clear: "both",
													textAlign: "left",
													width: "100%",
												}}
											>
												<Card>
													<Card.Meta
														title={
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "space-between",
																}}
															>
																<Space style={{ marginLeft: "1rem" }}>
																	<Avatar
																		style={{ backgroundColor: "#EAF0FC" }}
																		icon={
																			<UserOutlined
																				style={{ color: "#3478F6" }}
																			/>
																		}
																	/>
																	<p
																		style={{
																			color: "black",
																			fontWeight: "normal",
																			margin: 0,
																		}}
																	>
																		{reply.username || reply?.createdBy?.name}
																	</p>
																</Space>
																<Space>
																	<Button
																		icon={<LikeOutlined />}
																		onClick={() =>
																			handleReaction(item._id, "like")
																		}
																	/>
																	<Button
																		icon={<DislikeOutlined />}
																		onClick={() =>
																			handleReaction(item._id, "dislike")
																		}
																	/>
																	<p
																		style={{
																			color: "black",
																			fontWeight: "normal",
																			margin: 0,
																		}}
																	>
																		{moment(item.createdAt).fromNow()}
																	</p>
																</Space>
															</div>
														}
														description={
															<div>
																<style>
																	{`
																.content-container img {
																  max-width: 90%;
																  height: auto;
																}
																.content-container figure {
																  margin: 0;
																}
															  `}
																</style>
																<p
																	style={{
																		color: "black",
																		fontWeight: "bold",
																		fontSize: "14px",
																		marginLeft: "3.5rem",
																	}}
																	className="content-container"
																	dangerouslySetInnerHTML={{
																		__html: formattedHtml,
																	}}
																></p>
															</div>
														}
													/>
												</Card>
											</div>
										)
									)}
							</div>
						);
					}
				}}
			/>

			<Flex justify="space-between" align="center">
				<Card style={{ width: "100%" }}>
					<BlockNoteView editor={editor} theme="light">
						<SuggestionMenuController
							triggerCharacter={"@"}
							getItems={async (query) =>
								filterSuggestionItems(getMentionMenuItems(editor), query)
							}
						/>
					</BlockNoteView>

					<div>
						<Button
							style={{
								margin: "0.7rem 1rem 0 0rem",
								padding: "0.4rem 0.4rem 0.4rem 0.5rem",
								float: "right",
							}}
							icon={<SendOutlined />}
							type="primary"
							onClick={handleMessageSend}
						></Button>
					</div>
				</Card>
			</Flex>
		</>
	);
};

export default Activity;
