import { register } from "@antv/xflow";
import React, { useState } from "react";
import type { Node } from "@antv/xflow";

import "../index.css";

const DOWNTRINAGLENODE = "down-triangle-node";
let label;

const DownTriangle = ({ node }: { node: Node }) => {
	const data = node.getData();

	let normalString = "";
	if (data && typeof data === "object") {
		for (let i = 0; data[i] !== undefined; i++) {
			normalString += data[i];
		}
	}

	const [animal, setAnimal] = useState<string>(normalString || data);

	console.log(animal);
	label = animal;

	const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
		const newAnimalName = e.currentTarget.innerText.trim();
		setAnimal(newAnimalName);
		node.updateData(newAnimalName.toString());
	};

	return (
		<div className="downTriangle">
			<div
				style={{
					padding: "5px 5px 5px 5px",
					height: "100%",
					textAlign: "center",
					alignContent: "center",
					width: "100%",
					clipPath: "polygon(0% 0%, 100% 0%, 50% 100%)",
					direction: "ltr",
					unicodeBidi: "plaintext",
				}}
				contentEditable={true}
				suppressContentEditableWarning={true}
				onInput={handleInput}
			>
				{animal}
			</div>
		</div>
	);
};

console.log(label, "DOWNTRINAGLENODE");
const ports = {
	groups: {
		group1: {
			position: "top",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group2: {
			position: "right",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group3: {
			position: "bottom",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
		group4: {
			position: "left",
			attrs: {
				circle: {
					stroke: "#D06269",
					strokeWidth: 1,
					r: 4,
					magnet: true,
				},
			},
		},
	},
	items: [
		{ id: "group1", group: "group1" },
		{ id: "group2", group: "group2" },
		{ id: "group3", group: "group3" },
		{ id: "group4", group: "group4" },
	],
};

register({
	shape: DOWNTRINAGLENODE,
	ports: ports,
	component: DownTriangle,
	label: "DownTriangle",
	effect: ["data"],
});

export { DOWNTRINAGLENODE };
