/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
	Layout,
	Menu,
	Space,
	Button,
	Popover,
	Input,
	Flex,
	Breadcrumb,
	Tag,
	theme,
	message,
	Typography,
	Empty,
	Modal,
	Image,
	FloatButton,
	Drawer,
	Form,
	Row,
	Col,
	Select,
} from "antd";
import { Link, useParams } from "react-router-dom";
import {
	DownOutlined,
	MessageOutlined,
	CheckOutlined,
	SkinTwoTone,
	StarTwoTone,
	TeamOutlined,
	CloseOutlined,
	PicLeftOutlined,
	PlusOutlined,
	EditOutlined,
} from "@ant-design/icons";
import "./FeatureList.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
import { defineAbility } from "@casl/ability";
// import PermissionStore, {
// 	PermissionStoreType,
// } from "../stores/permissionStore";
// import AccountStore, { AccountStoreType } from "../stores/AccountStore";
const { Title } = Typography;
import usePlatformStore from "../stores/platformStore";

import { getNavigateUrlForWorkspaces } from "../util/NavigateAssistUtil";
import Constants from "../util/ConstantsUtil";
import useWorkspaceStore from "../stores/WorkspaceState";
import { useRequest } from "ahooks";
import { getSelectedWorkItems, getWorkItemsById } from "../api/WorkItemAPI";

const { Sider, Content } = Layout;

const FeatureListEdit: React.FC = () => {
	const {
		token: { colorBgContainer, colorPrimaryBg },
	} = theme.useToken();

	// const usePermissionStore = AccountStore() as AccountStoreType;
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const [editForm] = Form.useForm();

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const sectionRefs = useRef<any>({});
	const [uuid, setuuid] = useState<any>({ uuid: 0, type: "" });
	const [data, setData] = useState<any>([]);
	const [popoverVisible, setPopoverVisible] = useState<{
		[key: string]: boolean;
	}>({});
	const [popoverText, setPopoverText] = useState("");
	const [featureState, setFeatureState] = useState<any>([]);
	const { currentWorkspace, currentActivity, setCurrentActivity } =
		useWorkspaceStore((state: any) => ({
			currentWorkspace: state.currentWorkspace,
			currentActivity: state.currentActivity,
			setCurrentActivity: state.setCurrentActivity,
		}));
	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
		setUnauthorizedModel,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	//const { id } = useParams<{ id: string }>();
	const [allFeatureIds, setAllFeatureIds] = useState<any>([]);
	const [features, setFeatures] = useState<any>([]);
	const [abilities, setAbilities] = useState(ability);
	// useEffect(() => {
	// 	const initialAbilities = defineAbility((can) => {
	// 		const permissions = userProfileStoreInstance.permissions;
	// 		Object.entries(permissions).forEach(([subject, actions]) => {
	// 			can(actions, subject);
	// 		});
	// 	});
	// 	setAbilities(initialAbilities);
	// }, []);
	// console.log("ability", abilities);
	let PropertyValue: any;
	useEffect(() => {
		if (data.length > 0) {
			setAllFeatureIds(data);
		}
	}, [data]);
	useEffect(() => {
		console.log(allFeatureIds, "allFeatureIds");
	}, [allFeatureIds]);

	const [comments, setComments] = useState<any[]>([]);
	const [newCommentContent, setNewCommentContent] = useState<string>("");
	const [openCommentBox, setOpenCommentBox] = useState(false);
	const [contextMenuPosition, setContextMenuPosition] = useState<{
		x: number;
		y: number;
	} | null>(null);
	const [selectedPath, setSelectedPath] = useState<string | null>(null);
	const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);
	const [currentWorkItemId, setCurrentWorkItemId] = useState<string>("");
	const { isReviewEnabled } = useWorkspaceStore((state: any) => ({
		isReviewEnabled: state.isReviewEnabled,
	}));
	const [id, setId] = useState<string>(currentWorkspace._id);
	const [contentClassName, setContentClassName] = useState(
		"FeatureListLayoutContent"
	);
	const [isPopoverVisible, setPopoverVisibility] = useState(false);
	const [commentPosted, setCommentPosted] = useState(false);
	const { wfitemId } = useParams();
	const [updateItems, setUpdateItems] = useState([]);
	const [workFlowItem, setWorkFlowItem] = useState<any>([]);
	const [workitems, setWorkitems] = useState<any>([]);

	const { data: selectedWorkitemData, run: fetchWorkitemforSelected } =
		useRequest(
			(body) => getSelectedWorkItems(body, userProfileStoreInstance.idToken),
			{
				manual: true,
				onSuccess: (result) => {
					console.log(result, "result");
					message.success("Workitem fetched successfully");
				},
				onError: (error) => {
					console.log(error, "error");
					message.error("Failed to fetch workitem");
				},
			}
		);

	useEffect(() => {
		if (selectedWorkitemData) {
			setWorkitems(selectedWorkitemData);
			setData(selectedWorkitemData);
			FeatureListEdit;

			console.log(featureState, "featureState");

			if (featureState.length === 0) {
				console.log(
					selectedWorkitemData[0]?.title,
					"selectedWorkitemData[0].title"
				);
				setFeatureState(selectedWorkitemData[0]?.title);
				setCurrentWorkItemId(selectedWorkitemData[0]?._id);
				editForm.setFieldsValue({
					title: selectedWorkitemData[0]?.title,
					description: selectedWorkitemData[0]?.description,
					complexity_score: selectedWorkitemData[0]?.data?.complexity_score,
					complexity_score_reason:
						selectedWorkitemData[0]?.data?.complexity_score_reason,
					role_ids: selectedWorkitemData[0]?.data?.role_ids,
					benefits: selectedWorkitemData[0]?.data?.benefits,
				});
			}
		}
	}, [selectedWorkitemData]);

	const fetchWorkitem = async () => {
		console.log(workFlowItem, "workFlowItem");
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/selected`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const result = await response.json();
			console.log(result, "PLEASE");
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const { data: workitemByIdData, run: getWorkItemById } = useRequest(
		(workItemId) =>
			getWorkItemsById(workItemId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.info(
					`${result.title.slice(0, 1).toUpperCase() + result.title.slice(1)} Link`
				);
				console.log(result, "feature data");
			},
			onError: (error) => {
				message.error(`Failed to fetch workitem feature : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemByIdData) {
			setUpdateItems(workitemByIdData?.data?.items);
			setWorkFlowItem(workitemByIdData);
			fetchWorkitemforSelected({ items: workitemByIdData.data.items });
			fetchWorkitem();
		}
	}, [workitemByIdData]);

	useEffect(() => {
		getWorkItemById(wfitemId);
	}, [wfitemId]);

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${currentWorkspace._id}/feature-list`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const result = await response.json();
			if (!response.ok) {
				const result = await response.json();
				throw new Error(
					`status: ${response.status} message: ${result.message}`
				);
			}

			if (response.status === 401) {
				setUnauthorizedModel(true);
				message.error(`${result.message}`);
			} else {
				//console.log(dummyData, "dummyData");
				//setData(result);
				setCurrentActivity(result[0]);
				setUnauthorizedModel(false);
				// Process the data and set the default feature state
				if (result?.[0]?.appData?.features) {
					const featureIds = Object.values(result[0].appData.features).flatMap(
						(features: any) =>
							features.map((feature: any) => feature.featureType_id)
					);
					// if(!featureState){
					// 	setFeatureState(featureIds);
					// }
					if (featureState.length === 0) {
						console.log("featureIds[0]", featureIds);
						setFeatureState(featureIds[0]);
						const featureIds2 = Object.values(
							result[0]?.appData?.features
						)?.flatMap((features: any) =>
							features.map((feature: any) => feature)
						);
						// console.log("featureIds", featureIds2[0]);
						// setFeatureState(featureIds2[0].name);
						// console.log("featureIds2", featureIds2[0].id,"type","admin_feature");
						setuuid({ uuid: featureIds2[0].id, type: "admin_features" });
						// editFeature(featureIds2[0]);
						editForm.setFieldsValue({
							name: featureIds2[0].featureType_id,
							description: featureIds2[0].description,
							complexity_score: featureIds2[0].complexity_score,
							complexity_score_reason: featureIds2[0].complexity_score_reason,
							role_ids: featureIds2[0].role_ids,
							benefits: featureIds2[0].benefits,
						});
					}
				}

				setCommentPosted(false);
			}
		} catch (error: any) {
			message.error(`Error Feature List : ${error}`);
			// console.error("Error fetching data:", error);
		}
	};

	// console.log("uuid",uuid);

	// console.log("featurestate", featureState);
	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			//fetchData();
		}
	}, [id, userProfileStoreInstance.idToken]);

	useEffect(() => {
		const fetchPermission = async () => {
			await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/userid-workspaceid?work-space-id=${currentWorkspace._id}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			)
				.then((res) => res.json())
				.then((data: any) => {
					const permissionData: Record<string, string> = {};
					if (data.length > 0 && data[0].permission) {
						data[0].permission.forEach((permission: any) => {
							const subject = permission.field.toUpperCase();
							const actions = permission.permissions.map(
								(action: any) => action
							);

							// For each action, create a new key-value pair
							actions.forEach((action: string) => {
								const key = `${subject}_${action}`;
								permissionData[key] = action;
							});
						});
					}
					// Set the permissions in the Zustand store
					userProfileStoreInstance.setPermissions(permissionData);
				})
				.catch((err) => console.error(err));
		};
		fetchPermission();
	}, []);

	// console.log("exect structure", data?.[0]?.appData?.features);
	// useEffect(() => {
	// 	const keys= Object.keys(data?.[0]?.appData?.features);
	// 	console.log("keys",keys);
	// 	// if (data && data[0] && data[0].appData && data[0].appData.features) {
	// 	//   const featureNames = Object.keys(data[0].appData.features)
	// 	// 	.filter(feature => feature.endsWith('_features'));

	// 	//   console.log("Feature names:", featureNames);
	// 	// }
	//   }, []);

	useEffect(() => {
		const navigationUrls = getNavigateUrlForWorkspaces(
			`${id}`,
			currentWorkspace?.modules || [],
			Constants.FEATURE_ENGINEERING,
			Constants.FE_FEATURE_LIST
		);
		setNextNavigateUrl(navigationUrls.nextUrl);
		setBackNavigateUrl(navigationUrls.prevUrl);
		setAppBarTitle(Constants.FE_FEATURE_LIST);
		setCurrentModule(Constants.FEATURE_ENGINEERING);
		setCurrentPage(Constants.FE_FEATURE_LIST);
	}, [currentWorkspace]);

	// console.log(allFeatureIds);

	const scrollToSection = (title: string) => {
		const sectionRef = sectionRefs.current[title];
		if (sectionRef) {
			sectionRef.scrollIntoView({ behavior: "smooth" });
		}
	};

	let firstPropertyValue: any;
	useEffect(() => {
		if (data.length > 0) {
			setFeatures(data);
		}
	}, [data]);

	// console.log("testing", features);

	const formatText = (text: any) => {
		const words = text?.split("_");
		const formattedWords = words?.map(
			(word: any) => word.charAt(0).toUpperCase() + word.slice(1)
		);
		const formattedText = formattedWords?.join(" ");
		return formattedText;
	};

	const handlePopoverVisibleChange = (featureId: string, visible: boolean) => {
		setPopoverVisible((prev) => ({ ...prev, [featureId]: visible }));
	};

	const handlePopoverTextChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setPopoverText(e.target.value);
	};

	const handleSendMessage = (featureId: string) => {
		// console.log(`Message Sent for ${featureId}:`, popoverText);
		setPopoverVisible((prev) => ({ ...prev, [featureId]: false }));
	};

	useEffect(() => {
		setContentClassName(
			isReviewEnabled
				? "FeatureListLayoutContentHover"
				: "FeatureListLayoutContent"
		);
	}, [isReviewEnabled]);

	const handleRightClick = (e: React.MouseEvent) => {
		e.preventDefault();

		if (isReviewEnabled) {
			setPopoverVisibility(true);
			// setContentClassName(isCommentButtonClicked ? "FeatureSpecificationLayoutContentHover" : "FeatureSpecificationLayoutContent");
			const clickedElement = e.target as HTMLElement;
			const clickedPath = clickedElement.dataset.path || "";
			// console.log(clickedPath); // Use an empty string as a fallback
			setContextMenuPosition({ x: e.clientX, y: e.clientY });
			// console.log(clickedPath, "clickedpaths");
			setSelectedPath(clickedPath);
		} else {
			setContentClassName("FeatureSpecificationLayoutContent");
		}
	};

	const postComment = () => {
		const newComment = {
			comment: newCommentContent,
			path: selectedPath,
		};

		const newData = [...data];
		const userReview = newData[0]?.userReview || [];
		userReview.push(newComment);
		newData[0].userReview = userReview;
		setCommentPosted(true);

		setPopoverVisibility(false);

		setNewCommentContent("");
		setContextMenuPosition(null);

		fetch(`${process.env.REACT_APP_API_URL}/describe/${id}/feature-list`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(newData[0]),
		})
			.then((response) => response.json())
			.then((res) => {
				// console.log("dev", res);
			})
			.catch((error) => {
				console.log(error);
			});

		setCommentPosted(true);
	};

	const handlePostRequests = async () => {
		try {
			// console.log("isModalOkEnabled is true, triggering post request");
			// console.log(data);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${id}/feature-specification`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(data[0]),
				}
			);

			if (response.ok) {
				console.log("Post request successful.");
			} else {
				console.error("Post request failed.");
			}
		} catch (error) {
			console.error("Error performing post request:", error);
		}
	};

	const [form] = Form.useForm();
	const onFinish = async (values: any) => {
		// console.log("Received values:", values);
		// console.log("data[0]", data[0]);

		const newValues = {
			benefits: [values.benefits],
			complexity_score: values.complexity_score,
			complexity_score_reason: values.complexity_score_reason,
			description: values.description,
			name: values.name,
			role_ids: [values.role_ids],
			dependent_feature_ids: [],
		};

		if (!data[0]?.appData?.features?.user_features) {
			// If not, create user_features
			data[0].appData.features.user_features = [{ ...newValues }]; // You may need to initialize it appropriately
		} else if (Array.isArray(data[0].appData.features.user_features)) {
			// If user_features is already an array, push a new object
			data[0].appData.features.user_features.push({ ...newValues });
		}
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${id}/feature-list`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(data[0]),
				}
			);

			// console.log("response", response);
			if (response.ok) {
				const responseBody = await response.text();
				const resp = responseBody ? JSON.parse(responseBody) : null;

				// console.log("Put request successful.");

				// Update the data with the new response
				fetchData();
				form.resetFields();
				onClose();
				// console.log("Put request successful.");
			} else {
				console.error("Put request failed.");
			}
		} catch (error) {
			console.error("Error performing post request:", error);
		}
	};

	const showEditDrawer = () => {
		setEditOpen(true);
	};

	const onCloseEditDrawer = () => {
		setEditOpen(false);
	};

	// console.log(uuid);

	const editFeature = async (featuredetail: any) => {
		console.log(features, "features");
		const selectedFeature = await features.flat().find((feature: any) => {
			return feature.title == featuredetail.title;
		});

		if (selectedFeature) {
			// console.log("Selected Feature:", selectedFeature);
			setuuid({ uuid: selectedFeature._id, type: selectedFeature.type });
			setCurrentWorkItemId(selectedFeature._id);
			editForm.setFieldsValue({
				title: selectedFeature.title,
				description: selectedFeature.description,
				complexity_score: selectedFeature.data.complexity_score,
				complexity_score_reason: selectedFeature.data.complexity_score_reason,
				role_ids: selectedFeature.data.role_ids,
				benefits: selectedFeature.data.benefits,
			});
		} else {
			console.error("Feature not found!");
		}
	};

	interface FeatureChanges {
		[key: string]: {
			oldValue: any;
			newValue: any;
		};
	}

	const compareObjects = async (
		obj1: any,
		obj2: any
	): Promise<FeatureChanges[]> => {
		const changes: FeatureChanges[] = [];

		// Helper function to convert comma-separated string to array
		const stringToArray = (value: any): any => {
			return Array.isArray(value) ? value : value ? value.split(",") : [];
		};

		// Iterate over the keys of obj1
		for (const key in obj1) {
			// Check if the key exists in obj2 and values are different
			if (Object.prototype.hasOwnProperty.call(obj2, key)) {
				let oldValue = obj1[key];
				let newValue = obj2[key];

				// Convert values to arrays if the key is 'role_ids' or 'benefits'
				if (key === "role_ids" || key === "benefits") {
					oldValue = stringToArray(oldValue);
					newValue = stringToArray(newValue);
				}

				// Check if the values are different
				if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
					changes.push({
						[key]: {
							oldValue,
							newValue,
						},
					});
				}
			}
		}

		const Reqbody: any = {
			workspace: `${id}`,
			module: "FeatureEngineering",
			versionsequence: 1,
			section: "Featurelist",
			reviseReview: "",
			path: "",
			newValue: [],
			mode: "manual",
			oldValue: [],
			createdBy: "",
			appBranch: "00bb00da00d000000000d000",
		};

		if (changes.length === 1) {
			Reqbody.path = `featurelist/${obj1.id}/${Object.keys(changes[0])[0]}`;
			const firstKey = Object.keys(changes[0])[0];
			Reqbody.oldValue.push({ [firstKey]: changes[0][firstKey].oldValue });
			Reqbody.newValue.push({ [firstKey]: changes[0][firstKey].newValue });
		} else if (changes.length >= 2) {
			Reqbody.path = `featurelist/${obj1.id}`;
			for (const change of changes) {
				const firstKey = Object.keys(change)[0];
				Reqbody.oldValue.push({ [firstKey]: change[firstKey].oldValue });
				Reqbody.newValue.push({ [firstKey]: change[firstKey].newValue });
			}
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/al_steps_changes`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(Reqbody),
				}
			);

			if (response.ok) {
				// Feature updated successfully.
				// Fetch data again to refresh the UI
				fetchData();
				onCloseEditDrawer();
			} else {
				console.error("Failed to update feature.");
			}
		} catch (error) {
			console.error("Error updating feature:", error);
		}

		return changes;
	};

	const onFinishEdit = async (values: any) => {
		// const originalFeatures = data[0]?.appData?.features?.[`${uuid.type}`] || [];
		// const updatedFeatures = originalFeatures.map((feature: any) => {
		// 	if (feature.id === uuid.uuid) {
		// 		const changes = compareObjects(feature, values);
		// 		return {
		// 			...feature,
		// 			name: values.name,
		// 			description: values.description,
		// 			complexity_score: values.complexity_score,
		// 			complexity_score_reason: values.complexity_score_reason,
		// 			role_ids: Array.isArray(values.role_ids)
		// 				? values.role_ids
		// 				: values.role_ids.split(","),
		// 			benefits: Array.isArray(values.benefits)
		// 				? values.benefits
		// 				: values.benefits.split(","),
		// 		};
		// 	}

		// 	return feature;
		// });

		// const updatedData = {
		// 	...data[0],
		// 	appData: {
		// 		...data[0]?.appData,
		// 		features: {
		// 			...data[0]?.appData?.features,
		// 			[`${uuid.type}`]: updatedFeatures,
		// 		},
		// 	},
		// };

		const updatedData = {
			title: values.title,
			description: values.description,
			data: {
				complexity_score: values.complexity_score,
				complexity_score_reason: values.complexity_score_reason,
				role_ids: Array.isArray(values.role_ids)
					? values.role_ids
					: values.role_ids.split(","),
				benefits: Array.isArray(values.benefits)
					? values.benefits
					: values.benefits.split(","),
			},
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${currentWorkItemId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(updatedData),
				}
			);

			if (response.ok) {
				// Feature updated successfully.
				// Fetch data again to refresh the UI
				fetchData();
				onCloseEditDrawer();
			} else {
				console.error("Failed to update feature.");
			}
		} catch (error) {
			console.error("Error updating feature:", error);
		}
	};

	return (
		<>
			<AbilityContext.Provider value={abilities}>
				<Space direction="vertical" style={{ width: "100%" }}>
					<Can
						I="FEATURE_CAN_VIEW"
						a="FEATURE_ENGINEERING_CAN_VIEW"
						passThrough
					>
						{(allowed) =>
							allowed ? (
								<Layout
									style={{ backgroundColor: "transparent", width: "90vw" }}
								>
									<Layout
										style={{ display: "flex", backgroundColor: colorPrimaryBg }}
									>
										<Sider
											width={280}
											style={{ backgroundColor: colorPrimaryBg }}
										>
											<div className="MenuItem-wrap">
												<Menu
													mode="inline"
													defaultSelectedKeys={["0"]}
													className="leftmenu"
												>
													{allFeatureIds
														//.flat()
														.map((featureId: any, index: any) => (
															<>
																<Menu.Item
																	data-testid={`${featureId}-1`}
																	key={index}
																	onClick={() => {
																		setFeatureState(featureId.title);
																		console.log(featureId, "featureId");
																		editFeature(featureId);
																		setCurrentWorkItemId(featureId._id);
																	}}
																	className="leftmenu-item"
																	// style={{backgroundColor: "#e7e9ef"}}
																>
																	{formatText(featureId.title)}
																</Menu.Item>
															</>
														))}
												</Menu>
											</div>
										</Sider>
										<Content
											style={{
												backgroundColor: "transparent",
												textAlign: "left",
												borderRadius: 20,
											}}
										>
											{features && features.length ? (
												features
													//.flat()
													.filter((feature: any, index: number) => {
														// console.log("feature",feature.name.name);
														// console.log("features", features);
														// console.log("feature",feature);
														return feature.title === featureState;
													})
													.map((feature: any, index: number) => (
														// console.log("featuredetail",feature.name.name)
														<div
															key={index}
															className="FeatureList-Box"
															ref={(ref) =>
																(sectionRefs.current[feature.id] = ref)
															}
														>
															<Layout
																className="content"
																style={{ background: colorBgContainer }}
															>
																<Form
																	layout="vertical"
																	hideRequiredMark
																	form={editForm}
																	onFinish={onFinishEdit}
																	labelAlign="left"
																	style={{ maxWidth: 600 }}
																>
																	<Form.Item
																		style={{ width: "50vw" }}
																		name="title"
																		label="Feature Name"
																		rules={[
																			{
																				required: true,
																				message: "Please enter Feature name",
																			},
																		]}
																	>
																		<Input
																			placeholder="Type here..."
																			style={{
																				borderRadius: "4px",
																				marginLeft: "70px",
																			}}
																		/>
																	</Form.Item>

																	<Form.Item
																		style={{ width: "50vw" }}
																		name="description"
																		label="Description"
																		rules={[
																			{
																				required: true,
																				message:
																					"Please enter Feature description",
																			},
																		]}
																	>
																		<Input.TextArea
																			placeholder="Type here..."
																			style={{
																				borderRadius: "4px",
																				marginLeft: "70px",
																			}}
																		/>
																	</Form.Item>
																	<Flex
																		gap={20}
																		style={{ marginTop: 25, width: "70vw" }}
																		vertical
																	>
																		<Flex gap={20} style={{ width: "70vw" }}>
																			<div className="Complexity-icon">
																				<Button>
																					<SkinTwoTone />
																				</Button>
																			</div>
																			<Flex
																				onContextMenu={handleRightClick}
																				className={contentClassName}
																				data-path={`fe/featurelist/${featureState}`}
																				vertical
																				justify="center"
																				gap={5}
																				style={{ width: "50vw" }}
																			>
																				<Flex
																					gap={10}
																					style={{ width: "50vw" }}
																				>
																					<Title
																						onContextMenu={handleRightClick}
																						className={contentClassName}
																						data-path={`fe/featurelist/${featureState}`}
																						level={5}
																						style={{
																							margin: 0,
																							fontWeight: 500,
																							fontSize: 15,
																							width: "200px",
																						}}
																					>
																						Complexity
																					</Title>
																					<Form.Item
																						name="complexity_score"
																						label=""
																						rules={[
																							{
																								required: true,
																								message:
																									"Please select complexity_score",
																							},
																						]}
																					>
																						<Select
																							placeholder="Select level"
																							style={{
																								borderRadius: "4px",
																								width: "40%",
																								height: "40px",
																							}}
																						>
																							<Select.Option value="S">
																								S
																							</Select.Option>
																							<Select.Option value="M">
																								M
																							</Select.Option>
																							<Select.Option value="L">
																								L
																							</Select.Option>
																						</Select>
																					</Form.Item>
																				</Flex>
																				<Flex
																					gap={10}
																					style={{ width: "50vw" }}
																				>
																					<Form.Item
																						name="complexity_score_reason"
																						label=""
																						rules={[
																							{
																								required: true,
																								message:
																									"Please enter complexity_score_reason",
																							},
																						]}
																					>
																						<Input.TextArea
																							placeholder="Type here..."
																							style={{ borderRadius: "4px" }}
																						/>
																					</Form.Item>
																				</Flex>
																			</Flex>
																		</Flex>

																		<Flex gap={20}>
																			<div className="Complexity-icon">
																				<Button>
																					<TeamOutlined />
																				</Button>
																			</div>
																			<Flex
																				onContextMenu={handleRightClick}
																				className={contentClassName}
																				data-path={`fe/featurelist/${featureState}`}
																				vertical
																				justify="center"
																				gap={5}
																			>
																				<Title
																					onContextMenu={handleRightClick}
																					className={contentClassName}
																					data-path={`fe/featurelist/${featureState}`}
																					level={5}
																					style={{
																						margin: 0,
																						fontWeight: 500,
																						fontSize: 15,
																					}}
																				>
																					Roles
																				</Title>

																				<Form.Item
																					style={{ width: "50vw" }}
																					name="role_ids"
																					label=""
																					rules={[
																						{
																							required: true,
																							message: "Please enter role_ids",
																						},
																					]}
																				>
																					<Input
																						placeholder="Type here..."
																						style={{ borderRadius: "4px" }}
																					/>
																				</Form.Item>
																			</Flex>
																		</Flex>

																		<Flex gap={20}>
																			<div className="Complexity-icon">
																				<Button>
																					<StarTwoTone />
																				</Button>
																			</div>
																			<Flex
																				onContextMenu={handleRightClick}
																				className={contentClassName}
																				data-path={`fe/featurelist/${featureState}`}
																				vertical
																				justify="center"
																				gap={5}
																			>
																				<Title
																					onContextMenu={handleRightClick}
																					className={contentClassName}
																					data-path={`fe/featurelist/${featureState}`}
																					level={5}
																					style={{
																						margin: 0,
																						fontWeight: 500,
																						fontSize: 15,
																					}}
																				>
																					Benefits
																				</Title>
																				<Form.Item
																					style={{ width: "50vw" }}
																					name="benefits"
																					label=""
																					rules={[
																						{
																							required: true,
																							message: "Please enter benefits",
																						},
																					]}
																				>
																					<Input.TextArea
																						placeholder="Type here..."
																						style={{ borderRadius: "4px" }}
																					/>
																				</Form.Item>
																			</Flex>
																		</Flex>
																	</Flex>
																	<Flex
																		justify="flex-end"
																		style={{ width: "50vw" }}
																	>
																		<Button type="primary" htmlType="submit">
																			Submit
																		</Button>
																	</Flex>
																</Form>
															</Layout>
														</div>
													))
											) : (
												<></>
											)}
										</Content>
									</Layout>
								</Layout>
							) : (
								<Flex style={{ marginTop: "8vh" }} justify="center">
									<Image
										height={"50vh"}
										width={"60vw"}
										src={
											"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
										}
										preview={false}
									/>
								</Flex>
							)
						}
					</Can>
					{contextMenuPosition && features && (
						<Popover
							title={
								<Flex justify="space-between">
									<Typography.Title level={5}> Add a Comment </Typography.Title>
									<Button
										icon={<CloseOutlined />}
										size="small"
										shape="circle"
										onClick={() => setContextMenuPosition(null)}
									></Button>
								</Flex>
							}
							content={
								<div>
									<Input.TextArea
										style={{ height: "15vh", marginBottom: "1vh" }}
										placeholder="Type your comment here..."
										onChange={(e) => setNewCommentContent(e.target.value)}
										value={newCommentContent}
									/>
									<Button type="primary" onClick={() => postComment()}>
										Post Comment
									</Button>
								</div>
							}
							trigger="click"
							//open={openCommentBox}
							open={isPopoverVisible}
							onOpenChange={(visible) =>
								visible || setContextMenuPosition(null)
							}
						>
							<div
								className="NewCommentSectio"
								style={{
									position: "fixed",
									top: contextMenuPosition.y + "px",
									left: contextMenuPosition.x + "px",
								}}
							>
								<Button type="link"></Button>
							</div>
						</Popover>
					)}
				</Space>

				{/* <FloatButton
					shape="circle"
					type="default"
					style={{ right: 154 }}
					icon={<PlusOutlined />}
					onClick={showDrawer}
				/> */}

				<Drawer
					title="Create a new Feature"
					width={720}
					onClose={onClose}
					open={open}
					footer={
						<Space style={{ float: "right" }}>
							<Button onClick={onClose}>Cancel</Button>
							<Button type="primary" onClick={() => form.submit()}>
								Submit
							</Button>
						</Space>
					}
				>
					<Form
						labelCol={{ span: 10 }}
						wrapperCol={{ span: 25 }}
						layout="horizontal"
						hideRequiredMark
						form={form}
						onFinish={onFinish}
						labelAlign="left"
						style={{ maxWidth: 600 }}
					>
						<Form.Item
							name="name"
							label="Feature Name"
							rules={[{ required: true, message: "Please enter Feature name" }]}
						>
							<Input
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="description"
							label="Description"
							rules={[
								{
									required: true,
									message: "Please enter Feature description",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="complexity_score"
							label="Complexity Score"
							rules={[
								{ required: true, message: "Please select complexity_score" },
							]}
						>
							<Select
								placeholder="Select level"
								style={{ borderRadius: "4px", width: "40%", height: "40px" }}
							>
								<Select.Option value="S">S</Select.Option>
								<Select.Option value="M">M</Select.Option>
								<Select.Option value="L">L</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="complexity_score_reason"
							label="Complexity Score Reason"
							rules={[
								{
									required: true,
									message: "Please enter complexity_score_reason",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="role_ids"
							label="Role IDs"
							rules={[{ required: true, message: "Please enter role_ids" }]}
						>
							<Input
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="benefits"
							label="Benefits"
							rules={[{ required: true, message: "Please enter benefits" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
					</Form>
				</Drawer>

				{/* <FloatButton
					shape="circle"
					type="primary"
					style={{ right: 88 }}
					icon={<EditOutlined />}
					onClick={showEditDrawer}
				/> */}

				<Drawer
					title="Edit Features"
					width={720}
					onClose={onCloseEditDrawer}
					open={editOpen}
					footer={
						<Space style={{ float: "right" }}>
							<Button onClick={onCloseEditDrawer}>Cancel</Button>
							<Button type="primary" onClick={() => editForm.submit()}>
								Submit
							</Button>
						</Space>
					}
				>
					<Form
						labelCol={{ span: 10 }}
						wrapperCol={{ span: 25 }}
						layout="horizontal"
						hideRequiredMark
						form={editForm}
						onFinish={onFinishEdit}
						labelAlign="left"
						style={{ maxWidth: 600 }}
					>
						<Form.Item
							name="name"
							label="Feature Name"
							rules={[{ required: true, message: "Please enter Feature name" }]}
						>
							<Input
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="description"
							label="Description"
							rules={[
								{
									required: true,
									message: "Please enter Feature description",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="complexity_score"
							label="Complexity Score"
							rules={[
								{ required: true, message: "Please select complexity_score" },
							]}
						>
							<Select
								placeholder="Select level"
								style={{ borderRadius: "4px", width: "40%", height: "40px" }}
							>
								<Select.Option value="S">S</Select.Option>
								<Select.Option value="M">M</Select.Option>
								<Select.Option value="L">L</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							name="complexity_score_reason"
							label="Complexity Score Reason"
							rules={[
								{
									required: true,
									message: "Please enter complexity_score_reason",
								},
							]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="role_ids"
							label="Role IDs"
							rules={[{ required: true, message: "Please enter role_ids" }]}
						>
							<Input
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>

						<Form.Item
							name="benefits"
							label="Benefits"
							rules={[{ required: true, message: "Please enter benefits" }]}
						>
							<Input.TextArea
								placeholder="Type here..."
								style={{ borderRadius: "4px" }}
							/>
						</Form.Item>
					</Form>
				</Drawer>
			</AbilityContext.Provider>
		</>
	);
};

export default FeatureListEdit;
