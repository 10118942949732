import React, { useEffect, useState } from "react";
import {
	Modal,
	Input,
	Button,
	Form,
	Typography,
	//Select,
	Flex,
	Card,
	Radio,
} from "antd";
import "./EditWorkspace.css";

import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";

import useWorkspaceStore from "../stores/WorkspaceState";
//startsWithimport Constants from "../util/ConstantsUtil";
import { useNavigate, useParams } from "react-router-dom";
import operation_done from "../assets/operation_done.svg";
import operation_failed from "../assets/operation_failed.svg";
import business from "../assets/business.png";

const CreateWorkpaceForm: React.FC<any> = () => {
	const navigate = useNavigate();
	const { threadId } = useParams();
	const [chatThreadDetails, setChatThreadDetails] = useState<any>({});
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [form] = Form.useForm();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };

	const buttonItemLayout = { wrapperCol: { span: 2, offset: 19 } };

	// const fetchWorkspaceData = async () => {
	// 	try {
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/workspace`,
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 			}
	// 		);
	// 		const existingWorkspace = await response.json();

	// 		form.setFieldsValue({
	// 			workspaceName: existingWorkspace.name,
	// 			checkbox: existingWorkspace.modules,
	// 		});
	// 	} catch (error) {
	// 		console.error("Error fetching workspace data:", error);
	// 	}
	// };

	const fetchChatThreadDetails = async (threadId: string) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/ai-conversation-thread/${threadId}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const chatThread = await response.json();
			console.log(chatThread.goalResult);
			setChatThreadDetails(chatThread);
		} catch (error) {
			console.error("Error fetching chat thread data:", error);
		}
	};

	useEffect(() => {
		if (threadId) fetchChatThreadDetails(threadId);
	}, [threadId]);

	const { setAddWorkspaceDetails, addWorkspaceDetails, setCurrentWorkspace } =
		useWorkspaceStore((state: any) => ({
			addWorkspaceDetails: state.addWorkspaceDetails,
			setAddWorkspaceDetails: state.setAddWorkspaceDetails,
			currentWorkspace: state.currentWorkspace,
			setCurrentWorkspace: state.setCurrentWorkspace,
		}));

	const prepareWorkspace = (values: any) => {
		const modules: any[] = [];
		const currentModule = "test";
		const currentSection = "tests";
		// if (addWorkspaceDetails.isBusinessEngineering) {
		// 	modules.push(Constants.BUSINESS_ENGINEERING);
		// 	currentModule = Constants.BUSINESS_ENGINEERING;
		// 	currentSection = "";
		// }
		// if (addWorkspaceDetails.isFeatureEngineering) {
		// 	modules.push(Constants.FEATURE_ENGINEERING);
		// 	currentModule = Constants.FEATURE_ENGINEERING;
		// 	currentSection = Constants.FE_FEATURE_LIST;
		// }
		// if (addWorkspaceDetails.isDesignEngineering) {
		// 	modules.push(Constants.DESIGN_ENGINEERING);
		// 	currentModule = Constants.DESIGN_ENGINEERING;
		// 	currentSection = Constants.DE_USER_PERSONA;
		// }
		// if (addWorkspaceDetails.isCodeEngineering) {
		// 	modules.push(Constants.CODE_ENGINEERING);
		// 	currentModule = Constants.CODE_ENGINEERING;
		// 	currentSection = "";
		// }
		return {
			//_id: currentWorkspace._id,
			name: values.workspaceName,
			Description: values.Description,
			Agile: values.Agile,
			modules: modules,
			productInfo: chatThreadDetails.goalResult,
			currentModule: currentModule,
			currentSection: currentSection,
			status: "ACTIVE",
		};
	};

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};

	const [modal, contextHolder] = Modal.useModal();

	const countDown = (message: any, status: any) => {
		let secondsToGo = 5;
		let instance: any;

		if (status === "fail") {
			instance = modal.error({
				title: "Error!",
				content: (
					<div>
						<Typography.Text>{message}</Typography.Text>
						<img
							src={operation_failed}
							alt="Error Image"
							style={{ maxWidth: "100%" }}
						/>
					</div>
				),
			});
		} else {
			instance = modal.success({
				title: "Success!",
				content: (
					<div>
						<Typography.Text>{message}</Typography.Text>
						<img
							src={operation_done}
							alt="Success Image"
							style={{ maxWidth: "100%" }}
						/>
					</div>
				),
			});
		}

		const timer = setInterval(() => {
			secondsToGo -= 1;
			//   instance.update({
			// 	content: (
			// 	  <div>
			// 		<p>{message}</p>
			// 		<img src={imageUrl} alt="Image" style={{ maxWidth: '100%' }} />
			// 	  </div>
			// 	),
			//   });
		}, 1000);

		setTimeout(() => {
			clearInterval(timer);
			instance.destroy();
		}, secondsToGo * 1000);
	};

	const [confirmLoading, setConfirmLoading] = useState(false);

	const onFinish = (values: any) => {
		setConfirmLoading(true);
		setAddWorkspaceDetails({
			...addWorkspaceDetails,
			_id: currentWorkspace._id,
			workspaceName: values.workspaceName,
		});

		const workspace = prepareWorkspace(values);
		console.log(workspace);
		fetch(
			`${process.env.REACT_APP_API_URL}/workspace/${currentWorkspace._id}`,
			{
				method: "PUT",
				body: JSON.stringify(workspace),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		)
			.then((response) => response.json())
			.then((json) => {
				if (json) {
					setCurrentWorkspace(json);
					countDown(
						`Workspace ${values.workspaceName} created successfully. Redirecting to home page`,
						"pass"
					);
					setTimeout(() => {
						setConfirmLoading(false);
						navigate("/space");

						setAddWorkspaceDetails(null);
					}, 3000);
				} else {
					countDown(
						"We could not create your workspace. Please try again later",
						"fail"
					);
					setTimeout(() => {
						setConfirmLoading(false);
						navigate("/space");
						setAddWorkspaceDetails(null);
					}, 3000);
				}
			})
			.catch((error) => {
				console.error("Error updating workspace:", error);
			});
	};

	const [disabledSave, setDisabledSave] = useState(true);

	const handleFormChange = () => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
		setDisabledSave(hasErrors);
	};

	return (
		<Flex justify="center">
			{contextHolder}
			<Card
				hoverable
				style={{
					width: " 90vw",
					marginTop: "20px",
					marginBottom: "32px",
					background: "#F8FBFC",
					height: "80vh",
				}}
			>
				<Flex>
					<div style={{ width: "45%", overflow: "hidden", height: "70%" }}>
						<img
							alt="example"
							src={business}
							style={{
								overflow: "hidden",
								marginTop: "12%",
								maxHeight: "35vh",
								maxWidth: "35vw",
							}}
						/>
					</div>
					<div style={{ width: "500px" }}>
						<Typography.Title
							level={4}
							style={{
								textAlign: "center",
								marginTop: "8%",
								marginBottom: "auto",
							}}
						>
							Create Workspace
						</Typography.Title>
						<Form
							onFieldsChange={handleFormChange}
							{...layout}
							form={form}
							onFinish={onFinish}
							{...formItemLayout}
							layout="horizontal"
							style={{
								marginTop: "32px",
								width: "114%",
								marginLeft: "-10px",
								marginRight: "auto",
							}}
						>
							<Form.Item
								name="workspaceName"
								label="Name"
								colon={false}
								rules={[
									{ required: true, message: "Workspace name is required" },
									{ min: 5 },
								]}
							>
								<Input placeholder="Enter Name" />
							</Form.Item>
							<Form.Item
								name="Description"
								label="Description"
								colon={false}
								rules={[
									{
										required: true,
										message: "Workspace description is required",
									},
								]}
							>
								<Input.TextArea placeholder="Enter Description" />
							</Form.Item>
							<Form.Item
								name="workspaceType"
								label="Type"
								colon={false}
								rules={[
									{ required: true, message: "Workspace type is required" },
								]}
							>
								<Radio.Group>
									<Radio.Button value="appDev">App Development</Radio.Button>
									<Radio.Button value="webDev">Web Development</Radio.Button>
								</Radio.Group>
							</Form.Item>

							<Form.Item
								name="Agile"
								label="Agile Method"
								colon={false}
								rules={[{ required: true, message: "Workspace is required" }]}
							>
								<Radio.Group>
									<Radio.Button style={{ width: "160px" }} value="scrum">
										Scrum
									</Radio.Button>
									<Radio.Button style={{ width: "160px" }} value="kanban">
										Kanban
									</Radio.Button>
								</Radio.Group>
							</Form.Item>

							<Form.Item {...buttonItemLayout} style={{ marginLeft: "-20px" }}>
								<Button
									type="primary"
									htmlType="submit"
									disabled={disabledSave}
									loading={confirmLoading}
								>
									Submit
								</Button>
							</Form.Item>

							{/* <div className="button-container">
						<Button
							type="default"
							style={{ marginRight: "10px" }}
							onClick={handleModalCancel}
						>
							Share
						</Button>
						<Button type="primary" htmlType="submit">
							Done
						</Button>
					</div> */}
						</Form>
					</div>
				</Flex>
			</Card>
		</Flex>
	);
};

export default CreateWorkpaceForm;
