// import { Button, Flex, Typography } from "antd";
// import React, { useEffect, useState } from "react";
// import useAIActivityStore from "../../stores/AIActivityStore";
// import useWorkspaceStore from "../../stores/WorkspaceState";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../../stores/UserProfileStore";
// import { useNavigate, useParams } from "react-router-dom";
// import SelectWorkItemModal from "../../pages/SelectWorkItemModal";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import SelectWorkItemModalV2 from "../../pages/SelectWorkItemModalV2";
import useAIActivityStore from "../../stores/AIActivityStore";
import { Button, Flex, Typography, message } from "antd";
import { useRequest } from "ahooks";
import { getWorkItemsById } from "../../api/WorkItemAPI";

const PreChatProcess = () => {
	const navigate = useNavigate();
	const { goalParam } = useParams();
	// const [goal, setGoal] = useState<string>("");
	// const [aiConversationThread, setAiConversationThread] = useState<any>(null);
	const [workItemInContext, setWorkItemInContext] = useState<any>(null);
	const { setAIConversationContext, aiConversationContext } =
		useAIActivityStore((state: any) => ({
			setAIConversationContext: state.setAIConversationContext,
			aiConversationContext: state.aiConversationContext,
		}));

	// const [existingConversationThreadId, setExistingConversationThreadId] =
	// 	useState<string>("");
	const { currentWorkspace, setCurrentWorkspace } = useWorkspaceStore(
		(state: any) => ({
			setCurrentWorkspace: state.setCurrentWorkspace,
			currentWorkspace: state.currentWorkspace,
		})
	);
	const [itemSelectionModalVisible, setItemSelectionModalVisible] =
		useState(false);

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	useEffect(() => {
		initialSetup();
	}, []);

	// useEffect(() => {

	// },[aiConversationContext]);

	const initialSetup = async () => {
		if (goalParam && goalParam === "feature-elaboration") {
			if (!aiConversationContext?.data?.workitemId) {
				setAIConversationContext({
					type: "feature-elaboration",
					data: null,
				});
				setItemSelectionModalVisible(true);
			} else {
				getWorkItemById(aiConversationContext.data.workitemId);
				setAIConversationContext({
					type: "feature-elaboration",
					data: {
						workspaceId: currentWorkspace._id,
						workitemId: aiConversationContext.data.workitemId,
						userProfile: userProfileStoreInstance.profile,
					},
				});
			}
		}
		if (goalParam && goalParam === "feature-refinement") {
			if (!aiConversationContext?.data?.workitemId) {
				setAIConversationContext({
					type: "feature-refinement",
					data: null,
				});
				//setItemSelectionModalVisible(true);
			} else {
				getWorkItemById(aiConversationContext.data.workitemId);
				setAIConversationContext({
					type: "feature-refinement",
					data: {
						workspaceId: currentWorkspace._id,
						workitemId: aiConversationContext.data.workitemId,
						userProfile: userProfileStoreInstance.profile,
					},
				});
			}
		}
	};

	const { data: workitemByIdData, run: getWorkItemById } = useRequest(
		(workItemId) =>
			getWorkItemsById(workItemId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.success("Workitem by id fetched successfully");
				console.log(result, "feature data");
			},
			onError: (error) => {
				message.error(`Failed to fetch workitem feature : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemByIdData) {
			setWorkItemInContext(workitemByIdData);
		}
	}, [workitemByIdData]);

	const createWorkspace = async () => {
		//Create workspace dummy name
		const workspaceName = "Workspace" + Math.floor(Math.random() * 1000);

		const workspaceData = {
			name: workspaceName,
			status: "OPEN",
		};

		await fetch(`${process.env.REACT_APP_API_URL}/workspace`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(workspaceData),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Success:", data);
				setCurrentWorkspace(data);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	// const getConvsersationThreadConfig = async (goal: string) => {
	// 	try {
	// 		const goalInput = await getGoalInput(goal);
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/ai-conversation/goal/${goal}`,
	// 			{
	// 				method: "POST",
	// 				body: JSON.stringify(goalInput),
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 			}
	// 		);

	// 		if (!response.ok) {
	// 			throw new Error("Network response was not ok");
	// 		}

	// 		const responseData = await response.json();
	// 		setConversationGoal(responseData);
	// 		if (existingConversationThreadId === "") {
	// 			await createConversationThread(responseData.slug, goalInput);
	// 		} else {
	// 			await getConversationThread();
	// 		}
	// 		// Handle the response from the server if needed
	// 		console.log("GET request for step change successful", responseData);
	// 	} catch (error) {
	// 		console.error(
	// 			"There was a problem with the GET request for step change",
	// 			error
	// 		);
	// 	}
	// };

	// const updateConversationThread = async () => {
	// 	const updatedData = {
	// 		...aiConversationThread,
	// 		data: {
	// 			itemType: "WORKSPACE",
	// 			itemId: aiConversationContext.data.workitemId,
	// 		},
	// 	};

	// 	try {
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/ai-conversation-thread/${aiConversationThread._id}`,
	// 			{
	// 				method: "PUT",
	// 				headers: {
	// 					"Content-Type": "application/json; charset=UTF-8",
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 				body: JSON.stringify(updatedData),
	// 			}
	// 		);

	// 		if (response.ok) {
	// 			console.log("Conversation thread updated successfully");
	// 		} else {
	// 			console.error("Failed to update conversation thread");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error updating conversation thread:", error);
	// 	}
	// };

	// const checkItemInAIConversationContext = (goalParam: string) => {
	// 	if (aiConversationContext) {
	// 		if (
	// 			goalParam === "feature-elaboration" &&
	// 			aiConversationContext.type === "feature-elaboration"
	// 		) {
	// 			if (aiConversationContext?.data?.workItemId) {
	// 				return true;
	// 			} else {
	// 				return false;
	// 			}
	// 		}
	// 	}
	// 	return false;
	// };

	const handleWorkItemSelection = (item: any) => {
		setItemSelectionModalVisible(false);
		if (goalParam === "feature-elaboration") {
			setWorkItemInContext(item);
			setAIConversationContext({
				type: "feature-elaboration",
				data: {
					workspaceId: currentWorkspace._id,
					workitemId: item._id,
					userProfile: userProfileStoreInstance.profile,
				},
			});
		} else if (goalParam === "feature-refinement") {
			setWorkItemInContext(item);
			setAIConversationContext({
				type: "feature-refinement",
				data: {
					workspaceId: currentWorkspace._id,
					workitemId: item._id,
					userProfile: userProfileStoreInstance.profile,
				},
			});
		}
	};

	const preProcessStep = async () => {
		if (goalParam === "idea-refinement") {
			await createWorkspace();
			setAIConversationContext({
				type: "idea-refinement",
				data: null,
			});
		}
	};
	const navigateToChat = async () => {
		await preProcessStep();
		navigate(`/fd/chatv3/${goalParam}`);
	};
	return (
		<>
			<Flex justify="center" style={{ marginTop: "5rem" }}>
				{goalParam === "idea-refinement" && (
					<Button onClick={() => navigateToChat()}>
						Lets understand about product idea!!!
					</Button>
				)}
				{goalParam === "feature-refinement" && (
					<>
						<Flex vertical gap={12}>
							Lets refine feature idea!!!
							{!workItemInContext && (
								<Flex gap={12}>
									<Button
										onClick={() => {
											setAIConversationContext({
												type: "feature-refinement",
												data: null,
											});
											navigateToChat();
										}}
										disabled={workItemInContext ? true : false}
									>
										New feature
									</Button>
									<Button
										disabled={workItemInContext ? true : false}
										onClick={() => {
											setAIConversationContext({
												type: "feature-refinement",
												data: null,
											});
											setItemSelectionModalVisible(true);
										}}
									>
										Existing feature
									</Button>
								</Flex>
							)}
							{workItemInContext && (
								<Flex vertical gap={6}>
									<Typography.Title level={4}>
										{workItemInContext?.type} : {workItemInContext?.title}
									</Typography.Title>
									<br />
									<Button onClick={() => navigateToChat()}>
										Lets refine about feature!!!
									</Button>{" "}
									<br />
								</Flex>
							)}
						</Flex>
					</>
				)}
				{goalParam === "feature-elaboration" && (
					<Flex vertical gap={6}>
						<Typography.Title level={4}>
							{workItemInContext?.type} : {workItemInContext?.title}
						</Typography.Title>
						<br />
						<Button onClick={() => navigateToChat()}>
							Lets elaborate about feature!!!
						</Button>{" "}
						<br />
					</Flex>
				)}
			</Flex>
			<SelectWorkItemModalV2
				isModalVisible={itemSelectionModalVisible}
				handleModalCancel={() => setItemSelectionModalVisible(false)}
				goalParam={goalParam}
				onOk={handleWorkItemSelection}
			/>
		</>
	);
};

export default PreChatProcess;
