import React from "react";
import { Card } from "antd";
import { Node, register } from "@antv/xflow";

const CUSTOMMAINNODE = "custom-main-nodes";
const Nodes = ({ node }: { node: Node }) => {
	const data = node.getData();
	console.log("datasss", data);
	return (
		<Card
			style={{
				width: 250,
				height: 62,
				borderRadius: 10,
				borderColor: "black",
				backgroundColor: "rgba(232, 239, 254, 1)",
			}}
		>
			<p style={{ textAlign: "center", fontWeight: "bold" }}>{data}</p>
		</Card>
	);
};

const ports = {
	groups: {
		group3: {
			position: "bottom",
			attrs: {
				circle: { stroke: "#D06269", strokeWidth: 1, r: 1, magnet: true },
			},
		},
		group1: {
			position: "left",
			attrs: {
				circle: { stroke: "#D06269", strokeWidth: 1, r: 1, magnet: true },
			},
		},
	},
	items: [
		{ id: "group3", group: "group3" },
		{ id: "group1", group: "group1" },
	],
};

register({
	shape: CUSTOMMAINNODE,
	component: Nodes,
	effect: ["label"],
	ports: ports,
});

export { CUSTOMMAINNODE };
