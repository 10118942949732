/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import {
	Badge,
	Button,
	Menu,
	Popover,
	Input,
	Checkbox,
	Space,
	Tag,
	Flex,
	DatePicker,
} from "antd";
import {
	FilterOutlined,
	SearchOutlined,
	SwapLeftOutlined,
	ArrowLeftOutlined,
	SwapOutlined,
	CloseOutlined,
	ScheduleOutlined,
} from "@ant-design/icons";
import moment, { Moment } from "moment";
import { planerViewStore } from "../../stores/PlannerStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

const StatusFilterComponent = () => {
	const {
		filterValScheduler,
		setFilterValScheduler,
		dateFilterVal,
		setDateFilterVal,
	} = planerViewStore();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const initialMenuItems = [
		{ key: "Type", label: "Type", icon: <SwapLeftOutlined /> },
		{ key: "Date", label: "Date", icon: <ScheduleOutlined /> },
	];
	const [menuItems, setMenuItems] = useState(initialMenuItems);
	const [popoverVisible, setPopoverVisible] = useState(false);
	const [selectedIcon, setSelectedIcon] = useState<JSX.Element>(
		<FilterOutlined />
	);
	const [types, setTypes] = useState<string[]>([]);
	const [filteredTypes, setFilteredTypes] = useState<string[]>([]);
	const [showCheckboxes, setShowCheckboxes] = useState(false);
	const [showCheckboxesDate, setShowCheckboxesDate] = useState(false);
	const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
	const [startDate, setStartDate] = useState<Moment | null>(null);
	const [endDate, setEndDate] = useState<Moment | null>(null);

	useEffect(() => {
		const fetchTypeOptions = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/planconfig`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);

				if (response.ok) {
					const data = await response.json();
					const types = data.map((item: any) => item.type);
					setTypes(types);
					setFilteredTypes(types);
				} else {
					console.error("Failed to fetch type options");
				}
			} catch (error) {
				console.error("Error fetching type options:", error);
			}
		};

		fetchTypeOptions();
	}, [userProfileStoreInstance.idToken]);

	const handleCheckboxChange = (checked: boolean, type: string) => {
		let updatedSelectedTypes;
		if (checked) {
			updatedSelectedTypes = [...selectedTypes, type];
		} else {
			updatedSelectedTypes = selectedTypes.filter(
				(selectedType) => selectedType !== type
			);
		}
		setSelectedTypes(updatedSelectedTypes);
		setFilterValScheduler(updatedSelectedTypes); // Update the store with the new selected types
	};

	const handleUnselectAll = () => {
		setSelectedTypes([]);
		setFilterValScheduler([]);
		setShowCheckboxes(false);
	};

	const handleUnselectAllDate = () => {
		setDateFilterVal([null, null]);
		setShowCheckboxesDate(false);
		setStartDate(null);
		setEndDate(null);
	};

	const onChangeValue = (event: any) => {
		const value = event.target.value.toLowerCase();
		const filteredMenuItems = initialMenuItems.filter((item) =>
			item.label.toLowerCase().includes(value)
		);
		setMenuItems(filteredMenuItems);

		const filteredTypeItems = types.filter((type) =>
			type.toLowerCase().includes(value)
		);
		setFilteredTypes(filteredTypeItems);
	};

	const handleMenuItemClick = () => {
		setShowCheckboxes(true);
	};

	const handleMenuItemClickDate = () => {
		setShowCheckboxesDate(true);
	};

	const handleBackClick = () => {
		setShowCheckboxes(false);
		setShowCheckboxesDate(false);
	};

	const handleStartDateChange = (date: Moment | null) => {
		setStartDate(date);
		setDateFilterVal([date, endDate]);
	};

	const handleEndDateChange = (date: Moment | null) => {
		setEndDate(date);
		setDateFilterVal([startDate, date]);
	};

	const isMultiple = selectedTypes.length > 1;

	return (
		<Space>
			<Popover
				title={null}
				placement="bottomLeft"
				content={
					<>
						<Input
							placeholder="Search"
							style={{ width: 250, border: "none", marginTop: "0.5vh" }}
							size="small"
							onChange={onChangeValue}
							allowClear
							suffix={<SearchOutlined />}
							key={"search"}
							addonBefore={
								(showCheckboxes || showCheckboxesDate) && (
									<ArrowLeftOutlined onClick={handleBackClick} />
								)
							}
						/>
						{showCheckboxes ? (
							<Menu
								style={{ width: 256, alignItems: "center" }}
								mode="vertical"
							>
								{filteredTypes.map((type) => (
									<Menu.Item
										style={{
											display: "flex",
											alignItems: "center",
											height: 35,
											lineHeight: "35px",
											padding: "0px 4px",
											borderRadius: "6px",
										}}
										key={type}
									>
										<Checkbox
											checked={selectedTypes.includes(type)}
											onChange={(e) =>
												handleCheckboxChange(e.target.checked, type)
											}
										>
											{type}
										</Checkbox>
									</Menu.Item>
								))}
							</Menu>
						) : showCheckboxesDate ? (
							<div
								style={{
									alignItems: "center",
									marginTop: "1vh",
									textAlign: "center",
								}}
							>
								<Flex
									align="center"
									justify="cestartnter"
									style={{ padding: "15px" }}
								>
									<p style={{ margin: 0, color: "black" }}>Start Date:</p>
									<DatePicker
										style={{ marginLeft: "8px" }}
										onChange={handleStartDateChange}
									/>
								</Flex>
								<Flex
									style={{ padding: "15px" }}
									align="center"
									justify="start"
								>
									<p style={{ margin: 0, color: "black" }}>End Date:</p>
									<DatePicker
										style={{ marginLeft: "14px" }}
										onChange={handleEndDateChange}
									/>
								</Flex>
							</div>
						) : (
							!showCheckboxes &&
							!showCheckboxesDate && (
								<Menu style={{ width: 256 }} mode="vertical">
									{menuItems.map((item) => (
										<Menu.Item
											key={item.key}
											icon={item.icon}
											style={{
												display: "flex",
												alignItems: "center",
												height: 35,
												lineHeight: "35px",
												padding: "0px 4px",
												borderRadius: "6px",
											}}
											onClick={
												item.label === "Type"
													? handleMenuItemClick
													: handleMenuItemClickDate
											}
										>
											{item.label}
										</Menu.Item>
									))}
								</Menu>
							)
						)}
					</>
				}
				trigger="click"
				visible={popoverVisible}
				onVisibleChange={setPopoverVisible}
				style={{ textAlign: "center" }}
			>
				<Flex justify="center">
					<Badge color="blue" offset={[-40, 0]}>
						<Button
							style={{ borderRadius: "6px", fontSize: "14px" }}
							size="small"
						>
							{selectedIcon} Filter
						</Button>
					</Badge>
				</Flex>
			</Popover>
			{filterValScheduler.length > 0 && (
				<Flex style={{ alignItems: "center", gap: "0.05rem" }}>
					<Tag
						icon={<SwapOutlined />}
						style={{
							alignContent: "center",
							margin: 0,
							borderRadius: "0.5rem 0 0 0.5rem",
						}}
					>
						Type
					</Tag>
					<Tag
						style={{
							alignContent: "center",
							margin: 0,
							borderRadius: "0 0 0 0",
						}}
					>
						{isMultiple ? "is any of" : "is"}
					</Tag>
					<Tag
						style={{
							alignContent: "center",
							margin: 0,
							borderRadius: "0 0rem 0rem 0",
							cursor: "pointer",
						}}
					>
						{filterValScheduler.join(", ")}
					</Tag>
					<Tag
						onClick={() => handleUnselectAll()}
						style={{
							alignContent: "center",
							margin: 0,
							borderRadius: "0 0.5rem 0.5rem 0",
							cursor: "pointer",
						}}
						icon={<CloseOutlined />}
					/>
				</Flex>
			)}
			{startDate != null && endDate != null && (
				<Flex style={{ alignItems: "center", gap: "0.05rem" }}>
					<Tag
						style={{
							alignContent: "center",
							margin: 0,
							borderRadius: "0 0rem 0rem 0",
							cursor: "pointer",
						}}
					>
						{startDate && endDate
							? ` ${startDate?.format("DD MMM YYYY")} to ${endDate?.format("DD MMM YYYY")}`
							: "-"}
					</Tag>

					<Tag
						onClick={handleUnselectAllDate}
						style={{
							alignContent: "center",
							margin: 0,
							borderRadius: "0 0.5rem 0.5rem 0",
							cursor: "pointer",
						}}
						icon={<CloseOutlined />}
					/>
				</Flex>
			)}
		</Space>
	);
};

export default StatusFilterComponent;
