import React, { useEffect } from "react";
import { Button, Divider, Flex, List } from "antd";
// import usePlatformStore from "../stores/platformStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import "./NotificationList.css";
import { ClearOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";

interface NotificationListProps {
	getdata: boolean;
	clear: boolean;
}

const NotificationList: React.FC<NotificationListProps> = ({
	getdata,
	clear,
}) => {
	const [notifications, setNotifications] = React.useState<any[]>([]);
	// const { notifications } = usePlatformStore((state: any) => ({
	// 	notifications: state.notifications,
	// 	setNotifications: state.setNotifications,
	// }));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const serverLink = process.env.REACT_APP_API_URL;

	const fetchNotifications = () => {
		fetch(`${serverLink}/notifications/find/user`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				email: userProfileStoreInstance.profile.email,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				console.log("check notification", data);
				setNotifications(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (getdata === true || clear === true) {
			fetchNotifications();
		}
	}, [getdata, clear]);

	const handleClearNotification = async (Data: any) => {
		const reqbody = {
			recipient: Data.recipient,
			content: Data.content,
			subjectLine: Data.subjectLine,
			status: "READ",
		};
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/notifications/${Data._id}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(reqbody),
			}
		);

		if (response.ok) {
			fetchNotifications();
		} else {
			console.error("Failed to update Notification Status");
		}
	};

	return (
		<>
			<List
				className="notification-list"
				// pagination={{ boot, align }}
				dataSource={notifications}
				renderItem={(item: any) => (
					<List.Item>
						<div className="viewItems">
							<Flex justify="space-between">
								{/* <div className="viewItemsContent">{item.content}</div> */}
								<div
									className="viewItemsContent"
									dangerouslySetInnerHTML={{
										__html: item.content,
									}}
								/>
								<motion.div
									whileHover={{ rotate: [0, -45, 0, -45, 0] }}
									transition={{ duration: 1.0, delay: 0.4, loop: 2 }}
								>
									<Button
										icon={<ClearOutlined />}
										onClick={() => handleClearNotification(item)}
									></Button>
								</motion.div>
							</Flex>
							<Divider />
						</div>
					</List.Item>
				)}
			/>
		</>
	);
};

export default NotificationList;
