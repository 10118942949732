import type { NodeOptions } from "@antv/xflow";
import { useDnd } from "@antv/xflow";

import "./index.css";
import {
	CHEVRONNODE,
	DIAMONDNODE,
	DOWNTRINAGLENODE,
	ELLIPSENODE,
	ROUNDEDRECTANGLENODE,
	SQUARENODE,
	TRINAGLENODE,
} from "./node";
import { ColorPicker, Divider, Flex, Space, Tooltip } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { useState } from "react";
import type { ColorPickerProps, GetProp } from "antd";

type selectedColor = GetProp<ColorPickerProps, "value">;

const Dnd = () => {
	const [selectedColor, setSelectedColor] = useState<selectedColor>("#E4CCFF");
	const { startDrag } = useDnd();

	// const [visible, setVisible] = useState(false);

	// const toggleVisibility = () => {
	// 	setVisible(!visible);
	// };

	const handleMouseDown = (
		e: React.MouseEvent<Element, MouseEvent>,
		item: { type: string; label: string; node: NodeOptions }
	) => {
		startDrag(item.node, e);
	};

	const list = [
		{
			type: "Start",
			label: "Ellipse",
			node: {
				shape: ELLIPSENODE,

				width: 70,
				height: 70,
				attrs: {
					body: {
						strokeWidth: 2,
						stroke: selectedColor,
						fill: selectedColor,
					},
				},
			},
		},
		{
			type: "Process",
			label: "Square",
			node: {
				shape: SQUARENODE,
				width: 60,
				height: 60,
				attrs: {
					body: {
						strokeWidth: 2,
						stroke: selectedColor,
						fill: selectedColor,
					},
				},
			},
		},
		{
			type: "No",
			label: "Round Rectangle",
			node: {
				shape: ROUNDEDRECTANGLENODE,

				width: 90,
				height: 60,
				attrs: {
					body: {
						strokeWidth: 2,
						stroke: selectedColor,
						fill: selectedColor,
					},
				},
			},
		},
		{
			type: "Yes",
			label: "Down Rectangle",
			node: {
				shape: DOWNTRINAGLENODE,

				width: 90,
				height: 60,

				attrs: {
					body: {
						strokeWidth: 2,
						stroke: selectedColor,
						fill: selectedColor,
					},
				},
			},
		},
		{
			type: "Ok",
			label: "Triangle",
			node: {
				shape: TRINAGLENODE,

				width: 90,
				height: 60,

				attrs: {
					body: {
						strokeWidth: 2,
						stroke: selectedColor,
						fill: selectedColor,
					},
				},
			},
		},
		{
			type: "NotOk",
			label: "Diamond",
			node: {
				shape: DIAMONDNODE,
				width: 80,
				height: 80,
				attrs: {
					body: {
						fill: selectedColor,
						stroke: selectedColor,
					},
				},
			},
		},
		{
			type: "Polygon",
			label: "Chevron",

			node: {
				width: 120,
				height: 60,
				shape: CHEVRONNODE,
				attrs: {
					body: {
						strokeWidth: 2,
						stroke: selectedColor,
						fill: selectedColor,
					},
				},
			},
		},
	];

	const content = (
		<Space wrap className={"dnd"}>
			{list.map((item) => (
				<div
					key={item.label}
					className={`dndItem${item.type}`}
					onMouseDown={(e) =>
						handleMouseDown(
							e,
							item as { type: string; label: string; node: NodeOptions }
						)
					}
				>
					<Tooltip title={item.label}>
						<div className={`shape ${item.type}`}></div>
					</Tooltip>
				</div>
			))}
		</Space>
	);

	// console.log(selectedColor);

	const handleColorChange = (color: any) => {
		document.documentElement.style.setProperty(
			"--main-color",
			color.toHexString()
		);
		setSelectedColor(color.toHexString());
	};

	return (
		<Flex
			align="center"
			vertical
			style={{
				position: "absolute",
				bottom: "4rem",
				left: 0,
				right: 0,
				textAlign: "center",
				zIndex: 999,
				width: "100%",
			}}
		>
			{/* <Flex
				align="center"
				style={{
					display: visible ? "flex" : "none",
					backgroundColor: "#fcfcfb",
					minWidth: "30vw",
					borderRadius: " 2rem 2rem 0 0",
					width: "40vw",
					height: "7vh",
					border: "1px solid #CCCCCC",
					
					boxShadow:
						"rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
				}}
			>
				{content}
				<Divider
					type="vertical"
					style={{ borderColor: "black", height: "100%", marginLeft: "1.5rem" }}
				/>
				<Tooltip title="Color Picker">
					<ColorPicker value={selectedColor} onChange={handleColorChange} />
				</Tooltip>
				<Divider
					type="vertical"
					style={{ borderColor: "black", height: "100%" }}
				/>
				<Tooltip title="Connect">
					<Flex
						align="center"
						justify="center"
						style={{
							borderRadius: "10%",
							width: "2rem",
							height: "2rem",
							backgroundColor: "white",
							boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
							border: "1px solid #CCCCCC",
						}}
					>
						<MinusOutlined style={{ fontSize: "1.5rem" }} />
					</Flex>
				</Tooltip>
			</Flex> */}
			<Flex
				align="center"
				style={{
					padding: "0.4rem",
					backgroundColor: "white",
					border: "1px solid #CCCCCC",
					height: "12vh",
					minWidth: "45vw",
					width: "fit-content",
					borderRadius: "2rem",
					boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
				}}
			>
				{/* <Button
					onClick={toggleVisibility}
					icon={
						<BorderOutlined style={{ fontSize: 40, backgroundColor: "blue" }} />
					}
					type="text"
					style={{ margin: "1rem" }}
				/> */}
				{content}
				<Divider
					type="vertical"
					style={{ borderColor: "black", height: "100%", marginLeft: "1.5rem" }}
				/>
				<Tooltip title="Color Picker">
					<ColorPicker value={selectedColor} onChange={handleColorChange} />
				</Tooltip>
				<Divider
					type="vertical"
					style={{ borderColor: "black", height: "100%" }}
				/>
				<Tooltip title="Connect">
					<Flex
						align="center"
						justify="center"
						style={{
							borderRadius: "10%",
							width: "2rem",
							height: "2rem",
							backgroundColor: "white",

							boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
							border: "1px solid #CCCCCC",
						}}
					>
						<MinusOutlined style={{ fontSize: "1.5rem" }} />
					</Flex>
				</Tooltip>
			</Flex>
		</Flex>
	);
};

export { Dnd };
