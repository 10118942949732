import React, { useEffect, useState } from "react";
import { Layout, Descriptions } from "antd";
import "./RoleManagement.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
import AccountManagementNav from "./AccountMangementNav";

const AccountManagement: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [userAccountDetail, setUserAccountDetail] = useState<any>();

	const FetchAccountDetail = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/account/${userProfileStoreInstance.profile.accountId[0]}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setUserAccountDetail(data);
				console.log("response", data);
			} else if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				console.error("Error fetching roles data");
			}
		} catch (error) {
			console.error("Error fetching roles data:", error);
		}
	};

	useEffect(() => {
		FetchAccountDetail();
	}, []);

	return (
		<>
			<AccountManagementNav />
			<Layout
				style={{
					backgroundColor: "white",
					padding: "0 1.5rem ",
					margin: "0 1vw",
					height: "80vh",
					marginTop: "-0.5vh",
				}}
			>
				<div style={{ marginTop: "1vh" }}>
					<Descriptions>
						<Descriptions.Item label="Name">
							{userAccountDetail?.name}
						</Descriptions.Item>
						<Descriptions.Item label="Type">
							{userAccountDetail?.type}
						</Descriptions.Item>
					</Descriptions>
				</div>
			</Layout>
		</>
	);
};

export default AccountManagement;
