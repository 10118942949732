import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { Flex, Image, Spin } from "antd";

import useAIActivityStore from "../stores/AIActivityStore";

const ElaborateFeatureFromThread = () => {
	// Implement your component logic here
	const navigate = useNavigate();
	const { threadId } = useParams();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { aiConversationContext } = useAIActivityStore((state: any) => ({
		aiConversationContext: state.aiConversationContext,
	}));

	const parseConversationResult = async (threadId: string) => {
		// Implement your code here
		console.log(aiConversationContext, "aiConversationContext");
		const result = await fetch(
			`${process.env.REACT_APP_API_URL}/ai-conversation-thread/parse/${threadId}/feature-elaboration`,
			{
				method: "POST",
				body: JSON.stringify(aiConversationContext),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);
		if (result.status !== 200 && result.status !== 201) {
			throw new Error("Network response was not ok");
		}
		const responseData = await result.json();
		navigate(`/space`);
		return responseData;
	};

	useEffect(() => {
		if (threadId) parseConversationResult(threadId);
	}, [threadId]);

	return (
		<Flex
			vertical
			style={{
				height: "100vh",
			}}
		>
			<Flex justify="center">
				{/* <Steps
				type="navigation"
				//current={currentStep}
				size="small"
				style={{ marginTop: "8vh", width: "60%", justifyContent: "center" }}
				percent={100}
				items={[
					{ title: "Understand the product idea in detail", icon:<BulbFilled/>},
					// { title: "Existing solution" },
					// { title: "Your solution" },
					// {
					// 	title: "USP",
					// },
				]}
			/> */}
				<>
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
					/>
					<Spin size="large" />
				</>
			</Flex>{" "}
			<Flex justify="center">
				<h3>Finishing the final steps...</h3>
			</Flex>
		</Flex>
	);
};

export default ElaborateFeatureFromThread;
