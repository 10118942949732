import React, { useState, useEffect } from "react";
import {
	Layout,
	Card,
	Table,
	Typography,
	Space,
	Tooltip,
	Popconfirm,
	message,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import { useViewsStores } from "../../stores/BacklogStore";
import LinkTabModel from "./LinkTabModel";
import { DeleteOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { getWorkItemsById } from "../../api/WorkItemAPI";

const { Content } = Layout;
const { Text } = Typography;

const LinkTab: React.FC = () => {
	const [responseData, setResponseData] = useState<any[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { DrawerTab } = useViewsStores();
	const [openModal, setopenModal] = useState(false);
	const { selectedItemDetails } = planerViewStore();

	const { data: workitemByIdData, run: getWorkItemById } = useRequest(
		(workItemId) =>
			getWorkItemsById(workItemId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.info(
					`${result.title.slice(0, 1).toUpperCase() + result.title.slice(1)} Link`
				);
				console.log(result, "feature data");
			},
			onError: (error) => {
				message.error(`Failed to fetch workitem feature : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemByIdData) {
			if (Array.isArray(workitemByIdData.links)) {
				setResponseData(workitemByIdData.links);
			}
		}
	}, [workitemByIdData]);

	useEffect(() => {
		if (selectedItemDetails._id && DrawerTab === "link") {
			getWorkItemById(selectedItemDetails._id);
		}
	}, [selectedItemDetails._id, DrawerTab]);

	const Handledelete = async (index: number) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/work-items/${selectedItemDetails._id}/links/${index}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				// Update state after successful deletion
				const updatedLinks = [...responseData];
				updatedLinks.splice(index, 1);
				setResponseData(updatedLinks);
			} else {
				console.error("Error deleting link:", response.statusText);
			}
		} catch (error) {
			console.error("Error deleting link:", error);
		}
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text: any) => <span>{text}</span>,
		},
		{
			title: <div style={{ fontFamily: "Poppins" }}>Type</div>,
			dataIndex: "type",
			key: "type",
			render: (text: any) => (
				<Text
					style={{
						fontSize: "14px",
						fontWeight: "500",
						fontFamily: "Open Sans",
					}}
				>
					{text}
				</Text>
			),
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (text: any) => <span>{text}</span>,
		},
		{
			title: "URL",
			dataIndex: "url",
			key: "url",
			render: (text: any) => (
				<a href={text} target="_blank" rel="noreferrer">
					{text}
				</a>
			),
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (_id: any, record: any) => {
				return (
					<Space size={"large"}>
						<Tooltip title="Delete">
							<Popconfirm
								title="Sure to Delete?"
								onConfirm={() => {
									Handledelete(record.key);
								}}
							>
								<a style={{ color: "#3478F6", fontSize: "12px" }}>
									<DeleteOutlined style={{ fontSize: "14px", color: "red" }} />
								</a>
							</Popconfirm>
						</Tooltip>
					</Space>
				);
			},
		},
	];

	return (
		<>
			<LinkTabModel openModal={openModal} setopenModal={setopenModal} />
			<Layout style={{ height: "auto" }}>
				<Content className="prefix-content-container">
					<Card
						data-testid="card"
						style={{
							backgroundColor: "white",
							borderRadius: "16px",
							//padding: "24px",
							paddingLeft: "24px",
							paddingRight: "24px",
							border: "none",
							boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
							height: "100%",
							overflow: "scroll",
						}}
					>
						<div>
							<Table
								data-testid="table"
								scroll={{ y: 240 }}
								columns={columns}
								pagination={{
									pageSize: 6,
									pageSizeOptions: ["10", "20", "30"],
									showTotal: (total, range) =>
										`${range[0]}-${range[1]} of ${total} items`,
								}}
								dataSource={responseData.map((link, index) => ({
									key: index,
									name: link.name,
									type: link.type,
									description: link.description,
									url: link.url,
								}))}
							/>
						</div>
					</Card>
				</Content>
			</Layout>
		</>
	);
};

export default LinkTab;
