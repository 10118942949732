import React, { useState } from "react";
import { Popover, Card, Tooltip, Flex, Switch } from "antd";
import { Link } from "react-router-dom";
import {
	AppstoreAddOutlined,
	InsertRowAboveOutlined,
	InsertRowLeftOutlined,
} from "@ant-design/icons";
import userProfileStore from "../../stores/UserProfileStore";

interface MenuItem {
	key: string;
	label: string;
	url?: string;
}

interface CardItem {
	title?: string;
	description?: string;
	menuItems?: MenuItem[];
	url?: string;
}

const cards: CardItem[] = [
	{
		title: "Tasks",
		menuItems: [
			{ key: "1", label: "Home", url: `/space` },
			{ key: "2", label: "MyTasks", url: `/space/planner/mytasks` },
			{ key: "3", label: "Bookmarks", url: `` },
		],
	},
	{
		title: "Planner",
		menuItems: [
			{ key: "1", label: "Backlogs", url: `/space/planner/backlogs` },
			{ key: "2", label: "Rituals", url: `/space/planner/rituals` },
			{ key: "3", label: "Plans", url: `/space/planner/plans` },
		],
	},
	{
		title: "Design Artifacts",
		description: "Description",
		menuItems: [
			{
				key: "1",
				label: "User Persona",
				url: "planner/de/uxprocess/${currentWorkspace._id}",
			},
			{
				key: "2",
				label: "SiteMap",
				url: "planner/de/info/${currentWorkspace._id}",
			},
			{
				key: "3",
				label: "Architecture",
				url: "planner/de/ddd/core/${currentWorkspace._id}",
			},
			{
				key: "4",
				label: "API Definition",
				url: "planner/de/apiDesign/${currentWorkspace._id}",
			},
			{
				key: "5",
				label: "Database Schema",
				url: "planner/de/data/${currentWorkspace._id}",
			},
		],
	},
	{
		title: "Tools",
		description: "Description",
		menuItems: [
			{ key: "1", label: "Team Member", url: "/space/planner/teammember" },
			{ key: "2", label: "WhiteBoard", url: "/space/planner/whiteboard" },
			{ key: "3", label: "Notes", url: "/space/planner/notes" },
		],
	},
];

// interface PopupMenuPopoverProps {
// 	visible: boolean;
// 	onClickOutside: () => void;
// }

const PopupMenuPopover: React.FC = () => {
	// const handleItemClick = () => {
	// 	onClickOutside();
	// };
	const [popoverVisible, setPopoverVisible] = useState(false);

	const handleTogglePopover = () => {
		setPopoverVisible(!popoverVisible);
	};

	const handleClickOutside = () => {
		setPopoverVisible(false);
	};
	const { menuMode, setMenuMode } = userProfileStore((state: any) => ({
		menuMode: state.menuMode,
		setMenuMode: state.setMenuMode,
	}));

	const handleModeChange = () => {
		setMenuMode("inline");
		//onClickOutside();
	};

	const content = (
		<div
			className="Test"
			style={{
				display: "grid",
				gridTemplateColumns: "repeat(4, 1fr)",
				gap: "1vw",
				margin: "1vw",
				width: "65vw",
			}}
		>
			{cards.map((card, index) => (
				<Card
					key={index}
					style={{
						height: 200,
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<div style={{ marginLeft: "1vw", marginTop: "-1vh" }}>
						{card.title && <h3>{card.title}</h3>}
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<div style={{ flex: 1, paddingLeft: "10px" }}>
							{card.menuItems && card.menuItems.length > 0 && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										marginTop: "-20px",
									}}
								>
									{card.menuItems.map((item) => (
										<Link
											key={item.key}
											to={item.url || ""}
											onClick={handleClickOutside}
											style={{
												fontWeight: "bold",
												color: "#0004BF",
												marginBottom: "5px",
											}}
										>
											{item.label}
										</Link>
									))}
								</div>
							)}
						</div>
					</div>
				</Card>
			))}
			<Flex>
				<Tooltip
					title={
						menuMode === "inline"
							? "Switch Menu to Horizontal"
							: "Switch Menu to Vertical"
					}
				>
					<Flex>
						<InsertRowLeftOutlined style={{ color: "rgb(52, 120, 246)" }} />{" "}
						{"    "}
						<Switch
							size="small"
							onClick={handleModeChange}
							checked={menuMode === "horizontal"}
						/>{" "}
						{"    "}
						<InsertRowAboveOutlined style={{ color: "rgb(52, 120, 246)" }} />
					</Flex>
				</Tooltip>
			</Flex>
		</div>
	);

	return (
		<Popover
			placement="bottomRight"
			content={content}
			trigger="click"
			visible={popoverVisible}
			onVisibleChange={handleClickOutside}
			autoAdjustOverflow={true}
		>
			<AppstoreAddOutlined
				style={{
					color: "#0444BF",
					fontSize: "24px",
					cursor: "pointer",
					marginLeft: "1vw",
				}}
				onClick={handleTogglePopover}
			/>
		</Popover>
	);
};

export default PopupMenuPopover;
