import axios, { AxiosResponse } from "axios";
import { WhiteboardModel } from "../models/WhiteboardModel";

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
	baseURL,
});

async function createWhiteboard(
	createWhiteboardDto: WhiteboardModel,
	token: string
): Promise<WhiteboardModel> {
	try {
		const response: AxiosResponse<WhiteboardModel> = await api.post(
			`/whiteboard`,
			createWhiteboardDto,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			}
		);
		return response.data;
	} catch (error: any) {
		console.error(
			`Error creating whiteboard: ${error.response?.data || error.message}`
		);
		throw error;
	}
}

async function getWhiteboardById(
	id: string,
	token: string
): Promise<WhiteboardModel> {
	try {
		const response: AxiosResponse<WhiteboardModel> = await api.get(
			`/whiteboard/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error: any) {
		console.error(
			`Error getting whiteboard: ${error.response?.data || error.message}`
		);
		throw error;
	}
}

async function getWhiteboardByWorkspaceId(
	workspaceId: string,
	token: string
): Promise<WhiteboardModel[]> {
	try {
		const response: AxiosResponse<WhiteboardModel[]> = await api.get(
			`/whiteboard/workspace/${workspaceId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json; charset=UTF-8",
				},
			}
		);
		return response.data;
	} catch (error: any) {
		console.error(
			`Error getting whiteboard: ${error.response?.data || error.message}`
		);
		throw error;
	}
}

async function updateWhiteboard(
	id: string,
	updateWhiteboardDto: Partial<WhiteboardModel>,
	token: string
): Promise<WhiteboardModel> {
	try {
		const response: AxiosResponse<WhiteboardModel> = await api.put(
			`/whiteboard/${id}`,
			updateWhiteboardDto,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error: any) {
		console.error(
			`Error updating whiteboard: ${error.response?.data || error.message}`
		);
		throw error;
	}
}

export {
	createWhiteboard,
	getWhiteboardById,
	getWhiteboardByWorkspaceId,
	updateWhiteboard,
};
