import { Drawer, Empty } from "antd";
import React, { useEffect } from "react";
import usePlatformStore from "../stores/platformStore";
import { getHelpData } from "../util/HelpUtil";

interface HelpProps {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	open: boolean;
}

const Help: React.FC<HelpProps> = ({ setOpen, open }) => {
	const [helpData, setHelpData] = React.useState<any>([]);

	const { currentModule, currentPage } = usePlatformStore((state: any) => ({
		currentModule: state.currentModule,
		currentPage: state.currentPage,
	}));

	useEffect(() => {
		console.log("currentModule", currentModule);
		console.log("currentPage", currentPage);

		setHelpData(getHelpData(currentModule, currentPage, ""));
	}, [currentModule, currentPage]);

	const onClose = () => {
		setOpen(false);
	};
	return (
		<Drawer
			title={<h1>Help</h1>}
			placement="right"
			onClose={onClose}
			open={open}
			width={"32vw"}
		>
			{helpData && helpData.length > 0 ? helpData : <Empty />}
		</Drawer>
	);
};

export default Help;
