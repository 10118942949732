import { Button, Modal, Flex, Typography } from "antd";
import "./ConformationModel.css";
import Lottie from "lottie-react";
import ArchiveWorkspace from "../assets/ArchiveWorkspace.json";

const { Paragraph } = Typography;
const ArchivedWorkspaceConfirmationModal: React.FC<any> = ({
	visible,
	onOk,
	onCancel,
}) => {
	return (
		<Modal
			width={"45%"}
			open={visible}
			onOk={onOk}
			onCancel={onCancel}
			footer={null}
		>
			<Flex vertical justify="center" align="center" gap={5}>
				<Lottie
					animationData={ArchiveWorkspace}
					loop={true}
					autoplay={true}
					style={{ width: "50%", height: "auto" }}
				/>
				<Paragraph strong style={{ textAlign: "center" }}>
					Deleting this workspace – a simple click, but it holds a trove of
					shared victories and lessons. Are you ready for a fresh digital
					canvas?
				</Paragraph>
				<Flex gap={30} align="center" justify="center">
					<Button type="primary" onClick={onOk}>
						Delete Workspace
					</Button>
					<Button onClick={onCancel}>Cancel</Button>
				</Flex>
			</Flex>
		</Modal>
	);
};

export default ArchivedWorkspaceConfirmationModal;
