// import React, { useEffect, useState } from "react";
import React, { useState } from "react";
import "../layout/AppLayout.css";
//import EditWorkspaceModal from "../Alchemi/EditWorkspace";
import //PlusCircleOutlined,
//DeploymentUnitOutlined,
//EditOutlined,
//UserOutlined,
//BulbTwoTone,
//SettingOutlined,
"@ant-design/icons";
// import { FloatButton, Image, Layout, Spin } from "antd";
import { FloatButton, Layout } from "antd";
import { Outlet } from "react-router-dom";
//import ShareWorkSpaceModal from "../component/ShareWorkspace";
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../authConfig";

// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Help from "../pages/Help";

import FullDialogHeader from "./FullDialogHeader";

function WFFullDialogLayout() {
	// const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { Content } = Layout;

	// const { instance, accounts } = useMsal();

	// const [authenticated, setAuthenticated] = useState(false);

	// useEffect(() => {
	// 	if (authenticated) {
	// 		const fetchUserData = async () => {
	// 			try {
	// 				const response = await fetch(
	// 					`${ServerLink}/user-profiles/user/azureid`,
	// 					{
	// 						headers: {
	// 							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 						},
	// 					}
	// 				);
	// 				const userdate = await response.json();
	// 				console.log("userdate", userdate);
	// 				userProfileStoreInstance.setProfile(userdate);
	// 			} catch (error: any) {
	// 				message.error(`Error fetching user profile: ${error.message}`);
	// 			}
	// 		};

	// 		fetchUserData();
	// 	}
	// }, [authenticated]);

	// useEffect(() => {
	// 	const checkAuthentication = async () => {
	// 		if (accounts.length === 0) {
	// 			try {
	// 				await new Promise((resolve) => {
	// 					setTimeout(resolve, 100);
	// 					message.success("Redirecting to login page...");
	// 				});
	// 				await instance.loginRedirect(loginRequest);

	// 				console.log("Access token:", loginRequest);

	// 				setAuthenticated(true);
	// 			} catch (error: any) {
	// 				// console.error(error);
	// 				// Modal.error({
	// 				// 	title: "Authentication Failed",
	// 				// 	content:
	// 				// 		"There was an error during authentication. Please try again.",
	// 				// });
	// 				message.error(`Error authenticating: ${error.message}`);
	// 			}
	// 		} else {
	// 			setTimeout(() => {
	// 				setAuthenticated(true);
	// 			}, 1000);

	// 			const silentRequest = {
	// 				account: accounts[0],
	// 				scopes: ["openid"],
	// 			};
	// 			try {
	// 				await new Promise((resolve) => {
	// 					setTimeout(resolve, 500);
	// 				});
	// 				const response = await instance.acquireTokenSilent(silentRequest);
	// 				const idToken = response.idToken;
	// 				// console.log("idtoken", idToken);
	// 				userProfileStoreInstance.setIdToken(idToken);
	// 			} catch (error: any) {
	// 				message.error(`Error authenticating: ${error.message}`);
	// 			}
	// 			// console.log("Access token:", loginRequest);
	// 		}
	// 	};
	// 	checkAuthentication();
	// }, [instance, accounts.length]);

	//console.log("authenticated", authenticated);

	// const ServerLink = process.env.REACT_APP_API_URL;

	// useEffect(() => {
	// 	if (authenticated) {
	// 		message.success("Welcome! You are now logged in.");
	// 	}
	// }, [authenticated]);

	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	return (
		<Layout className="LandingPage" style={{ height: "100vh" }}>
			{/* {authenticated ? ( */}
			<>
				<FullDialogHeader />
				<Layout style={{ background: "#F5F8FE" }}>
					{/* <PlanerMenu /> */}

					<Content
						style={{
							overflow: "auto",
							padding: ".5rem 4rem .5rem 4rem",
						}}
					>
						<Outlet />
						<FloatButton
							icon={<QuestionCircleOutlined />}
							onClick={showDrawer}
						/>
						<Help open={open} setOpen={setOpen} />
					</Content>
				</Layout>
			</>
			{/* ) : (
				<div className="loader-container">
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
					/>
					<Spin size="large" />
				</div>
			)} */}
		</Layout>
	);
}

export default WFFullDialogLayout;
