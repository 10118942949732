import React, { useState, useEffect } from "react";
import { XFlow, XFlowGraph } from "@antv/xflow";

import { useParams } from "react-router-dom";
import { Spin } from "antd";

import CustomCard from "./CardComponet";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import { InitNode } from "../GenericSubDomainv2";

const GenericSubDomainIndex = () => {
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = useState(true);
	const { dddSpecOnclick } = useWorkspaceStore((state: any) => ({
		dddSpecOnclick: state.dddSpecOnclick,
	}));
	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false); // 1 sec
		}, 1000);

		return () => clearTimeout(timeout); // Cleanup function
	}, []);

	return (
		<div
			className={"container"}
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				height: "100vh",
				marginTop: "-5vh",
			}}
		>
			{loading ? ( // Show loading indicator while loading
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "100vh",
					}}
				>
					<Spin size="large" />
					<img
						style={{ marginTop: "10px", width: "150px" }}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
						alt="Loading"
					/>
					<Spin size="large" />
				</div>
			) : (
				<>
					{/* Adjust width and height here */}
					<div style={{ width: "89vw", height: "87vh", position: "relative" }}>
						<div style={{ width: "50%", height: "100%", position: "relative" }}>
							<XFlow>
								<XFlowGraph
									centerView
									// zoomable
									// zoomOptions={{
									// 	minScale: 0.5,
									// 	maxScale: 3,
									// }}
									pannable
									fitView
									magnetAdsorbedHighlightOptions={{
										name: "stroke",
										args: {
											attrs: {
												fill: "#5F95FF",
												stroke: "#5F95FF",
											},
										},
									}}
									connectionOptions={{
										snap: true,
										allowBlank: false,
										allowLoop: false,
										highlight: true,
										anchor: "center",
										router: "orth",
										connector: "rounded",
									}}
									connectionEdgeOptions={{
										attrs: {
											line: {
												stroke: "rgb(72, 203, 164)",
												strokeWidth: 2,
												targetMarker: {
													name: "block",
													width: 14,
													height: 10,
												},
											},
										},
										zIndex: 0,
									}}
									selectOptions={{
										multiple: true,
										rubberband: true,
										strict: true,
										modifiers: "shift",
									}}
								/>

								<InitNode id={id} />
							</XFlow>
						</div>
						{dddSpecOnclick ? (
							<CustomCard selectedNodeDetails={dddSpecOnclick} />
						) : null}
					</div>
				</>
			)}
		</div>
	);
};

export default GenericSubDomainIndex;
