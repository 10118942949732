// WorkItemModal.tsx
import React, { useEffect } from "react";
import { Modal, Form, Button, Input } from "antd";

import "react-quill/dist/quill.snow.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { useRequest } from "ahooks";
import { updateProductArtifact } from "../../api/UserPersona";

interface WorkItemModalProps {
	visible: boolean;
	onCancel: () => void;
	data: any;
	persona: any;
	personNo: any;
}
const { TextArea } = Input;

const EditUxProcess: React.FC<WorkItemModalProps> = ({
	visible,
	onCancel,
	data,
	persona,
	personNo,
}) => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [form] = Form.useForm();
	console.log(data, "data");
	const formItemLayout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 16 },
	};

	useEffect(() => {
		// Update form's initial values when 'data' prop changes
		if (data) {
			form.setFieldsValue({
				name: data.user_persona.name,
				age: data.user_persona.age,
				occupation: data.user_persona.occupation,
				biography: data.user_persona.biography,
				pain_points: data.user_persona.pain_points,
				motivations: data.user_persona.motivations,
			});
		}
	}, [data]);

	// fetch(
	// 	`${process.env.REACT_APP_API_URL}/project-artifact/${persona[0]._id}`,
	// 	{
	// 		method: "PUT",

	// 		headers: {
	// 			"Content-Type": "application/json; charset=UTF-8",
	// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 		},
	// 		body: JSON.stringify(updatedData),
	// 	}
	// )
	// 	.then((response) => {
	// 		if (response.ok) {
	// 			console.log("Update successful", response);
	// 			// Close the modal after successful update
	// 			onCancel();
	// 		} else {
	// 			console.error("Update failed", response);
	// 		}
	// 	})
	// 	.catch((error) => {
	// 		console.error("Error fetching data:", error);
	// 	});

	const { run: fetchUpdate } = useRequest(
		(id: string, updatedData: any) =>
			updateProductArtifact(id, updatedData, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log("User persona is updating ....", result);
				// Close the modal after successful update
				onCancel();
			},
			onError: () => {
				console.log("Error updating product artifact ....");
			},
		}
	);

	const handleFinish = (values: any) => {
		console.log(values);
		const field = {
			id: data.id,
			role_id: "customer",
			user_persona: {
				...values,
			},
		};
		console.log(personNo);

		// Create a copy of the persona array
		const updatedUserPersonas = [...persona[0].data];
		console.log(updatedUserPersonas, "updatedUserPersonas");

		// Update the user_persona object at the specified index
		updatedUserPersonas[personNo] = {
			...updatedUserPersonas[personNo],
			...field,
			// user_persona: {
			// 	...field,
			// },
		};
		// Create a new object with the updated user_personas array
		const updatedData = {
			...persona[0],
			data: [
				// ...persona[0].data,
				// user_personas: updatedUserPersonas,
				...updatedUserPersonas,
			],
		};

		console.log(updatedData);
		fetchUpdate(persona[0]._id, updatedData);
	};

	return (
		<Modal
			title="Edit User Experience Process"
			centered
			open={visible}
			onCancel={onCancel}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={() => form.submit()}>
					Save
				</Button>,
			]}
			width={800}
		>
			<Form {...formItemLayout} form={form} onFinish={handleFinish}>
				<Form.Item label="Name" name="name">
					<Input placeholder="Name" />
				</Form.Item>
				<Form.Item label="Age" name="age">
					<Input placeholder="Age" />
				</Form.Item>
				<Form.Item label="Occupation" name="occupation">
					<Input placeholder="Occupation" />
				</Form.Item>

				<Form.Item label="Biography" name="biography">
					<TextArea rows={4} placeholder="Biography" />
				</Form.Item>
				<Form.Item label="Pain Point" name="pain_points">
					<TextArea rows={4} placeholder="Pain Point" />
				</Form.Item>
				<Form.Item label="Motivation" name="motivations">
					<TextArea rows={4} placeholder="Motivation" />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditUxProcess;
