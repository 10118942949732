import { Badge, Button, Flex, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { useRequest } from "ahooks";
import Notification from "../pages/Notification";
import { BellOutlined } from "@ant-design/icons";
import usePlatformStore from "../stores/platformStore";
import { motion } from "framer-motion";

const NotificationNavButton = () => {
	const [showCountBadge, setShowCountBadge] = useState(false);
	const [open, setOpen] = useState(false);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { notifications, setNotifications } = usePlatformStore(
		(state: any) => ({
			notifications: state.notifications,
			setNotifications: state.setNotifications,
		})
	);
	const getNotifications = async () => {
		// Implement your code here

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/describe/${currentWorkspace._id}/activity?status=TEST`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);

		const result = await response.json();
		return result;
		//setAIActivityJob(result);
	};
	const {
		data: aiActivitiesData,
		run: aiActivitiesRun,
		cancel: aiActivitiesCancel,
	} = useRequest(() => getNotifications(), {
		pollingInterval: 120000,
		pollingWhenHidden: false,
	});
	useEffect(() => {
		return () => {
			aiActivitiesCancel();
		};
	}, []);

	useEffect(() => {
		if (aiActivitiesData) {
			setNotifications(aiActivitiesData);
		}
	}, [aiActivitiesData]);

	useEffect(() => {
		if (currentWorkspace) aiActivitiesRun();
	}, [currentWorkspace]);

	useEffect(() => {
		notifications &&
			notifications.length > 0 &&
			notifications.filter((notification: any) => {
				if (notification.status === "IN_PROGRESS") {
					setShowCountBadge(true);
				} else {
					setShowCountBadge(true);
				}
			});
		if (notifications && notifications.length === 0) {
			setShowCountBadge(true);
		}
	}, [notifications]);
	const showDrawer = () => {
		setOpen(true);
	};
	return (
		// JSX code for your component's UI
		// <Popover
		// 	title={"Alchemi AI"}
		// 	placement="bottom"
		// 	content={<AlchemiAIPopup />}
		// 	trigger={"click"}
		// 	style={{ textAlign: "center", border: "2px solid red" }}
		// >
		// 	{" "}
		<>
			<Flex justify="flex-start" align="start">
				<Badge
					count={notifications && notifications.length}
					showZero={showCountBadge}
					color="#0444bf"
					size="default"
					offset={[-2, 2]}
				>
					<Notification open={open} setOpen={setOpen} />
					<Tooltip placement="bottom" title="Notifications">
						<motion.div
							whileHover={{ rotate: [0, -45, 0, 45, 0, -45, 0] }}
							transition={{ duration: 0.5, loop: 3 }}
						>
							<Button
								shape="circle"
								icon={<BellOutlined />}
								// size="middle"
								size="small"
								onClick={showDrawer}
								style={{ color: "#0444BF", borderColor: "#0444BF" }}
							/>
						</motion.div>
					</Tooltip>
				</Badge>
			</Flex>
		</>
		// </Popover>
	);
};

export default NotificationNavButton;
