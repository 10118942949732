// import Content1 from "./Content1";
// import Content2 from "./Content2";

import AssignedBug from "../AssignedBug";
import AssignedTask from "../AssignedTask";
import AssignedUserStory from "../AssignedUserStory";
import BarChart from "../BarChart";
import PieChart from "../PieChart";

interface ComponentMap {
	[key: string]: React.FC<object>;
}

const components: ComponentMap = {
	// Content1,
	// Content2,

	AssignedTask,
	AssignedBug,
	AssignedUserStory,
	PieChart,
	BarChart,
	// Add more Content components here
};

function DynamicContent({
	componentName,
	...props
}: {
	componentName: string;
	[key: string]: any;
}) {
	const Component = components[componentName];
	return Component ? <Component {...props} /> : null;
}

export default DynamicContent;
