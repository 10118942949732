/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { Badge, Button, Menu, Popover, Input, message, Avatar } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";

const UserProfileFilterComponent = ({ type }: { type: string }) => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const [permissionsData, setPermissionsData] = useState<any[]>([]);
	const { selectedItemDetails, setSelectedItemDetails } = planerViewStore();
	const [filteredUserProfiles, setFilteredUserProfiles] = useState<any[]>([]);
	const [popoverVisible, setPopoverVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [selectedUserProfile, setSelectedUserProfile] = useState<any>(null);

	useEffect(() => {
		const fetchUserProfiles = async () => {
			const requestOptions = {
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			};

			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${selectedItemDetails.workspaceId}`,
					requestOptions
				);
				if (!response.ok) {
					message.error("Failed to fetch workspace permissions");
					setLoading(false);
					return;
				}

				const data = await response.json();
				setPermissionsData(data);
				setFilteredUserProfiles(data);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching user profiles:", error);
				message.error("An error occurred while fetching user profiles");
				setLoading(false);
			}
		};

		fetchUserProfiles();
	}, [userProfileStoreInstance.idToken, selectedItemDetails.workspaceId]);

	const onChangeValue = (event: any) => {
		const value = event.target.value.toLowerCase();
		const filteredItems = permissionsData.filter((perm) =>
			perm.userProfileId?.name?.toLowerCase().includes(value)
		);
		setFilteredUserProfiles(filteredItems);
	};

	const handleMenuClick = async (profile: any) => {
		try {
			const requestOptions = {
				method: "PUT",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({ assignee: profile.userProfileId }),
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				requestOptions
			);

			if (response.ok) {
				// Fetch the updated selectedItemDetails from the server
				const updatedResponse = await fetch(
					`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
					requestOptions
				);
				HandleSubscriberlist(profile.userProfileId._id);

				if (updatedResponse.ok) {
					const updatedItemDetails = await updatedResponse.json();
					setSelectedItemDetails(updatedItemDetails);
					setSelectedUserProfile(profile.userProfileId);
					message.success("Assignee updated successfully");
				} else {
					message.error("Failed to fetch updated item details");
				}
			} else {
				message.error("Failed to update assignee");
			}
		} catch (error) {
			console.error("Error updating assignee:", error);
			message.error("An error occurred while updating assignee");
		}

		setPopoverVisible(false);
	};

	const HandleSubscriberlist = async (id: any) => {
		try {
			const reqbody = {
				subscriberlist: [] as any,
			};

			const subscriberIds = selectedItemDetails.subscriberlist?.map(
				(subscriber: any) => subscriber._id.toString()
			);

			// Add the subscriber ID if it does not exist
			reqbody.subscriberlist = [...subscriberIds, id];

			const requestOptions = {
				method: "PUT",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(reqbody),
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				requestOptions
			);

			if (response.ok) {
				const data = await response.json();
			} else {
				console.error("Failed to update assignee");
			}
		} catch (error) {
			console.error("Error updating assignee:", error);
		}
	};

	if (type === "Menu") {
		return (
			<Popover
				title={null}
				placement="leftTop"
				content={
					loading ? (
						<div>Loading...</div>
					) : (
						<>
							<Input
								placeholder="Search"
								onChange={onChangeValue}
								allowClear
								suffix={<SearchOutlined />}
								key={"search"}
							/>
							<Menu
								style={{ width: 256, maxHeight: 300, overflowY: "auto" }}
								mode="vertical"
							>
								{filteredUserProfiles.length > 0 ? (
									filteredUserProfiles.map(
										(perm) =>
											perm.userProfileId?.name && (
												<Menu.Item
													key={perm.userProfileId?._id}
													icon={
														<Avatar style={{ backgroundColor: "#e3f0ff" }}>
															{perm.userProfileId?.name.charAt(0)}
														</Avatar>
													}
													onClick={() => handleMenuClick(perm)}
												>
													{perm.userProfileId?.name}
												</Menu.Item>
											)
									)
								) : (
									<Menu.Item disabled>No team members</Menu.Item>
								)}
							</Menu>
						</>
					)
				}
				trigger="click"
				visible={popoverVisible}
				onVisibleChange={setPopoverVisible}
			>
				<div style={{ textAlign: "center" }}>
					<Badge color="blue" offset={[-40, 0]}>
						<Button style={{ color: "black" }} type="link">
							{selectedItemDetails.assignee ? (
								<div style={{ display: "flex", alignItems: "center" }}>
									<span>Assignee : </span>
									<Avatar
										style={{ backgroundColor: "#e3f0ff", marginLeft: "0.8rem" }}
									>
										{selectedItemDetails.assignee.name.charAt(0)}
									</Avatar>
									<span style={{ marginLeft: "8px" }}>
										{selectedItemDetails.assignee.name}
									</span>
								</div>
							) : (
								"Assignee: NONE"
							)}
						</Button>
					</Badge>
				</div>
			</Popover>
		);
	}

	if (type === "Tag") {
		return (
			<Popover
				title={null}
				// placement="rightTop"
				content={
					loading ? (
						<div>Loading...</div>
					) : (
						<>
							<Input
								placeholder="Search"
								onChange={onChangeValue}
								allowClear
								suffix={<SearchOutlined />}
								key={"search"}
							/>
							<Menu
								style={{ width: 256, maxHeight: 300, overflowY: "auto" }}
								mode="vertical"
							>
								{filteredUserProfiles.length > 0 ? (
									filteredUserProfiles.map(
										(perm) =>
											perm.userProfileId?.name && (
												<Menu.Item
													key={perm.userProfileId?._id}
													icon={
														<Avatar style={{ backgroundColor: "#e3f0ff" }}>
															{perm.userProfileId?.name.charAt(0)}
														</Avatar>
													}
													onClick={() => handleMenuClick(perm)}
												>
													{perm.userProfileId?.name}
												</Menu.Item>
											)
									)
								) : (
									<Menu.Item disabled>No team members</Menu.Item>
								)}
							</Menu>
						</>
					)
				}
				trigger="click"
				visible={popoverVisible}
				onVisibleChange={setPopoverVisible}
			>
				<div style={{ textAlign: "center" }}>
					{selectedItemDetails.assignee ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<span>Assignee : </span>
							<span style={{ marginLeft: "8px" }}>
								{selectedItemDetails.assignee.name}
							</span>
						</div>
					) : (
						"Assignee: NONE"
					)}
				</div>
			</Popover>
		);
	}
};

export default UserProfileFilterComponent;
