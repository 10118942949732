import { StarOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { useLocation } from "react-router-dom";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { Modal } from "antd";
// import { useRequest } from "ahooks";
// import { getBookmark } from "../../api/BookmarkAPI";

const Bookmark = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	// const { bookmarks } = userProfileStore((state: any) => ({
	// 	bookmarks: state.bookmarks,
	//   }));
	const location = useLocation();
	const [isStarClicked, setIsStarClicked] = useState(false);
	const [bookmarkId, setBookmarkId] = useState(null);
	const [message, setMessage] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);

	// 	const fetchBookmarks = async () => {
	// 		console.log("current workspace", currentWorkspace._id);
	// 		console.log("helooooo");
	// 		try {
	// 			console.log("inside try");
	// 		  const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/bookmarks/${currentWorkspace._id}`,// Include the workspace ID in the URL
	// 			{
	// 			  method: "GET",
	// 			  headers: {
	// 				"Content-Type": "application/json",
	// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 			  },
	// 			}
	// 		  );
	// 		  console.log(response);
	// 		  if (!response.ok) {
	// 			throw new Error(`HTTP error! status: ${response.status}`);
	// 		  }
	// 		  if (response.status == 200) { // Check if the response is not empty
	// 			console.log("response is 200");

	// 				const data = await response.json();

	// 			console.log(data);
	// 			userProfileStoreInstance.setBookmarks(data); // Set the bookmarks in the store
	// 		  }

	// 		} catch (error) {
	// 		  console.error(error);

	// 		}
	// 	  };

	// useEffect(() => {
	// 	fetchBookmarks();
	// }, [currentWorkspace._id]);

	// const { data: bookMarkData, run: fetchBookmarks } = useRequest(
	// 	() => getBookmark(userProfileStoreInstance.idToken),
	// 	{
	// 		manual: true,
	// 		onSuccess: (data) => {
	// 			console.log(data);
	// 		},
	// 		onError: (error) => {
	// 			console.log(error);
	// 		},
	// 	}
	// );

	// useEffect(() => {
	// 	if (bookMarkData) {
	// 		userProfileStoreInstance.setBookmarks(bookMarkData);
	// 		setBookmarkId(bookMarkData[0]._id);
	// 	}
	// }, [currentWorkspace._id]);

	const fetchBookmarkId = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/bookmarks`, // replace with your API endpoint to fetch the bookmark ID
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const data = await response.json();
			console.log(data._id);

			setBookmarkId(data[0]._id);
			userProfileStoreInstance.setBookmarks(data);
			console.log(userProfileStoreInstance.bookmarks);
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		if (isStarClicked) {
			fetchBookmarkId(); // replace with your function to fetch the bookmark ID
		}
	}, [isStarClicked]); // Add isStarClicked as a dependency

	const deleteBookmark = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/bookmarks/${bookmarkId}`, // replace `bookmarkId` with the actual ID of the bookmark
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const data = await response.json();
			console.log(data);
			setIsStarClicked(false);
			setMessage("Deleted Successfully");
			setIsModalVisible(true);
			// Fetch the updated bookmarks from the database
			const fetchResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/bookmarks`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const updatedBookmarks = await fetchResponse.json();

			// Update the bookmarks in the userProfileStore
			userProfileStoreInstance.setBookmarks(updatedBookmarks);
		} catch (error) {
			console.error(error);
			setMessage("Error deleting bookmark");
			setIsModalVisible(true);
		}
	};

	const createBookmark = async () => {
		setIsStarClicked(true);

		console.log("currentWorkspace id", currentWorkspace._id);

		try {
			// Check if the bookmark already exists
			if (isStarClicked) {
				Modal.confirm({
					title: "Do you want to delete this bookmark?",
					onOk: () => deleteBookmark(), // Pass the bookmarkId as an argument
				});
			} else {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/bookmarks`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							title: "Bookmark1",
							url: location.pathname,
							workspaceId: currentWorkspace._id,
						}),
					}
				);
				const data = await response.json();
				console.log(data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			{/* <Alert message={message} type="success" showIcon /> */}
			<Modal
				title="Alert"
				visible={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
			>
				{message}
			</Modal>
			<StarOutlined
				style={{
					marginRight: "10px",
					fontSize: "1.1rem",
					color: isStarClicked ? "orange" : "inherit",
				}}
				onClick={() => createBookmark()}
			/>
		</div>
	);
};

export default Bookmark;
