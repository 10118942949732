/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Flex, Image, Layout } from "antd";
import { Outlet } from "react-router-dom";
import AppNavbar from "./AppNavbar";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
import AppHeader from "../layout/AppHeader";
import { Content } from "antd/es/layout/layout";
import ProfileMenu from "../pages/ProfileMenu";

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

const { Sider } = Layout;

function SettingsLayout() {
	const [abilities, setAbilities] = useState(ability);
	return (
		<AbilityContext.Provider value={abilities}>
			<Can I="SETTINGS_CAN_VIEW" a="SETTINGS_CAN_VIEW" passThrough>
				{(allowed) =>
					allowed ? (
						<Layout style={{ minHeight: "100vh", backgroundColor: "#FAFAFF" }}>
							<ProfileMenu />
							<Layout
								style={{
									backgroundColor: "#FAFAFF",
									justifyContent: "start",
								}}
							>
								<Content
									style={{
										height: "100vh",
										overflow: "hidden",
										paddingTop: ".2rem",
									}}
								>
									<div style={{ paddingTop: "15px" }}>
										<AppHeader />
									</div>
									<AppNavbar
										breadcrumbs={["Settings"]}
										menuType="settings"
										reviewEnabled={false}
										onYesClick={() => {}}
									/>
									<Outlet />
								</Content>
							</Layout>
						</Layout>
					) : (
						<Flex style={{ marginTop: "15vh" }} justify="center">
							<Image
								height={"50vh"}
								width={"60vw"}
								src={
									"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
								}
								preview={false}
							/>
						</Flex>
					)
				}
			</Can>
		</AbilityContext.Provider>
	);
}

export default SettingsLayout;
