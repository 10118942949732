import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import useWorkspaceStore from "../../stores/WorkspaceState";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import { useViewsStores } from "../../stores/BacklogStore";

import { UserOutlined } from "@ant-design/icons";

const BacklogDropdownMenu: React.FC<{
	open: boolean;
	pos: { x: number; y: number };
	selectedRec: any;
}> = ({ open, pos, selectedRec }) => {
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const { setSortBy, setRightClickMenu, rightClickMenu } = planerViewStore();

	const { setSelectedGroupBy } = useViewsStores();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [tags, setTags] = useState<any[]>([]);
	//const [selectedRecord, setSelectedRecord] = useState<any>(selectedRec);

	const items: MenuProps["items"] = [
		{
			key: "Status",
			label: "Status",
			children: [
				{
					key: "IN_PROGRESS",
					label: "In Progress",
				},
				{
					key: "COMPLETED",
					label: "Completed",
				},
			],
		},
		{
			key: "Assignee",
			label: "Assign to",
			children:
				tags &&
				tags.map((tag) => ({
					label: tag.userProfileId?.name,
					key: tag.userProfileId?._id,
				})),
		},
		{
			key: "GroupBy",
			label: "Group By",
			children: [
				{
					label: "Type",
					key: "1",
					icon: <UserOutlined />,
				},
				{
					label: "Priority",
					key: "2",
					icon: <UserOutlined />,
				},
				{
					label: "Status",
					key: "3",
					icon: <UserOutlined />,
				},
				{
					label: "None",
					key: "4",
					icon: <UserOutlined />,
				},
			],
		},
		{
			key: "ShortBy",
			label: "Sort By",
			children: [
				{
					label: "Created date (Asc)",
					key: "created-asc",
				},
				{
					label: "Created date (Desc)",
					key: "created-desc",
				},
			],
		},
	];

	const fetchUserProfile = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${currentWorkspace._id}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setTags(data);
				console.log(data);
			} else {
				console.error("Error fetching tags data");
			}
		} catch (error) {
			console.error("Error fetching tags data:", error);
		}
	};
	useEffect(() => {
		fetchUserProfile();
	}, []);

	const updateAssigne = async (
		item: any,
		selectedLabel: any,
		field: string
	) => {
		// console.log(item,"kjfkjerhgihergjherg", selectedLabel,"hfwkuehiuerrheri");
		console.log(field, "filed");

		const responce = {
			...item.assiginee,
			[field]: selectedLabel?.key,
		};
		console.log(responce, "response");

		try {
			const updateResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${item._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(responce),
				}
			);

			const updateResult = await updateResponse.json();

			if (updateResponse.ok) {
				console.log("Data updated successfully:", updateResult);

				setRightClickMenu(!rightClickMenu);
				// window.location.reload();
			} else {
				console.error("Failed to update data");
			}
		} catch (error) {
			console.error("Error updating data:", error);
		}
	};

	const onClick: MenuProps["onClick"] = ({ item, key, keyPath }) => {
		console.log(key, "key");
		console.log(items, "items");
		console.log(keyPath, "keyPath");
		console.log(item, "item");
		let category = "";
		let selectedLabel: any | null;
		if (keyPath.length > 1) {
			if (keyPath[0] === key) {
				category = keyPath[1];
			} else {
				category = keyPath[0];
			}
			const selectedCategory: any | null = items?.find(
				(menuItem) => menuItem?.key === category
			);
			selectedLabel = selectedCategory?.children?.find(
				(menuItem: any) => menuItem?.key === key
			);
		} else {
			selectedLabel = items?.find((menuItem) => menuItem?.key === key);
			if (key === "GroupBy") {
				console.log("delete");
			}
		}

		if (category === "Status") {
			console.log(selectedRec, "selectedRec");
			updateAssigne(selectedRec, selectedLabel, "status");
		} else if (category === "Assignee") {
			const field = "assignee";

			updateAssigne(selectedRec, selectedLabel, field);
		} else if (category === "GroupBy") {
			console.log(selectedLabel, "selectedLabel");
			setSelectedGroupBy(selectedLabel.label);
		} else if (category === "ShortBy") {
			console.log(selectedLabel, "selectedLabel");
			setSortBy(selectedLabel.label);
		}
	};

	const currentUser = userProfileStoreInstance.profile._id;

	// Check if the current user is the assignee of the selected record
	const isAssignee = selectedRec?.assigneeId === currentUser;

	// Render the dropdown menu items conditionally based on whether the current user is the assignee
	const filteredItems = items.map((item: any) => {
		if (item.key === "Status") {
			// If the current user is not the assignee, disable the "Status" options
			const children = item.children.map((child: any) => ({
				...child,
				disabled: !isAssignee,
			}));
			return {
				...item,
				children,
			};
		}
		return item;
	});

	return (
		<Dropdown
			menu={{ items: filteredItems, onClick }}
			open={open}
			overlayStyle={{ left: `${pos.x}px`, top: `${pos.y}px` }}
		>
			<></>
		</Dropdown>
	);
};

export default BacklogDropdownMenu;
