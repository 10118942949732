/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import {
	ArrowLeftOutlined,
	PlusCircleOutlined,
	UserAddOutlined,
} from "@ant-design/icons";
import {
	Modal,
	Form,
	Input as AntInput,
	Button,
	DatePicker,
	Radio,
	Card,
	Flex,
	Checkbox,
	Table,
	Tag,
	Tooltip,
	Breadcrumb,
	Space,
	Input,
	Avatar,
	Dropdown,
	Menu,
	Select,
	Typography,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { CalendarOutlined } from "@ant-design/icons";
import UpdateModal from "./RitualTaskUpdate";
import EditableCell from "./EditableCell";
import { useNavigate } from "react-router-dom";
//import RitualActivityTab from "./RitualActivityTab";
import RitualChatInterface from "./RitualDiscussionTab";
import AddChecklistItemModal from "./AddChecklistModel";
import { v4 as uuidv4 } from "uuid";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import RitualActivityTab from "./RitualActivityTab";
import RitualNotes from "./RitualNotes";
import LinkTabModel from "../LinkTabModel";
import RitualLink from "./RitualLink";
import { getRitualSession, updateRitualSession } from "../../../api/RitualApi";
import { useRequest } from "ahooks";
const { RangePicker } = DatePicker;
const { Title } = Typography;

const RitualView: React.FC<any> = () => {
	const [form] = Form.useForm();
	const [selectedRadio, setSelectedRadio] = useState<string | null>("radio1");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [updateModalVisible, setUpdateModalVisible] = useState(false);
	const [dataToSend, setDataToSend] = useState<any>(null);
	const [data, setData] = useState<any>();
	const [editedItemTitle, setEditedItemTitle] = useState<string>("");
	const [editMode, setEditMode] = useState<string | null>(null);
	const [openModal, setopenModal] = useState(false);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [tags, setTags] = useState<any[]>([]);

	const navigate = useNavigate();

	const { itemId } = useParams();

	console.log(itemId, "itemId");
	const [visible, setVisible] = useState(false);

	const handleAddChecklistItem = async (value: any) => {
		try {
			const id = uuidv4();

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/ritual-session/${itemId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						...data,
						checklist: [
							...data.checklist,
							{ title: value, status: "pending", _id: id },
						],
					}),
				}
			);

			if (response.ok) {
				// Refresh data after successfully adding checklist item
				fetchTodos();
				console.log("Checklist item added successfully:", value);
			} else {
				console.error("Failed to add checklist item");
			}
		} catch (error) {
			console.error("Error adding checklist item:", error);
		} finally {
			handleCancel();
		}
	};

	const handleOk = () => {
		setVisible(true);
	};
	const handleCancel = () => {
		setVisible(false);
	};

	const formItemLayout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const {
		data: RitualData,
		error: RitualError,
		loading: RitualLoading,
		run,
	} = useRequest(
		() => getRitualSession(itemId!, userProfileStoreInstance.idToken ?? ""),
		{
			manual: true,
			onSuccess: (result) => {
				setData(result);
				const doneChecklistItems = result.checklist.filter(
					(item: any) => item.status === "done"
				);
				const defaultChecklistValues = doneChecklistItems.map(
					(item: any) => item.title
				);
				form.setFieldsValue({
					checklist: defaultChecklistValues,
				});
				console.log(RitualData);
			},
			onError: (error) => {
				console.error("Error fetching todos:", error);
				console.log(RitualError);
			},
		}
	);

	const fetchTodos = () => {
		run();
	};

	useEffect(() => {
		fetchTodos();
	}, []);

	const fetchUserProfile = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${currentWorkspace._id}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setTags(data);
				console.log(data);
			} else {
				console.error("Error fetching tags data");
			}
		} catch (error) {
			console.error("Error fetching tags data:", error);
		}
	};
	useEffect(() => {
		fetchUserProfile();
	}, []);

	const handleRadioChange = (e: any) => {
		setSelectedRadio(e.target.value);
	};
	const handleDoubleClick = (item: any) => {
		setEditMode(item._id);
		setEditedItemTitle(item.title);
	};

	const {
		run: updateChecklistItem,
		loading: updateLoading,
		error: updateError,
	} = useRequest(
		async (updatedData: any) => {
			if (itemId) {
				await updateRitualSession(
					itemId,
					updatedData,
					userProfileStoreInstance.idToken ?? ""
				);
			} else {
				throw new Error("itemId is undefined");
			}
		},
		{
			manual: true,
			onSuccess: () => {
				fetchTodos();
			},
			onError: (error) => {
				console.error("Error updating checklist item status:", error);
			},
		}
	);

	const handleCheckboxChanges = async (
		title: string,
		checked: boolean,
		id: any
	) => {
		try {
			const updatedChecklist = data.checklist.map((item: any) =>
				item._id === id
					? { ...item, status: checked ? "done" : "pending", _id: id }
					: item
			);

			const updatedData = {
				...data,
				checklist: updatedChecklist,
			};

			updateChecklistItem(updatedData);
		} catch (error) {
			console.error("Error updating checklist item status:", error);
		}
	};
	const handleEnterKeyPress = async (
		e: React.KeyboardEvent<HTMLInputElement>,
		status: string,
		id: any
	) => {
		if (e.key === "Enter") {
			try {
				const updatedChecklist = data.checklist.map((item: any) =>
					item._id === id
						? { ...item, title: editedItemTitle, status: status }
						: item
				);

				const updatedData = {
					...data,
					checklist: updatedChecklist,
				};

				const {
					run: updateChecklistItem,
					loading: updateLoading,
					error: updateError,
				} = useRequest(
					() =>
						updateRitualSession(
							itemId!,
							updatedData,
							userProfileStoreInstance.idToken ?? ""
						),
					{
						manual: true,
						onSuccess: () => {
							fetchTodos(); // Re-fetch todos after successful update
							console.log("Checklist item updated successfully:", itemId);
						},
						onError: (error) => {
							console.error("Failed to update checklist item:", itemId, error);
						},
					}
				);

				updateChecklistItem(); // Trigger the update request
			} catch (error) {
				console.error("Error updating checklist item:", error);
			} finally {
				setEditMode(null);
			}
		}
	};
	useEffect(() => {
		fetchTodos();
	}, [itemId, updateModalVisible, visible]);

	useEffect(() => {
		// Set form values when data changes
		if (data) {
			form.setFieldsValue({
				type: data.type,
				title: data.title,
				TimeSlot: [
					moment(data.startDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
					moment(data.endDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
				],
				Agenda: data.description,
			});
		}
	}, [data, form]);

	const handleIconClick = (item: any) => {
		setUpdateModalVisible(true);
		setDataToSend(item);
	};

	const handleUpdateModalCancel = () => {
		setUpdateModalVisible(false);
	};

	{
		/*
	function removePTags(text: string) {
		return text.replace(/<p>/g, "").replace(/<\/p>/g, "");
	}
	*/
	}
	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			render: (text: any, item: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<EyeOutlined
						onClick={() => handleIconClick(item)}
						style={{ fontSize: "1.1rem", marginRight: "1vw" }}
					/>
					<a style={{ color: "black", fontWeight: "bold" }}>{text}</a>
				</div>
			),
		},
		// {
		// 	title: "Description",
		// 	dataIndex: "description",
		// 	key: "description",
		// 	render: (text: any) => (
		// 		<div style={{ display: "flex", alignItems: "center" }}>
		// 			<a
		// 				style={{ color: "black", fontWeight: "bold" }}
		// 				// dangerouslySetInnerHTML={{ __html: text }}
		// 			>
		// 				{removePTags(text)}
		// 			</a>
		// 		</div>
		// 	),
		// },

		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (type: any) => (
				<Tag color="yellow" style={{ color: "black" }}>
					{type}
				</Tag>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
	];
	const [selectedUserName, setSelectedUserName] = useState<string[]>([]);

	const handleMenuClick = (e: any) => {
		const clickedUserName = e.key;
		const selectedUserNamesCopy = [...selectedUserName];
		const isAlreadySelected = selectedUserNamesCopy.includes(clickedUserName);
		if (!isAlreadySelected) {
			selectedUserNamesCopy.push(clickedUserName);
		} else {
			const index = selectedUserNamesCopy.indexOf(clickedUserName);
			selectedUserNamesCopy.splice(index, 1);
		}
		setSelectedUserName(selectedUserNamesCopy);
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			{tags.map((member, index) => (
				<Menu.Item key={member?.userProfileId?.name || index}>
					{member?.userProfileId?.name}
				</Menu.Item>
			))}
		</Menu>
	);

	console.log("Menu:", menu);
	const handleNavigate = () => {
		navigate("/space/planner/rituals");
	};

	const formatDate = (dateString: any) => {
		return moment(dateString).format("D MMMM");
	};

	return (
		<Flex
			justify="center"
			style={{
				backgroundColor: "#EDF4FF",
				margin: "1rem",
				padding: "1rem",
				height: "83vh",
				maxWidth: "100vw",
			}}
			className="WorkspaceDiv"
		>
			<Card
				style={{
					width: "50%",
					marginBottom: "2rem",
					padding: "0rem 1rem 1rem 1rem",
				}}
			>
				<Flex align="center">
					<Button
						onClick={handleNavigate}
						shape="circle"
						icon={<ArrowLeftOutlined />}
						style={{ marginRight: "5px" }}
					/>
					{/* <Title
						level={2}
						style={{
							fontSize: "1.6rem",
							textAlign: "left",
							marginLeft: "10px",
							fontWeight: "bolder",
							textTransform: "capitalize",
						}}
					>
						</Title>
						{data?.title} {formatDate(data?.startDate)}-
						{formatDate(data?.endDate)}
					 */}

					<div>
						<Title
							level={2}
							style={{
								fontSize: "1.6rem",
								textAlign: "left",
								marginLeft: "12px",
								fontWeight: "bolder",
								textTransform: "capitalize",
								marginTop: "40px",
							}}
						>
							{data?.title}
						</Title>
						<div style={{ display: "flex", alignItems: "center" }}>
							{/* Use flex with align-items to ensure proper vertical alignment */}
							<Button type="text" icon={<CalendarOutlined />} />
							<p
								style={{
									color: "#6B7280", // Using hex color for gray
									marginLeft: "3px",
									marginTop: "0", // No top margin for proper alignment
								}}
							>
								{formatDate(data?.startDate)} - {formatDate(data?.endDate)}
							</p>
						</div>
					</div>
				</Flex>

				<Flex
					vertical
					style={{
						height: "55vh",
						marginTop: "2vh",
						marginLeft: "2rem",
					}}
				>
					<Flex vertical>
						<Flex>
							<Title style={{ color: "#5C92B8" }} level={2}>
								Agenda
							</Title>
						</Flex>
						<div
							style={{
								height: "17vh",
								overflowY: "auto",
							}}
							dangerouslySetInnerHTML={{ __html: data?.description }}
						></div>
					</Flex>
					<Flex vertical style={{ marginTop: "1rem" }}>
						<Flex align="center" gap={10}>
							<Title style={{ color: "#5C92B8" }} level={2}>
								CheckList
							</Title>
							<PlusCircleOutlined
								onClick={handleOk}
								style={{ fontSize: "1.8rem" }}
							/>
						</Flex>
						{data?.checklist && (
							<Flex
								style={{
									height: "20vh",
									overflow: "scroll",
								}}
								vertical
								gap={20}
							>
								<Form
									{...formItemLayout}
									form={form}
									style={{ maxWidth: "100%", width: "100%" }}
								>
									<Form.Item name={"checklist"} noStyle>
										<Checkbox.Group style={{ width: "100%" }}>
											<Flex
												style={{ width: "100%" }}
												vertical
												align="start"
												justify="start"
											>
												{data.checklist.map((item: any) => (
													<div
														key={item._id}
														style={{ display: "flex", alignItems: "center" }}
													>
														<div
															onDoubleClick={() => handleDoubleClick(item)}
															style={{ marginRight: "8px" }}
														>
															<Checkbox
																checked
																value={item.title}
																type="checkbox"
																onChange={(e) =>
																	handleCheckboxChanges(
																		item.title,
																		e.target.checked,
																		item._id
																	)
																}
															/>
														</div>
														<div>
															{editMode === item._id ? (
																<Input
																	value={editedItemTitle}
																	onChange={(e) =>
																		setEditedItemTitle(e.target.value)
																	}
																	onPressEnter={(e) =>
																		handleEnterKeyPress(
																			e,
																			item.status,
																			item._id
																		)
																	}
																	onBlur={() => setEditMode(null)}
																	autoFocus
																/>
															) : (
																<span
																	onDoubleClick={() => handleDoubleClick(item)}
																	style={{ cursor: "pointer" }}
																>
																	{item.title}
																</span>
															)}
														</div>
													</div>
												))}
											</Flex>
										</Checkbox.Group>
									</Form.Item>
								</Form>
							</Flex>
						)}
					</Flex>

					<UpdateModal
						visible={updateModalVisible}
						onCancel={handleUpdateModalCancel}
						data={dataToSend}
					/>
				</Flex>
			</Card>
			<Card
				style={{
					width: "50%",
					marginBottom: "2rem",
					padding: "1rem 1rem 1rem 1rem",
				}}
			>
				<Flex>
					<Radio.Group onChange={handleRadioChange}>
						<Radio.Button value="radio1">Note</Radio.Button>
						<Radio.Button value="radio2">Discussions</Radio.Button>
						<Radio.Button value="radio3">Activity</Radio.Button>
						<Radio.Button value="radio4">Links</Radio.Button>
					</Radio.Group>
				</Flex>
				<Flex justify="end">
					<Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
						<Button shape="circle">
							<UserAddOutlined />
						</Button>
					</Dropdown>
					<Avatar.Group maxCount={3}>
						{selectedUserName.map((name, index) => (
							<Tooltip title={name} key={index}>
								<Avatar>
									{name
										.split(" ")
										.map((n) => n[0])
										.join("")}
								</Avatar>
							</Tooltip>
						))}
						{Array.isArray(data?.teamMember) &&
							data.teamMember.map((member: any) => (
								<Tooltip title={member.name} key={member._id}>
									<Avatar>
										{member.name
											.split(" ")
											.map(
												(
													namePart: any[],
													index: number,
													array: string | any[]
												) =>
													index === 0 || index === array.length - 1
														? namePart[0]
														: null
											)
											.join("")}
									</Avatar>
								</Tooltip>
							))}
					</Avatar.Group>
				</Flex>

				<Flex style={{ height: "50vh", marginTop: "1rem" }}>
					{selectedRadio === "radio1" && (
						// <ReactQuill style={{ width: "100%", height: "100%" }} />
						<RitualNotes />
					)}
					{selectedRadio === "radio2" && data?.discussions && (
						<RitualChatInterface />
					)}
					{selectedRadio === "radio3" && data?.activity && (
						<RitualActivityTab />
					)}
					{/* {selectedRadio === "radio4" && <RitualLink openModal={false} setopenModal={function (value: React.SetStateAction<boolean>): void {
						throw new Error("Function not implemented.");
					} } />} */}
					{selectedRadio === "radio4" && itemId && (
						<RitualLink
							itemId={itemId}
							openModal={openModal}
							setopenModal={setopenModal}
						/>
					)}
				</Flex>
			</Card>
			<AddChecklistItemModal
				visible={visible}
				onCreate={handleAddChecklistItem}
				onCancel={handleCancel}
			></AddChecklistItemModal>
		</Flex>
	);
};

export default RitualView;
