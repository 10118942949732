import axios, { AxiosResponse } from "axios";
import { capicityPlan } from "../models/CapicityPlaning";

async function findByPlan(id: any, token: any): Promise<capicityPlan[]> {
	try {
		const response: AxiosResponse<capicityPlan[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/capacity-plan/plan/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching capicityPlaning:", error);
		throw error;
	}
}

async function createnewTeamMember(
	body: any,
	planID: any,
	token: any
): Promise<capicityPlan[]> {
	try {
		const response: AxiosResponse<capicityPlan[]> = await axios.put(
			`${process.env.REACT_APP_API_URL}/capacity-plan/team-members/${planID}/add`,
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching capicityPlaning:", error);
		throw error;
	}
}

async function updateTeamMember(
	planID: any,
	memberId: any,
	body: any,
	token: any
): Promise<capicityPlan[]> {
	try {
		const response: AxiosResponse<capicityPlan[]> = await axios.put(
			`${process.env.REACT_APP_API_URL}/capacity-plan/${planID}/team-members/${memberId}`,
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching capicityPlaning:", error);
		throw error;
	}
}

async function create(body: any, token: any): Promise<capicityPlan> {
	try {
		const response: AxiosResponse<capicityPlan> = await axios.post(
			`${process.env.REACT_APP_API_URL}/capacity-plan`,
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching capicityPlaning:", error);
		throw error;
	}
}
export { findByPlan, createnewTeamMember, updateTeamMember, create };
