import React, { useState, useEffect } from "react";
import {
	Divider,
	//Button,
	Flex,
	Form,
	Input,
	Modal,
	Space,
} from "antd";
import useWorkspaceStore from "../stores/WorkspaceState";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import BreadCrumb from "./Editors/BreadCrumb";
import breadcrumbConfig from "../util/BreadCrumbsUtil";
import { motion } from "framer-motion";
import { EditOutlined } from "@ant-design/icons";

function PlanTypeManagementNav({
	openPlanTypeModal,
	setopenPlanTypeModal,
}: {
	openPlanTypeModal: boolean;
	setopenPlanTypeModal: React.Dispatch<React.SetStateAction<boolean>>;
}): any {
	const [prefixType, setPrefixType] = useState("");
	const [typeWarning, setTypeWarning] = useState(false);
	const [valueWarning, setValueWarning] = useState(false);
	const [prefixValue, setPrefixValue] = useState("");
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	useEffect(() => {
		const isPrefixTypeValid = prefixType.length > 0 && !typeWarning;
		const isPrefixValueValid = prefixValue.length <= 3 && !valueWarning;
		setIsSaveButtonDisabled(
			!(isPrefixTypeValid && isPrefixValueValid && prefixType && prefixValue)
		);
	}, [prefixType, prefixValue, typeWarning, valueWarning]);

	const handleAddPlanType = () => {
		const APIUrl = `${process.env.REACT_APP_API_URL}/planconfig`;
		const data = {
			type: prefixType,
			prefix: prefixValue,
			workspaceid: currentWorkspace._id,
			lastnumber: 0,
		};
		fetch(APIUrl, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Success:", data);
				setopenPlanTypeModal(false);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const handlePrefixTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		const hasLowercase = /[a-z]/.test(value);
		setTypeWarning(hasLowercase);
		if (!hasLowercase) {
			setPrefixType(value.toUpperCase().replace(/[^A-Z]/g, ""));
		}
	};

	const handlePrefixValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		const hasLowercase = /[a-z]/.test(value);
		const isTooLong = value.length > 3;
		setValueWarning(hasLowercase || isTooLong);
		if (!hasLowercase) {
			setPrefixValue(value.toUpperCase().replace(/[^A-Z]/g, ""));
		}
	};

	const [isHovered, setIsHovered] = useState(false);
	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		// <Flex
		// 	justify="space-between"
		// 	align="center"
		// 	style={{
		// 		padding: "20px 50px 20px 50px",
		// 		margin: "4px 0 0px",
		// 		backgroundColor: "rgb(245, 248, 254)",
		// 	}}
		// >
		// 	<Flex align="center" gap={16}>
		<div>
			<Divider className="ant-divider-horizontal" />
			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex style={{ alignItems: "center", marginLeft: "2vw" }}>
						<Space>
							<Flex className="breadcrumb">
								<BreadCrumb config={breadcrumbConfig.planmanagement} />
							</Flex>
						</Space>
					</Flex>
					<>
						{/* <Button type="primary" onClick={() => setopenPlanTypeModal(true)}>
					Add Type
				</Button> */}
						<motion.div
							initial="initial"
							animate={isHovered ? "hover" : "initial"}
							variants={buttonVariants}
							transition={{ duration: 0.3 }}
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							className="add-work-item-button"
							onClick={() => setopenPlanTypeModal(true)}
						>
							<EditOutlined />
							{isHovered && <span style={{ marginLeft: "8px" }}>Add Type</span>}
						</motion.div>

						<Modal
							title="Add Type"
							visible={openPlanTypeModal}
							onCancel={() => setopenPlanTypeModal(false)}
							onOk={handleAddPlanType}
							okButtonProps={{ disabled: isSaveButtonDisabled }}
						>
							<Form layout="horizontal">
								<Form.Item
									label="Type"
									required
									style={{ width: "100%" }}
									labelCol={{ span: 6 }}
									wrapperCol={{ span: 18 }}
								>
									<Input value={prefixType} onChange={handlePrefixTypeChange} />
									{typeWarning && (
										<div style={{ color: "red" }}>
											Only capital letters are allowed
										</div>
									)}
								</Form.Item>
								<Form.Item
									label="Prefix"
									required
									style={{ width: "100%" }}
									labelCol={{ span: 6 }}
									wrapperCol={{ span: 18 }}
									validateStatus={valueWarning ? "error" : ""}
								>
									<Input
										value={prefixValue}
										onChange={handlePrefixValueChange}
									/>
									{valueWarning && (
										<div style={{ color: "red" }}>
											Only capital letters and it must be 3 characters long
										</div>
									)}
								</Form.Item>
							</Form>
						</Modal>
					</>
				</Flex>
			</div>
		</div>
	);
}

export default PlanTypeManagementNav;
