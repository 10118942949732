/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import {
	//CrownFilled,
	BulbOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Layout, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
// import AccountStore, { AccountStoreType } from "../stores/AccountStore";
import usePlatformStore from "../stores/platformStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
import welcome from "../assets/welcome.jpg";

const { Paragraph } = Typography;

function NewWorkspace() {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const navigate = useNavigate();
	const { threadId } = useParams();
	const { Content } = Layout;

	const [isTaskCompleted, setIsTaskCompleted] = useState(false);
	const [abilities, setAbilities] = useState(ability);

	const { setBackNavigateUrl, setNextNavigateUrl } = usePlatformStore(
		(state: any) => ({
			setBackNavigateUrl: state.setBackNavigateUrl,
			setNextNavigateUrl: state.setNextNavigateUrl,
		})
	);
	useEffect(() => {
		setNextNavigateUrl("/");
		setBackNavigateUrl("/");
	}, []);

	useEffect(() => {
		if (isTaskCompleted === true) {
			navigate("/load");
		}
	}, []);

	const { setAddWorkspaceDetails } = useWorkspaceStore((state: any) => ({
		setAddWorkspaceDetails: state.setAddWorkspaceDetails,
	}));

	const handleStartIdeaClick = () => {
		setAddWorkspaceDetails(null);
		setBackNavigateUrl("/fd/workspace/new");
		navigate(`/fd/chat/idea-refinement`);
	};

	return (
		<AbilityContext.Provider value={abilities}>
			<Layout style={{ backgroundColor: "#F5F8FE" }}>
				<Content
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "calc(100vh - 12rem)",
					}}
				>
					<Card style={{ width: "50%", marginTop: "52px" }}>
						<div className="workspaceLayoutContent">
							<div style={{ textAlign: "center", marginBottom: "1rem" }}>
								<img
									src={welcome}
									style={{
										overflow: "hidden",
										marginTop: "0%",
										maxHeight: "100%",
										maxWidth: "100%",
									}}
								/>
								<h2>Start by telling your idea</h2>
								<p>
									It&apos;s like laying down the brick for a digital castle,
									only without all the heavy lifting.
								</p>
							</div>
							<Flex justify="center" gap="small">
								<Button onClick={handleStartIdeaClick} type="primary">
									Start with your Idea <BulbOutlined />
								</Button>
							</Flex>
						</div>
					</Card>
				</Content>
				<Flex
					className="footer"
					justify="center"
					style={{
						width: "100%",
						position: "absolute",
						bottom: "0",
						margin: "1rem 0 0px 0",
					}}
				>
					<p style={{ fontSize: 12, fontWeight: 500 }}>
						© 2024 Zentience. All rights reserved.
					</p>
				</Flex>
			</Layout>
		</AbilityContext.Provider>
	);
}
export default NewWorkspace;
