import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Avatar, Space } from "antd";
import {
	LikeOutlined,
	MessageOutlined,
	UserOutlined,
	CloseOutlined,
	DislikeOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../DiscussionTab.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
//import { planerViewStore } from "../../../stores/PlannerStore";
import { useParams } from "react-router-dom";
import moment from "moment";

// interface Message {
// 	// _id:number;
//     replies: any[];
//     id: number;
//     content: string;
//     username: string;
//     type: "main" | "reply";
// }

const RitualChatInterface: React.FC = () => {
	//const { selectedItemDetails } = planerViewStore();
	const [inputValue, setInputValue] = useState<string>("");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [messages, setMessages] = useState<any[]>([]);
	const [replyMode, setReplyMode] = useState<{
		active: boolean;
		messageId: number | null;
	}>({ active: false, messageId: null });
	const [replyModeReply, setReplyModeReply] = useState<{
		active: boolean;
		messageId: number | null;
	}>({ active: false, messageId: null });
	const [replyContent, setReplyContent] = useState<string>("");
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const { itemId } = useParams();
	const [activeReactions, setActiveReactions] = useState<{
		[key: number]: string;
	}>({});

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		}
	};

	const fetchMessages = async () => {
		try {
			console.log(itemId, "itemId");
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/discussions/${itemId}/Rituals`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();

				console.log(data, "data");
				setMessages(data);
			} else {
				console.error("Failed to fetch messages");
			}
		} catch (error) {
			console.error("Error fetching messages:", error);
		}
	};

	useEffect(() => {
		fetchMessages();
	}, []);

	const handleMessageSend = async () => {
		if (inputValue.trim() !== "") {
			const newMessage = {
				// _id:messages[0]._id,
				id: messages.length,
				content: inputValue.trim(),
				username: userProfileStoreInstance.profile.name,
				replies: [],
				type: "main",
			};
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/discussions`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							type: "main",
							mentions: [],
							attachments: [],
							content: inputValue.trim(),
							parentMessageId: null,
							createdBy: userProfileStoreInstance.profile.name,
							threadId: null,
							parentObjectId: [{ type: "Rituals", id: itemId }],

							reactions: [{ type: null, createdBy: null, createdAt: null }],
							resolved: false,
							resolvedBy: null,
							resolvedAt: null,
						}),
					}
				);
				if (response.ok) {
					const data = await response.json();
					newMessage.id = data._id;
					setMessages([...messages, newMessage]);
				} else {
					console.error("Failed to post message");
				}
			} catch (error) {
				console.error("Error posting message:", error);
			}
			setInputValue("");
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleMessageSend();
			setReplyMode({ ...replyMode, active: false });
		}
	};

	const handleKeyDownReply = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleReplySend();
			setReplyModeReply({ ...replyModeReply, active: false });
			setReplyMode({ ...replyMode, active: false });
		}
	};

	const handleReplyClick = (messageId: number) => {
		setReplyMode({ active: true, messageId });
	};

	const handleCancelReply = () => {
		setReplyMode({ active: false, messageId: null });
		setReplyModeReply({ active: false, messageId: null });
	};

	const handleReplyChange = (value: string) => {
		setReplyContent(value);
	};

	const handleReplySend = async () => {
		if (replyContent.trim() !== "") {
			const newReply = {
				// _id:messages._id,
				id: messages.length, // This should be replaced with the ID received from the backend
				content: replyContent.trim(),
				username: userProfileStoreInstance.profile.name,
				replies: [],
				type: "reply",
			};
			try {
				let parentMessageId: number | null = null;
				if (replyMode.active && replyMode.messageId !== null) {
					parentMessageId = replyMode.messageId;
				} else if (replyModeReply.active && replyModeReply.messageId !== null) {
					parentMessageId = replyModeReply.messageId;
				}

				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/discussions`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							type: "reply",
							mentions: [],
							attachments: [],
							content: replyContent.trim(),
							createdBy: userProfileStoreInstance.profile.name,
							parentMessageId: parentMessageId,
							parentObjectId: [{ type: "Rituals", id: itemId }],
							threadId: parentMessageId,
							reactions: [{ type: null, createdBy: null, createdAt: null }],
							resolved: false,
							resolvedBy: null,
							resolvedAt: null,
						}),
					}
				);
				if (response.ok) {
					const data = await response.json();
					newReply.id = data._id; // Assuming backend returns the ID of the created reply

					// Find the index of the parent message
					const parentMessageIndex = messages.findIndex(
						(msg) => msg.id === parentMessageId
					);

					if (parentMessageIndex !== -1) {
						// Update the replies array of the parent message
						messages[parentMessageIndex].replies = [
							...(messages[parentMessageIndex].replies || []),
							newReply,
						];

						// Update the state with the modified messages array
						setMessages([...messages]);

						// Clear reply content after sending
						setReplyContent("");
					}
				} else {
					console.error("Failed to post reply");
					// Handle error
				}
			} catch (error) {
				console.error("Error posting reply:", error);
				// Handle error
			}
		}
	};

	const handleReaction = async (messageId: number, reactionType: string) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/discussions/${messageId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						reactions: [
							{
								type: reactionType,
								createdBy: userProfileStoreInstance.profile.name,
								createdAt: new Date().toISOString(),
							},
						],
					}),
				}
			);
			if (response.ok) {
				// Update the reactions locally if the request is successful
				const updatedMessages = messages.map((message) => {
					if (message.id === messageId) {
						return {
							...message,
							reactions: [
								...message.reactions,
								{
									type: reactionType,
									createdBy: userProfileStoreInstance.profile.name,
									createdAt: new Date().toISOString(),
								},
							],
						};
					}
					return message;
				});
				setMessages(updatedMessages);
				setActiveReactions({ ...activeReactions, [messageId]: reactionType });
			} else {
				console.error(`Failed to ${reactionType} message`);
			}
		} catch (error) {
			console.error(`Error ${reactionType} message:`, error);
		}
	};

	return (
		<div style={{ maxWidth: "655px", marginLeft: "70px", textAlign: "left" }}>
			<ReactQuill
				value={inputValue}
				onChange={setInputValue}
				placeholder="Type your message..."
				onKeyDown={handleKeyDown}
			/>
			<Card style={{ border: "none", height: "40vh", overflow: "scroll" }}>
				{messages.map(
					(message) =>
						message.content && (
							<div key={message.id} style={{ marginBottom: "10px" }}>
								<Card
									ref={messagesEndRef}
									style={{
										borderRadius: "0px",
										width: "75%",
										float: message.type === "main" ? "left" : "right",
										boxShadow:
											(replyMode.active &&
												replyMode.messageId === message.id) ||
											(replyModeReply.active &&
												replyModeReply.messageId === message.id)
												? "0 2px 4px rgba(0.1,0.1,0.1,0.2)"
												: "0 4px 4px -2px rgba(0, 0, 0, 0.1)",
										backgroundColor:
											(replyMode.active &&
												replyMode.messageId === message.id) ||
											(replyModeReply.active &&
												replyModeReply.messageId === message.id)
												? "#f0f0f0"
												: "white",
										marginBottom: "10px",
									}}
									actions={[
										<div key={message.id}>
											<div
												dangerouslySetInnerHTML={{ __html: message.content }}
												style={{
													textAlign: "left",
													marginLeft: "1.5vw",
													color: "black",
												}}
											/>
										</div>,
									]}
								>
									<Card.Meta
										title={
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<Space style={{ marginLeft: "1rem" }}>
													<Avatar
														style={{ backgroundColor: "#EAF0FC" }}
														icon={<UserOutlined style={{ color: "#3478F6" }} />}
													/>
													<p
														style={{
															color: "black",
															fontWeight: "normal",
															margin: 0,
														}}
													>
														{message.username || message.createdBy}
													</p>
												</Space>
												<Space>
													<Button
														icon={<LikeOutlined />}
														type={
															activeReactions[message.id] === "like"
																? "primary"
																: "default"
														}
														onClick={() => handleReaction(message._id, "like")}
													/>
													<Button
														icon={<DislikeOutlined />}
														type={
															activeReactions[message.id] === "dislike"
																? "primary"
																: "default"
														}
														onClick={() =>
															handleReaction(message._id, "dislike")
														}
													/>
													{!replyMode.active && (
														<Button
															icon={<MessageOutlined />}
															onClick={() => handleReplyClick(message.id)}
															style={{ marginLeft: "8px" }}
														/>
													)}
													{replyMode.active &&
														replyMode.messageId === message.id && (
															<Button
																icon={<CloseOutlined />}
																onClick={handleCancelReply}
																style={{ marginLeft: "8px" }}
															/>
														)}
													<p
														style={{
															color: "black",
															fontWeight: "normal",
															margin: 0,
														}}
													>
														{moment(message.createdAt).fromNow()}
													</p>
												</Space>
											</div>
										}
									/>
								</Card>
								{replyMode.active && replyMode.messageId === message.id && (
									<ReactQuill
										style={{ width: "90%" }}
										value={replyContent}
										onChange={handleReplyChange}
										onKeyDown={handleKeyDownReply}
										placeholder="Reply to this message..."
									/>
								)}
								{message.replies &&
									message.replies.map((reply: any) => (
										<div
											key={reply.id}
											style={{
												float: message.type === "main" ? "left" : "right",
												clear: "both",
												textAlign: message.type === "main" ? "left" : "right",
												width: "90%",
												marginRight: "10%",
											}}
										>
											<Card
												ref={messagesEndRef}
												style={{
													borderRadius: "0px",
													width: "90%",
													float: "right",
													boxShadow:
														replyModeReply.active &&
														replyModeReply.messageId === message.id
															? "0 2px 4px rgba(0.1,0.1,0.1,0.2)"
															: "0 4px 4px -2px rgba(0, 0, 0, 0.1)",
													backgroundColor:
														replyModeReply.active &&
														replyModeReply.messageId === message.id
															? "#f0f0f0"
															: "white",
													marginBottom: "10px",
												}}
												actions={[
													<div key={reply.id}>
														<div
															dangerouslySetInnerHTML={{
																__html: reply.content,
															}}
															style={{
																textAlign: "left",
																marginLeft: "1.5vw",
																color: "black",
															}}
														/>
													</div>,
												]}
											>
												<Card.Meta
													title={
														<div
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
															}}
														>
															<Space style={{ marginLeft: "1rem" }}>
																<Avatar
																	style={{ backgroundColor: "#EAF0FC" }}
																	icon={
																		<UserOutlined
																			style={{ color: "#3478F6" }}
																		/>
																	}
																/>
																<p
																	style={{
																		color: "black",
																		fontWeight: "normal",
																		margin: 0,
																	}}
																>
																	{reply.username || reply.createdBy}
																</p>
															</Space>
															<Space>
																<Button
																	icon={<LikeOutlined />}
																	onClick={() =>
																		handleReaction(message._id, "like")
																	}
																/>
																<Button
																	icon={<DislikeOutlined />}
																	onClick={() =>
																		handleReaction(message._id, "dislike")
																	}
																/>
																<p
																	style={{
																		color: "black",
																		fontWeight: "normal",
																		margin: 0,
																	}}
																>
																	{moment(message.createdAt).fromNow()}
																</p>
															</Space>
														</div>
													}
												/>
											</Card>
										</div>
									))}
							</div>
						)
				)}
			</Card>
		</div>
	);
};

export default RitualChatInterface;
