export const AdminPermissions = {
	SETTINGS_CAN_VIEW: "SETTINGS_CAN_VIEW",
	FEATURE_ENGINEERING_CAN_VIEW: "FEATURE_CAN_VIEW",
	FEATURE_ENGINEERING_CAN_REVIEW: "FEATURE_CAN_REVIEW",
	WORKSPACE_CAN_CREATE: "WORKSPACE_CAN_CREATE",
	DESIGN_ENGINEERING_CAN_VIEW: "DESIGN_CAN_VIEW",
	DESIGN_ENGINEERING_CAN_REVIEW: "DESIGN_CAN_REVIEW",
};

export const ContributorPermissions = {
	FEATURE_ENGINEERING_CAN_VIEW: "FEATURE_CAN_VIEW",
	FEATURE_ENGINEERING_CAN_REVIEW: "FEATURE_CAN_REVIEW",
	DESIGN_ENGINEERING_CAN_VIEW: "DESIGN_CAN_VIEW",
	DESIGN_ENGINEERING_CAN_REVIEW: "DESIGN_CAN_REVIEW",
};

export const GuestPermissions = {
	FEATURE_ENGINEERING_CAN_VIEW: "FEATURE_CAN_VIEW",
	DESIGN_ENGINEERING_CAN_VIEW: "DESIGN_CAN_VIEW",
};

export const user1permissions = {
	FEATURE_ENGINEERING_CAN_VIEW: "FEATURE_CAN_VIEW",
	FEATURE_ENGINEERING_CAN_REVIEW: "FEATURE_CAN_REVIEW",
	DESIGN_ENGINEERING_CAN_VIEW: "DESIGN_CAN_VIEW",
	DESIGN_ENGINEERING_CAN_REVIEW: "DESIGN_CAN_REVIEW",
};

export const user2permissions = {
	FEATURE_ENGINEERING_CAN_VIEW: "FEATURE_CAN_VIEW",
	FEATURE_ENGINEERING_CAN_REVIEW: "FEATURE_CAN_REVIEW",
	DESIGN_ENGINEERING_CAN_VIEW: "DESIGN_CAN_VIEW",
};

export const SubscriptionPermissions = {
	accountName: "",
	subscriptionLimits: {
		"number of projets": {
			type: "number",
			subject: "user",
			value: 1,
		},
		features: {
			type: "enable",
			subject: "review-feature",
			value: true,
		},
	},
};
