import React, { useEffect, useState } from "react";
import "./PlanerHeader.css";
import {
	CheckCircleOutlined,
	DownOutlined,
	EditOutlined,
	// UnorderedListOutlined,
	//UserOutlined,
	// AppstoreFilled,
	SearchOutlined,
	SwapOutlined,
	UserOutlined,
	WarningOutlined,
	//ArrowLeftOutlined,
} from "@ant-design/icons";
import {
	Flex,
	Dropdown,
	Button,
	Space,
	Input,
	// TreeSelect,

	//Tooltip,
	//Breadcrumb,
	//Radio,
	MenuProps,
	// Select,
	// Tooltip,
	Menu,
	Divider,
} from "antd";

import { useViewsStores } from "../../stores/BacklogStore";

import { planerViewStore } from "../../stores/PlannerStore";

import { useLocation, useNavigate } from "react-router-dom";
// import Constants from "../../util/ConstantsUtil";
// import WorkItemModal from "./AddWorkitemModel";
import { motion } from "framer-motion";
import { Can } from "../../layout/AppLayout";
import Display from "./Display_button/Display";
// import FilterComponent from "./Filter_button/Filter";
import UniversalComponent from "./Filter_button/UniversalFilter";
import Constants from "../../util/ConstantsUtil";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import BreadCrumb from "../Editors/BreadCrumb";

// const { Option } = Select;

const modeConfig = {
	filters: {
		status: {
			label: "Status",
			options: [
				{
					key: Constants.STATUS + "_" + Constants.IN_PROGRESS,
					label: Constants.IN_PROGRESS,
				},
				{ key: Constants.STATUS + "_" + Constants.OPEN, label: Constants.OPEN },
				{
					key: Constants.STATUS + "_" + Constants.COMPLETED,
					label: Constants.COMPLETED,
				},
			],
			icon: <CheckCircleOutlined />,
		},
		priority: {
			label: "Priority",
			options: [
				{
					key: Constants.PRIORITY + "_" + Constants.URGENT,
					label: Constants.URGENT,
				},
				{
					key: Constants.PRIORITY + "_" + Constants.IMPORTANT,
					label: Constants.IMPORTANT,
				},
				{
					key: Constants.PRIORITY + "_" + Constants.MEDIUM,
					label: Constants.MEDIUM,
				},
				{ key: Constants.PRIORITY + "_" + Constants.LOW, label: Constants.LOW },
			],
			icon: <WarningOutlined />,
		},
		type: {
			label: "Type",
			options: [
				{ key: "Type_AllItems", label: "All Items" },
				{ key: "Type_FEATURE", label: "Features" },
				{ key: "Type_USER_STORY", label: "User Stories" },
				{ key: "Type_BUG", label: "Bugs" },
				{ key: "Type_EPIC", label: "Epic" },
			],
			icon: <SwapOutlined />,
		},
	},
};

function BacklogNav() {
	const url = useLocation().pathname;
	const navigate = useNavigate();
	const {
		BacklogFilterValue,
		setBacklogFilterValue,
		setBacklogSelectedFilter,
	} = useViewsStores();
	// console.log(url);

	// const [modalVisible, setModalVisible] = useState(false);

	const {
		setListView,
		setCardView,
		setRowBy,
		RowBy,
		selectedGroupBy,
		CardOrderning,
		setDocsView,
		setCardOrderning,
		// setListView,
		// setCardView,
		isCardView,
		input,
		setInput,
		// setfilter,
		setSelectedGroupBy,
		backlogworkitemmodel,
		setbacklogworkitemmodel,
		ViewFilter,
		ListSelectedGroupBy,
		setListSelectedGroupBy,
	} = useViewsStores();
	const { setSortBy } = planerViewStore();
	const { setRadioButtonSelected } = planerViewStore();

	// const treeData = [
	// {
	// 	value: Constants.TYPE, // change to CAPS
	// 	title: Constants.TYPE,
	// 	children: [
	// 		{
	// 			value: Constants.TYPE + "_" + Constants.FEATURE,
	// 			title: Constants.FEATURE,
	// 		},
	// 		{
	// 			value: Constants.TYPE + "_" + Constants.USER_STORY,
	// 			title: Constants.USER_STORY,
	// 		},
	// 	],
	// },
	// {
	// 	value: Constants.PRIORITY, // change to CAPS
	// 	title: Constants.PRIORITY,
	// 	children: [
	// 		// {
	// 		// 	value: Constants.KANO, // change to CAPS
	// 		// 	title: Constants.KANO,
	// 		// 	children: [
	// 		// 		{
	// 		// 			value: Constants.CAPS_PRIORITY_BASIC_NEEDS,
	// 		// 			title: Constants.PRIORITY_BASIC_NEEDS,
	// 		// 		},
	// 		// 		{
	// 		// 			value: Constants.CAPS_PRIORITY_PERFORMANCE_NEEDS,
	// 		// 			title: Constants.PRIORITY_PERFORMANCE_NEEDS,
	// 		// 		},
	// 		// 		{
	// 		// 			value: Constants.CAPS_PRIORITY_EXCITEMENT_NEEDS,
	// 		// 			title: Constants.PRIORITY_EXCITEMENT_NEEDS,
	// 		// 		},
	// 		// 	],
	// 		// },
	// 		// {
	// 		// 	value: Constants.MOSCOW, // change to CAPS
	// 		// 	title: Constants.MOSCOW,
	// 		// 	children: [
	// 		// 		{
	// 		// 			value: Constants.CAPS_PRIORITY_MUST_HAVE,
	// 		// 			title: Constants.MUST_HAVE,
	// 		// 		},
	// 		// 		{
	// 		// 			value: Constants.CAPS_PRIORITY_SHOULD_HAVE,
	// 		// 			title: Constants.SHOULD_HAVE,
	// 		// 		},
	// 		// 		{
	// 		// 			value: Constants.CAPS_PRIORITY_COULD_HAVE,
	// 		// 			title: Constants.COULD_HAVE,
	// 		// 		},
	// 		// 		{
	// 		// 			value: Constants.CAPS_PRIORITY_WONT_HAVE,
	// 		// 			title: Constants.WONT_HAVE,
	// 		// 		},
	// 		// 	],
	// 		// },
	// 		{
	// 			value: Constants.PRIORITY + "_" + Constants.URGENT,
	// 			title: Constants.URGENT,
	// 		},
	// 		{
	// 			value: Constants.PRIORITY + "_" + Constants.IMPORTANT,
	// 			title: Constants.IMPORTANT,
	// 		},
	// 		{
	// 			value: Constants.PRIORITY + "_" + Constants.MEDIUM,
	// 			title: Constants.MEDIUM,
	// 		},
	// 		{
	// 			value: Constants.PRIORITY + "_" + Constants.LOW,
	// 			title: Constants.LOW,
	// 		},
	// 	],
	// },
	// {
	// 	value: Constants.STATUS, //change to CAPS
	// 	title: Constants.STATUS,
	// 	children: [
	// 		{
	// 			value: Constants.STATUS + "_" + Constants.IN_PROGRESS,
	// 			title: Constants.IN_PROGRESS,
	// 		},
	// 		{
	// 			value: Constants.STATUS + "_" + Constants.OPEN,
	// 			title: Constants.OPEN,
	// 		},
	// 		{
	// 			value: Constants.STATUS + "_" + Constants.COMPLETED,
	// 			title: Constants.COMPLETED,
	// 		},
	// 		// {
	// 		// 	value: Constants.STATUS + "_" + Constants.PENDING,
	// 		// 	title: Constants.PENDING,
	// 		// },
	// 	],
	// },
	// ];

	// const [value, setValue] = useState<any[]>([]);

	const isBacklogsPage = url === "/space/planner/backlogs";

	useEffect(() => {
		if (isBacklogsPage) {
			setRadioButtonSelected("AllItems");
		}
	}, [isBacklogsPage, setRadioButtonSelected]);

	// const onChange = (newValue: any[]) => {
	// 	const filteredValues = newValue.filter((val: any) => {
	// 		return !treeData.some((item) => item.value === val);
	// 	});
	// 	setValue(filteredValues);
	// 	setfilter(filteredValues);
	// };

	// const handleRadioChange = (value: string) => {
	// 	// setValue([]);
	// 	setfilter([]);
	// 	setRadioButtonSelected(value);
	// };

	// const handleChange = (event: any) => {
	// 	setInput(event.target.value);
	// };

	const handleAddWorkItemClick = () => {
		setbacklogworkitemmodel(!backlogworkitemmodel);
	};

	const itemss = [
		{
			label: "Type",
			key: "1",
			icon: <UserOutlined />,
		},
		{
			label: "Priority",
			key: "2",
			icon: <UserOutlined />,
		},
		{
			label: "Status",
			key: "3",
			icon: <UserOutlined />,
		},
		{
			label: "None",
			key: "4",
			icon: <UserOutlined />,
		},
	];

	const handleGroupByChange = ({ key }: { key: React.Key }) => {
		console.log(key);
		const selectedItem = itemss.find((item) => item.key === key);
		if (selectedItem) {
			setSelectedGroupBy(selectedItem.label);
		}
	};
	const items: MenuProps["items"] = [
		{
			key: "1",
			label: (
				<a onClick={() => navigate("/space/prioritization")}>Prioritization</a>
			),
		},
		// {
		// 	key: "2",
		// 	label: <a onClick={() => navigate("/fd/workspace/settings")}>Settings</a>,
		// },
	];

	const [isSearchExpanded, setIsSearchExpanded] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const handleSearchChange = (event: any) => {
		setInput(event.target.value);
		if (event.target.value === "") {
			setIsSearchExpanded(false);
		}
	};

	const handleSearchIconClick = () => {
		setIsSearchExpanded(true);
	};

	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		<div>
			<Divider className="ant-divider-horizontal" />
			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex style={{ alignItems: "center", marginLeft: "2vw" }}>
						<Space>
							{/* <Tooltip placement="bottom" title="Back">
								<Button
									shape="circle"
									icon={<ArrowLeftOutlined />}
									size="large"
								/>
							</Tooltip> */}
							<Flex className="breadcrumb">
								{/* <Breadcrumb>
									<Breadcrumb.Item>
										<h3
											color="#87d068"
											style={{ fontSize: "1.1rem", fontFamily: "Poppins" }}
										>
											Backlogs
										</h3>
									</Breadcrumb.Item>
								</Breadcrumb> */}
								<BreadCrumb config={breadcrumbConfig.backlog} />
							</Flex>
							{/* <FilterComponent /> */}
							<UniversalComponent
								config={modeConfig}
								filterValue={BacklogFilterValue}
								setFilterValue={setBacklogFilterValue}
								setSelectedFilter={setBacklogSelectedFilter}
								ViewFilter={ViewFilter}
							/>
						</Space>
					</Flex>
					<Flex style={{ alignItems: "center", marginLeft: "3vw" }}>
						<Space></Space>
					</Flex>
					<Flex justify="end" gap={"small"} style={{ alignItems: "center" }}>
						{/* <Select
							onChange={handleRadioChange}
							defaultValue={`AllItems`}
							size="middle"
							style={{ width: "10vw", fontFamily: "Poppins", height: "5vh" }}
						>
							<Option value={`AllItems`} defaultChecked>
								All Items
							</Option>
							<Option value={`FEATURE`}>Features</Option>
							<Option value={`USER STORY`}>User Stories</Option>
							<Option value={`BUG`}>Bugs</Option>
							<Option value={`EPIC`}>Epic</Option>
						</Select> */}
						{/* <Input
							suffix={<SearchOutlined />}
							size="middle"
							style={{
								height: "35px",
								// width: "14vw",
								width: "10vw",
								fontSize: "0.9rem",
								fontFamily: "Poppins",
							}}
							placeholder="Search"
							onChange={handleChange}
							value={input}
							allowClear
						/> */}
						{isSearchExpanded ? (
							<Input
								suffix={<SearchOutlined />}
								size="small"
								// style={{
								// 	height: "24px",
								// 	width: "10vw",
								// 	fontSize: "0.9rem",
								// 	fontFamily: "Poppins",
								// }}
								className="search-input"
								placeholder="Search"
								onChange={handleSearchChange}
								value={input}
								allowClear
								autoFocus
								onBlur={() => {
									if (input === "") setIsSearchExpanded(false);
								}}
							/>
						) : (
							<SearchOutlined
								className="search-icon"
								onClick={handleSearchIconClick}
								// onMouseEnter={() => setIsSearchExpanded(true)}
							/>
						)}
						{/* <Dropdown
							overlay={
								<Menu onClick={handleGroupByChange}>
									{items.map((item) => (
										<Menu.Item key={item.key} icon={item.icon}>
											{item.label}
										</Menu.Item>
									))}
								</Menu>
							}
							trigger={["click"]}
						>
							<Button size="middle" shape="round">
								<Space>
									Group by
									{selectedGroupBy === "Release" &&
									url === "/space/planner/backlogs"
										? "None"
										: selectedGroupBy}
									<DownOutlined />
								</Space>
							</Button>
						</Dropdown> */}

						{/* <Flex>
							<TreeSelect
								showSearch
								size="small"
								style={{
									width: "10vw",
									fontFamily: "Poppins",
									height: value.length > 1 ? `${value.length * 30}px` : "5vh",
								}}
								value={value}
								dropdownStyle={{
									maxHeight: 400,
									overflow: "auto",
									borderRadius: "1.25rem",
								}}
								placeholder={
									<span style={{ color: "black", fontFamily: "Poppins" }}>
										Filter By
									</span>
								}
								allowClear
								multiple
								treeDefaultExpandAll={false}
								onChange={onChange}
								treeData={treeData}
							/>
						</Flex> */}

						{/* <Flex gap={10}>
							<Tooltip placement="bottom" title="List View">
								<Button
									shape="circle"
									icon={<UnorderedListOutlined />}
									size="middle"
									size="small"
									type="text"
									style={{ fontSize: "1.2rem" }}
									onClick={setListView}
									style={{ color: "#0444BF", borderColor: "#0444BF" }}
								>
									<UnorderedListOutlined />
								</Button>
							</Tooltip>
							<Tooltip placement="bottom" title="Card View">
								<Button
									shape="circle"
									icon={<AppstoreFilled />}
									size="middle"
									size="small"
									type="text"
									style={{ fontSize: "1.2rem" }}
									onClick={setCardView}
									style={{ color: "#0444BF", borderColor: "#0444BF" }}
								>
									<AppstoreFilled />
								</Button>
							</Tooltip>
						</Flex> */}
						{isCardView && (
							<Dropdown
								overlay={
									<Menu onClick={handleGroupByChange}>
										{itemss.map((item) => (
											<Menu.Item key={item.key} icon={item.icon}>
												{item.label}
											</Menu.Item>
										))}
									</Menu>
								}
								trigger={["click"]}
							>
								<Button
									size="middle"
									shape="round"
									className="card-view-dropdown"
								>
									<Space>
										Group by
										<DownOutlined />
									</Space>
								</Button>
							</Dropdown>
						)}
						<Dropdown
							menu={{
								items,
								style: {
									borderRadius: "6px",
									maxHeight: "175px",
									height: "40px",
									// lineHeight: "35px"
								},
							}}
							trigger={["click"]}
							overlayStyle={{ backgroundColor: "white", borderRadius: "6px" }}
						>
							<Button
								icon={<DownOutlined style={{ color: "#FCA311" }} />}
								size="small"
								shape="round"
								style={{
									borderRadius: "6px",
									color: "#FCA311",
									borderColor: "#FCA311",
									backgroundColor: "white",
								}}
								className="actions-dropdown-button"
							>
								<Space>{`Actions`}</Space>
							</Button>
						</Dropdown>
						{/* <Button
							size="middle"
							shape="round"
							type="primary"
							onClick={handleAddWorkItemClick}
						>
							Add Work Item
						</Button> */}
						<Display
							setListView={setListView}
							setCardView={setCardView}
							setSelectedGroupBy={setSelectedGroupBy}
							isCardView={isCardView}
							setRowBy={setRowBy}
							RowBy={RowBy}
							selectedGroupBy={selectedGroupBy}
							CardOrderning={CardOrderning}
							setDocsView={setDocsView}
							setCardOrderning={setCardOrderning}
							setSortBy={setSortBy}
							DocView={true}
							ListSelectedGroupBy={ListSelectedGroupBy}
							setListSelectedGroupBy={setListSelectedGroupBy}
						/>
						<Can I="create" a="backlog" passThrough>
							{(allowed) =>
								allowed ? (
									// <motion.button
									// 	type="button"
									// 	style={{
									// 		fontFamily: "Poppins",
									// 		fontSize: "14px",
									// 		backgroundColor: "#0444BF",
									// 		borderRadius: "20px",
									// 		padding: "8px",
									// 		color: "white",
									// 		borderColor: "white",
									// 		//width: "10%",
									// 		minWidth: "10vw",
									// 	}}
									// 	onClick={handleAddWorkItemClick}
									// 	whileTap={{ scale: 0.8, backgroundColor: "#032c81" }}
									// >
									// 	Add Work Item
									// </motion.button>

									// <Button
									// 	style={{
									// 		overflow: "hidden",
									// 		backgroundColor: "#0444bf",
									// 		fontFamily: "Poppins",
									// 	}}
									// 	type="primary"
									// 	onClick={handleAddWorkItemClick}
									// >
									// 	Add Work Item
									// </Button>

									<motion.div
										initial="initial"
										animate={isHovered ? "hover" : "initial"}
										variants={buttonVariants}
										transition={{ duration: 0.3 }}
										onMouseEnter={() => setIsHovered(true)}
										onMouseLeave={() => setIsHovered(false)}
										// style={{
										//     display: "flex",
										//     justifyContent: "center",
										//     alignItems: "center",
										//     backgroundColor: "#0444BF",
										//     borderRadius: "6px",
										// 	marginRight:".5vw",
										//     padding: "8px",
										//     color: "white",
										//     borderColor: "white",
										//     overflow: "hidden",
										//     cursor: "pointer",
										// 	whiteSpace: "nowrap",
										// }}
										className="add-work-item-button"
										onClick={handleAddWorkItemClick}
									>
										<EditOutlined />
										{isHovered && (
											<span style={{ marginLeft: "8px" }}>Add Work Item</span>
										)}
									</motion.div>
								) : null
							}
						</Can>
					</Flex>
				</Flex>

				{/* <WorkItemModal visible={modalVisible} onCancel={handleModalCancel} /> */}
			</div>
		</div>
	);
}
export default BacklogNav;
