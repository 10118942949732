import axios, { AxiosResponse } from "axios";
import { WorkItem } from "../models/WorkItem";

async function getWorkItemsById(id: any, token: any): Promise<WorkItem> {
	try {
		const response: AxiosResponse<WorkItem> = await axios.get(
			`${process.env.REACT_APP_API_URL}/workitems/Id/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}
async function getWorkItemsbyQuery(
	currentWorkspaceId: any,
	query: any,
	token: any
): Promise<WorkItem[]> {
	try {
		const response: AxiosResponse<WorkItem[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/workitems/workspace/${currentWorkspaceId}`,

			{
				params: {
					title: query,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

async function getWorkItemSForMYTask(
	currentWorkspaceId: any,
	type: any,
	assigned: any,
	token: any
): Promise<WorkItem[]> {
	try {
		const response: AxiosResponse<WorkItem[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/workitems/MyTasks/${type}/${currentWorkspaceId}/${assigned}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

async function fetchWorkitemByType(
	currentWorkspaceId: any,
	type: any,
	token: any
): Promise<WorkItem[]> {
	try {
		const response: AxiosResponse<WorkItem[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/workitems/types/${type}/${currentWorkspaceId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

async function fetchWorkitemwithnotask(
	currentWorkspace: any,
	token: any
): Promise<WorkItem[]> {
	try {
		const response: AxiosResponse<WorkItem[]> = await axios.get(
			`/workitems/notask/${currentWorkspace}`,
			{
				headers: {
					Authorization: `Bearer ${token.idToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

async function fetchWorkItemByWorkspaceId(
	workspaceId: number,
	token: any
): Promise<WorkItem> {
	try {
		const response: AxiosResponse<WorkItem> = await axios.get(
			`${process.env.REACT_APP_API_URL}/workitems/${workspaceId}`,
			{
				headers: {
					Authorization: `Bearer ${token.idToken}`,
				},
			}
		);

		return response.data;
	} catch (error) {
		console.error(`Error fetching work item with ID ${workspaceId}:`, error);
		throw error;
	}
}

// Create a method to create a new work item
async function createWorkItem(workItem: WorkItem): Promise<WorkItem> {
	try {
		const response: AxiosResponse<WorkItem> = await axios.post("/", workItem);
		return response.data;
	} catch (error) {
		console.error("Error creating work item:", error);
		throw error;
	}
}

async function getWorkitemByFilter(
	workspaceId: any,
	workItem: any,
	token: any
): Promise<WorkItem[]> {
	try {
		const response: AxiosResponse<WorkItem[]> = await axios.post(
			`${process.env.REACT_APP_API_URL}/workitems/filter/${workspaceId}`,
			workItem,
			{
				headers: {
					Authorization: `Bearer ${token.idToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

async function getWorkitemByTaskFilter(
	workspaceId: any,
	workItem: any,
	token: any
): Promise<WorkItem[]> {
	try {
		const response: AxiosResponse<WorkItem[]> = await axios.post(
			`${process.env.REACT_APP_API_URL}/workitems/filter/task/${workspaceId}`,
			workItem,
			{
				headers: {
					Authorization: `Bearer ${token.idToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}
async function getSelectedWorkItems(
	workItem: any,
	token: any
): Promise<WorkItem[]> {
	try {
		const response: AxiosResponse<WorkItem[]> = await axios.post(
			`${process.env.REACT_APP_API_URL}/workitems/selected`,
			workItem,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

// Create a method to update an existing work item
async function updateWorkItem(
	id: number,
	workItem: any,
	token: any
): Promise<WorkItem> {
	try {
		const response: AxiosResponse<WorkItem> = await axios.put(
			`${process.env.REACT_APP_API_URL}/workitems/${id}`,
			workItem,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error(`Error updating work item with ID ${id}:`, error);
		throw error;
	}
}

// Create a method to delete a work item by ID
async function deleteWorkItem(id: number): Promise<void> {
	try {
		await axios.delete(`/${id}`);
	} catch (error) {
		console.error(`Error deleting work item with ID ${id}:`, error);
		throw error;
	}
}

async function fetchAllChildren(token: any, id: string): Promise<WorkItem[]> {
	try {
		const response: AxiosResponse<WorkItem[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/workitems/child/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token.idToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

export {
	getWorkItemsById,
	getSelectedWorkItems,
	getWorkItemsbyQuery,
	getWorkItemSForMYTask,
	fetchWorkitemByType,
	fetchWorkitemwithnotask,
	fetchAllChildren,
	fetchWorkItemByWorkspaceId,
	createWorkItem,
	updateWorkItem,
	deleteWorkItem,
	getWorkitemByFilter,
	getWorkitemByTaskFilter,
};
