/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	ApartmentOutlined,
	ArrowLeftOutlined,
	BugFilled,
	CheckCircleOutlined,
	CloseCircleOutlined,
	CodepenCircleFilled,
	DownOutlined,
	EllipsisOutlined,
	EyeOutlined,
	FilterFilled,
	GoldenFilled,
	SlidersFilled,
	SwapOutlined,
	ToolOutlined,
	WarningOutlined,
} from "@ant-design/icons";
import {
	Avatar,
	Button,
	Card,
	Collapse,
	Dropdown,
	Flex,
	Input,
	Layout,
	List,
	Menu,
	// Menu,
	MenuProps,
	Popconfirm,
	Space,
	Tag,
	Typography,
	message,
	// message,
} from "antd";
// import "./FeaturePrioritization/KanoPrioritization.css";

import { useEffect, useState } from "react";

import SubMenu from "antd/es/menu/SubMenu";
import { usePlanStore } from "../../stores/PlanStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import PlansNav from "./PlansNav";
import { useSearchParams } from "react-router-dom";
import PlanViewNav from "./PlanViewNav";
import { set } from "lodash";
import FilterViewPlan from "./Filter_button/FilterViewPlan";
import UniversalComponent from "./Filter_button/UniversalFilter";
import Constants from "../../util/ConstantsUtil";
import { useRequest } from "ahooks";
import {
	fetchWorkitemwithnotask,
	getWorkitemByFilter,
	updateWorkItem,
} from "../../api/WorkItemAPI";

const { Content, Header } = Layout;
const { Title } = Typography;
const { Search } = Input;

const modeConfig = {
	filters: {
		status: {
			label: "Status",
			options: [
				{
					key: Constants.STATUS + "_" + Constants.IN_PROGRESS,
					label: Constants.IN_PROGRESS,
				},
				{ key: Constants.STATUS + "_" + Constants.OPEN, label: Constants.OPEN },
				{
					key: Constants.STATUS + "_" + Constants.COMPLETED,
					label: Constants.COMPLETED,
				},
			],
			icon: <CheckCircleOutlined />,
		},
		priority: {
			label: "Priority",
			options: [
				{
					key: Constants.PRIORITY + "_" + Constants.URGENT,
					label: Constants.URGENT,
				},
				{
					key: Constants.PRIORITY + "_" + Constants.IMPORTANT,
					label: Constants.IMPORTANT,
				},
				{
					key: Constants.PRIORITY + "_" + Constants.MEDIUM,
					label: Constants.MEDIUM,
				},
				{ key: Constants.PRIORITY + "_" + Constants.LOW, label: Constants.LOW },
			],
			icon: <WarningOutlined />,
		},
		type: {
			label: "Type",
			options: [
				{ key: "Type_AllItems", label: "All Items" },
				{ key: "Type_FEATURE", label: "Features" },
				{ key: "Type_USER_STORY", label: "User Stories" },
				{ key: "Type_BUG", label: "Bugs" },
				{ key: "Type_EPIC", label: "Epic" },
			],
			icon: <SwapOutlined />,
		},
	},
};

function PlanView() {
	const [data, setData] = useState<any>();
	const [tags, setTags] = useState<any>();
	const [plan, setPlan] = useState<any>();
	const [Menudata, setMenudata] = useState<any[]>([]);
	const [filterid, setfilterid] = useState<any[]>([]);
	const [filtereddata, setfiltereddata] = useState<any[]>([]);
	const [searchvalue, setsearchvalue] = useState<string>("");
	const [filterstatus, setfilterstatus] = useState<string>("");
	const [filterstatuslist, setfilterstatuslist] = useState<string>("");
	const [filterstatuscard, setfilterstatuscard] = useState<string>("");
	const [searchParams] = useSearchParams();
	const [listFiltervalue, setListFiltervalue] = useState<string>("");
	const [cardFiltervalue, setCardFiltervalue] = useState<string>("");
	const planId = searchParams.get("planId");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const {
		selectedGroupBy,
		planvalue,
		urlData,
		isCardView,
		FetchPlan,
		setFetchPlan,
		ReleasePlanValue,
		changeUrl,
		PlanSelectedFilter,
		PlanFilterValue,
		setPlanFilterValue,
		setPlanSelectedFilter,
	} = usePlanStore();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	// const fetchTodos = async () => {
	// 	try {
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/workitems/notask/${currentWorkspace?._id}`,
	// 			{
	// 				method: "GET",
	// 				headers: {
	// 					"Content-Type": "application/json; charset=UTF-8",
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 			}
	// 		);

	// 		if (response.ok) {
	// 			const result = await response.json();

	// 			const filteredResult = result.filter(
	// 				(item: { type: string }) => item.type !== "task"
	// 			);
	// 			setData(filteredResult);
	// 		} else {
	// 			console.error("Failed to fetch todos");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error fetching todos:", error);
	// 	}
	// };

	const { run: fetchTodos } = useRequest(fetchWorkitemwithnotask, {
		manual: true,
		onSuccess: (result) => {
			const filteredResult = result.filter((item) => item.type !== "task");
			setData(filteredResult);
			message.success("Todos fetched successfully");
		},
		onError: (error) => {
			console.error("Error fetching todos:", error);
			message.error("Failed to fetch todos");
		},
	});

	const fetchRelease = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${currentWorkspace?._id}?type=All`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData: any[] = await response.json();
				const filterTodos: any[] = todosData.filter((todo: any) => {
					return todo._id === planId;
				});
				setPlan(filterTodos[0]);

				setTodos(filterTodos);
				setMenudata(filterTodos);
				const ids = filterTodos
					.map((todo) => {
						return todo.items.map((item: any) => item._id);
					})
					.flat();
				setfilterid(ids);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	const { data: plandata, run: fetchworkitembyfilter } = useRequest(
		() =>
			getWorkitemByFilter(
				currentWorkspace._id,
				PlanFilterValue,
				userProfileStoreInstance
			),
		{
			manual: true,
		}
	);

	useEffect(() => {
		if (plandata) {
			const filteredResult = plandata.filter(
				(item: { type: string }) => item.type !== "task"
			);
			setData(filteredResult);
		}
	}, [plandata]);

	useEffect(() => {
		fetchworkitembyfilter();
	}, [PlanSelectedFilter]);

	useEffect(() => {
		const datafilter = data?.filter(
			(itemer: any) => !filterid?.includes(itemer._id)
		);
		setfiltereddata(datafilter);
	}, []);

	useEffect(() => {
		const datafilter = data?.filter(
			(itemer: any) => !filterid?.includes(itemer._id)
		);
		setfiltereddata(datafilter);
	}, [
		urlData.screen,
		urlData.newUrl,
		isCardView,
		data,
		urlData.prevUrl,
		FetchPlan,
		filterid,
	]);

	useEffect(() => {
		fetchRelease();
		// fetchTodos(currentWorkspaceId, userProfileStoreInstance);
	}, [ReleasePlanValue, FetchPlan]);

	const [todos, setTodos] = useState<any[]>([]);

	function groupBy(array: any[], key: string) {
		return array.reduce((result: any, currentItem: any) => {
			const groupKey = currentItem[key];
			if (!result[groupKey]) {
				result[groupKey] = [];
			}
			result[groupKey].push(currentItem);
			return result;
		}, {});
	}

	const setGroupKey = (selectedGroupBy: string) => {
		switch (selectedGroupBy) {
			case "Type":
				return "bug";
			case "Priority":
				return "priority";
			case "Status":
				return "status";
			case "release":
				return "release";
			default:
				return "name";
		}
	};

	const groupKey = setGroupKey("Release");
	const groupedTasks = groupBy(todos, groupKey);

	useEffect(() => {
		const fetchTagsData = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					setTags(data);
				} else if (response.status === 401) {
					setUnauthorizedModel(true);
				} else {
					console.error("Error fetching tags data");
				}
			} catch (error) {
				console.error("Error fetching tags data:", error);
			}
		};

		fetchTagsData();
	}, []);

	const [column, setColumn] = useState<any[]>([]);

	const setColumnState: () => any = () => {
		const column = groupBy(todos, "kano");
		const columns = Object.entries(column).map(([groupKey]) => ({
			id: groupKey,
			title: `${groupKey} `,
		}));
		setColumn(columns);
	};

	useEffect(() => {
		setColumnState();
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	useEffect(() => {
		changeUrl(
			"/space/planner/featureplanner",
			"/space/planner/featureplans",
			"Feature Plan"
		);
	}, []);

	const { run: runUpdateWorkItem } = useRequest(
		(id, workItem) =>
			updateWorkItem(id, workItem, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.success(`Work item updated successfully, ${result.title}`);
			},
			onError: (error) => {
				message.error(`Failed to update work item:", ${error}`);
			},
		}
	);

	const { run: runUpdateWorkItemDates } = useRequest(
		(id, workItem) =>
			updateWorkItem(id, workItem, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.success(
					`Work item dates updated successfully:", start date: ${result.startDate} and end date ${result.endDate}`
				);
				fetchRelease();
				setFetchPlan(!FetchPlan);
			},
			onError: (error) => {
				message.error(`Failed to update work item dates:",${error}`);
			},
		}
	);

	const handleMenuClick = async (e: any, item: any) => {
		const resultdata = Menudata?.filter((data) => {
			return data._id === e.key;
		});

		resultdata[0].items = resultdata[0].items.map((item: any) => {
			return item._id;
		});

		resultdata[0].items.push(item._id);

		try {
			// Assuming there is an API endpoint for updating data
			const updateResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${e?.key}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(resultdata[0]),
				}
			);

			const updateResult = await updateResponse.json();

			if (updateResponse.ok) {
				fetchRelease();
				setFetchPlan(!FetchPlan);
				// Additional logic or state updates can be done here
			} else {
				console.error("Failed to update data");
			}
		} catch (error) {
			console.error("Error updating data:", error);
		}

		// try {
		// 	// Assuming there is an API endpoint for updating data
		// 	const updatePLanWorkitem = await fetch(
		// 		`${process.env.REACT_APP_API_URL}/workitems/${item._id}`,
		// 		{
		// 			method: "PUT",
		// 			headers: {
		// 				"Content-Type": "application/json; charset=UTF-8",
		// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
		// 			},
		// 			body: JSON.stringify({ planIds: planId }),
		// 		}
		// 	);

		// 	const updateResults = await updatePLanWorkitem.json();

		// 	if (updatePLanWorkitem.ok) {
		// 		console.log("Data updated successfully:", updateResults);
		// 	} else {
		// 		console.error("Failed to update data");
		// 	}
		// } catch (error) {
		// 	console.error("Error updating data:", error);
		// }

		await runUpdateWorkItem(item._id, { planIds: planId });

		if (item.startDate === undefined || item.endDate === undefined) {
			const payload = {
				...item,
				startDate: resultdata[0].startdate,
				endDate: resultdata[0].enddate,
			};

			await runUpdateWorkItemDates(item._id, payload);

			// try {
			// 	const updateWorkItemResponse = await fetch(
			// 		`${process.env.REACT_APP_API_URL}/workitems/${item._id}`, // Update with your actual endpoint
			// 		{
			// 			method: "PUT",
			// 			headers: {
			// 				"Content-Type": "application/json; charset=UTF-8",
			// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			// 			},
			// 			body: JSON.stringify(payload),
			// 		}
			// 	);

			// 	const updateResult = await updateWorkItemResponse.json();

			// 	if (updateWorkItemResponse.ok) {
			// 		console.log("Work item updated successfully:", updateResult);
			// 		// Additional logic or state updates can be done here
			// 		fetchRelease();
			// 		setFetchPlan(!FetchPlan);
			// 	} else {
			// 		console.error("Failed to update work item");
			// 	}
			// } catch (error) {
			// 	console.error("Error updating work item:", error);
			// }
		}
	};

	const truncateDescription = (description: string, wordCount: number) => {
		const words = description.split(" ");
		if (words.length > wordCount) {
			return words.slice(0, wordCount).join(" ") + "...";
		}
		return description;
	};

	const FilterValues = (value: any) => {
		const newvalues = value?.toLowerCase();
		setsearchvalue(newvalues);
	};

	const FilterValuesList = (value: any) => {
		const newvalues = value?.toLowerCase();
		setListFiltervalue(newvalues);
	};

	const FilterValuesCard = (value: any) => {
		const newvalues = value?.toLowerCase();
		setCardFiltervalue(newvalues);
	};

	const sortItems = [
		{
			label: "Open",
			key: "OPEN",
		},
		{
			label: "Completed",
			key: "COMPLETED",
		},
		{
			label: "In Progress",
			key: "In progress",
		},
		{
			label: "None",
			key: "",
		},
	];

	const handleSortByChange = ({ key }: { key: string }) => {
		setfilterstatus(key.toLowerCase() as any);
	};

	const handleSortByChangelist = ({ key }: { key: string }) => {
		setfilterstatuslist(key.toLowerCase() as any);
	};

	const handleSortByChangecard = ({ key }: { key: string }) => {
		setfilterstatuscard(key.toLowerCase() as any);
	};

	const FilterSearchValudata = (data: any) => {
		const newdata = data?.filter(
			(test: any) =>
				(test.title?.toLowerCase().includes(searchvalue) ||
					test.description?.toLowerCase().includes(searchvalue) ||
					test?.WorkitemId?.toLowerCase().includes(searchvalue)) &&
				test?.status?.toLowerCase().replace(/_/g, " ").includes(filterstatus)
		);
		return newdata;
	};

	const FilterSearchValudataList = (data: any) => {
		const newdata = data?.filter(
			(test: any) =>
				(test.title?.toLowerCase().includes(listFiltervalue) ||
					test.description?.toLowerCase().includes(listFiltervalue) ||
					test?.WorkitemId?.toLowerCase().includes(listFiltervalue)) &&
				test?.status
					?.toLowerCase()
					.replace(/_/g, " ")
					.includes(filterstatuslist)
		);
		return newdata;
	};

	const FilterSearchValudataCard = (data: any) => {
		const newdata = data?.filter(
			(test: any) =>
				(test.title?.toLowerCase().includes(cardFiltervalue) ||
					test.description?.toLowerCase().includes(cardFiltervalue) ||
					test?.WorkitemId?.toLowerCase().includes(cardFiltervalue)) &&
				test?.status
					?.toLowerCase()
					.replace(/_/g, " ")
					.includes(filterstatuscard)
		);
		return newdata;
	};

	function removePTags(text: string) {
		return text?.replace(/<p>/g, "").replace(/<\/p>/g, "");
	}

	const RemoveWorkitem = async (WorkitemId: any) => {
		try {
			const updateWorkItemResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${planId}/${WorkitemId._id}`, // Update with your actual endpoint
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			// const updateResult = await updateWorkItemResponse.json();

			if (updateWorkItemResponse.ok) {
				// console.log("Work item updated successfully:", updateResult);
				// Additional logic or state updates can be done here
				fetchRelease();
				setFetchPlan(!FetchPlan);
			} else {
				console.error("Failed to update work item");
			}
		} catch (error) {
			console.error("Error updating work item:", error);
		}
	};

	function formatAssigneeName(assignee: any) {
		if (!assignee || !assignee.name) {
			return "";
		}

		const nameParts = assignee.name.split(" ");
		let formattedName = "";

		if (nameParts.length > 0) {
			formattedName += nameParts[0].charAt(0).toUpperCase();
		}

		if (nameParts.length > 1 && nameParts[1]) {
			formattedName += nameParts[1].charAt(0).toUpperCase();
		}

		return formattedName;
	}

	return (
		<>
			<Layout style={{ background: "#FAFBFF" }}>
				<PlanViewNav title={plan ? plan.name : ""} screen={"planView"} />
				<Content style={{ backgroundColor: "#F5F8FE", padding: "2rem" }}>
					<Flex style={{ height: "90vh" }}>
						{isCardView ? (
							<Flex
								style={{
									borderRadius: "1.25rem 1.25rem 0rem 1.25rem",
									borderTop: "2px solid #bed2fc",
									borderLeft: "2px solid #bed2fc",
									borderRight: "2px solid #bed2fc",
									background: "#fff",
									padding: "0.5rem",
									overflowY: "auto",
									backgroundColor: "white",
									overflow: "scroll",
									height: "70vh",
									width: "50vw",
									// display: "block",
									// position: "relative",
									marginRight: "0.5rem",
									animation: "fadeIn 2s ease-in-out",
								}}
							>
								<Flex vertical style={{ width: "50vw" }} key={1}>
									<Flex
										justify="center"
										style={{
											fontWeight: "bold",
											fontSize: "1.3rem",
											margin: "1rem",
										}}
									>
										Backlogs
									</Flex>
									<Flex
										justify="space-around"
										align="center"
										style={{ marginBottom: "1rem" }}
									>
										<Search
											placeholder="input search text"
											allowClear
											style={{ width: "33vw" }}
											onChange={(e) => FilterValuesCard(e.target.value)}
										/>
										<Dropdown
											overlay={
												<Menu>
													{sortItems.map((item) => (
														<Menu.Item
															onClick={() => handleSortByChangecard(item)}
															key={item.key}
														>
															{item.label}
														</Menu.Item>
													))}
												</Menu>
											}
											trigger={["click"]}
										>
											<FilterFilled />
										</Dropdown>
									</Flex>
									<Flex justify="center" className="ColumnTitle">
										Unassigned
									</Flex>
									<Flex vertical style={{ overflow: "scroll", height: "90vh" }}>
										{FilterSearchValudataCard(filtereddata)
											// .filter((item: any) => item.name === col)
											// ?.filter((itemer: any) => !filterid?.includes(itemer._id))
											?.map((task: any) => (
												<Dropdown
													key={task.id}
													overlay={
														// <SubMenu key="sub1" icon={<ApartmentOutlined />} title="Plans">
														<Menu onClick={(e) => handleMenuClick(e, task)}>
															{Menudata.map((menuItem) =>
																menuItem ? (
																	<Menu.Item key={menuItem._id}>
																		{menuItem.name}
																	</Menu.Item>
																) : null
															)}
														</Menu>
														// </SubMenu>
													}
													trigger={["contextMenu"]}
												>
													<Card className="KanoCard" key={task.id}>
														<Flex align="start">
															{/* <div
																style={{
																	color: col.color,
																	fontSize: "1.2rem",
																}}
															>
																{task.icon}
															</div> */}
															<Space direction="vertical">
																<Flex justify="space-between">
																	<div style={{ fontWeight: "bold" }}>
																		{task.title}
																	</div>
																	{/* <div style={{ fontWeight: "bold" }}>
																		{task.story}
																	</div> */}
																</Flex>
																<Flex className="kanoCardDescription">
																	{truncateDescription(task.description, 10)}
																	{/* {task.description.split(' ').slice(0, 20).join(' ')}
													{task.description.split(' ').length == 20 && '...'} */}
																</Flex>
															</Space>
														</Flex>
														<Flex
															justify="space-between"
															style={{ marginTop: "0.5rem" }}
														>
															<Tag
																// color={col.color}
																style={{
																	color: "black",
																	fontSize: "0.8rem",
																	width: "fit-content",
																	textAlign: "center",
																}}
															>
																{task.priority}
															</Tag>
															<div style={{ width: "4vw" }}>
																{/* <Tag
																	style={{
																		color: task.colour,
																		fontSize: "0.8rem",
																		textAlign: "center",
																	}}
																>
																	{task.bug}
																</Tag> */}
															</div>
															<div
																style={{
																	fontSize: "0.8rem",
																	fontWeight: "bold",
																	// color: col.color,
																}}
															>
																Status
															</div>
														</Flex>
													</Card>
												</Dropdown>
											))}
									</Flex>
								</Flex>
							</Flex>
						) : (
							<Flex
								style={{
									// width: "28%",
									borderRadius: "1.25rem 1.25rem 0rem 1.25rem",
									borderTop: "2px solid #bed2fc",
									borderLeft: "2px solid #bed2fc",
									borderRight: "2px solid #bed2fc",
									background: "#fff",
									padding: "0.5rem",
									overflowY: "auto",
									backgroundColor: "white",
									overflow: "scroll",
									height: "65vh",
									width: "50vw",
									// display: "block",
									// position: "relative",
									marginRight: "0.5rem",
									animation: "fadeIn 2s ease-in-out",
								}}
							>
								{
									<Flex vertical style={{ width: "50vw" }}>
										<Flex
											justify="center"
											style={{
												fontWeight: "bold",
												fontSize: "1.3rem",
												margin: "1rem",
											}}
										>
											Backlogs
										</Flex>

										<Flex
											justify="space-around"
											align="center"
											style={{
												gap: "10px",
												paddingLeft: "10px",
												paddingRight: "10px",
											}}
										>
											<Input
												placeholder="Search"
												allowClear
												style={{ width: "33vw" }}
												onChange={(e) => FilterValuesList(e.target.value)}
											/>
											{/* <FilterViewPlan /> */}
											<UniversalComponent
												config={modeConfig}
												filterValue={PlanFilterValue}
												setFilterValue={setPlanFilterValue}
												setSelectedFilter={setPlanSelectedFilter}
											/>
										</Flex>
										{/* <Dropdown
												overlay={
													<Menu>
														{sortItems.map((item) => (
															<Menu.Item
																onClick={() => handleSortByChangelist(item)}
																key={item.key}
															>
																{item.label}
															</Menu.Item>
														))}
													</Menu>
												}
												trigger={["click"]}
											>
												<FilterFilled style={{color:"#0004BF"}} />
											</Dropdown> */}

										<Flex vertical>
											<List
												dataSource={FilterSearchValudataList(filtereddata)}
												renderItem={(item: any) => (
													<List.Item
														style={{
															margin: "0.5rem",
															// backgroundColor: "white",
															padding: "1rem",
															// borderRadius: "0.25rem",
															// width: "55vw",
															backgroundColor: "#edf4ff",
															borderRadius: "0.5rem",
															marginBottom: "0.5rem",
														}}
													>
														<Dropdown
															overlay={
																<Menu
																	style={{
																		textAlign: "left",
																		borderRadius: "20px",
																		boxShadow:
																			"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
																	}}
																	mode="vertical"
																	defaultSelectedKeys={["1"]}
																>
																	<SubMenu
																		key="sub2"
																		icon={<ApartmentOutlined />}
																		title="Assign to Plan"
																	>
																		<Menu
																			onClick={(e) => handleMenuClick(e, item)}
																		>
																			{Menudata.map((menuItem) =>
																				menuItem ? (
																					<Menu.Item key={menuItem._id}>
																						{menuItem.name}
																					</Menu.Item>
																				) : null
																			)}
																		</Menu>
																	</SubMenu>
																</Menu>
															}
															trigger={["contextMenu"]}
														>
															<Flex gap={"1rem"} style={{ width: "100%" }}>
																<Flex gap={"1rem"} style={{ width: "100%" }}>
																	<Space size={30}>
																		<span
																			style={{
																				fontSize: "1.5rem",
																				cursor: "pointer",
																			}}
																		>
																			<EyeOutlined />
																		</span>
																		<span
																			style={{
																				fontSize: "1.5rem",
																				color: item.colour,
																			}}
																		>
																			{item.icon}
																		</span>
																	</Space>
																	<Space
																		size={[8, 8]}
																		direction="vertical"
																		style={{ width: "100%", textAlign: "left" }}
																	>
																		<span
																			style={{
																				fontSize: "1rem",
																				textAlign: "left",
																				fontWeight: "bold",
																			}}
																		>
																			{item.title}
																		</span>
																		<div
																			style={{
																				fontSize: "0.8rem",
																				color: "black",
																			}}
																		>
																			{/* {item.description} */}
																			<p
																				style={{
																					color: "black",
																					fontFamily: "Open Sans",
																				}}
																				dangerouslySetInnerHTML={{
																					__html: item.description,
																				}}
																			/>
																		</div>
																	</Space>
																</Flex>
															</Flex>
														</Dropdown>
													</List.Item>
												)}
											/>
										</Flex>
									</Flex>
								}
							</Flex>
						)}

						<Flex
							vertical
							// className="kano"
							gap={20}
							style={{
								borderRadius: "1.25rem 1.25rem 0rem 1.25rem",
								borderTop: "2px solid #bed2fc",
								borderLeft: "2px solid #bed2fc",
								borderRight: "2px solid #bed2fc",
								background: "#fff",
								padding: "0.5rem",
								overflowY: "auto",
								height: "65vh",
								animation: "fadeIn 2s ease-in-out",
								width: "50vw",
								marginLeft: "0.5rem",
							}}
						>
							<Flex
								justify="center"
								style={{
									fontWeight: "bold",
									fontSize: "1.3rem",
									margin: "1rem 1rem 0rem",
								}}
							>
								{plan ? plan.name : ""}
							</Flex>

							<Flex
								justify="space-around"
								align="center"
								style={{
									gap: "10px",
									paddingLeft: "10px",
									paddingRight: "10px",
								}}
							>
								<Input
									placeholder="Search "
									allowClear
									style={{ width: "34vw" }}
									onChange={(e) => FilterValues(e.target.value)}
								/>

								<Dropdown
									overlay={
										<Menu>
											{sortItems.map((item) => (
												<Menu.Item
													onClick={() => handleSortByChange(item)}
													key={item.key}
												>
													{item.label}
												</Menu.Item>
											))}
										</Menu>
									}
									trigger={["click"]}
								>
									<UniversalComponent
										config={modeConfig}
										filterValue={PlanFilterValue}
										setFilterValue={setPlanFilterValue}
										setSelectedFilter={setPlanSelectedFilter}
									/>
								</Dropdown>
							</Flex>
							<Collapse
								style={{
									height: "80vh",
									overflow: "scroll",
									animation: "fadeIn 0.5s ease-in-out",
								}}
								defaultActiveKey={planvalue}
								ghost
							>
								{Object.entries(groupedTasks).map(([groupKey]) => (
									<Collapse.Panel
										key={groupKey}
										style={{ backgroundColor: "white", alignItems: "center" }}
										header={
											<div style={{ display: "flex", alignItems: "center" }}>
												<h4 style={{ textAlign: "left", margin: 0 }}>
													{groupKey}
												</h4>
											</div>
										}
									>
										<List
											split={false}
											style={{
												overflowY: "auto",
												width: "100%",
											}}
											dataSource={groupedTasks[groupKey]}
											renderItem={(itemer: any) => (
												<>
													{FilterSearchValudata(itemer?.items)?.map(
														(item: any, index: number) => (
															<List.Item
																key={item._id}
																style={{
																	backgroundColor: "#edf4ff",
																	borderRadius: "0.5rem",
																	marginBottom: "0.5rem",
																}}
															>
																<div
																	style={{
																		padding: "0.5rem",
																		display: "flex",
																		justifyContent: "space-between",
																		width: "100%",
																		marginTop: "0.5rem",
																	}}
																>
																	<div
																		style={{
																			display: "flex",
																			justifyContent: "space-between",
																		}}
																	>
																		<div>
																			<Avatar>
																				<ToolOutlined />
																			</Avatar>
																		</div>

																		<div
																			style={{
																				textAlign: "left",
																				marginLeft: "0.5rem",
																			}}
																		>
																			<div style={{ fontWeight: "bold" }}>
																				{item.title}
																			</div>
																			<div>
																				{/* {removePTags(item.description)} */}
																				<p
																					style={{
																						color: "black",
																						fontFamily: "Open Sans",
																					}}
																					dangerouslySetInnerHTML={{
																						__html: item.description,
																					}}
																				/>
																			</div>
																		</div>
																	</div>
																	<div
																		style={{
																			display: "flex",
																			justifyContent: "space-between",
																			alignItems: "center",
																			alignContent: "center",
																		}}
																	>
																		<div>
																			<Tag
																				color="yellow"
																				style={{
																					color: "black",
																					width: "fit-content",
																				}}
																			>
																				{item.status.toUpperCase()}
																			</Tag>
																		</div>

																		<div>
																			<Avatar
																				style={{
																					backgroundColor: item?.assignee
																						?.preferences?.profileColor
																						? item?.assignee?.preferences
																								?.profileColor
																						: (index + 1) % 2 == 0
																							? "#9d58be"
																							: "#be5858",
																					fontSize: "0.9rem",
																				}}
																			>
																				{formatAssigneeName(item?.assignee)}
																			</Avatar>
																		</div>
																		<div
																			style={{
																				display: "flex",
																				width: "2rem",
																			}}
																		>
																			<Popconfirm
																				title="Sure to Remove?"
																				onConfirm={() => {
																					RemoveWorkitem(item);
																				}}
																			>
																				<a
																					style={{
																						color: "#3478F6",
																						fontSize: "12px",
																					}}
																				>
																					<div style={{ marginTop: "-2.5rem" }}>
																						<CloseCircleOutlined
																							style={{
																								color: "red",
																								fontSize: "1.3rem",
																								// width: "2rem",
																								position: "relative",

																								top: "0",
																								right: "0",
																							}}
																						/>
																					</div>
																				</a>
																			</Popconfirm>
																		</div>
																	</div>
																</div>
															</List.Item>
														)
													)}
												</>
											)}
										/>
									</Collapse.Panel>
								))}
							</Collapse>
						</Flex>
					</Flex>
				</Content>
			</Layout>
		</>
	);
}

export default PlanView;
function setUnauthorizedModel(arg0: boolean) {
	throw new Error("Function not implemented.");
}
