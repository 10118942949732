import { Button, Drawer, Popconfirm } from "antd";
import React, { useState } from "react";
import NotificationList from "../component/NotificationList";
import { ClearOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { motion } from "framer-motion";

interface NotificationProps {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	open: boolean;
}

const Notification: React.FC<NotificationProps> = ({ setOpen, open }) => {
	const [clearall, setclearall] = useState(false);

	// const showDrawer = () => {
	// 	setOpen(true);
	// };

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const onClose = () => {
		setOpen(false);
	};

	const handleClearNotification = async () => {
		const reqbody = {
			status: "READ",
		};
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/notifications/clear/all`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(reqbody),
			}
		);

		if (response.ok) {
			setclearall(!clearall);
		} else {
			console.error("Failed to update Notification Status");
		}
	};

	return (
		<Drawer
			title={<h1>Notifications</h1>}
			placement="right"
			onClose={onClose}
			open={open}
			width={"32vw"}
			extra={
				<Popconfirm
					title="Clear all notifications"
					placement="leftBottom"
					description="Are you sure to clear all notifications?"
					icon={<QuestionCircleOutlined style={{ color: "red" }} />}
					onConfirm={handleClearNotification}
				>
					<motion.div
						whileHover={{ rotate: [0, -45, 0, -45, 0] }}
						transition={{ duration: 1.0, delay: 0.4, loop: 2 }}
					>
						<Button icon={<ClearOutlined />}></Button>
					</motion.div>
				</Popconfirm>
			}
		>
			<NotificationList getdata={open} clear={clearall} />
		</Drawer>
	);
};

export default Notification;
