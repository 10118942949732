import React, { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { fetchAllChildren } from "../../api/WorkItemAPI";
import { Flex, Tag } from "antd";
import PriorityFilterComponent from "./PriorityPopover";
import UserProfileFilterComponent from "./AssigneePopover";
import TypeFilterComponent from "./TypePopover";
import StatusFilterComponent from "./StatusPopover";

function ShowParent({
	id,
	type,
	// priority,
	// assignee,
}: {
	id: string;
	type: string;
	priority: string;
	assignee: string;
}) {
	const [children, setChildren] = useState<any[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { data: childrenData, run: fetchChildren } = useRequest(
		() => fetchAllChildren(userProfileStoreInstance, id),
		{
			manual: true,
		}
	);

	useEffect(() => {
		if (childrenData) {
			setChildren(childrenData);
		}
	}, [childrenData]);

	useEffect(() => {
		fetchChildren();
	}, [id]);
	return (
		<div>
			<div style={{ marginBottom: "1rem" }}>
				{children.length > 0 && (
					<>
						<p>
							Sub-{type} of{" "}
							{children.map((child) => (
								<>
									<CheckCircleOutlined /> {child.title}{" "}
								</>
							))}
						</p>
					</>
				)}
			</div>
			<Flex align="center">
				<Tag color="blue" style={{ alignItems: "center", cursor: "pointer" }}>
					<StatusFilterComponent type={"Tag"} />
				</Tag>
				<Tag color="blue" style={{ alignItems: "center", cursor: "pointer" }}>
					<TypeFilterComponent />
				</Tag>
				<Tag style={{ alignItems: "center", cursor: "pointer" }} color="blue">
					<PriorityFilterComponent type={"Tag"} />
				</Tag>
				<Tag style={{ alignContent: "center", cursor: "pointer" }} color="blue">
					<UserProfileFilterComponent type={"Tag"} />
				</Tag>
			</Flex>
		</div>
	);
}

export default ShowParent;
