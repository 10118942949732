// viewsStore.js
import create from "zustand";

interface ViewsStore {
	ListSelectedGroupBy: string;
	CardOrderning: string;
	radioButtonSelected: string;
	rightClickMenu: boolean;
	sortBy: string;
	isCardView: boolean;
	isEditModalOpen: boolean;
	input: string;
	filter: any[];
	ReleasePlanValue: boolean;
	TaskValue: boolean;
	DocsView: boolean;
	setDocsView: () => void;
	setInput: (value: string) => void;
	setfilter: (value: any[]) => void;
	selectedGroupBy: string;
	open: boolean;
	selectedItemDetails: any;
	FetchPlan: boolean;
	planvalue: any[];
	backlogworkitemmodel: boolean;
	mytaskworkitemmodel: boolean;
	Inviteusermodel: boolean;
	TaskFilterValue: any[];
	TaskSelectedFilter: any[];
	ViewFilter: boolean;
	RowBy: string;
	DrawerTab: string;
	setDrawerTab: (DrawerTab: string) => void;
	setRowBy: (value: string) => void;
	setViewFilter: (value: any) => void;
	setTaskSelectedFilter: (value: any) => void;
	setTaskFilterValue: (value: any) => void;
	setRadioButtonSelected: (value: string) => void;
	setInviteusermodel: (isOpen: boolean) => void;
	setRightClickMenu: (isOpen: boolean) => void;
	setmytaskworkitemmodel: (isOpen: boolean) => void;
	setbacklogworkitemmodel: (isOpen: boolean) => void;
	setListView: () => void;
	setCardView: () => void;
	setSortBy: (sortBy: string) => void;
	setEditModalOpen: (isOpen: boolean) => void;
	setSelectedGroupBy: (groupBy: string) => void;
	setListSelectedGroupBy: (value: string) => void;
	setOpen: (isOpen: boolean) => void;
	setSelectedItemDetails: (item: any) => void;
	setReleasePlan: (isOpen: boolean) => void;
	setFetchPlan: (FetchPlan: boolean) => void;
	setplanvalue: (value: any[]) => void;
	setTask: (isOpen: boolean) => void;
	setCardOrderning: (value: string) => void;
	urlData: {
		prevUrl: string;
		newUrl: string;
		screen: string;
		changeUrl: (prevUrl: string, newUrl: string, screen: string) => void;
	};
}

export const TaskStore = create<ViewsStore>((set) => ({
	ListSelectedGroupBy: "None",
	RowBy: "type",
	CardOrderning: "None",
	radioButtonSelected: "MyTasks",
	rightClickMenu: false,
	sortBy: "None",
	isCardView: false,
	isEditModalOpen: false,
	input: "",
	filter: [],
	ReleasePlanValue: false,
	backlogworkitemmodel: false,
	mytaskworkitemmodel: false,
	FetchPlan: false,
	TaskValue: false,
	Inviteusermodel: false,
	TaskFilterValue: [],
	TaskSelectedFilter: [],
	ViewFilter: true,
	DocsView: false,
	DrawerTab: "",
	setDrawerTab: (value: string) => set({ DrawerTab: value }),
	setRowBy: (value: string) => set({ RowBy: value }),
	setCardOrderning: (value: string) => set({ CardOrderning: value }),
	setViewFilter: (value: boolean) => set({ ViewFilter: value }),
	setDocsView: () => set({}),
	setTaskSelectedFilter: (value: any) => set({ TaskSelectedFilter: value }),
	setTaskFilterValue: (value: any) => set({ TaskFilterValue: value }),
	setRadioButtonSelected: (value: string) =>
		set({ radioButtonSelected: value }),
	setInviteusermodel: (isOpen: boolean) => set({ Inviteusermodel: isOpen }),
	setRightClickMenu: (isOpen: boolean) => set({ rightClickMenu: isOpen }),
	setmytaskworkitemmodel: (isOpen: boolean) =>
		set({ mytaskworkitemmodel: isOpen }),
	setbacklogworkitemmodel: (isOpen: boolean) =>
		set({ backlogworkitemmodel: isOpen }),
	setSortBy: (sortBy: string) => set({ sortBy: sortBy }),
	setListView: () => set({ isCardView: false }),
	setCardView: () => set({ isCardView: true }),
	// setEditModalOpen: () => set({isEditModalOpen:true}),
	setEditModalOpen: (isOpen: boolean) => set({ isEditModalOpen: isOpen }),
	setInput: (value: string) => set({ input: value }),
	setfilter: (value: any[]) => set({ filter: value }),
	selectedGroupBy: "None",
	open: false,
	selectedItemDetails: null,
	planvalue: [],
	setSelectedGroupBy: (groupBy: string) => set({ selectedGroupBy: groupBy }),
	setListSelectedGroupBy: (value: string) =>
		set({ ListSelectedGroupBy: value }),
	setOpen: (isOpen: boolean) => set({ open: isOpen }),
	setSelectedItemDetails: (item: any) => set({ selectedItemDetails: item }),
	setReleasePlan: (isOpen: boolean) => set({ ReleasePlanValue: isOpen }),
	setFetchPlan: (isopen: boolean) => set({ FetchPlan: isopen }),
	setplanvalue: (value: any[]) => set({ planvalue: value }),
	setTask: (isOpen: boolean) => set({ TaskValue: isOpen }),
	urlData: {
		prevUrl: "",
		newUrl: "",
		screen: "",
		changeUrl: (prevUrl: string, newUrl: string, screen: string) => {
			set((state) => ({
				urlData: {
					...state.urlData,
					prevUrl: prevUrl,
					newUrl: newUrl,
					screen: screen,
				},
			}));
		},
	},
}));
