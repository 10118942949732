import React from "react";
import { Button, Modal, Flex, Typography } from "antd";
import "./ConformationModel.css";
import robot from "../assets/robot.json";
import { msalInstance } from "..";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import usePlatformStore, { PlatformStoreType } from "../stores/platformStore";

const { Paragraph } = Typography;
const ValidateAuthenticationModel: React.FC<any> = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	// const accountStoreInstance = AccountStore() as AccountStoreType;
	const usePlatformStoreInstance = usePlatformStore() as PlatformStoreType;
	const useWorkspaceStoreInstance = usePlatformStore() as any;

	const onLogout = () => {
		// localStorage.clear();
		// console.log('User logged out');
		// navigate("/");
		userProfileStoreInstance.clearStore();
		// accountStoreInstance.clearStore();
		usePlatformStoreInstance.clearStore();
		useWorkspaceStoreInstance.clearStore();
		localStorage.clear();
		const logoutConfig = {
			account: msalInstance.getAllAccounts()[0],
			postLogoutRedirectUri: process.env.REACT_APP_AZURE_AD_LOGOUT_REDIRECT_URL,
		};
		try {
			msalInstance.logoutRedirect(logoutConfig).then(() => {
				navigate("/");
			});
		} catch (error) {
			console.log("Error during logout:", error);
		}
	};

	const isOpen = usePlatformStoreInstance.unauthorizedModel;
	console.log("isOpen", isOpen);

	const navigate = useNavigate();
	const onOk = () => {};
	return (
		<>
			<Modal width="50vw" open={isOpen} footer={null} centered onOk={onOk}>
				<Flex className="modal" align="center" justify="center" vertical>
					<Lottie
						animationData={robot}
						loop={true}
						autoplay={true}
						style={{ width: "50%", height: "auto" }}
					/>
					<Paragraph strong style={{ textAlign: "center" }}>
						Your session has timed out due to inactivity. For security reasons,
						we log users out automatically after a period of non-use. To
						continue, please log in again.
					</Paragraph>
					<Flex justify="flex-end">
						<Button
							style={{ width: "8rem", height: "2.5rem" }}
							type="primary"
							onClick={onLogout}
						>
							Login
						</Button>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};

export default ValidateAuthenticationModel;
