import React, { useEffect, useState } from "react";
import {
	Form,
	Input,
	// Checkbox,
	Layout,
	Card,
	Flex,
	Button,
	Typography,
	// message,
	Select,
	message,
} from "antd";
import "./AddRole.css";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
// import usePlatformStore from "../stores/platformStore";

const { Text } = Typography;

const AddRolesModal: React.FC = () => {
	// const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
	// 	setUnauthorizedModel: state.setUnauthorizedModel,
	// }));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const formItemLayout = { labelCol: { span: 2 }, wrapperCol: { span: 14 } };
	// const [roleName, setRoleName] = useState<string>("");
	const [permissions, setPermissions] = useState<any>([]);
	const [options, setOptions] = useState<any>([]);
	// const permissions: string[] = [
	// 	"Can Create",
	// 	"Can View",
	// 	"Can Edit",
	// 	"Can Delete",
	// ];

	// const categories: string[] = ["Business", "Features", "Code", "Design"];

	// const [selectedPermissionsMap, setSelectedPermissionsMap] = useState<{
	// 	[key: string]: string[];
	// }>({});

	// const handlePermissionChange = (category: string, selected: string[]) => {
	// 	setSelectedPermissionsMap({
	// 		...selectedPermissionsMap,
	// 		[category]: selected,
	// 	});
	// };

	const fetchPermissions = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/permissions`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const PermissionsData = await response.json();
				const newpermissions = PermissionsData.map((permission: any) => {
					return {
						label: permission.name,
						value: permission._id,
					};
				});
				setPermissions(newpermissions);
				setOptions(newpermissions);
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	const handleChange = (value: string) => {
		setOptions(
			permissions.filter((permission: any) =>
				permission.label.toLowerCase().includes(value.toLowerCase())
			)
		);
	};

	useEffect(() => {
		fetchPermissions();
	}, []);

	const handleButtonClick = async (values: any) => {
		const name = values.roleName;
		console.log("Role name:", name);
		const createdBy = "defaultCreatedBy";
		const updatedBy = "defaultUpdatedBy";

		// const permissionsArray = categories.map((category) => {
		// 	const selectedPermissions = selectedPermissionsMap[category] || [];
		// 	return {
		// 		field: category,
		// 		permissions: selectedPermissions,
		// 	};
		// });

		const postData = {
			name,
			createdBy,
			updatedBy,
			permissions: values.permissions,
			account_Id: userProfileStoreInstance.profile.account_Id,
		};

		console.log("Sending data to the backend:", values);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/roles`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(postData),
			});

			if (response.ok) {
				const responseData = await response.json();
				console.log("Response from the backend:", responseData);
				// Handle success
				message.success("Role added successfully");
				navigate("/settings/role");
			} else if (response.status === 401) {
				// setUnauthorizedModel(true);
			} else {
				const errorData = await response.json();
				message.error(`Error adding role. Please try again.${errorData}`);
			}
		} catch (error: any) {
			message.error(`Error adding role. Please try again: ${error.message}`);
		}
	};

	const handleCancelClick = async () => {
		navigate("/settings/role");
	};

	return (
		<Layout>
			<Card className="fixedCard-settings">
				<h2 className="titleCardSettings">Add Roles</h2>

				<Form
					form={form}
					onFinish={handleButtonClick}
					{...formItemLayout}
					layout="horizontal"
					colon={false}
				>
					<Flex>
						<Text>Role Name</Text>
					</Flex>

					<Form.Item
						className="titleTypoAddRole"
						name="roleName"
						rules={[
							{
								required: true,
								message: "Please enter role name",
							},
						]}
					>
						<Input
							className="formInputUser"
							placeholder="Enter role name"
							// value={roleName}
							// onChange={(event) => setRoleName(event.target.value)}
						/>
					</Form.Item>

					<Flex>
						<Text className="titleTypoAddRole">Permissions</Text>
					</Flex>
					<Form.Item
						className="titleTypoAddRole"
						name="permissions"
						rules={[
							{
								required: true,
								message: "Please select at least one permission",
							},
						]}
					>
						<Select
							mode="multiple"
							allowClear
							style={{ width: "100%" }}
							placeholder="Please select"
							onSearch={handleChange}
							options={options}
							filterOption={false}
						/>

						{/* <Card style={{ border: "none" }}>
							{categories.map((category) => (
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginBottom: "22px",
									}}
									key={category}
								>
									<h4 style={{ fontSize: "14px", margin: 0 }}>{category}</h4>

									<Checkbox.Group
										options={permissions.map((permission) => ({
											label: permission,
											value: permission,
										}))}
										value={selectedPermissionsMap[category] || []}
										onChange={(selected) =>
											handlePermissionChange(category, selected as string[])
										}
									/>
								</div>
							))}
						</Card> */}
					</Form.Item>

					<Flex style={{ paddingBottom: "16px" }} justify={"flex-end"}>
						<div className="buttons-container">
							<Button
								style={{ width: "5rem" }}
								onClick={handleCancelClick}
								data-testId="addRoleTest"
							>
								Cancel
							</Button>
							<Button
								style={{ width: "5rem" }}
								type="primary"
								htmlType="submit"
							>
								Save
							</Button>
						</div>
					</Flex>
				</Form>
			</Card>
		</Layout>
	);
};

export default AddRolesModal;
