import React, { useEffect, useState } from "react";
import {
	Modal,
	Form,
	Input as AntInput,
	Button,
	DatePicker,
	message,
	Radio,
	//Row,
} from "antd";
//import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreatePlanModel.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { usePlanStore } from "../../stores/PlanStore";

import useWorkspaceStore from "../../stores/WorkspaceState";
import { useNavigate } from "react-router-dom";

interface CreatePlanModelprops {
	visible: boolean;
	onCancel: () => void;
}

const { RangePicker } = DatePicker;
const messageApi = message;
const success = () => {
	messageApi.success("New Plan added successfully");
};

const CreatePlanModel: React.FC<CreatePlanModelprops> = ({
	visible,
	onCancel,
}) => {
	// const formItemLayout = {
	// 	labelCol: { span: 5 },
	// 	wrapperCol: { span: 18 },
	// };
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { setFetchPlan, FetchPlan, setnewplan } = usePlanStore();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [PlanTypes, setPlanTypes] = useState<string[]>([]);

	const handleFormSubmit = async (values: any) => {
		// Extracting dates from the dateRange array
		const [startDate, endDate] = values.dateRange.map((date: Date) => {
			return date.toISOString().split("T")[0]; // Extracting date part
		});

		await fetch(`${process.env.REACT_APP_API_URL}/plan`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify({
				name: values.planName,
				type: values.planType,
				startdate: startDate,
				enddate: endDate,
				items: [],
				workspaceId: currentWorkspace?._id,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
				onCancel();
				setFetchPlan(!FetchPlan);
				success();
				setnewplan(data._id, true, values.planName);
				navigate(`/space/planner/planner?planId=${data._id}`);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchPlanTypeData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/planconfig/${currentWorkspace?._id}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const data = await response.json();
				if (Array.isArray(data)) {
					const extracTypes: any = data.map((type) => type.type);
					setPlanTypes(extracTypes);
					console.log("Fetched data for workitem Type:", extracTypes);
					// setResponseData(data);
				} else {
					console.error("Fetched data is not an array");
				}
			} else {
				console.error("Error fetching prefixes data");
			}
		} catch (error) {
			console.error("Error fetching prefixes data:", error);
		}
	};

	useEffect(() => {
		fetchPlanTypeData();
	}, []);

	return (
		<Modal
			title="Create Plan"
			visible={visible}
			onCancel={onCancel}
			width={"55vw"}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					htmlType="submit"
					onClick={() => form.submit()}
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				onFinish={handleFormSubmit}
				// {...formItemLayout}
				//style={{ margin: "5rem 0 8rem 0" }}
				style={{ margin: "2rem 0" }}
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
			>
				<Form.Item
					label="Plan Name"
					name="planName"
					rules={[{ required: true, message: "Please input plan name!" }]}
				>
					<AntInput placeholder="Plan Name" style={{ width: "30vw" }} />
				</Form.Item>

				<Form.Item
					label="Plan Type"
					name="planType"
					rules={[{ required: true, message: "Please select plan type!" }]}
				>
					<Radio.Group options={PlanTypes} optionType="button" />
					{/* <Radio.Button value="Featureplan">Feature</Radio.Button>
						<Radio.Button value="Releaseplan">Release</Radio.Button>
						<Radio.Button value="Sprintplan">Sprint</Radio.Button> */}
					{/* </Radio.Group> */}
				</Form.Item>

				<Form.Item
					label="Date Range"
					name="dateRange"
					rules={[{ required: true, message: "Please select date range!" }]}
				>
					<RangePicker style={{ width: "77.5%" }} />
				</Form.Item>

				<Form.Item
					label="Goals"
					name="goals"
					rules={[{ required: true, message: "Please input goals!" }]}
				>
					{/* <ReactQuill style={{ marginLeft: "3.2rem", width: "30vw" }} /> */}
					<AntInput.TextArea
						placeholder="Goals"
						rows={3}
						style={{ width: "30vw" }}
					/>
				</Form.Item>
				{/* </Row> */}
			</Form>
		</Modal>
	);
};

export default CreatePlanModel;
