import React, { useState } from "react";

import {
	EditOutlined,
	//UserOutlined,
	SearchOutlined,
	//ArrowLeftOutlined,
} from "@ant-design/icons";
import {
	Flex,
	//Button,
	//Space,
	Input,
	TreeSelect,
	//Tooltip,
	//Breadcrumb,
	Grid,
	Divider,
} from "antd";
// import WorkItemModal from "./AddWorkitemModel";
import { planerViewStore } from "../../../stores/PlannerStore";
import { useLocation } from "react-router-dom";
import RitualCeration from "./RitualsCreation";
import Constants from "../../../util/ConstantsUtil";
import { motion } from "framer-motion";
import { Can } from "../../../layout/AppLayout";
import breadcrumbConfig from "../../../util/BreadCrumbsUtil";
import BreadCrumb from "../../Editors/BreadCrumb";
const { useBreakpoint } = Grid;

function RitualNav() {
	const url = useLocation().pathname;
	console.log(url);
	const [modalVisible, setModalVisible] = useState(false);
	const { input, setInput, setfilter } = planerViewStore();
	const screens = useBreakpoint();

	const treeData = [
		{
			value: Constants.TYPE, // change to CAPS
			title: Constants.TYPE,
			children: [
				{
					value: Constants.CAPS_RITUAL,
					title: Constants.RITUALS,
				},
				{
					value: Constants.CAPS_STAND_UP,
					title: Constants.STAND_UP,
				},
			],
		},
	];

	const [value, setValue] = useState<any[]>([]);

	const onChange = (newValue: any[]) => {
		const filteredValues = newValue.filter((val: any) => {
			return !treeData.some((item) => item.value === val);
		});
		setValue(filteredValues);
		setfilter(filteredValues);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
	};

	// const handleChange = (event: any) => {
	// 	setInput(event.target.value); // Update input value
	// };

	const handleAddWorkItemClick = () => {
		setModalVisible(true);
	};

	{
		/*const items = [
		{
			label: "Type",
			key: "1",
			icon: <UserOutlined />,
		},
		{
			label: "Priority",
			key: "2",
			icon: <UserOutlined />,
		},
		{
			label: "Status",
			key: "3",
			icon: <UserOutlined />,
		},
		{
			label: "None",
			key: "4",
			icon: <UserOutlined />,
		},
	];

	const handleGroupByChange = ({ key }: { key: React.Key }) => {
		const selectedItem = items.find((item) => item.key === key);
		if (selectedItem) {
			setSelectedGroupBy(selectedItem.label);
		}
	}; */
	}

	const [isSearchExpanded, setIsSearchExpanded] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const handleSearchChange = (event: any) => {
		setInput(event.target.value);
		if (event.target.value === "") {
			setIsSearchExpanded(false);
		}
	};

	const handleSearchIconClick = () => {
		setIsSearchExpanded(true);
	};

	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		<div>
			<Divider className="ant-divider-horizontal" />
			{/* <Flex vertical>
				<Flex>
					<h2 style={{ textDecoration: "underline", margin: 0, padding: 0 }}>
						{url === "/planner/backlogs"
							? "All Backlog"
							: url === "/planner/mytasks"
								? "My Tasks"
								: url === "/planner/releaseplan"
									? "Release Plan"
									: url === "/planner/featureplan"
										? "Feature Plan"
										: url === "/planner/sprintplan"
											? "Sprint Plan"
											: "Unknown Page"}
					</h2>
				</Flex>
			</Flex> */}

			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex className="breadcrumb" style={{ marginLeft: "2vw" }}>
						{/* <Space>
							<Tooltip placement="bottom" title="Back">
								<Button
									shape="circle"
									icon={<ArrowLeftOutlined />}
									size="large"
								/>
							</Tooltip> 
							<Flex vertical>
								<Breadcrumb>
									<Breadcrumb.Item>
										<h3 color="#87d068" style={{ fontSize: "1.1rem" }}>
											Ritual Sessions
										</h3>
									</Breadcrumb.Item>
								</Breadcrumb>
							</Flex>
						</Space> */}
						<BreadCrumb config={breadcrumbConfig.rituals} />
					</Flex>
					<Flex justify="end" gap={"small"} style={{ alignItems: "center" }}>
						{/* <Input
							suffix={<SearchOutlined />}
							size="middle"
							style={{
								height: "5vh",
								width: "14vw",
								fontSize: "0.9rem",
								fontFamily: "Poppins",
							}}
							placeholder="Search"
							onChange={handleChange}
							value={input}
						/> */}
						{isSearchExpanded ? (
							<Input
								suffix={<SearchOutlined />}
								size="small"
								className="search-input"
								placeholder="Search"
								onChange={handleSearchChange}
								value={input}
								allowClear
								autoFocus
								onBlur={() => {
									if (input === "") setIsSearchExpanded(false);
								}}
							/>
						) : (
							<SearchOutlined
								className="search-icon"
								onClick={handleSearchIconClick}
								// onMouseEnter={() => setIsSearchExpanded(true)}
							/>
						)}
						{/*<Dropdown
							overlay={
								<Menu onClick={handleGroupByChange}>
									{items.map((item) => (
										<Menu.Item key={item.key} icon={item.icon}>
											{item.label}
										</Menu.Item>
									))}
								</Menu>
							}
							trigger={["click"]}
						>
							<Button size="middle" shape="round">
								<Space>
									Group by
									{selectedGroupBy === "Release" &&
									url === "/space/planner/backlogs"
										? "None"
										: selectedGroupBy}
									<DownOutlined />
								</Space>
							</Button>
						</Dropdown> */}

						<Flex>
							<TreeSelect
								showSearch
								size="small"
								style={{
									minWidth: "10vw",
									height: "auto",
									fontFamily: "Poppins",
								}}
								value={value}
								dropdownStyle={{
									width: screens.xs
										? "35%"
										: screens.sm
											? 130
											: screens.md
												? 130
												: 130,
									maxHeight: 400,
									overflow: "auto",
									borderRadius: "6px",
								}}
								placeholder={
									<span style={{ color: "black", fontFamily: "Poppins" }}>
										Filter By
									</span>
								}
								allowClear
								multiple
								treeDefaultExpandAll={false}
								onChange={onChange}
								treeData={treeData}
							/>
						</Flex>

						{/* <Button
							size="middle"
							shape="round"
							type="primary"
							onClick={handleAddWorkItemClick}
						>
							Add Ritual Plan
						</Button> */}
						<Can I="add" a="rituals" passThrough>
							{(allowed) =>
								allowed ? (
									<>
										{/* <motion.button
											type="button"
											style={{
												fontFamily: "Poppins",
												fontSize: "14px",
												backgroundColor: "#0444BF",
												borderRadius: "20px",
												padding: "8px",
												color: "white",
												borderColor: "white",
												minWidth: "10vw",
											}}
											onClick={handleAddWorkItemClick}
											whileTap={{ scale: 0.8, backgroundColor: "#032c81" }}
										>
											Add Ritual Plan
										</motion.button> */}
										<motion.div
											initial="initial"
											animate={isHovered ? "hover" : "initial"}
											variants={buttonVariants}
											transition={{ duration: 0.3 }}
											onMouseEnter={() => setIsHovered(true)}
											onMouseLeave={() => setIsHovered(false)}
											className="add-work-item-button"
											onClick={handleAddWorkItemClick}
										>
											<EditOutlined />
											{isHovered && (
												<span style={{ marginLeft: "8px" }}>
													Add Ritual Plan
												</span>
											)}
										</motion.div>
										<RitualCeration
											visible={modalVisible}
											onCancel={handleModalCancel}
										/>
									</>
								) : null
							}
						</Can>
					</Flex>
				</Flex>
			</div>
		</div>
	);
}
export default RitualNav;
