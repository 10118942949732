/* eslint-disable @typescript-eslint/no-unused-vars */
import { Breadcrumb, Button, Flex } from "antd";
import Title from "antd/es/typography/Title";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import usePlatformStore from "../stores/platformStore";
import _ from "lodash";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

function ViewNavbar() {
	const navigate = useNavigate();
	const location = useLocation();

	const { backNavigateUrl } = usePlatformStore((state: any) => ({
		backNavigateUrl: state.backNavigateUrl,
	}));
	const isViewTask = location.pathname.includes("viewtask");
	return (
		<>
			<Flex
				align="center"
				gap={16}
				style={{ marginLeft: "5vw", marginBottom: "3vh", marginTop: "3vh" }}
			>
				<Button
					shape="circle"
					icon={<ArrowLeftOutlined style={{ color: "#0444BF" }} />}
					size="large"
					onClick={() => {
						navigate(backNavigateUrl);
					}}
				/>
				<Flex vertical>
					<Breadcrumb>
						<Breadcrumb.Item>.</Breadcrumb.Item>
					</Breadcrumb>
					<Title
						level={5}
						style={{
							margin: 0,
							textAlign: "left",
							fontFamily: "Poppins",
						}}
					>
						{isViewTask ? "My Task" : "Backlog"}
					</Title>
				</Flex>
			</Flex>
		</>
	);
}

export default ViewNavbar;
