import React, { useEffect, useState } from "react";
import {
	Checkbox,
	Col,
	Divider,
	Flex,
	Form,
	Input,
	Row,
	Tag,
	Typography,
} from "antd";

import { planerViewStore } from "../../stores/PlannerStore";
import { PlusCircleOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import AddChecklistItemModal from "./Rituals/AddChecklistModel";
import { v4 as uuidv4 } from "uuid";
const { Title } = Typography;
const QuickView = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [form] = Form.useForm();
	const { selectedItemDetails, setSelectedItemDetails } = planerViewStore();
	const [visible, setVisible] = useState(false);
	const [editedItemTitle, setEditedItemTitle] = useState<string>("");
	const [editMode, setEditMode] = useState<string | null>(null);

	const handleOk = () => {
		setVisible(true);
	};
	const handleCancel = () => {
		setVisible(false);
	};
	const handleAddChecklistItem = async (value: any) => {
		try {
			const id = uuidv4();

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						...selectedItemDetails,
						checklist: [
							...selectedItemDetails.checklist,
							{ title: value, status: "pending", _id: id },
						],
					}),
				}
			);

			if (response.ok) {
				// Refresh data after successfully adding checklist item
				const updatedData = await response.json(); // Assuming the response contains updated data
				setSelectedItemDetails(updatedData);
				console.log("Checklist item added successfully:", value);
			} else {
				console.error("Failed to add checklist item");
			}
		} catch (error) {
			console.error("Error adding checklist item:", error);
		} finally {
			handleCancel();
		}
	};

	const formItemLayout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const handleDoubleClick = (item: any) => {
		setEditMode(item._id);
		setEditedItemTitle(item.title);
	};

	const handleCheckboxChanges = async (
		title: string,
		checked: boolean,
		id: any
	) => {
		try {
			const updatedChecklist = selectedItemDetails.checklist.map((item: any) =>
				item._id === id
					? { ...item, status: checked ? "done" : "pending", _id: id }
					: item
			);

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						...selectedItemDetails,
						checklist: updatedChecklist,
					}),
				}
			);

			if (response.ok) {
				const updatedData = await response.json();
				setSelectedItemDetails(updatedData);
				console.log("Checklist item status updated successfully");
			} else {
				console.error("Failed to update checklist item status");
			}
		} catch (error) {
			console.error("Error updating checklist item status:", error);
		}
	};

	const handleEnterKeyPress = async (
		e: React.KeyboardEvent<HTMLInputElement>,
		status: string,
		id: any
	) => {
		if (e.key === "Enter") {
			try {
				// Find the checklist item by itemId
				const updatedChecklist = selectedItemDetails.checklist.map(
					(item: any) =>
						item._id === id
							? { ...item, title: editedItemTitle, status: status }
							: item
				);

				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							...selectedItemDetails,
							checklist: updatedChecklist,
						}),
					}
				);
				if (response.ok) {
					const updatedData = await response.json(); // Assuming the response contains updated data
					setSelectedItemDetails(updatedData);
					console.log("Checklist item updated successfully:");
				} else {
					console.error("Failed to update checklist item:");
				}
			} catch (error) {
				console.error("Error updating checklist item:", error);
			} finally {
				setEditMode(null);
			}
		}
	};

	useEffect(() => {
		if (selectedItemDetails) {
			const doneChecklistItems = selectedItemDetails.checklist?.filter(
				(item: any) => item.status === "done"
			);

			const defaultChecklistValues = doneChecklistItems?.map(
				(item: any) => item.title
			);

			form.setFieldsValue({
				checklist: defaultChecklistValues,
			});
		}
	}, [selectedItemDetails]);

	return (
		<div>
			<div style={{ textAlign: "left" }}>
				<p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
					dangerouslySetInnerHTML={{ __html: selectedItemDetails?.description }}
				></p>

				{/* <p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
				>
					Type: {selectedItemDetails?.type}
				</p> */}

				<Row gutter={16}>
					<Col className="gutter-row" span={4}>
						<div>Status</div>
					</Col>
					<Col className="gutter-row" span={8}>
						<Tag color="red" style={{ textAlign: "left" }}>
							{selectedItemDetails?.status}
						</Tag>
					</Col>
					<Col className="gutter-row" span={4}>
						<div>Priority</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<Tag color="pink" style={{ textAlign: "left" }}>
							{selectedItemDetails?.priority}
						</Tag>
					</Col>
				</Row>
			</div>
			<Divider />
			<Flex vertical style={{ marginTop: "1rem" }}>
				<Flex align="center" gap={10}>
					<Title style={{ color: "black" }} level={3}>
						CheckList
					</Title>
					<PlusCircleOutlined
						onClick={handleOk}
						style={{ fontSize: "1.8rem" }}
					/>
				</Flex>
				{selectedItemDetails?.checklist && (
					<Flex
						style={{
							height: "20vh",
							overflow: "scroll",
						}}
						vertical
						gap={20}
					>
						<Form
							{...formItemLayout}
							form={form}
							style={{ maxWidth: "100%", width: "100%" }}
						>
							<Form.Item name={"checklist"} noStyle>
								<Checkbox.Group style={{ width: "100%" }}>
									<Flex
										style={{ width: "100%" }}
										vertical
										align="start"
										justify="start"
									>
										{selectedItemDetails.checklist.map((item: any) => (
											<div
												key={item._id}
												style={{ display: "flex", alignItems: "center" }}
											>
												<div
													onDoubleClick={() => handleDoubleClick(item)}
													style={{ marginRight: "8px" }}
												>
													<Checkbox
														checked
														value={item.title}
														type="checkbox"
														onChange={(e) =>
															handleCheckboxChanges(
																item.title,
																e.target.checked,
																item._id
															)
														}
													/>
												</div>
												<div>
													{editMode === item._id ? (
														<Input
															value={editedItemTitle}
															onChange={(e) =>
																setEditedItemTitle(e.target.value)
															}
															onPressEnter={(e) =>
																handleEnterKeyPress(e, item.status, item._id)
															}
															onBlur={() => setEditMode(null)}
															autoFocus
														/>
													) : (
														<span
															onDoubleClick={() => handleDoubleClick(item)}
															style={{ cursor: "pointer" }}
														>
															{item.title}
														</span>
													)}
												</div>
											</div>
										))}
									</Flex>
								</Checkbox.Group>
							</Form.Item>
						</Form>
					</Flex>
				)}
			</Flex>
			<AddChecklistItemModal
				visible={visible}
				onCreate={handleAddChecklistItem}
				onCancel={handleCancel}
			></AddChecklistItemModal>
		</div>
	);
};

export default QuickView;
