import { Flex, List, Tag } from "antd";
import React, { useEffect } from "react";
import useWorkspaceStore from "../../stores/WorkspaceState";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import moment from "moment";

interface AlchemiAIRunListProps {
	// Define your component props here
}

const AlchemiAIRunList: React.FC<AlchemiAIRunListProps> = (props) => {
	// Add your component logic here

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [aiRuns, setAIRuns] = React.useState([]);
	const getAIRunsByWorkspace = async () => {
		// Implement your code here

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/describe/${currentWorkspace._id}/activity?status=ALL`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					"Content-Type": "application/json; charset=UTF-8",
				},
			}
		);

		const result = await response.json();
		setAIRuns(result);
	};

	useEffect(() => {
		// Add your code here
		console.log(props);
		getAIRunsByWorkspace();
	}, []);

	return (
		<Flex
			style={{
				width: "24vw",
				minHeight: "70vh",
				maxHeight: "95vh",
				overflow: "scroll",
			}}
			vertical
		>
			<Flex
				style={{
					width: "100%",
				}}
				justify="space-between"
			>
				&nbsp;
			</Flex>
			<List
				pagination={{ position: "bottom", align: "center", pageSize: 5 }}
				dataSource={aiRuns}
				renderItem={(item: any, index) => (
					<List.Item key={index}>
						<Flex>
							<Flex
								style={{
									width: "50px",
									height: "50px",
									borderRadius: "50%",
									backgroundColor: "#f0f0f0",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{item.section.charAt(0)}
							</Flex>
							<Flex
								style={{
									marginLeft: "10px",
								}}
								vertical
								justify="space-evenly"
							>
								<div>{item.section}</div>

								<Tag color={item.status == "IN_PROGRESS" ? "pink" : "green"}>
									{item.status}
								</Tag>
							</Flex>
						</Flex>
						<Flex
							justify="flex-end"
							align="flex-end"
							style={{
								marginRight: "10px",
							}}
						>
							<Tag color="lime" style={{ borderRadius: "16px" }}>
								{moment(item.createdAt).fromNow()}
							</Tag>
						</Flex>
					</List.Item>
				)}
			/>
		</Flex>
	);
};

export default AlchemiAIRunList;
