import { create } from "zustand";
import { persist } from "zustand/middleware";

export type AIActivityStore = {
	currentAIActivities: any;
	setCurrentAIActivities: (currentAIActivities: any) => void;
	alRecommendations: any;
	setAlRecommendations: (alRecommendations: any) => void;
	aiConversationContext: any;
	setAIConversationContext: (aiConversationContext: any) => void;
	currentConversationThread: any;
	setCurrentConversationThread: (currentConversationThread: any) => void;
	currentThreadStatus: string;
	setCurrentThreadStatus: (currentThreadStatus: string) => void;
	isAIConversationActive: boolean;
	setIsAIConversationActive: (isAIConversationActive: boolean) => void;

	clearStore: () => void;
};

const useAIActivityStore = create(
	persist(
		(set) => ({
			currentAIActivities: null,
			setCurrentAIActivities: (currentAIActivities: any) =>
				set({ currentAIActivities: currentAIActivities }),
			alRecommendations: null,
			setAlRecommendations: (alRecommendations: any) =>
				set({ alRecommendations: alRecommendations }),
			aiConversationContext: null,
			setAIConversationContext: (aiConversationContext: any) =>
				set({ aiConversationContext: aiConversationContext }),

			currentConversationThread: null,
			setCurrentConversationThread: (currentConversationThread: any) =>
				set({ currentConversationThread: currentConversationThread }),

			currentThreadStatus: "",
			setCurrentThreadStatus: (currentThreadStatus: string) =>
				set({ currentThreadStatus: currentThreadStatus }),

			isAIConversationActive: false,
			setIsAIConversationActive: (isAIConversationActive: boolean) =>
				set({ isAIConversationActive: isAIConversationActive }),

			clearStore: () =>
				set({
					currentAIActivities: null,
				}),
		}),

		{
			name: "aiActivityStore",
		}
	)
);

export default useAIActivityStore;
