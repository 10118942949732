/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import "./Plans.css";
import {
	DownOutlined,
	UnorderedListOutlined,
	//UserOutlined,
	//AppstoreFilled,
	SearchOutlined,
	ArrowLeftOutlined,
	AppstoreFilled,
} from "@ant-design/icons";
import {
	Flex,
	Dropdown,
	Button,
	Space,
	Input,
	TreeSelect,
	Menu,
	Tooltip,
	Breadcrumb,
	Steps,
	Typography,
	Modal,
} from "antd";
// import WorkItemModal from "./AddWorkitemModel";

// import { useLocation, useNavigate } from "react-router-dom";
import { usePlanStore } from "../../stores/PlanStore";
import CreatePlanModel from "./CreatePlanModel";
import { useLocation, useNavigate } from "react-router-dom";
import Constants from "../../util/ConstantsUtil";
import operation_failed from "../../assets/operation_failed.svg";
import operation_done from "../../assets/operation_done.svg";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import BreadCrumb from "../Editors/BreadCrumb";

interface ComponentProps {
	title: string;
	screen: string;
}

const PlanViewNav: React.FC<ComponentProps> = ({ title, screen }) => {
	// const url = useLocation().pathname;
	const navigate = useNavigate();
	const location = useLocation();
	const render = !location.pathname.includes("/space/planner/planner?planId=");
	// console.log(url);
	const [modalVisible, setModalVisible] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const {
		setListView,
		setCardView,
		input,
		setInput,
		setfilter,
		newPlan,
		//setSelectedGroupBy,
		// urlData,
		setSortBy,
		setnewplan,
	} = usePlanStore();

	const sortItems = [
		{
			label: "Created date (Asc)",
			key: "created-asc",
		},
		{
			label: "Created date (Desc)",
			key: "created-desc",
		},
	];

	const treeData = [
		{
			value: Constants.TYPE, // change to CAPS
			title: Constants.TYPE,
			children: [
				{
					value: Constants.CAPS_TYPE_FEATURE,
					title: Constants.FEATURE,
				},
				{
					value: Constants.CAPS_TYPE_USER_STORY,
					title: Constants.USER_STORY,
				},
			],
		},
		{
			value: Constants.PRIORITY, // change to CAPS
			title: Constants.PRIORITY,
			children: [
				{
					value: Constants.KANO, // change to CAPS
					title: Constants.KANO,
					children: [
						{
							value: Constants.CAPS_PRIORITY_BASIC_NEEDS,
							title: Constants.PRIORITY_BASIC_NEEDS,
						},
						{
							value: Constants.CAPS_PRIORITY_PERFORMANCE_NEEDS,
							title: Constants.PRIORITY_PERFORMANCE_NEEDS,
						},
						{
							value: Constants.CAPS_PRIORITY_EXCITEMENT_NEEDS,
							title: Constants.PRIORITY_EXCITEMENT_NEEDS,
						},
					],
				},
				{
					value: Constants.MOSCOW, // change to CAPS
					title: Constants.MOSCOW,
					children: [
						{
							value: Constants.CAPS_PRIORITY_MUST_HAVE,
							title: Constants.MUST_HAVE,
						},
						{
							value: Constants.CAPS_PRIORITY_SHOULD_HAVE,
							title: Constants.SHOULD_HAVE,
						},
						{
							value: Constants.CAPS_PRIORITY_COULD_HAVE,
							title: Constants.COULD_HAVE,
						},
						{
							value: Constants.CAPS_PRIORITY_WONT_HAVE,
							title: Constants.WONT_HAVE,
						},
					],
				},
			],
		},
		{
			value: Constants.STATUS, //change to CAPS
			title: Constants.STATUS,
			children: [
				{
					value: Constants.CAPS_IN_PROGRESS,
					title: Constants.IN_PROGRESS,
				},
				{
					value: Constants.CAPS_OPEN,
					title: Constants.OPEN,
				},
				{
					value: Constants.CAPS_CLOSED,
					title: Constants.CLOSED,
				},
			],
		},
	];

	const [value, setValue] = useState<any[]>([]);

	const onChange = (newValue: any[]) => {
		const filteredValues = newValue.filter((val: any) => {
			return !treeData.some((item) => item.value === val);
		});
		setValue(filteredValues);
		setfilter(filteredValues);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
	};

	const handleChange = (event: any) => {
		setInput(event.target.value); // Update input value
	};

	// const handleAddWorkItemClick = () => {
	// 	setModalVisible(true);
	// };

	// const items = [
	// 	{
	// 		label: "Type",
	// 		key: "1",
	// 		icon: <UserOutlined />,
	// 	},
	// 	{
	// 		label: "Priority",
	// 		key: "2",
	// 		icon: <UserOutlined />,
	// 	},
	// 	{
	// 		label: "Status",
	// 		key: "3",
	// 		icon: <UserOutlined />,
	// 	},
	// 	{
	// 		label: "None",
	// 		key: "4",
	// 		icon: <UserOutlined />,
	// 	},
	// ];

	// const handleGroupByChange = ({ key }: { key: React.Key }) => {
	// 	const selectedItem = items.find((item) => item.key === key);
	// 	if (selectedItem) {
	// 		setSelectedGroupBy(selectedItem.label);
	// 	}
	// };

	const handleSortByChange = ({ key }: { key: React.Key }) => {
		const selectedItem = sortItems.find((item) => item.key === key);
		if (selectedItem) {
			setSortBy(selectedItem.label as any);
		}
	};

	const [modal, contextHolder] = Modal.useModal();

	const countDown = (message: any, status: any) => {
		let secondsToGo = 5;
		let instance: any;

		if (status === "fail") {
			instance = modal.error({
				title: "Error!",
				content: (
					<div>
						<Typography.Text>{message}</Typography.Text>
						<img
							src={operation_failed}
							alt="Error Image"
							style={{ maxWidth: "100%" }}
						/>
					</div>
				),
			});
		} else {
			instance = modal.success({
				title: "Success!",
				content: (
					<div>
						<Typography.Text>{message}</Typography.Text>
						<img
							src={operation_done}
							alt="Success Image"
							style={{ maxWidth: "100%" }}
						/>
					</div>
				),
			});
		}

		const timer = setInterval(() => {
			secondsToGo -= 1;
			//   instance.update({
			// 	content: (
			// 	  <div>
			// 		<p>{message}</p>
			// 		<img src={imageUrl} alt="Image" style={{ maxWidth: '100%' }} />
			// 	  </div>
			// 	),
			//   });
		}, 1000);

		setTimeout(() => {
			clearInterval(timer);
			instance.destroy();
		}, secondsToGo * 1000);
	};

	const handleNextStep = () => {
		if (screen === "planView") {
			navigate(`/space/planner/taskboard/${newPlan.planId}`);
		}
		if (screen === "workitemView") {
			setIsModalVisible(true);
			countDown(
				`Plan created successfully. Redirecting to Plans page.`,
				"pass"
			);
			setTimeout(() => {
				navigate(`/space/planner/plans`);
				setnewplan("", false, "");
			}, 3200);
		}
	};

	const onChangestep = (value: number) => {
		if (value === 0 && screen === "workitemView") {
			navigate(`/space/planner/planner?planId=${newPlan.planId}`);
		}
		if (value === 1 && screen === "workitemView") {
			navigate(`/space/planner/taskboard/${newPlan.planId}`);
		}
		if (value === 2 && screen === "workitemView") {
			navigate(`/space/planner/capacityplan/${newPlan.planId}`);
		}
	};

	const renderButton = () => {
		if (screen === "workitemView") {
			return (
				<Flex gap={8}>
					<Tooltip placement="bottom" title="List View">
						<Button
							shape="circle"
							size="middle"
							//size="small"
							//type="text"
							style={{
								//fontSize: "1.2rem",
								color: "#0444BF",
								borderColor: "#0444BF",
							}}
							onClick={setListView}
						>
							<UnorderedListOutlined />
						</Button>
					</Tooltip>
					<Tooltip placement="bottom" title="Card View">
						<Button
							shape="circle"
							size="middle"
							//size="small"
							//type="text"
							style={{
								//fontSize: "1.2rem",
								color: "#0444BF",
								borderColor: "#0444BF",
							}}
							onClick={setCardView}
						>
							<AppstoreFilled />
						</Button>
					</Tooltip>
				</Flex>
			);
		}
		return null;
	};

	return (
		<div style={{ backgroundColor: "#F5F8FE" }}>
			<div
				style={{
					marginTop: newPlan.active ? "8vh" : "3vh",
					marginBottom: newPlan.active ? "1rem" : "0rem",
				}}
			>
				{/* size={{ xs: 24, sm: 32, md: 32, lg: 32, xl: 32, xxl: 32 }} */}
				<Flex justify="space-between" align="center">
					{newPlan.active ? (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginLeft: "3vw",
								width: "100vw",
							}}
						>
							<div style={{ width: "65vw" }}>
								<Steps
									current={screen === "planView" ? 0 : 3}
									onChange={onChangestep}
									items={[
										{
											title: screen === "planView" ? "Add Features" : "",
											// description,
										},
										{
											title: "",
											// description,
											// subTitle: "Left 00:00:08",
										},
										{
											title: "",
											// description,
										},
										{
											title:
												screen === "workitemView"
													? "Assign People To Tasks"
													: "",
											// description,
										},
									]}
								/>
							</div>
							<div style={{ marginRight: "2rem" }}>
								<Button
									size="middle"
									shape="round"
									type="primary"
									onClick={handleNextStep}
								>
									Next Step
								</Button>
							</div>
						</div>
					) : (
						<Flex style={{ alignItems: "center", marginLeft: "3vw" }}>
							{/* <Space>
								<Tooltip placement="bottom" title="Back">
									<Button
										shape="circle"
										icon={<ArrowLeftOutlined />}
										size="large"
										onClick={() => navigate(-1)}
										style={{ color: "#0444BF", borderColor: "#0444BF" }}
									/>
								</Tooltip>
								<Flex vertical>
									<Breadcrumb>
										<Breadcrumb.Item>
											<h3
												color="#87d068"
												style={{ fontSize: "1.1rem", fontFamily: "Poppins" }}
											>
												{title}
											</h3>
										</Breadcrumb.Item>
									</Breadcrumb>
								</Flex>
							</Space> */}
							<BreadCrumb config={breadcrumbConfig.view} workItemId={title} />
						</Flex>
					)}

					<Flex justify="end" gap={"small"}>
						{/* <Button
							shape="round"
							size="middle"
							onClick={() => navigate(urlData.newUrl)}
						>
							{urlData.screen}
						</Button> */}

						{/* <Input
							suffix={<SearchOutlined />}
							size="middle"
							style={{ height: "3.9vh", width: "17vw", fontSize: "0.9rem" }}
							placeholder="Input search text"
							onChange={handleChange}
							value={input}
						/> */}
						{/* <Dropdown
							overlay={
								<Menu onClick={handleGroupByChange}>
									{items.map((item) => (
										<Menu.Item key={item.key} icon={item.icon}>
											{item.label}
										</Menu.Item>
									))}
								</Menu>
							}
							trigger={["click"]}
						>
							<Button size="middle" shape="round">
								<Space>
									Group by
									<DownOutlined />
								</Space>
							</Button>
						</Dropdown> */}

						{/* <Dropdown
							overlay={
								<Menu onClick={handleSortByChange}>
									{sortItems.map((item) => (
										<Menu.Item key={item.key}>{item.label}</Menu.Item>
									))}
								</Menu>
							}
							trigger={["click"]}
						>
							<Button size="middle" shape="round">
								<Space>
									Sort by
									<DownOutlined />
								</Space>
							</Button>
						</Dropdown> */}
						{/* <Flex>
							<TreeSelect
								showSearch
								size="small"
								style={{ width: "8vw", height: "100%" }}
								value={value}
								dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
								placeholder="Filter By"
								allowClear
								multiple
								treeDefaultExpandAll={false}
								onChange={onChange}
								treeData={treeData}
							/>
						</Flex> */}
						{!newPlan.active &&
							// <Flex gap={8}>
							// 	<Tooltip placement="bottom" title="List View">
							// 	<Button
							// 		shape="circle"
							// 		size="middle"
							// 		//size="small"
							// 		//type="text"
							// 		style={{
							// 			//fontSize: "1.2rem",
							// 			color: "#0444BF",
							// 			borderColor: "#0444BF"
							// 		}}
							// 		onClick={setListView}
							// 	>
							// 		<UnorderedListOutlined />
							// 	</Button>
							// 	</Tooltip>
							// 	<Tooltip placement="bottom" title="Card View">
							// 	<Button
							// 		shape="circle"
							// 		size="middle"
							// 		//size="small"
							// 		//type="text"
							// 		style={{
							// 			//fontSize: "1.2rem",
							// 			color: "#0444BF",
							// 			borderColor: "#0444BF"
							// 		}}
							// 		onClick={setCardView}
							// 	>
							// 		<AppstoreFilled />
							// 	</Button>
							// 	</Tooltip>
							// </Flex>
							renderButton()}
					</Flex>
				</Flex>
				{contextHolder}

				<CreatePlanModel visible={modalVisible} onCancel={handleModalCancel} />
			</div>
		</div>
	);
};
export default PlanViewNav;
