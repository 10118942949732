/* eslint-disable @typescript-eslint/no-unused-vars */

import { Graph, useGraphStore } from "@antv/xflow";
import { useCallback, useEffect, useState } from "react";
// import userProfileStore, { UserProfileStoreType } ';
import { message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { CUSTOMMAINNODE } from "./CoreSubDomain/CUSTOMEMAINNODE";
import { CUSTOMECHILDNODE } from "./CoreSubDomain/CUSTOMECHILDNODE";
import useWorkspaceStore from "../../stores/WorkspaceState";

function formatString(inputString: any) {
	const stringWithoutUnderscores = inputString.replace(/_/g, " ");
	return (
		stringWithoutUnderscores.charAt(0).toUpperCase() +
		stringWithoutUnderscores.slice(1)
	);
}

const InitNode = ({ id }: { id: any }) => {
	const addNodes = useGraphStore((state) => state.addNodes);
	const addEdges = useGraphStore((state) => state.addEdges);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [apiData, setApiData] = useState<any>(null);

	const { setDddSpecOnclick } = useWorkspaceStore((state: any) => ({
		setDddSpecOnclick: state.setDddSpecOnclick,
	}));

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiUrl = process.env.REACT_APP_API_URL;

				if (!apiUrl) {
					console.error(
						"REACT_APP_API_URL is not defined in your environment."
					);
					return;
				}

				const response = await fetch(
					`${apiUrl}/project-artifact/de-ddd-spec/${id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);

				if (!response.ok) {
					throw new Error(
						`status: ${response.status} message: ${response.statusText}`
					);
				}

				const data = await response.json();
				setApiData(data);
			} catch (error: any) {
				message.error(`Error: ${error.message}`);
			}
		};

		fetchData();
	}, [userProfileStoreInstance]);

	const addNodeInit = useCallback(() => {
		if (!apiData) return;

		const designSpecData =
			(apiData && apiData[0]?.data?.strategic_design) || [];
		const coreSubDomains =
			(designSpecData &&
				designSpecData.generic_sub_domains &&
				designSpecData.generic_sub_domains) ||
			null;

		if (designSpecData) {
			const nodeWidth = 190; // Width of each node
			const totalNodesWidth = coreSubDomains.length * nodeWidth; // Total width occupied by all nodes
			const startX = (30 * window.innerWidth) / 100 - totalNodesWidth / 2; // Starting X position to center nodes
			setDddSpecOnclick(coreSubDomains[0].bounded_contexts[0]);
			const nodesToAdd = coreSubDomains.map((page: any, index: number) => ({
				id: `initNode${index + 1}`,
				shape: CUSTOMMAINNODE,
				x: 50,
				y: 100 * index + 100,
				data: formatString(page.id),
			}));

			addNodes([...nodesToAdd]);

			coreSubDomains.forEach((page: any, index: number) => {
				let yOffset = 0;

				const subchildNodes = page.bounded_contexts.map(
					(storyId: any, subIndex: number) => {
						const yPos = nodesToAdd[index].y + 120 + yOffset;

						yOffset += 0;

						return {
							id: `subchildNode_${index}_${subIndex}`,
							shape: CUSTOMECHILDNODE,
							x: 300,
							// y:nodeWidth+index*(251+yOffset),
							y: 100 * index + 150,
							data: {
								page: formatString(storyId.id),

								data: storyId,
							},
						};
					}
				);

				subchildNodes.forEach((subchildNode: any) => {
					addEdges([
						{
							source: nodesToAdd[index].id,
							sourcePort: "group3",
							target: subchildNode.id,
							targetPort: "group1",
							attrs: {
								line: {
									"stroke-width": 1,
									stroke: "black",
								},
							},
						},
					]);
				});

				addNodes(subchildNodes);
			});
		}
	}, [apiData, addEdges, addNodes]);

	useEffect(() => {
		addNodeInit();
	}, [addNodeInit]);

	return null;
};

export { InitNode, CUSTOMMAINNODE };
