import React, { useState, useEffect } from "react";
import { Flex, Layout, theme, Spin } from "antd";
import { Outlet } from "react-router-dom";
import DDDMenu from "../pages/Design-artifacts/DDDMenu";
import breadcrumbConfig from "../util/BreadCrumbsUtil";
import BreadCrumb from "../pages/Editors/BreadCrumb";
const { Sider, Content } = Layout;

function DDDLayout() {
	const {
		token: { colorPrimaryBg },
	} = theme.useToken();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false); // 1.5 sec
		}, 1500);
		return () => clearTimeout(timeout);
	}, []);
	return (
		<div>
			{loading ? (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "100vh",
					}}
				>
					<Spin size="large" />
					<img
						style={{ marginTop: "10px", width: "150px" }}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
						alt="Loading"
					/>
					<Spin size="large" />
				</div>
			) : (
				<>
					<Layout style={{ background: "#f5f8fe" }}>
						<Flex
							justify="start"
							style={{
								alignItems: "start",
								flexDirection: "column",
								width: "80%",
							}}
						>
							{/* <h2
								style={{
									color: "black",
									fontSize: "1.1rem",
									marginLeft: "50px",
									marginTop: "5vh",
									marginBottom: "1vh",
								}}
							>
								Architecture
							</h2> */}
							<div
								style={{
									fontSize: "1.1rem",
									marginLeft: "50px",
									marginTop: "5vh",
									marginBottom: "1vh",
								}}
							>
								<BreadCrumb config={breadcrumbConfig.architecture} />
							</div>
							{/* <Content style={{ backgroundColor: "rgb(250, 250, 255) " }}> */}
							<Content style={{ backgroundColor: "#f5f8fe" }}>
								<Outlet />
							</Content>
						</Flex>
						<Sider width={280} style={{ backgroundColor: colorPrimaryBg }}>
							<DDDMenu />
						</Sider>
					</Layout>
				</>
			)}
		</div>
	);
}

export default DDDLayout;
