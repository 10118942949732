import React, { useEffect, useState } from "react";
import { Graph } from "@antv/x6";
import "./DesignSpecCore.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import {
	Button,
	Card,
	Flex,
	Input,
	Layout,
	Modal,
	Tabs,
	Typography,
	Result,
	Spin,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import "./StrategicDesign.css";
// import CoreSubDomain from "./CoreSubDomain";
// import GenericSubDomains from "./GenericSubDomain";
// import SupportingSubDomain from "./SupportingSubDomain";
import { useParams } from "react-router-dom";
import useWorkspaceStore from "../../stores/WorkspaceState";
import usePlatformStore from "../../stores/platformStore";
import { getNavigateUrlForWorkspaces } from "../../util/NavigateAssistUtil";
import Constants from "../../util/ConstantsUtil";
import SupportPage from "./CoreSubDomain/index";
import SupportSubDomainIndex from "./CoreSubDomain/SupportiveSubDomainIndex";
import GenericSubDomainIndex from "./CoreSubDomain/GenericSubDomianIndex";
import ContextMappingIndex from "./CoreSubDomain/ContexMappingIndex";
// import SupportingSubDomainv2 from "./SupportingSubDomainv2";
import { SmileOutlined } from "@ant-design/icons";

function formatString(inputString: any) {
	console.log(inputString);
	if (inputString) {
		const stringWithoutUnderscores = inputString.replace(/_/g, " ");
		return (
			stringWithoutUnderscores.charAt(0).toUpperCase() +
			stringWithoutUnderscores.slice(1)
		);
	}
}

const StrategicDesign: React.FC = () => {
	// const [comments, setComments] = useState<any[]>([]);
	const [newCommentContent, setNewCommentContent] = useState<string>("");
	//const [openCommentBox, setOpenCommentBox] = useState(false);
	// const [contextMenuPosition, setContextMenuPosition] = useState<{
	// 	x: number;
	// 	y: number;
	// } | null>(null);
	const [selectedPath, setSelectedPath] = useState<string | null>(null);
	const [showPopOver, setShowPopOver] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(true); // New loading state
	//const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);
	const { isReviewEnabled } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
		isReviewEnabled: state.isReviewEnabled,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [modalPosition, setModalPosition] = useState<{
		top: number;
		left: number;
	} | null>(null);
	const [designSpecData, setDesignSpecData] = useState<any>(null);
	// const [isPopoverVisible, setPopoverVisibility] = useState(false);
	const [commentPosted, setCommentPosted] = useState(false);
	const [data, setData] = useState<any>([]);
	const [activeTab, setActiveTab] = useState<string>("1");
	const { id } = useParams<{ id: string }>();
	const { currentWorkspace, setCurrentActivity } = useWorkspaceStore(
		(state: any) => ({
			currentWorkspace: state.currentWorkspace,
			setCurrentActivity: state.setCurrentActivity,
		})
	);
	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
		setUnauthorizedModel,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	useEffect(() => {
		const navigationUrls = getNavigateUrlForWorkspaces(
			`${id}`,
			currentWorkspace.modules,
			Constants.DESIGN_ENGINEERING,
			Constants.DE_DDD_STRATEGIC_DESGIN
		);
		setNextNavigateUrl(navigationUrls.nextUrl);
		setBackNavigateUrl(navigationUrls.prevUrl);
		setAppBarTitle(Constants.DE_DDD_STRATEGIC_DESGIN);
		setCurrentModule(Constants.DESIGN_ENGINEERING);
		setCurrentPage(Constants.DE_DDD_STRATEGIC_DESGIN);
	}, [currentWorkspace]);
	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			const fetchData = async () => {
				try {
					const response = await fetch(
						`${process.env.REACT_APP_API_URL}/project-artifact/de-ddd-spec/${id}`,
						{
							headers: {
								Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
							},
						}
					);

					if (!response.ok) {
						if (response.status === 401) {
							setUnauthorizedModel(true);
						}
						throw new Error(`HTTP error! Status: ${response.status}`);
					}

					const data = await response.json();
					setData(data);
					setCurrentActivity(data[0]);
					setDesignSpecData(data[0]?.data?.strategic_design || []);
					console.log(designSpecData, "designSpecData");
				} catch (error) {
					console.error("Error fetching data:", error);
				} finally {
					setIsLoading(false); // Set isLoading to false after data fetching
				}
			};

			fetchData();
		}
	}, [userProfileStoreInstance.idToken]);

	// const addComment = (content: string, path: any) => {
	// 	const newComment: any = {
	// 		content,
	// 		path,
	// 	};
	// 	setComments([...comments, newComment]);
	// };

	// Define a function to check if designSpecData is empty
	const isDesignSpecDataEmpty = (data: NonNullable<unknown>) => {
		return !data || Object.keys(data).length === 0;
	};

	const postComment = () => {
		const newComment = {
			comment: newCommentContent,
			path: selectedPath,
		};

		const newData = [...data];
		const userReview = newData[0].userReview || [];
		userReview.push(newComment);
		newData[0].userReview = userReview;
		setCommentPosted(true);

		setNewCommentContent("");

		setShowPopOver(false);

		fetch(`${process.env.REACT_APP_API_URL}/describe/${id}/de-ddd-spec`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(newData[0]),
		})
			.then((response) => response.json())
			.then((res) => {
				console.log("dev", res);
			})
			.catch((error) => {
				console.log(error);
			});

		setCommentPosted(true);
	};

	console.log(commentPosted);
	console.log("check", isReviewEnabled);

	useEffect(() => {
		if (designSpecData) {
			const graphContainer = document.getElementById("x6-graph-container");

			if (graphContainer) {
				graphContainer.innerHTML = "";

				const graph = new Graph({
					container: graphContainer,
					grid: false,
					async: true,
					background: { color: "white" },
					interacting: {
						nodeMovable: false,
						edgeMovable: false,
						vertexMovable: false,
					},
				});

				const createdNodes = new Map<string, any>();
				let sourceX = 170;
				let targetX = 170;

				const context_mapping = designSpecData.context_mapping;

				context_mapping?.forEach((entry: any) => {
					const [source, target] = entry.between;
					if (!createdNodes.has(source)) {
						const sourceNode = graph.addNode({
							shape: "rect",
							width: 220,
							height: 120,
							label: formatString(source),
							position: { x: sourceX, y: 320 },
							attrs: {
								body: {
									rx: 20,
									ry: 20,
									strokeWidth: 1,
									stroke: "rgba(52, 120, 246, 1)",
									fill: "rgba(232, 239, 254, 1)",
								},
								label: {
									padding: 20,
									class: "node-label",
								},
							},
						});

						graph.on("cell:mouseenter", (cellView: any) => {
							const cell = cellView.cell;
							if (isReviewEnabled) {
								cell.attr("body/fill", "rgba(52, 120, 246, 0.8)");
							}
						});

						graph.on("cell:mouseleave", (cellView: any) => {
							const cell = cellView.cell;
							if (isReviewEnabled) {
								cell.attr("body/fill", "rgba(232, 239, 254, 1)");
							}
						});

						graph.on("cell:contextmenu", (cellView: any) => {
							const cell = cellView.cell;

							if (isReviewEnabled) {
								const nodePosition = cellView.cell.position();
								const clickedPath = cell.attr("body/data-path") || "";
								setSelectedPath(clickedPath);

								setShowPopOver(true);
								setModalPosition({
									top: nodePosition.y + 100,
									left: nodePosition.x + 450,
								});
							}
						});

						createdNodes.set(source, sourceNode);
						sourceX += 450;
					}
					if (!createdNodes.has(target)) {
						const targetNode = graph.addNode({
							shape: "rect",
							width: 220,
							height: 120,
							label: formatString(target),
							position: { x: targetX, y: 10 },
							attrs: {
								body: {
									rx: 20,
									ry: 20,
									strokeWidth: 1,
									stroke: "rgba(52, 120, 246, 1)",
									fill: "rgba(232, 239, 254, 1)",
								},
								label: {
									padding: 20,
									class: "node-label",
								},
							},
						});

						createdNodes.set(target, targetNode);
						targetX += 450;
					}

					const direction = entry.direction;
					const type = entry.type;

					if (direction === "Upstream to Downstream") {
						graph.addEdge({
							source: { cell: createdNodes.get(source) },
							labels: [
								{
									position: 0.3,
									attrs: {
										text: {
											text: formatString(type),
											class: "connection-label",
										},
									},
								},
							],
							target: { cell: createdNodes.get(target) },
							attrs: {
								line: {
									stroke: "rgba(0, 0, 0, 0.5)",
									strokeWidth: 1,
								},
							},
						});
					} else if (direction === "Downstream to Upstream") {
						graph.addEdge({
							source: { cell: createdNodes.get(target) },
							target: { cell: createdNodes.get(source) },
							labels: [
								{
									position: 0.4,
									attrs: {
										text: {
											text: formatString(type),
											class: "connection-label",
										},
									},
								},
							],
							attrs: {
								line: {
									stroke: "rgba(0, 0, 0, 0.5)",
									strokeWidth: 1,
								},
							},
						});
					}
				});
			}
		}
	}, [designSpecData, isReviewEnabled]);

	return (
		<div>
			<Layout style={{ backgroundColor: "#f5f8fe", marginTop: "1rem" }}>
				<Card
					className="content-dimensions"
					style={{ padding: "0px 0px 0px 12px", marginLeft: "2vw" }}
				>
					<h2 style={{ textAlign: "left", marginTop: "-12px" }}>
						Strategic Design
					</h2>
					{isLoading ? (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								minHeight: "50vh",
							}}
						>
							<Spin size="large" />
							<img
								style={{ marginTop: "10px", width: "150px" }}
								src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
								alt="Loading"
							/>
							<Spin size="large" />
						</div>
					) : isDesignSpecDataEmpty(designSpecData) ? (
						<Result
							icon={<SmileOutlined style={{ color: "#0444BF" }} />}
							title="There are No Design Found!!"
							style={{ marginTop: "10vh", marginLeft: "-10vw" }}
						/>
					) : (
						<>
							<Tabs
								className="tabs-ddd"
								type="card"
								defaultActiveKey="1"
								activeKey={activeTab}
								onChange={(key) => setActiveTab(key)}
							>
								<TabPane tab="Context Mapping" key="1">
									<ContextMappingIndex />
								</TabPane>
								<TabPane tab="Core Sub Domains" key="2">
									<div className="tab-overflow">
										<SupportPage />
									</div>
								</TabPane>
								<TabPane tab="Generic Sub Domains" key="3">
									<div className="tab-overflow">
										<GenericSubDomainIndex />
									</div>
								</TabPane>
								<TabPane tab="Supporting Sub Domains" key="4">
									<div className="tab-overflow">
										<SupportSubDomainIndex />
									</div>
								</TabPane>
							</Tabs>
							{showPopOver && (
								<Modal
									width={350}
									onCancel={() => {
										setShowPopOver(false);
									}}
									visible={showPopOver}
									mask={false}
									footer={false}
									style={{
										position: "absolute",
										top: modalPosition?.top,
										left: modalPosition?.left,
									}}
								>
									<Flex justify="space-between">
										<Typography.Title level={5}>
											{" "}
											Add a Comment{" "}
										</Typography.Title>
									</Flex>
									<div>
										<Input.TextArea
											style={{ height: "15vh", marginBottom: "1vh" }}
											placeholder="Type your comment here..."
											onChange={(e) => setNewCommentContent(e.target.value)}
											value={newCommentContent}
										/>
										<Button type="primary" onClick={postComment}>
											Post Comment
										</Button>
									</div>
								</Modal>
							)}
						</>
					)}
				</Card>
			</Layout>
		</div>
	);
};

export default StrategicDesign;
