import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { List, Space, Tag, Flex, Collapse, Table, Avatar, Tooltip } from "antd";
import { Tag, Collapse, Table, Avatar, Tooltip } from "antd";
// import { EllipsisOutlined, EyeOutlined } from "@ant-design/icons";
import {
	DeploymentUnitOutlined,
	EyeOutlined,
	MessageOutlined,
	WechatWorkOutlined,
} from "@ant-design/icons";
import { useViewsStores } from "../../stores/BacklogStore";
import { planerViewStore } from "../../stores/PlannerStore";

import { useLocation } from "react-router-dom";
import "./ListView.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import BacklogDropdownMenu from "./BacklogContextMenu";

const BacklogListViewItem: React.FC<any> = ({ Data }: { Data: any[] }) => {
	const navigate = useNavigate();
	const { selectedGroupBy, setDrawerTab } = useViewsStores();
	const { setOpen, setSelectedItemDetails } = planerViewStore();
	const url = useLocation().pathname;
	//const [parentId, setParentId] = useState("");
	//const [todos, setTodos] = useState<any[]>([]);
	//const [expandedRowKey, setExpandedRowKey] = useState<string | number>("");
	const [selectRec, setSelectRec] = useState<any>();
	const [tags, setTags] = useState<any[]>([]);
	const [newData, setnewData] = useState<any[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	useEffect(() => {
		setnewData(Data);
	}, [Data]);

	const setGroupKey = (selectedGroupBy: string) => {
		switch (selectedGroupBy) {
			case "Type":
				return "type";
			case "Priority":
				return "priority";
			case "Status":
				return "status";
			case "release":
				return "release";
			default:
				if (url === "/space/planner/backlogs") {
					return "None";
				} else if (url === "/space/planner/mytasks") {
					return "status";
				} else {
					return "release";
				}
		}
	};

	const groupKey = setGroupKey(selectedGroupBy);

	const groupedTasks = groupBy(newData, groupKey);

	const handleEyeIconClick = (item: any, newData: any[]) => {
		setOpen(true);
		setDrawerTab("details");

		const selectedItemData = newData.find(
			(dataItem) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);
	};

	const handleEditor = (item: any) => {
		const selectedItemData = newData.find(
			(dataItem) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);

		navigate(`/space/planner/editor/${selectedItemData._id}`);
	};

	const handleMapIconClick = (item: any, newData: any[]) => {
		setOpen(true);
		setDrawerTab("mapview");

		const selectedItemData = newData.find(
			(dataItem) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);
	};
	const handleChatIconClick = (item: any, newData: any[]) => {
		setOpen(true);
		setDrawerTab("discussion");

		const selectedItemData = newData.find(
			(dataItem) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);
	};

	useEffect(() => {
		const fetchTagsData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/team-members?workspace-id=${currentWorkspace._id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (response.ok) {
					const data = await response.json();
					setTags(data);
				} else {
					console.error("Error fetching tags data");
				}
			} catch (error) {
				console.error("Error fetching tags data:", error);
			}
		};

		fetchTagsData();
	}, []);

	{
		/*{function removePTags(text: string) {
		return text.replace(/<p>/g, "").replace(/<\/p>/g, "");
	}} */
	}

	console.log(tags, "tags");

	const columns = [
		// {
		// 	title: "",
		// 	dataIndex: "",
		// 	key: "WorkitemId",
		// 	render: (text: any, item: any) => (
		// 		<div style={{ display: "flex", alignItems: "center" }}>
		// 			<EyeOutlined
		// 				onClick={() => handleEyeIconClick(item, Data)}
		// 				style={{ fontSize: "1.1rem", marginRight: "1vw" }}
		// 			/>
		// 			{/* <a style={{ color: "black", fontWeight: "bold" }}>{text}</a> */}
		// 		</div>
		// 	),
		// },
		// {
		// 	title: "",
		// 	dataIndex: "",
		// 	key: "WorkitemId",
		// 	render: (text: any, item: any) => (
		// 		<div style={{ display: "flex", alignItems: "center" }}>
		// 			<MessageOutlined
		// 				onClick={() => handleChatIconClick(item, Data)}
		// 				style={{ fontSize: "1.1rem", marginRight: "1vw" }}
		// 			/>
		// 			{/* <a style={{ color: "black", fontWeight: "bold" }}>{text}</a> */}
		// 		</div>
		// 	),
		// },
		{
			title: (
				<div style={{ fontFamily: "Poppins", marginLeft: "3rem" }}>Action</div>
			),
			key: "action",
			width: 150,
			render: (text: any, item: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginRight: "1vw",
						}}
					>
						<EyeOutlined
							onClick={() => handleEyeIconClick(item, Data)}
							style={{
								fontSize: "1.1rem",
								//marginRight: "1vw",
								marginLeft: "1vw",
								//color: "#FCA311"
							}}
						/>
					</div>
					<div style={{ display: "flex", alignItems: "center" }}>
						<WechatWorkOutlined
							onClick={() => handleEditor(item)}
							style={{
								fontSize: "1.1rem",
								marginRight: "1vw",
								//color: "#FCA311"
							}}
						/>
						<DeploymentUnitOutlined
							onClick={() => handleMapIconClick(item, Data)}
							style={{
								fontSize: "1.1rem",
								marginRight: "1vw",
								//color: "#FCA311"
							}}
						/>
					</div>
					<div style={{ display: "flex", alignItems: "center" }}>
						<MessageOutlined
							onClick={() => handleChatIconClick(item, Data)}
							style={{
								fontSize: "1.1rem",
								marginRight: "1vw",
								//color: "#FCA311"
							}}
						/>
					</div>
				</div>
			),
		},
		{
			//title: "ID",
			title: <div style={{ fontFamily: "Poppins" }}>ID</div>,
			dataIndex: "WorkitemId",
			key: "WorkitemId",
			width: 50,
			render: (text: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					{/* <EyeOutlined
						onClick={() => handleIconClick(item, Data)}
						style={{ fontSize: "1.1rem", marginRight: "1vw" }}
					/> */}
					<a
						style={{
							color: "black",
							fontWeight: "bold",
							fontFamily: "Open Sans",
						}}
					>
						{text}
					</a>
				</div>
			),
		},
		{
			//title: "Title",
			title: <div style={{ fontFamily: "Poppins" }}>Title</div>,
			dataIndex: "title",
			key: "title",
			width: 550,
			render: (text: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<a
						style={{
							color: "black",
							fontWeight: "bold",
							fontFamily: "Open Sans",
						}}
					>
						{text}
					</a>
				</div>
			),
		},
		// {
		// 	title: "Description",
		// 	dataIndex: "description",
		// 	key: "description",
		// 	render: (text: any) => (
		// 		<div style={{ display: "flex", alignItems: "center" }}>
		// 			<a
		// 				style={{ color: "black", fontWeight: "bold" }}
		// 				// dangerouslySetInnerHTML={{ __html: text }}
		// 			>
		// 				{removePTags(text)}
		// 			</a>
		// 		</div>
		// 	),
		// },
		{
			//title: "Priority",
			title: <div style={{ fontFamily: "Poppins" }}>Priority</div>,
			dataIndex: "priority",
			key: "priority",
			width: 100,
			render: (priority: any) => (
				<Tag color="blue" style={{ color: "black", fontFamily: "Open Sans" }}>
					{priority?.charAt(0)}
				</Tag>
			),
		},
		{
			//title: "Type",
			title: <div style={{ fontFamily: "Poppins" }}>Type</div>,
			dataIndex: "type",
			key: "type",
			width: 100,
			render: (type: any) => (
				<Tag color="yellow" style={{ color: "black", fontFamily: "Open Sans" }}>
					{type}
				</Tag>
			),
		},
		{
			//title: "Status",
			title: <div style={{ fontFamily: "Poppins" }}>Status</div>,
			dataIndex: "status",
			key: "status",
			width: 130,
			render: (type: any) => <div>{type.toUpperCase()}</div>,
		},
		{
			//title: "Assign",
			title: <div style={{ fontFamily: "Poppins" }}>Assign</div>,
			dataIndex: "assignee",
			key: "assignee",
			width: 100,
			render: (assigneeData: any) => {
				if (selectedGroupBy === "None") {
					// const assigneeNameWords =
					// 	assigneeData?.name || assigneeData?.split(" ") || [];
					// const firstName = assigneeNameWords[0] || "";
					// const lastName =
					// 	assigneeNameWords.length > 1
					// 		? assigneeNameWords[assigneeNameWords.length - 1]
					// 		: "";

					// const avatarText = `${firstName.charAt(0)}${lastName.charAt(
					// 	0
					// )}`.toUpperCase();
					const assigneeNameWords = assigneeData?.name?.split(" ") || [];
					const firstName = assigneeNameWords[0] || "";
					const lastName =
						assigneeNameWords.length > 1
							? assigneeNameWords[assigneeNameWords.length - 1]
							: "";

					const avatarText = `${firstName.charAt(0)}${lastName.charAt(
						0
					)}`.toUpperCase();

					const pastelColor = assigneeData?.preferences?.profileColor
						? assigneeData.preferences.profileColor
						: getRandomPastelColor();

					return (
						<Tooltip title={`${assigneeData?.name}`} placement="top">
							<Avatar
								style={{
									backgroundColor: pastelColor,
								}}
							>
								{avatarText}
							</Avatar>
						</Tooltip>
					);
				} else {
					const assigneeNameWords = assigneeData?.name?.split(" ") || [];
					const firstName = assigneeNameWords[0] || "";
					const lastName =
						assigneeNameWords.length > 1
							? assigneeNameWords[assigneeNameWords.length - 1]
							: "";

					const avatarText = `${firstName.charAt(0)}${lastName.charAt(
						0
					)}`.toUpperCase();

					const pastelColor = assigneeData?.preferences?.profileColor
						? assigneeData.preferences.profileColor
						: getRandomPastelColor();

					return (
						<Tooltip title={`${assigneeData?.name}`} placement="top">
							<Avatar
								style={{
									backgroundColor: pastelColor,
								}}
							>
								{avatarText}
							</Avatar>
						</Tooltip>
					);
				}
			},
		},
		// {
		// 	//title: "Action",
		// 	title: <div style={{ fontFamily: "Poppins" }}>Action</div>,
		// 	key: "action",
		// },
	];

	const data = newData
		.filter((item) => item.type !== "task" && item.type !== "WORKFLOW_TASK")
		.map((item) => ({
			WorkitemId: item.WorkitemId,
			key: item._id,
			title: item.title,
			description: item.description,
			priority: item.priority,
			type: item.type,
			status: item.status,
			story: item.story,
			assignee: item?.assignee,
			assigneeId: item?.assignee?._id,
		}));

	const getRandomPastelColor = () => {
		// const hue = Math.floor(Math.random() * 360);
		const hue = 219;
		const pastelColor = `hsl(${hue}, 80%, 80%)`;
		return pastelColor;
	};
	const [menuVisible, setMenuVisible] = React.useState(false);
	const [position, setPosition] = React.useState({
		x: 0,
		y: 0,
	});

	//////////////////////////////////////Case: No grouping is applied///////////////////////////////////////////////////

	if (selectedGroupBy === "None") {
		return (
			<>
				<BacklogDropdownMenu
					open={menuVisible}
					pos={position}
					selectedRec={selectRec}
				/>
				<Table
					columns={columns}
					size="small"
					onRow={(record, rowIndex) => {
						return {
							onContextMenu: (event) => {
								event.preventDefault();
								console.log("Right clicked on row ", record);
								setSelectRec(record);
								console.log(rowIndex);
								//event.preventDefault();
								if (!menuVisible) {
									document.addEventListener(`click`, function onClickOutside() {
										setMenuVisible(false);
										document.removeEventListener(`click`, onClickOutside);
									});
								}
								setMenuVisible(true);
								setPosition({ x: event.clientX, y: event.clientY });
							},
						};
					}}
					//expandedRowRender={expandedRowRender}
					scroll={{ y: 345 }}
					dataSource={data}
					// expandable={{
					// 	expandedRowKeys: [expandedRowKey],
					// 	onExpand: (expanded, record) => {
					// 		setExpandedRowKey(expanded ? record.key : "");
					// 		if (expanded) {
					// 			handleCollapse(record);
					// 		}
					// 	},
					// }}
				>
					{/* <Table.Column title="ID" dataIndex="WorkitemId" key="WorkitemId" />
					<Table.Column title="Title" dataIndex="title" key="title" />
					<Table.Column title="Description" dataIndex="description" key="description" />
					<Table.Column title="Priority" dataIndex="priority" key="priority" />
					<Table.Column title="Type" dataIndex="type" key="type" />
					<Table.Column title="Status" dataIndex="status" key="status" />
					<Table.Column title="Action" key="action" />   */}
				</Table>
			</>
		);
	}

	//////////////////////////////////////Case: Grouping is applied///////////////////////////////////////////////////
	return (
		<Collapse ghost>
			{Object.entries(groupedTasks)
				.filter(([groupKey]) => groupKey !== "undefined")
				.map(([groupKey, tabledata]) => (
					<Collapse.Panel
						key={groupKey}
						header={<div style={{ textAlign: "left" }}>{groupKey}</div>}
					>
						<BacklogDropdownMenu
							open={menuVisible}
							pos={position}
							selectedRec={selectRec}
						/>
						<Table
							columns={columns}
							onRow={(record, rowIndex) => {
								return {
									onContextMenu: (event) => {
										event.preventDefault();
										console.log("Right clicked on row ", record);
										setSelectRec(record);
										console.log(rowIndex);
										//event.preventDefault();
										if (!menuVisible) {
											document.addEventListener(
												`click`,
												function onClickOutside() {
													setMenuVisible(false);
													document.removeEventListener(`click`, onClickOutside);
												}
											);
										}
										setMenuVisible(true);
										setPosition({ x: event.clientX, y: event.clientY });
									},
								};
							}}
							dataSource={(tabledata as any[])
								.filter(
									(item: any) =>
										item.type !== "task" && item.type !== "WORKFLOW_TASK"
								)
								.map((item: any) => ({
									WorkitemId: item.WorkitemId,
									key: item._id,
									title: item.title,
									description: item.description,
									priority: item.priority,
									type: item.type,
									status: item.status,
									story: item.story,
									assignee: item?.assignee,
									assigneeId: item?.assignee?._id,
								}))}
							pagination={false}
							// ).map((item) => ({
							// 								...item,
							// 								key: item._id,
							// 							}))}
							// expandable={{
							// 	expandedRowKeys: [expandedRowKey],
							// 	onExpand: (expanded, record) => {
							// 		setExpandedRowKey(expanded ? record.key : "");
							// 		if (expanded) {
							// 			handleCollapse(record);
							// 		}
							// 	},
							// }}
						/>
					</Collapse.Panel>
				))}
		</Collapse>
	);

	function groupBy(array: any[], key: string) {
		return array.reduce((result: any, currentItem: any) => {
			const groupKey = currentItem[key];
			if (groupKey !== undefined) {
				// Check if groupKey is defined
				if (!result[groupKey]) {
					result[groupKey] = [];
				}
				result[groupKey].push(currentItem);
			}
			return result;
		}, {});
	}
};

export default BacklogListViewItem;
