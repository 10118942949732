import { Button, Menu, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { useRequest } from "ahooks";
import { addPage, getNotesbyId } from "../../../api/Notes";

function RightPannel({
	note_id,
	selectedPage,
	setselectedPage,
	noteData,
	setnoteData,
}: {
	note_id: string;
	selectedPage: any;
	setselectedPage: any;
	noteData: any;
	setnoteData: any;
}) {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	//   const [noteData, setNoteData] = useState<any>({});
	const [isInputVisible, setIsInputVisible] = useState(false);
	const [newPageTitle, setNewPageTitle] = useState("");

	const { run: fetchNoteById } = useRequest(
		(id) => getNotesbyId(id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data, "note data");
			},

			onError: (error) => {
				message.error(`Error: ${error.message}`);
			},
		}
	);

	useEffect(() => {
		fetchNoteById(note_id);
	}, [note_id]);

	const { run: runAddNewPage } = useRequest(
		(body, id) => addPage(body, id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data, "data");

				fetchNoteById(note_id);
				setnoteData(data);
				setNewPageTitle("");
				setIsInputVisible(false);
				message.success("Page added successfully");
			},
			onError: (error) => {
				console.log(error, "error");
				message.error(`Error: ${error.message}`);
			},
		}
	);

	const AddnewPage = async () => {
		const newPage = {
			pageTitle: newPageTitle,
			pageContent: [],
			createdBy: userProfileStoreInstance.profile._id,
		};

		runAddNewPage(newPage, note_id);
	};

	const handleInputKeyPress = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key === "Enter") {
			AddnewPage();
		}
	};

	return (
		<>
			<div style={{ textAlign: "right", margin: "1rem 0.5rem 1rem 0rem" }}>
				<Button type="primary" onClick={() => setIsInputVisible(true)}>
					Add Page
				</Button>
			</div>
			{isInputVisible && (
				<Input
					value={newPageTitle}
					onChange={(e) => setNewPageTitle(e.target.value)}
					onKeyPress={handleInputKeyPress}
					placeholder="Enter page title"
				/>
			)}
			<Menu selectedKeys={[selectedPage?._id]}>
				{noteData.pages?.length > 0 &&
					noteData.pages.map((page: any) => {
						return (
							<Menu.Item
								style={{ textAlign: "left" }}
								onClick={() => {
									setselectedPage(page);
								}}
								key={page._id}
							>
								{page.pageTitle}
							</Menu.Item>
						);
					})}
			</Menu>
		</>
	);
}

export default RightPannel;
