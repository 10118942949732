import React, { useEffect, useState } from "react";
import {
	Button,
	Flex,
	Input,
	Layout,
	Menu,
	Popover,
	Typography,
	message,
	theme,
} from "antd";
//import SwaggerUI from "swagger-ui-react"; // Import Swagger UI
//import "swagger-ui-react/swagger-ui.css";
import "./ApiDesign.css";
import { useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import usePlatformStore from "../../stores/platformStore";
import { getNavigateUrlForWorkspaces } from "../../util/NavigateAssistUtil";
import Constants from "../../util/ConstantsUtil";
import { CloseOutlined } from "@ant-design/icons";
const { Sider, Content } = Layout;

function formatString(inputString: any) {
	const stringWithoutUnderscores = inputString.replace(/_/g, " ");
	return (
		stringWithoutUnderscores.charAt(0).toUpperCase() +
		stringWithoutUnderscores.slice(1)
	);
}

const ApiDesign: React.FC = () => {
	// const [comments, setComments] = useState<any[]>([]);
	const [newCommentContent, setNewCommentContent] = useState<string>("");
	//const [openCommentBox, setOpenCommentBox] = useState(false);
	const [contextMenuPosition, setContextMenuPosition] = useState<{
		x: number;
		y: number;
	} | null>(null);
	const [selectedPath, setSelectedPath] = useState<string | null>(null);
	//const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);

	const { isReviewEnabled } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
		isReviewEnabled: state.isReviewEnabled,
	}));
	const [contentClassName, setContentClassName] = useState(
		"StrategicDesignContent"
	);
	const {
		token: { colorPrimaryBg },
	} = theme.useToken();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [data, setData] = useState<any>([]);
	const [selectedEndpoint, setSelectedEndpoint] = useState<any>(null);
	const [selectedPropertyKey, setSelectedPropertyKey] = useState<string | null>(
		null
	);
	const [selectedPropertyvalue, setSelectedPropertyValue] = useState<
		string | null
	>(null);
	const [Keys, setKeys] = useState<string | null>(null);
	//const [showSwaggerUI, setShowSwaggerUI] = useState<boolean>(false);

	const { id } = useParams<{ id: string }>();
	const { currentWorkspace, setCurrentActivity } = useWorkspaceStore(
		(state: any) => ({
			currentWorkspace: state.currentWorkspace,
			setCurrentActivity: state.setCurrentActivity,
		})
	);
	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
		setUnauthorizedModel,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	useEffect(() => {
		const navigationUrls = getNavigateUrlForWorkspaces(
			`${id}`,
			currentWorkspace?.modules || [],
			Constants.DESIGN_ENGINEERING,
			Constants.DE_API_SPECIFICATION
		);
		setNextNavigateUrl(navigationUrls.nextUrl);
		setBackNavigateUrl(navigationUrls.prevUrl);
		setAppBarTitle(Constants.DE_API_SPECIFICATION);
		setCurrentModule(Constants.DESIGN_ENGINEERING);
		setCurrentPage(Constants.DE_API_SPECIFICATION);
	}, [currentWorkspace]);

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${id}/de-api-spec`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const result = await response.json();
			if (!response.ok) {
				throw new Error(
					`Status: ${response.status} Message: ${result.message}`
				);
			}

			if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				setUnauthorizedModel(false);
			}

			setData(result);
			setCurrentActivity(result[0]);
			const firstEndpoint = result[0]?.aiData?.endpoints[0];
			setSelectedEndpoint(firstEndpoint);
			const firstButton = Object.keys(firstEndpoint?.openapi_spec || {})[0];
			const firstButtonValue = Object.keys(
				firstEndpoint?.openapi_spec[firstButton] || {}
			)[0];
			setSelectedPropertyKey(firstButton);
			setSelectedPropertyValue(firstButtonValue);
			setKeys(firstButtonValue);
		} catch (error) {
			message.error(`Error: ${error}`);
		}
	};

	const handleMenuClick = (endpointId: string) => {
		const selectedEndpoint = data[0]?.aiData?.endpoints.find(
			(endpoint: any) => endpoint.id === endpointId
		);
		setSelectedEndpoint(selectedEndpoint);
		const firstButton = Object.keys(selectedEndpoint?.openapi_spec || {})[0];
		const firstButtonValue = Object.keys(
			selectedEndpoint?.openapi_spec[firstButton] || {}
		)[0];
		setSelectedPropertyKey(firstButton);
		setSelectedPropertyValue(firstButtonValue);
		setKeys(firstButtonValue);
		//setShowSwaggerUI(false);
	};

	console.log(selectedPropertyKey, selectedPropertyvalue, Keys);

	// const handleButtonClick = (
	// 	propertyKey: string,
	// 	nestedValue: any,
	// 	key: any
	// ) => {
	// 	setSelectedPropertyKey(propertyKey);
	// 	setSelectedPropertyValue(nestedValue);
	// 	setKeys(key);
	// 	console.log(key);
	// };

	// const handleSwaggerToggle = () => {
	// 	setShowSwaggerUI(!showSwaggerUI);
	// };

	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			fetchData();
		}
	}, [userProfileStoreInstance.idToken]);

	const endpointIds =
		data[0]?.aiData?.endpoints.map((endpoint: any) => endpoint.id) || [];

	console.log(data);

	useEffect(() => {
		setContentClassName(
			isReviewEnabled ? "StrategicDesignContentHover" : "StrategicDesignContent"
		);
	}, [isReviewEnabled]);

	const handleRightClick = (e: React.MouseEvent) => {
		e.preventDefault();

		if (isReviewEnabled) {
			// setContentClassName(isCommentButtonClicked ? "FeatureSpecificationLayoutContentHover" : "FeatureSpecificationLayoutContent");
			const clickedElement = e.target as HTMLElement;
			const clickedPath = clickedElement.dataset.path || "";
			console.log(clickedPath); // Use an empty string as a fallback
			setContextMenuPosition({ x: e.clientX, y: e.clientY });
			console.log(clickedPath, "clickedpath");
			setSelectedPath(clickedPath);
		} else {
			// setContentClassName("FeatureSpecificationLayoutContent");
		}
	};

	// const addComment = (content: string, path: any) => {
	// 	const newComment: any = {
	// 		content,
	// 		path,
	// 	};
	// 	setComments([...comments, newComment]);
	// };

	const postComment = async () => {
		try {
			if (newCommentContent.trim() !== "" && selectedPath) {
				const updatedComment = {
					comment: newCommentContent,
					path: selectedPath,
				};

				const commentData = {
					workspace: data[0].workspace,
					module: data[0].module,
					section: data[0].section,
					context: data[0].context,
					input: data[0].input,
					aiData: data[0].aiData,
					userReview: [...data[0].userReview, updatedComment],
					createdBy: data[0].createdBy,
					updatedBy: data[0].updatedBy,
					id: data[0]._id,
				};

				console.log(commentData, "commentdata");

				// const response = await fetch(
				// 	`${process.env.REACT_APP_API_URL}/describe/${id}/feature-specification`,
				// 	{
				// 		method: "PUT",
				// 		headers: {
				// 			"Content-Type": "application/json",
				// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				// 		},

				// 		body: JSON.stringify(commentData),
				// 	}
				// );

				// if (response.ok) {
				// 	console.log("Comment posted successfully.");

				// 	addComment(newCommentContent, selectedPath);
				// 	setData([commentData]);

				// 	setNewCommentContent("");
				// 	setContextMenuPosition(null);
				// 	setSelectedPath(null);
				// } else {
				// 	console.error("Failed to post comment.");
				// }
			}
		} catch (error) {
			console.error("Error posting comment:", error);
		}
	};

	// ...

	// const handleCommentButtonClick = () => {
	// 	setContentClassName(setClassNameBasedOnCommentButton());
	// 	setCommentButtonClicked(!isCommentButtonClicked);
	// 	setContextMenuPosition(null);
	// };

	console.log(
		data[0]?.aiData?.endpoints[0]?.openapi_spec?.requestBody?.content[
			"application/json"
		]?.schema,
		"data"
	);

	return (
		// <Space
		// 	direction="vertical"
		// 	style={{ width: "100%", height: "100%" }}
		// 	size={[0, 48]}
		// >
		<>
			<Layout style={{ backgroundColor: "#FAFBFF" }}>
				<Sider width={280} style={{ backgroundColor: colorPrimaryBg }}>
					<div className="MenuItem-wrap">
						<Menu
							className="leftmenu"
							mode="inline"
							defaultSelectedKeys={["0"]}
						>
							{endpointIds.map((endpoint: any, index: number) => (
								<Menu.Item
									className="leftmenu-item"
									key={index.toString()}
									onClick={() => handleMenuClick(endpoint)}
								>
									{formatString(endpoint)}
								</Menu.Item>
							))}
						</Menu>
					</div>
				</Sider>
				<Layout style={{ display: "flex", backgroundColor: "#FAFBFF" }}>
					<Content
						style={{ backgroundColor: colorPrimaryBg, textAlign: "left" }}
					>
						<div className="ApiDesign">
							<div className="ApiDesignTitle"></div>
							<div className="ApiDesignContent">
								<div
									data-testid="ApiDesignTitle"
									className="ApiDesignContentBox content-dimensions"
								>
									{selectedEndpoint &&
										selectedEndpoint?.openapi_spec &&
										Object.entries(selectedEndpoint).map(
											([pathKey, pathValue]) => (
												<div key={pathKey} className="ApiDesignContentBoxTop">
													{Object.keys(
														(pathValue as Record<string, any>) || []
													).map((key: any) => (
														<div key={key}>
															<div className="ApiDesignUrl">
																<h3>{selectedEndpoint?.url}</h3>
																<div>GET</div>
															</div>
															<div className="ApiDesignMethod">
																<Button>Request</Button>
																<Button>Response</Button>
															</div>
															<div id="ApiDesignContainer">
																{selectedEndpoint &&
																	Object.entries(selectedEndpoint).map(
																		([key]) => (
																			<pre
																				key={key}
																				onContextMenu={handleRightClick}
																				className={contentClassName}
																				data-path={`/de/`}
																			>
																				{JSON.stringify(
																					selectedEndpoint?.openapi_spec
																						?.requestBody?.content[
																						"application/json"
																					]?.schema,
																					null,
																					2
																				)}
																			</pre>
																		)
																	)}
															</div>
														</div>
													))}
												</div>
											)
										)}
								</div>
								{/* {showSwaggerUI ? (
									<SwaggerUI
										spec={selectedEndpoint?.openapi_spec}
										docExpansion="list"
									/>
								) : ( */}
								{/* <div className="ApiDesignContentBox content-dimensions">
									{selectedEndpoint &&
										selectedEndpoint.openapi_spec.paths &&
										Object.entries(selectedEndpoint.openapi_spec.paths).map(
											([pathKey, pathValue]) => (
												<div key={pathKey} className="ApiDesignContentBoxTop">
													{Object.keys(
														(pathValue as Record<string, any>) || []
													).map((key: any) => (
														<div key={key}>
															<div className="ApiDesignUrl">
																<h3>{pathKey}</h3>
																<div>{key}</div>
															</div>
															<div className="ApiDesignMethod">
																{Object.entries(
																	(pathValue as Record<string, any>)[key] || {}
																).map(([nestedKey, nestedValue]) => (
																	<div key={nestedKey}>
																		<Button
																			key={nestedKey}
																			onClick={() =>
																				handleButtonClick(
																					nestedKey,
																					nestedValue,
																					key
																				)
																			}
																		>
																			{nestedKey}
																		</Button>
																	</div>
																))}
															</div>
															<div id="ApiDesignContainer">
																{selectedPropertyKey && Keys === key && (
																	<pre
																		onContextMenu={handleRightClick}
																		className={contentClassName}
																		data-path={`/de/`}
																	>
																		{JSON.stringify(
																			{
																				[selectedPropertyKey]:
																					selectedPropertyvalue,
																			},
																			null,
																			2
																		)}
																	</pre>
																)}
															</div>
														</div>
													))}
												</div>
											)
										)}
								</div> */}
								{/* )} */}
								<div data-testid="ToggleButton" className="ToggleButton">
									{/* <Switch
										style={{ marginLeft: "10px" }}
										checked={showSwaggerUI}
										onChange={handleSwaggerToggle}
									/>
									<span style={{ marginLeft: "10px" }}>
										{showSwaggerUI ? "Swagger UI" : "Custom Content"}
									</span> */}
								</div>
							</div>
						</div>
					</Content>
				</Layout>
			</Layout>
			{contextMenuPosition && (
				<Popover
					title={
						<Flex justify="space-between">
							<Typography.Title level={5}> Add a Comment </Typography.Title>
							<Button
								icon={<CloseOutlined />}
								size="small"
								shape="circle"
								onClick={() => setContextMenuPosition(null)}
							></Button>
						</Flex>
					}
					content={
						<div>
							<Input.TextArea
								style={{ height: "15vh", marginBottom: "1vh" }}
								placeholder="Type your comment here..."
								onChange={(e) => setNewCommentContent(e.target.value)}
								value={newCommentContent}
							/>
							<Button type="primary" onClick={postComment}>
								Post Comment
							</Button>
						</div>
					}
					trigger="click"
					//open={openCommentBox}
					open={contextMenuPosition !== null}
					onOpenChange={(visible) => visible || setContextMenuPosition(null)}
				>
					<div
						className="NewCommentSectio"
						style={{
							position: "fixed",
							top: contextMenuPosition.y + "px",
							left: contextMenuPosition.x + "px",
						}}
					>
						{/* Placeholder for comment button */}
						<Button type="link"></Button>
					</div>
				</Popover>
			)}
		</>
		// </Space>
	);
};

export default ApiDesign;
