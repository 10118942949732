import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Form, Input, Menu, Modal } from "antd";
import {
	EllipsisOutlined,
	EditOutlined,
	DeleteOutlined,
	AppstoreAddOutlined,
} from "@ant-design/icons"; // Import necessary icons
import { register } from "@antv/xflow";
import { BASICFLOWNODE } from "./BASICFLOWNODE";
import type { Node } from "@antv/xflow";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";

const CUSTOMSECONDRYNODE = "custom-secondary-node";

const CustomeSecondryNode = ({ node }: { node: Node }) => {
	const [form] = Form.useForm();
	const data = node.getData();
	const [carddata, setCardData] = useState(data);
	const [modalVisible, setModalVisible] = useState(false);
	const [visible, setVisible] = useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { InformationArchitecture, setInformationArchitecture } =
		useWorkspaceStore((state: any) => ({
			InformationArchitecture: state.InformationArchitecture,
			setInformationArchitecture: state.setInformationArchitecture,
		}));

	useEffect(() => {
		setCardData(data);
		form.setFieldsValue({ data: carddata.page });
	}, [data]);

	const handleEditClick = () => {
		setModalVisible(true);
	};
	const handleModalCancel = () => {
		setModalVisible(false);
	};

	const onFinish = () => {
		const formData = form.getFieldsValue();

		const abc = {
			...carddata.pageData,
			user_story_ids: [
				...carddata.pageData.user_story_ids,
				formData.userProfileId,
			],
		};
		// console.log("Form submitted with edited data:", carddata.index);
		const sub = {
			...carddata.data,
			data: {
				...carddata.data.data,
				sitemap: {
					...carddata.data.data.sitemap,
					pages: carddata.data.data.sitemap.pages.map(
						(page: any, index: any) => {
							if (index === carddata.index) {
								return abc;
							}
							return page;
						}
					),
				},
			},
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/project-artifact/${carddata.data._id}`,
			{
				method: "PUT",

				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(sub),
			}
		)
			.then((response) => {
				if (response.ok) {
					console.log("Update successful", response);
				} else {
					console.error("Update failed", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
		setInformationArchitecture(!InformationArchitecture);
		setVisible(false);
	};

	const onCancel = () => {
		setVisible(false);
	};

	const handelAdd = () => {
		setVisible(true);
	};

	const handleDeleteClick = () => {
		const sub = {
			...carddata.data,
			data: {
				...carddata.data.data,
				sitemap: {
					...carddata.data.data.sitemap,
					pages: carddata.data.data.sitemap.pages.filter(
						(page: any, index: any) => index !== carddata.index
					),
				},
			},
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/project-artifact/${carddata.data._id}`,
			{
				method: "PUT",

				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(sub),
			}
		)
			.then((response) => {
				if (response.ok) {
					console.log("Update successful", response);
				} else {
					console.error("Update failed", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
		setInformationArchitecture(!InformationArchitecture);
		console.log("Delete clicked");
	};

	const menu = (
		<Menu>
			<Menu.Item key="edit " onClick={handleEditClick}>
				<EditOutlined /> {"   "}
				Edit
			</Menu.Item>
			<Menu.Item key="delete " onClick={handleDeleteClick}>
				<DeleteOutlined /> {"   "}
				Delete
			</Menu.Item>
			<Menu.Item key={"add "} onClick={handelAdd}>
				<AppstoreAddOutlined /> {"   "}Add{" "}
			</Menu.Item>
		</Menu>
	);

	const handleFormSubmit = () => {
		const formData = form.getFieldsValue();
		const sub = {
			...carddata.data,
			data: {
				...carddata.data.data,
				sitemap: {
					...carddata.data.data.sitemap,
					pages: carddata.data.data.sitemap.pages.map(
						(page: any, index: any) =>
							index === carddata.index
								? { ...page, ...carddata.page, id: formData.data }
								: page
					),
				},
			},
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/project-artifact/${carddata.data._id}`,
			{
				method: "PUT",

				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(sub),
			}
		)
			.then((response) => {
				if (response.ok) {
					console.log("Update successful", response);
				} else {
					console.error("Update failed", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});

		const abc = {
			...carddata,
			page: formData.data,
		};
		setCardData(abc);

		setInformationArchitecture(!InformationArchitecture);
		setModalVisible(false);
	};

	return (
		<>
			<Card
				style={{
					width: 190,
					height: 100,
					borderRadius: 0,
					borderWidth: 2,
					borderColor: "rgb(74, 123, 203)",
					backgroundColor: "rgb(0, 123, 203)",
					color: "#fff",
					fontWeight: "bold",
					wordBreak: "break-all",
					position: "relative",
				}}
			>
				<Dropdown overlay={menu} trigger={["click"]}>
					<EllipsisOutlined
						style={{
							position: "absolute",
							top: 5,
							right: 5,
							fontSize: 16,
						}}
					/>
				</Dropdown>
				{carddata.page}
			</Card>
			<Modal
				title="Edit Node"
				open={modalVisible}
				onCancel={handleModalCancel}
				footer={null}
			>
				<Form
					form={form}
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					onFinish={handleFormSubmit}
				>
					<Form.Item label="Node Data" name="data">
						<Input />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Modal>

			<Modal title="Add Node" open={visible} onCancel={onCancel} footer={null}>
				<Form form={form} layout="vertical" onFinish={onFinish}>
					<Form.Item
						name="userProfileId"
						label="Add Data"
						rules={[{ required: true, message: "Please enter node data" }]}
					>
						<Input />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Add
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

register({
	shape: CUSTOMSECONDRYNODE,
	component: CustomeSecondryNode,
	inherit: BASICFLOWNODE,
	effect: ["label"],
});

export { CUSTOMSECONDRYNODE };
