// // import { Button, Form, Input, Modal, Select, Table, message } from "antd";
// // import React, { useEffect, useState } from "react";

// // import userProfileStore, {
// // 	UserProfileStoreType,
// // } from "../../../stores/UserProfileStore";
// // import { getRitualSession, updateRitualSession } from "../../../api/RitualApi";
// // import { useRequest } from "ahooks";

// // function RitualLink({
// // 	itemId,
// // 	openModal,
// // 	setopenModal,
// // }: {
// // 	itemId: string;
// // 	openModal: boolean;
// // 	setopenModal: React.Dispatch<React.SetStateAction<boolean>>;
// // }): any {
// // 	const [linkName, setLinkName] = useState("");
// // 	const [linkType, setLinkType] = useState("");
// // 	const [linkDescription, setLinkDescription] = useState("");
// // 	const [linkURL, setLinkURL] = useState("");
// // 	const [linkData, setLinkData] = useState<any>([]);
// // 	const [ritualData, setRitualData] = useState<any>(null);

// // 	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

// // 	const fetchLinkDetails = () => {
// // 		const {
// // 			run: fetchLinkDetailsRun,
// // 			data: ritualData,
// // 			error: ritualError,
// // 			loading: ritualLoading,
// // 		} = useRequest(
// // 			() => getRitualSession(itemId, userProfileStoreInstance.idToken ?? ""),
// // 			{
// // 				manual: true,
// // 				onSuccess: (result) => {
// // 					setLinkData(result?.links || []);
// // 					console.log(ritualData);
// // 					setRitualData(ritualData);
// // 				},
// // 				onError: (error) => {
// // 					message.error("Error fetching ritual session: " + error.message);
// // 				},
// // 			}
// // 		);

// // 		useEffect(() => {
// // 			fetchLinkDetailsRun();
// // 		}, []);

// // 		if (ritualLoading) {
// // 			return <div>Loading...</div>;
// // 		}

// // 		if (ritualError) {
// // 			return <div>Error: {ritualError.message}</div>;
// // 		}

// // 		return <div>Data loaded successfully!</div>;
// // 	};

// // 	const handleAddLink = () => {
// // 		const newLinkData = {
// // 			name: linkName,
// // 			type: linkType,
// // 			description: linkDescription,
// // 			url: linkURL,
// // 		};

// // 		const updatedLinkData = [...linkData, newLinkData];
// // 		console.log(updatedLinkData, "linkData");

// // 		const updatedRitualData = {
// // 			...ritualData,
// // 			links: updatedLinkData,
// // 		};

// // 		console.log(updatedRitualData, "ritualData");

// // 		runUpdateRitualSession();
// // 	};

// // 	const {
// // 		data: updatedRitualData,
// // 		error: updateError,
// // 		loading: updateLoading,
// // 		run: runUpdateRitualSession,
// // 	} = useRequest(
// // 		() =>
// // 			updateRitualSession(
// // 				itemId,
// // 				{
// // 					...ritualData,
// // 					links: [
// // 						...linkData,
// // 						{
// // 							name: linkName,
// // 							type: linkType,
// // 							description: linkDescription,
// // 							url: linkURL,
// // 						},
// // 					],
// // 				},
// // 				userProfileStoreInstance.idToken ?? ""
// // 			),
// // 		{
// // 			manual: true,
// // 			onSuccess: (result) => {
// // 				console.log("Success:", result);
// // 				setopenModal(false);
// // 				fetchLinkDetails();
// // 				console.log(updatedRitualData);
// // 			},
// // 			onError: (err) => {
// // 				message.error("Error fetching item: " + err.message);
// // 			},
// // 		}
// // 	);

// // 	useEffect(() => {
// // 		fetchLinkDetails();
// // 	}, []);

// // 	return (
// // 		<>
// // 			<div
// // 				style={{
// // 					display: "flex",
// // 					justifyContent: "flex-end",
// // 				}}
// // 			>
// // 				<Button onClick={handleAddLink} disabled={updateLoading}>
// // 					{updateLoading ? "Updating..." : "Add Link"}
// // 				</Button>
// // 				{updateError && (
// // 					<p>Error updating ritual session: {updateError.message}</p>
// // 				)}
// // 			</div>
// // 			<Modal
// // 				title="Add Link"
// // 				open={openModal}
// // 				onCancel={() => setopenModal(false)}
// // 				onOk={handleAddLink}
// // 			>
// // 				<Form layout="horizontal">
// // 					<Form.Item
// // 						label="Name"
// // 						required
// // 						rules={[{ required: true, message: "input the name!" }]}
// // 					>
// // 						<Input
// // 							value={linkName}
// // 							onChange={(e) => setLinkName(e.target.value)}
// // 						/>
// // 					</Form.Item>
// // 					<Form.Item
// // 						label="Type"
// // 						required
// // 						rules={[{ required: true, message: "select the type!" }]}
// // 					>
// // 						<Select value={linkType} onChange={(value) => setLinkType(value)}>
// // 							<Select.Option value="Figma">Figma</Select.Option>
// // 							<Select.Option value="Antd">Antd</Select.Option>
// // 							<Select.Option value="Xflow">Xflow</Select.Option>
// // 						</Select>
// // 					</Form.Item>
// // 					<Form.Item
// // 						label="Description"
// // 						required
// // 						rules={[{ required: true, message: "input the Description!" }]}
// // 					>
// // 						<Input
// // 							value={linkDescription}
// // 							onChange={(e) => setLinkDescription(e.target.value)}
// // 						/>
// // 					</Form.Item>
// // 					<Form.Item
// // 						label="URL"
// // 						required
// // 						rules={[{ required: true, message: "input the URL!" }]}
// // 					>
// // 						<Input
// // 							value={linkURL}
// // 							onChange={(e) => setLinkURL(e.target.value)}
// // 						/>
// // 					</Form.Item>
// // 				</Form>
// // 			</Modal>
// // 			<div
// // 				style={{
// // 					display: "flex",
// // 					justifyContent: "center",
// // 				}}
// // 			>
// // 				<Table
// // 					dataSource={linkData}
// // 					columns={[
// // 						{
// // 							title: "Name",
// // 							dataIndex: "name",
// // 							key: "name",
// // 						},
// // 						{
// // 							title: "Type",
// // 							dataIndex: "type",
// // 							key: "type",
// // 						},
// // 						{
// // 							title: "Description",
// // 							dataIndex: "description",
// // 							key: "description",
// // 						},
// // 						{
// // 							title: "URL",
// // 							dataIndex: "url",
// // 							key: "url",
// // 						},
// // 					]}
// // 				/>
// // 			</div>
// // 		</>
// // 	);
// // }

// // export default RitualLink;

// import { Button, Form, Input, Modal, Select, Table, message } from "antd";
// import React, { useEffect, useState } from "react";
// import userProfileStore, { UserProfileStoreType } from "../../../stores/UserProfileStore";
// import { getRitualSession, updateRitualSession } from "../../../api/RitualApi";
// import { useRequest } from "ahooks";

// function RitualLink({
// 	itemId,
// 	openModal,
// 	setopenModal,
// }: {
// 	itemId: string;
// 	openModal: boolean;
// 	setopenModal: React.Dispatch<React.SetStateAction<boolean>>;
// }): any {
// 	const [linkName, setLinkName] = useState("");
// 	const [linkType, setLinkType] = useState("");
// 	const [linkDescription, setLinkDescription] = useState("");
// 	const [linkURL, setLinkURL] = useState("");
// 	const [linkData, setLinkData] = useState<any>([]);
// 	const [ritualData, setRitualData] = useState<any>(null);

// 	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

// 	const {
// 		run: fetchLinkDetailsRun,
// 		data:Ritualdata,
// 		error: ritualError,
// 		loading: ritualLoading,
// 	} = useRequest(
// 		() => getRitualSession(itemId, userProfileStoreInstance.idToken ?? ""),
// 		{
// 			manual: true,
// 			onSuccess: (result) => {
// 				setLinkData(result?.links || []);
// 				setRitualData(Ritualdata);
// 			},
// 			onError: (error) => {
// 				message.error("Error fetching ritual session: " + error.message);
// 			},
// 		}
// 	);

// 	useEffect(() => {
// 		fetchLinkDetailsRun();
// 	}, [fetchLinkDetailsRun]);

// 	const {
// 		run: runUpdateRitualSession,
// 		loading: updateLoading,
// 		error: updateError,
// 	} = useRequest(
// 		() =>
// 			updateRitualSession(
// 				itemId,
// 				{
// 					...ritualData,
// 					links: [
// 						...linkData,
// 						{
// 							name: linkName,
// 							type: linkType,
// 							description: linkDescription,
// 							url: linkURL,
// 						},
// 					],
// 				},
// 				userProfileStoreInstance.idToken ?? ""
// 			),
// 		{
// 			manual: true,
// 			onSuccess: (result) => {
// 				message.success("Ritual session updated successfully!");
// 				setRitualData(result);
// 				setopenModal(false);
// 				fetchLinkDetailsRun();
// 				setLinkName("");
// 				setLinkType("");
// 				setLinkDescription("");
// 				setLinkURL("");
// 			},
// 			onError: (err) => {
// 				message.error("Error updating ritual session: " + err.message);
// 			},
// 		}
// 	);

// 	const handleAddLink = () => {
// 		runUpdateRitualSession();
// 	};

// 	return (
// 		<>
// 			<div style={{ display: "flex", justifyContent: "flex-end" }}>
// 				<Button onClick={handleAddLink} disabled={updateLoading}>
// 					{updateLoading ? "Updating..." : "Add Link"}
// 				</Button>
// 				{updateError && <p>Error updating ritual session: {updateError.message}</p>}
// 			</div>
// 			<Modal
// 				title="Add Link"
// 				open={openModal}
// 				onCancel={() => setopenModal(false)}
// 				onOk={handleAddLink}
// 			>
// 				<Form layout="horizontal">
// 					<Form.Item label="Name" required rules={[{ required: true, message: "Input the name!" }]}>
// 						<Input value={linkName} onChange={(e) => setLinkName(e.target.value)} />
// 					</Form.Item>
// 					<Form.Item label="Type" required rules={[{ required: true, message: "Select the type!" }]}>
// 						<Select value={linkType} onChange={(value) => setLinkType(value)}>
// 							<Select.Option value="Figma">Figma</Select.Option>
// 							<Select.Option value="Antd">Antd</Select.Option>
// 							<Select.Option value="Xflow">Xflow</Select.Option>
// 						</Select>
// 					</Form.Item>
// 					<Form.Item
// 						label="Description"
// 						required
// 						rules={[{ required: true, message: "Input the description!" }]}
// 					>
// 						<Input value={linkDescription} onChange={(e) => setLinkDescription(e.target.value)} />
// 					</Form.Item>
// 					<Form.Item label="URL" required rules={[{ required: true, message: "Input the URL!" }]}>
// 						<Input value={linkURL} onChange={(e) => setLinkURL(e.target.value)} />
// 					</Form.Item>
// 				</Form>
// 			</Modal>
// 			<div style={{ display: "flex", justifyContent: "center" }}>
// 				{ritualLoading ? (
// 					<p>Loading...</p>
// 				) : ritualError ? (
// 					<p>Error loading ritual data: {ritualError.message}</p>
// 				) : (
// 					<Table
// 						dataSource={linkData}
// 						columns={[
// 							{
// 								title: "Name",
// 								dataIndex: "name",
// 								key: "name",
// 							},
// 							{
// 								title: "Type",
// 								dataIndex: "type",
// 								key: "type",
// 							},
// 							{
// 								title: "Description",
// 								dataIndex: "description",
// 								key: "description",
// 							},
// 							{
// 								title: "URL",
// 								dataIndex: "url",
// 								key: "url",
// 							},
// 						]}
// 					/>
// 				)}
// 			</div>
// 		</>
// 	);
// }

// export default RitualLink;

import { Button, Form, Input, Modal, Select, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { getRitualSession, updateRitualSession } from "../../../api/RitualApi";
import { useRequest } from "ahooks";

function RitualLink({
	itemId,
	openModal,
	setopenModal,
}: {
	itemId: string;
	openModal: boolean;
	setopenModal: React.Dispatch<React.SetStateAction<boolean>>;
}): any {
	const [form] = Form.useForm();
	const [linkData, setLinkData] = useState<any[]>([]);
	const [ritualData, setRitualData] = useState<any>(null);

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const {
		run: fetchLinkDetailsRun,
		data: Ritualdata,
		error: ritualError,
		loading: ritualLoading,
	} = useRequest(
		() => getRitualSession(itemId, userProfileStoreInstance.idToken ?? ""),
		{
			manual: true,
			onSuccess: (result) => {
				setLinkData(result?.links || []);
				setRitualData(Ritualdata);
			},
			onError: (error) => {
				message.error("Error fetching ritual session: " + error.message);
			},
		}
	);

	useEffect(() => {
		fetchLinkDetailsRun();
	}, [fetchLinkDetailsRun]);

	const {
		run: runUpdateRitualSession,
		loading: updateLoading,
		error: updateError,
	} = useRequest(
		(newLinkData) =>
			updateRitualSession(
				itemId,
				{
					...ritualData,
					links: [...linkData, newLinkData],
				},
				userProfileStoreInstance.idToken ?? ""
			),
		{
			manual: true,
			onSuccess: (result) => {
				message.success("Ritual session updated successfully!");
				setRitualData(result);
				setopenModal(false);
				fetchLinkDetailsRun();
				form.resetFields();
			},
			onError: (err) => {
				message.error("Error updating ritual session: " + err.message);
			},
		}
	);

	const handleAddLink = () => {
		form
			.validateFields()
			.then((values) => {
				const newLinkData = {
					name: values.name,
					type: values.type,
					description: values.description,
					url: values.url,
				};
				runUpdateRitualSession(newLinkData);
			})
			.catch((errorInfo) => {
				message.error(
					"Please fill out all required fields correctly.",
					errorInfo
				);
			});
	};

	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button onClick={handleAddLink} disabled={updateLoading}>
					{updateLoading ? "Updating..." : "Add Link"}
				</Button>
				{updateError && (
					<p>Error updating ritual session: {updateError.message}</p>
				)}
			</div>
			<Modal
				title="Add Link"
				open={openModal}
				onCancel={() => setopenModal(false)}
				onOk={handleAddLink}
			>
				<Form form={form} layout="horizontal">
					<Form.Item
						label="Name"
						name="name"
						rules={[{ required: true, message: "Input the name!" }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Type"
						name="type"
						rules={[{ required: true, message: "Select the type!" }]}
					>
						<Select>
							<Select.Option value="Figma">Figma</Select.Option>
							<Select.Option value="Antd">Antd</Select.Option>
							<Select.Option value="Xflow">Xflow</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Description"
						name="description"
						rules={[{ required: true, message: "Input the description!" }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="URL"
						name="url"
						rules={[{ required: true, message: "Input the URL!" }]}
					>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
			<div style={{ display: "flex", justifyContent: "center" }}>
				{ritualLoading ? (
					<p>Loading...</p>
				) : ritualError ? (
					<p>Error loading ritual data: {ritualError.message}</p>
				) : (
					<Table
						dataSource={linkData}
						columns={[
							{
								title: "Name",
								dataIndex: "name",
								key: "name",
							},
							{
								title: "Type",
								dataIndex: "type",
								key: "type",
							},
							{
								title: "Description",
								dataIndex: "description",
								key: "description",
							},
							{
								title: "URL",
								dataIndex: "url",
								key: "url",
							},
						]}
					/>
				)}
			</div>
		</>
	);
}

export default RitualLink;
