import React, { useEffect, useState } from "react";
import {
	Flex,
	Layout,
	Typography,
	// Image,
	Card,
	Button,
	Tag,
	// Menu,
	// Popover,
	// Button,
	// Input,
} from "antd";
import { useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import usePlatformStore from "../stores/platformStore";
import { getNavigateUrlForWorkspaces } from "../util/NavigateAssistUtil";
import Constants from "../util/ConstantsUtil";
import "./FeatureRoles.css";
import { UserOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

function formatString(inputString: any) {
	const stringWithoutUnderscores = inputString.replace(/_/g, " ");
	return (
		stringWithoutUnderscores.charAt(0).toUpperCase() +
		stringWithoutUnderscores.slice(1)
	);
}

const FeatureRoles: React.FC = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [data, setData] = useState<any>([]);
	const { id } = useParams<{ id: string }>();
	// const { id: routeId } = useParams<{ id: string }>();
	// const [comments, setComments] = useState<any[]>([]);
	// const [newCommentContent, setNewCommentContent] = useState<string>("");
	//const [openCommentBox, setOpenCommentBox] = useState(false);
	// const [contextMenuPosition, setContextMenuPosition] = useState<{
	// 	x: number;
	// 	y: number;
	// } | null>(null);
	// const [selectedPath, setSelectedPath] = useState<string | null>(null);
	//const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);

	// const { isReviewEnabled } = useWorkspaceStore((state: any) => ({
	// 	currentWorkspace: state.currentWorkspace,
	// 	isReviewEnabled: state.isReviewEnabled,
	// }));
	// const [contentClassName, setContentClassName] = useState(
	// "UserPersonaLayoutContent"
	// );

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/describe/${id}/feature-list`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const result = await response.json();
			if (response.status === 401) {
				setUnauthorizedModel(true);
			}
			setData(result);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	console.log(data);

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
		setUnauthorizedModel,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));

	useEffect(() => {
		const navigationUrls = getNavigateUrlForWorkspaces(
			`${id}`,
			currentWorkspace.modules,
			Constants.FEATURE_ENGINEERING,
			Constants.FE_FEATURE_ROLE
		);
		setNextNavigateUrl(navigationUrls.nextUrl);
		setBackNavigateUrl(navigationUrls.prevUrl);
		setAppBarTitle(Constants.FE_FEATURE_ROLE);
		setCurrentModule(Constants.FEATURE_ENGINEERING);
		setCurrentPage(Constants.FE_FEATURE_ROLE);
	}, [currentWorkspace]);

	useEffect(() => {
		fetchData();
	}, []);

	const adminRoles = data[0]?.aiData.admin_roles || [];
	const customerRoles = data[0]?.aiData.customer_roles || [];
	const allRoles: any[] = [...adminRoles, ...customerRoles];

	console.log(allRoles);

	// console.log("All Roles:", allRoles);

	// useEffect(() => {
	// 	setContentClassName(
	// 		isReviewEnabled
	// 			? "UserPersonaLayoutContentHover"
	// 			: "UserPersonaLayoutContent"
	// 	);
	// }, [isReviewEnabled]);

	// const handleRightClick = (e: React.MouseEvent) => {
	// 	e.preventDefault();

	// 	if (isReviewEnabled) {
	// 		// setContentClassName(isCommentButtonClicked ? "FeatureSpecificationLayoutContentHover" : "FeatureSpecificationLayoutContent");
	// 		const clickedElement = e.target as HTMLElement;
	// 		const clickedPath = clickedElement.dataset.path || "";
	// 		console.log(clickedPath); // Use an empty string as a fallback
	// 		setContextMenuPosition({ x: e.clientX, y: e.clientY });
	// 		console.log(clickedPath, "clickedpath");
	// 		setSelectedPath(clickedPath);
	// 	} else {
	// 		// setContentClassName("FeatureSpecificationLayoutContent");
	// 	}
	// };

	// const addComment = (content: string, path: any) => {
	// 	const newComment: any = {
	// 		content,
	// 		path,
	// 	};
	// 	setComments([...comments, newComment]);
	// };

	// const postComment = async () => {
	// 	try {
	// 		if (newCommentContent.trim() !== "" && selectedPath) {
	// 			const updatedComment = {
	// 				comment: newCommentContent,
	// 				path: selectedPath,
	// 			};

	// 			const commentData = {
	// 				workspace: data[0].workspace,
	// 				module: data[0].module,
	// 				section: data[0].section,
	// 				context: data[0].context,
	// 				input: data[0].input,
	// 				aiData: data[0].aiData,
	// 				userReview: [...data[0].userReview, updatedComment],
	// 				createdBy: data[0].createdBy,
	// 				updatedBy: data[0].updatedBy,
	// 				id: data[0]._id,
	// 			};

	// 			console.log(commentData, "commentdata");

	// 			// const response = await fetch(
	// 			// 	`${process.env.REACT_APP_API_URL}/describe/${id}/feature-specification`,
	// 			// 	{
	// 			// 		method: "PUT",
	// 			// 		headers: {
	// 			// 			"Content-Type": "application/json",
	// 			// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 			// 		},

	// 			// 		body: JSON.stringify(commentData),
	// 			// 	}
	// 			// );

	// 			// if (response.ok) {
	// 			// 	console.log("Comment posted successfully.");

	// 			// 	addComment(newCommentContent, selectedPath);
	// 			// 	setData([commentData]);

	// 			// 	setNewCommentContent("");
	// 			// 	setContextMenuPosition(null);
	// 			// 	setSelectedPath(null);
	// 			// } else {
	// 			// 	console.error("Failed to post comment.");
	// 			// }
	// 		}
	// 	} catch (error) {
	// 		console.error("Error posting comment:", error);
	// 	}
	// };

	return (
		<Content>
			<Flex justify="center" align="center" gap="large">
				{" "}
				{allRoles.map((role) => (
					<Card className="role-card" key={role.id}>
						<Flex gap={"small"}>
							{" "}
							<Flex>
								{" "}
								<Button
									style={{
										width: "5rem",
										height: "5rem",
										backgroundColor: "#D9D9D9",
									}}
									className="role-icon"
									shape="circle"
									type="text"
									icon={
										<UserOutlined
											style={{
												fontSize: "3rem",
												color: role.id.startsWith("admin")
													? "#52C41A"
													: "defult",
											}}
										/>
									}
								/>
							</Flex>
							<Flex vertical justify="center" gap={"small"}>
								<Flex>{formatString(role.id)}</Flex>
								<Flex>
									<Tag
										style={{ borderRadius: "2rem" }}
										color={role.id.startsWith("admin") ? "green" : "blue"}
									>
										{role.id.startsWith("admin") ? "Admin" : "Customer"}
									</Tag>
								</Flex>
							</Flex>
						</Flex>

						<Title level={5}>{`"${role.description}"`}</Title>
						<Flex
							vertical
							align="flex-start"
							justify="flex-start"
							className="Responsibility"
						>
							Responsibility
							<ul>
								{role.responsibilities.map(
									(responsibility: string, index: number) => (
										<li key={index}>{responsibility}</li>
									)
								)}
							</ul>
						</Flex>
					</Card>
				))}
			</Flex>
		</Content>
		// </Layout>
	);
};

export default FeatureRoles;
