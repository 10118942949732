import React, { useState, useEffect } from "react";
import {
	Button,
	Card,
	Flex,
	Form,
	Image,
	Input,
	Layout,
	Menu,
	Progress,
	Typography,
} from "antd";
import "./ProductInfo.css";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
// import useDataStore, { StoreState } from "../stores/productIndoStore";
import useWorkspaceStore, {
	WorkspaceStoreState,
} from "../stores/WorkspaceState";
//import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const { TextArea } = Input;

interface Question {
	element: string;
	question: string;
	image?: string;
}

const productInfo: Question[] = [
	{
		question: "What is the Problem Statement?",
		element: "Problem Statement",
		image:
			"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_problemsolving.svg",
	},
	{
		question: "How do current users solve the problem?",
		element: "Existing Current Solution",
		image:
			"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_existingsol.svg",
	},
	{
		question: "Can you describe the product's idea?",
		element: "Product Idea",
		image:
			"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_productidea.svg",
	},
	{
		question: "What makes this product special?",
		element: "Unique Selling Point",
		image:
			"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_unique.svg",
	},
];

function ProductInfoV2({ next }: { next: () => void }) {
	const setData = useWorkspaceStore() as WorkspaceStoreState;

	//const navigate = useNavigate();

	const { Sider } = Layout;

	const [selectedMenuItem, setSelectedMenuItem] = useState("tmp_key-0");
	const [currentStep, setCurrentStep] = useState(0);
	const [form] = Form.useForm();

	const [elementInputs, setElementInputs] = useState<{ [key: string]: string }>(
		{}
	);
	const [selectedElement, setSelectedElement] = useState<string>(
		productInfo[0].element
	);
	const [inputValue, setInputValue] = useState<string>("test");
	const [maxProgress, setMaxProgress] = useState(25);
	const { setAddWorkspaceDetails, addWorkspaceDetails } = useWorkspaceStore(
		(state: any) => ({
			addWorkspaceDetails: state.addWorkspaceDetails,
			setAddWorkspaceDetails: state.setAddWorkspaceDetails,
		})
	);

	if (next) {
		console.log(next);
	}

	// useEffect(() => {
	// 	console.log(addWorkspaceDetails);
	// 	console.log(elementInputs);
	// 	if(elementInputs && elementInputs[selectedElement])
	// 		setInputValue(elementInputs[selectedElement]);
	// 	else
	// 		setInputValue("");
	// 	if(addWorkspaceDetails && addWorkspaceDetails[productInfo[0].element])
	// 		setInputValue(addWorkspaceDetails[productInfo[0].element]);
	// 	else
	// 		setInputValue("");

	// }, [inputValue]);

	useEffect(() => {
		if (addWorkspaceDetails && addWorkspaceDetails[productInfo[0].element]) {
			setInputValue(addWorkspaceDetails[productInfo[0].element]);
		} else {
			setInputValue("");
			console.log("emput");
		}
	}, []);

	useEffect(() => {
		console.log(addWorkspaceDetails);
		console.log(elementInputs);
		//setInputValue();
		if (!selectedElement) setSelectedElement(productInfo[0].element);
		console.log(selectedElement);

		for (let i = 0; i < productInfo.length; i++) {
			console.log(productInfo[i].element);
			if (
				productInfo[i].element == selectedElement &&
				addWorkspaceDetails &&
				addWorkspaceDetails[productInfo[i].element]
			) {
				form.setFieldsValue({
					description: addWorkspaceDetails[productInfo[i].element],
				});
				break;
			}
		}
	}, [elementInputs, selectedElement]);

	const handleMenuClick = async (e: any) => {
		console.log("handleMenuClick");
		try {
			await form.validateFields();
		} catch (error) {
			return;
		}

		setSelectedMenuItem(e.key);
		setCurrentStep(Number(e.key.split("-")[1]));

		const nextIndex = Number(e.key.split("-")[1]);
		if (nextIndex < productInfo.length) {
			const nextElement = productInfo[nextIndex].element;
			setSelectedElement(nextElement);
			setInputValue(elementInputs[nextElement]);
			if (addWorkspaceDetails && addWorkspaceDetails[nextElement]) {
				form.setFieldsValue({
					description: addWorkspaceDetails[nextElement],
				});
			} else {
				form.setFieldsValue({
					description: elementInputs[nextElement],
				});
			}

			const newMaxProgress = (nextIndex / (productInfo.length - 1)) * 100;
			setMaxProgress(newMaxProgress);
		}
	};

	const handleNext = async () => {
		console.log("handleNext");
		try {
			await form.validateFields();
		} catch (error) {
			return;
		}

		//set the current value
		const currentElement = productInfo[currentStep].element;
		if (addWorkspaceDetails && addWorkspaceDetails[currentElement]) {
			setAddWorkspaceDetails({
				...addWorkspaceDetails,
				[currentElement]: addWorkspaceDetails[currentElement],
			});
		} else {
			setElementInputs((prevInputs) => ({
				...prevInputs,
				[currentElement]: addWorkspaceDetails[currentElement],
			}));
		}

		const nextStep = currentStep + 1;
		if (nextStep < productInfo.length) {
			const nextElement = productInfo[nextStep].element;
			setSelectedElement(nextElement);
			setSelectedMenuItem(`tmp_key-${nextStep}`);
			setCurrentStep(nextStep);

			if (addWorkspaceDetails && addWorkspaceDetails[nextElement]) {
				form.setFieldsValue({
					description: addWorkspaceDetails[nextElement],
				});
			} else {
				form.setFieldsValue({
					description: elementInputs[nextElement],
				});
			}
			const newMaxProgress = (nextStep / (productInfo.length - 1)) * 100;
			setMaxProgress(newMaxProgress);
		} else {
			const dataToLog = productInfo.map(({ element, question }) => ({
				element,
				question,
				answer: addWorkspaceDetails[element],
			}));
			setData.setData(dataToLog);
			setAddWorkspaceDetails({
				...addWorkspaceDetails,
				productInfo: dataToLog,
			});

			next();

			console.log(dataToLog);
		}
	};

	const handlePrevious = () => {
		console.log("handlePrevious");
		//set the current value
		const currentElement = productInfo[currentStep].element;
		if (addWorkspaceDetails && addWorkspaceDetails[currentElement]) {
			setAddWorkspaceDetails({
				...addWorkspaceDetails,
				[currentElement]: addWorkspaceDetails[currentElement],
			});
		} else {
			setElementInputs((prevInputs) => ({
				...prevInputs,
				[currentElement]: addWorkspaceDetails[currentElement],
			}));
		}
		const previousStep = currentStep - 1;
		if (previousStep >= 0) {
			const nextElement = productInfo[previousStep].element;
			setSelectedElement(nextElement);
			setSelectedMenuItem(`tmp_key-${previousStep}`);
			setCurrentStep(previousStep);
			if (addWorkspaceDetails && addWorkspaceDetails[nextElement]) {
				form.setFieldsValue({
					description: addWorkspaceDetails[nextElement],
				});
			} else {
				form.setFieldsValue({
					description: elementInputs[nextElement],
				});
			}
		}
	};

	const formatElementText = (element: string) => {
		const withoutDescribe = element.replace("describe-", "");
		const words = withoutDescribe.split("-");
		const formattedWords = words.map((word) => {
			return word.charAt(0).toUpperCase() + word.slice(1);
		});
		return formattedWords.join(" ");
	};

	const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (!selectedElement) setSelectedElement(productInfo[0].element);
		const currentElement: any = selectedElement;
		const newValue = e.target.value;

		setAddWorkspaceDetails({
			...addWorkspaceDetails,
			[currentElement]: newValue,
		});

		setElementInputs((prevInputs) => ({
			...prevInputs,
			[currentElement]: newValue,
		}));
	};

	return (
		<Layout className="ProductInfo" style={{ backgroundColor: "#f5f8fe" }}>
			{/* <Layout className="ParentLayout"> */}
			<Form form={form}>
				<Layout style={{ backgroundColor: "#f5f8fe" }}>
					<Sider style={{ backgroundColor: "#f5f8fe" }} width={"15vw"}>
						<div className="ProgressBarDiv">
							<Progress
								data-testid="ProgressPercentage"
								strokeColor={"#0d8be9"}
								percent={maxProgress}
								showInfo={false}
								className="ProgressBar"
							/>
						</div>
						<div data-testid="progressBar" className="ProgressDivision">
							<div>{currentStep + 1}&nbsp;</div>
							<div className="ProgressDivsionByNumber"> / 4</div>
						</div>
						<Menu
							selectedKeys={[selectedMenuItem]}
							mode="inline"
							className="leftmenu"
							onClick={handleMenuClick}
							data-testid="sider"
						>
							{productInfo.map((item, index) => (
								<Menu.Item key={`tmp_key-${index}`} className="leftmenu-item">
									{formatElementText(item.element)}
								</Menu.Item>
							))}
						</Menu>
					</Sider>

					<Layout
						className="FeatureList-Box"
						style={{ backgroundColor: "#f5f8fe" }}
					>
						<Card style={{ width: "74vw" }}>
							{/* <div className="ProductInfoQuestion" data-testid="question"> */}
							<Flex justify="space-evenly">
								<Flex
									vertical
									align="flex-end"
									justify="space-between"
									style={{ padding: 32, width: "50vw" }}
								>
									<div data-testid="Workspacetitle">
										<Title level={4} style={{ fontWeight: 500 }}>
											{
												productInfo.find(
													(item) => item.element === selectedElement
												)?.question
											}
										</Title>
									</div>
									<div className="ProductInfoTextAreaDiv">
										<Form.Item
											name={"description"}
											rules={[{ required: true }, { min: 20 }]}
										>
											<TextArea
												data-testid="textarea"
												placeholder="Type here..."
												className="ProductInfoTextArea"
												value={inputValue}
												onChange={handleTextAreaChange}
												style={{ backgroundColor: "white" }}
											/>
										</Form.Item>
									</div>
									<Flex align="center" gap={10} style={{ marginBottom: 20 }}>
										<Button
											onClick={handlePrevious}
											icon={<ArrowLeftOutlined />}
											data-testid="previousButton"
										></Button>
										<Button
											icon={<ArrowRightOutlined />}
											type="primary"
											htmlType="submit"
											data-testid="nextButton"
											onClick={handleNext}
										></Button>
									</Flex>
								</Flex>
								<Flex align="center" justify="left">
									<Image
										width={220}
										preview={false}
										style={{ alignSelf: "center" }}
										src={
											productInfo.find(
												(item) => item.element === selectedElement
											)?.image
										}
									/>
								</Flex>
							</Flex>
						</Card>
					</Layout>
				</Layout>
			</Form>
			{/* </Layout> */}
		</Layout>
	);
}

export default ProductInfoV2;
