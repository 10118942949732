import axios, { AxiosResponse } from "axios";
import { ProductArtifactModel } from "../models/PersonaModel";

// Post: Create Product Artifact
async function createProductArtifact(
	createProductArtifactDto: any,
	token: string
): Promise<ProductArtifactModel> {
	try {
		const response: AxiosResponse<ProductArtifactModel> = await axios.post(
			`${process.env.REACT_APP_API_URL}/project-artifact`,
			createProductArtifactDto,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error creating product artifact:", error);
		throw error;
	}
}

// Get: Get Product Artifacts by Workspace ID
async function getProductArtifacts(
	workspaceId: any,
	type: string,
	token: any
): Promise<ProductArtifactModel[]> {
	try {
		const response: AxiosResponse<ProductArtifactModel[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/project-artifact/${type}/${workspaceId}`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching product artifacts:", error);
		throw error;
	}
}

// Get: Get Product Artifact by ID
async function getProductArtifact(
	id: string,
	token: string
): Promise<ProductArtifactModel> {
	try {
		const response: AxiosResponse<ProductArtifactModel> = await axios.get(
			`${process.env.REACT_APP_API_URL}/project-artifact/artifact/${id}`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching product artifact:", error);
		throw error;
	}
}

// Put: Update Product Artifact
async function updateProductArtifact(
	id: any,
	updateProductArtifactDto: Partial<ProductArtifactModel>,
	token: any
): Promise<ProductArtifactModel> {
	try {
		const response: AxiosResponse<ProductArtifactModel> = await axios.put(
			`${process.env.REACT_APP_API_URL}/project-artifact/${id}`,
			updateProductArtifactDto,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error updating product artifact:", error);
		throw error;
	}
}

// Get: Get Filtered Product Artifacts
async function getFilteredProductArtifacts(
	filterDto: any,
	token: string
): Promise<ProductArtifactModel[]> {
	try {
		const response: AxiosResponse<ProductArtifactModel[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/project-artifact/filter`,
			{
				headers: { Authorization: `Bearer ${token}` },
				params: filterDto,
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching filtered product artifacts:", error);
		throw error;
	}
}

export {
	createProductArtifact,
	getProductArtifacts,
	getProductArtifact,
	updateProductArtifact,
	getFilteredProductArtifacts,
};
