/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect } from "react";
import { Layout, Button, List, Typography, Row, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
import AddRolesModal from "./AddRoleModal";
import RoleManagementNav from "./RoleManagementNav";
import "./RoleManagement.css";

const { Text } = Typography;

const RoleManagement: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const [responseData, setResponseData] = useState<any[]>([]);
	const [, setSelectedRoleId] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const fetchRolesData = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/roles`, {
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setResponseData(data);
			} else if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				console.error("Error fetching roles data");
			}
		} catch (error) {
			console.error("Error fetching roles data:", error);
		}
	};

	useEffect(() => {
		fetchRolesData();
	}, []);

	const handleAddRoleClick = () => {
		setIsModalVisible(true);
	};

	const handleEditRoleClick = (id: any) => {
		setSelectedRoleId(id);
		navigate(`/settings/role/edit/${id}`);
	};

	const handleDeleteRoleClick = async (id: any) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/roles/${id}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				fetchRolesData();
			} else {
				console.error("Error deleting role");
			}
		} catch (error) {
			console.error("Error deleting role:", error);
		}
	};

	const handleAddRole = (newRole: any) => {
		setResponseData([...responseData, newRole]);
	};

	return (
		<>
			<RoleManagementNav />
			<Layout
				style={{
					backgroundColor: "white",
					padding: "0 1.5rem ",
					margin: "0 1vw",
					height: "80vh",
					marginTop: "-0.5vh",
				}}
			>
				<AddRolesModal
					isVisible={isModalVisible}
					setIsVisible={setIsModalVisible}
					onAddRole={handleAddRole}
				/>

				<List
					dataSource={responseData}
					renderItem={(item: any) => (
						<List.Item
							key={item._id}
							style={{ padding: "5px 0" }}
							actions={[
								<a
									key="edit"
									style={{
										color: "#0444BF",
										fontSize: "12px",
										fontFamily: "Open Sans",
									}}
									onClick={() => handleEditRoleClick(item._id)}
								>
									Edit Permissions
								</a>,
								<Popconfirm
									key="delete"
									title="Sure to Delete?"
									onConfirm={() => handleDeleteRoleClick(item._id)}
								>
									<a
										style={{
											color: "#0444BF",
											fontSize: "12px",
											fontFamily: "Open Sans",
										}}
									>
										<DeleteOutlined
											style={{ fontSize: "12px", color: "red" }}
										/>
									</a>
								</Popconfirm>,
							]}
						>
							<List.Item.Meta
								title={
									<Text
										style={{
											fontSize: "12px",
											fontWeight: "400",
										}}
									>
										{item.name}
									</Text>
								}
							/>
						</List.Item>
					)}
				/>
			</Layout>
		</>
	);
};

export default RoleManagement;
