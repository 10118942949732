/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { Avatar, Flex, List, Space, message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { getActivelogsbyparentId } from "../../api/ActiveLogAPI";

interface ViewTaskActivityProps {
	taskId?: string;
}

const ViewTaskActivity: React.FC<ViewTaskActivityProps> = ({ taskId }) => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [updates, setUpdates] = React.useState<any[]>([]);
	const { selectedItemDetails, isEditModalOpen } = planerViewStore();

	const { taskId: itemId } = useParams();

	console.log(itemId, "itemId");

	const { data: activelogData, run: ActiveLoggsRun } = useRequest(
		(workspaceId) =>
			getActivelogsbyparentId(workspaceId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				// message.info(`${response.length} updates`);
				console.log("response", response);
			},
			onError: (error) => {
				message.error(error.message);
			},
		}
	);

	useEffect(() => {
		if (activelogData) {
			setUpdates([]);

			const filteredData = activelogData
				.filter((res: any) => res.content && res.content.length > 0)
				.map((res: any) => {
					const extractedData = res.content.flatMap((item: any) => {
						if (typeof item === "object") {
							const keys = Object.keys(item);
							return keys.map((key) => ({
								key,
								data: item[key],
								type: res.type,
								date: res.createdAt,
								createdBy: res.createdBy.name,
							}));
						}
						return [];
					});
					return {
						content: extractedData,
					};
				});

			const extractDataArray = (extractedData: any) => {
				return extractedData.flatMap((data: any) => {
					return data.content.map((item: any) => {
						return {
							type: item.type,
							key: item.key,
							date: item.date,
							createdBy: item.createdBy,
							data: item.data,
						};
					});
				});
			};

			const dataArray = extractDataArray(filteredData);

			setUpdates(dataArray);
		}
	}, [activelogData]);

	useEffect(() => {
		ActiveLoggsRun(selectedItemDetails._id);
	}, [selectedItemDetails._id, itemId, selectedItemDetails, isEditModalOpen]);

	function extractValueAfterSlash(inputString: string) {
		// Find the index of the slash
		const slashIndex = inputString.indexOf("/");

		// Extract the substring after the slash
		if (slashIndex !== -1) {
			// Check if slash is found
			const valueAfterSlash = inputString.substring(slashIndex + 1);
			return valueAfterSlash;
		} else {
			return inputString; // Return null if slash is not found
		}
	}

	return (
		<List
			itemLayout="horizontal"
			key={"test"}
			dataSource={updates}
			renderItem={(item, index) => (
				<List.Item key={index + 1}>
					<div
						key={index + 1}
						style={{ display: "flex", width: "100%", alignItems: "center" }}
					>
						<div style={{ width: "50%", textAlign: "left" }}>
							{extractValueAfterSlash(item.key) === "newlycreated"
								? `${item.type} is Created`
								: `${extractValueAfterSlash(item.key)}field is updated`}
						</div>
						<div style={{ width: "20%" }}>{moment(item.date).fromNow()}</div>
						<div style={{ width: "30%" }}>
							<Avatar icon={<UserOutlined />} />
							{item.createdBy}
						</div>
					</div>
				</List.Item>
			)}
		/>
	);
};

export default ViewTaskActivity;
