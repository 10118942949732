import { Layout, theme } from "antd";
import { Outlet } from "react-router-dom";
// import PlanerMenu from "../pages/Planer/PlanerMenu";
// import PlanerHeader from "../pages/Planer/PlanerHeader";
import DrawerComponent from "../pages/Planner/Drawer";
import { planerViewStore } from "../stores/PlannerStore";
import { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore, {
	WorkspaceStoreState,
} from "../stores/WorkspaceState";

const { Header, Content } = Layout;

const Planerlayout: React.FC = () => {
	const { open, setOpen } = planerViewStore();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const workspaceStoreInstance = useWorkspaceStore() as WorkspaceStoreState;
	const [todos, setTodos] = useState<any[]>([]);
	const {
		token: { colorPrimaryBg },
	} = theme.useToken();

	const handleDrawerCancel = () => {
		setOpen(false);
	};

	const fetchTodos = async () => {
		const currentWorkspaceId = workspaceStoreInstance.currentWorkspace
			? workspaceStoreInstance.currentWorkspace["_id"]
			: "";
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${currentWorkspaceId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const result = await response.json();
			console.log(result, "result");
			if (response.ok) {
				setTodos(result);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		fetchTodos();
	}, [open]);
	return (
		<Layout style={{ background: "#FAFBFF", position: "relative" }}>
			<DrawerComponent
				visible={open}
				onClose={handleDrawerCancel}
				data={todos}
			/>

			<Header style={{ background: colorPrimaryBg, height: "auto" }}>
				{/* <PlanerHeader></PlanerHeader> */}
			</Header>
			<Layout style={{ background: "#F5F8FE" }}>
				{/* <PlanerMenu /> */}

				<Content style={{ background: "rgb(250, 250, 255) " }}>
					<Outlet />
				</Content>
			</Layout>
		</Layout>
	);
};

export default Planerlayout;
