import React, { useEffect } from "react";
import {
	Drawer,
	Form,
	Button,
	Flex,
	DatePicker,
	Select,
	Tag,
	Space,
} from "antd";
import { TaskStore } from "../../stores/TasksStore";
import Constants from "../../util/ConstantsUtil";

interface TaskDrawerComponentProps {
	visible: boolean;
	onClose: () => void;
	data: any;
}

const TaskDrawerPlanerComponent: React.FC<TaskDrawerComponentProps> = ({
	visible,
	onClose,
	data,
}) => {
	const [form] = Form.useForm();

	const { selectedItemDetails } = TaskStore();

	useEffect(() => {
		form.setFieldsValue({
			status: selectedItemDetails?.status,
			type: selectedItemDetails?.type,
		});
	}, [data, form]);

	const handleFormSubmit = (values: any) => {
		console.log("Form values:", values);
	};

	return (
		<Drawer
			placement="right"
			closable={true}
			onClose={onClose}
			open={visible}
			width={"40%"}
			footer={
				<Space style={{ float: "right" }}>
					<Button type="primary">Submit</Button>
				</Space>
			}
		>
			<Flex style={{ alignItems: "center" }} gap={20}>
				<Flex style={{ color: selectedItemDetails?.colour, fontSize: "30px" }}>
					{selectedItemDetails?.icon}
				</Flex>
				<Flex style={{}}>
					<h3>{selectedItemDetails?.title}</h3>
				</Flex>
				<Flex style={{ height: "max-content" }}>
					<Tag>{selectedItemDetails?.tag}</Tag>
				</Flex>
			</Flex>
			<Flex>
				<Flex>
					<p style={{ fontWeight: "normal", color: "black", fontSize: "1rem" }}>
						{selectedItemDetails?.description}
					</p>
				</Flex>
			</Flex>

			<Form
				style={{ marginTop: "2vh" }}
				form={form}
				onFinish={handleFormSubmit}
			>
				<Form.Item name="type" label="Type">
					<Select
						options={[
							{ value: Constants.CAPS_UX, label: Constants.CAPS_UX },
							{ value: Constants.FEATURE, label: Constants.FEATURE }, // change to CAPS
							{ value: Constants.IDEA, label: Constants.IDEA }, // change to CAPS
							{ value: Constants.BUG, label: Constants.BUG }, // change to CAPS
						]}
					/>
				</Form.Item>

				<Form.Item name="status" label="Status">
					<Select
						options={[
							{
								value: Constants.CAPS_IN_PROGRESS,
								label: Constants.CAPS_IN_PROGRESS,
							},
							{
								value: Constants.CAPS_COMPLETED,
								label: Constants.CAPS_COMPLETED,
							},
						]}
					/>
				</Form.Item>
				<Form.Item label="Due Date">
					<DatePicker />
				</Form.Item>
			</Form>
		</Drawer>
	);
};

export default TaskDrawerPlanerComponent;
