import React from "react";
import {
	Modal,
	Form,
	Input as AntInput,
	Button,
	DatePicker,
	message,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreatePlanModel.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { usePlanStore } from "../../../stores/PlanStore";
import useWorkspaceStore from "../../../stores/WorkspaceState";

interface CreatePlanModelProps {
	visible: boolean;
	onCancel: () => void;
}

const { RangePicker } = DatePicker;

const CreatePlanModel: React.FC<CreatePlanModelProps> = ({
	visible,
	onCancel,
}) => {
	const formItemLayout = {
		labelCol: { span: 3 },
		wrapperCol: { span: 18 },
	};

	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const { setFetchPlan, FetchPlan } = usePlanStore();
	const messageApi = message;
	const success = () => {
		messageApi.success("New Plan added successfully");
	};

	const handleFormSubmit = async (values: any) => {
		// Extracting dates from the dateRange array
		const [startDate, endDate] = values.dateRange.map((date: Date) => {
			return date.toISOString().split("T")[0]; // Extracting date part
		});

		await fetch(`${process.env.REACT_APP_API_URL}/plan`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify({
				name: values.planName,
				startdate: startDate,
				enddate: endDate,
				type: "Releaseplan",
				items: [],
				workspaceId: currentWorkspace?._id,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
				success();

				onCancel();
				setFetchPlan(!FetchPlan);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Modal
			title="Create Release Plan"
			visible={visible}
			onCancel={onCancel}
			width={1000}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					htmlType="submit"
					onClick={() => form.submit()}
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				onFinish={handleFormSubmit}
				{...formItemLayout}
				style={{ margin: "5rem 0 10rem 0" }}
			>
				<Form.Item
					label="Plan Name"
					name="planName"
					rules={[{ required: true, message: "Please input plan name!" }]}
				>
					<AntInput
						style={{ borderRadius: "0.25rem", width: "40%" }}
						placeholder="Plan Name"
					/>
				</Form.Item>

				<Form.Item
					label="From Date"
					name="dateRange"
					rules={[{ required: true, message: "Please select date range!" }]}
				>
					<RangePicker format="YYYY-MM-DD" />
				</Form.Item>

				<Form.Item label="Goals" name="goals">
					<ReactQuill style={{ borderRadius: "0.25rem", width: "60%" }} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default CreatePlanModel;
