import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import { Button } from "antd";
import {
	CalendarOutlined,
	ScheduleOutlined,
	ClockCircleOutlined,
	PieChartOutlined,
} from "@ant-design/icons";

type ViewSwitcherProps = {
	isChecked: boolean;
	onViewListChange: (isChecked: boolean) => void;
	onViewModeChange: (viewMode: ViewMode) => void;
};

export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
	onViewModeChange,
}) => {
	return (
		<div style={{ float: "right" }} className="ViewContainer">
			<Button
				className="Button"
				style={{ borderRadius: "6px", fontSize: "14px" }}
				size="small"
				icon={<ClockCircleOutlined />}
				onClick={() => onViewModeChange(ViewMode.Day)}
			>
				Day
			</Button>
			<Button
				style={{ marginLeft: "0.5vw", borderRadius: "6px", fontSize: "14px" }}
				className="Button"
				size="small"
				icon={<ScheduleOutlined />}
				onClick={() => onViewModeChange(ViewMode.Week)}
			>
				Week
			</Button>
			<Button
				style={{ marginLeft: "0.5vw", borderRadius: "6px", fontSize: "14px" }}
				className="Button"
				size="small"
				icon={<CalendarOutlined />}
				onClick={() => onViewModeChange(ViewMode.Month)}
			>
				Month
			</Button>
			<Button
				style={{ marginLeft: "0.5vw", borderRadius: "6px", fontSize: "14px" }}
				className="Button"
				size="small"
				icon={<PieChartOutlined />}
				onClick={() => onViewModeChange(ViewMode.Year)}
			>
				Year
			</Button>
		</div>
	);
};
