import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	Layout,
	Card,
	Flex,
	Button,
	Typography,
	ColorPicker,
	ColorPickerProps,
	GetProp,
} from "antd";
import "./AddRole.css";
import { useNavigate, useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
type Color = GetProp<ColorPickerProps, "value">;

const EditTagModal: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { id } = useParams();
	const { Text } = Typography;
	const formItemLayout = { labelCol: { span: 2 }, wrapperCol: { span: 14 } };
	const [tagName, setTagName] = useState<string>("");
	const [category, setCategory] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [color, setColor] = useState<Color>("#1677ff");
	const [tagData, setTagData] = useState<any>({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/tags/${id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (response.ok) {
					const responseData = await response.json();
					setTagData(responseData);
					form.setFieldsValue({
						tagName: responseData.name,
						category: responseData.category,
						description: responseData.description,
						color: responseData.color,
					});
				} else if (response.status === 401) {
					setUnauthorizedModel(true);
				} else {
					console.error("Error fetching tag data:", response.statusText);
				}
			} catch (error) {
				console.error("Error fetching tag data:", error);
			}
		};

		fetchData();
	}, [form, id]);

	const handleButtonClick = async () => {
		const formData = form.getFieldsValue();
		const createdBy = tagData.createdBy || "defaultCreatedBy";
		const updatedBy = "defaultUpdatedBy";

		const postData = {
			name: formData.tagName || tagData.name,
			category: formData.category || tagData.category,
			description: formData.description || tagData.description,
			color: formData.color || color,
			createdBy,
			updatedBy,
			account_Id: userProfileStoreInstance.profile.account_Id,
		};

		console.log("Sending data to the backend:", postData);

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/tags/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(postData),
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				console.log("Response from the backend:", responseData);
				console.log("Data sent successfully");
				navigate("/fd/workspace/settings/tag");
			} else {
				const errorData = await response.json();
				console.error("Error sending data to the backend:", errorData);
				console.error("Error sending data to the backend");
			}
		} catch (error) {
			console.error("Error sending data:", error);
		}
	};

	const handleCancelClick = async () => {
		navigate("/fd/workspace/settings/tag");
	};

	return (
		<Layout style={{ background: "white" }}>
			<h2
				style={{
					color: "black",
					fontFamily: "Poppins",
					fontSize: "24px",
					marginLeft: "2.5rem",
				}}
			>
				Edit Tag
			</h2>
			<Card
				className="fixedCard-settings"
				style={{
					overflow: "auto",
					width: "80vw",
					maxHeight: "68vh",
					marginLeft: "1rem",
				}}
			>
				<Form
					form={form}
					onFinish={handleButtonClick}
					{...formItemLayout}
					layout="horizontal"
					colon={false}
					style={{ marginTop: "-4rem" }}
				>
					<Flex justify="space-between" align="center">
						<Text>Tag Name:</Text>
						<Form.Item
							className="titleTypoAddTag"
							name="tagName"
							rules={[
								{
									required: true,
									message: "Please enter tag name",
								},
							]}
							style={{ width: "90%" }}
						>
							<Input
								className="formInputUser"
								placeholder="Enter tag name"
								value={tagName}
								onChange={(event: {
									target: { value: React.SetStateAction<string> };
								}) => setTagName(event.target.value)}
							/>
						</Form.Item>
					</Flex>
					<Flex justify="space-between" align="center">
						<Text>Category:</Text>
						<Form.Item
							className="titleTypoAddTag"
							name="category"
							rules={[
								{
									required: true,
									message: "Please enter tag category",
								},
							]}
							style={{ width: "90%" }}
						>
							<Input
								className="formInputUser"
								placeholder="Enter tag category"
								value={category}
								onChange={(event: {
									target: { value: React.SetStateAction<string> };
								}) => setCategory(event.target.value)}
							/>
						</Form.Item>
					</Flex>
					<Flex
						justify="space-between"
						align="center"
						style={{ marginBottom: "1.5rem" }}
					>
						<Text>Description:</Text>
						<Form.Item
							className="titleTypoAddTag"
							name="description"
							rules={[
								{
									required: true,
									message: "Please enter tag description",
								},
							]}
							style={{ width: "90%", height: "3rem" }}
						>
							<Input.TextArea
								className="formInputUser"
								placeholder="Enter tag description"
								value={description}
								onChange={(event: {
									target: { value: React.SetStateAction<string> };
								}) => setDescription(event.target.value)}
							/>
						</Form.Item>
					</Flex>
					<Flex
						justify="space-between"
						align="center"
						style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
					>
						<Text>Color:</Text>
						<Form.Item
							className="titleTypoAddTag"
							name="color"
							getValueFromEvent={(color) => {
								setColor("#" + color.toHex());
							}}
							style={{ width: "90%", height: ".4rem" }}
							rules={[
								{
									required: false,
									message: "Please select tag color",
								},
							]}
						>
							<ColorPicker
								defaultValue={color}
								showText={(color) => (
									<span>---Color--- ({color.toHexString()})</span>
								)}
								getPopupContainer={undefined}
								autoAdjustOverflow={undefined}
								destroyTooltipOnHide={undefined}
							/>
						</Form.Item>
					</Flex>

					<Flex style={{ paddingBottom: "16px" }} justify={"flex-end"}>
						<div className="buttons-container">
							<Button
								style={{ width: "5rem" }}
								onClick={handleCancelClick}
								data-testId="addTagTest"
							>
								Cancel
							</Button>
							<Button
								style={{ width: "5rem" }}
								onClick={handleButtonClick}
								type="primary"
								htmlType="submit"
							>
								Save
							</Button>
						</div>
					</Flex>
				</Form>
			</Card>
		</Layout>
	);
};

export default EditTagModal;
