import React, { useState, useEffect } from "react";
import {
	Button,
	Col,
	Divider,
	Flex,
	Row,
	Tag,
	Typography,
	message,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { SkinTwoTone, StarTwoTone, TeamOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { getWorkItemsById } from "../../api/WorkItemAPI";

interface ViewTask {
	taskId?: string;
}

const { Title } = Typography;
const QuickViewBacklog: React.FC<ViewTask> = ({ taskId }) => {
	const [taskDetails, setTaskDetails] = useState<any | null>(null);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { data: workitemByIdData, run: getWorkItemById } = useRequest(
		(workItemId) =>
			getWorkItemsById(workItemId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.success(`${result.title} ${result.type} details`);
				console.log(result, "feature data");
			},
			onError: (error) => {
				message.error(`Failed to fetch workitem feature : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemByIdData) {
			setTaskDetails(workitemByIdData);
		}
	}, [workitemByIdData]);

	useEffect(() => {
		getWorkItemById(taskId);
	}, [taskId]);

	const formatText = (text: any) => {
		const words = text?.split("_");
		const formattedWords = words?.map(
			(word: any) => word.charAt(0).toUpperCase() + word.slice(1)
		);
		const formattedText = formattedWords?.join(" ");
		return formattedText;
	};

	return (
		<div>
			<div style={{ textAlign: "left" }}>
				<p
					style={{
						fontWeight: "normal",
						color: "black",
						fontSize: "1rem",
						marginBottom: "30px",
					}}
					dangerouslySetInnerHTML={{ __html: taskDetails?.description || "" }}
				></p>

				<Row gutter={16}>
					<Col className="gutter-row" span={4}>
						<div>Status</div>
					</Col>
					<Col className="gutter-row" span={8}>
						<Tag color="red" style={{ textAlign: "left" }}>
							{taskDetails?.status}
						</Tag>
					</Col>
					<Col className="gutter-row" span={4}>
						<div>Priority</div>
					</Col>
					<Col className="gutter-row" span={6}>
						<Tag color="pink" style={{ textAlign: "left" }}>
							{taskDetails?.priority}
						</Tag>
					</Col>
				</Row>
			</div>
			<Divider />
			{taskDetails?.data && (
				<Flex gap={20} style={{ marginTop: 25 }} vertical>
					<Flex gap={20} style={{ textAlign: "left" }}>
						<div className="Complexity-icon">
							<Button>
								<SkinTwoTone />
							</Button>
						</div>
						<Flex
							//onContextMenu={handleRightClick}
							//className={contentClassName}
							//data-path={`fe/featurelist/${featureState}`}
							vertical
							justify="center"
							gap={5}
						>
							<Flex gap={10} style={{ alignItems: "center" }}>
								<Title
									//onContextMenu={handleRightClick}
									//className={contentClassName}
									//data-path={`fe/featurelist/${featureState}`}
									level={5}
									style={{
										margin: 0,
										fontWeight: 500,
										fontSize: 15,
									}}
								>
									Complexity
								</Title>
								<Tag
									// onContextMenu={handleRightClick}
									// className={contentClassName}
									// data-path={`fe/featurelist/${featureState}`}
									color="gold"
								>
									{taskDetails?.data?.complexity_score}
								</Tag>
							</Flex>
							<Flex gap={10} style={{ alignItems: "center" }}>
								<p
									// onContextMenu={handleRightClick}
									// className={contentClassName}
									// data-path={`fe/featurelist/${featureState}`}
									style={{ margin: 0, fontWeight: 400 }}
								>
									{taskDetails?.data?.complexity_score_reason}
								</p>
							</Flex>
						</Flex>
					</Flex>

					<Flex gap={20} style={{ textAlign: "left" }}>
						<div className="Complexity-icon">
							<Button>
								<TeamOutlined />
							</Button>
						</div>
						<Flex
							// onContextMenu={handleRightClick}
							// className={contentClassName}
							// data-path={`fe/featurelist/${featureState}`}
							vertical
							justify="center"
							gap={5}
						>
							<Title
								// onContextMenu={handleRightClick}
								// className={contentClassName}
								// data-path={`fe/featurelist/${featureState}`}
								level={5}
								style={{
									margin: 0,
									fontWeight: 500,
									fontSize: 15,
								}}
							>
								Roles
							</Title>
							<ul
								style={{
									margin: 0,
									padding: "0 0 0 20px",
									opacity: 0.5,
								}}
							>
								{taskDetails?.data?.role_ids?.map(
									(role: string, roleIndex: number) => (
										<li
											// onContextMenu={handleRightClick}
											// className={contentClassName}
											// data-path={`fe/featurelist/${featureState}`}
											key={roleIndex}
										>
											{formatText(role)}
										</li>
									)
								)}
							</ul>
						</Flex>
					</Flex>

					<Flex gap={20} style={{ textAlign: "left" }}>
						<div className="Complexity-icon">
							<Button>
								<StarTwoTone />
							</Button>
						</div>
						<Flex
							// onContextMenu={handleRightClick}
							// className={contentClassName}
							// data-path={`fe/featurelist/${featureState}`}
							vertical
							justify="center"
							gap={5}
						>
							<Title
								// onContextMenu={handleRightClick}
								// className={contentClassName}
								// data-path={`fe/featurelist/${featureState}`}
								level={5}
								style={{
									margin: 0,
									fontWeight: 500,
									fontSize: 15,
								}}
							>
								Benefits
							</Title>
							<ul
								style={{
									margin: 0,
									padding: "0 0 0 20px",
									opacity: 0.5,
								}}
							>
								{taskDetails?.data?.benefits.map(
									(benefit: string, benfitIndex: number) => (
										<li
											// onContextMenu={handleRightClick}
											// className={contentClassName}
											// data-path={`fe/featurelist/${featureState}`}
											key={benfitIndex}
										>
											{benefit}
										</li>
									)
								)}
							</ul>
						</Flex>
					</Flex>
				</Flex>
			)}
		</div>
	);
};

export default QuickViewBacklog;
