import _ from "lodash";
import Constants from "./ConstantsUtil";

export const getNavigateUrlForWorkspaces = (
	workspaceId: string,
	workspaceModules: any[],
	currentModule: string,
	currentPage: string
) => {
	//get navigate assist object for the filtered modules
	let filterNavDetails = navigateAssistObjForWorkspace.filter((module) => {
		for (let i = 0; i < workspaceModules.length; i++) {
			if (module.module === workspaceModules[i]) {
				return module;
			}
		}
		//return module.module === currentModule;
	});
	//const isModuleInWorkspace = _.some(workspaceModules, { module: currentModule });

	filterNavDetails = _.sortBy(filterNavDetails, ["seq"]);

	// get object from filteredModules next in the seq from the current page equal step
	let currentStepSeq = 0;
	_.find(filterNavDetails, (module) => {
		if (module.step === currentPage) {
			currentStepSeq = module.seq;
		}
	});

	let nextUrl = "";
	for (let i = 0; i < filterNavDetails.length; i++) {
		if (filterNavDetails[i].seq > currentStepSeq && nextUrl === "") {
			nextUrl = filterNavDetails[i].url;
		}
	}

	// filterNavDetails.forEach((module) => {
	// 	if (module.seq > currentStepSeq) {
	// 		nextUrl = module.url;
	// 		return;
	// 	}
	// });

	//get previous url
	let prevUrl = "";

	for (const module of filterNavDetails) {
		if (module.seq < currentStepSeq) {
			prevUrl = module.url;
		} else if (module.seq === currentStepSeq) {
			continue;
		} else if (module.seq > currentStepSeq) {
			break;
		}
	}
	if (prevUrl !== "/") {
		nextUrl = nextUrl + workspaceId;
		prevUrl = prevUrl + workspaceId;
	}

	if (
		currentModule === Constants.FEATURE_ENGINEERING &&
		currentPage === Constants.FE_FEATURE_LIST
	) {
		prevUrl = "/";
	}

	if (
		currentModule === Constants.DESIGN_ENGINEERING &&
		currentPage === Constants.DE_DATA_MODEL
	) {
		nextUrl = "/";
	}
	console.log("nextUrl", nextUrl);
	console.log("prevUrl", prevUrl);
	return {
		nextUrl,
		prevUrl,
	};
};

export const navigateAssistObjForWorkspace = [
	// {
	// 	seq: 0,
	// 	module: Constants.WORKSPACE,
	// 	step: Constants.WORKSPACE_LIST,
	// 	url: "/",
	// },
	{
		seq: 1,
		module: Constants.FEATURE_ENGINEERING,
		step: Constants.FE_FEATURE_LIST,
		url: "/workspace/fe/featurelist/",
	},
	{
		seq: 2,
		module: Constants.FEATURE_ENGINEERING,
		step: Constants.FE_FEATURE_ROLE,
		url: "/workspace/fe/featurerole/",
	},
	{
		seq: 3,
		module: Constants.FEATURE_ENGINEERING,
		step: Constants.FE_FEATURE_SPECIFICATION,
		url: "/workspace/fe/featurespec/",
	},
	{
		seq: 4,
		module: Constants.DESIGN_ENGINEERING,
		step: Constants.DE_USER_PERSONA,
		url: "/workspace/de/uxprocess/",
	},
	{
		seq: 5,
		module: Constants.DESIGN_ENGINEERING,
		step: Constants.DE_INFORMATION_ARCHITECTURE,
		url: "/workspace/de/info/",
	},
	{
		seq: 6,
		module: Constants.DESIGN_ENGINEERING,
		step: Constants.DE_DDD_CORE,
		url: "/workspace/de/ddd/core/",
	},
	// {
	// 	seq: 7,
	// 	module: Constants.DESIGN_ENGINEERING,
	// 	step: Constants.DE_DDD_STRATEGIC_DESGIN,
	// 	url: "/workspace/de/ddd/strategic/",
	// },
	// {
	// 	seq: 8,
	// 	module: Constants.DESIGN_ENGINEERING,
	// 	step: Constants.DE_DDD_ADVANCE_PATTERN,
	// 	url: "/workspace/de/ddd/advanced/",
	// },
	{
		seq: 9,
		module: Constants.DESIGN_ENGINEERING,
		step: Constants.DE_API_SPECIFICATION,
		url: "/workspace/de/apiDesign/",
	},
	{
		seq: 10,
		module: Constants.DESIGN_ENGINEERING,
		step: Constants.DE_DATA_MODEL,
		url: "/workspace/de/data/",
	},
];
